import { Injectable } from '@angular/core';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Patient } from 'src/app/models/patient';

@Injectable({
    providedIn: 'root',
})
export class PatientStateService {
    constructor(private patientServices: AdminPatientServices) {}

    private readonly patient = new ReplaySubject<Patient>(1);
    public readonly patient$ = this.patient.asObservable();

    public setNewPatient(newPatient: Patient): void {
        this.patient.next(newPatient);
    }

    public setSummaryPatientData(id: number): void {
        this.patientServices.getPatientTrialSummary(id).subscribe((res) => {
            return this.patient.next(res);
        });
    }

    public getSummaryPatientData(): Observable<Patient> {
        return this.patient$;
    }

    clearStore() {
        this.patient.next(null);
    }
    public getPatient() {
        return this.patient$;
    }
}
