export class ISaveStatus implements IISaveStatus {
    infoLabel?: string | null;
    validationErrors?: string[] | null;
    saveComplete!: boolean;
    saveSuccessful!: boolean;
    modelValid!: boolean;

    constructor(data?: IISaveStatus) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.infoLabel = _data['infoLabel'] !== undefined ? _data['infoLabel'] : <any>null;
            if (Array.isArray(_data['validationErrors'])) {
                this.validationErrors = [] as any;
                for (let item of _data['validationErrors']) this.validationErrors!.push(item);
            } else {
                this.validationErrors = <any>null;
            }
            this.saveComplete = _data['saveComplete'] !== undefined ? _data['saveComplete'] : <any>null;
            this.saveSuccessful = _data['saveSuccessful'] !== undefined ? _data['saveSuccessful'] : <any>null;
            this.modelValid = _data['modelValid'] !== undefined ? _data['modelValid'] : <any>null;
        }
    }

    static fromJS(data: any): ISaveStatus {
        data = typeof data === 'object' ? data : {};
        let result = new ISaveStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['infoLabel'] = this.infoLabel !== undefined ? this.infoLabel : <any>null;
        if (Array.isArray(this.validationErrors)) {
            data['validationErrors'] = [];
            for (let item of this.validationErrors) data['validationErrors'].push(item);
        }
        data['saveComplete'] = this.saveComplete !== undefined ? this.saveComplete : <any>null;
        data['saveSuccessful'] = this.saveSuccessful !== undefined ? this.saveSuccessful : <any>null;
        data['modelValid'] = this.modelValid !== undefined ? this.modelValid : <any>null;
        return data;
    }
}

export interface IISaveStatus {
    infoLabel?: string | null;
    saveComplete: boolean;
    saveSuccessful: boolean;
    modelValid: boolean;
}
