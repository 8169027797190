import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { GenericRequest } from 'src/app/models/request';

@Component({
    selector: 'medpace-additional-travel-info-card',
    templateUrl: './additional-travel-info.component.html',
})
export class MedpaceAdditionalTravelInfoCardComponent implements OnChanges {
    @Input()
    request: GenericRequest;
    @Input()
    isEditing: boolean;

    @Output()
    additionalInfoChange = new EventEmitter<InputChange>();

    caregiverTraveling: boolean;
    isAMinor: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.request && changes.request.currentValue && changes.request.currentValue.caregiver) {
            this.caregiverTraveling = true;
        } else if (changes.request && changes.request.currentValue && !changes.request.currentValue.caregiver) {
            this.caregiverTraveling = false;
        }

        if (changes.request && changes.request.currentValue && changes.request.currentValue.isAMinor) {
            this.isAMinor = true;
        } else if (changes.request && changes.request.currentValue && !changes.request.currentValue.isAMinor) {
            this.isAMinor = false;
        }
    }

    changeCaregiverTravel(event: any) {
        if (event.value === 'yes') {
            this.request.caregiver = true;
            this.caregiverTraveling = true;
            this.additionalInfoChange.emit({ target: 'hasCaregiverTravelling', value: true });
        }
        if (event.value === 'no') {
            this.caregiverTraveling = false;
            this.request.caregiver = true;
            this.additionalInfoChange.emit({ target: 'hasCaregiverTravelling', value: false });
        }
    }
    changeMinorStatus(event: any) {
        if (event.value === 'yes') {
            this.additionalInfoChange.emit({ target: 'minorPatient', value: true });
        }
        if (event.value === 'no') {
            this.additionalInfoChange.emit({ target: 'minorPatient', value: false });
        }
    }
}
