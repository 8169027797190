// import { UserService } from './../user/user.service';
import { Injectable } from '@angular/core';
import { UserService } from '@app/generated-api/api/user.service';
import { ICreateUserDTO, IPasswordUpdateDto, IUpdateUserDTO, IUserStatusDTO } from '@models/interfaces/iUser';
import { CreateUserDTO, UpdateUserDTO, UserAdminViewModel, UserStatusDTO } from '@models/user';
import { UserClient } from '@repositories/admin/user-client.repository';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { Observable, ReplaySubject, finalize, tap } from 'rxjs';
import { GetUserDto, PasswordUpdateDto } from './../../models/user';
import { AccessRequestStateService } from './access-request-state.service';

@Injectable({
    providedIn: 'root',
})
export class UserStateService {
    private readonly userSubject: ReplaySubject<CreateUserDTO> = new ReplaySubject<CreateUserDTO>(1);
    public readonly userObservable$: Observable<CreateUserDTO> = this.userSubject.asObservable();
    private readonly userAdminViewModelArray: ReplaySubject<UserAdminViewModel[]> = new ReplaySubject<
        UserAdminViewModel[]
    >();
    public readonly userAdminViewModelArray$: Observable<UserAdminViewModel[]> =
        this.userAdminViewModelArray.asObservable();
    private user: CreateUserDTO;
    private createdUser: CreateUserDTO;
    private updatedUser: UpdateUserDTO;
    private studyIdsForWhoseSitesUserIsPrimaryCRC: number[] = [];
    private siteIdsForWhoseSitesUserIsPrimaryCRC: number[] = [];

    constructor(
        private userClient: UserClient,
        private snackbarService: SnackbarService,
        private userClientNew: UserService,
        private accesRequestStateService: AccessRequestStateService
    ) {}

    setUserAdminViewModelArray(): void {
        this.userClient
            .getUserAdminDetails()
            .pipe(
                tap((result) => {
                    this.userAdminViewModelArray.next(result);
                })
            )
            .subscribe();
    }

    getUserAdminViewModelArray(): Observable<UserAdminViewModel[]> {
        return this.userAdminViewModelArray$;
    }

    setUserToCreate(): void {
        this.createdUser = new CreateUserDTO(<ICreateUserDTO>{ isActive: true });
        this.user = this.createdUser;
        this.userSubject.next(this.user);
    }

    setUser(userDTO: UpdateUserDTO): void {
        this.updatedUser = userDTO;
        this.user = this.updatedUser;
        this.userSubject.next(this.user);
    }

    setUserByEmail(userEmail: string): void {
        let encodedUserEmail: string = btoa(userEmail);
        this.userClient
            .getUserByEmail(encodedUserEmail)
            .pipe(
                tap((userDTO) => {
                    this.updatedUser = this._mapGetUserDtoToUpdateUserDTO(userDTO);
                    this.user = this.updatedUser;
                    this.studyIdsForWhoseSitesUserIsPrimaryCRC = userDTO.studyIdsPrimaryCRC;
                    this.siteIdsForWhoseSitesUserIsPrimaryCRC = userDTO.siteIdsPrimaryCRC;
                    this.userSubject.next(this.user);
                })
            )
            .subscribe();
    }

    resetUser(): void {
        this.user = <UpdateUserDTO>{ id: -100, isActive: false, hasLocalAuthentication: false };
        this.userSubject.next(this.user);
        this.studyIdsForWhoseSitesUserIsPrimaryCRC = [];
        this.siteIdsForWhoseSitesUserIsPrimaryCRC = [];
    }

    getStudyIdsForWhoseSitesUserIsPrimaryCRC(): number[] {
        return this.studyIdsForWhoseSitesUserIsPrimaryCRC;
    }

    getSitesIdsForWhoseSitesUserIsPrimaryCRC(): number[] {
        return this.siteIdsForWhoseSitesUserIsPrimaryCRC;
    }

    getUser(): Observable<CreateUserDTO> {
        return this.userObservable$;
    }

    updateUser(): void {
        this.userClient
            .update(this.updatedUser)
            .pipe(
                tap((result) => {
                    this.snackbarService.openInfoSnackbar(
                        `Updated ${this.updatedUser.firstName} ${this.updatedUser.lastName}`
                    );
                }),
                finalize(() => {
                    this.setUserAdminViewModelArray();
                    this.accesRequestStateService.setAccessRequestLogs();
                })
            )
            .subscribe();
    }
    createUser(): void {
        this.userClient
            .create(this.createdUser)
            .pipe(
                tap((result) => {
                    this.snackbarService.openInfoSnackbar(
                        `Created ${this.createdUser.firstName} ${this.createdUser.lastName}`
                    );
                }),
                finalize(() => {
                    this.setUserAdminViewModelArray();
                    this.accesRequestStateService.setAccessRequestLogs();
                })
            )
            .subscribe();
    }
    setUserStatus(): void {
        if (this.updatedUser?.id) {
            this.userClient
                .setUserStatus(
                    new UserStatusDTO(<IUserStatusDTO>{
                        userId: this.updatedUser.id,
                        isActive: !this.updatedUser.isActive,
                    })
                )
                .pipe(
                    tap((result) => {
                        this.snackbarService.openInfoSnackbar(
                            !this.updatedUser.isActive ? 'User Enabled' : 'User Disabled'
                        );
                        this.setUserByEmail(this.updatedUser.emailAddress);
                        this.setUserAdminViewModelArray();
                    })
                )
                .subscribe();
        }
    }
    updateCurrentUserPassword(newPassword: string): void {
        this.userClient
            .updatePassword(new PasswordUpdateDto(<IPasswordUpdateDto>{ updatedPassword: newPassword }))
            .pipe(
                tap((result) => {
                    this.snackbarService.openInfoSnackbar('Password Updated');
                })
            )
            .subscribe();
    }

    resetUserPassword(userEmail: string): void {
        let base64EncodedEmail: string = btoa(userEmail);
        this.userClientNew
            .userResetPassword(base64EncodedEmail)
            .pipe(
                tap((_) => {
                    this.snackbarService.openInfoSnackbar('Password was Reset');
                })
            )
            .subscribe();
    }

    private _mapGetUserDtoToUpdateUserDTO(getUser: GetUserDto): UpdateUserDTO {
        return new UpdateUserDTO(<IUpdateUserDTO>{
            id: getUser.id,
            firstName: getUser?.firstName,
            lastName: getUser?.lastName,
            middleName: getUser?.middleName,
            emailAddress: getUser?.emailAddress,
            phoneNumber: getUser?.phoneNumber,
            isActive: getUser?.isActive,
            userAccessGroupId: getUser?.userAccessGroupId,
            hasLocalAuthentication: getUser?.hasLocalAuthentication,
            siteIds: getUser?.siteIds,
            studyIds: getUser?.studyIds,
        });
    }
}
