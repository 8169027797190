import { Injectable } from '@angular/core';
import { ReportRepository } from '@repositories/reports/reports.repository';
import { Observable } from 'rxjs';

@Injectable()
export class ReportServices {
    constructor(private repo: ReportRepository) {}

    getStudyExpenses(studyId: number): Observable<ArrayBuffer> {
        return this.repo.getStudyExpenses(studyId);
    }
    getFullStudyUtilizationReportData(studyId: number) {
        return this.repo.getFullStudyUtilizationReportData(studyId);
    }
    getCRCLastLoginReportData(studyId: number) {
        return this.repo.getCRCLastLoginReportData(studyId);
    }
    getPatientLastRequestSubmittedAtReportData(studyId: number) {
        return this.repo.getPatientLastRequestSubmittedAtReportData(studyId);
    }
    getSiteRequestCountReportData(studyId: number) {
        return this.repo.getSiteRequestCountReportData(studyId);
    }
    getPatientProfileCreationReportData(studyId: number) {
        return this.repo.getPatientProfileCreationReportData(studyId);
    }
}
