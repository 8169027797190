import { Injectable } from '@angular/core';
import { RequestApprovalStatus } from '@models/requestApprovalStatus';

@Injectable({
    providedIn: 'root',
})
export class RequestStatusService {
    private requestApprovalStatuses: RequestApprovalStatus[] = [
        { value: 1, viewValue: 'Draft' },
        { value: 2, viewValue: 'Submitted' },
        { value: 3, viewValue: 'Completed' },
        { value: 4, viewValue: 'In Process' },
        { value: 5, viewValue: 'Pending Approval' },
        { value: 6, viewValue: 'Denied' },
        { value: 7, viewValue: 'Cancelled' },
        { value: 8, viewValue: 'Pending Set-Up' },
        { value: 9, viewValue: 'Pending Site Verification' },
    ];

    public getStatuses(): RequestApprovalStatus[] {
        return this.requestApprovalStatuses;
    }

    public getStatusesAvailabletoChange() {
        return this.requestApprovalStatuses.filter((val) => val.value >= 3 && val.value != 9);
    }

    public getStatusById(id: number) {
        return this.requestApprovalStatuses.find((val) => val.value === id);
    }
}

export enum RequestStatusEnum {
    Draft = 1,
    Submitted = 2,
    Completed = 3,
    InProcess = 4,
    PendingApproval = 5,
    Denied = 6,
    Cancelled = 7,
    PendingSetUp = 8,
    PendingSiteVerification = 9,
}
