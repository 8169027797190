<mds-dialog dialogTitle="Request History">
    <mds-dialog-content>
        <div>
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-table">
                            <mds-table
                                id="request-history"
                                [dataSource]="requestStatusLog"
                                [hasCustomRowDefs]="true"
                                [dataColumns]="requestColumnsToDisplay"
                                columnCase="uppercase"
                                dataWhiteSpace="nowrap"
                                matSort
                            >
                                <ng-container matColumnDef="requestApprovalStatusId">
                                    <mat-header-cell *matHeaderCellDef>STATUS</mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{ element.requestApprovalStatusId }}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="timestamp">
                                    <mat-header-cell *matHeaderCellDef>DATE</mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{
                                        getLocalDate(element.timestamp)
                                            | dateFormat: DateFormat.dateOnly : null
                                            | uppercase
                                    }}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="responsibleUserName">
                                    <mat-header-cell *matHeaderCellDef>NAME</mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{ element.responsibleUserName }}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="details">
                                    <mat-header-cell *matHeaderCellDef>DETAILS</mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{ element.details }}</mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="requestColumns"> </mat-header-row>
                                <mat-row *matRowDef="let row; columns: requestColumns"></mat-row>
                            </mds-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button [id]="'medpaceBtn'" label="Done" (clickEvent)="closeDialog()" variant="primary"> </mds-button>
    </mds-dialog-actions>
</mds-dialog>
