import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SiteService } from '@services/site/site.service';
import { SaveStatusResponse } from '@utility/utility';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PatientPortalService {
    #http = inject(HttpClient);
    #siteService = inject(SiteService);
    sendEmailInvitationToPatientPortal(patientId: number) {
        return this.#http.post<SaveStatusResponse>(
            `${this.#siteService.getSiteBaseUrl()}/api/accessRequests/${patientId}`,
            null
        );
    }
    getPatientPortalAccessRequestByPatientId(patientId: number) {
        return this.#http.get<string>(`${this.#siteService.getSiteBaseUrl()}/api/accessRequests/${patientId}`);
    }
    removePatientPortalAccessRequestByPatientId(patientId: number) {
        return this.#http.delete<SaveStatusResponse>(
            `${this.#siteService.getSiteBaseUrl()}/api/accessRequests/${patientId}`
        );
    }
    isPatientInPatientPortal(patientId: number): Observable<boolean> {
        return this.#http.get<boolean>(
            `${this.#siteService.getSiteBaseUrl()}/api/patients/${patientId}/patientPortalAccess`
        );
    }
}
