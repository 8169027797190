import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { AuthService } from '@services/auth/auth.service';
import { EventService } from '@services/event/event.service';
import { RequestMessagingService } from '@services/messaging/request-messaging.service';
import { AccessRequestStateService } from '@services/state-management/access-request-state.service';
import { UserService } from '@services/user/user.service';
import { Observable, Subject, combineLatest, filter, forkJoin, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { User } from '../../../models/user';

export interface UserProfile {
    name: string;
    initial: string;
    link: string;
}

export interface NotificationItems {
    link: string;
    color?: string;
    content: string;
    portal: string;
}
@Component({
    selector: 'medpace-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class MedpaceBannerComponent implements OnInit, OnDestroy {
    loggedIn: boolean = false;

    user: User;

    submittedRequestCount: number;
    profile: UserProfile;
    bannerExpanded = false;
    notificationItems: NotificationItems[] = [];
    private componentDestroyed$: Subject<boolean> = new Subject();
    homeLink: string;
    actionItems: any;
    defaultHiddenActionsInComponent: any = [];
    adminHomeLink: string = '/admin/dashboard';
    crcHomeLink: string = '/crc/dashboard';
    superAdminActions = [
        {
            title: 'Dashboard',
            link: '/admin/dashboard',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Studies',
            link: '/admin/studies',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Sites',
            link: '/admin/sites',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Patients',
            link: '/admin/patients',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Requests',
            link: '/admin/requests',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Manage',
            link: 'superadmin/manage',
            icon: '',
            routerLink: true,
        },
    ];
    adminActions = [
        {
            title: 'Dashboard',
            link: '/admin/dashboard',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Studies',
            link: '/admin/studies',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Sites',
            link: '/admin/sites',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Patients',
            link: '/admin/patients',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Requests',
            link: '/admin/requests',
            icon: '',
            routerLink: true,
        },
    ];

    crcActions = [
        {
            title: 'Dashboard',
            link: '/crc/dashboard',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Sites',
            link: '/crc/sites',
            icon: '',
            routerLink: true,
        },
        {
            title: 'Requests',
            link: '/crc/requests',
            icon: '',
            routerLink: true,
        },
    ];
    defaultBase = [];

    defaultHiddenAfterLogin = [
        {
            title: 'Logout',
            link: '/logout',
            icon: 'arrow_back',
            routerLink: true,
        },
    ];

    getIsAuthenticated$ = this.authService.getIsAuthenticated();
    user$ = this.userService.getUser();
    doesUserHaveRole$ = this.userService.doesUserHaveRole();

    constructor(
        private adminPatientServices: AdminPatientServices,
        private requests: RequestMessagingService,
        private authService: AuthService,
        private userService: UserService,
        private accessRequestStateService: AccessRequestStateService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        this.profile = <UserProfile>{
            name: 'Not Logged',
            initial: 'UN',
            link: '',
        };

        this.authService
            .getIsAuthenticated()
            .pipe(
                takeUntil(this.componentDestroyed$),
                filter((isAuthenticated) => isAuthenticated),
                switchMap((val: boolean) => {
                    this.loggedIn = val;
                    if (this.loggedIn) {
                        return combineLatest({ user: this.user$, doesUserHaveRole: this.doesUserHaveRole$ });
                    }
                }),
                switchMap(({ user, doesUserHaveRole }) => {
                    if (!!user) {
                        this.user = user;
                        let initials: string = '';
                        this.user?.name?.split(' ').forEach((el) => {
                            initials += el[0];
                        });

                        this.profile = <UserProfile>{
                            name: this.user?.name,
                            initial: initials,
                            link: 'account',
                        };

                        if (this.user && doesUserHaveRole) {
                            this.actionItems = this.user.isSuperAdmin
                                ? this.superAdminActions
                                : this.user.isAdmin
                                  ? this.adminActions
                                  : this.crcActions;

                            this.homeLink =
                                this.user.isSuperAdmin || this.user.isAdmin ? this.adminHomeLink : this.crcHomeLink;
                        }
                    }
                    return this.setNotifications();
                })
            )
            .subscribe();

        this.eventService.on('notification-reload', () => {
            this.setNotifications().pipe(take(1)).subscribe();
        });
    }

    setSubmittedRequestNotifications(): Observable<number> {
        let portalName: string = 'Requests';

        return this.requests.getAdminRequestCount().pipe(
            takeUntil(this.componentDestroyed$),
            tap((result) => {
                this.submittedRequestCount = result;

                let item = {
                    link: this.user.isAdmin ? '/admin/requests/notification' : '/crc/requests/notification',
                    color: 'red',
                    content: 'Requests submitted: ' + this.submittedRequestCount,
                    portal: portalName,
                };

                this.mergeNotifications(portalName, item, result);
            })
        );
    }

    setPendingAccessRequestNotification(): Observable<number> {
        if (!this.user?.isSuperAdmin) return of(0);

        let portalName: string = 'Access Requests';

        return this.accessRequestStateService.getAccessRequests().pipe(
            takeUntil(this.componentDestroyed$),
            map((accessRequests) => {
                let item = <NotificationItems>{
                    link: 'superadmin/manage/notification',
                    color: 'blue',
                    content: 'Pending Access Requests: ' + accessRequests?.length,
                    portal: portalName,
                };

                this.mergeNotifications(portalName, item, accessRequests?.length);
                return accessRequests?.length;
            })
        );
    }

    mergeNotifications(portalName: string, item: NotificationItems, count: number) {
        const portal = this.notificationItems.find((x) => x.portal === portalName);

        if (portal) {
            const index = this.notificationItems.indexOf(portal);
            this.notificationItems.splice(index, 1);
        }

        if (count > 0) this.notificationItems.push(item);
    }

    setPatientEditRequestNotification(): Observable<number> {
        if (!this.user?.isSuperAdmin) return of(0);

        return this.adminPatientServices.getAllSubmittedPatientEditRequestCount().pipe(
            tap((patientEditRequestCount) => {
                if (patientEditRequestCount > 0) {
                    let item = {
                        link: '/admin/patients/editrequest',
                        color: '#002554',
                        content: 'Edit requests submitted: ' + patientEditRequestCount,
                        portal: 'Patients',
                    };

                    this.notificationItems.push(item);
                }
            })
        );
    }

    logout() {
        this.authService.logout();
    }

    setNotifications(): Observable<number[]> {
        this.notificationItems = [];
        return forkJoin([
            this.setPendingAccessRequestNotification(),
            this.setSubmittedRequestNotifications(),
            this.setPatientEditRequestNotification(),
        ]);
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
