/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { TrainHistoryData } from '@models/history-data/trainHistoryData';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { TrainTravelType } from '@models/request';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, getUniqueObjects, getUniqueStrings } from '@utility/utility';
import { equalOrMoreThanTodayOnlyIfCRCDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment } from 'moment';
import { Observable, startWith, tap } from 'rxjs';
export class SingleTrainTripFormGroup extends PersistentFormGroup<{
    travelType: PersistentFormControl<TrainTravelType>;
    stationOrigin: PersistentFormControl<string>;
    stationDestination: PersistentFormControl<string>;
    departureDate: PersistentFormControl<Moment>;
    returnDate: PersistentFormControl<Moment>;
    specialRequests: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<SingleTrainTripFormGroup>, noTravelAllowed: boolean) {
        const formGroup = new SingleTrainTripFormGroup(
            {
                travelType: new PersistentFormControl(value.travelType, [
                    (control) => {
                        const value = control.value as TrainTravelType;
                        if (
                            Validators.required(control) === null &&
                            (value !== TrainTravelType.NoTravel || noTravelAllowed)
                        )
                            return null;
                        else {
                            return { required: 'Value is required' };
                        }
                    },
                ]),
                stationOrigin: new PersistentFormControl(value.stationOrigin, [
                    Validators.required,
                    maxLengthValidator(70),
                ]),
                stationDestination: new PersistentFormControl(value.stationDestination, [
                    Validators.required,
                    maxLengthValidator(70),
                ]),
                departureDate: new PersistentFormControl(value.departureDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                returnDate: new PersistentFormControl(value.returnDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                specialRequests: new PersistentFormControl(value.specialRequests, [maxLengthValidator(1000)]),
            },
            [
                (control: AbstractControl<SingleTrainTripFormGroup['value']>) => {
                    const value = control.value;
                    if (!value.departureDate || !value.returnDate) return null;
                    return value.departureDate <= value.returnDate
                        ? null
                        : {
                              invalidDates: 'Return date cannot be before departure date.',
                          };
                },
            ]
        );
        return formGroup;
    }
}
@Component({
    selector: 'medpace-train-details',
    templateUrl: './train-details.component.html',
    styleUrls: ['./train-details.component.scss'],
})
export class TrainDetailsComponent {
    public TrainTravelType = TrainTravelType;
    @Input() mode: EditableCardMode;
    @Input() noTravelOption: boolean = true;
    @Input() set formGroup(value: SingleTrainTripFormGroup) {
        this._formGroup = value;

        this.travelTypeChange$ = this.formGroup.controls.travelType.valueChanges.pipe(
            startWith(this.formGroup.getRawValue().travelType),
            tap((travelType) => {
                let controlsToEnable: PersistentFormControl[] = [];
                let controlsToDisable: PersistentFormControl[] = [];
                if (travelType === TrainTravelType.RoundTrip) {
                    controlsToEnable = Object.values(this.formGroup.controls).filter(
                        (control) => control !== this.formGroup.controls.travelType
                    );
                    controlsToDisable = [];
                } else if (travelType === TrainTravelType.OneWay) {
                    controlsToEnable = Object.values(this.formGroup.controls).filter(
                        (control) =>
                            control !== this.formGroup.controls.travelType &&
                            control !== this.formGroup.controls.returnDate
                    );
                    controlsToDisable = [this.formGroup.controls.returnDate];
                } else {
                    // No_Travel
                    controlsToEnable = Object.values(this.formGroup.controls).filter(
                        (control) => control === this.formGroup.controls.travelType
                    );
                    controlsToDisable = Object.values(this.formGroup.controls).filter(
                        (control) => control !== this.formGroup.controls.travelType
                    );
                }
                controlsToEnable.forEach((control) =>
                    control.enable({
                        breakPersistence: true,
                        onlySelf: true,
                        emitEvent: false,
                    })
                );
                controlsToDisable.forEach((control) =>
                    control.disable({
                        persistent: true,
                        onlySelf: true,
                        emitEvent: false,
                    })
                );
            })
        );
    }
    get formGroup() {
        return this._formGroup;
    }
    private _formGroup: SingleTrainTripFormGroup;
    @Input() set historyData(value: TrainHistoryData[]) {
        // every component should create its own MdsOptions so they dont share values
        this.autocompleteOptions = {
            stationOrigin: getUniqueStrings(
                value.filter((data) => !!data.stationOrigin).map((data) => data.stationOrigin)
            ),
            stationDestination: getUniqueStrings(
                value.filter((data) => !!data.stationDestination).map((data) => data.stationDestination)
            ),
            specialRequests: getUniqueObjects(
                value
                    .filter((data) => !!data.specialRequest)
                    .map(
                        (data) =>
                            <MedpaceAutocompleteObject>{ requestDate: data.requestDate, value: data.specialRequest }
                    )
            ),
        };
    }
    protected autocompleteOptions: {
        stationOrigin: string[];
        stationDestination: string[];
        specialRequests: MedpaceAutocompleteObject[];
    };
    protected travelTypeChange$: Observable<any>;
}
