import { CaregiverEditRequestDto } from '@models/caregiverEditRequestDto';
import { CountryViewModel } from '@models/country';
import { Caregiver } from '@models/patient';
import { PersistentFormGroup } from '@utility/persistent-forms';
import { getCountryByCodeOrName } from '@utility/utility';
import { utc } from 'moment';
import { AddressEditFormGroup } from '../address.formgroup';
import { EditFieldFormGroup } from '../edit-field.formgroup';
import { PassportEditFormGroup } from '../passport.formgroup';
export class CaregiverEditFormGroup extends PersistentFormGroup<{
    address: AddressEditFormGroup;
    addressSameAsPatient: EditFieldFormGroup<boolean | null>;
    passport: PassportEditFormGroup;
}> {
    id: number;
    patientId: number;
    constructor(
        public readonly caregiver: Caregiver,
        public readonly editRequest: CaregiverEditRequestDto
    ) {
        super({
            address: new AddressEditFormGroup(),
            addressSameAsPatient: new EditFieldFormGroup<boolean | null>(
                'Address the same as patient',
                null,
                'boolean'
            ),
            passport: new PassportEditFormGroup(),
        });
    }

    getPatientDataModel(model: Caregiver, countries: CountryViewModel[]) {
        const value = this.getRawValue();
        if (value.address.address1.isAccepted) model.address1 = value.address.address1.requestedValue;

        if (value.address.address2.isAccepted) model.address2 = value.address.address2.requestedValue;

        if (value.address.city.isAccepted) model.city = value.address.city.requestedValue;

        if (value.address.country.isAccepted) model.country = value.address.country.requestedValue; // patientdatum.country should be saved as country full name, not the code

        if (value.address.zipcode.isAccepted) model.state = value.address.state.requestedValue;

        if (value.address.zipcode.isAccepted) model.zipcode = value.address.zipcode.requestedValue;

        if (value.address.phone.isAccepted) model.phone = value.address.phone.requestedValue;
        if (value.address.preferredLang.isAccepted) model.preferredLang = value.address.preferredLang.requestedValue;

        if (value.addressSameAsPatient.isAccepted)
            model.addressSameAsPatient = value.addressSameAsPatient.requestedValue;

        if (value.passport.passportNum.isAccepted) model.passportNum = value.passport.passportNum.requestedValue;

        if (value.passport.passportCountry.isAccepted) {
            const passportCountryFullName = value.passport.passportCountry.requestedValue;
            if (passportCountryFullName) {
                const passportCountry = getCountryByCodeOrName(passportCountryFullName, countries);
                if (!passportCountry) throw new Error('Missing country for caregiver: ' + model.id);
                model.passportCountry = passportCountry.countryCode; // country code, not full name
            } else model.passportCountry = '';
        }

        if (value.passport.passportIssue.isAccepted)
            model.passportIssue = value.passport.passportIssue.requestedValue?.toISOString().split('T')[0] ?? null;

        if (value.passport.passportExpiration.isAccepted)
            model.passportExpiration =
                value.passport.passportExpiration.requestedValue?.toISOString().split('T')[0] ?? null;

        return model;
    }

    disableIfUnchanged() {
        const controls = Object.values(this.controls);
        controls.forEach((control) => {
            control.disableIfUnchanged();
        });

        if (!controls.some((control) => control.enabled)) this.disable({ persistent: true });
        else this.enable({ breakPersistence: true });
    }
    setRequestedValue(editRequest: CaregiverEditRequestDto, countries: CountryViewModel[]) {
        const address = this.controls.address.controls;
        address.address1.setValue({
            requestedValue: editRequest.address1 ?? null,
            isAccepted: false,
        });
        address.address2.setValue({
            requestedValue: editRequest.address2 ?? null,
            isAccepted: false,
        });
        address.city.setValue({
            requestedValue: editRequest.city ?? null,
            isAccepted: false,
        });
        address.country.setValue({
            requestedValue: editRequest.country
                ? (getCountryByCodeOrName(editRequest.country, countries)?.viewValue ?? null)
                : null,
            isAccepted: false,
        });
        address.state.setValue({
            requestedValue: editRequest.state ?? null,
            isAccepted: false,
        });
        address.zipcode.setValue({
            requestedValue: editRequest.zipcode ?? null,
            isAccepted: false,
        });
        this.controls.addressSameAsPatient.setValue({
            requestedValue: editRequest.addressSameAsPatient ?? null,
            isAccepted: false,
        });

        address.phone.setValue({
            requestedValue: editRequest.phone ?? null,
            isAccepted: false,
        });
        address.preferredLang.setValue({
            requestedValue: editRequest.preferredLang ?? null,
            isAccepted: false,
        });

        const international = this.controls.passport.controls;
        international.passportNum.setValue({
            requestedValue: editRequest.passportNum ?? null,
            isAccepted: false,
        });

        if (editRequest.passportCountry === '') {
            international.passportCountry.setValue({
                requestedValue: null,
                isAccepted: false,
            });
            international.passportCountry.hasBeenCleared = true;
        } else {
            international.passportCountry.setValue({
                requestedValue: editRequest.passportCountry
                    ? (getCountryByCodeOrName(editRequest.passportCountry, countries)?.viewValue ?? null)
                    : null,
                isAccepted: false,
            });
        }
        if (editRequest.passportIssue === '0001-01-01') {
            international.passportIssue.setValue({
                requestedValue: null,
                isAccepted: false,
            });
            international.passportIssue.hasBeenCleared = true;
        } else {
            international.passportIssue.setValue({
                requestedValue: editRequest.passportIssue ? utc(editRequest.passportIssue) : null,
                isAccepted: false,
            });
        }

        if (editRequest.passportExpiration === '0001-01-01') {
            international.passportExpiration.setValue({
                requestedValue: null,
                isAccepted: false,
            });
            international.passportExpiration.hasBeenCleared = true;
        } else {
            international.passportExpiration.setValue({
                requestedValue: editRequest.passportExpiration ? utc(editRequest.passportExpiration) : null,
                isAccepted: false,
            });
        }
    }
    setCurrentValue(caregiver: Caregiver) {
        const address = this.controls.address.controls;
        address.address1.currentValue = caregiver.address1;
        address.address2.currentValue = caregiver.address2;
        address.city.currentValue = caregiver.city;
        address.country.currentValue = caregiver.country;
        address.state.currentValue = caregiver.state;
        address.zipcode.currentValue = caregiver.zipcode;
        address.phone.currentValue = caregiver.phone;
        address.preferredLang.currentValue = caregiver.preferredLang;
        this.controls.addressSameAsPatient.currentValue = caregiver.addressSameAsPatient;

        const passport = this.controls.passport.controls;
        passport.passportNum.currentValue = caregiver.passportNum;
        passport.passportCountry.currentValue = caregiver.passportCountry;
        passport.passportIssue.currentValue = caregiver.passportIssue ? utc(caregiver.passportIssue) : null;
        passport.passportExpiration.currentValue = caregiver.passportExpiration
            ? utc(caregiver.passportExpiration)
            : null;
    }
}
