import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccessGroupEnum } from '@models/accessGroup';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ForcedLoginAuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isDoneLoading$.pipe(
            filter((isDone) => isDone),
            switchMap((_) => {
                const rolesThatHaveAccessToRoute: AccessGroupEnum[] = route.data['accessRestriction'];
                const travelPortalGroupName = sessionStorage.getItem('grp');
                const accessGroup = this.getAccessGroupFromTravelPortalGroupName(travelPortalGroupName);

                const userGroupIsPermittedToViewRoute =
                    !rolesThatHaveAccessToRoute || rolesThatHaveAccessToRoute?.includes(accessGroup);

                const authServiceHasValidTokenForUser =
                    this.authService.hasValidAccessToken && this.authService.hasValidIdToken;
                const isAuthorized = authServiceHasValidTokenForUser && userGroupIsPermittedToViewRoute;

                if (!authServiceHasValidTokenForUser) {
                    // Store the original URL so when users signs-in in B2C and is redirected back to app,
                    // we can move them to the page they originally wanted to visit.
                    // Do it only if user is not signed in yet (but not when he is signed and not allowed) so we
                    // don't end up in a redirect loop.
                    localStorage.setItem('redirectUrl', state.url);
                }

                return of(isAuthorized);
            }),
            tap((isAuthorized) => {
                if (!isAuthorized) {
                    this.authService.login(state.url);
                }
            })
        );
    }

    getAccessGroupFromTravelPortalGroupName(travelPortalGroupName: string): AccessGroupEnum | null {
        switch (travelPortalGroupName) {
            case 'Medpace_TravelPortal_SuperAdmins':
                return AccessGroupEnum.SuperAdmin;
            case 'Medpace_TravelPortal_Admins':
                return AccessGroupEnum.Admin;
            case 'Medpace_TravelPortal_CRC_Users':
                return AccessGroupEnum.CRC;
            default:
                return null;
        }
    }
}
