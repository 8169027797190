import * as moment from 'moment';
import { Moment, MomentFormatSpecification, MomentInput, utc } from 'moment';

declare module 'moment' {
    interface Moment {
        copyDateFrom(date: Moment): Moment;
        copyTimeFrom(time: Moment): Moment;
    }
}
moment.prototype.copyDateFrom = function (this: Moment, date: Moment): Moment {
    return this.year(date.year()).month(date.month()).date(date.date());
};
moment.prototype.copyTimeFrom = function (this: Moment, time: Moment): Moment {
    return this.hour(time.hour()).minute(time.minute()).second(time.second()).millisecond(time.millisecond());
};
export function safeUTC(
    inp?: MomentInput,
    format?: MomentFormatSpecification,
    language?: string,
    strict?: boolean
): Moment {
    if (inp === null || inp === '') return null;
    else if (inp === undefined) return undefined;
    else return utc(inp, format, language, strict);
}
