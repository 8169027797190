import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CSP_NONCE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MedpaceDeleteCaregiverModalComponent } from '@app/admin/manage/modals/delete-caregiver-modal/delete-caregiver-modal.component';
import { MedpaceDeleteInfoCaregiverModalComponent } from '@app/admin/manage/modals/delete-info-caregiver-modal/delete-info-caregiver-modal.component';
import { MedpaceDraggableListWithCheckboxesComponent } from '@app/components/atoms/medpace-draggable-list-with-checkboxes/medpace-draggable-list-with-checkboxes.component';
import { MedpaceDraggableListComponent } from '@app/components/atoms/medpace-draggable-list/medpace-draggable-list.component';
import { DecimalNumberDirective } from '@app/directives/decimal-number/decimal-number.directive';
import { MarkAsteriskDirective } from '@app/directives/mark-asterisk/mark-asterisk.directive';
import { AnnouncementModalComponent } from '@components/atoms/announcement-modal/announcement-modal/announcement-modal.component';
import { HistoryTimelineComponent } from '@components/atoms/history-timeline/history-timeline.component';
import { AnnouncementsComponent } from '@components/molecules/forms/announcements/announcements/announcements.component';
import { CreateEditAnnouncementDialogComponent } from '@components/molecules/forms/announcements/create-edit-announcement-dialog/create-edit-announcement-dialog.component';
import { InformationAccuracyModalComponent } from '@components/molecules/forms/patient-payment-card/bank-details/information-accuracy-modal/information-accuracy-modal.component';
import { CaregiverEditRequestComponent } from '@components/molecules/modals/patient-edit-request-modal/caregiver-edit-request/caregiver-edit-request.component';
import { PatientEditRequestModalComponent } from '@components/molecules/modals/patient-edit-request-modal/patient-edit-request-modal.component';
import { PatientEditRequestComponent } from '@components/molecules/modals/patient-edit-request-modal/patient-edit-request/patient-edit-request.component';
import { PatientTravelPrefsEditRequestComponent } from '@components/molecules/modals/patient-edit-request-modal/patient-travel-prefs-edit-request/patient-travel-prefs-edit-request.component';
import { SiteClintrakChangesModalComponent } from '@components/molecules/modals/site-clintrak-changes-modal/site-clintrak-changes-modal.component';
import { StudyClintrakChangesModalComponent } from '@components/molecules/modals/study-clintrak-changes-modal/study-clintrak-changes-modal.component';
import { environment } from '@environments/environment';
import { AdminUserRepository } from '@repositories/admin/admin-user.repository';
import { UserClient } from '@repositories/admin/user-client.repository';
import { AnnouncementRepository } from '@repositories/announcement/announcement.repository';
import { CaregiverRepository } from '@repositories/caregiver/caregiver.repository';
import { GetThereRepository } from '@repositories/getthere/getthere.repository';
import { AccessGroupClient } from '@repositories/group/access-group-client.repository';
import { AnnouncementService } from '@services/announcement/announcement.service';
import { CaregiverService } from '@services/caregiver/caregiver.service';
import { EnvConfigurationService } from '@services/env/env-configuration.service';
import { ReimbursementRequestStateService } from '@services/state-management/reimbursement-request-state.service';
import { StipendRequestStateService } from '@services/state-management/stipend-request-state.service';
import { TimeOptionsStateService } from '@services/state-management/time-options-state.service';
import { TravelRequestStateService } from '@services/state-management/travel-request-state.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { EditRequestTableComponent } from 'src/app/components/molecules/modals/patient-edit-request-modal/edit-request-table/edit-request-table.component';
import { DisableMouseScrollBehaviorDirective } from 'src/app/directives/disable-mouse-scroll-behavior/disable-mouse-scroll-behavior.directive';
import { ScrollToInvalidControlOnSubmitDirective } from 'src/app/directives/scroll-to-invalid-control-on-submit/scroll-to-invalid-control-on-submit.directive';
import { SiteService } from 'src/app/services/site/site.service';
import { nonceFactory } from 'src/main';
import { ReportBugModalComponent } from '../app/admin/manage/modals/report-bug/report-bug.component';
import { LocalizationRegisterModule } from '../app/shared/localization-register.module';
import { MaterialModule } from '../app/shared/material.module';
import { SuperAdminDirective } from '././directives/super-admin/super-admin.directive';
import { API_BASE_URL } from './API_BASE_URL';
import { MedpaceAccessRequestActionModalComponent } from './admin/manage/modals/access-request-action-modal/access-request-action-modal.component';
import { ChangePasswordModalComponent } from './admin/manage/modals/change-password-modal/change-password-modal.component';
import { DownloadAttachmentModalComponent } from './admin/manage/modals/download-attachment-modal/download-attachment-modal.component';
import { LoadingComponent } from './admin/manage/modals/loading-component/loading.component';
import { MedpacePatientChangeStatusModalComponent } from './admin/manage/modals/patient-change-status-modal.component';
import { MedpacePatientTransferToAnotherSiteComponent } from './admin/manage/modals/patient-transfer-to-another-site/patient-transfer-to-another-site.component';
import { MedpaceSiteChangeStatusModalComponent } from './admin/manage/modals/site-change-status-modal/site-change-status-modal.component';
import { CreateUserManagementModalComponent } from './admin/manage/modals/user-management/create-user-management-modal/create-user-management-modal.component';
import { SendInvitationModalComponent } from './admin/manage/modals/user-management/send-invitation-modal/send-invitation-modal.component';
import { UpdateUserManagementModalComponent } from './admin/manage/modals/user-management/update-user-management-modal/update-user-management-modal.component';
import { MedpaceUserOperationAlertModalComponent } from './admin/manage/modals/user-operation-alert-modal/user-operation-alert-modal.component';
import { AppRoutingModule, registeredComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/atoms/alert/alert.component';
import { MedpaceDataRedirectorComponent } from './components/atoms/data-redirector/data-redirector.component';
import { LoadingCardComponent } from './components/atoms/loading-card/loading-card.component';
import { DndDirective } from './components/atoms/logo-upload/dnd.directive';
import {
    MedpaceCardComponent,
    MedpaceCardContentDirective,
    MedpaceCardHeaderDirective,
} from './components/atoms/medpace-card/medpace-card.component';
import { MedpaceChipListComponent } from './components/atoms/medpace-chip-list/medpace-chip-list.component';
import { MedpaceDatepickerComponent } from './components/atoms/medpace-datepicker/medpace-datepicker.component';
import {
    MedpaceEditableCardComponent,
    MedpaceEditableCardEditModeContentDirective,
    MedpaceEditableCardHeaderDirective,
    MedpaceEditableCardViewModeContentDirective,
} from './components/atoms/medpace-editable-card/medpace-editable-card.component';
import { RequestHistoryPostComponent } from './components/atoms/request-history/request-history-post/request-history-post.component';
import { RequestHistoryComponent } from './components/atoms/request-history/request-history.component';
import { MedpaceTextareaAutocompleteComponent } from './components/atoms/textarea-autocomplete/medpace-textarea-autocomplete.component';
import { AccessRequestActionsCardComponent } from './components/molecules/access-request-actions-card/access-request-actions-card.component';
import { CreateAccessRequestCardComponent } from './components/molecules/create-access-request-card/create-access-request-card.component';
import { CRCCard2Component } from './components/molecules/forms/crc-card2/crc-card2.component';
import { MedpaceCreateEditRequestExpenseDetailsComponent } from './components/molecules/forms/medpace-create-edit-request-expense-details/medpace-create-edit-request-expense-details.component';
import { MedpaceCreateRequestVisitDetailsComponent } from './components/molecules/forms/medpace-create-request-visit-details/medpace-create-request-visit-details.component';
import { MedpaceEditRequestVisitDetailsComponent } from './components/molecules/forms/medpace-edit-request-visit-details/medpace-edit-request-visit-details.component';
import { TransferDataComponent } from './components/molecules/forms/patient-travel-card/ground/transfer-data/transfer-data.component';
import { ReimbursementRatesUploadComponent } from './components/molecules/forms/reimbursement-rates-upload/reimbursement-rates-upload.component';
import { StudyTierComponent } from './components/molecules/forms/study-tier/study-tier.component';
import { CarRentalPreferencesComponent } from './components/molecules/forms/travel-card/car-rental-request-details/car-rental-preferences/car-rental-preferences.component';
import { CarRentalRequestDetailsComponent } from './components/molecules/forms/travel-card/car-rental-request-details/car-rental-request-details.component';
import { FlightDetailsComponent } from './components/molecules/forms/travel-card/flight-request-details/flight-details/flight-details.component';
import { FlightPreferencesComponent } from './components/molecules/forms/travel-card/flight-request-details/flight-preferences/flight-preferences.component';
import { FlightRequestDetailsComponent } from './components/molecules/forms/travel-card/flight-request-details/flight-request-details.component';
import { InternationalPreferencesComponent } from './components/molecules/forms/travel-card/international-preferences/international-preferences.component';
import { PassportInfoComponent } from './components/molecules/forms/travel-card/international-preferences/passport-info/passport-info.component';
import { LodgingPreferencesComponent } from './components/molecules/forms/travel-card/lodging-request-details/lodging-preferences/lodging-preferences.component';
import { LodgingRequestDetailsComponent } from './components/molecules/forms/travel-card/lodging-request-details/lodging-request-details.component';
import { OtherRequestDetailsComponent } from './components/molecules/forms/travel-card/other-request-details/other-request-details.component';
import { TrainDetailsComponent } from './components/molecules/forms/travel-card/train-request-details/train-details/train-details.component';
import { TrainPreferencesComponent } from './components/molecules/forms/travel-card/train-request-details/train-preferences/train-preferences.component';
import { TrainRequestDetailsComponent } from './components/molecules/forms/travel-card/train-request-details/train-request-details.component';
import { TransactionDetailsComponent } from './components/molecules/forms/travel-card/transaction-details/transaction-details.component';
import { TransferPreferencesComponent } from './components/molecules/forms/travel-card/transfer-request-details/transfer-preferences/transfer-preferences/transfer-preferences.component';
import { TransferRequestDetailsComponent } from './components/molecules/forms/travel-card/transfer-request-details/transfer-request-details.component';
import { TravelCardComponent } from './components/molecules/forms/travel-card/travel-card.component';
import { DisplayErrorModalComponent } from './components/molecules/modals/display-error-modal/display-error-modal.component';
import { MedpaceMessageModalComponent } from './components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { MedpaceVisitMappingModalComponent } from './components/molecules/modals/medpace-visit-mapping-modal/medpace-visit-mapping-modal.component';
import { SiteRegisterModalComponent } from './components/molecules/modals/site-register-modal/site-register-modal.component';
import { PatientEditRequestTableComponent } from './components/molecules/patient-edit-request-table/patient-edit-request-table.component';
import { UserPersonalInfoCardComponent } from './components/molecules/user-personal-info-card/user-personal-info-card.component';
import { GlobalContactNumbersComponent } from './components/organisms/global-contact-numbers/global-contact-numbers.component';
import { SimpleCardComponent } from './components/organisms/simple-card/simple-card.component';
import { ErrorSnackbarComponent } from './components/organisms/snackBar/error-snackbar/error-snackbar.component';
import { InfoSnackbarComponent } from './components/organisms/snackBar/info-snackbar/info-snackbar.component';
import { AdminDirective } from './directives/admin/admin.directive';
import { ExpansionFormErrorHighlighterDirective } from './directives/expansion-form-error-highlighter.directive';
import { FormErrorHighlighterDirective } from './directives/form-error-highlighter.directive';
import { ApiModule } from './generated-api/api.module';
import { BASE_PATH } from './generated-api/variables';
import { httpInterceptorProviders } from './http-interceptors/index';
import { dateFormatPipe } from './pipes/datepipe';
import { AccessRequestRepository } from './repositories/accessRequest/access-request.repository';
import { AdminPatientRepository } from './repositories/admin/admin-patient.repository';
import { AdminRequestRepository } from './repositories/admin/admin-request.repository';
import { AdminSiteRepository } from './repositories/admin/admin-site.repository';
import { AdminStudyRepository } from './repositories/admin/admin-study.repository';
import { CRCSiteRepository } from './repositories/crc/crc-sites.repository';
import { CRCStudyRepository } from './repositories/crc/crc-studies.repository';
import { FormDataRepository } from './repositories/form-data.repository';
import { GroupRepository } from './repositories/group/group.repository';
import { ReportRepository } from './repositories/reports/reports.repository';
import { ReimbursementRatesUploadDialogComponent } from './request/modals/reimbursement-rates-upload/reimbursement-rates-upload-dialog/reimbursement-rates-upload-dialog.component';
import { AccessRequestService } from './services/access-request/access-request.service';
import { AdminPatientServices } from './services/admin/admin-patient.service';
import { AdminRequestServices } from './services/admin/admin-request.service';
import { AdminSiteServices } from './services/admin/admin-site.service';
import { AdminStudyServices } from './services/admin/admin-study.sevice';
import { AttachmentSizeService } from './services/attachment-size/attachment-size.service';
import { AuthService } from './services/auth/auth.service';
import { CRCSiteServices } from './services/crc/crc-site.service';
import { CRCStudyServices } from './services/crc/crc-study.service';
import { ErrorHandlingModule } from './services/error-handling/error-handling.module';
import { FormDataService } from './services/form-data/form-data.service';
import { GetThereService } from './services/getthere/getthere.service';
import { GoogleMapsService } from './services/google-maps/google-maps.service';
import { GroupService } from './services/group/group.service';
import { ForcedLoginAuthGuard } from './services/guards/auth.guard';
import { RequestMessagingService } from './services/messaging/request-messaging.service';
import { PatientStatusService } from './services/patient-status/patient-status.service';
import { ReportServices } from './services/reports/reports.service';
import { RequestStatusService } from './services/request-status/request-status.service';
import { AccessRequestStateService } from './services/state-management/access-request-state.service';
import { PatientStateService } from './services/state-management/patient-state.service';
import { RequestStateService } from './services/state-management/request-state.service';
import { SiteStateService } from './services/state-management/site-state.service';
import { StudyStateService } from './services/state-management/study-state.service';
import { UserService } from './services/user/user.service';
import { XLSXService } from './services/xlsx/xlsx.service';
import { MdsModule } from './shared/mds.module';
import { NoRoleModalComponent } from './study/modals/no-role-modal/no-role-modal.component';
@NgModule({
    declarations: [
        AppComponent,
        DndDirective,
        registeredComponents,
        MedpaceMessageModalComponent,
        MedpaceVisitMappingModalComponent,
        DecimalNumberDirective,
        MarkAsteriskDirective,
        ReimbursementRatesUploadComponent,
        ReimbursementRatesUploadDialogComponent,
        MedpaceDeleteCaregiverModalComponent,
        LoadingComponent,
        LoadingCardComponent,
        MedpaceSiteChangeStatusModalComponent,
        SiteRegisterModalComponent,
        StudyTierComponent,
        MedpaceDataRedirectorComponent,
        UserPersonalInfoCardComponent,
        ErrorSnackbarComponent,
        InfoSnackbarComponent,
        SuperAdminDirective,
        AdminDirective,
        MedpaceCardComponent,
        MedpaceCardHeaderDirective,
        MedpaceCardContentDirective,
        MedpaceEditableCardComponent,
        MedpaceAccessRequestActionModalComponent,
        MedpaceUserOperationAlertModalComponent,
        MedpaceEditableCardEditModeContentDirective,
        MedpaceEditableCardViewModeContentDirective,
        MedpaceEditableCardHeaderDirective,
        MedpaceCreateRequestVisitDetailsComponent,
        MedpaceEditRequestVisitDetailsComponent,
        MedpacePatientChangeStatusModalComponent,
        NoRoleModalComponent,
        dateFormatPipe,
        DownloadAttachmentModalComponent,
        MedpaceCreateEditRequestExpenseDetailsComponent,
        AlertComponent,
        MedpaceChipListComponent,
        GlobalContactNumbersComponent,
        UpdateUserManagementModalComponent,
        CreateUserManagementModalComponent,
        SendInvitationModalComponent,
        AccessRequestActionsCardComponent,
        CRCCard2Component,
        ChangePasswordModalComponent,
        MedpaceTextareaAutocompleteComponent,
        TransferDataComponent,
        DisableMouseScrollBehaviorDirective,
        ScrollToInvalidControlOnSubmitDirective,
        AnnouncementModalComponent,
        AnnouncementsComponent,
        CreateEditAnnouncementDialogComponent,
        MedpaceDatepickerComponent,
        TravelCardComponent,
        FlightRequestDetailsComponent,
        FlightDetailsComponent,
        TrainRequestDetailsComponent,
        TrainDetailsComponent,
        LodgingRequestDetailsComponent,
        CarRentalRequestDetailsComponent,
        TransferRequestDetailsComponent,
        OtherRequestDetailsComponent,
        TransactionDetailsComponent,
        FlightPreferencesComponent,
        TrainPreferencesComponent,
        LodgingPreferencesComponent,
        CreateAccessRequestCardComponent,
        CarRentalPreferencesComponent,
        InternationalPreferencesComponent,
        SimpleCardComponent,
        FormErrorHighlighterDirective,
        ExpansionFormErrorHighlighterDirective,
        MedpaceDeleteInfoCaregiverModalComponent,
        TransferPreferencesComponent,
        MedpaceDraggableListComponent,
        MedpaceDraggableListWithCheckboxesComponent,
        PassportInfoComponent,
        InformationAccuracyModalComponent,
        RequestHistoryComponent,
        RequestHistoryPostComponent,
        MedpacePatientTransferToAnotherSiteComponent,
        PatientEditRequestTableComponent,
        EditRequestTableComponent,
        PatientEditRequestModalComponent,
        PatientEditRequestComponent,
        PatientTravelPrefsEditRequestComponent,
        CaregiverEditRequestComponent,
        StudyClintrakChangesModalComponent,
        SiteClintrakChangesModalComponent,
        HistoryTimelineComponent,
        ReportBugModalComponent,
        DisplayErrorModalComponent,
    ],
    imports: [
        ApiModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [`${environment.baseUrl}/api`],
                sendAccessToken: true,
            },
        }),
        BrowserAnimationsModule,
        MaterialModule,
        MdsModule,
        MatExpansionModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatToolbarModule,
        ErrorHandlingModule,
        GoogleMapsModule,
        LocalizationRegisterModule,
        MatListModule,
    ],
    providers: [
        AuthService,
        ForcedLoginAuthGuard,
        GroupService,
        GroupRepository,
        AdminSiteRepository,
        AdminSiteServices,
        AdminStudyRepository,
        AdminStudyServices,
        AdminPatientRepository,
        AdminPatientServices,
        AdminUserRepository,
        FormDataRepository,
        FormDataService,
        AdminRequestRepository,
        AdminRequestServices,
        RequestMessagingService,
        AccessRequestService,
        AccessRequestRepository,
        CRCStudyRepository,
        CRCStudyServices,
        CRCSiteRepository,
        CRCSiteServices,
        ReportServices,
        ReportRepository,
        SiteService,
        httpInterceptorProviders,
        RequestStatusService,
        PatientStatusService,
        AttachmentSizeService,
        RequestStateService,
        StudyStateService,
        SiteStateService,
        PatientStateService,
        AccessRequestStateService,
        UserService,
        DatePipe,
        XLSXService,
        UserClient,
        AccessGroupClient,
        dateFormatPipe,
        { provide: API_BASE_URL, useValue: environment.baseUrl },
        { provide: BASE_PATH, useValue: environment.baseUrl },
        GoogleMapsService,
        GetThereService,
        GetThereRepository,
        { provide: LOCALE_ID, useValue: navigator.language },
        TravelRequestStateService,
        StipendRequestStateService,
        ReimbursementRequestStateService,
        AnnouncementRepository,
        AnnouncementService,
        CaregiverRepository,
        CaregiverService,
        TimeOptionsStateService,
        { provide: CSP_NONCE, useFactory: nonceFactory, deps: [EnvConfigurationService] },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
