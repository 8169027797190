<medpace-table
    *ngIf="{ announcementsVM: announcementTableModels$ | async } as vm"
    [attr.data-cy]="'announcementsTable'"
    (rowClickEvent)="rowClick($event)"
    [columns]="columns"
    [data]="vm.announcementsVM ?? undefined"
    [fieldMap]="columnMap"
    [ifFiltering]="false"
    [isSearching]="true"
    [isCard]="false"
    title="Announcements"
></medpace-table>
<div class="flex start">
    <mds-button
        [id]="'announcement-create-btn'"
        [attr.data-cy]="'createAnnouncementButton'"
        [variant]="'primary'"
        [label]="'Create Announcement'"
        (clickEvent)="createAnnouncement()"
    ></mds-button>
</div>
