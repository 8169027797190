import { ValidatorFn } from '@angular/forms';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
export type EditRequestDisplayMode = 'string' | 'date' | 'country' | 'boolean';
export class EditFieldFormGroup<T> extends PersistentFormGroup<{
    requestedValue: PersistentFormControl<T | null>;
    isAccepted: PersistentFormControl<boolean>;
}> {
    hasChanged = false;
    hasBeenCleared = false;
    constructor(
        public readonly name: string,
        public currentValue: T | null,
        public readonly displayMode: EditRequestDisplayMode,
        validators?: ValidatorFn[]
    ) {
        super({
            requestedValue: new PersistentFormControl<T | null>(null, validators),
            isAccepted: new PersistentFormControl<boolean>(false, { nonNullable: true }),
        });
    }

    disableIfUnchanged() {
        this.hasChanged = this.value.requestedValue !== null && this.value.requestedValue !== undefined;
        if (this.hasChanged || this.hasBeenCleared) {
            this.enable({ breakPersistence: true });
            this.patchValue({ isAccepted: true });
            // by default accept all that changed
        } else {
            this.disable({ persistent: true });
            this.patchValue({ isAccepted: false });
        }
    }
}
