<mds-dialog>
    <mds-dialog-content>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <h5>
                        Are you sure you want to delete this patient? If you proceed, all PHI associated with this
                        patient will be purged.
                    </h5>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            [id]="'yesBtn'"
            (clickEvent)="deleteConfirm()"
            variant="primary"
            label="Yes"
            width="auto"
        ></mds-button>
        <mds-button
            [id]="'cancelBtn'"
            (clickEvent)="closeDialog()"
            variant="outline"
            label="Cancel"
            width="auto"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
