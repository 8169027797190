<div class="o-page">
    <div class="o-grid__item">
        <medpace-table
            [columns]="columns"
            [data]="contactNumbers"
            [fieldMap]="columnMap"
            [ifFiltering]="false"
            [isSearching]="true"
            title="Global Contact Numbers"
        ></medpace-table>
    </div>
</div>
