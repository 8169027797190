/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { CarServiceHistoryData } from '@models/history-data/carServiceHistoryData';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { PersistentFormArray, PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, getUniqueObjects, MdsOptionGeneric } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';
import { TransferDataFormGroup } from '../../patient-travel-card/ground/transfer-data/transfer-data.component';
export class TransferRequestDetailsFormGroup extends PersistentFormGroup<{
    transfers: PersistentFormArray<TransferDataFormGroup>;
    specialRequests: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<TransferRequestDetailsFormGroup>): TransferRequestDetailsFormGroup {
        return new TransferRequestDetailsFormGroup({
            transfers: new PersistentFormArray(
                value.transfers.map((transfer) => TransferDataFormGroup.create(transfer)),
                [
                    (control: AbstractControl) => {
                        const array = control as FormArray;
                        if (array.length === 0)
                            return {
                                requiredAtLeastOneTransfer: 'You must add at least one transfer.',
                            };

                        return null;
                    },
                ]
            ),
            specialRequests: new PersistentFormControl(value.specialRequests, [maxLengthValidator(1000)]),
        });
    }
}
@Component({
    selector: 'medpace-transfer-request-details',
    templateUrl: './transfer-request-details.component.html',
    styleUrls: ['./transfer-request-details.component.scss'],
})
export class TransferRequestDetailsComponent {
    @Input() mode: EditableCardMode;
    @Input() formGroup: TransferRequestDetailsFormGroup;
    @Input() timeOptions: MdsOptionGeneric<number>[];
    @Input() set historyData(value: CarServiceHistoryData[]) {
        // every component should create its own MdsOptions so they dont share values
        this.autocompleteOptions = {
            specialRequests: getUniqueObjects(
                value
                    .filter((data) => !!data.specialRequest)
                    .map(
                        (data) =>
                            <MedpaceAutocompleteObject>{ requestDate: data.requestDate, value: data.specialRequest }
                    )
            ),
        };
    }
    protected autocompleteOptions: {
        specialRequests: MedpaceAutocompleteObject[];
    };
    protected addTransfer() {
        this.formGroup.controls.transfers.push(
            TransferDataFormGroup.create({
                pickupAddress: '',
                dropoffAddress: '',
                pickupDate: null,
                pickupTime: null,
            })
        );
    }
    protected removeTransfer(index: number) {
        this.formGroup.controls.transfers.removeAt(index);
    }
}
