import { Component, computed, inject, input, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryViewModel } from '@models/country';
import { Patient } from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { CountryStateService } from '@services/state-management/country-state.service';
import { tap } from 'rxjs';
import { PatientTravelPrefsEditRequestFormGroup } from './patient-travel-prefs-edit-request.formgroup';
@Component({
    selector: 'medpace-patient-travel-prefs-edit-request',
    templateUrl: './patient-travel-prefs-edit-request.component.html',
})
export class PatientTravelPrefsEditRequestComponent {
    formGroup = input.required<PatientTravelPrefsEditRequestFormGroup>();
    patient = input.required<Patient>();
    editRequest = input.required<PatientEditRequestDto>();
    countryStateService = inject(CountryStateService);
    countries = signal<CountryViewModel[]>([]);
    countries$ = this.countryStateService.getCountries().pipe(
        tap((countries) => {
            this.countries.set(countries);
        })
    );
    isAnyControlEnabled(formGroup: FormGroup) {
        return Object.values(formGroup.controls).some((control) => control.enabled);
    }
    formGroupWithSetValues = computed(() => {
        const formGroup = this.formGroup();
        const patient = this.patient();
        const editRequest = this.editRequest();
        const countries = this.countries();
        if (!formGroup || !patient || !editRequest || countries.length === 0) return;
        formGroup.setCurrentValue(patient);
        formGroup.setRequestedValue(editRequest, countries);
        formGroup.disableIfUnchanged();
        return formGroup;
    });
    flightEditFields = computed(() => {
        const formGroupWithSetValues = this.formGroupWithSetValues();
        if (!formGroupWithSetValues) return;

        const fields = Object.values(formGroupWithSetValues.controls.flight.controls);

        return fields.filter((control) => control.enabled);
    });

    trainEditFields = computed(() => {
        const formGroupWithSetValues = this.formGroupWithSetValues();
        if (!formGroupWithSetValues) return;

        const fields = Object.values(formGroupWithSetValues.controls.train.controls);

        return fields.filter((control) => control.enabled);
    });

    lodgingEditFields = computed(() => {
        const formGroupWithSetValues = this.formGroupWithSetValues();
        if (!formGroupWithSetValues) return;

        const fields = Object.values(formGroupWithSetValues.controls.lodging.controls);

        return fields.filter((control) => control.enabled);
    });

    internationalEditFields = computed(() => {
        const formGroupWithSetValues = this.formGroupWithSetValues();
        if (!formGroupWithSetValues) return;

        const fields = Object.values(formGroupWithSetValues.controls.international.controls);

        return fields.filter((control) => control.enabled);
    });

    carRentalEditFields = computed(() => {
        const formGroupWithSetValues = this.formGroupWithSetValues();
        if (!formGroupWithSetValues) return;

        const fields = Object.values(formGroupWithSetValues.controls.carRental.controls);

        return fields.filter((control) => control.enabled);
    });

    carServiceEditFields = computed(() => {
        const formGroupWithSetValues = this.formGroupWithSetValues();
        if (!formGroupWithSetValues) return;

        const fields = Object.values(formGroupWithSetValues.controls.carService.controls);

        return fields.filter((control) => control.enabled);
    });
}
