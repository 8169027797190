import { CountryViewModel } from '@models/country';
import { Patient, PatientDataModel } from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { PersistentFormGroup } from '@utility/persistent-forms';
import { getCountryByCodeOrName } from '@utility/utility';
import { AddressEditFormGroup } from '../address.formgroup';
export class PatientEditRequestFormGroup extends PersistentFormGroup<{
    address: AddressEditFormGroup;
}> {
    constructor(
        public readonly patient: Patient,
        public readonly editRequest: PatientEditRequestDto
    ) {
        super({
            address: new AddressEditFormGroup(),
        });
    }
    getPatientDataModel(model: PatientDataModel, countries: CountryViewModel[]) {
        const value = this.getRawValue();
        if (value.address.address1.isAccepted) model.patientDatum.address1 = value.address.address1.requestedValue;

        if (value.address.address2.isAccepted) model.patientDatum.address2 = value.address.address2.requestedValue;

        if (value.address.city.isAccepted) model.patientDatum.city = value.address.city.requestedValue;

        if (value.address.country.isAccepted) model.patientDatum.country = value.address.country.requestedValue; // patientdatum.country should be saved as country full name, not the code

        if (value.address.zipcode.isAccepted) model.patientDatum.state = value.address.state.requestedValue;

        if (value.address.zipcode.isAccepted) model.patientDatum.zipcode = value.address.zipcode.requestedValue;

        if (value.address.phone.isAccepted) model.patientDatum.phone = value.address.phone.requestedValue;
        if (value.address.preferredLang.isAccepted)
            model.patientDatum.preferredLang = value.address.preferredLang.requestedValue;

        return model;
    }
    disableIfUnchanged() {
        const controls = Object.values(this.controls);
        controls.forEach((control) => {
            control.disableIfUnchanged();
        });

        if (!controls.some((control) => control.enabled)) this.disable({ persistent: true });
        else this.enable({ breakPersistence: true });
    }
    setRequestedValue(editRequest: PatientEditRequestDto, countries: CountryViewModel[]) {
        const address = this.controls.address.controls;
        address.address1.setValue({
            requestedValue: editRequest.address1 ?? null,
            isAccepted: false,
        });
        address.address2.setValue({
            requestedValue: editRequest.address2 ?? null,
            isAccepted: false,
        });
        address.city.setValue({
            requestedValue: editRequest.city ?? null,
            isAccepted: false,
        });
        address.country.setValue({
            requestedValue: editRequest.country
                ? (getCountryByCodeOrName(editRequest.country, countries)?.viewValue ?? null)
                : null,
            isAccepted: false,
        });
        address.state.setValue({
            requestedValue: editRequest.state ?? null,
            isAccepted: false,
        });
        address.zipcode.setValue({
            requestedValue: editRequest.zipcode ?? null,
            isAccepted: false,
        });

        address.phone.setValue({
            requestedValue: editRequest.phone ?? null,
            isAccepted: false,
        });
        address.preferredLang.setValue({
            requestedValue: editRequest.preferredLang ?? null,
            isAccepted: false,
        });
    }
    setCurrentValue(patient: Patient) {
        const address = this.controls.address.controls;
        address.address1.currentValue = patient.patientIdentification.address1;
        address.address2.currentValue = patient.patientIdentification.address2;
        address.city.currentValue = patient.patientIdentification.city;
        address.country.currentValue = patient.patientIdentification.country;
        address.state.currentValue = patient.patientIdentification.state;
        address.zipcode.currentValue = patient.patientIdentification.zipcode;
        address.phone.currentValue = patient.patientIdentification.phoneNumber;
        address.preferredLang.currentValue = patient.patientIdentification.preferredLanguage;
    }
}
