import { Component, Input, OnInit, inject } from '@angular/core';
import { DateFormat } from '@models/date-format';
import { RequestLog } from '@models/request';
import { RequestStatusService } from '@services/request-status/request-status.service';
import * as moment from 'moment';
import { Moment, utc } from 'moment';

@Component({
    selector: 'medpace-request-history-post',
    templateUrl: './request-history-post.component.html',
    styleUrls: ['./request-history-post.component.scss'],
})
export class RequestHistoryPostComponent implements OnInit {
    //Note: Post time can be different than showed in history table, as in history table dates are represented in UTC timezone.
    // In this component time is represented in local timezone. For example, 12:00pm in London -> 7:00am in Cincinnati
    private requestStatusService = inject(RequestStatusService);

    DateFormat = DateFormat;

    @Input({ required: true }) postInfo: RequestLog;
    @Input({ required: true }) isAdmin: boolean;

    postMessage: string;
    offset = moment().utcOffset();
    postTime: Moment;

    ngOnInit(): void {
        this.postMessage = this.getPostMessage();
        this.postTime = utc(this.postInfo.timestamp).utcOffset(this.offset);
    }
    getLocalDate(timestamp: Date) {
        return utc(timestamp).toDate();
    }

    getPostMessage() {
        if (this.postInfo && this.postInfo.responsibleUserName) {
            if (this.postInfo?.requestApprovalStatusId && this.postInfo?.details) {
                //Status and note changed
                let userName = this.postInfo?.responsibleUserName;
                if (!this.isAdmin && this.postInfo.anonymized) {
                    userName = 'CRC User';
                }

                return `${
                    this.requestStatusService.getStatusById(this.postInfo.requestApprovalStatusId).viewValue
                } with note "${this.postInfo.details}" by ${userName}`;
            } else if (this.postInfo?.requestApprovalStatusId && !this.postInfo?.details) {
                //Only status changed

                const isChangeCausedByPatient = this.postInfo.actionWasPerformedInPatientPortal;
                const roleOfUserWhoChangedRequest = isChangeCausedByPatient ? `patient ` : ``;

                let userName = this.postInfo?.responsibleUserName;
                if (!this.isAdmin && this.postInfo.anonymized) {
                    userName = 'CRC User';
                }

                return `${
                    this.requestStatusService.getStatusById(this.postInfo.requestApprovalStatusId).viewValue
                } by ${roleOfUserWhoChangedRequest}${userName}`;
            }
        }
    }
}
