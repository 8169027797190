import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SiteDataModel } from '../../models/site';

import { SiteService } from 'src/app/services/site/site.service';

@Injectable()
export class CRCSiteRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getUserSite(email: string): Observable<SiteDataModel[]> {
        return this.client.get<SiteDataModel[]>(`${this.siteService.getSiteBaseUrl()}/api/sites`);
    }

    getUserSiteByStudy(studyId: number): Observable<SiteDataModel[]> {
        return this.client.get<SiteDataModel[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/sites`);
    }
}
