import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/generated-api/api/user.service';
import { EnvConfigurationService } from '@services/env/env-configuration.service';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { GroupService } from '../group/group.service';

@Injectable()
export class AuthService {
    private hasValidTokenSubject$ = new BehaviorSubject<boolean>(false);
    public hasValidToken$ = this.hasValidTokenSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    private isLoggedSubject$ = new BehaviorSubject<boolean>(false);

    constructor(
        private envService: EnvConfigurationService,
        private oauthService: OAuthService,
        private groupService: GroupService,
        private userService: UserService,

        private router: Router
    ) {
        this.configure();
        window.addEventListener('storage', (event) => {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            this.hasValidTokenSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                this.login();
            }
        });

        this.oauthService.events.subscribe((_) => {
            this.hasValidTokenSubject$.next(this.oauthService.hasValidAccessToken());
        });

        this.oauthService.events.pipe(filter((e) => ['token_error'].includes(e.type))).subscribe((e) => {
            setTimeout(() => {}, 5000);
        });
        this.oauthService.events
            .pipe(
                filter((e) => e.type === 'token_received'),
                tap((e) => console.debug('Token has been received!', e.type)),
                withLatestFrom(this.isLoggedSubject$),
                filter(([event, isLogged]) => isLogged === false),
                switchMap(() => this.groupService.getUserGroups())
            )
            .pipe(
                tap((grp) => {
                    let grpNames = grp.map((g) => g.appName);
                    this.groupService.setGroupsToSessionStorage(grpNames);

                    const redirectUrl = localStorage.getItem('redirectUrl');
                    if (redirectUrl) {
                        // Redirect to the originally intended URL
                        localStorage.removeItem('redirectUrl');
                        this.router.navigateByUrl(redirectUrl);
                        return;
                    }
                })
            )
            .pipe(tap(() => this.isLoggedSubject$.next(true)))
            .subscribe();

        this.oauthService.events
            .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe((e) => {
                this.isLoggedSubject$.next(false);
                this.login();
            });

        this.runInitialLoginSequence();

        this.oauthService.tryLoginImplicitFlow().then(() => {
            return Promise.resolve();
        });
    }

    public runInitialLoginSequence(): Promise<void> {
        if (location.hash) {
            // console.log('Encountered hash fragment, plotting as table...');
            // console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
        }

        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:

        return (
            this.oauthService
                .loadDiscoveryDocument(this.envService.configuration.discoveryDoc)

                // For demo purposes, we pretend the previous call was very slow
                // .then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))

                // 1. HASH LOGIN:
                // Try to log in via hash fragment after redirect back
                // from IdServer from initImplicitFlow:
                .then(() => {
                    this.oauthService.tryLogin();
                })

                .then(() => {
                    if (this.oauthService.hasValidAccessToken()) {
                        return Promise.resolve();
                    }
                    //this short-circuits whatever is happenening that throws token_errors when we log out
                    //and causes the page refresh issue that slows the login process
                    if (!this.oauthService.hasValidAccessToken()) {
                        console.log(
                            'Not valid this.oauthService.hasValidAccessToken()',
                            this.oauthService.getAccessToken()
                        );
                        return Promise.resolve();
                    }
                })
                .then(() => {
                    // Someone just successfully logged in. Send request to the backend to save the last login time:
                    if (this.oauthService.hasValidAccessToken()) {
                        return this.userService
                            .userSetLastLoginTime()
                            .pipe(take(1))
                            .subscribe({
                                error: (error) => {
                                    // The only reason for this to fail I know so far (apart from network errors) is when user's token from B2C has no `email` claim,
                                    // and backend responds with 404. This seems to happen for Medpace domain `admin accounts` only, and in this case we don't even
                                    // know who the user is so we cannot recover from it.
                                    console.error(`Failed to set user's last login date: ${error}`);
                                },
                            });
                    }
                })
                .then(() => {
                    this.isDoneLoadingSubject$.next(true);
                    // Check for the strings 'undefined' and 'null' just to be sure. Our current
                    // login(...) should never have this, but in case someone ever calls
                    // initImplicitFlow(undefined | null) this could happen.

                    if (
                        this.oauthService.state &&
                        this.oauthService.state !== 'undefined' &&
                        this.oauthService.state !== 'null'
                    ) {
                        let stateUrl = this.oauthService.state;
                        if (stateUrl.startsWith('/') === false) {
                            stateUrl = decodeURIComponent(stateUrl);
                        }
                        console.log(
                            `There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`
                        );
                        this.router.navigateByUrl(stateUrl);
                    }
                })
                .catch(() => this.isDoneLoadingSubject$.next(true))
        );
    }

    private configure() {
        this.oauthService.configure(this.envService.mapConfigurationToAuthConfig(this.envService.configuration));
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.oauthService.setupAutomaticSilentRefresh();
    }

    login(targetUrl?: string) {
        // Action starts after User logs out from page. It asks for login again.
        this.oauthService.initLoginFlow(targetUrl);
    }

    logout() {
        this.oauthService.logOut();
        this.isLoggedSubject$.next(false);
        window.sessionStorage.clear();
    }

    public get hasValidAccessToken() {
        return this.oauthService.hasValidAccessToken();
    }

    public get hasValidIdToken() {
        return this.oauthService.hasValidIdToken();
    }

    public get claims(): Record<string, any> {
        return this.oauthService.getIdentityClaims();
    }

    getIsAuthenticated(): Observable<boolean> {
        return this.hasValidToken$;
    }
}
