import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CaregiverTravelRequest, CaregiverTravelRequestDTO } from '../../models/caregiverTravelRequest';
import { SiteService } from '../../services/site/site.service';
import { mapCaregiverTravelRequestDtoToCaregiverTravelRequest } from '../../services/transforms/caregiver-transform';
@Injectable()
export class CaregiverRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    public getTravelRequests(caregiverId: number): Observable<CaregiverTravelRequest[]> {
        return this.client
            .get<
                CaregiverTravelRequestDTO[]
            >(`${this.siteService.getSiteBaseUrl()}/api/caregivers/${caregiverId}/TravelRequests`)
            .pipe(map((dtos) => dtos.map((dto) => mapCaregiverTravelRequestDtoToCaregiverTravelRequest(dto))));
    }
}
