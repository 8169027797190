<div id="bank-details" *ngIf="countries$ | async as countries">
    <ng-template
        [ngTemplateOutlet]="isLoading ? spinner : template"
        [ngTemplateOutletContext]="{ data: countries }"
    ></ng-template>
</div>

<!-- TEMPLATE -->
<ng-template #template let-data="data">
    <!-- EXPANSION PANEL -->
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="expansion-panel">
                <mds-expansion-panel
                    [panelTitle]="'Bank Details'"
                    (openedEvent)="handleInformationAccuracyUnderstanding()"
                >
                    <!-- EDIT MODE -->
                    <ng-container *ngIf="isEditing">
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <mds-text-field
                                    label="Full name of the account holder"
                                    placeholder="Type here..."
                                    floatLabel="always"
                                    appearance="outline"
                                    data-cy="full-name-input"
                                    [formCtrl]="bankDetailsFormGroup.controls.fullNameControl"
                                />
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <mds-text-field
                                    label="Bank code (BIC / SWIFT)"
                                    placeholder="Type here..."
                                    floatLabel="always"
                                    appearance="outline"
                                    data-cy="bank-code-input"
                                    [formCtrl]="bankDetailsFormGroup.controls.bankCodeControl"
                                />
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <mds-text-field
                                    label="IBAN"
                                    placeholder="Type here..."
                                    floatLabel="always"
                                    appearance="outline"
                                    data-cy="iban-input"
                                    [formCtrl]="bankDetailsFormGroup.controls.ibanControl"
                                />
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <mds-text-field
                                    label="Recipient Address"
                                    placeholder="Type here..."
                                    floatLabel="always"
                                    appearance="outline"
                                    data-cy="address-input"
                                    [formCtrl]="bankDetailsFormGroup.controls.addressControl"
                                />
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <mds-text-field
                                    label="City"
                                    placeholder="Type here..."
                                    floatLabel="always"
                                    data-cy="city-input"
                                    appearance="outline"
                                    [formCtrl]="bankDetailsFormGroup.controls.cityControl"
                                />
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <mds-text-field
                                    label="Zipcode / Postal Code"
                                    placeholder="Type here..."
                                    floatLabel="always"
                                    data-cy="postcode-input"
                                    appearance="outline"
                                    [formCtrl]="bankDetailsFormGroup.controls.postCodeControl"
                                />
                            </div>
                            <div class="o-grid__item">
                                <mds-autocomplete
                                    [closeOnPageScroll]="false"
                                    [validateInput]="true"
                                    label="Country"
                                    appearance="outline"
                                    floatLabel="always"
                                    placeholder="Enter country"
                                    data-cy="country-dropdown"
                                    [formCtrl]="bankDetailsFormGroup.controls.countryControl"
                                    [options]="data"
                                />
                            </div>
                        </div>
                    </ng-container>

                    <!-- VIEW MODE -->
                    <ng-container *ngIf="!isEditing">
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>Full name of the account holder</h5>
                                    <span data-cy="full-name-text">{{ bankDetails?.fullName || 'Unspecified' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>Bank code (BIC/SWIFT)</h5>
                                    <span data-cy="bank-code-text">{{ bankDetails?.bankCode || 'Unspecified' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>IBAN</h5>
                                    <span data-cy="iban-text">{{ bankDetails?.iban || 'Unspecified' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>Recipient Address</h5>
                                    <span data-cy="address-text">{{ bankDetails?.address || 'Unspecified' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>City</h5>
                                    <span data-cy="city-text">{{ bankDetails?.city || 'Unspecified' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>Zipcode/Postal Code</h5>
                                    <span data-cy="postcode-text">{{ bankDetails?.postCode || 'Unspecified' }}</span>
                                </div>
                            </div>
                            <div class="o-grid__item">
                                <div class="c-info__block">
                                    <h5>Country</h5>
                                    <span data-cy="country-text">{{
                                        bankDetails?.country?.name || 'Unspecified'
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mds-expansion-panel>
            </div>
        </div>
    </div>
</ng-template>

<!-- LOADING SPINNER -->
<ng-template #spinner>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-loading-card></medpace-loading-card>
        </div>
    </div>
</ng-template>
