<div class="o-grid__row">
    <div class="o-grid__item">
        <div class="c-form__input">
            <form [formGroup]="consentFormGroup">
                <mds-checkbox
                    label="By checking this box I confirm the patient has signed the informed consent form for this study"
                    id="consent"
                    [checked]="patient?.hasConsent"
                    (changeEvent)="pushConsentChange($event)"
                    [formGrp]="consentFormGroup"
                    formCtrlName="consentControl"
                >
                    <p>By checking this box I confirm the patient</p>
                    <p>has signed the informed consent form for this study</p>
                </mds-checkbox>
            </form>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
