<div *ngIf="isEditing" class="o-grid" [formGroup]="parentFormGroup">
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="primaryCrc"
                [formCtrl]="crcEmailControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Primary CRC"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'primaryCrcEmailToInvite' })"
            ></mds-text-field>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && !loaderSpinner" class="o-grid">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Primary CRC Email</h5>

                <!-- Crc from ClinTrak -->
                <span>{{ this.crcEmail }}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
