<div class="o-grid__item">
    <span id="totalFileSizesNotify" *ngIf="isFilesSizeTrepasses" class="label-warning_filesSizes"
        >Submission has exceeded entry limit ({{ maxSummaryFileSizesInMB }}MB). Please condense receipt files OR submit
        current request and upload remaining files in a new request.</span
    >
</div>

<div class="c-upload">
    <div
        class="c-upload__dropzone"
        appDnd
        (fileDropped)="processFiles($event)"
        [ngSwitch]="currentUploadStatus"
        [class.c-upload__dropzone--withImage]="currentUploadStatus === uploadStatus.AvailableWithUrl"
    >
        <input
            *ngIf="idVal"
            type="file"
            #fileDropRef
            id="{{ idVal }}"
            accept="{{ acceptableTypesInput }}"
            (change)="onFileSelected($event.target.files)"
        />
        <input
            *ngIf="!idVal"
            type="file"
            #fileDropRef
            id="fileDropRef"
            accept="{{ acceptableTypesInput }}"
            (change)="onFileSelected($event.target.files)"
        />
        <span *ngIf="currentUploadStatus !== uploadStatus.AvailableWithUrl" class="c-upload__icon"
            ><i class="fas fa-cloud-upload-alt"></i
        ></span>
        <span class="label-warning_fileExtensionInformations_Block" *ngSwitchCase="uploadStatus.Empty"
            >Please upload files only in
            {{ acceptableTypesDisplay }}
            formats.</span
        >

        <!--
            Observation: displaying the document name makes sense when user is uploading PDF files or spreadsheets.
            But for images (like in the Study Details edit screen) it would be better to display the image preview like during initial upload. Something to fix in the future.
        -->
        <span *ngSwitchCase="uploadStatus.Available">{{ fileName }}</span>

        <img *ngSwitchCase="uploadStatus.AvailableWithUrl" class="c-upload__img" [src]="url" alt="{{ fileName }}" />

        <span id="fileSizeNotify" class="label-warning" *ngSwitchCase="uploadStatus.InvalidAttachmentSize"
            >File size is above {{ maxSizeOfFileInMB }} MB!</span
        >
        <span id="fileExtensionNotify" class="label-warning" *ngSwitchCase="uploadStatus.UnacceptableTypesInput"
            >File is not in
            {{ acceptableTypesDisplay }}
            formats.</span
        >
    </div>

    <mds-button
        [id]="'upload-btn'"
        for="fileDropRef"
        label="{{ uploadButtonTitle }}"
        [variant]="'outline'"
        width="100%"
        (clickEvent)="uploadButtonClicked()"
    ></mds-button>
</div>
