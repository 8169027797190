<ng-container *ngIf="{ vm: tableData$ | async } as tableData">
    <medpace-table
        data-cy="patient-edit-request-table"
        [data]="tableData.vm ?? undefined"
        title="Profile Edit Requests"
        [isCard]="false"
        [ifFiltering]="false"
        [isSearching]="true"
        [includePaginator]="true"
        [showFilters]="false"
        class="patient-edit-request-table"
        [columns]="columns"
        [fieldMap]="columnsMap"
        [searchFn]="searchFn"
        (rowClickEvent)="openDialog($event)"
    />
</ng-container>
