import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'medpace-delete-patient-modal',
    templateUrl: './delete-patient-modal.component.html',
    styleUrls: ['./delete-patient-modal.component.scss'],
})
export class MedpaceDeletePatientModalComponent {
    constructor(public dialogRef: MatDialogRef<MedpaceDeletePatientModalComponent>) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    deleteConfirm(): void {
        this.dialogRef.close(true);
    }
}
