import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalResult } from '@app/enums/modal-result.enum';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { ClinTrakPersonViewModel, StudyPersonLocaleViewModel } from '@models/clintrak';
import { InputChange } from '@models/event-objects/input-change';
import { StudySaveType } from '@models/event-objects/study-save-type';
import { User } from '@models/user';
import { Step } from '@services/scroll/steps-interface';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UserService } from '@services/user/user.service';
import { disableControls, enableControls, getDisabledControls } from '@utility/form-utils';
import { buildSnackBar, collectErrors, markFormGroupTouched } from '@utility/utility';
import { take, tap } from 'rxjs';
import { Site } from 'src/app/models/site';
import { FieldValidator } from 'src/app/models/validators/field-validator';
import { Study, StudyInformation, SupportTier } from '../../../models/study';
import { StudyTierFields } from './../../../models/study';

@Component({
    selector: 'medpace-study',
    templateUrl: './study.component.html',
    styleUrls: ['./study.component.scss'],
})
export class MedpaceStudyComponent implements OnInit {
    @Input()
    study: Study;

    @Input()
    isAdmin: boolean;

    @Input()
    isUpdate: boolean = false;

    infoFieldsValidator: FieldValidator<StudyInformation>;
    detailsFieldsValidator: FieldValidator<Study>;

    @Output()
    submitClickEvent = new EventEmitter();

    @Output()
    detailsChangeEvent = new EventEmitter<Study>();

    @Output()
    tierSupportChangeEvent = new EventEmitter<SupportTier>();

    @Output()
    studyInfoChangeEvent = new EventEmitter<StudyInformation>();

    @Output()
    logoChangeEvent = new EventEmitter<string>();

    @Output()
    modalOpenEvent = new EventEmitter();

    @Output()
    openUploadSpreadsheetEvent = new EventEmitter();

    @Output()
    alertSiteEvent = new EventEmitter<Site>();

    @Output()
    studyRegionsChange = new EventEmitter();

    @Output()
    studyCountriesChange = new EventEmitter();

    @Output()
    studyTravelOptionsChange = new EventEmitter();

    @Output()
    rejectStudyEvent = new EventEmitter();

    studyFormGroup: FormGroup;

    label: string = 'Not defined';

    tierFields = StudyTierFields;

    steps: Step[] = [
        {
            name: 'Tier Support',
            isActive: true,
            identifier: 'tierSupport',
        },
        {
            name: 'Study Details',
            isActive: false,
            identifier: 'studyDetails',
        },
        {
            name: 'Study Information',
            isActive: false,
            identifier: 'studyInfo',
        },
    ];

    personsRole: string[] = ['globalCtm', 'regionalCtms', 'pc', 'globalRsm', 'prm'];

    pageUrl: string;

    countries: any[] = [];
    currencies: any[] = [];
    studyInfoTitle: string = 'Study Information';

    @Output() uploadSpreadsheetClickedEvent = new EventEmitter<void>();
    @Output() addNewSiteClickedEvent = new EventEmitter<void>();
    @Output() siteRowClickedEvent = new EventEmitter<Site>();
    public readonly user$ = this.userService.getUser();
    constructor(
        private snackbarService: SnackbarService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        this.studyFormGroup = new FormGroup({});
    }

    ngOnInit(): void {
        if (this.isUpdate) {
            this.steps.push({
                name: 'Site Information',
                isActive: false,
                identifier: 'sites',
            });
        }
        this.labelCheck();
    }

    approve(saveType: StudySaveType) {
        this.submit(saveType);
    }

    reject() {
        let dialog = this.dialog.open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
            MedpaceMessageModalComponent,
            {
                data: {
                    title: `Are you sure you want to reject this study?`,
                    bodyText: '',
                    showCancelButton: true,
                    okayButtonLabel: 'Yes',
                    cancelButtonLabel: 'Cancel',
                },
                maxWidth: 600,
                minWidth: 360,
                disableClose: true,
            }
        );

        dialog
            .afterClosed()
            .pipe(
                take(1),
                tap((result) => {
                    if (result === ModalResult.Okay) {
                        this.rejectStudyEvent.emit();
                    }
                })
            )
            .subscribe();
    }

    labelCheck() {
        this.isUpdate ? (this.label = 'Update Study') : (this.label = 'Create New Study');
    }

    processStudyChange: (event: any) => void = (event: any) => {
        const countries = this.mapToStudyCountries(event);
        const regions = this.mapToStudyRegions(event);
        const tier = this.mapToTier(event);
        const logo = this.mapToLogo(event);
        const studyInfo = this.mapToStudyInformation(event);
        const studyDetails = this.mapToStudyDetails(event);
        const studyTravelOptions = this.mapToStudyTravelOptions(event);

        if (logo) {
            this.logoChangeEvent.emit(logo);
        }
        if (tier) {
            this.tierSupportChangeEvent.emit(tier);
        }
        if (studyInfo) {
            this.studyInfoChangeEvent.emit(studyInfo);
        }
        if (studyDetails) {
            this.detailsChangeEvent.emit(studyDetails);
        }
        if (regions) {
            this.studyRegionsChange.emit(regions);
        }
        if (countries) {
            this.studyCountriesChange.emit(countries);
        }
        if (studyTravelOptions) {
            this.studyTravelOptionsChange.emit(studyTravelOptions);
        }
    };

    submitIncrementalSave(): void {
        this.submit(<StudySaveType>{ type: 'incrementalSave', doRoute: false });
    }

    submit(saveType: StudySaveType) {
        let disabledControls: string[];

        if (saveType.type !== 'incrementalSave') {
            disabledControls = getDisabledControls(this.studyFormGroup);
            enableControls(this.studyFormGroup, disabledControls);
        }

        this.studyFormGroup.valid ? this.submitClickEvent.emit(saveType) : markFormGroupTouched(this.studyFormGroup);

        //should display snackbar only when form invalid
        if (this.isUpdate && this.studyFormGroup.invalid) {
            buildSnackBar(collectErrors(this.studyFormGroup), this.snackbarService);
        }

        if (saveType.type !== 'incrementalSave') {
            disableControls(this.studyFormGroup, disabledControls);
        }
    }

    getStudyInfoFormGroup() {
        return this.studyFormGroup.get(`${this.studyInfoTitle} CardFormGroup`);
    }

    uploadSpreadsheetClicked() {
        this.uploadSpreadsheetClickedEvent.emit();
    }
    addNewSiteClicked() {
        this.addNewSiteClickedEvent.emit();
    }
    siteRowClicked(clickedSite: Site) {
        this.siteRowClickedEvent.emit(clickedSite);
    }
    emitOpenUploadSpreadsheetEvent(): void {
        this.openUploadSpreadsheetEvent.emit(true);
    }

    private mapToStudyRegions: (event: InputChange) => any[] = (event: InputChange) => {
        if (event.target === 'studyRegions') {
            return event.value;
        }
    };

    private mapToStudyCountries: (event: InputChange) => any[] = (event: InputChange) => {
        if (event.target === 'studyCountries') {
            return event.value;
        }
    };

    private mapToStudyTravelOptions: (event: InputChange) => any[] = (event: InputChange) => {
        if (event.target === 'studyTravelOptions') {
            return event.value;
        }
    };

    private mapToLogo: (event: InputChange) => string = (event: InputChange) => {
        if (event.target === 'logo') {
            return event.value;
        }
    };

    private mapToTier: (event: InputChange) => SupportTier = (event: InputChange) => {
        for (let key in Object.keys(this.tierFields)) {
            if (event.target === Object.keys(this.tierFields)[key]) {
                return parseInt(event.value);
            }
        }
    };

    private mapToStudyInformation: (event: InputChange) => StudyInformation = (event: InputChange) => {
        let targetName = event.target.substring(0, event.target.indexOf('_'));
        let isRegions = event.target.includes('-');
        let targetIndex = !isRegions
            ? +event.target.substring(event.target.indexOf('_') + 1)
            : +event.target.substring(event.target.indexOf('_') + 1, event.target.indexOf('-'));

        if (event.value === null && event.target !== 'fpfv' && event.target !== 'lplv') {
            return this.removePerson(targetIndex, targetName);
        }
        if (this.isPerson(event)) return this.setPersonChange(event, targetIndex, isRegions);

        let info = <StudyInformation>{};
        info[event.target] = event.value;
        return info;
    };

    private mapToStudyDetails: (event: InputChange) => any = (event: InputChange) => {
        let study = <Study>{};
        study[event.target] = event.value;
        return study;
    };
    public isUpdateAndAdmin(user: User): boolean {
        return this.isUpdate && (user.isAdmin || user.isSuperAdmin);
    }

    private isPerson(event: InputChange): boolean {
        let targetName = event.target.substring(0, event.target.indexOf('_'));
        return this.personsRole.includes(targetName);
    }

    private removePerson(index: number, targetName: string): StudyInformation {
        let info = <StudyInformation>{};
        let existingStudyInfo = this.study.studyInfo;

        existingStudyInfo[targetName].splice(index, 1);
        info = existingStudyInfo;

        return info;
    }

    private setPersonChange(event: InputChange, index: number, isRegion: boolean): StudyInformation {
        let targetName = event.target.substring(0, event.target.indexOf('_'));
        let info = <StudyInformation>{};
        let person = <ClinTrakPersonViewModel>{
            fullName: !isRegion ? event.value : '',
            type: targetName,
            regionsId: isRegion ? event.value?.map((x) => x.regionId) : '',
        };
        if (this.study.studyInfo[targetName].length === index) {
            if (this.study.studyInfo[targetName].length === 0) info[targetName] = [person];
            else {
                let existingStudyInfo = this.study.studyInfo;
                existingStudyInfo[targetName].push(person);
                info = existingStudyInfo;
            }
        } else {
            let existingStudyInfo = this.study.studyInfo;
            if (!isRegion) existingStudyInfo[targetName][index].fullName = event.value;
            else {
                existingStudyInfo[targetName][index].regionsId = event.value?.map((x) => x.regionId);
                let tmpArray = [];
                event.value.forEach((value) => {
                    let newLocaleModel = <StudyPersonLocaleViewModel>{
                        studyPersonId: existingStudyInfo[targetName][index].id,
                        regionId: value.regionId,
                        id: value.id,
                    };

                    tmpArray.push(newLocaleModel);
                });

                existingStudyInfo[targetName][index].studyPersonLocales = tmpArray;
            }

            info = existingStudyInfo;
        }

        return info;
    }
}
