<div class="o-grid">
    <div class="o-grid__row row-p">
        <h4 class="header">Cannot Complete Request</h4>
    </div>
    <div class="o-grid__row row-p">
        <span class="header"
            >Request cannot be completed because the patient is not ready for payment. Make sure proper forms have been
            uploaded to Patient Profile</span
        >
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="flex start">
                <mds-button
                    [id]="'medpaceBtn'"
                    label="Okay"
                    (clickEvent)="closeDialog()"
                    variant="primary"
                ></mds-button>
            </div>
        </div>
    </div>
</div>
