import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedpaceCreateSiteComponent } from '@components/organisms/create-site/site.component';
import { User } from '@models/user';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { mapCRCData_To_SiteCRCs, markFormGroupTouched } from '@utility/utility';
import {
    Address,
    Site,
    SiteCRC,
    SiteInfo,
    SitePayment,
    SiteServices,
    updateSiteProperties,
} from '../../../models/site';

@Component({
    selector: 'medpace-new-site-modal',
    templateUrl: './new-site-modal.component.html',
    styleUrls: [],
})
export class MedpaceNewSiteModalComponent {
    isModalUpdate: boolean = false;
    siteFormGroup: FormGroup;
    studyUsers: User[];

    @ViewChild(MedpaceCreateSiteComponent)
    private siteComponent: MedpaceCreateSiteComponent;

    constructor(
        public dialogRef: MatDialogRef<MedpaceNewSiteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { site: Site; studyUsers: User[] },
        private googleMapsService: GoogleMapsService
    ) {
        if (this.data.site.id) {
            this.isModalUpdate = true;
        }

        this.siteFormGroup = new FormGroup({});
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    detectInfoChange: (event: SiteInfo) => void = (event: SiteInfo) => {
        this.data.site.info = updateSiteProperties(event, this.data.site.info);
    };

    detectCRCChange: (event: SiteCRC[]) => void = (event: SiteCRC[]) => {
        this.data.site.siteCRCs = event;
    };

    detectAddressChange: (event: Address) => void = (event: Address) => {
        this.googleMapsService.setStyleForModal();
        this.data.site.address = updateSiteProperties(event, this.data.site.address);
    };

    detectPaymentChange: (event: SitePayment) => void = (event: SitePayment) => {
        this.data.site.payment = updateSiteProperties(event, this.data.site.payment);
    };

    detectServicesChange: (event: SiteServices) => void = (event: SiteServices) => {
        this.data.site.services = updateSiteProperties(event, this.data.site.services);
    };
    onSubmit(): void {
        if (this.siteFormGroup.valid && this.siteComponent.crcCard.formGroup.valid) {
            this.data.site.siteCRCs = mapCRCData_To_SiteCRCs(this.siteComponent.crcCard.getOutputData(), 0);
            this.dialogRef.close(this.data.site);
        } else {
            markFormGroupTouched(this.siteFormGroup);
        }
    }
}
