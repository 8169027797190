<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h3>Dashboard</h3>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-table
                    [data]="studies"
                    title="Active Studies"
                    [columns]="columns"
                    [fieldMap]="columnMap"
                    [ifFiltering]="false"
                    [isSearching]="true"
                    (rowClickEvent)="doClickRow($event)"
                    [defaultSorting]="{ columnName: 'sponsor', direction: 'asc' }"
                ></medpace-table>
            </div>
        </div>
    </div>
</div>
