import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { Observable, Subscription, tap } from 'rxjs';
import { Patient } from '../../../../models/patient';
@Component({
    selector: 'medpace-consent',
    templateUrl: './consent-card.component.html',
})
export class MedpacePatientConsentCardComponent implements OnInit, OnDestroy, OnChanges {
    patient: Patient;

    @Input()
    isEditing: boolean;

    @Input()
    parentFormGroup: FormGroup;

    @Output()
    consentChangeEvent = new EventEmitter();

    consentFormGroup: FormGroup;
    loaderSpinner: boolean = true;
    patient$: Observable<Patient> = this.patientStateService.getSummaryPatientData();
    subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private patientStateService: PatientStateService
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isEditing && !changes.isEditing?.firstChange) {
            changes.isEditing.currentValue
                ? this.consentFormGroup.controls.consentControl.enable()
                : this.consentFormGroup.controls.consentControl.disable();
        }
    }

    ngOnInit(): void {
        this.initializeFormGroup();
        this.subscriptions.push(
            this.patient$
                .pipe(
                    tap((x) => {
                        this.patient = x;
                        this.setValuesFormGroup();
                        this.loaderSpinner = false;
                    })
                )
                .subscribe()
        );
        this.isEditing
            ? this.consentFormGroup.controls.consentControl.enable()
            : this.consentFormGroup.controls.consentControl.disable();
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    initializeFormGroup() {
        this.consentFormGroup = this.formBuilder.group({
            consentControl: new FormControl(false, Validators.requiredTrue),
        });
        this.parentFormGroup.addControl('consentFormGroup', this.consentFormGroup);
    }
    setValuesFormGroup() {
        if (this.patient?.hasConsent) {
            this.consentFormGroup.controls.consentControl.setValue(this.patient?.hasConsent);
        } else {
            this.consentFormGroup.controls.consentControl.setValue(false);
        }
    }

    pushConsentChange(event: any): void {
        this.consentChangeEvent.emit({ target: 'hasConsent', value: event.checked });
    }
}
