<div id="topPage" class="flex flex-col">
    <medpace-banner></medpace-banner>

    <router-outlet *ngIf="isRouterOutputLoad"></router-outlet>

    <ng-container *ngIf="isLoading">
        <div class="overlay"></div>
        <medpace-loading></medpace-loading>
    </ng-container>

    <medpace-footer></medpace-footer>
</div>
