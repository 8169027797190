import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Target } from '@models/event-objects/target';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { maxLengthValidator } from '@utility/utility.validators';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Patient, PatientAccessibilityRequirements } from 'src/app/models/patient';
@Component({
    selector: 'medpace-accessibility-card',
    templateUrl: './accessibility-card.component.html',
})
export class MedpacePatientAccessiblityCardComponent implements OnInit, OnChanges, OnDestroy {
    accessibilityRequirements: PatientAccessibilityRequirements = null;

    patient$: Observable<Patient> = this.patientStateService.getSummaryPatientData();
    private componentDestroyed$: Subject<boolean> = new Subject();

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();

    @Input()
    isEditing: boolean;
    @Input() parentFormGroup: FormGroup;

    accessibilityReqFormGroup: FormGroup;
    loaderSpinner: boolean = true;
    setupComponent$: Observable<Patient>;

    constructor(
        private formBuilder: FormBuilder,
        private patientStateService: PatientStateService
    ) {
        this.initializeFormGroup();
    }

    ngOnInit(): void {
        this.parentFormGroup.addControl('accessibilityReqFormGroup', this.accessibilityReqFormGroup);
        this.setupComponent$ = this.patient$.pipe(
            takeUntil(this.componentDestroyed$),
            tap((patient) => {
                if (patient) {
                    this.accessibilityRequirements = patient?.accessibilityRequirements;
                    this.setCurrentValues();
                }
                this.loaderSpinner = false;
            })
        );
    }
    ngOnDestroy(): void {
        this.componentDestroyed$.next(null);
        this.componentDestroyed$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isEditing ? this.enableAll() : this.disableAll();
    }

    initializeFormGroup() {
        const formGroup = this.formBuilder.group({
            walkerControl: new FormControl(false),
            wheelchairControl: new FormControl(false),
            rampControl: new FormControl(false),
            otherControl: new FormControl(false),
            additionalRequirementsControl: new FormControl(''),
        });

        this.accessibilityReqFormGroup = formGroup;

        //Handle Additional Requirements field validation
        this.accessibilityReqFormGroup.controls.otherControl.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((fieldRequired) => {
                    if (fieldRequired) {
                        this.accessibilityReqFormGroup.controls.additionalRequirementsControl.setValidators([
                            Validators.required,
                            maxLengthValidator(250),
                        ]);
                    } else {
                        this.accessibilityReqFormGroup.controls.additionalRequirementsControl.setValidators([
                            maxLengthValidator(250),
                        ]);
                    }
                    this.accessibilityReqFormGroup.controls.additionalRequirementsControl.updateValueAndValidity();
                })
            )
            .subscribe();
    }

    setCurrentValues() {
        this.accessibilityReqFormGroup.patchValue({
            walkerControl: this.accessibilityRequirements.walker,
            wheelchairControl: this.accessibilityRequirements.wheelchair,
            rampControl: this.accessibilityRequirements.ramp,
            otherControl: this.accessibilityRequirements.other,
            additionalRequirementsControl: this.accessibilityRequirements.additionalRequirements,
        });

        if (this.accessibilityRequirements.other) {
            this.accessibilityReqFormGroup.patchValue({
                additionalRequirementsControl: this.accessibilityRequirements.additionalRequirements,
            });
        }
    }

    disableAll() {
        Object.values(this.accessibilityReqFormGroup.controls).forEach((control) => control.disable());
    }

    enableAll() {
        Object.values(this.accessibilityReqFormGroup.controls).forEach((control) => control.enable());
    }

    emitChange: (event: MatCheckboxChange) => void = (event: any) => {
        if (event.source.id === 'other' && !event.checked) {
            //When 'Other' checkbox is unchecked, emit an event to clear additionalRequirements
            this.emitAdditionalRequirements({ id: 'additionalRequirements' }, '');
        }
        this.inputChangeEvent.emit({ target: event.source.id, value: event.checked });
    };

    emitAdditionalRequirements: (target: Target, value: string) => void = (target: any, value: string) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };
}
