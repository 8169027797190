import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { CountryRegionCtsmViewModel } from '@models/clintrak';
import { PatientVisitDto } from '@models/patient-visit-name-history-element/patient-visit-name-history-element';
import { StudyPatientViewModel } from 'src/app/models/patient';
import { Site } from 'src/app/models/site';
import { User } from 'src/app/models/user';
import { transformToSite } from 'src/app/services/transforms/site-transform';
import {
    transformToStudy,
    transformToStudyArray,
    transformToStudyRegionArray,
    transformTravelOptionViewModelToStudyTravelOptionArray,
} from 'src/app/services/transforms/study-transform';
import {
    ActiveStudySummaryViewModel,
    CreateUpdateStudyDataModel,
    ExpenseType,
    Study,
    StudyRegion,
    StudyTravelOption,
    VisitName,
} from '../../models/study';
import { AdminStudyRepository } from '../../repositories/admin/admin-study.repository';

@Injectable()
export class AdminStudyServices {
    constructor(private adminStudyRepo: AdminStudyRepository) {}

    createStudy: (newStudy: CreateUpdateStudyDataModel) => Observable<HttpResponse<number>> = (
        newStudy: CreateUpdateStudyDataModel
    ) => {
        return this.adminStudyRepo.createStudy(newStudy);
    };

    updateStudy: (studyId: number, study: CreateUpdateStudyDataModel) => Observable<number> = (
        studyId: number,
        study: CreateUpdateStudyDataModel
    ) => {
        return this.adminStudyRepo.updateStudy(studyId, study);
    };

    getActiveStudies: () => Observable<Study[]> = () => {
        return this.adminStudyRepo.getStudies().pipe(
            map((value) => {
                return value ? transformToStudyArray(value) : [];
            })
        );
    };

    getAllStudySummaries: () => Observable<ActiveStudySummaryViewModel[]> = () =>
        this.adminStudyRepo.getAllStudySummaries();

    getAllPendingApprovalStudySummaries: () => Observable<Study[]> = () =>
        this.adminStudyRepo.getAllPendingApprovalStudySummaries();

    getStudySummaries: () => Observable<Study[]> = () => this.adminStudyRepo.getStudySummaries();

    getStudy: (studyId: number) => Observable<Study> = (studyId: number) => {
        return this.adminStudyRepo.getStudy(studyId).pipe(
            map((value) => {
                return value ? transformToStudy(value) : <Study>{};
            })
        );
    };

    getStudySites: (studyId: number) => Observable<Site[]> = (studyId: number) => {
        return this.adminStudyRepo.getStudySites(studyId).pipe(
            map((siteModels) => {
                return siteModels ? siteModels.map((siteModel) => transformToSite(siteModel)) : [];
            })
        );
    };

    doesStudyHaveSiteWithTravelSupport(studyId: number): Observable<boolean> {
        return this.adminStudyRepo.getSiteTravelSupportCount(studyId).pipe(map((value) => value > 0));
    }

    getStudyVisitTypes: (studyId: number) => Observable<VisitName[]> = (studyId: number) => {
        return this.adminStudyRepo.getStudyVisits(studyId);
    };

    getStudyExpenseTypes: (studyId: number) => Observable<ExpenseType[]> = (studyId: number) => {
        return this.adminStudyRepo.getExpenseTypes(studyId);
    };

    getStudyRegions: (studyId: number) => Observable<StudyRegion[]> = (studyId: number) => {
        return this.adminStudyRepo.getStudyRegions(studyId).pipe(
            map((value) => {
                return value ? transformToStudyRegionArray(value) : [];
            })
        );
    };

    getStudyCountries(studyId: number): Observable<CountryRegionCtsmViewModel[]> {
        return this.adminStudyRepo.getStudyCountries(studyId);
    }

    getStudyTravelOptions: (studyId: number) => Observable<StudyTravelOption[]> = (studyId: number) => {
        return this.adminStudyRepo.getStudyTravelOptions(studyId).pipe(
            map((value) => {
                return value ? transformTravelOptionViewModelToStudyTravelOptionArray(value) : [];
            })
        );
    };

    getStudyPatients: (studyId: number) => Observable<StudyPatientViewModel[]> = (studyId: number) => {
        return this.adminStudyRepo.getStudyPatients(studyId);
    };

    getStudyUsers: (studyId: number) => Observable<User[]> = (studyId: number) => {
        return this.adminStudyRepo.getStudyUsers(studyId);
    };

    getStudyVisitNamesWithPatientRequests(studyId: number, patientId: number): Observable<PatientVisitDto[]> {
        return this.adminStudyRepo.getStudyVisitNamesWithPatientRequests(studyId, patientId);
    }
}
