import { Injectable } from '@angular/core';
import { AccessRequestDto, UserAccessRequestLogDto } from '@models/accessRequest';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccessRequestStateService {
    private readonly accessRequests: BehaviorSubject<AccessRequestDto[]> = new BehaviorSubject<AccessRequestDto[]>(
        null
    );
    private readonly accessRequests$: Observable<AccessRequestDto[]> = this.accessRequests.asObservable();
    private readonly accessRequestLogs: BehaviorSubject<UserAccessRequestLogDto[]> = new BehaviorSubject<
        UserAccessRequestLogDto[]
    >(null);
    private readonly accessRequestLogs$: Observable<UserAccessRequestLogDto[]> = this.accessRequestLogs.asObservable();

    constructor(private accessRequestService: AccessRequestService) {}

    getAccessRequests(): Observable<AccessRequestDto[]> {
        return this.accessRequests$;
    }

    setAccessRequests() {
        this.accessRequestService
            .getAllAccessRequests()
            .pipe(
                take(1),
                tap((result) => {
                    this.accessRequests.next(result);
                })
            )
            .subscribe();
    }

    setAccessRequestLogs() {
        this.accessRequestService
            .getAllAccessRequestLogs()
            .pipe(
                take(1),
                tap((result) => {
                    this.accessRequestLogs.next(result);
                })
            )
            .subscribe();
    }

    getAccessRequestLogs(): Observable<UserAccessRequestLogDto[]> {
        return this.accessRequestLogs$;
    }

    public setState(state: AccessRequestDto[]): void {
        this.accessRequests.next(state);
    }

    public removeFromState(id: number): void {
        if (id) {
            let state = this.accessRequests.value.filter((x) => x.id !== id);
            this.accessRequests.next(state);
        }
    }

    clearStore() {
        this.accessRequestLogs.next(null);
    }
}
