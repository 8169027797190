import { Component, Directive } from '@angular/core';

@Directive({
    selector: 'medpace-card-header',
    standalone: false,
})
export class MedpaceCardHeaderDirective {}

@Directive({
    selector: 'medpace-card-content',
    standalone: false,
})
export class MedpaceCardContentDirective {}

@Component({
    selector: 'medpace-card',
    templateUrl: './medpace-card.component.html',
    styleUrls: ['./medpace-card.component.scss'],
})
export class MedpaceCardComponent {}
