import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appDisableMouseScroll]',
})
export class DisableMouseScrollBehaviorDirective {
    constructor() {}

    @HostListener('mousewheel', ['$event'])
    onWheel(event: Event): void {
        event.preventDefault();
    }
}
