import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ClinTrakStudyViewModel, CountryRegionCtsmViewModel } from '@models/clintrak';
import { StudySaveType } from '@models/event-objects/study-save-type';
import { VisitGroup } from '@models/visitGroup';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { transformToSiteDataModel } from '@services/transforms/site-transform';
import { Subject, combineLatest, finalize, take, takeUntil, tap } from 'rxjs';
import { MedpaceMessageModalComponent } from 'src/app/components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { User } from 'src/app/models/user';
import { GroupService } from 'src/app/services/group/group.service';
import { Site, createNewSite } from '../../models/site';
import {
    CreateUpdateStudyDataModel,
    ExpenseType,
    Study,
    StudyDataModel,
    StudyInformation,
    StudyMappedVisitDto,
    StudyRegion,
    StudyTravelOption,
    SupportTier,
    VisitName,
} from '../../models/study';
import { AdminStudyServices } from '../../services/admin/admin-study.sevice';
import {
    transformToCreateUpdateStudyDataModel,
    transformToStudyDataModel,
} from '../../services/transforms/study-transform';
import { MedpaceBulkSiteModalComponent } from '../modals/bulk-site/site-upload-modal.component';
import { MedpaceNewSiteModalComponent } from '../modals/new-site/new-site-modal.component';

@Component({
    selector: '',
    templateUrl: './new-edit-study.component.html',
    styleUrls: ['./new-edit-study.component.scss'],
})
export class StudyComponent implements OnInit, OnDestroy {
    study: Study;
    isAdmin: boolean = false;
    isEditStudy: boolean = false;
    studyUsers: User[];
    clinTrakStudyGuid: string;
    clintrakContext: boolean = true;
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private studyServices: AdminStudyServices,
        private activeRoute: ActivatedRoute,
        private groupServices: GroupService,
        private router: Router,
        private snackbarService: SnackbarService,
        private dialog: MatDialog,
        private stateService: StudyStateService,
        private siteStateService: SiteStateService,
        private adminSiteServices: AdminSiteServices,
        private cardService: CurrentlyEditedCardService,
        private requestService: AdminRequestServices,
        private location: Location,
        private clinTrakDataService: ClinTrakDataService
    ) {}

    ngOnInit() {
        this.groupServices.getGroupsFromStorage().subscribe((result) => {
            this.isAdmin = this.groupServices.isUserAdmin(result);
        });
        if (this.activeRoute.snapshot.routeConfig.path !== 'admin/newstudy' && (history.state as Study)['id']) {
            this.isEditStudy = true;
            this.study = history.state;

            this.setStates(history.state.id);
            this.getStates();
        } else if (this.activeRoute.snapshot.routeConfig.path !== 'admin/newstudy') {
            this.isEditStudy = true;
            this.setStates(this.activeRoute.snapshot.params.id);
            this.getStates();
        }

        if (this.activeRoute.snapshot.routeConfig.path === 'admin/newstudy') {
            this.isEditStudy = false;
            this.study = new Study();
            this.study.studyInfo = new StudyInformation();
            this.study.studyInfo.visitTypes = [
                <VisitName>{
                    name: '',
                    sortOrder: 1,
                    visitGroup: <VisitGroup>{ name: 'Visit schedule', sortOrder: 1 },
                },
            ];
            this.study.sites = [];
            this.study.supportTier = 1;

            const clinTrakStudy: ClinTrakStudyViewModel = this.router.lastSuccessfulNavigation?.extras?.state?.event;
            if (clinTrakStudy) {
                this.setDataFromClinTrak(clinTrakStudy);
            } else this.clintrakContext = false;

            this.stateService.setNewStudy(this.study);
        }
    }

    private setDataFromClinTrak(clinTrakStudy: ClinTrakStudyViewModel) {
        this.clinTrakStudyGuid = clinTrakStudy.studyId;
        this.study.studyCode = clinTrakStudy.projectId;
        this.study.sponsor = clinTrakStudy.sponsorName;
        this.study.protocol = clinTrakStudy.studyNumber;
        this.study.studyCode = clinTrakStudy.projectId;
        this.clinTrakDataService
            .getStudyData(clinTrakStudy.studyId)
            .pipe(
                tap((data) => {
                    this.study.studyInfo.globalCtm = data?.globalCtm;
                    this.study.studyInfo.globalRsm = data?.globalRsm;
                    this.study.studyInfo.prm = data?.prm;
                    this.study.studyInfo.regionalCtms = data?.regionalCtms;
                    this.study.studyInfo.pc = data?.pc;
                    this.study.studyInfo.studyCountries = data?.countries;
                }),
                finalize(() => this.stateService.setNewStudy(this.study))
            )
            .subscribe();
    }

    setStates(id: number) {
        this.stateService.setStudy(id);
        this.stateService.setUsers(id);

        this.stateService
            .getUsers()
            .pipe(take(1))
            .subscribe((users) => {
                this.siteStateService.setNewUsers(users);
            });
    }

    getStates() {
        let study$ = this.stateService.getStudy();
        let users$ = this.stateService.getUsers();
        combineLatest({ study: study$, users: users$ })
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(({ study, users }) => {
                if (study) this.study = study;
                this.studyUsers = users;
            });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.stateService.clearStore();
        this.siteStateService.clearStore();
        this.requestService.clearCacheActiveTravelRequestTypeCountByStudy();
    }

    checkEditStatus(): string {
        return this.isEditStudy ? 'Edit Study' : 'Create New Study';
    }
    uploadSpreadsheetClicked() {
        const dialogRef = this.dialog.open<MedpaceBulkSiteModalComponent, User[], Site[]>(
            MedpaceBulkSiteModalComponent,
            { id: 'UploadSpreadsheetDialog', disableClose: true, data: this.studyUsers }
        );
        dialogRef.afterClosed().subscribe((uploadedSites) => {
            // in case user cancels
            if (uploadedSites) {
                uploadedSites.forEach((site) => {
                    site.studyId = this.study.id;
                    this.study.sites.push(site);
                });
                this.updateExistingStudy({ type: 'incrementalSave', doRoute: false }, true);
            }
        });
    }
    addNewSiteClicked() {
        let site = createNewSite();
        site.id = 0;
        site.studyId = this.study.id;
        const dialogRef = this.dialog.open<MedpaceNewSiteModalComponent, { site: Site; studyUsers: User[] }, Site>(
            MedpaceNewSiteModalComponent,
            {
                id: 'NewSiteDialog',
                width: '80%',
                maxWidth: 800,
                minWidth: 360,
                disableClose: true,
                data: { site, studyUsers: this.studyUsers },
            }
        );
        dialogRef.afterClosed().subscribe((newSite) => {
            // in case user cancels
            if (newSite) {
                const mappedSite = transformToSiteDataModel(newSite);
                this.adminSiteServices
                    .createSite(mappedSite)
                    .pipe(takeUntil(this.componentDestroyed$))
                    .subscribe(() => {
                        this.setStates(mappedSite.studyId);
                        this.getStates();
                    });
            }
        });
    }
    siteRowClicked(clickedSite: Site) {
        this.router.navigate([`studies/${this.study.id}/sites/${clickedSite.id}`]).then((navigated: boolean) => {
            if (navigated) {
                this.snackbarService.openInfoSnackbar('Redirect to site ' + clickedSite.info.name);
            }
        });
    }

    registerTierSupportChange(event: SupportTier) {
        this.study.supportTier = event;
    }

    registerLogoChange(event: string): void {
        this.study.logoUri = event;
    }

    registerStudyRegionChange(event: any): void {
        this.study.studyInfo.studyRegions = event;
    }

    registerStudyCountryChange(event: any): void {
        this.study.studyInfo.studyCountries = event;
    }

    registerStudyTravelOptionChange(event: any): void {
        if (!!this.study?.studyInfo?.studyTravelOptions) {
            this.study.studyInfo.studyTravelOptions = event;
        }
    }

    registerStudyInfoChange(event: StudyInformation) {
        if (!this.study?.studyInfo) {
            this.study.studyInfo = {
                globalCtm: undefined,
                pc: undefined,
                prm: undefined,
                regionalCtms: undefined,
                globalRsm: undefined,
                status: undefined,
                indication: undefined,
                numPatients: undefined,
                numSites: undefined,
                fpfv: undefined,
                lplv: undefined,
                visitTypes: <VisitName[]>[],
                expenseTypes: <ExpenseType[]>[],
                studyRegions: <StudyRegion[]>[],
                studyCountries: <CountryRegionCtsmViewModel[]>[],
                studyTravelOptions: <StudyTravelOption[]>[],
                studyMappedVisits: <StudyMappedVisitDto[]>[],
                hasMultipleVisitSchedules: undefined,
            };
        }

        this.study.studyInfo = { ...this.study.studyInfo, ...event };
    }

    registerStudyDetailsChange(event: any) {
        this.study = { ...this.study, ...event };
    }

    rejectClinTrakStudy() {
        this.clinTrakDataService
            .rejectStudy(this.clinTrakStudyGuid)
            .pipe(
                take(1),
                finalize(() => {
                    this.location.back();
                    this.snackbarService.openInfoSnackbar('Study rejected successfully');
                })
            )
            .subscribe();
    }

    submitNewStudy(event: any) {
        this.study.isActive = true;
        const model = transformToCreateUpdateStudyDataModel(this.study);
        model.isApproved = true;
        model.isFromClinTrak = true;
        this.studyServices.createStudy(model).subscribe(
            (response) => {
                this.location.back();
                this.snackbarService.openInfoSnackbar('Created new study');
            },
            (error) => {
                this.handleStudySaveError(error);
            }
        );
    }

    updateExistingStudy(event: StudySaveType, withSites: boolean = false) {
        let model: CreateUpdateStudyDataModel | StudyDataModel;
        if (withSites) {
            //for now needed when sites are added with spreadsheet
            model = transformToStudyDataModel(this.study);
        } else {
            model = transformToCreateUpdateStudyDataModel(this.study);
        }
        // model.isApproved = true; //first update is like approve
        this.studyServices.updateStudy(model.id, model).subscribe({
            next: (response) => {
                let card = this.cardService.getState();
                if (card?.cardForm) {
                    card.cardForm.toggleEditMode();
                    this.cardService.clearCurrentState(card);
                }
                this.setStates(model.id);
                if (event.type !== 'incrementalSave') {
                    this.location.back();
                }

                this.snackbarService.openInfoSnackbar(`Updated study ${this.study.studyCode}`);
            },
            error: (error) => {
                this.handleStudySaveError(error);
            },
        });
    }

    private handleStudySaveError(error) {
        switch (error?.status) {
            case 400:
                switch (error.error.errorReasonCode) {
                    case 'DuplicateStudy':
                        const dialogConfig = new MatDialogConfig();

                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 350;
                        dialogConfig.minWidth = 350;

                        dialogConfig.data = {
                            title: 'Duplicate Entry',
                            bodyText: 'This study cannot be created because a study with the same code already exists.',
                        };

                        this.dialog.open(MedpaceMessageModalComponent, dialogConfig);
                        break;
                    default:
                        throw error;
                }
            default:
                throw error;
        }
    }
}
