<div class="o-grid">
    <ng-container *ngIf="mode === 'view'">
        <ng-container
            *ngIf="formGroup.value.travelType !== null && formGroup.value.travelType !== TrainTravelType.NoTravel"
        >
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>From station</h5>
                        <span>{{ formGroup.controls.stationOrigin.value }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>To station</h5>
                        <span>{{ formGroup.controls.stationDestination.value }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Departure date</h5>
                        <span>{{ formGroup.controls.departureDate.value?.toDate() | dateFormat | uppercase }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block" *ngIf="formGroup.value.travelType === TrainTravelType.RoundTrip">
                        <h5>Return date</h5>
                        <span>{{ formGroup.controls.returnDate.value?.toDate() | dateFormat | uppercase }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Special requests</h5>
                        <span>{{ formGroup.controls.specialRequests.value }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="mode === 'edit' && { travelTypeChange: travelTypeChange$ | async }">
        <medpace-alert
            *ngFor="let error of formGroup.errors | keyvalue"
            [additionalClass]="'danger'"
            [content]="error.value"
        ></medpace-alert>
        <div class="o-grid__row">
            <label>
                Choose train ticket type
                <span class="required-asterisk">*</span>
            </label>
        </div>
        <div class="o-grid__row">
            <mat-radio-group [formControl]="formGroup.controls.travelType">
                <mat-radio-button data-cy="roundTrip_Radio" [value]="TrainTravelType.RoundTrip"
                    >Roundtrip</mat-radio-button
                >
                <mat-radio-button data-cy="oneWay_Radio" [value]="TrainTravelType.OneWay">One way</mat-radio-button>
                <mat-radio-button *ngIf="noTravelOption" data-cy="noTravel_Radio" [value]="TrainTravelType.NoTravel"
                    >No travel</mat-radio-button
                >
            </mat-radio-group>
        </div>
        <medpace-alert
            *ngIf="formGroup.controls.travelType.hasError('required')"
            [additionalClass]="'danger'"
            [content]="'Please select a train ticket type'"
        ></medpace-alert>
        <ng-container
            *ngIf="formGroup.value.travelType !== null && formGroup.value.travelType !== TrainTravelType.NoTravel"
        >
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <mds-autocomplete
                        id="stationOrigin"
                        label="Station (origin)"
                        appearance="outline"
                        [placeholder]="'Type here...'"
                        [floatLabel]="'always'"
                        [options]="autocompleteOptions.stationOrigin"
                        [formCtrl]="formGroup.controls.stationOrigin"
                    ></mds-autocomplete>
                </div>
                <div class="o-grid__item">
                    <mds-autocomplete
                        id="stationDestination"
                        label="Station (destination)"
                        appearance="outline"
                        [placeholder]="'Type here...'"
                        [floatLabel]="'always'"
                        [formCtrl]="formGroup.controls.stationDestination"
                        [options]="autocompleteOptions.stationDestination"
                    ></mds-autocomplete>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-datepicker
                        label="Departure Date"
                        appearance="outline"
                        id="departureDate"
                        [formCtrl]="formGroup.controls.departureDate"
                    >
                    </medpace-datepicker>
                </div>
                <div class="o-grid__item">
                    <medpace-datepicker
                        *ngIf="formGroup.value.travelType === TrainTravelType.RoundTrip"
                        label="Return Date"
                        id="returnDate"
                        appearance="outline"
                        [formCtrl]="formGroup.controls.returnDate"
                    >
                    </medpace-datepicker>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-textarea-autocomplete
                        id="specialRequests"
                        [autoSize]="true"
                        [cdkAutosizeMaxRows]="20"
                        [cdkAutosizeMinRows]="5"
                        label="Special Requests"
                        placeholder="Special requests - e.g. seat preference"
                        appearance="outline"
                        floatLabel="always"
                        [formCtrl]="formGroup.controls.specialRequests"
                        [options]="autocompleteOptions.specialRequests"
                    ></medpace-textarea-autocomplete>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
