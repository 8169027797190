<div *ngIf="isEditing" class="o-grid" [formGroup]="parentFormGroup">
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="line1"
                [formGrp]="parentFormGroup"
                formCtrlName="line1Control"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Address Line 1"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'line1' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="line2"
                [formGrp]="parentFormGroup"
                formCtrlName="line2Control"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Address Line 2"
                width="100%"
                (input)="emitChange($event.target.value, { id: 'line2' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="city"
                [formGrp]="parentFormGroup"
                formCtrlName="cityControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="City"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'city' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field half">
            <mds-text-field
                id="stateOrProvince"
                [formGrp]="parentFormGroup"
                formCtrlName="stateOrProvinceControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                [label]="regionStateType$ | async"
                width="100%"
                (input)="emitChange($event.target.value, { id: 'stateOrProvince' })"
            ></mds-text-field>
        </div>
        <div class="o-grid__field half">
            <mds-text-field
                id="postalCode"
                [formGrp]="parentFormGroup"
                formCtrlName="postalCodeControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Zipcode/Postal Code"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'postalCode' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-dropdown
                floatLabel="always"
                [closeOnPageScroll]="false"
                [formGrp]="parentFormGroup"
                formCtrlName="regionControl"
                appearance="outline"
                label="Region"
                placeholder="Select region"
                [options]="regions"
                id="regionDropdown"
                [required]="true"
                (selectionChange)="emitChange($event.value, { id: 'region' })"
            ></mds-dropdown>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-autocomplete
                [closeOnPageScroll]="false"
                [options]="countries$ | async"
                appearance="outline"
                floatLabel="always"
                placeholder="Enter country"
                label="Country"
                id="countryAutocomplete"
                inputType="text"
                [formGrp]="parentFormGroup"
                formCtrlName="countryControl"
                [validateInput]="true"
            >
            </mds-autocomplete>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && !loaderSpinner && site$ | async as site" class="o-grid">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Address Line 1</h5>
                <span>{{ site?.address?.line1 }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="site?.address?.line2" class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Address Line 2</h5>
                <span>{{ site?.address?.line2 }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>City</h5>
                <span>{{ site?.address?.city }}</span>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>{{ regionStateType$ | async }}</h5>
                <span>{{ site?.address?.stateOrProvince }}</span>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Zipcode/Postal Code</h5>
                <span>{{ site?.address?.postalCode ? site?.address?.postalCode : 'Unspecified' }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Region</h5>
                <span>{{ site?.address?.region }}</span>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Country</h5>
                <span>{{ site?.address?.country }}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
