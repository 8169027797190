import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalResult } from '@app/enums/modal-result.enum';

@Component({
    selector: 'medpace-message-modal',
    templateUrl: './medpace-message-modal.component.html',
    styleUrls: ['./medpace-message-modal.component.scss'],
})
export class MedpaceMessageModalComponent {
    title: string;
    bodyText: string;

    okayButtonLabel: string;
    cancelButtonLabel: string;

    showCancelButton: boolean = true;
    showBodyText: boolean = true;

    constructor(
        private dialogRef: MatDialogRef<MedpaceMessageModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            bodyText: string;
            okayButtonLabel?: string;
            cancelButtonLabel?: string;
            showCancelButton?: boolean;
        }
    ) {
        this.title = data.title;
        this.bodyText = data.bodyText;
        this.okayButtonLabel = data.okayButtonLabel ?? 'Okay';
        this.cancelButtonLabel = data.cancelButtonLabel ?? 'Cancel';
        this.showCancelButton = data.showCancelButton ?? false;

        if (!this.bodyText || !this.bodyText.length) {
            this.showBodyText = false;
        }
    }

    confirm() {
        this.dialogRef.close(ModalResult.Okay);
    }

    cancel() {
        this.dialogRef.close(ModalResult.Cancel);
    }
}
