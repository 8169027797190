import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnDestroy, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PersonTypes } from '@app/enums/PersonTypes';
import {
    ClinTrakPersonViewModel,
    ClinTrakStudyDataViewModel,
    CountryRegionCtsmViewModel,
    StudyPersonLocaleViewModel,
} from '@models/clintrak';
import { CreateUpdateStudyDataModel, Study, StudyInformation } from '@models/study';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ClinTrakDataStateService } from '@services/state-management/clintrak-data-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { transformToCreateUpdateStudyDataModel } from '@services/transforms/study-transform';
import { maxLengthValidator } from '@utility/utility.validators';
import { filter, finalize, map, of, switchMap, take, tap } from 'rxjs';
import { DisplayErrorModalComponent } from '../display-error-modal/display-error-modal.component';

@Component({
    selector: 'study-clintrak-changes-modal',
    templateUrl: './study-clintrak-changes-modal.component.html',
    styleUrls: ['./study-clintrak-changes-modal.component.scss'],
})
export class StudyClintrakChangesModalComponent implements OnDestroy {
    data = inject<{ id: number }>(MAT_DIALOG_DATA);
    data$ = of(this.data);
    public dialog: MatDialog = inject(MatDialog);
    private studyStateService = inject(StudyStateService);
    private dialogRef = inject(DialogRef);
    private clintrakDataService = inject(ClinTrakDataService);
    private clintrakDataStateService = inject(ClinTrakDataStateService);
    private studyServices = inject(AdminStudyServices);
    private snackBarService = inject(SnackbarService);
    formBuilder = inject(FormBuilder);
    parentFormGroup = new FormGroup({
        sponsorControl: new FormControl<string>(''),
        protocolControl: new FormControl<string>(''),
        projectIdControl: new FormControl<string>(''),
    });
    studyDetailsFormGroupName: string = 'Study Details CardFormGroup';
    studyInfoFormGroupName: string = 'Study Information CardFormGroup';
    content: StudyInformation = <StudyInformation>{};
    existingClinTrakData: ClinTrakStudyDataViewModel = null;
    isLoading: boolean = true;
    regions: CountryRegionCtsmViewModel[];

    regions$ = this.clintrakDataStateService.getCountries();

    clinTrakData$ = this.data$.pipe(
        take(1),
        filter(Boolean),
        tap((data) => this.studyStateService.setStudy(data?.id)),
        switchMap((data) => this.clintrakDataService.getStudyDataByPcsStudyId(data.id)),
        tap((clinTrakData) => {
            if (clinTrakData) {
                this.existingClinTrakData = clinTrakData;
                this.content.globalCtm = clinTrakData?.globalCtm;
                this.content.globalRsm = clinTrakData?.globalRsm;
                this.content.prm = clinTrakData?.prm;
                this.content.regionalCtms = clinTrakData?.regionalCtms;
                this.content.pc = clinTrakData?.pc;

                this.setClinTrakData(clinTrakData);
            }
        }),
        switchMap((_) => this.regions$),
        take(1),
        tap((regions) => {
            this.regions = regions;
        }),
        finalize(() => (this.isLoading = false))
    );

    ngOnDestroy(): void {
        this.studyStateService.clearStore();
    }

    setClinTrakData(clinTrakData: ClinTrakStudyDataViewModel) {
        this.parentFormGroup.controls.sponsorControl.setValue(clinTrakData.sponsor);
        this.parentFormGroup.controls.protocolControl.setValue(clinTrakData.protocol);
        this.parentFormGroup.controls.projectIdControl.setValue(clinTrakData.projectId);

        this.parentFormGroup.disable();
    }

    submit() {
        this.updateExistingStudy();
    }

    setData() {
        let studyDetailsFormGroup = this.parentFormGroup.controls[this.studyDetailsFormGroupName] as FormGroup;
        studyDetailsFormGroup.controls.sponsorControl.setValue(this.parentFormGroup.controls.sponsorControl.value);
        studyDetailsFormGroup.controls.protocolControl.setValue(this.parentFormGroup.controls.protocolControl.value);

        let personTypes = Object.values(PersonTypes);

        personTypes.forEach((type) => {
            if (this.content[type]) {
                this.setRoleFormArray(type, this.content[type]);
            }
        });
    }

    closeModal() {
        this.dialogRef.close(null);
    }

    getRegionNamesByGuid(regionsId: string[]): string {
        let regions = this.regions.filter((x) => regionsId.includes(x.regionId));
        let regionsName = regions.map((x) => x.regionName);
        let result = [...new Set(regionsName)].join(', ');
        return result;
    }

    setRoleFormArray(roleName: string, persons: ClinTrakPersonViewModel[]): void {
        let studyInfoFormGroup = this.parentFormGroup.controls[this.studyInfoFormGroupName] as FormGroup;
        let formGroup = studyInfoFormGroup?.controls[roleName + 'FormGroup'] as FormGroup;
        let array = formGroup?.controls.formArray as FormArray<FormGroup>;

        array.clear();
        persons?.forEach(() => {
            array.push(
                this.formBuilder.group({
                    fullName: new FormControl('', [Validators.required, maxLengthValidator(150)]),
                    regionsDropdown: new FormControl(''),
                })
            );
        });

        if (array?.controls.length > 0 && persons?.length > 0) {
            array?.controls.forEach((controlGroup, index) => {
                switch (roleName) {
                    case 'pc':
                        controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                        controlGroup.controls.regionsDropdown.setValue(persons[index]?.regionsId);
                        break;
                    case 'regionalCtms':
                        controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                        controlGroup.controls.regionsDropdown.setValue(persons[index]?.regionsId);
                        break;
                    default:
                        controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                        break;
                }
                controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                controlGroup.controls.regionsDropdown.setValue(persons[index]?.regionsId);
            });
        }
    }

    updateExistingStudy() {
        let model: CreateUpdateStudyDataModel;

        this.studyStateService
            .getStudy()
            .pipe(
                take(1),
                map((study) => this.mapClinTrakData(study)),
                switchMap((study: Study) => {
                    model = transformToCreateUpdateStudyDataModel(study);
                    return this.studyServices.updateStudy(model.id, model);
                })
            )
            .subscribe({
                error: (e) => {
                    console.error('error', e);
                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: e.error.ExceptionMessage,
                    });
                },
                complete: () => {
                    this.studyStateService.setStudy(model.id);
                    this.snackBarService.openInfoSnackbar(`Updated study ${this.existingClinTrakData.projectId}`);
                    this.closeModal();
                },
            });
    }

    mapClinTrakData(study: Study): Study {
        let studyDetailsFormGroup = this.parentFormGroup.controls[this.studyDetailsFormGroupName] as FormGroup;

        study.sponsor = studyDetailsFormGroup.controls.sponsorControl.value;
        study.protocol = studyDetailsFormGroup.controls.protocolControl.value;

        let personTypes = Object.values(PersonTypes);
        return this.mapPersons(personTypes, study);
    }

    mapPersons(personsTypes: PersonTypes[], study: Study): Study {
        let studyInfoFormGroup = this.parentFormGroup.controls[this.studyInfoFormGroupName] as FormGroup;
        personsTypes.forEach((type) => {
            let resultArray: ClinTrakPersonViewModel[] = [];
            let formGroup = studyInfoFormGroup?.controls[type.toString() + 'FormGroup'] as FormGroup;
            let array = formGroup?.controls.formArray as FormArray<FormGroup>;

            array.controls.forEach((formGroup) => {
                let tempLocaleArray = [];
                let regionsId = formGroup.controls.regionsDropdown?.value as string[];

                if (!!regionsId && regionsId.length > 0) {
                    regionsId?.forEach((regionId) => {
                        let newLocaleModel = <StudyPersonLocaleViewModel>{
                            studyPersonId: '0',
                            regionId: regionId,
                        };

                        tempLocaleArray.push(newLocaleModel);
                    });
                }

                let person = <ClinTrakPersonViewModel>{
                    fullName: formGroup.controls.fullName.value,
                    studyPersonLocales: tempLocaleArray,
                    type: type,
                };
                resultArray.push(person);
            });

            study.studyInfo[type] = resultArray;
        });

        return study;
    }
}
