import { Injectable } from '@angular/core';
import { Site } from '@models/site';
import { User } from '@models/user';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { BehaviorSubject, Observable, ReplaySubject, forkJoin, take } from 'rxjs';

@Injectable()
export class SiteStateService {
    constructor(
        private studyService: AdminStudyServices,
        private siteService: AdminSiteServices
    ) {}

    private readonly site = new BehaviorSubject<Site>(null);
    public readonly site$ = this.site.asObservable();

    private readonly users = new ReplaySubject<User[]>();
    public readonly users$ = this.users.asObservable();
    private getSiteAndCRCs(id: number) {
        return forkJoin({ site: this.siteService.getSite(id), crcs: this.siteService.getSiteCRCs(id) });
    }
    public setSite(id: number) {
        this.getSiteAndCRCs(id)
            .pipe(take(1))
            .subscribe((result) => {
                result.site.siteCRCs = result.crcs;
                this.site.next(result.site);
            });
    }
    public setSiteAndStudyUsers(id: number): void {
        this.getSiteAndCRCs(id)
            .pipe(take(1))
            .subscribe((result) => {
                let siteResult = result.site;
                siteResult.siteCRCs = result.crcs;
                this.studyService.getStudyUsers(siteResult.studyId).subscribe((results) => {
                    results.forEach((u) => {
                        u['displayName'] = `${u.firstName} ${u.lastName}`;
                    });
                    this.users.next(results);
                });

                return this.site.next(siteResult);
            });
    }

    public getSite(): Observable<Site> {
        return this.site$;
    }

    public setNewSite(newSite: Site): void {
        this.site.next(newSite);
    }

    public clearStore() {
        this.site.next(null);
    }

    public setUsers(id: number): void {
        this.studyService
            .getStudyUsers(id)
            .pipe(take(1))
            .subscribe((res) => {
                res.forEach((u) => {
                    u['displayName'] = `${u.firstName} ${u.lastName}`;
                });
                return this.users.next(res);
            });
    }
    public setNewUsers(users: User[]) {
        this.users.next(users);
    }

    public getUsers(): Observable<User[]> {
        return this.users$;
    }
}
