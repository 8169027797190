import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { EditedCard } from '@models/edited-card';
import { isEqual } from 'lodash-es';
@Injectable({
    providedIn: 'root',
})
export class CurrentlyEditedCardService {
    //initial value for signal
    private signal: WritableSignal<EditedCard> = signal<EditedCard>({
        cardName: undefined,
        cardForm: undefined,
    });

    /**
     * Gets object with information about currently edited card
     */
    getState: Signal<EditedCard> = this.signal.asReadonly();

    /**
     * sets state for EditedCard if no card is being edited
     * @param newState object of type EditedCard that is to be set to state
     * @returns true if state was set and false if it was not set
     */
    setState(newState: EditedCard): boolean {
        if (!this.signal().cardName) {
            this.signal.set(newState);
            return true;
        } else return false;
    }

    /**
     * Clear state if it's the same as oldState
     * @param oldState state to be removed
     * @returns true if stated is cleared, false if it's not cleared
     */
    clearCurrentState(oldState: EditedCard): boolean {
        if (isEqual(this.signal(), oldState)) {
            this.clearState();
            return true;
        } else return false;
    }

    /**
     * Clear state
     */
    clearState(): void {
        this.signal.set({ cardName: undefined, cardForm: undefined });
    }
}
