<div class="o-grid">
    <div *ngIf="mode === 'view'">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <app-transfer-data
                    *ngFor="let transferFormGroup of formGroup.controls.transfers.controls; let i = index"
                    [index]="i"
                    [mode]="'view'"
                    [timeOptions]="timeOptions"
                    [formGroup]="transferFormGroup"
                    (removeEvent)="removeTransfer($event)"
                ></app-transfer-data>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special Requests</h5>
                    <span>{{ formGroup.controls.specialRequests.value }}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="mode === 'edit'">
        <medpace-alert
            *ngFor="let error of formGroup.controls.transfers.errors | keyvalue"
            [additionalClass]="'danger'"
            [content]="error.value"
        ></medpace-alert>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <app-transfer-data
                    *ngFor="let transferFormGroup of formGroup.controls.transfers.controls; let i = index"
                    [index]="i"
                    [mode]="'edit'"
                    [timeOptions]="timeOptions"
                    [formGroup]="transferFormGroup"
                    (removeEvent)="removeTransfer($event)"
                ></app-transfer-data>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-button
                    [id]="'add-transfer-button'"
                    data-cy="add-transfer-button"
                    [label]="'Add Transfer'"
                    [variant]="'primary'"
                    (clickEvent)="addTransfer()"
                ></mds-button>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-textarea-autocomplete
                    id="specialRequests"
                    [formCtrl]="formGroup.controls.specialRequests"
                    [cdkAutosizeMaxRows]="20"
                    [cdkAutosizeMinRows]="5"
                    [label]="'Special Requests'"
                    placeholder="Special requests - e.g. ADA accessible"
                    [appearance]="'outline'"
                    floatLabel="always"
                    [autoSize]="true"
                    [options]="autocompleteOptions.specialRequests"
                ></medpace-textarea-autocomplete>
            </div>
        </div>
    </div>
</div>
