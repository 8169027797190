import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import {
    AbstractControl,
    AbstractControlOptions,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { iDraggableObject } from '@models/interfaces/iDraggableObject';
import {
    duplicateInFormGroupValidator,
    maxLengthValidator,
    requiredAtLeastOneOption,
} from '@utility/utility.validators';
import { capitalize } from 'lodash-es';
import { Subject } from 'rxjs';

export type RemoveOptionData<T extends iDraggableObject> = { optionToRemove: T; options: T[] };
@Component({
    selector: 'medpace-draggable-list',
    templateUrl: './medpace-draggable-list.component.html',
    styleUrls: ['./medpace-draggable-list.component.scss'],
})
export class MedpaceDraggableListComponent<T extends iDraggableObject> implements OnInit, OnDestroy {
    private fb = inject(FormBuilder);

    @Input() optionName: string;
    @Input() title: string;
    @Input() labelText: string;
    @Input() options: T[];
    @Input() parentFormGroup: FormGroup = this.fb.group({});
    @Output() optionsChanged = new EventEmitter<T[]>();
    @Output() tryRemoveOption = new EventEmitter<RemoveOptionData<T>>();
    @Output() tryRemoveGroup = new EventEmitter<string>();

    optionsFormGroup: FormGroup = this.fb.group({}, <AbstractControlOptions>{ validators: requiredAtLeastOneOption });
    optionFormControlName: string = '';
    private componentDestroyed$: Subject<boolean> = new Subject();
    groupNameEditInput = new FormControl<string>('', [Validators.required, maxLengthValidator(100)]);

    ngOnInit(): void {
        this.addFormGroupToParent(this.optionsFormGroup);
        this.optionFormControlName = `${this.optionName}Control`;

        if (this.options === null || this.options === undefined) {
            this.options = <T[]>[{ name: '', sortOrder: 1 }];
        }
        this.initializeFormControls(this.options);
        this.addGroupNameEditInputControl();
    }

    addGroupNameEditInputControl() {
        this.parentFormGroup.addControl(`groupNameEditInput`, this.groupNameEditInput);
        this.parentFormGroup.controls.groupNameEditInput.setValue(this.optionName ?? '');
    }

    optionChanged(value: string, option: T): void {
        this.options.find((val) => val === option).name = value;
        this.optionsChanged.emit(this.options);
    }

    getFormControl<T extends iDraggableObject>(formGroup: FormGroup, option: T): FormControl<string> {
        return <FormControl<string>>formGroup.get(this.getFormControlName(option));
    }

    getFormControlName<T extends iDraggableObject>(option: T): string {
        return `${this.optionFormControlName + option.sortOrder}`;
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    addFormGroupToParent(formGroup: FormGroup<any>): void {
        if (!!this.parentFormGroup) {
            this.parentFormGroup.addControl(`${this.optionName}Group`, formGroup);
        }
    }

    drop(event: CdkDragDrop<T[]>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }
        moveItemInArray(this.options, event.previousIndex, event.currentIndex);
        this.reassignSortOrder(this.options);
        this.synchronizeFormControls(this.optionsFormGroup, this.options, this.optionFormControlName);

        this.optionsChanged.emit(this.options);
    }

    reassignSortOrder(options: T[]): void {
        options.forEach((value, index) => {
            value.sortOrder = index + 1;
        });
    }

    addOption(): void {
        let newVisit: T = <T>{ sortOrder: this.options.length + 1 };

        this.options.push(newVisit);
        this.synchronizeFormControls(this.optionsFormGroup, this.options, this.optionFormControlName);

        //event is not emitted, empty value
    }
    onOptionRemoveClick(option: T) {
        this.tryRemoveOption.emit({ optionToRemove: option, options: this.options });
    }
    removeOption(option: T): void {
        const optionToRemove = this.options.find((val) => val === option);

        if (!!optionToRemove) {
            this.options.splice(this.options.indexOf(optionToRemove), 1);
            this.reassignSortOrder(this.options);
            this.synchronizeFormControls(this.optionsFormGroup, this.options, this.optionFormControlName);

            this.optionsChanged.emit(this.options);
        }
    }

    removeGroup() {
        this.parentFormGroup.removeControl(`${this.optionName}Group`);
        this.tryRemoveGroup.emit(this.optionName);
    }

    /* istanbul ignore next */
    convertToFormControl(abstractControl: AbstractControl): FormControl {
        return <FormControl>abstractControl;
    }

    initializeFormControls(options: T[]): void {
        options.forEach((option) => {
            const name = this.getFormControlName(option);
            this.optionsFormGroup.addControl(name, this.createFormControl(option?.name));
        });
    }

    /* istanbul ignore next */
    capitalizeText(text: string): string {
        if (!!text) return capitalize(text);
    }

    getButtonID(name: string): string {
        return `add-${name}-btn`;
    }

    /* istanbul ignore next */
    createFormControl(name: string) {
        return new FormControl(name, [
            Validators.required,
            maxLengthValidator(100),
            duplicateInFormGroupValidator(
                this.optionsFormGroup,
                `${this.capitalizeText(this.labelText)} has that option already`
            ),
        ]);
    }

    private synchronizeFormControls(formGroup: FormGroup, options: T[], formControlName: string): void {
        // Remove old controls from formGroup
        const controlNames = Object.keys(formGroup.controls);
        controlNames.forEach((name) => formGroup.removeControl(name));

        // Add controls with updated names
        for (let i = 0; i < options.length; i++) {
            const newControl = this.createFormControl(options[i].name);
            formGroup.addControl(`${formControlName}${i + 1}`, newControl);
        }

        formGroup.markAllAsTouched();
    }

    getValidationMessage(formGroup: FormGroup): string {
        let validationMessage: string;
        if (!!formGroup?.errors) {
            validationMessage = Object.values(formGroup.errors)[0];
        } else {
            //no error in formGroup, but in formControls inside
            validationMessage = `Please fill in all "${this.capitalizeText(this.labelText)}" fields correctly`;
        }

        return validationMessage;
    }
}
