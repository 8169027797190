import { Site, SiteCRC, SiteDataModel, SiteRawDataModel, SiteSummaryViewModel, SiteTableView } from '../../models/site';

export function transformToSiteDataModel(site: Site): SiteDataModel {
    return <SiteDataModel>{
        id: site.id,
        studyId: site.studyId,
        siteNumber: site.info.siteNumber,
        institutionName: site.info.name,
        pilastName: site.info.pilastName,
        address1: site.address.line1,
        address2: site.address.line2,
        city: site.address.city,
        state: site.address.stateOrProvince,
        zipcode: site.address.postalCode,
        country: site.address.country,
        region: site.address.region,
        isShippingAddressSameAsPrimary: site.isShippingAddressSameAsPrimary,
        shippingAddress1: site.shippingAddress.line1,
        shippingAddress2: site.shippingAddress.line2,
        shippingCity: site.shippingAddress.city,
        shippingState: site.shippingAddress.stateOrProvince,
        shippingZipcode: site.shippingAddress.postalCode,
        shippingCountry: site.shippingAddress.country,
        shippingRegion: site.shippingAddress.region,
        siteCrcs: site.siteCRCs,
        paymentCc: site.payment.credit,
        paymentCheck: site.payment.check,
        paymentWireTransfer: site.payment.wireTransfer,
        collectBankDetails: site.payment.collectBankDetails,
        paymentDirect: site.payment.reimburse,
        travelServiceSupport: site.services.travel,
        reimbursementSupport: site.services.reimbursement,
        stipendSupport: site.services.stipend,
        stipendSupportDetails: site.services.stipendDetails,
        otherServiceSupport: site.services.other,
        otherServiceDetails: site.services.otherDetails,
        kycdocumentRequired: site.services.kycDocumentation,
        paymentConfirmationRequired: site.services.readyForPayment,
        status: site.status,
        statusId: site.status.statusId,
        isReviewedByCRC: site.isReviewedByCRC,
        allowPpinvitations: site.services.allowPpinvitations,
        clinTrakStatus: site.info.clintrakStatus,
        primaryCrcEmailToInvite: site.primaryCrcEmailToInvite,
    };
}
export function transformToSite(model: SiteDataModel): Site {
    return <Site>{
        studyId: model.studyId,
        id: model.id,
        info: {
            siteNumber: model.siteNumber,
            name: model.institutionName,
            pilastName: model.pilastName,
            clintrakStatus: model.clinTrakStatus,
        },
        address: {
            line1: model.address1,
            line2: model.address2,
            city: model.city,
            stateOrProvince: model.state,
            postalCode: model.zipcode,
            country: model.country,
            region: model.region,
        },
        isShippingAddressSameAsPrimary: model.isShippingAddressSameAsPrimary,
        shippingAddress: {
            line1: model.shippingAddress1,
            line2: model.shippingAddress2,
            city: model.shippingCity,
            stateOrProvince: model.shippingState,
            postalCode: model.shippingZipcode,
            country: model.shippingCountry,
            region: model.shippingRegion,
        },
        payment: {
            credit: model.paymentCc,
            check: model.paymentCheck,
            wireTransfer: model.paymentWireTransfer,
            collectBankDetails: model.collectBankDetails,
            reimburse: model.paymentDirect,
        },
        services: {
            travel: model.travelServiceSupport,
            reimbursement: model.reimbursementSupport,
            stipend: model.stipendSupport,
            stipendDetails: model.stipendSupportDetails,
            other: model.otherServiceSupport,
            otherDetails: model.otherServiceDetails,
            kycDocumentation: model.kycdocumentRequired,
            readyForPayment: model.paymentConfirmationRequired,
            allowPpinvitations: model.allowPpinvitations,
        },
        siteCRCs: model.siteCrcs,
        region: model.region,
        patients: model.patients,
        status: model.status,
        isReviewedByCRC: model.isReviewedByCRC,
        primaryCrcEmailToInvite: model.primaryCrcEmailToInvite,
    };
}

export function transformToSiteArray(models: SiteDataModel[]): Site[] {
    let sites = <Site[]>[];
    if (models) {
        models.forEach((model) => sites.push(transformToSite(model)));
    }
    return sites;
}

export function transformToSiteCRCUser(modelCRC): SiteCRC {
    return <SiteCRC>{
        userId: modelCRC.userId,
        siteId: modelCRC.siteId,
        isPrimary: modelCRC.isPrimary,
        user: {
            id: modelCRC.user.id,
            firstName: modelCRC.user.firstName,
            lastName: modelCRC.user.lastName,
            emailAddress: modelCRC.user.emailAddress,
            phoneNumber: modelCRC.user.phoneNumber,
        },
    };
}
export function transformToSiteCRCUserArray(userArray): SiteCRC[] {
    let crcs = <SiteCRC[]>[];
    if (userArray) {
        userArray.forEach((user) => crcs.push(transformToSiteCRCUser(user)));
    }
    return crcs;
}
export function transformSiteToSiteTableView(site: Site, protocol: string): SiteTableView {
    const primaryCRC = site.siteCRCs.find((crc) => crc.isPrimary);
    const primaryCRCName = primaryCRC ? primaryCRC.user.firstName + ' ' + primaryCRC.user.lastName : ''; // on test and prod there may exist sites without any primaryCRC assigned
    return <SiteTableView>{
        id: site.id,
        siteNumber: site.info.siteNumber,
        pilastName: site.info.pilastName,
        name: site.info.name,
        protocol: protocol,
        primaryCRC: primaryCRCName,
    };
}
export function transformSiteViewModelToSiteTableView(siteViewModel: SiteSummaryViewModel): SiteTableView {
    return <SiteTableView>{
        id: siteViewModel.id,
        siteNumber: siteViewModel.siteNumber,
        pilastName: siteViewModel.piLastName,
        name: siteViewModel.institutionName,
        protocol: siteViewModel.protocol,
        primaryCRC: siteViewModel.primaryCRCFullName,
    };
}
export function transformSiteRawDataModelToSiteTableView(
    siteRawDataModel: SiteRawDataModel,
    protocol: string
): SiteTableView {
    return <SiteTableView>{
        id: siteRawDataModel.id,
        siteNumber: siteRawDataModel.siteNumber,
        pilastName: siteRawDataModel.pilastName,
        name: siteRawDataModel.institutionName,
        protocol: protocol,
    };
}
