import { Injectable } from '@angular/core';
import { CountryRegion } from '@models/countryRegion';
import { FormDataService } from '@services/form-data/form-data.service';
import { Observable, ReplaySubject, map, take } from 'rxjs';
import { CountryViewModel } from 'src/app/models/country';

@Injectable({
    providedIn: 'root',
})
export class CountryStateService {
    constructor(private formService: FormDataService) {}

    private readonly countries = new ReplaySubject<CountryViewModel[]>();
    private readonly activeCountries = new ReplaySubject<CountryViewModel[]>();
    private readonly regions = new ReplaySubject<CountryRegion[]>();
    public init(): void {
        this.formService
            .getCountries()
            .pipe(take(1))
            .subscribe((res) => {
                let names = res.map(
                    (x) =>
                        new CountryViewModel({
                            id: x.id,
                            value: x.name,
                            viewValue: x.name,
                            countryCode: x.threeCharCode,
                        })
                );
                let countryRegions = res.map((x) => <CountryRegion>{ country: x.name, region: x.regionType });
                this.countries.next(names);
                this.regions.next(countryRegions);
            });
        this.formService
            .getActiveCountries()
            .pipe(take(1))
            .subscribe((res) => {
                let names = res.map(
                    (x) =>
                        new CountryViewModel({
                            id: x.id,
                            value: x.name,
                            viewValue: x.name,
                            countryCode: x.threeCharCode,
                        })
                );
                return this.activeCountries.next(names);
            });
    }
    public getCountries(): Observable<CountryViewModel[]> {
        return this.countries.asObservable();
    }

    public getCountriesStringArray(): Observable<string[]> {
        return this.countries.asObservable().pipe(
            map((val) => {
                return val.map((country) => country.viewValue);
            })
        );
    }

    public getCountryByName(name: string): Observable<CountryViewModel> {
        return this.countries.asObservable().pipe(map((countries) => countries.find((c) => c.viewValue === name)));
    }

    public getCountryById(id: number): Observable<CountryViewModel> {
        return this.countries.asObservable().pipe(map((countries) => countries.find((c) => c.id === id)));
    }

    public getCountryByCode(code: string): Observable<CountryViewModel> {
        return this.countries.asObservable().pipe(map((countries) => countries.find((c) => c.countryCode === code)));
    }

    public getCountryByCodeOrName(codeOrName: string): Observable<CountryViewModel> {
        if (codeOrName.length === 3) return this.getCountryByCode(codeOrName);
        else return this.getCountryByName(codeOrName);
    }
    public getActiveCountries(): Observable<CountryViewModel[]> {
        return this.activeCountries.asObservable();
    }

    getRegions(): Observable<CountryRegion[]> {
        return this.regions.asObservable();
    }

    public getRegionStateType(country: string): Observable<string> {
        return this.getRegions().pipe(
            map((regions) => {
                const reg = regions.filter((reg) => reg.country === country)[0]?.region;
                return reg || 'Province / Region / State';
            })
        );
    }
}
