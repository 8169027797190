import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '@components/organisms/snackBar/error-snackbar/error-snackbar.component';
import { InfoSnackbarComponent } from '@components/organisms/snackBar/info-snackbar/info-snackbar.component';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService implements OnDestroy {
    subscriptions: Subscription[] = [];
    constructor(private snackBar: MatSnackBar) {}

    openInfoSnackbar(message: string) {
        this.snackBar.openFromComponent(InfoSnackbarComponent, {
            duration: 5000,
            data: message,
        });
    }

    /* intended for business errors */
    openErrorSnackbar(message: string) {
        if (message.trim().length > 0) {
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
                duration: 10000,
                data: message,
                panelClass: ['snackbar-error'],
            });

            // I decided to log a warning to console here, so Cypress tests can also capture that something went wrong in logs.
            // This is logged as a warning and not an error, so tests don't always fail when it happens (it might be an expected result in some test paths).
            console.warn(`An error was displayed to a business user in a snackbar: ${message}`);
        }
    }

    /* intended for development errors, typically configured to only appear in dev environments */
    openDevelopmentErrorSnackbar(message: string) {
        const snackBar = this.snackBar.open(message, 'Close', {
            duration: 0, // it will not auto-disappear
            data: message,
            panelClass: ['snackbar-error-debug'],
        });

        const dismissSnackbarSubscription = snackBar.onAction().subscribe(() => {
            snackBar.dismiss();
        });
        this.subscriptions.push(dismissSnackbarSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }
}
