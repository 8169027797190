import { Component, Input } from '@angular/core';

@Component({
    selector: 'medpace-vertical-stepper-link',
    templateUrl: './vertical-stepper-link.component.html',
    styleUrls: ['./../../../organisms/stepper/vertical-stepper.component.scss'],
})
export class VerticalStepperLinkComponent {
    @Input()
    stepName: string;

    @Input()
    isActive: boolean;

    @Input()
    stepIdentifier: string;

    @Input()
    offsetElement: HTMLElement;

    onClick() {
        let element = document.getElementById(this.stepIdentifier);
        window.scrollTo({ top: element.offsetTop + this.offsetElement.offsetHeight });
    }
}
