<mds-dialog [dialogTitle]="title">
    <mds-dialog-content *ngIf="showBodyText">
        <div class="inner-html" [innerHTML]="bodyText"></div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            [id]="'okay-btn'"
            mat-button
            (clickEvent)="confirm()"
            label="{{ okayButtonLabel }}"
            variant="primary"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            *ngIf="showCancelButton"
            mat-button
            mat-dialog-close
            label="{{ cancelButtonLabel }}"
            variant="outline"
            (clickEvent)="cancel()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
