import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { SiteService } from 'src/app/services/site/site.service';

@Injectable()
export class AdminUserRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    updateUser: (user: User) => Observable<any> = (user: User) => {
        return this.client.put<any>(`${this.siteService.getSiteBaseUrl()}/api/users`, user);
    };

    syncUsers: () => Observable<any> = () => {
        return this.client.post<any>(`${this.siteService.getSiteBaseUrl()}/api/users/syncauthusers`, {});
    };

    getUsersVM: () => Observable<any[]> = () => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/users/admindetails`);
    };
}
