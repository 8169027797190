import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessGroupService } from './api/accessGroup.service';
import { AccessRequestService } from './api/accessRequest.service';
import { AnnouncementService } from './api/announcement.service';
import { CaregiverService } from './api/caregiver.service';
import { CountryService } from './api/country.service';
import { CurrencyService } from './api/currency.service';
import { GetThereService } from './api/getThere.service';
import { HistoryDataService } from './api/historyData.service';
import { KYCAuditLogService } from './api/kYCAuditLog.service';
import { MileageReimbursementRateService } from './api/mileageReimbursementRate.service';
import { PatientService } from './api/patient.service';
import { PatientPortalAccessRequestService } from './api/patientPortalAccessRequest.service';
import { RegionService } from './api/region.service';
import { ReportService } from './api/report.service';
import { RequestService } from './api/request.service';
import { RequestStatusLogService } from './api/requestStatusLog.service';
import { SiteService } from './api/site.service';
import { StudyService } from './api/study.service';
import { TravelOptionService } from './api/travelOption.service';
import { UserService } from './api/user.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        AccessGroupService,
        AccessRequestService,
        AnnouncementService,
        CaregiverService,
        CountryService,
        CurrencyService,
        GetThereService,
        HistoryDataService,
        KYCAuditLogService,
        MileageReimbursementRateService,
        PatientService,
        PatientPortalAccessRequestService,
        RegionService,
        ReportService,
        RequestService,
        RequestStatusLogService,
        SiteService,
        StudyService,
        TravelOptionService,
        UserService,
    ],
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error(
                'You need to import the HttpClientModule in your AppModule! \n' +
                    'See also https://github.com/angular/angular/issues/20575'
            );
        }
    }
}
