<medpace-editable-card
    #editableCard
    [mode]="mode"
    [title]="'Travel Information'"
    [showTitleAsteriskWhenEditMode]="true"
    [showSwitchModeButton]="showSwitchModeButton"
    [isLoading]="isLoading"
    class="o-grid"
    [id]="'travelInformation'"
    [formGroup]="formGroup"
>
    <medpace-card-header>
        <div class="c-card__ribbon">
            <div class="c-card__item c-card__item--left mb-2">
                <h3>{{ editableCard.title }}</h3>
                <span
                    class="required-asterisk"
                    *ngIf="editableCard.showTitleAsteriskWhenEditMode && editableCard.mode === 'edit'"
                    >*</span
                >
            </div>
            <mds-button
                *ngIf="showBookButton && editableCard.mode === 'view'"
                [id]="'bookViaGetthere'"
                (clickEvent)="bookViaGetthereClickPatient()"
                label="BOOK VIA GETTHERE"
                [variant]="'outline'"
                [disabled]="!isGetThereButtonEnabled()"
            ></mds-button>
            <ng-container *ngIf="editableCard.showSwitchModeButton">
                <mds-button
                    *ngIf="editableCard.mode === 'edit'"
                    (clickEvent)="editableCard.handleSaveClick()"
                    label="Save"
                    [variant]="'outline'"
                    [id]="'saveBtn'"
                ></mds-button>
                <mds-button
                    *ngIf="editableCard.mode === 'view'"
                    (clickEvent)="editableCard.handleEditClick()"
                    label="Edit"
                    [variant]="'outline'"
                    [id]="'editBtn'"
                ></mds-button>
            </ng-container>
        </div>
    </medpace-card-header>
    <medpace-card-content>
        <medpace-loading-card *ngIf="isLoading === true"></medpace-loading-card>
        <div *ngIf="isLoading === false">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <label class="caregiver-label">
                        Which patient caregiver(s) will be attending the visit? <span style="color: red">*</span>
                    </label>
                    <div [id]="'accompanyingCaregivers'" style="display: flex; flex-direction: column; margin-top: 7px">
                        <ng-container
                            *ngTemplateOutlet="
                                caregiverCheckboxGroup;
                                context: {
                                    data: {
                                        label:
                                            'Primary Caregiver ' +
                                            getCaregiverFullName(
                                                formGroup.controls.accompanyingCaregivers.controls.primaryCaregiver
                                                    .value.value.caregiver
                                            ),
                                        checkedFormControl:
                                            formGroup.controls.accompanyingCaregivers.controls.primaryCaregiver.controls
                                                .checked,
                                        caregiver:
                                            formGroup.controls.accompanyingCaregivers.controls.primaryCaregiver.value
                                                .value.caregiver,
                                        bookable:
                                            formGroup.controls.accompanyingCaregivers.controls.primaryCaregiver.controls
                                                .value.value.bookable,
                                        status: request?.status,
                                    },
                                }
                            "
                        ></ng-container>
                        <ng-container
                            *ngFor="
                                let caregiverControl of formGroup.controls.accompanyingCaregivers.controls
                                    .otherCaregivers.controls
                            "
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    caregiverCheckboxGroup;
                                    context: {
                                        data: {
                                            label:
                                                'Caregiver ' +
                                                getCaregiverFullName(caregiverControl.value.value.caregiver),
                                            checkedFormControl: caregiverControl.controls.checked,
                                            caregiver: caregiverControl.value.value.caregiver,
                                            bookable: caregiverControl.controls.value.value.bookable,
                                            status: request?.status,
                                        },
                                    }
                                "
                            ></ng-container
                        ></ng-container>

                        <mds-checkbox
                            [label]="'No caregivers will accompany patient'"
                            [formCtrl]="formGroup.controls.accompanyingCaregivers.controls.none.controls.checked"
                            [id]="'accompanyingCaregivers_None_Checkbox'"
                        >
                        </mds-checkbox>

                        <medpace-alert
                            *ngFor="let error of formGroup.controls.accompanyingCaregivers.errors | keyvalue"
                            [additionalClass]="'danger'"
                            [content]="error.value"
                            [attr.data-cy]="'accompanyingCaregiversAlert'"
                        ></medpace-alert>
                    </div>
                </div>
            </div>

            <hr style="margin-top: 40px; margin-bottom: 20px; border-top: 1px solid #b0b0b08a" />
            <medpace-alert
                *ngFor="let error of formGroup.controls.travelDetails.errors | keyvalue"
                [additionalClass]="'danger'"
                [content]="error.value"
                [attr.data-cy]="'travelDetailsAlert'"
            ></medpace-alert>
            <ng-container *ngIf="editableCard.mode === 'edit'">
                <medpace-alert
                    *ngFor="let alert of alerts$ | async"
                    [additionalClass]="alert.type"
                    [content]="alert.content"
                ></medpace-alert>
            </ng-container>
            <div class="o-grid__row">
                <div class="o-grid__item" [id]="'travel-information-tabs'" *ngIf="study$ | async as study">
                    <mds-tab-group
                        data-cy="travel-tab-group"
                        [headerPosition]="'above'"
                        [stretchTabs]="false"
                        [tabAlign]="'center'"
                        [preserveContent]="false"
                        [fitInkBarToContent]="false"
                        [dynamicHeight]="true"
                        [disablePagination]="false"
                        [animationDuration]="'0ms'"
                    >
                        <!-- FLIGHT TAB -->
                        <mds-tab
                            #flightTab
                            [label]="'Flights'"
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.travelDetails.controls.needsAirTravel,
                                formGroup.controls.travelDetails.controls.flightDetails,
                                formGroup.controls.travelDetails.controls.flightTransactionDetails,
                            ]"
                            [filterContent]="flightTab.label"
                        >
                            <div [id]="'flightTab'">
                                <ng-container
                                    [ngSwitch]="
                                        isTravelOptionAvailable(study?.studyInfo?.studyTravelOptions, 'Flights')
                                    "
                                >
                                    <ng-container *ngSwitchCase="true">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                needsTravelRadioGroup;
                                                context: {
                                                    data: {
                                                        label: 'Will the patient need air travel?',
                                                        formControl:
                                                            formGroup.controls.travelDetails.controls.needsAirTravel,
                                                        disabled: isLimitedService(study),
                                                    },
                                                }
                                            "
                                        ></ng-container>

                                        <ng-container
                                            *ngIf="formGroup.controls.travelDetails.controls.needsAirTravel.value"
                                        >
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <mds-expansion-panel
                                                class="expansion-panel-request"
                                                [panelTitle]="'Flight preferences'"
                                                [description]="'(from Patient Profile)'"
                                            >
                                                <medpace-flight-preferences
                                                    [formGroup]="
                                                        formGroup.controls.travelDetails.controls.flightPreferences
                                                    "
                                                    [mode]="'view'"
                                                ></medpace-flight-preferences>
                                            </mds-expansion-panel>
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-flight-request-details
                                                [mode]="isLimitedService(study) ? 'view' : editableCard.mode"
                                                [accompanyingCaregivers]="getAccompanyingFlightCaregivers()"
                                                [formGroup]="formGroup.controls.travelDetails.controls.flightDetails"
                                                [historyData]="historyData.flight"
                                            ></medpace-flight-request-details>

                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-transaction-details
                                                *ngIf="isUpdate"
                                                [mode]="editableCard.mode"
                                                [formGroup]="
                                                    formGroup.controls.travelDetails.controls.flightTransactionDetails
                                                "
                                                [currencyOptions]="currencyOptions"
                                                (downloadAttachmentEvent)="downloadAttachment($event)"
                                            ></medpace-transaction-details>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <ng-template
                                            *ngTemplateOutlet="alert; context: { data: { type: 'flight' } }"
                                        ></ng-template>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mds-tab>

                        <!-- TRAIN TAB -->
                        <mds-tab
                            [label]="'Train'"
                            #trainTab
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.travelDetails.controls.needsTrainTravel,
                                formGroup.controls.travelDetails.controls.trainDetails,
                                formGroup.controls.travelDetails.controls.trainTransactionDetails,
                            ]"
                            [filterContent]="trainTab.label"
                            ><div [id]="'trainTab'">
                                <ng-container
                                    [ngSwitch]="isTravelOptionAvailable(study?.studyInfo?.studyTravelOptions, 'Trains')"
                                >
                                    <ng-container *ngSwitchCase="true">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                needsTravelRadioGroup;
                                                context: {
                                                    data: {
                                                        label: 'Will the patient need train travel?',
                                                        formControl:
                                                            formGroup.controls.travelDetails.controls.needsTrainTravel,
                                                        disabled: isLimitedService(study),
                                                    },
                                                }
                                            "
                                        ></ng-container>
                                        <ng-container
                                            *ngIf="formGroup.controls.travelDetails.controls.needsTrainTravel.value"
                                        >
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <mds-expansion-panel
                                                class="expansion-panel-request"
                                                [panelTitle]="'Train preferences'"
                                                [description]="'(from Patient Profile)'"
                                            >
                                                <medpace-train-preferences
                                                    [formGroup]="
                                                        formGroup.controls.travelDetails.controls.trainPreferences
                                                    "
                                                    [mode]="'view'"
                                                ></medpace-train-preferences>
                                            </mds-expansion-panel>

                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-train-request-details
                                                [mode]="isLimitedService(study) ? 'view' : editableCard.mode"
                                                [formGroup]="formGroup.controls.travelDetails.controls.trainDetails"
                                                [accompanyingCaregivers]="getAccompanyingTrainCaregivers()"
                                                [historyData]="historyData.train"
                                            ></medpace-train-request-details>
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-transaction-details
                                                *ngIf="isUpdate"
                                                [mode]="editableCard.mode"
                                                [formGroup]="
                                                    formGroup.controls.travelDetails.controls.trainTransactionDetails
                                                "
                                                [currencyOptions]="currencyOptions"
                                                (downloadAttachmentEvent)="downloadAttachment($event)"
                                            ></medpace-transaction-details>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <ng-template
                                            *ngTemplateOutlet="alert; context: { data: { type: 'train' } }"
                                        ></ng-template
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </mds-tab>

                        <!-- LODGING TAB -->
                        <mds-tab
                            [label]="'Lodging'"
                            #lodgingTab
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.travelDetails.controls.needsLodging,
                                formGroup.controls.travelDetails.controls.lodgingDetails,
                                formGroup.controls.travelDetails.controls.lodgingTransactionDetails,
                            ]"
                            [filterContent]="lodgingTab.label"
                        >
                            <div [id]="'lodgingTab'">
                                <ng-container
                                    [ngSwitch]="isTravelOptionAvailable(study?.studyInfo?.studyTravelOptions, 'Hotels')"
                                >
                                    <ng-container *ngSwitchCase="true">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                needsTravelRadioGroup;
                                                context: {
                                                    data: {
                                                        label: 'Will the patient need lodging?',
                                                        formControl:
                                                            formGroup.controls.travelDetails.controls.needsLodging,
                                                        disabled: false,
                                                    },
                                                }
                                            "
                                        ></ng-container>
                                        <ng-container
                                            *ngIf="formGroup.controls.travelDetails.controls.needsLodging.value"
                                        >
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <mds-expansion-panel
                                                class="expansion-panel-request"
                                                [panelTitle]="'Lodging preferences'"
                                                [description]="'(from Patient Profile)'"
                                            >
                                                <medpace-lodging-preferences
                                                    [formGroup]="
                                                        formGroup.controls.travelDetails.controls.lodgingPreferences
                                                    "
                                                    [mode]="'view'"
                                                ></medpace-lodging-preferences>
                                            </mds-expansion-panel>

                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-lodging-request-details
                                                [mode]="editableCard.mode"
                                                [formGroup]="formGroup.controls.travelDetails.controls.lodgingDetails"
                                                [historyData]="historyData.lodging"
                                            ></medpace-lodging-request-details>
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-transaction-details
                                                *ngIf="isUpdate"
                                                [mode]="editableCard.mode"
                                                [formGroup]="
                                                    formGroup.controls.travelDetails.controls.lodgingTransactionDetails
                                                "
                                                [currencyOptions]="currencyOptions"
                                                (downloadAttachmentEvent)="downloadAttachment($event)"
                                            ></medpace-transaction-details>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <ng-template
                                            *ngTemplateOutlet="alert; context: { data: { type: 'hotel' } }"
                                        ></ng-template
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </mds-tab>

                        <!-- RENTAL CAR TAB -->
                        <mds-tab
                            [label]="'Rental Car'"
                            #carRentalTab
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.travelDetails.controls.needsCarRental,
                                formGroup.controls.travelDetails.controls.carRentalDetails,
                                formGroup.controls.travelDetails.controls.carRentalTransactionDetails,
                            ]"
                            [filterContent]="carRentalTab.label"
                        >
                            <div [id]="'carRentalTab'">
                                <ng-container
                                    [ngSwitch]="
                                        isTravelOptionAvailable(study?.studyInfo?.studyTravelOptions, 'Rental Cars')
                                    "
                                >
                                    <ng-container *ngSwitchCase="true">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                needsTravelRadioGroup;
                                                context: {
                                                    data: {
                                                        label: 'Will the patient need a rental car?',
                                                        formControl:
                                                            formGroup.controls.travelDetails.controls.needsCarRental,
                                                        disabled: false,
                                                    },
                                                }
                                            "
                                        ></ng-container>
                                        <ng-container
                                            *ngIf="formGroup.controls.travelDetails.controls.needsCarRental.value"
                                        >
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <mds-expansion-panel
                                                class="expansion-panel-request"
                                                [panelTitle]="'Rental car preferences'"
                                                [description]="'(from Patient Profile)'"
                                            >
                                                <medpace-car-rental-preferences
                                                    [formGroup]="
                                                        formGroup.controls.travelDetails.controls.carRentalPreferences
                                                    "
                                                    [mode]="'view'"
                                                ></medpace-car-rental-preferences>
                                            </mds-expansion-panel>
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-car-rental-request-details
                                                [mode]="editableCard.mode"
                                                [formGroup]="formGroup.controls.travelDetails.controls.carRentalDetails"
                                                [timeOptions]="timeOptions"
                                                [countryOptions]="countryOptionsStringArray"
                                                [historyData]="historyData.carRental"
                                            ></medpace-car-rental-request-details>
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-transaction-details
                                                *ngIf="isUpdate"
                                                [mode]="editableCard.mode"
                                                [formGroup]="
                                                    formGroup.controls.travelDetails.controls
                                                        .carRentalTransactionDetails
                                                "
                                                [currencyOptions]="currencyOptions"
                                                (downloadAttachmentEvent)="downloadAttachment($event)"
                                            ></medpace-transaction-details>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <ng-template
                                            *ngTemplateOutlet="alert; context: { data: { type: 'rental car' } }"
                                        ></ng-template
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </mds-tab>

                        <!-- CAR SERVICE TAB -->
                        <mds-tab
                            [label]="'Car Service'"
                            #transferTab
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.travelDetails.controls.needsTransfer,
                                formGroup.controls.travelDetails.controls.transferDetails,
                                formGroup.controls.travelDetails.controls.transferTransactionDetails,
                            ]"
                            [filterContent]="transferTab.label"
                        >
                            <div [id]="'car-service-tab'">
                                <ng-container
                                    [ngSwitch]="
                                        isTravelOptionAvailable(study?.studyInfo?.studyTravelOptions, 'Car Services')
                                    "
                                >
                                    <ng-container *ngSwitchCase="true">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                needsTravelRadioGroup;
                                                context: {
                                                    data: {
                                                        label: 'Will the patient need a car service?',
                                                        formControl:
                                                            formGroup.controls.travelDetails.controls.needsTransfer,
                                                        disabled: false,
                                                    },
                                                }
                                            "
                                        ></ng-container>
                                        <ng-container
                                            *ngIf="formGroup.controls.travelDetails.controls.needsTransfer.value"
                                        >
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <mds-expansion-panel
                                                class="expansion-panel-request"
                                                [panelTitle]="'Car service preferences'"
                                                [description]="'(from Patient Profile)'"
                                            >
                                                <medpace-transfer-preferences
                                                    [formGroup]="
                                                        formGroup.controls.travelDetails.controls.transferPreferences
                                                    "
                                                    [mode]="'view'"
                                                ></medpace-transfer-preferences>
                                            </mds-expansion-panel>

                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-transfer-request-details
                                                [mode]="editableCard.mode"
                                                [formGroup]="formGroup.controls.travelDetails.controls.transferDetails"
                                                [timeOptions]="timeOptions"
                                                [historyData]="historyData.car"
                                            ></medpace-transfer-request-details>
                                            <ng-container *ngTemplateOutlet="hr"></ng-container>
                                            <medpace-transaction-details
                                                *ngIf="isUpdate"
                                                [mode]="editableCard.mode"
                                                [formGroup]="
                                                    formGroup.controls.travelDetails.controls.transferTransactionDetails
                                                "
                                                [currencyOptions]="currencyOptions"
                                                (downloadAttachmentEvent)="downloadAttachment($event)"
                                            ></medpace-transaction-details>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <ng-template
                                            *ngTemplateOutlet="alert; context: { data: { type: 'car service' } }"
                                        ></ng-template
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </mds-tab>

                        <mds-tab
                            [label]="'Other'"
                            #otherTab
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.travelDetails.controls.needsOther,
                                formGroup.controls.travelDetails.controls.otherDetails,
                                formGroup.controls.travelDetails.controls.otherTransactionDetails,
                            ]"
                            [filterContent]="otherTab.label"
                        >
                            <div [id]="'otherTab'">
                                <ng-container
                                    *ngTemplateOutlet="
                                        needsTravelRadioGroup;
                                        context: {
                                            data: {
                                                label: 'Will the patient need other accommodations?',
                                                formControl: formGroup.controls.travelDetails.controls.needsOther,
                                                disabled: false,
                                            },
                                        }
                                    "
                                ></ng-container>
                                <ng-container *ngIf="formGroup.controls.travelDetails.controls.needsOther.value">
                                    <ng-container *ngTemplateOutlet="hr"></ng-container>
                                    <medpace-other-request-details
                                        [mode]="editableCard.mode"
                                        [formGroup]="formGroup.controls.travelDetails.controls.otherDetails"
                                        [historyData]="historyData.other"
                                    ></medpace-other-request-details>
                                    <ng-container *ngTemplateOutlet="hr"></ng-container>
                                    <medpace-transaction-details
                                        *ngIf="isUpdate"
                                        [mode]="editableCard.mode"
                                        [formGroup]="formGroup.controls.travelDetails.controls.otherTransactionDetails"
                                        [currencyOptions]="currencyOptions"
                                        (downloadAttachmentEvent)="downloadAttachment($event)"
                                    ></medpace-transaction-details>
                                </ng-container>
                            </div>
                        </mds-tab>
                    </mds-tab-group>
                </div>
            </div>
        </div>
    </medpace-card-content>
</medpace-editable-card>
<ng-template #needsTravelRadioGroup let-data="data">
    <div class="o-grid needs-travel-radio-group">
        <ng-container *ngIf="data.disabled">
            <medpace-alert
                [content]="'Study does not support this travel option.'"
                [additionalClass]="'warning'"
            ></medpace-alert>
        </ng-container>
        <ng-container *ngIf="!data.disabled || (isUpdate && data.formControl?.value)">
            <div class="o-grid__row">
                <label>{{ data.label }}</label>
            </div>
            <div class="o-grid__row">
                <mat-radio-group [formControl]="data.formControl" style="display: flex; flex-direction: column">
                    <mat-radio-button [value]="true" [id]="'needsTravel_Yes_Radio'">Yes</mat-radio-button>
                    <mat-radio-button [value]="false" [id]="'needsTravel_No_Radio'">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #hr>
    <hr style="margin-top: 2.5rem; margin-bottom: 2.5rem; border-top: 1px solid black" />
</ng-template>
<ng-template #caregiverCheckboxGroup let-data="data">
    <div *ngIf="data.caregiver" style="display: flex; justify-content: space-between; align-items: center">
        <mds-checkbox [attr.data-cy]="'caregiverCheckbox'" [label]="data.label" [formCtrl]="data.checkedFormControl">
        </mds-checkbox>
        <ng-container *ngIf="showBookButton && editableCard.mode === 'view' && data.checkedFormControl.value">
            <mds-button
                [id]="'getthere-book-btn'"
                *ngIf="data.bookable"
                (clickEvent)="bookViaGetthereClickCaregiver(data.caregiver)"
                label="BOOK VIA GETTHERE"
                buttonSize="small"
                [variant]="'outline'"
                [disabled]="!isGetThereButtonEnabled()"
            ></mds-button>
            <mds-button
                [id]="'no-booking-btn'"
                *ngIf="!data.bookable"
                label="NOTHING TO BOOK"
                buttonSize="small"
                [variant]="'outline'"
                [disabled]="true"
            ></mds-button>
        </ng-container>
    </div>
</ng-template>

<ng-template #alert let-data="data">
    <div class="mt-1">
        <medpace-alert [additionalClass]="'primary'" [content]="getAlertContent(data.type)"></medpace-alert>
    </div>
</ng-template>
