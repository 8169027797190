import { utc } from 'moment';
import {
    Announcement,
    AnnouncementDTO,
    AnnouncementStatus,
    CreateAnnouncementDTO,
    UpdateAnnouncementDTO,
} from '../../models/announcement';
import {
    mapAnnouncementFilterOptionDTOToAnnouncementFilterOption,
    mapAnnouncementFilterOptionToAnnouncementFilterOptionDTO,
} from './announcementFilterOption-transform';
export function mapAnnouncementToUpdateAnnouncementDTO(model: Announcement): UpdateAnnouncementDTO {
    return <UpdateAnnouncementDTO>{
        id: model.id,
        title: model.title,
        description: model.description,
        statusId: model.status as number,
        expireTimestamp: model.expireTimestamp.toISOString(),
        lastModifiedUserId: model.lastModifiedUserId,
        lastModifiedUserName: model.author,
        filterOptions: model.filterOptions.map((option) =>
            mapAnnouncementFilterOptionToAnnouncementFilterOptionDTO(option)
        ),
    };
}

export function mapAnnouncementToCreateAnnouncementDTO(model: Announcement): CreateAnnouncementDTO {
    return <CreateAnnouncementDTO>{
        title: model.title,
        description: model.description,
        statusId: model.status as number,
        expireTimestamp: model.expireTimestamp.toISOString(),
        lastModifiedUserId: model.lastModifiedUserId,
        filterOptions: model.filterOptions.map((option) =>
            mapAnnouncementFilterOptionToAnnouncementFilterOptionDTO(option)
        ),
    };
}

export function mapAnnouncementDTOToAnnouncement(dto: AnnouncementDTO): Announcement {
    return <Announcement>{
        ...dto,
        status: dto.statusId as AnnouncementStatus,
        expireTimestamp: utc(dto.expireTimestamp),
        lastModifiedUserId: dto.lastModifiedUserId,
        author: dto.lastModifiedUserName,
        filterOptions: dto.filterOptions.map((option) =>
            mapAnnouncementFilterOptionDTOToAnnouncementFilterOption(option)
        ),
    };
}
