import { LoadingViewTriggerService } from '@services/loading-module-trigger/loading-module-trigger.service';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { UserService } from '@services/user/user.service';

@Component({
    selector: 'medpace-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class MedpaceLoginComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private loadingViewTriggerService: LoadingViewTriggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    isAuthenticated$: Observable<boolean>;
    iFrameCloseObservable$: Observable<boolean>;
    isIFrame: boolean = true;
    iFrameId: string = 'loginframe';

    ngOnInit(): void {
        this.isAuthenticated$ = this.authService.getIsAuthenticated();
        this.isAuthenticated$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((isAuthenticated) => {
                    if (isAuthenticated) {
                        this.loadingViewTriggerService.openLoading();
                        this.isIFrame = false;
                    }
                })
            )
            .subscribe();

        this.userService
            .getUser()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((user) => {
                    if (user) {
                        user.isAdmin
                            ? this.router.navigateByUrl('admin/dashboard')
                            : this.router.navigateByUrl('crc/dashboard');
                        this.loadingViewTriggerService.closeLoading();
                    }
                })
            )
            .subscribe();

        window.addEventListener('storage', this.onLocalStorageChange.bind(this));
    }

    onLocalStorageChange(event: StorageEvent) {
        if (event.key === 'Hide Frame') {
            if (event.oldValue === 'true') {
                this.isIFrame = false;
            }
        }
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.loadingViewTriggerService.closeLoading();
    }
}
