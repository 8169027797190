<div class="o-page">
    <div class="o-grid">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <h3>Study Overview</h3>
                </div>
                <div class="o-grid__item" *ngIf="isAdmin">
                    <div class="flex end">
                        <mds-button
                            [id]="'expense-report-btn'"
                            variant="outline"
                            label="Expense Report"
                            (clickEvent)="downloadExpenseReport()"
                        ></mds-button>
                    </div>
                </div>
                <div class="o-grid__item" *ngIf="isAddPatientButtonVisible()">
                    <div class="flex end">
                        <mds-button
                            [id]="'add-patient-btn'"
                            variant="primary"
                            label="Add Patient"
                            (clickEvent)="addNewPatient()"
                        ></mds-button>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isAdmin">
                <div class="o-grid__item">
                    <medpace-info-card
                        [data]="study"
                        [isAdmin]="isAdmin"
                        type="study"
                        inputCTA="ADDITIONAL STUDY DETAIL"
                        [contentHeaders]="['Sponsor', 'Protocol', 'Project ID']"
                        [contentMapping]="infoColumnMap"
                        (navButtonClick)="goToDetails()"
                    ></medpace-info-card>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="!isAdmin">
                <div class="o-grid__item">
                    <medpace-info-card
                        [data]="study"
                        type="study"
                        [isAdmin]="isAdmin"
                        [contentHeaders]="['Sponsor', 'Protocol', 'Project ID']"
                        [contentMapping]="infoColumnMap"
                        (navButtonClick)="goToDetails()"
                    ></medpace-info-card>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isAdmin">
                <div class="o-grid__item">
                    <mds-expansion-panel
                        [panelTitle]="'Sites'"
                        [description]="''"
                        icon="account_circle"
                        [expanded]="false"
                        id="sitesExpansion"
                        [attr.data-cy]="'sitesExpansion'"
                    >
                        <medpace-table
                            [isCard]="false"
                            [data]="filteredSites"
                            [columns]="columns"
                            [fieldMap]="columnMap"
                            title="Sites"
                            searchPlaceholder="Country"
                            [filterOptions]="countries"
                            [ifFiltering]="true"
                            [isSearching]="true"
                            (rowClickEvent)="doClickSite($event)"
                            [defaultSorting]="{ columnName: 'siteNumber', direction: 'asc' }"
                        ></medpace-table>
                    </mds-expansion-panel>
                </div>
            </div>

            <div class="o-grid__row" *ngIf="isAdmin">
                <div class="o-grid__item">
                    <mds-expansion-panel
                        [panelTitle]="'Study Utilization'"
                        [description]="''"
                        icon="account_circle"
                        [expanded]="false"
                        *ngIf="studyUtilizationData$ | async as studyUtilizationData"
                    >
                        <h5>Study Utilization At A Glance</h5>
                        <div class="utilization-tables">
                            <table>
                                <tr>
                                    <td>Sites Scoped</td>
                                    <td>{{ studyUtilizationData.sitesScoped }}</td>
                                </tr>
                                <tr>
                                    <td>Sites Onboarded</td>
                                    <td>{{ studyUtilizationData.sitesOnboarded }}</td>
                                </tr>
                                <tr>
                                    <td>Sites Utilizing PCS</td>
                                    <td>{{ studyUtilizationData.sitesUtilizingPCS }}</td>
                                </tr>
                            </table>

                            <table>
                                <tr>
                                    <td>Patients Scoped</td>
                                    <td>{{ studyUtilizationData.patientsScoped }}</td>
                                </tr>
                                <tr>
                                    <td>Patient Profiles Created</td>
                                    <td>{{ studyUtilizationData.patientProfilesCreated }}</td>
                                </tr>
                                <tr>
                                    <td>Patients Utilizing PCS</td>
                                    <td>{{ studyUtilizationData.patientsUtilizingPCS }}</td>
                                </tr>
                            </table>
                        </div>
                    </mds-expansion-panel>
                </div>
            </div>

            <div class="o-grid__row" *ngIf="isAdmin">
                <div class="o-grid__item">
                    <mds-expansion-panel
                        [panelTitle]="'Reports'"
                        [description]="''"
                        icon="account_circle"
                        [expanded]="false"
                        *ngIf="studyUtilizationData$ | async as studyUtilizationData"
                    >
                        <h5>Download Reports</h5>
                        <div class="reports-grid">
                            <div>
                                <mds-button
                                    (clickEvent)="downloadFullStudyUtilizationReport()"
                                    label="Full Study Utilization"
                                    [variant]="'primary'"
                                    width="100%"
                                />
                                <mds-button
                                    (clickEvent)="downloadCRCLastLoginReport()"
                                    label="CRC Last Login"
                                    [variant]="'primary'"
                                    width="100%"
                                />
                                <mds-button
                                    (clickEvent)="downloadPatientProfileCreationReport()"
                                    label="Patient Profiles Creation"
                                    [variant]="'primary'"
                                    width="100%"
                                />
                            </div>
                            <div>
                                <mds-button
                                    (clickEvent)="downloadPatientLastRequestSubmittedAtReport()"
                                    label="Patients Last Requests"
                                    [variant]="'primary'"
                                    width="100%"
                                />
                                <mds-button
                                    (clickEvent)="downloadSiteRequestCountReport()"
                                    label="Open Requests"
                                    [variant]="'primary'"
                                    width="100%"
                                />
                            </div>
                        </div>
                    </mds-expansion-panel>
                </div>
            </div>
            @if (isAdmin) {
                <ng-container *ngIf="{ vm: pendingSites$ | async } as pendingSites; else spinner">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <mds-expansion-panel
                                [panelTitle]="'Pending Sites from ClinTrak'"
                                [description]="''"
                                icon="access_time"
                                [attr.data-cy]="'pendingSites'"
                            >
                                <medpace-table
                                    [isCard]="false"
                                    [data]="pendingSites.vm || undefined"
                                    [columns]="columnsClinTrakSites"
                                    [fieldMap]="clinTrakSitesColumnMap"
                                    title=""
                                    searchPlaceholder="Country"
                                    [isSearching]="true"
                                    [filterOptions]="clinTrakCountries"
                                    [ifFiltering]="true"
                                    (rowClickEvent)="doClickPendingSite($event)"
                                ></medpace-table>
                            </mds-expansion-panel>
                        </div>
                    </div>
                </ng-container>
            }
            <div class="o-grid__row" *ngIf="!isAdmin">
                <div class="o-grid__item">
                    <div class="c-card">
                        <div class="c-card__header">
                            <div class="c-card__ribbon">
                                <div class="c-card__item c-card__item--left">
                                    <h3>Patients</h3>
                                </div>
                                <div class="c-card__item c-card__item--right">
                                    <div class="c-card__search">
                                        <mds-text-field
                                            data-cy="table-search"
                                            appearance="outline"
                                            label="Search"
                                            startIcon="search"
                                            (keyup)="applyFilter($event)"
                                        ></mds-text-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="c-card__content">
                            <!-- we're doing a one-off table here because of the additional column. -->
                            <div class="c-table">
                                <mds-table
                                    id="patient-table"
                                    [dataSource]="patients"
                                    [hasCustomRowDefs]="true"
                                    columnCase="uppercase"
                                    dataWhiteSpace="nowrap"
                                    style="display: grid"
                                    matSort
                                >
                                    <ng-container matColumnDef="id">
                                        <mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header
                                            sortActionDescription="Sort by id"
                                            >PATIENT ID</mat-header-cell
                                        >
                                        <mat-cell *matCellDef="let element">{{ element.patientNum }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="pcsId">
                                        <mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header
                                            sortActionDescription="Sort by PCS ID"
                                            >PCS ID</mat-header-cell
                                        >
                                        <mat-cell *matCellDef="let element">{{ element.pcsId }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="firstName">
                                        <mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header
                                            sortActionDescription="Sort by firstname"
                                            >FIRST NAME</mat-header-cell
                                        >
                                        <mat-cell *matCellDef="let element">{{ element.firstName }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="lastName">
                                        <mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header
                                            sortActionDescription="Sort by lastname"
                                            >LAST NAME</mat-header-cell
                                        >
                                        <mat-cell *matCellDef="let element">{{ element.lastName }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="lastVisitDate">
                                        <mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header
                                            sortActionDescription="Sort by last visit date"
                                            >LAST VISIT DATE</mat-header-cell
                                        >
                                        <mat-cell *matCellDef="let element">{{ element.lastVisitDate }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <mat-header-cell *matHeaderCellDef>ACTION</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <button
                                                id="patientMenu"
                                                mat-icon-button
                                                [matMenuTriggerFor]="actionMenu"
                                                [disabled]="element['isPatientDeleted']"
                                            >
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #actionMenu>
                                                <button
                                                    id="patientOverview"
                                                    mat-menu-item
                                                    (click)="goToPatient(element)"
                                                >
                                                    Patient Overview
                                                </button>
                                                <button
                                                    *ngIf="canCreateTravelRequest(element.site)"
                                                    id="newTravelRequest"
                                                    mat-menu-item
                                                    (click)="newTravelRequest(element)"
                                                >
                                                    New Travel Request
                                                </button>
                                                <button
                                                    *ngIf="canCreatePaymentRequest(element.site)"
                                                    id="newPaymentRequest"
                                                    mat-menu-item
                                                    (click)="newPaymentRequest(element)"
                                                >
                                                    New Payment Request
                                                </button>
                                            </mat-menu>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="patientColumns"> </mat-header-row>
                                    <mat-row
                                        (click)="row.patientActionExpanded = !row.patientActionExpanded"
                                        [class.mdc-data-table__row-removed]="row['isPatientDeleted']"
                                        *matRowDef="let row; columns: patientColumns"
                                        [attr.title]="GetTitle(row)"
                                    ></mat-row>
                                </mds-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <mds-spinner
            color="primary"
            class="loading-card"
            mode="indeterminate"
            [diameter]="50"
            [strokeWidth]="6"
        ></mds-spinner>
    </div>
</ng-template>
