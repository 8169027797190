import { EMPTY, fromEvent, Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { map, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    private scrollY$: Observable<number>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.scrollY$ = fromEvent(window, 'scroll').pipe(
                map((event) => {
                    return window.scrollY || this.document.documentElement.scrollTop;
                }),
                share()
            );
        } else {
            //in non-browser environments, provide an empty observable so you can safely subscribe to scroll$
            this.scrollY$ = EMPTY;
        }
    }

    getScrollObservable(): Observable<number> {
        return this.scrollY$;
    }
}
