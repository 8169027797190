/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { RentalCarHistoryData } from '@models/history-data/rentalCarHistoryData';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, getUniqueObjects, getUniqueStrings, MdsOptionGeneric } from '@utility/utility';
import { equalOrMoreThanTodayOnlyIfCRCDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment, utc } from 'moment';
import { map, Observable, of, startWith } from 'rxjs';
export class CarRentalRequestDetailsFormGroup extends PersistentFormGroup<{
    pickupAddress: PersistentFormControl<string>;
    pickupCountry: PersistentFormControl<string>;
    pickupDate: PersistentFormControl<Moment>;
    pickupTime: PersistentFormControl<number>;
    dropOffDate: PersistentFormControl<Moment>;
    dropOffTime: PersistentFormControl<number>;
    specialRequests: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<CarRentalRequestDetailsFormGroup>): CarRentalRequestDetailsFormGroup {
        return new CarRentalRequestDetailsFormGroup(
            {
                pickupAddress: new PersistentFormControl(value.pickupAddress, [
                    Validators.required,
                    maxLengthValidator(200),
                ]),
                pickupCountry: new PersistentFormControl(value.pickupCountry, [Validators.required]),
                pickupDate: new PersistentFormControl(value.pickupDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                pickupTime: new PersistentFormControl(value.pickupTime, [Validators.required]),
                dropOffDate: new PersistentFormControl(value.dropOffDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                dropOffTime: new PersistentFormControl(value.dropOffTime, [Validators.required]),
                specialRequests: new PersistentFormControl(value.specialRequests, [maxLengthValidator(1000)]),
            },
            [
                (control: AbstractControl) => {
                    const typedControl = control as CarRentalRequestDetailsFormGroup;
                    const value = typedControl.getRawValue();
                    if (!value.pickupDate || !value.dropOffDate) return null;
                    const pickupDateTime = value.pickupDate.startOf('day').add(value.pickupTime, 'minutes');
                    const dropoffDateTime = value.dropOffDate.startOf('day').add(value.dropOffTime, 'minutes');
                    return pickupDateTime < dropoffDateTime
                        ? null
                        : {
                              invalidDates: 'Dropoff date and time must be later than pickup',
                          };
                },
            ]
        );
    }
}
@Component({
    selector: 'medpace-car-rental-request-details',
    templateUrl: './car-rental-request-details.component.html',
    styleUrls: ['./car-rental-request-details.component.scss'],
})
export class CarRentalRequestDetailsComponent {
    @Input() mode: EditableCardMode;
    @Input() formGroup: CarRentalRequestDetailsFormGroup;
    @Input() timeOptions: MdsOptionGeneric<number>[];
    protected filteredCountryOptions$: Observable<string[]>;
    @Input() set countryOptions(options: string[]) {
        if (!this.formGroup) this.filteredCountryOptions$ = of([]);
        this.filteredCountryOptions$ = this.formGroup.controls.pickupCountry.valueChanges.pipe(
            startWith(this.formGroup.controls.pickupCountry.value),
            map((country) => {
                let searchTerm = '';
                if (!!country) {
                    searchTerm = country as string; // whatever user types with his keyboard,
                } else return options;
                return options.filter((option) => option.toLowerCase().startsWith(searchTerm.toLowerCase()));
            })
        );
    }
    @Input() set historyData(value: RentalCarHistoryData[]) {
        // every component should create its own MdsOptions so they dont share values
        this.autocompleteOptions = {
            pickupAddress: getUniqueStrings(
                value.filter((data) => !!data.pickUpLocation).map((data) => data.pickUpLocation)
            ),
            specialRequests: getUniqueObjects(
                value
                    .filter((data) => !!data.specialRequest)
                    .map(
                        (data) =>
                            <MedpaceAutocompleteObject>{ requestDate: data.requestDate, value: data.specialRequest }
                    )
            ),
        };
    }
    protected autocompleteOptions: {
        pickupAddress: string[];
        specialRequests: MedpaceAutocompleteObject[];
    };
    getDateFromMinutesSinceMidnight(minutesSinceMidnight: number) {
        return utc().startOf('day').add(minutesSinceMidnight, 'minutes');
    }
}
