import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingViewTriggerService {
    isLoadingScreen: boolean = false;
    loadingSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    loadingObservable: Observable<boolean> = this.loadingSubject.asObservable();

    constructor() {
        this.loadingSubject.next(this.isLoadingScreen);
    }

    observeLoading(): Observable<boolean> {
        return this.loadingObservable;
    }
    private persistent: boolean;
    openLoading(options?: { persistent: boolean }): void {
        if (options) this.persistent = options.persistent;

        this.isLoadingScreen = true;
        this.loadingSubject.next(this.isLoadingScreen);
    }

    closeLoading(options?: { force: boolean }): void {
        if (!this.persistent || options?.force) {
            this.persistent = false;
            this.isLoadingScreen = false;
            this.loadingSubject.next(this.isLoadingScreen);
        }
    }
}
