import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CRCLastLoginReportData } from 'src/app/models/utilizationReporting/crcLastLoginReportData';
import { FullStudyUtilizationReportData } from 'src/app/models/utilizationReporting/fullStudyUtilizationReportData';
import { PatientLastRequestSubmittedAtReportData } from 'src/app/models/utilizationReporting/patientLastRequestSubmittedReportData';
import { PatientProfileCreationReportData } from 'src/app/models/utilizationReporting/patientProfileCreationReportData';
import { SiteRequestCountReportData } from 'src/app/models/utilizationReporting/siteRequestCountReportData';
import { SiteService } from 'src/app/services/site/site.service';
@Injectable()
export class ReportRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getStudyExpenses(studyId: number): Observable<ArrayBuffer> {
        return this.client.get(`${this.siteService.getSiteBaseUrl()}/api/reports/study/${studyId}/expenses`, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders({ 'Timezone-Offset': new Date().getTimezoneOffset() }),
        });
    }
    getFullStudyUtilizationReportData(studyId: number) {
        return this.client.get<FullStudyUtilizationReportData>(
            `${this.siteService.getSiteBaseUrl()}/api/reports/study/${studyId}/utilizationReportData`
        );
    }
    getCRCLastLoginReportData(studyId: number) {
        return this.client.get<CRCLastLoginReportData[]>(
            `${this.siteService.getSiteBaseUrl()}/api/reports/study/${studyId}/crcLastLoginReportData`
        );
    }
    getPatientLastRequestSubmittedAtReportData(studyId: number) {
        return this.client.get<PatientLastRequestSubmittedAtReportData[]>(
            `${this.siteService.getSiteBaseUrl()}/api/reports/study/${studyId}/patientLastRequestSubmittedAtReportData`
        );
    }
    getSiteRequestCountReportData(studyId: number) {
        return this.client.get<SiteRequestCountReportData[]>(
            `${this.siteService.getSiteBaseUrl()}/api/reports/study/${studyId}/siteRequestCountReportData`
        );
    }
    getPatientProfileCreationReportData(studyId: number) {
        return this.client.get<PatientProfileCreationReportData[]>(
            `${this.siteService.getSiteBaseUrl()}/api/reports/study/${studyId}/patientProfileCreationReportData`
        );
    }
}
