import { Validators } from '@angular/forms';
import { PersistentFormGroup } from '@utility/persistent-forms';
import { maxLengthValidator, phoneNumberFormatValidatorWithMaxLength } from '@utility/utility.validators';
import { EditFieldFormGroup } from './edit-field.formgroup';
type TControl = {
    address1: EditFieldFormGroup<string | null>;
    address2: EditFieldFormGroup<string | null>;
    city: EditFieldFormGroup<string | null>;
    country: EditFieldFormGroup<string | null>;
    state: EditFieldFormGroup<string | null>;
    zipcode: EditFieldFormGroup<string | null>;
    phone: EditFieldFormGroup<string | null>;
    preferredLang: EditFieldFormGroup<string | null>;
};
export class AddressEditFormGroup extends PersistentFormGroup<TControl> {
    constructor() {
        super({
            address1: new EditFieldFormGroup<string | null>('Address 1', null, 'string', [
                maxLengthValidator(100),
                Validators.required,
            ]),
            address2: new EditFieldFormGroup<string | null>('Address 2', null, 'string', [maxLengthValidator(100)]),
            city: new EditFieldFormGroup<string | null>('City', null, 'string', [
                maxLengthValidator(100),
                Validators.required,
            ]),
            state: new EditFieldFormGroup<string | null>('Province / State / Region', null, 'string', [
                maxLengthValidator(100),
            ]),
            zipcode: new EditFieldFormGroup<string | null>('Zipcode', null, 'string', [maxLengthValidator(10)]),
            country: new EditFieldFormGroup<string | null>('Country', null, 'country', [
                maxLengthValidator(65),
                Validators.required,
            ]),
            phone: new EditFieldFormGroup<string | null>('Phone number', null, 'string', [
                phoneNumberFormatValidatorWithMaxLength(16),
                Validators.required,
            ]),
            preferredLang: new EditFieldFormGroup<string | null>('Preferred language', null, 'string', [
                maxLengthValidator(50),
                Validators.required,
            ]),
        });
    }
    disableIfUnchanged() {
        const controls = Object.values(this.controls);
        controls.forEach((control) => {
            control.disableIfUnchanged();
        });

        if (!controls.some((control) => control.enabled)) this.disable({ persistent: true });
        else this.enable({ breakPersistence: true });
    }
}
