import { AbstractControl, FormGroup } from '@angular/forms';

declare module '@angular/forms' {
    interface FormGroup {
        recursiveUpdateValueAndValidity(): void;
    }

    interface AbstractControl {
        setEnabled(enable: boolean): void;
    }
}
FormGroup.prototype.recursiveUpdateValueAndValidity = function (this: FormGroup) {
    Object.values(this.controls).forEach((control) => {
        if (control instanceof FormGroup) {
            control.recursiveUpdateValueAndValidity();
        } else {
            control.updateValueAndValidity();
        }
    });
    this.updateValueAndValidity();
};

AbstractControl.prototype.setEnabled = function (enable: boolean) {
    if (enable) {
        this.enable();
    } else {
        this.disable();
    }
};
