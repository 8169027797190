import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Study } from '../../models/study';

import { SiteService } from 'src/app/services/site/site.service';

@Injectable()
export class CRCStudyRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}
    getAllStudySummaries: () => Observable<Study[]> = () => {
        return this.client.get<Study[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/allsummaries`);
    };
}
