import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { SiteService } from './../../app/services/site/site.service';
import { UserService } from './../../app/services/user/user.service';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
    isRoleSet: boolean = false;
    usersAddress: string = '/api/users';
    constructor(
        private siteService: SiteService,
        private userService: UserService
    ) {
        this.userService
            .doesUserHaveRole()
            .pipe(
                tap((isRole) => {
                    this.isRoleSet = isRole;
                })
            )
            .subscribe();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRoleSet) {
            if (
                request.url.indexOf(this.siteService.getSiteBaseUrl()) === -1 ||
                request.url.indexOf(this.usersAddress) != -1
            ) {
                return next.handle(request);
            }
        }
        return next.handle(request);
    }
}
