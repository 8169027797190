import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientDB } from '@models/generated-patient';
import { RequestSummary } from '@models/request';
import { SaveStatusResponse } from '@utility/utility';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { SiteService } from 'src/app/services/site/site.service';
import { convertToFormData } from 'src/app/utility/formData-util';
import { KycAuditLog } from '../../models/kycAuditLog';
import {
    Caregiver,
    CaregiverDTO,
    PatchPatientDataModel,
    PatientDataModel,
    PatientDetailsDataModel,
} from '../../models/patient';
import { PatientEditRequestDto } from '../../models/patientEditRequestDto';

@Injectable()
export class AdminPatientRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getAllPatientSummaries: () => Observable<PatientDetailsDataModel[]> = () => {
        return this.client.get<PatientDetailsDataModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/detailed/patientsummaries`
        );
    };

    createPatient: (patient: PatientDataModel) => Observable<HttpResponse<number>> = (patient: PatientDataModel) => {
        const formData = convertToFormData(patient);
        return this.client.post<number>(`${this.siteService.getSiteBaseUrl()}/api/patients`, formData, {
            observe: 'response',
        });
    };

    getPatientById: (patientId: number) => Observable<PatientDB> = (patientId: number) => {
        return this.client.get<PatientDB>(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}`);
    };

    getPatientTrialSummary: (patientId: number) => Observable<PatientDataModel> = (patientId: number) => {
        return this.client.get<PatientDataModel>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/detailed`
        );
    };

    updatePatient: (patientId: number, patient: PatientDataModel) => Observable<SaveStatusResponse> = (
        patientId: number,
        patient: PatientDataModel
    ) => {
        const formData = convertToFormData(patient);
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}`,
            formData
        );
    };

    patchPatient: (
        patientId: number,
        patientOld: PatchPatientDataModel,
        patientNew: PatchPatientDataModel
    ) => Observable<any> = (
        patientId: number,
        patientOld: PatchPatientDataModel,
        patientNew: PatchPatientDataModel
    ) => {
        const patchData = compare(patientOld, patientNew);
        return this.client.patch<number>(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}`, patchData);
    };

    deletePatient: (patientId: number) => Observable<any> = (patientId: number) => {
        return this.client.delete<number>(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}`);
    };

    createKYCDownloadLog: (log: KycAuditLog) => Observable<any[]> = (log: KycAuditLog) => {
        return this.client.post<any[]>(`${this.siteService.getSiteBaseUrl()}/api/kycauditlog/logkycdownload`, log);
    };

    deleteCaregiver: (patientId: number, caregiverId: number) => Observable<any> = (
        patientId: number,
        caregiverId: number
    ) => {
        return this.client.delete<number>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/caregiver/${caregiverId}`
        );
    };

    getPatientCaregivers: (patientId: number) => Observable<CaregiverDTO[]> = (patientId: number) => {
        return this.client.get<CaregiverDTO[]>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/caregivers`
        );
    };

    createCaregiver: (caregiver: Caregiver) => Observable<any> = (caregiver: Caregiver) =>
        this.client.post(`${this.siteService.getSiteBaseUrl()}/api/patients/caregiver`, caregiver);

    updateCaregiver: (caregiver: Caregiver) => Observable<any> = (caregiver: Caregiver) =>
        this.client.put(`${this.siteService.getSiteBaseUrl()}/api/patients/caregiver`, caregiver);

    getPatientKycDocumentUri(patientId: number, kycDocumentId: number): Observable<URL> {
        // return this.client.get<File>(
        //     `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/kyc/${kycDocumentId}`
        // );
        return of(new URL(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/kyc/${kycDocumentId}`));
    }

    deletePatientKycDocument(patientId: number, kycDocumentId: number): Observable<boolean> {
        return this.client.delete<boolean>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/kyc/${kycDocumentId}`
        );
    }

    getAllEditRequests(): Observable<PatientEditRequestDto[]> {
        return this.client.get<PatientEditRequestDto[]>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/editRequests`
        );
    }

    getEditRequest(patientId: number): Observable<PatientEditRequestDto> {
        return this.client.get<PatientEditRequestDto>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/editRequest`
        );
    }

    getSubmittedEditRequestCount(): Observable<number> {
        return this.client.get<number>(`${this.siteService.getSiteBaseUrl()}/api/patients/editRequests/count`);
    }

    removeEditRequest(patientId: number): Observable<SaveStatusResponse> {
        return this.client.delete<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/editRequest`
        );
    }

    getPatientRequestSummaries(patientId: number): Observable<RequestSummary[]> {
        return this.client.get<RequestSummary[]>(
            `${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/requestSummaries`
        );
    }
}
