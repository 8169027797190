import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GroupInterceptor } from './grp.interceptor';
import { CacheInterceptor } from './cache.interceptor';
import { LoadingInterceptor } from './loading.interceptor';
import { ErrorHandlerInterceptor } from './error-handler.interceptor';
import { LoginInterceptor } from './login.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: GroupInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
];
