<medpace-editable-card
    title="Visit Details"
    mode="edit"
    [showSwitchModeButton]="false"
    [showTitleAsteriskWhenEditMode]="true"
    [isLoading]="viewModel === null"
    [formGroup]="getFormGroup()"
    class="o-grid"
    ><medpace-editable-card-edit-mode-content *ngIf="viewModel">
        <div *ngIf="viewModel.requestType === RequestTypes.PAYMENT">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Site #</h5>
                        <span>{{ paymentRequest_Handler.viewModel.siteNumber }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Site Address</h5>
                        <span
                            >{{ paymentRequest_Handler.viewModel.siteAddress.line1 }}&nbsp;{{
                                paymentRequest_Handler.viewModel.siteAddress.line2
                            }}&nbsp;{{ paymentRequest_Handler.viewModel.siteAddress.city }} ,&nbsp;{{
                                paymentRequest_Handler.viewModel.siteAddress.stateOrProvince
                            }}&nbsp;{{ paymentRequest_Handler.viewModel.siteAddress.postalCode }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="Visit Name"
                        placeholder="Select Visit"
                        id="visitNameDropdown"
                        data-cy="visitNameDropdown"
                        [options]="paymentRequest_Handler.viewModel.visitNameOptions"
                        [formCtrl]="paymentRequest_Handler.formGroup.controls.visitName"
                        [required]="true"
                        (selectionChange)="paymentRequest_Handler.onVisitNameChange($event)"
                    ></mds-dropdown>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <div class="c-datepicker">
                        <medpace-datepicker
                            [formCtrl]="paymentRequest_Handler.formGroup.controls.visitDate"
                            id="visitDate"
                            data-cy="visitDateDatepicker"
                            appearance="outline"
                            label="Visit Date"
                            [required]="true"
                            (dateChange)="paymentRequest_Handler.onVisitDateChange($event)"
                        ></medpace-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="viewModel.requestType === RequestTypes.TRAVEL">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Site #</h5>
                        <span>{{ travelRequest_Handler.viewModel.siteNumber }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Site Address</h5>
                        <span
                            >{{ travelRequest_Handler.viewModel.siteAddress.line1 }}&nbsp;{{
                                travelRequest_Handler.viewModel.siteAddress.line2
                            }}&nbsp;{{ travelRequest_Handler.viewModel.siteAddress.city }} ,&nbsp;{{
                                travelRequest_Handler.viewModel.siteAddress.stateOrProvince
                            }}&nbsp;{{ travelRequest_Handler.viewModel.siteAddress.postalCode }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-textarea
                        floatLabel="always"
                        [formCtrl]="travelRequest_Handler.formGroup.controls.specialNavigationInstructions"
                        (input)="travelRequest_Handler.onSpecialNavigationInstructionsChange($event.target.value)"
                        id="specialNav"
                        [cdkAutosizeMinRows]="8"
                        [cdkAutosizeMaxRows]="8"
                        label="Additional Instructions to Find Address"
                        placeholder="Type here..."
                        appearance="outline"
                        [autoSize]="true"
                    ></mds-textarea>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="Visit Name"
                        placeholder="Select Visit"
                        id="visitNameDropdown"
                        data-cy="visitNameDropdown"
                        [options]="travelRequest_Handler.viewModel.visitNameOptions"
                        [formCtrl]="travelRequest_Handler.formGroup.controls.visitName"
                        [required]="true"
                        (selectionChange)="travelRequest_Handler.onVisitNameChange($event)"
                    ></mds-dropdown>
                </div>
            </div>

            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <medpace-datepicker
                        [formCtrl]="travelRequest_Handler.formGroup.controls.visitStartDate"
                        id="visitStartDate"
                        data-cy="visitStartDateDatepicker"
                        appearance="outline"
                        label="Visit Start Date"
                        [required]="true"
                        (dateChange)="travelRequest_Handler.onVisitStartDateChange($event)"
                    ></medpace-datepicker>
                </div>
                <div class="o-grid__field half">
                    <medpace-datepicker
                        [formCtrl]="travelRequest_Handler.formGroup.controls.visitEndDate"
                        id="visitEndDate"
                        data-cy="visitEndDateDatepicker"
                        appearance="outline"
                        label="Visit End Date"
                        [required]="true"
                        (dateChange)="travelRequest_Handler.onVisitEndDateChange($event)"
                    ></medpace-datepicker>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="Appt. Start Time"
                        placeholder="Select time"
                        id="startDropdown"
                        [options]="travelRequest_Handler.viewModel.timePickerOptions"
                        [formCtrl]="travelRequest_Handler.formGroup.controls.visitStartTime"
                        [required]="true"
                        (selectionChange)="travelRequest_Handler.onVisitStartTimeChange($event)"
                    ></mds-dropdown>
                </div>
                <div class="o-grid__field half">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        [formCtrl]="travelRequest_Handler.formGroup.controls.visitEndTime"
                        appearance="outline"
                        label="Appt. End Time"
                        placeholder="Select time"
                        [options]="travelRequest_Handler.viewModel.timePickerOptions"
                        id="endDropdown"
                        [required]="true"
                        (selectionChange)="travelRequest_Handler.onVisitEndTimeChange($event)"
                    ></mds-dropdown>
                </div>
            </div>
        </div>
    </medpace-editable-card-edit-mode-content>
</medpace-editable-card>
