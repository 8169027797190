<div>
    <div class="c-card">
        <div *ngIf="requestTable.length !== 0; else spinner">
            <div class="c-card__header">
                <div class="c-card__ribbon">
                    <div class="c-card__item c-card__item--left mb-2">
                        <h6>REQUESTS OVERVIEW</h6>
                    </div>
                    <div class="c-card__item c-card__item--right">
                        <mds-button
                            [id]="'allRequestsBtn'"
                            (clickEvent)="goToTable()"
                            variant="primary"
                            label="View all Requests"
                            [variant]="'outline'"
                        ></mds-button>
                    </div>
                </div>
            </div>
            <div class="c-card__content">
                <div class="c-info">
                    <div class="o-grid">
                        <div class="o-grid__row">
                            <div class="o-grid__item">Travel Requests</div>
                            <div class="o-grid__item">{{ travel }}</div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">Reimbursement Requests</div>
                            <div class="o-grid__item">{{ oop }}</div>
                        </div>
                        <div class="o-grid__row">
                            <div class="o-grid__item">Stipend Requests</div>
                            <div class="o-grid__item">{{ stipend }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #spinner>
            <div class="spinnerOverlay">
                <medpace-loading-card></medpace-loading-card>
            </div>
        </ng-template>
    </div>
</div>
