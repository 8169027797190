<div class="div-main">
    <div *ngFor="let rows of createRows" class="o-grid__row">
        <div *ngFor="let r of rows.vals" class="o-grid__col">
            <div class="o-grid__field">
                <selected-custom-dropdown
                    *ngIf="r.type === 'MultiDropDown'"
                    (inputValueChange)="addInputChange($event)"
                    (applyFilterEvent)="applyFilters()"
                    [labelName]="r.labelText"
                    [data]="r.values"
                    [dataName]="r.filterByName"
                    [setToDefaults]="setToDefaults"
                    [isPatientUrl]="isPatientOverviewUrl || isPatientAllUrl"
                    [selectedValues]="r.selectedValues"
                >
                </selected-custom-dropdown>
                <selected-custom-datepicker
                    *ngIf="r.type === 'RangeDatePicker'"
                    (inputValueChange)="addInputChange($event)"
                    [labelName]="r.labelText"
                    [setToDefaults]="setToDefaults"
                    [selectedValues]="r.selectedValues"
                    (applyFilterEvent)="applyFilters()"
                >
                </selected-custom-datepicker>
            </div>
        </div>
    </div>
    <div class="filterBtns flex start">
        <mds-button
            [id]="'filter-apply-btn'"
            (clickEvent)="onApplyFiltersClicked()"
            variant="primary"
            label="APPLY FILTERS"
        >
        </mds-button>
        <mds-button
            [id]="'reset-btn'"
            (clickEvent)="onResetFiltersClicked()"
            variant="outline"
            color="warn"
            label="RESET"
        >
        </mds-button>
    </div>
</div>
