import { Component, computed, inject, input, signal, Signal } from '@angular/core';
import { CountryViewModel } from '@models/country';
import { Patient } from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { CountryStateService } from '@services/state-management/country-state.service';
import { tap } from 'rxjs';
import { EditFieldFormGroup } from '../edit-field.formgroup';
import { PatientEditRequestFormGroup } from './patient-edit-request.formgroup';
@Component({
    selector: 'medpace-patient-edit-request',
    templateUrl: './patient-edit-request.component.html',
})
export class PatientEditRequestComponent {
    formGroup = input.required<PatientEditRequestFormGroup>();
    patient = input.required<Patient>();
    editRequest = input.required<PatientEditRequestDto>();
    countryStateService = inject(CountryStateService);
    countries = signal<CountryViewModel[]>([]);
    countries$ = this.countryStateService.getCountries().pipe(
        tap((countries) => {
            this.countries.set(countries);
        })
    );

    editFields: Signal<EditFieldFormGroup<any>[]> = computed(() => {
        const formGroup = this.formGroup();
        const patient = this.patient();
        const editRequest = this.editRequest();
        const countries = this.countries();
        if (!formGroup || !patient || !editRequest || countries.length === 0) return;
        formGroup.setCurrentValue(patient);
        formGroup.setRequestedValue(editRequest, countries);
        formGroup.disableIfUnchanged();

        const fields = Object.values(formGroup.controls.address.controls);

        return fields.filter((control) => control.enabled);
    });
}
