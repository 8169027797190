import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Target } from '@models/event-objects/target';
import { SiteSettings } from '@models/site-option-models';
import { SupportTier } from '@models/study';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { SiteOptionService } from '@services/site-option/site-option.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { UserService } from '@services/user/user.service';
import { PersistentFormControl } from '@utility/persistent-forms';
import { maxLengthValidator } from '@utility/utility.validators';
import { Observable, Subject, combineLatest, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Site, SitePayment, SiteServices } from 'src/app/models/site';

@Component({
    selector: 'medpace-site-services-card',
    templateUrl: './site-services-card.component.html',
    styleUrls: ['../../../organisms/input-card/input-card.component.scss'],
})
export class MedpaceSiteServicesCardComponent implements OnChanges, OnInit, OnDestroy {
    serviceOptions: SiteServices;
    private componentDestroyed$: Subject<boolean> = new Subject();
    paymentOptions: SitePayment;

    @Input() isEditing: boolean;
    @Input() isPatientProfile: boolean;
    @Input() parentFormGroup: FormGroup;
    @Input() isModal: boolean = false;
    @Input() studyId: number;

    expanded: boolean;
    isStipendChecked: boolean = false;
    isOtherChecked: boolean = false;
    isRequiredPaymentOptions: boolean = false;
    isRequiredSpecificService: boolean = false;
    isTravelDisabled: boolean = false;
    loaderSpinner: boolean = true;

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();
    optionsText: SiteSettings = this.siteOptionService.getSiteSettings();

    readyForPaymentValidationFormGroup = this.formBuilder.group({
        kycDocumentationControl: new FormControl(false),
        readyForPaymentControl: new FormControl(false),
    });
    SpecificServicesFormGroup = this.formBuilder.group({
        travelSupportControl: new PersistentFormControl(false),
        reimbursementControl: new FormControl(false),
        stipendControl: new FormControl(false),
        stipendDetails: new FormControl('', [maxLengthValidator(500)]),
        otherControl: new FormControl({ value: false, disabled: true }),
        otherDetails: new FormControl('', [maxLengthValidator(500)]),
    });
    PaymentOptionsFormGroup = this.formBuilder.group({
        prePaidDebitCardControl: new FormControl({ value: false, disabled: false }),
        checkControl: new FormControl({ value: false, disabled: false }),
        wireTransferControl: new FormControl({ value: false, disabled: false }),
        collectBankDetailsControl: new FormControl({ value: false, disabled: false }),
        reimburseControl: new FormControl({ value: false, disabled: false }),
    });
    PatientPortalFormGroup = this.formBuilder.group({
        allowInvitations: new FormControl<boolean>(false),
    });

    public isAdmin$ = this.userService.getUser().pipe(map((user) => user.isAdmin || user.isSuperAdmin));

    travelRequestsPresent$: Observable<boolean> = this.stateService.getSite().pipe(
        switchMap((site: Site) => {
            //handle modal
            if (this.isModal || !site.id) {
                return of(0);
            } else {
                return this.adminRequestService.getTravelRequestCountSite(site?.id);
            }
        }),
        map((value) => value > 0),
        tap((value: boolean) => {
            if (value) {
                //if site has travel requests, the Travel Support option cannot be unchecked
                const travelSupportControl = <PersistentFormControl>(
                    this.SpecificServicesFormGroup.controls.travelSupportControl
                );
                travelSupportControl.setPersistence({ disable: true });
            }
        })
    );
    constructor(
        private formBuilder: FormBuilder,
        private stateService: SiteStateService,
        private userService: UserService,
        private adminStudyServices: AdminStudyServices,
        private adminRequestService: AdminRequestServices,
        private siteOptionService: SiteOptionService
    ) {}

    ngOnInit(): void {
        this.parentFormGroup.addControl(
            'checkedSpecificServicesFormGroup',
            new FormControl(false, Validators.requiredTrue)
        );
        this.parentFormGroup.addControl('readyForPaymentValidationFormGroup', this.readyForPaymentValidationFormGroup);
        this.parentFormGroup.addControl('PaymentOptionsFormGroup', this.PaymentOptionsFormGroup);
        this.parentFormGroup.addControl('SpecificServicesFormGroup', this.SpecificServicesFormGroup);

        this.stateService
            .getSite()
            .pipe(
                takeUntil(this.componentDestroyed$),
                switchMap((site: Site) => {
                    if (site?.services && site?.payment) {
                        this.serviceOptions = site.services;
                        this.paymentOptions = site.payment;
                        this.setCurrentValues(this.serviceOptions, this.paymentOptions);
                    }

                    this.validateCheckbox();
                    if (!!site) {
                        return combineLatest([this.adminStudyServices.getStudy(site?.studyId), of(this.isModal)]);
                    } else if (this.isModal) {
                        return combineLatest([this.adminStudyServices.getStudy(this.studyId), of(this.isModal)]);
                    } else return combineLatest([of(null), of(this.isModal)]);
                })
            )
            .pipe(
                tap(([study, _]) => {
                    if (!!study && study.supportTier === SupportTier.payment) {
                        const travelControl = <PersistentFormControl>(
                            this.SpecificServicesFormGroup.controls.travelSupportControl
                        );
                        travelControl.disable();
                        travelControl.setPersistence({ disable: true });
                        this.isTravelDisabled = true;
                    }
                })
            )
            .subscribe(([study, modal]) => {
                if (!!study || modal) this.loaderSpinner = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        changes.isEditing && changes.isEditing.currentValue ? this.enableAll() : this.disableAll();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    validateCheckbox() {
        const isAnySpecificServiceUsed =
            this.SpecificServicesFormGroup.controls.travelSupportControl.value == true ||
            this.SpecificServicesFormGroup.controls.reimbursementControl.value == true ||
            this.SpecificServicesFormGroup.controls.stipendControl.value == true;

        this.parentFormGroup.controls.checkedSpecificServicesFormGroup.setValue(isAnySpecificServiceUsed);
        this.isRequiredSpecificService = !isAnySpecificServiceUsed;

        if (
            (this.SpecificServicesFormGroup.controls.reimbursementControl.value == true ||
                this.SpecificServicesFormGroup.controls.stipendControl.value == true) &&
            Object.values(this.PaymentOptionsFormGroup.controls).some((control) => control.value == true)
        ) {
            this.parentFormGroup.controls.checkedSpecificServicesFormGroup.setValue(true);
            this.isRequiredPaymentOptions = false;
        } else if (
            (this.SpecificServicesFormGroup.controls.reimbursementControl.value == true ||
                this.SpecificServicesFormGroup.controls.stipendControl.value == true) &&
            !Object.values(this.PaymentOptionsFormGroup.controls).some((control) => control.value == true)
        ) {
            this.parentFormGroup.controls.checkedSpecificServicesFormGroup.setValue(false);
            this.isRequiredPaymentOptions = true;
        } else this.isRequiredPaymentOptions = false;
    }

    disableAll() {
        Object.values(this.readyForPaymentValidationFormGroup.controls).forEach((control) => control.disable());
        Object.values(this.SpecificServicesFormGroup.controls).forEach((control) => control.disable());
        Object.values(this.PaymentOptionsFormGroup.controls).forEach((control) => control.disable());
        Object.values(this.PatientPortalFormGroup.controls).forEach((control) => control.disable());
    }

    enableAll() {
        Object.values(this.readyForPaymentValidationFormGroup.controls).forEach((control) => control.enable());
        Object.values(this.SpecificServicesFormGroup.controls).forEach((control) => control.enable());

        if (this.SpecificServicesFormGroup.controls.reimbursementControl.value == true)
            Object.values(this.PaymentOptionsFormGroup.controls).forEach((control) => control.enable());

        Object.values(this.PatientPortalFormGroup.controls).forEach((control) => control.enable());
    }

    setCurrentValues(servicesOptions: SiteServices, paymentOptons: SitePayment) {
        this.readyForPaymentValidationFormGroup.patchValue({
            kycDocumentationControl: servicesOptions.kycDocumentation,
            readyForPaymentControl: servicesOptions.readyForPayment,
        });

        this.SpecificServicesFormGroup.patchValue({
            travelSupportControl: servicesOptions.travel,
            reimbursementControl: servicesOptions.reimbursement,
            stipendControl: servicesOptions.stipend,
            stipendDetails: servicesOptions.stipendDetails,
            otherControl: servicesOptions.other,
            otherDetails: servicesOptions.otherDetails,
        });

        this.PaymentOptionsFormGroup.patchValue({
            prePaidDebitCardControl: paymentOptons.credit,
            checkControl: paymentOptons.check,
            wireTransferControl: paymentOptons.wireTransfer,
            collectBankDetailsControl: paymentOptons.collectBankDetails,
            reimburseControl: paymentOptons.reimburse,
        });

        this.PatientPortalFormGroup.patchValue({
            allowInvitations: servicesOptions.allowPpinvitations,
        });
        this.isStipendChecked = this.serviceOptions.stipend;
        this.isOtherChecked = this.serviceOptions.other;

        this.isVisibleOtherCheckbox();
        this.isEditing ? this.enableAll() : this.disableAll();
    }

    emitStipendDetailsChange: (value: string, target: Target) => void = (value: string, target: Target) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };

    emitOtherDetailsChange: (value: string, target: Target) => void = (value: string, target: Target) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };

    isVisibleOtherCheckbox() {
        Object.values(this.SpecificServicesFormGroup.controls).some((control) => control.value == true)
            ? this.SpecificServicesFormGroup.controls.otherControl.enable()
            : this.SpecificServicesFormGroup.controls.otherControl.disable();
    }

    emitChange: (event: MatCheckboxChange) => void = (event: MatCheckboxChange) => {
        this.isVisibleOtherCheckbox();

        if (event.source.id === 'stipendCheckbox') {
            this.isStipendChecked = event.checked;
            if (!event.checked) {
                this.isOtherChecked = event.checked;
                this.SpecificServicesFormGroup.controls.otherControl.setValue(false);
                this.inputChangeEvent.emit({ target: 'other', value: event.checked });
            }
        }

        if (event.source.id === 'otherCheckbox') {
            this.isOtherChecked = event.checked;
        }

        if (
            this.SpecificServicesFormGroup.controls.travelSupportControl.value == true &&
            this.SpecificServicesFormGroup.controls.reimbursementControl.value == false &&
            this.SpecificServicesFormGroup.controls.stipendControl.value == false
        ) {
            Object.values(this.PaymentOptionsFormGroup.controls).forEach((control) => control.disable());
        } else Object.values(this.PaymentOptionsFormGroup.controls).forEach((control) => control.enable());

        this.validateCheckbox();

        this.inputChangeEvent.emit({ target: event.source.name, value: event.checked });
    };
}
