import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { safeUTC } from '@app/extensions/moment-extensions';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { TransferData, TransferData_DTO } from '@models/transferData';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, MdsOptionGeneric } from '@utility/utility';
import { equalOrMoreThanTodayOnlyIfCRCDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment, utc } from 'moment';
import { ReplaySubject, filter, switchMap, tap } from 'rxjs';

export function fromDTO(dto: TransferData_DTO): TransferData {
    return <TransferData>{
        ...dto,
        pickupDateTime: safeUTC(dto.pickupDateTime),
    };
}
export function toDTO(value: TransferData): TransferData_DTO {
    return <TransferData_DTO>{
        ...value,
        pickupDateTime: value.pickupDateTime.toISOString(),
    };
}
export class TransferDataFormGroup extends PersistentFormGroup<{
    pickupDate: PersistentFormControl<Moment>;
    pickupTime: PersistentFormControl<number>;
    pickupAddress: PersistentFormControl<string>;
    dropoffAddress: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<TransferDataFormGroup>): TransferDataFormGroup {
        return new TransferDataFormGroup({
            pickupDate: new PersistentFormControl<Moment>(value.pickupDate, [
                equalOrMoreThanTodayOnlyIfCRCDateValidator,
            ]),
            pickupTime: new PersistentFormControl<number>(value.pickupTime),
            pickupAddress: new PersistentFormControl<string>(value.pickupAddress, [
                Validators.required,
                maxLengthValidator(200),
            ]),
            dropoffAddress: new PersistentFormControl<string>(value.dropoffAddress, [
                Validators.required,
                maxLengthValidator(200),
            ]),
        });
    }
}
@Component({
    selector: 'app-transfer-data',
    templateUrl: './transfer-data.component.html',
    styleUrls: ['./transfer-data.component.scss'],
})
export class TransferDataComponent {
    @Input() public mode: EditableCardMode;
    @Input() public timeOptions: MdsOptionGeneric<number>[];
    @Input() formGroup: TransferDataFormGroup;
    @Input() public index: number;
    @Output() public removeEvent = new EventEmitter<number>();
    private pickupAddressElementSubject = new ReplaySubject<ElementRef>();
    @ViewChild('pickupAddress', { read: ElementRef }) public set pickupAddressElement(value: ElementRef) {
        this.pickupAddressElementSubject.next(value);
    }
    protected readonly pickupAddressChange$ = this.pickupAddressElementSubject.pipe(
        filter((element) => !!element),
        switchMap((element) => {
            const input = element.nativeElement.querySelector('input[type="text"]') as HTMLInputElement;
            const googleMapsService = new GoogleMapsService(this.ngZone, this.dialog);
            googleMapsService.setPlaceListener(input);
            return googleMapsService.observePlaceListener();
        }),
        filter((result) => !!result),
        tap((result) => this.formGroup.controls.pickupAddress.setValue(result.formatted_address))
    );
    private dropoffAddressElementSubject = new ReplaySubject<ElementRef>();
    @ViewChild('dropoffAddress', { read: ElementRef }) public set dropoffAddressElement(value: ElementRef) {
        this.dropoffAddressElementSubject.next(value);
    }
    protected readonly dropoffAddressChange$ = this.dropoffAddressElementSubject.pipe(
        filter((element) => !!element),
        switchMap((element) => {
            const input = element.nativeElement.querySelector('input[type="text"]') as HTMLInputElement;
            const googleMapsService = new GoogleMapsService(this.ngZone, this.dialog);
            googleMapsService.setPlaceListener(input);
            return googleMapsService.observePlaceListener();
        }),
        filter((result) => !!result),
        tap((result) => this.formGroup.controls.dropoffAddress.setValue(result.formatted_address))
    );
    private readonly ngZone = inject(NgZone);
    private readonly snackbarService = inject(SnackbarService);
    private readonly dialog = inject(MatDialog);
    protected remove() {
        this.removeEvent.emit(this.index);
    }
    protected minutesSinceMidnightToMoment(minutesSinceMidnight: number) {
        return utc().startOf('day').add(minutesSinceMidnight, 'minutes');
    }
}
