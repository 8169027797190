import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IMedpaceLogoUploadComponent, UploadStatus } from '@models/interfaces/iMedpaceLogoUploadComponent';
import { getExtension } from 'mime';
import { maxFileSize, maxFileSizeInMB, maxSizeOfFilesInMB } from 'src/globals';
import { AttachmentSizeService } from './../../../services/attachment-size/attachment-size.service';

@Component({
    selector: 'medpace-logo-upload',
    templateUrl: './logo-upload.component.html',
    styleUrls: ['./logo-upload.component.scss'],
})
export class MedpaceLogoUploadComponent implements IMedpaceLogoUploadComponent, OnInit {
    constructor(
        public attachmentSizeService: AttachmentSizeService,
        private sanitizer: DomSanitizer
    ) {}

    files: any[] = [];
    fileName: string;
    maxSizeOfFileInMB: number = 0;
    isFilesSizeTrepasses: boolean = false;
    maxSummaryFileSizesInMB: number;

    url: SafeUrl;
    file: File;

    uploadStatus = UploadStatus;

    @Output()
    emitStuff = new EventEmitter<File>();

    @Output()
    fileNameEmitter = new EventEmitter<string>();

    @Input()
    inputObj: any;
    @Input()
    idVal: string;

    @Input()
    acceptableTypes: string[];

    @Input()
    uploadButtonTitle: string;

    @Input()
    isSpreadsheetUpload: boolean = false;

    @Input()
    isImage: boolean = true;

    @Input()
    forParsing: boolean = false;

    currentUploadStatus: UploadStatus;

    acceptableTypesInput: string;

    acceptableTypesDisplay: string;

    ngOnInit() {
        this.currentUploadStatus = UploadStatus.Empty;
        if (this.inputObj) {
            this.currentUploadStatus = UploadStatus.Available;
            this.fileName = 'document';
        } else {
        }

        this.maxSizeOfFileInMB = maxFileSizeInMB;
        this.maxSummaryFileSizesInMB = maxSizeOfFilesInMB;
        this.acceptableTypesInput = '.' + this.acceptableTypes.reduce((sum, current) => sum + ', .' + current);
        this.acceptableTypesDisplay = '.' + this.acceptableTypes.reduce((sum, current) => sum + ', .' + current);
    }

    setFileSizeTrepassMessage() {
        this.isFilesSizeTrepasses = true;
    }

    setFileSizeNotTrepassMessage() {
        this.isFilesSizeTrepasses = false;
    }

    getFileSize(): number {
        return this.file.size;
    }

    processFiles(event: FileList) {
        this.onFileSelected(event);
    }

    onFileSelected(event: FileList) {
        this.file = event[0];

        if (this.file) {
            if (this.file.size >= maxFileSize) {
                this.currentUploadStatus = UploadStatus.InvalidAttachmentSize;
            } else if (!this.isAcceptableFileType(this.file.type)) {
                this.currentUploadStatus = UploadStatus.UnacceptableTypesInput;
            } else {
                if (this.IsFileTypeImage(this.file.type)) {
                    // TODO: This needs to be reworked - current solution may introduce XSS vulnerabilities
                    // It's responsible for creating a 'preview' of an image after selecting it for an upload
                    this.url = this.sanitizer.bypassSecurityTrustUrl(
                        (window.URL ? URL : webkitURL).createObjectURL(this.file)
                    );
                    this.currentUploadStatus = UploadStatus.AvailableWithUrl;
                } else {
                    this.currentUploadStatus = UploadStatus.Available;
                }

                this.fileName = this.file.name;
                this.fileNameEmitter.emit(this.fileName);
                this.attachmentSizeService.addAttachmentSize(this); // ???
                this.emitStuff.emit(this.file);
            }
        }
    }

    private IsFileTypeImage(fileType: string): boolean {
        return fileType?.indexOf('image') != -1;
    }

    /* istanbul ignore next */
    uploadButtonClicked() {
        const fileUpload = document.getElementById(this.idVal ? this.idVal : 'fileDropRef');
        fileUpload.click();
    }

    private isAcceptableFileType(fileType: string): boolean {
        return this.acceptableTypes.includes(getExtension(fileType));
    }
}
