<div class="o-grid__row" *ngIf="{ isAdmin: isAdmin$ | async } as vm">
    <div class="o-grid__item" *ngIf="!isPatientProfile">
        <div class="c-form__input" *ngIf="!loaderSpinner">
            <div class="c-info__block">
                <h5>Ready For Payment Validation</h5>
            </div>
            <div
                *ngIf="
                    !vm.isAdmin &&
                    readyForPaymentValidationFormGroup.controls.kycDocumentationControl.value === false &&
                    readyForPaymentValidationFormGroup.controls.readyForPaymentControl.value === false
                "
            >
                No payment validation option selected
            </div>
            <form [formGroup]="readyForPaymentValidationFormGroup" ngNativeValidate>
                <div
                    *ngIf="
                        vm.isAdmin || readyForPaymentValidationFormGroup.controls.kycDocumentationControl.value === true
                    "
                >
                    <mds-checkbox
                        id="kycDocumentationCheckbox"
                        [formGrp]="readyForPaymentValidationFormGroup"
                        formCtrlName="kycDocumentationControl"
                        label="KYC Documentation Required"
                        name="kycDocumentation"
                        (changeEvent)="emitChange($event)"
                    >
                    </mds-checkbox>
                    <span class="checkbox-description"
                        >A patient form must be uploaded to the Know Your Customer section of the Patient Profile before
                        checking Ready for Payment</span
                    >
                </div>
                <div
                    *ngIf="
                        vm.isAdmin || readyForPaymentValidationFormGroup.controls.readyForPaymentControl.value === true
                    "
                >
                    <mds-checkbox
                        id="readyForPaymentCheckbox"
                        [formGrp]="readyForPaymentValidationFormGroup"
                        formCtrlName="readyForPaymentControl"
                        label="Ready for Payment Required"
                        name="readyForPayment"
                        (changeEvent)="emitChange($event)"
                    >
                    </mds-checkbox>
                    <span class="checkbox-description"
                        >Ready for Payment must be checked in the Patient Profile before a payment request can be
                        completed</span
                    >
                </div>
            </form>
            <div class="c-info__block header">
                <h5>Specific Services</h5>
                <span *ngIf="isRequiredSpecificService" class="required-asterisk">Option is required</span>
            </div>
            <div
                *ngIf="
                    !vm.isAdmin &&
                    SpecificServicesFormGroup.controls.travelSupportControl.value === false &&
                    SpecificServicesFormGroup.controls.reimbursementControl.value === false &&
                    SpecificServicesFormGroup.controls.stipendControl.value === false &&
                    SpecificServicesFormGroup.controls.otherControl.value === false
                "
            >
                No specific service option selected
            </div>
            <form [formGroup]="SpecificServicesFormGroup" ngNativeValidate class="flex-column">
                <mds-checkbox
                    *ngIf="vm.isAdmin || SpecificServicesFormGroup.controls.travelSupportControl.value === true"
                    id="travelSupportCheckbox"
                    [formGrp]="SpecificServicesFormGroup"
                    formCtrlName="travelSupportControl"
                    [label]="optionsText.travel"
                    (changeEvent)="emitChange($event)"
                    name="travel"
                >
                </mds-checkbox>
                <ng-container *ngIf="travelRequestsPresent$ | async">
                    <span class="checkbox-description travel-support-warning">
                        Option cannot be edited due to pending travel requests related to this site
                    </span>
                </ng-container>
                <mds-checkbox
                    *ngIf="vm.isAdmin || SpecificServicesFormGroup.controls.reimbursementControl.value === true"
                    id="reimbursementCheckbox"
                    [formGrp]="SpecificServicesFormGroup"
                    formCtrlName="reimbursementControl"
                    [label]="optionsText.reimbursement"
                    (changeEvent)="emitChange($event)"
                    name="reimbursement"
                >
                </mds-checkbox>
                <mds-checkbox
                    *ngIf="vm.isAdmin || SpecificServicesFormGroup.controls.stipendControl.value === true"
                    id="stipendCheckbox"
                    [formGrp]="SpecificServicesFormGroup"
                    formCtrlName="stipendControl"
                    [label]="optionsText.stipend"
                    (changeEvent)="emitChange($event)"
                    name="stipend"
                >
                </mds-checkbox>
                <div *ngIf="isStipendChecked">
                    <mds-text-field
                        class="fix-to-checkbox"
                        id="stipendDetails"
                        label=""
                        placeholder="E.g. $50 USD Screening, $50 USD Treatment, $25 USD Follow-Up"
                        name="stipendDetails"
                        (input)="emitStipendDetailsChange($event.target.value, { id: 'stipendDetails' })"
                        width="100%"
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        [formGrp]="SpecificServicesFormGroup"
                        formCtrlName="stipendDetails"
                    ></mds-text-field>
                </div>
                <mds-checkbox
                    *ngIf="vm.isAdmin || SpecificServicesFormGroup.controls.otherControl.value === true"
                    id="otherCheckbox"
                    [formGrp]="SpecificServicesFormGroup"
                    formCtrlName="otherControl"
                    [label]="optionsText.other"
                    (changeEvent)="emitChange($event)"
                    name="other"
                >
                </mds-checkbox>
                <div *ngIf="isOtherChecked">
                    <mds-text-field
                        class="fix-to-checkbox"
                        id="otherDetails"
                        label=""
                        placeholder="E.g. Site requires handling all Reimbursements. Country restricts caregivers from receiving payment…"
                        name="stipendDetails"
                        (input)="emitOtherDetailsChange($event.target.value, { id: 'otherDetails' })"
                        width="100%"
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        [formGrp]="SpecificServicesFormGroup"
                        formCtrlName="otherDetails"
                    ></mds-text-field>
                </div>
            </form>
            <div class="c-info__block header">
                <h5>Payment Options</h5>
                <span *ngIf="isRequiredPaymentOptions" class="required-asterisk">Option is required</span>
            </div>
            <div
                *ngIf="
                    !vm.isAdmin &&
                    PaymentOptionsFormGroup.controls.prePaidDebitCardControl.value === false &&
                    PaymentOptionsFormGroup.controls.checkControl.value === false &&
                    PaymentOptionsFormGroup.controls.wireTransferControl.value === false &&
                    PaymentOptionsFormGroup.controls.reimburseControl.value === false
                "
            >
                No payment option selected
            </div>
            <div class="flex-column">
                <mds-checkbox
                    *ngIf="vm.isAdmin || PaymentOptionsFormGroup.controls.prePaidDebitCardControl.value === true"
                    id="prePaidDebitCardCheckbox"
                    [formGrp]="PaymentOptionsFormGroup"
                    formCtrlName="prePaidDebitCardControl"
                    [label]="optionsText.credit"
                    (changeEvent)="emitChange($event)"
                    name="credit"
                >
                </mds-checkbox>
                <mds-checkbox
                    *ngIf="vm.isAdmin || PaymentOptionsFormGroup.controls.checkControl.value === true"
                    id="checkCheckbox"
                    [formGrp]="PaymentOptionsFormGroup"
                    formCtrlName="checkControl"
                    [label]="optionsText.check"
                    (changeEvent)="emitChange($event)"
                    name="check"
                >
                </mds-checkbox>
                <mds-checkbox
                    *ngIf="vm.isAdmin || PaymentOptionsFormGroup.controls.wireTransferControl.value === true"
                    id="wireTransferCheckbox"
                    [formGrp]="PaymentOptionsFormGroup"
                    formCtrlName="wireTransferControl"
                    [label]="optionsText.wireTransfer"
                    (changeEvent)="emitChange($event)"
                    name="wireTransfer"
                >
                </mds-checkbox>
                <div *ngIf="PaymentOptionsFormGroup.controls.wireTransferControl.value" class="indent">
                    <mds-checkbox
                        id="collectBankDetailsCheckbox"
                        [formGrp]="PaymentOptionsFormGroup"
                        formCtrlName="collectBankDetailsControl"
                        [label]="optionsText.collectBankDetails"
                        (changeEvent)="emitChange($event)"
                        name="collectBankDetails"
                    />
                </div>
                <mds-checkbox
                    *ngIf="vm.isAdmin || PaymentOptionsFormGroup.controls.reimburseControl.value === true"
                    id="reimburseCheckbox"
                    [formGrp]="PaymentOptionsFormGroup"
                    formCtrlName="reimburseControl"
                    [label]="optionsText.reimburse"
                    (changeEvent)="emitChange($event)"
                    name="reimburse"
                >
                </mds-checkbox>
            </div>
            <div class="c-info__block header">
                <h5>Patient Portal</h5>
            </div>
            <div class="flex-column">
                <mds-checkbox
                    id="allowInvitationToPatientPortal"
                    [formCtrl]="PatientPortalFormGroup.controls.allowInvitations"
                    [label]="'Allow the site\'s CRCs to invite new patients to Patient Portal'"
                    (changeEvent)="emitChange($event)"
                    name="allowPpinvitations"
                >
                </mds-checkbox>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
