import { Component, DoCheck, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'medpace-request-dashboard-card',
    templateUrl: './request-dashboard.component.html',
})
export class MedpaceRequestDashboardCardComponent implements DoCheck {
    constructor(private router: Router) {}
    ngDoCheck(): void {
        this.travel = this.requestTable.filter((f) => f.requestType === 'Travel')[0]?.total;
        this.stipend = this.requestTable.filter((f) => f.requestType === 'Stipend')[0]?.total;
        this.oop = this.requestTable.filter((f) => f.requestType === 'Reimbursement')[0]?.total;
    }
    @Input()
    requestTable: any[];

    travel: number;
    stipend: number;
    oop: number;

    goToTable(): void {
        this.router.navigate(['admin/requests']);
    }
}
