<mds-dialog dialogTitle="Request Status">
    <mds-dialog-content [formGroup]="requestStatusChangeFormGroup">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Current Status</h5>
                        <span>{{ this.textStatus }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <div>
                        <mds-dropdown
                            floatLabel="always"
                            [closeOnPageScroll]="false"
                            formCtrlName="statusDropdownControl"
                            appearance="outline"
                            label="Change Status to"
                            placeholder="Please Select Status"
                            [options]="displayStatus"
                            id="statusDropdown"
                            (selectionChange)="updateStatus($event)"
                            [formGrp]="requestStatusChangeFormGroup"
                            [required]="true"
                        ></mds-dropdown>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="!this.isCompletable">
                <div class="o-grid__item">
                    <div>
                        <span
                            >Request cannot be completed because the patient is not ready for payment. Make sure proper
                            forms have been uploaded to Patient Profile.
                        </span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div>
                        <mds-textarea
                            label="Additional Information"
                            floatLabel="always"
                            placeholder="Type Here..."
                            appearance="outline"
                            id="additionalInfo"
                            name="additionalInfo"
                            [autoSize]="true"
                            [cdkAutosizeMinRows]="8"
                            [cdkAutosizeMaxRows]="8"
                            [formGrp]="requestStatusChangeFormGroup"
                            formCtrlName="additionalInfoControl"
                        >
                        </mds-textarea>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button
            [id]="'confirm-btn'"
            id="changeRequestStatusConfirmBtn"
            label="Confirm"
            (clickEvent)="saveDialog()"
            variant="primary"
        >
        </mds-button>
        <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline"> </mds-button>
    </mds-dialog-actions>
</mds-dialog>
