import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Study } from '@models/study';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { SiteStateService } from '@services/state-management/site-state.service';
import { PersistentFormControl } from '@utility/persistent-forms';
import { maxLengthValidator } from '@utility/utility.validators';
import { filter, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Site, SiteInfo } from '../../../../models/site';

@Component({
    selector: 'medpace-site-info',
    templateUrl: './site-info-card.component.html',
})
export class MedpaceSiteInfoComponent implements OnInit, OnDestroy, OnChanges {
    info: SiteInfo;
    site$: Observable<Site> = this.stateService.getSite();
    private componentDestroyed$: Subject<boolean> = new Subject();
    study: Study = null;

    @Input()
    isEditing: boolean;

    @Input() parentFormGroup: FormGroup;

    @Output() inputChangeEvent = new EventEmitter<InputChange>();

    loaderSpinner: boolean = true;

    constructor(
        private stateService: SiteStateService,
        private studyService: AdminStudyServices
    ) {}

    ngOnInit(): void {
        this.initializeFormGroup();
        this.site$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((site) => {
                    if (site) {
                        this.info = site.info;
                    }
                }),
                filter((site) => !!site),
                switchMap((site) => {
                    return this.studyService.getStudy(site.studyId);
                }),
                tap((study) => {
                    this.study = study;
                    this.setValues();
                    this.loaderSpinner = false;
                })
            )
            .subscribe();
    }

    //need this because after click "Edit" all controls are enabled
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.isEditing &&
            !changes.isEditing.previousValue &&
            changes.isEditing.currentValue &&
            this.study?.isFromClinTrak
        ) {
            (this.parentFormGroup.controls.siteNumberControl as PersistentFormControl).disable({ persistent: true });
        }
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    initializeFormGroup() {
        this.parentFormGroup.addControl(
            'siteNumberControl',
            new PersistentFormControl('', [Validators.required, maxLengthValidator(50)])
        );
        this.parentFormGroup.addControl(
            'nameControl',
            new FormControl('', [Validators.required, maxLengthValidator(250)])
        );
        this.parentFormGroup.addControl(
            'pilastNameControl',
            new FormControl('', [Validators.required, maxLengthValidator(75)])
        );
    }

    setValues() {
        if (this.info) {
            this.parentFormGroup.controls.nameControl.setValue(this.info.name);
            this.parentFormGroup.controls.siteNumberControl.setValue(this.info.siteNumber);
            this.parentFormGroup.controls.pilastNameControl.setValue(this.info.pilastName);
            if (this.study?.isFromClinTrak) {
                (this.parentFormGroup.controls.siteNumberControl as PersistentFormControl).disable({
                    persistent: true,
                });
            }
        }
    }

    emitChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };
}
