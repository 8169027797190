<div class="o-grid">
    <ng-container *ngIf="mode === 'view'">
        <!-- Flight input form variant: legacy form with only "Special requests" field visible; to display for pre-August-2023 requests -->
        <ng-container *ngIf="formGroup.value.travelType !== null && formGroup.value.departureDate === null">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Special requests</h5>
                        <span>{{ formGroup.controls.specialRequests.value }}</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Flight input form variant: form with all fields visible -->
        <ng-container
            *ngIf="
                formGroup.value.travelType !== null &&
                formGroup.value.travelType !== TravelType.NO_FLIGHT &&
                formGroup.value.departureDate !== null
            "
        >
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>From airport</h5>
                        <span>{{ formGroup.controls.airportOrigin.value }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>To airport</h5>
                        <span>{{ formGroup.controls.airportDestination.value }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Departure date</h5>
                        <span>{{ formGroup.controls.departureDate.value?.toDate() | dateFormat | uppercase }}</span>
                    </div>
                </div>
                <div class="o-grid__item" *ngIf="formGroup.value.travelType === TravelType.ROUND_TRIP">
                    <div class="c-info__block">
                        <h5>Return date</h5>
                        <span>{{ formGroup.controls.returnDate.value?.toDate() | dateFormat | uppercase }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Special requests</h5>
                        <span>{{ formGroup.controls.specialRequests.value }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="mode === 'edit' && { travelTypeChange: travelTypeChange$ | async }">
        <medpace-alert
            *ngFor="let error of formGroup.errors | keyvalue"
            [additionalClass]="'danger'"
            [content]="error.value"
        ></medpace-alert>
        <div class="o-grid__row">
            <label>
                Choose flight type
                <span class="required-asterisk">*</span>
            </label>
        </div>
        <div class="o-grid__row">
            <mat-radio-group [formControl]="formGroup.controls.travelType">
                <mat-radio-button data-cy="roundTrip_Radio" [value]="TravelType.ROUND_TRIP">Roundtrip</mat-radio-button>
                <mat-radio-button data-cy="oneWay_Radio" [value]="TravelType.ONE_WAY">One way</mat-radio-button>
                <mat-radio-button *ngIf="noFlightOption" data-cy="noFlight_Radio" [value]="TravelType.NO_FLIGHT"
                    >No flight</mat-radio-button
                >
            </mat-radio-group>
        </div>

        <medpace-alert
            *ngIf="formGroup.controls.travelType.hasError('required')"
            [additionalClass]="'danger'"
            [content]="'Please select a flight type'"
        ></medpace-alert>

        <ng-container
            *ngIf="formGroup.value.travelType !== null && formGroup.value.travelType !== TravelType.NO_FLIGHT"
        >
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <mds-autocomplete
                        label="Airport (origin)"
                        [placeholder]="'Type here...'"
                        [floatLabel]="'always'"
                        id="airportOrigin"
                        appearance="outline"
                        floatLabel="auto"
                        [options]="autocompleteOptions.airportOrigin"
                        [formCtrl]="formGroup.controls.airportOrigin"
                    ></mds-autocomplete>
                </div>
                <div class="o-grid__item">
                    <mds-autocomplete
                        label="Airport (destination)"
                        [placeholder]="'Type here...'"
                        [floatLabel]="'always'"
                        id="airportDestination"
                        appearance="outline"
                        floatLabel="auto"
                        [formCtrl]="formGroup.controls.airportDestination"
                        [options]="autocompleteOptions.airportDestination"
                    ></mds-autocomplete>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-datepicker
                        label="Departure Date"
                        appearance="outline"
                        id="departureDate"
                        [formCtrl]="formGroup.controls.departureDate"
                    >
                    </medpace-datepicker>
                </div>
                <div class="o-grid__item">
                    <medpace-datepicker
                        *ngIf="formGroup.value.travelType === TravelType.ROUND_TRIP"
                        label="Return Date"
                        id="returnDate"
                        appearance="outline"
                        [formCtrl]="formGroup.controls.returnDate"
                    >
                    </medpace-datepicker>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-textarea-autocomplete
                        [autoSize]="true"
                        [cdkAutosizeMaxRows]="20"
                        [cdkAutosizeMinRows]="5"
                        label="Special Requests"
                        placeholder="Special requests - e.g. airline preference, seat preference"
                        appearance="outline"
                        id="specialRequests"
                        floatLabel="always"
                        [formCtrl]="formGroup.controls.specialRequests"
                        [options]="autocompleteOptions.specialRequests"
                    ></medpace-textarea-autocomplete>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
