/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { TravelType } from '@app/enums/travel-type.enum';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { FlightHistoryData } from '@models/history-data/flightHistoryData';
import { Caregiver, getCaregiverFullName } from '@models/patient';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { SingleFlightFormGroup } from './flight-details/flight-details.component';

export class CaregiverFlightFormGroup extends PersistentFormGroup<{
    details: PersistentFormGroup<{
        bookIdenticalTrip: PersistentFormControl<boolean>;
        flightDetails: SingleFlightFormGroup;
    }>;
    caregiver: PersistentFormControl<Caregiver>; // helper property, not visible in UI
}> {
    public static create(value: FormGroupValueOf<CaregiverFlightFormGroup>): CaregiverFlightFormGroup {
        return new CaregiverFlightFormGroup({
            caregiver: new PersistentFormControl(value.caregiver),
            details: new PersistentFormGroup({
                flightDetails: SingleFlightFormGroup.create(value.details.flightDetails, true),
                bookIdenticalTrip: new PersistentFormControl(value.details.bookIdenticalTrip, [Validators.required]),
            }),
        });
    }
}
export class FlightRequestDetailsFormGroup extends PersistentFormGroup<{
    patientFlightDetails: SingleFlightFormGroup;
    caregivers: FormArray<CaregiverFlightFormGroup>;
}> {
    public static create(value: FormGroupValueOf<FlightRequestDetailsFormGroup>): FlightRequestDetailsFormGroup {
        return new FlightRequestDetailsFormGroup({
            patientFlightDetails: SingleFlightFormGroup.create(value.patientFlightDetails, false),
            caregivers: new FormArray(
                value.caregivers.map((caregiverDetails) => CaregiverFlightFormGroup.create(caregiverDetails))
            ),
        });
    }
}
@Component({
    selector: 'medpace-flight-request-details',
    templateUrl: './flight-request-details.component.html',
    styleUrls: ['./flight-request-details.component.scss'],
})
export class FlightRequestDetailsComponent {
    public TravelType = TravelType;
    @Input() public mode: EditableCardMode;
    @Input() formGroup: FlightRequestDetailsFormGroup;
    @Input() accompanyingCaregivers: CaregiverFlightFormGroup[];
    @Input() historyData: FlightHistoryData[];
    getCaregiverFullName(caregiver: Caregiver) {
        return getCaregiverFullName(caregiver);
    }
    hasAnyNonDisabledCaregiverControls() {
        return (
            this.formGroup.controls.caregivers.controls.filter((caregiverControl) => !caregiverControl.disabled)
                .length !== 0
        );
    }
}
