<mds-dialog dialogTitle="Change Password" style="max-width: 500px">
    <mds-dialog-content>
        <div *ngIf="(currentUser$ | async)?.hasLocalAuthentication; else noLocalAuthenticationCommunicate">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="flex start middle">
                        <span class="required-asterisk">*</span>
                        <span> indicates a required field </span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="passwordChangeFormGroup"
                        [formCtrlName]="newPasswordFieldName"
                        [id]="newPasswordFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="password"
                        floatLabel="always"
                        [label]="newPasswordFieldName"
                        width="100%"
                        [matTooltip]="matToolTipTextPassword"
                        matTooltipPosition="below"
                        aria-label="None"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="passwordChangeFormGroup"
                        [formCtrlName]="reenterPasswordFieldName"
                        [id]="reenterPasswordFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="password"
                        floatLabel="always"
                        [label]="reenterPasswordFieldName"
                        width="100%"
                        [matTooltip]="matToolTipTextPassword"
                        matTooltipPosition="below"
                        aria-label="None"
                    ></mds-text-field>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions *ngIf="currentUser$ | async as currentUser" [align]="'end'">
        <mds-button
            [id]="'changePasswordSubmitBtn'"
            mat-button
            (clickEvent)="changePasswordClick()"
            label="Change Password"
            variant="primary"
            *ngIf="currentUser?.hasLocalAuthentication"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            mat-button
            mat-dialog-close
            label="Cancel"
            [variant]="currentUser?.hasLocalAuthentication ? 'outline' : 'primary'"
            (clickEvent)="cancelClick()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #noLocalAuthenticationCommunicate>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <h5>User doesn't have a local Authentication Account!</h5>
        </div>
    </div>
</ng-template>
