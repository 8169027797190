import { Injectable } from '@angular/core';
import { PaymentOptions, SiteSettings } from '@models/site-option-models';

@Injectable({
    providedIn: 'root',
})
export class SiteOptionService {
    paymentOptions: PaymentOptions = {
        credit: 'Pre-Paid Debit Card',
        check: 'Check',
        wireTransfer: 'Wire Transfer',
        reimburse: 'Site will reimburse patient directly -- Medpace to reimburse site',
    };

    siteSettings: SiteSettings = {
        travel: 'Travel Support',
        reimbursement: 'Reimbursement',
        stipend: 'Stipend',
        other: 'Other',
        collectBankDetails: 'Enable collection of patient bank details?',
        ...this.paymentOptions,
    };

    getPaymentOptions(): PaymentOptions {
        return this.paymentOptions;
    }
    getSiteSettings(): SiteSettings {
        return this.siteSettings;
    }
}
