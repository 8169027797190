import { ScrollService } from '@services/scroll/scroll.service';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Step } from '@services/scroll/steps-interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'medpace-vertical-stepper',
    templateUrl: './vertical-stepper.component.html',
    styleUrls: ['./vertical-stepper.component.scss'],
})
export class VerticalStepperComponent implements OnInit, OnDestroy {
    @Input()
    steps: Step[];

    @Output()
    linkClicked = new EventEmitter();

    scrollHandleSubscription: Subscription;

    navbar: HTMLElement;

    constructor(private scrollService: ScrollService) {}

    ngOnDestroy(): void {
        this.scrollHandleSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.scrollHandleSubscription = this.scrollService.getScrollObservable().subscribe((x) => {
            this.setStepperLevel(x);
        });

        this.navbar = document.getElementById('navbar');
    }

    stuff(event: any) {
        this.steps?.forEach((step) => {
            if (step.name === event.name) {
                step.isActive = true;
            } else {
                step.isActive = false;
            }
        });
    }

    private setStepperLevel(windowTop: number): void {
        const filteredSteps = this.steps.filter((x) => windowTop <= this.getElementStepperPosition(x.identifier));
        const currentStep = filteredSteps[0];
        if (currentStep) {
            this.steps.forEach((step) => {
                if (step.name == currentStep.name) {
                    step.isActive = true;
                } else {
                    step.isActive = false;
                }
            });
        }
    }

    private getElementStepperPosition(ObjectId: string) {
        let stepComponent: HTMLElement = document.getElementById(ObjectId);
        return stepComponent != null ? stepComponent.offsetTop + stepComponent.offsetHeight / 2 : -1;
    }
}
