import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from '@models/date-format';
const defaultFormat = DateFormat.dateOnly;
@Pipe({
    name: 'dateFormat',
})
export class dateFormatPipe implements PipeTransform {
    transform(
        value: Date | string,
        format: string = defaultFormat,
        timezone: string = 'utc',
        locale: string = navigator.language
    ): string {
        if (!value) {
            return null;
        }

        if (format != defaultFormat) {
            try {
                return new DatePipe(navigator.language).transform(value, format, timezone, locale);
            } catch (error) {
                return new DatePipe('en-US').transform(value, format, timezone, 'en-US');
            }
        }
        return new DatePipe('en-US').transform(value, format, timezone, 'en-US');
    }
}
