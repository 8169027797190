import { CountryRegionCtsmViewModel } from '@models/clintrak';
import {
    CreateUpdateStudyDataModel,
    Study,
    StudyCountryDataModel,
    StudyDataModel,
    StudyPersonsDataModel,
    StudyRegion,
    StudyRegionDataModel,
    StudyTravelOption,
    StudyTravelOptionDataModel,
    TravelOptionViewModel,
} from '../../models/study';
import { transformToSiteArray, transformToSiteDataModel } from './site-transform';

export function transformToCreateUpdateStudyDataModel(study: Study): CreateUpdateStudyDataModel {
    return <CreateUpdateStudyDataModel>{
        id: study.id,
        sponsor: study.sponsor,
        protocol: study.protocol,
        code: study.studyCode,
        isActive: study.isActive,
        logo: study.logoUri,
        isApproved: study.isApproved,
        isFromClinTrak: study.isFromClinTrak,
        supportTierId: study.supportTier,
        studyPersons: <StudyPersonsDataModel>{
            globalCtm: study.studyInfo.globalCtm,
            globalRsm: study.studyInfo.globalRsm,
            regionalCtms: study.studyInfo.regionalCtms,
            pc: study.studyInfo.pc,
            prm: study.studyInfo.prm,
            studyId: study.id,
        },
        hasMultipleVisitSchedules: study.studyInfo.hasMultipleVisitSchedules,
        studyMappedVisits: study.studyInfo.studyMappedVisits,
        firstPatientFirstVisit: study.studyInfo.fpfv,
        lastPatientLastVisit: study.studyInfo.lplv,
        patient: undefined,
        siteCrcStudy: undefined,
        visitNames: study.studyInfo.visitTypes,
        expenseTypes: study.studyInfo.expenseTypes,
        numPatients: study.numPatients,
        numSites: study.numSites,
        studyRegions: transformToStudyRegionDataModelArray(study.studyInfo.studyRegions, study.id),
        studyCountries: transformToStudyCountryDataModelArray(study.studyInfo.studyCountries, study.id),
        studyTravelOptions: transformToStudyTravelOptionDataModelArray(study.studyInfo.studyTravelOptions, study.id),
    };
}

export function transformToStudyDataModel(study: Study): StudyDataModel {
    return <StudyDataModel>{
        ...transformToCreateUpdateStudyDataModel(study),
        sites: study.sites.map((site) => transformToSiteDataModel(site)),
    };
}

export function transformToStudy(model: StudyDataModel): Study {
    return <Study>{
        id: model.id,
        sponsor: model.sponsor,
        protocol: model.protocol,
        studyCode: model.code,
        logoUri: model.logoUri,
        isActive: model.isActive,
        sites: transformToSiteArray(model.sites),
        supportTier: model.supportTierId,
        numSites: model.numSites,
        numPatients: model.numPatients,
        isApproved: model.isApproved,
        isFromClinTrak: model.isFromClinTrak,
        studyInfo: {
            globalCtm: model.studyPersons?.globalCtm,
            globalRsm: model.studyPersons?.globalRsm,
            regionalCtms: model.studyPersons?.regionalCtms,
            indication: model.indication,
            status: model.status,
            pc: model.studyPersons?.pc,
            prm: model.studyPersons?.prm,
            fpfv: model.firstPatientFirstVisit,
            lplv: model.lastPatientLastVisit,
            visitTypes: model.visitNames,
            numSites: model.numSites,
            numPatients: model.numPatients,
            studyMappedVisits: model.studyMappedVisits,
            hasMultipleVisitSchedules: model.hasMultipleVisitSchedules,
            studyRegions: transformToStudyRegionArray(model.studyRegions),
            studyTravelOptions: transformToStudyTravelOptionArray(model.studyTravelOptions),
        },
    };
}
export function transformToStudyArray(models: StudyDataModel[]): Study[] {
    let studies = <Study[]>[];
    models.forEach((model) => studies.push(transformToStudy(model)));
    return studies;
}

export function transformToStudyRegion(regionsDM: StudyRegionDataModel): StudyRegion {
    return <StudyRegion>{
        name: regionsDM.name,
        studyId: regionsDM.studyId,
        id: regionsDM.id,
    };
}

export function transformToStudyRegionArray(regionsDM: StudyRegionDataModel[]): StudyRegion[] {
    let regions = <StudyRegion[]>[];
    regionsDM?.forEach((r) => regions.push(transformToStudyRegion(r)));
    return regions;
}

export function transformToStudyTravelOption(travelOptionDM: StudyTravelOptionDataModel): StudyTravelOption {
    return <StudyTravelOption>{
        name: travelOptionDM.name,
        studyId: travelOptionDM.studyId,
        id: travelOptionDM.id,
    };
}

export function transformToStudyTravelOptionArray(travelOptionDM: StudyTravelOptionDataModel[]): StudyTravelOption[] {
    let travelOptions = <StudyTravelOption[]>[];
    travelOptionDM?.forEach((to) => travelOptions.push(transformToStudyTravelOption(to)));
    return travelOptions;
}

export function transformTravelOptionViewModelToStudyTravelOption(
    travelOptionVM: TravelOptionViewModel
): StudyTravelOption {
    return <StudyTravelOption>{
        id: travelOptionVM.id,
        name: travelOptionVM.name,
        studyId: travelOptionVM.studyTravelOptions.studyId,
    };
}

export function transformTravelOptionViewModelToStudyTravelOptionArray(travelOptionVM: TravelOptionViewModel[]) {
    let travelOptions = <StudyTravelOption[]>[];
    travelOptionVM?.forEach((to) => travelOptions.push(transformTravelOptionViewModelToStudyTravelOption(to)));
    return travelOptions;
}

export function transformToStudyRegionDataModel(region: StudyRegion, studyId: number): StudyRegionDataModel {
    return <StudyRegionDataModel>{
        regionId: region.id,
        studyId: studyId ? studyId : undefined,
        name: region.name,
    };
}

export function transformToStudyCountryDataModel(
    country: CountryRegionCtsmViewModel,
    studyId: number
): StudyCountryDataModel {
    return {
        studyId: studyId,
        countryRegion: country.countryId,
    };
}

export function transformToStudyRegionDataModelArray(regions: StudyRegion[], studyId: number): StudyRegionDataModel[] {
    let regionsDM = <StudyRegionDataModel[]>[];
    regions?.forEach((r) => regionsDM.push(transformToStudyRegionDataModel(r, studyId)));

    return regionsDM;
}

export function transformToStudyCountryDataModelArray(
    countries: CountryRegionCtsmViewModel[],
    studyId: number
): StudyCountryDataModel[] {
    let countriesDM = <StudyCountryDataModel[]>[];
    countries?.forEach((c) => countriesDM.push(transformToStudyCountryDataModel(c, studyId)));

    return countriesDM;
}

export function transformToStudyTravelOptionDataModel(
    travelOption: StudyTravelOption,
    studyId: number
): StudyTravelOptionDataModel {
    return <StudyTravelOptionDataModel>{
        travelOptionId: travelOption.id,
        studyId: studyId ? studyId : undefined,
        name: travelOption.name,
    };
}
export function transformToStudyTravelOptionDataModelArray(
    travelOptions: StudyTravelOption[],
    studyId: number
): StudyTravelOptionDataModel[] {
    let travelOptionDM = <StudyTravelOptionDataModel[]>[];
    travelOptions?.forEach((to) => travelOptionDM.push(transformToStudyTravelOptionDataModel(to, studyId)));

    return travelOptionDM;
}
