<div class="o-grid" *ngIf="isEditing">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <span
                >Caregiver accompanying?
                <span class="required-asterisk">*</span>
            </span>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mat-radio-group id="careGiverTraveling" (change)="changeCaregiverTravel($event)">
                <div class="c-radio">
                    <mat-radio-button
                        id="doesHaveCaregiver"
                        value="yes"
                        [checked]="
                            (request.caregiver !== null || request.caregiver !== undefined) && caregiverTraveling
                        "
                    >
                        Yes
                    </mat-radio-button>
                </div>
                <div class="c-radio">
                    <mat-radio-button
                        value="no"
                        [checked]="
                            (request.caregiver !== null || request.caregiver !== undefined) && !caregiverTraveling
                        "
                    >
                        No
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
</div>
<div *ngIf="!isEditing" class="o-grid">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <span>Caregiver accompanying?</span>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mat-radio-group id="careGiverTraveling" disabled (change)="changeCaregiverTravel($event)">
                <div class="c-radio">
                    <mat-radio-button id="doesHaveCaregiver" value="yes" [checked]="caregiverTraveling">
                        Yes
                    </mat-radio-button>
                </div>
                <div class="c-radio">
                    <mat-radio-button value="no" [checked]="!caregiverTraveling"> No </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
</div>
