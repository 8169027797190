<form [formGroup]="datePickerFormGroup" class="matformfield-fullwidth" appearance="outline">
    <div class="div-search">
        <mds-date-range-picker
            [formGrp]="datePickerFormGroup"
            appearance="outline"
            [label]="labelName"
            floatLabel="always"
            startFormCtrlName="start"
            endFormCtrlName="end"
        >
        </mds-date-range-picker>
    </div>
</form>
