import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserTableModel } from '@models/user';
import { CreateUserManagementModalComponent } from '../user-management/create-user-management-modal/create-user-management-modal.component';

@Component({
    selector: 'medpace-user-operation-alert-modal',
    templateUrl: './user-operation-alert-modal.component.html',
    styleUrls: ['./user-operation-alert-modal.component.scss'],
})
export class MedpaceUserOperationAlertModalComponent {
    justification: string = '';
    alertFormGroup: FormGroup;
    userEmail: string;
    constructor(
        public dialogRef: MatDialogRef<MedpaceUserOperationAlertModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserTableModel,
        public dialog: MatDialog,
        private formBuilder: FormBuilder
    ) {
        this.alertFormGroup = this.formBuilder.group({
            justificationControl: ['', Validators.required],
        });
    }

    cancelClick(): void {
        this.dialogRef.close();
    }

    submitClick(): void {
        if (!this.alertFormGroup.valid) {
            this.alertFormGroup.markAllAsTouched();
            return;
        }

        if (this.data) this.dialogRef.close(this.alertFormGroup.controls?.justificationControl?.value);
        else {
            this.dialogRef.close();
            this.doActionForCreateUser();
        }
    }

    doActionForCreateUser() {
        this.dialog.open(CreateUserManagementModalComponent, {
            autoFocus: false,
            width: '500px',
            disableClose: true,
            data: this.alertFormGroup.controls?.justificationControl?.value,
        });
    }
}
