import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { Chip } from '@medpacesoftwaredevelopment/designsystem';
import { AccessRequestTableModel } from '@models/accessRequest';
import { IAccessActionDto } from '@models/interfaces/iAccessRequest';
import { Study } from '@models/study';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { maxLengthValidator } from '@utility/utility.validators';
import { Observable, ReplaySubject, map, startWith, take, tap } from 'rxjs';

@Component({
    selector: 'medpace-access-request-action-modal',
    templateUrl: './access-request-action-modal.component.html',
    styleUrls: ['./access-request-action-modal.component.scss'],
})
export class MedpaceAccessRequestActionModalComponent {
    isLoading: boolean = false;
    accessRequestId: number;
    arActionFormGroup: FormGroup;
    studies: Study[] = [];
    filteredStudies$: Observable<any[]>;
    selectedStudies: Study[] = [];
    studyListChipsSubject: ReplaySubject<Chip[]> = new ReplaySubject<Chip[]>(1);
    studyListChips$: Observable<Chip[]> = this.studyListChipsSubject.asObservable();
    isAdminAccessLevel: boolean = false;
    isStudiesEmpty: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MedpaceAccessRequestActionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AccessRequestTableModel,
        private accessRequestService: AccessRequestService,
        private snackbarService: SnackbarService,
        private fb: FormBuilder,
        protected adminStudies: AdminStudyServices,
        public dialog: MatDialog
    ) {
        this.isAdminAccessLevel = this.data?.accessType === 'Admin';

        this.arActionFormGroup = this.fb.group({
            reasonControl: ['', [Validators.required, maxLengthValidator(100)]],
            studyControl: [''],
        });

        if (this.isAdminAccessLevel)
            this.adminStudies
                .getActiveStudies()
                .pipe(
                    take(1),
                    tap((result) => {
                        this.studies = result;
                        this._setStudiesFiltration();
                        this._initializeChips();
                    })
                )
                .subscribe();
    }

    protected _setStudiesFiltration(): void {
        this.filteredStudies$ = this.arActionFormGroup.controls.studyControl.valueChanges.pipe(
            startWith(null),
            map((study: any | null) => {
                if (typeof study === 'string') {
                    return this._filterStudies(study).map((x) => x.protocol);
                } else {
                    return this.studies
                        .slice()
                        .filter((x) => !this.selectedStudies.map((z) => z.protocol).includes(x.protocol))
                        .map((x) => x.protocol);
                }
            })
        );
    }

    protected _filterStudies(value: string): Study[] {
        let studyProtocol = '';
        studyProtocol = value.toLowerCase();
        let filterStudies = this.studies.filter(
            (study) => !this.selectedStudies.map((selectedStudy) => selectedStudy.id).includes(study.id)
        );
        return filterStudies.filter((study) => study.protocol.toLowerCase().indexOf(studyProtocol) === 0);
    }

    selectedStudy(option: MatOption): void {
        if (!this.selectedStudies) {
            this.selectedStudies = [];
            this.studyListChipsSubject.next([]);
        }
        const selectedStudy = this.studies.find((x) => x.protocol === option.value);
        this.selectedStudies.push(selectedStudy);
        let studyChipList: Chip[] = this._getStudyListChips();
        studyChipList.push({ color: 'primary', value: selectedStudy.protocol, removable: true });
        this.studyListChipsSubject.next(studyChipList);
        this.arActionFormGroup.controls.studyControl.setValue(null);
        this.isStudiesEmpty = false;
    }

    removeStudy(chipEvent: MatChipEvent): void {
        const studyRemoved = this.selectedStudies.find((x) => x.protocol == chipEvent.chip.value);
        const index = this.selectedStudies.indexOf(studyRemoved);

        this.selectedStudies.splice(index, 1);
        let studyChipList: Chip[] = this._getStudyListChips();
        studyChipList.splice(index, 1);
        this.studyListChipsSubject.next(studyChipList);
        this.arActionFormGroup.controls.studyControl.setValue(null);
    }

    protected _getStudyListChips(): Chip[] {
        let studyChipList1: Chip[];
        this.studyListChips$
            .pipe(
                take(1),
                tap((studyChipList) => {
                    studyChipList1 = studyChipList;
                })
            )
            .subscribe();
        return studyChipList1;
    }

    protected _initializeChips() {
        this.studyListChipsSubject.next(
            this.selectedStudies.map((study) => {
                return <Chip>{
                    value: study.protocol,
                    removable: true,
                };
            })
        );
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    grantAccess(): void {
        if (
            this.arActionFormGroup.controls.reasonControl.value &&
            !this.arActionFormGroup.controls.reasonControl.valid
        ) {
            this.arActionFormGroup.markAllAsTouched();
            this.isLoading = false;
            return;
        }

        if (this.isAdminAccessLevel && this.selectedStudies?.length < 1) {
            this.isStudiesEmpty = true;
            return;
        }

        this.isStudiesEmpty = false;

        let action = <IAccessActionDto>{
            reason: this.arActionFormGroup.controls['reasonControl']?.value,
            studyIds: this.selectedStudies?.map((x) => x.id),
        };

        this.isLoading = true;
        this.accessRequestService
            .grantAccess(this.data.id, action)
            .pipe(
                take(1),
                tap({
                    complete: () => this.dialogRef.close(this.data.id),
                })
            )
            .subscribe({
                error: (e) => {
                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: e.error.ExceptionMessage,
                    });

                    this.closeDialog();
                },
                complete: () => this.snackbarService.openInfoSnackbar('Access Request has been granted.'),
            });
    }

    rejectAccess(): void {
        this.isLoading = true;
        if (!this.arActionFormGroup.valid) {
            this.arActionFormGroup.markAllAsTouched();
            this.isLoading = false;
            return;
        }

        let action = <IAccessActionDto>{
            reason: this.arActionFormGroup.controls['reasonControl']?.value,
        };

        this.accessRequestService
            .rejectAccess(this.data.id, action)
            .pipe(
                take(1),
                tap({
                    complete: () => this.dialogRef.close(this.data.id),
                })
            )
            .subscribe({
                error: (e) => {
                    console.error('error', e);
                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: e.error.ExceptionMessage,
                    });

                    this.closeDialog();
                },
                complete: () => this.snackbarService.openInfoSnackbar('Access Request has been rejected.'),
            });
    }
}
