/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';

export class FlightPreferencesFormGroup extends PersistentFormGroup<{
    airlinePreference1: PersistentFormControl<string>;
    frequentFlyerNumber1: PersistentFormControl<string>;
    airlinePreference2: PersistentFormControl<string>;
    frequentFlyerNumber2: PersistentFormControl<string>;
    seatPreference: PersistentFormControl<string>;
    specialNeeds: PersistentFormControl<string>;
    knownTravelerNumber: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<FlightPreferencesFormGroup>): FlightPreferencesFormGroup {
        return new FlightPreferencesFormGroup({
            airlinePreference1: new PersistentFormControl(value.airlinePreference1, maxLengthValidator(50)),
            frequentFlyerNumber1: new PersistentFormControl(value.frequentFlyerNumber1, maxLengthValidator(50)),
            airlinePreference2: new PersistentFormControl(value.airlinePreference2, maxLengthValidator(50)),
            frequentFlyerNumber2: new PersistentFormControl(value.frequentFlyerNumber2, maxLengthValidator(50)),
            seatPreference: new PersistentFormControl(value.seatPreference, maxLengthValidator(75)),
            specialNeeds: new PersistentFormControl(value.specialNeeds, maxLengthValidator(500)),
            knownTravelerNumber: new PersistentFormControl(value.knownTravelerNumber, maxLengthValidator(20)),
        });
    }
}
@Component({
    selector: 'medpace-flight-preferences',
    templateUrl: './flight-preferences.component.html',
    styleUrls: ['./flight-preferences.component.scss'],
})
export class FlightPreferencesComponent {
    @Input() formGroup: FlightPreferencesFormGroup;
    @Input() mode: EditableCardMode;
}
