/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TravelType } from '@app/enums/travel-type.enum';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { FlightHistoryData } from '@models/history-data/flightHistoryData';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, getUniqueObjects, getUniqueStrings } from '@utility/utility';
import { equalOrMoreThanTodayOnlyIfCRCDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment } from 'moment';
import { Observable, startWith, tap } from 'rxjs';
export class SingleFlightFormGroup extends PersistentFormGroup<{
    travelType: PersistentFormControl<TravelType>;
    airportOrigin: PersistentFormControl<string>;
    airportDestination: PersistentFormControl<string>;
    departureDate: PersistentFormControl<Moment>;
    returnDate: PersistentFormControl<Moment>;
    specialRequests: PersistentFormControl<string>;
    id: PersistentFormControl<number>;
}> {
    public static create(value: FormGroupValueOf<SingleFlightFormGroup>, noTravelAllowed: boolean) {
        const formGroup = new SingleFlightFormGroup(
            {
                travelType: new PersistentFormControl(value.travelType, [
                    (control) => {
                        const value = control.value as TravelType;
                        if (
                            Validators.required(control) === null &&
                            (value !== TravelType.NO_FLIGHT || noTravelAllowed)
                        )
                            return null;
                        else {
                            return { required: 'Value is required' };
                        }
                    },
                ]),
                airportOrigin: new PersistentFormControl(value.airportOrigin, [
                    Validators.required,
                    maxLengthValidator(70),
                ]),
                airportDestination: new PersistentFormControl(value.airportDestination, [
                    Validators.required,
                    maxLengthValidator(70),
                ]),
                departureDate: new PersistentFormControl(value.departureDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                returnDate: new PersistentFormControl(value.returnDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                specialRequests: new PersistentFormControl(value.specialRequests, [maxLengthValidator(1000)]),
                id: new PersistentFormControl(value.id),
            },
            [
                (control: AbstractControl<SingleFlightFormGroup['value']>) => {
                    const value = control.value;
                    if (!value.departureDate || !value.returnDate) return null;
                    return value.departureDate <= value.returnDate
                        ? null
                        : {
                              invalidDates: 'Return date cannot be before departure date.',
                          };
                },
            ]
        );
        return formGroup;
    }
}
@Component({
    selector: 'medpace-flight-details',
    templateUrl: './flight-details.component.html',
    styleUrls: ['./flight-details.component.scss'],
})
export class FlightDetailsComponent {
    public TravelType = TravelType;
    @Input() mode: EditableCardMode;
    @Input() noFlightOption: boolean = true;
    @Input() set formGroup(value: SingleFlightFormGroup) {
        this._formGroup = value;
        this.travelTypeChange$ = this.formGroup.controls.travelType.valueChanges.pipe(
            startWith(this.formGroup.getRawValue().travelType),
            tap((travelType) => {
                let controlsToEnable: PersistentFormControl[] = [];
                let controlsToDisable: PersistentFormControl[] = [];
                if (travelType === TravelType.ROUND_TRIP) {
                    controlsToEnable = Object.values(this.formGroup.controls).filter(
                        (control) => control !== this.formGroup.controls.travelType
                    );
                    controlsToDisable = [];
                } else if (travelType === TravelType.ONE_WAY) {
                    controlsToEnable = Object.values(this.formGroup.controls).filter(
                        (control) =>
                            control !== this.formGroup.controls.travelType &&
                            control !== this.formGroup.controls.returnDate
                    );
                    controlsToDisable = [this.formGroup.controls.returnDate];
                } else {
                    // No_Flight
                    controlsToEnable = Object.values(this.formGroup.controls).filter(
                        (control) => control === this.formGroup.controls.travelType
                    );
                    controlsToDisable = Object.values(this.formGroup.controls).filter(
                        (control) => control !== this.formGroup.controls.travelType
                    );
                }
                controlsToEnable.forEach((control) =>
                    control.enable({
                        breakPersistence: true,
                        onlySelf: true,
                        emitEvent: false,
                    })
                );
                controlsToDisable.forEach((control) =>
                    control.disable({
                        persistent: true,
                        onlySelf: true,
                        emitEvent: false,
                    })
                );
            })
        );
    }
    get formGroup() {
        return this._formGroup;
    }
    private _formGroup: SingleFlightFormGroup;
    @Input() set historyData(value: FlightHistoryData[]) {
        // every component should create its own MdsOptions so they dont share values
        this.autocompleteOptions = {
            airportOrigin: getUniqueStrings(
                value.filter((data) => !!data.airportOrigin).map((data) => data.airportOrigin)
            ),
            airportDestination: getUniqueStrings(
                value.filter((data) => !!data.airportDestination).map((data) => data.airportDestination)
            ),
            specialRequests: getUniqueObjects(
                value
                    .filter((data) => !!data.specialRequest)
                    .map(
                        (data) =>
                            <MedpaceAutocompleteObject>{ requestDate: data.requestDate, value: data.specialRequest }
                    )
            ),
        };
    }
    protected autocompleteOptions: {
        airportOrigin: string[];
        airportDestination: string[];
        specialRequests: MedpaceAutocompleteObject[];
    };
    protected travelTypeChange$: Observable<any>;
}
