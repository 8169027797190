import { Component, computed, inject, input, signal, Signal } from '@angular/core';
import { CaregiverEditRequestDto } from '@models/caregiverEditRequestDto';
import { CountryViewModel } from '@models/country';
import { Caregiver } from '@models/patient';
import { CountryStateService } from '@services/state-management/country-state.service';
import { tap } from 'rxjs';
import { EditFieldFormGroup } from '../edit-field.formgroup';
import { CaregiverEditFormGroup } from './caregiver-edit-request.formgroup';

@Component({
    selector: 'medpace-caregiver-edit-request',
    templateUrl: './caregiver-edit-request.component.html',
})
export class CaregiverEditRequestComponent {
    formGroup = input.required<CaregiverEditFormGroup>();
    caregiver = input.required<Caregiver>();
    editRequest = input.required<CaregiverEditRequestDto>();
    countryStateService = inject(CountryStateService);
    countries = signal<CountryViewModel[]>([]);
    countries$ = this.countryStateService.getCountries().pipe(
        tap((countries) => {
            this.countries.set(countries);
        })
    );

    editFields: Signal<EditFieldFormGroup<any>[]> = computed(() => {
        const formGroup = this.formGroup();
        const caregiver = this.caregiver();
        const editRequest = this.editRequest();
        const countries = this.countries();
        if (!formGroup || !caregiver || !editRequest || !countries) return;
        formGroup.setCurrentValue(caregiver);
        formGroup.setRequestedValue(editRequest, countries);
        formGroup.disableIfUnchanged();

        const fields = [
            formGroup.controls.addressSameAsPatient,
            ...Object.values(formGroup.controls.address.controls),
            ...Object.values(formGroup.controls.passport.controls),
        ];

        return fields.filter((control) => control.enabled);
    });
}
