import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputChange } from '@models/event-objects/input-change';
import { ClinTrakDataStateService } from '@services/state-management/clintrak-data-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { emailValidator, maxLengthValidator } from '@utility/utility.validators';
import { Subject, combineLatest, takeUntil, tap } from 'rxjs';
@Component({
    selector: 'medpace-crc-invite-card',
    templateUrl: './crc-invite-card.component.html',
    styleUrls: ['./crc-invite-card.component.scss'],
})
export class CRCInviteCardComponent implements OnInit, OnDestroy {
    @Input() isEditing: boolean;
    @Output() inputChangeEvent = new EventEmitter<InputChange>();
    @Input() parentFormGroup: FormGroup;

    crcEmailControl: FormControl<string> = new FormControl<string>('', [
        Validators.required,
        maxLengthValidator(150),
        emailValidator,
    ]);
    crcEmail: string;
    loaderSpinner: boolean = true;
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private clinTrakDataStateService: ClinTrakDataStateService,
        private siteStateService: SiteStateService
    ) {}

    ngOnInit(): void {
        this.parentFormGroup.addControl('primaryCrcControl', this.crcEmailControl);

        combineLatest([this.siteStateService.getSite(), this.clinTrakDataStateService.getStudySiteData()])
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((result) => {
                    if (!result[0] && result[1]) {
                        this.crcEmail = result[1].primaryCrcEmail;
                        this.setPrimaryCrcEmail();
                    } else if (result[0]) {
                        this.crcEmail = result[0].primaryCrcEmailToInvite;
                        this.setPrimaryCrcEmail();
                    }

                    this.loaderSpinner = false;
                })
            )
            .subscribe();
    }

    setPrimaryCrcEmail() {
        this.parentFormGroup.controls.primaryCrcControl.setValue(this.crcEmail);
        this.inputChangeEvent.emit({ target: 'primaryCrcEmailToInvite', value: this.crcEmail });
    }

    emitChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
