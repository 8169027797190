<medpace-card>
    <medpace-card-header>
        <div #overrideHeader>
            <ng-content select="medpace-card-header"></ng-content>
        </div>
        <div *ngIf="!overrideHeader.hasChildNodes()" class="c-card__ribbon">
            <div class="c-card__item c-card__item--left mb-2">
                <h3>{{ title }}</h3>
                <span class="required-asterisk" *ngIf="showTitleAsteriskWhenEditMode && mode === 'edit'">*</span>
            </div>

            <div *ngIf="showSwitchModeButton">
                <mds-button
                    *ngIf="mode === 'edit'"
                    (clickEvent)="handleSaveClick()"
                    label="Save"
                    [variant]="'outline'"
                    [id]="'saveBtn'"
                ></mds-button>
                <mds-button
                    *ngIf="mode === 'view'"
                    (clickEvent)="handleEditClick()"
                    label="Edit"
                    [variant]="'outline'"
                    [id]="'editBtn'"
                ></mds-button>
            </div>
        </div>
    </medpace-card-header>

    <medpace-card-content>
        <form #ngForm="ngForm" [formGroup]="formGroup" appScrollToInvalidControlOnSubmit>
            <div #overrideContent>
                <ng-content select="medpace-card-content"></ng-content>
            </div>
            <div *ngIf="!overrideContent.hasChildNodes()">
                <medpace-loading-card *ngIf="isLoading === true"></medpace-loading-card>

                <div *ngIf="isLoading === false">
                    <ng-content select="medpace-editable-card-edit-mode-content" *ngIf="mode === 'edit'"></ng-content>
                    <ng-content select="medpace-editable-card-view-mode-content" *ngIf="mode === 'view'"></ng-content>
                </div>
            </div>
        </form>
    </medpace-card-content>
</medpace-card>
