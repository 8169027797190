/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { PersistentFormArray, PersistentFormGroup } from '@utility/persistent-forms';
import { PassportInfoFormGroup } from './passport-info/passport-info.component';

export class InternationalPreferencesFormGroup extends PersistentFormGroup<{
    patientPassportInfo: PassportInfoFormGroup;
    caregiverPassportInfos: PersistentFormArray<PassportInfoFormGroup>;
}> {
    public static create(patientPassportInfo: PassportInfoFormGroup): InternationalPreferencesFormGroup {
        return new InternationalPreferencesFormGroup({
            patientPassportInfo: patientPassportInfo,
            caregiverPassportInfos: new PersistentFormArray<PassportInfoFormGroup>([]),
        });
    }
}
@Component({
    selector: 'medpace-international-preferences',
    templateUrl: './international-preferences.component.html',
    styleUrls: ['./international-preferences.component.scss'],
})
export class InternationalPreferencesComponent {
    @Input() formGroup: InternationalPreferencesFormGroup;
    @Input() mode: EditableCardMode;
}
