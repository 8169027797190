import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Announcement, AnnouncementDTO } from '@models/announcement';
import { SiteService } from '@services/site/site.service';
import {
    mapAnnouncementDTOToAnnouncement,
    mapAnnouncementToCreateAnnouncementDTO,
    mapAnnouncementToUpdateAnnouncementDTO,
} from '@services/transforms/announcement-transform';
import { SaveStatusResponse } from '@utility/utility';
import { Observable, map } from 'rxjs';
@Injectable()
export class AnnouncementRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    public getAll(): Observable<Announcement[]> {
        return this.client
            .get<AnnouncementDTO[]>(`${this.siteService.getSiteBaseUrl()}/api/announcements`)
            .pipe(map((dtos) => dtos.map((dto) => mapAnnouncementDTOToAnnouncement(dto))));
    }

    public getAllActive(numberOfElements: number = 2): Observable<Announcement[]> {
        return this.client
            .get<
                AnnouncementDTO[]
            >(`${this.siteService.getSiteBaseUrl()}/api/announcements/active?count=${numberOfElements}`)
            .pipe(map((dtos) => dtos.map((dto) => mapAnnouncementDTOToAnnouncement(dto))));
    }

    public getById(announcementId: number): Observable<Announcement> {
        return this.client
            .get<AnnouncementDTO>(`${this.siteService.getSiteBaseUrl()}/api/announcements/${announcementId}`)
            .pipe(map((dto) => mapAnnouncementDTOToAnnouncement(dto)));
    }

    public create(announcement: Announcement) {
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/announcements`,
            mapAnnouncementToCreateAnnouncementDTO(announcement),
            { observe: 'response' }
        );
    }

    public update(announcementId: number, announcement: Announcement) {
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/announcements/${announcementId}`,
            mapAnnouncementToUpdateAnnouncementDTO(announcement),
            { observe: 'response' }
        );
    }

    public delete(announcementId: number) {
        return this.client.delete<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/announcements/${announcementId}`,
            { observe: 'response' }
        );
    }
}
