<div
    class="o-grid c-bar"
    *ngIf="{ pickupAddressChange: pickupAddressChange$ | async, dropoffAddressChange: dropoffAddressChange$ | async }"
>
    <div *ngIf="mode === 'edit'">
        <div class="o-grid__row" style="display: flex; justify-content: end">
            <a (click)="remove()">
                <i class="fas fa-trash icon"></i>
            </a>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-datepicker
                    data-cy="transfer-date"
                    [label]="'Pick-up date'"
                    [appearance]="'fill'"
                    [formCtrl]="formGroup.controls.pickupDate"
                    [appearance]="'outline'"
                ></medpace-datepicker>
            </div>
            <div class="o-grid__item">
                <mds-dropdown
                    floatLabel="always"
                    [closeOnPageScroll]="false"
                    data-cy="transfer-time"
                    [appearance]="'fill'"
                    [label]="'Pick-up time'"
                    [placeholder]="'Select Time'"
                    [options]="timeOptions"
                    [formCtrl]="formGroup.controls.pickupTime"
                    [appearance]="'outline'"
                ></mds-dropdown>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    #pickupAddress
                    data-cy="transfer-pick-up-address"
                    [label]="'Pick-up Address'"
                    [floatLabel]="'always'"
                    [formCtrl]="formGroup.controls.pickupAddress"
                    [appearance]="'outline'"
                ></mds-text-field>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    #dropoffAddress
                    data-cy="transfer-drop-off-address"
                    [label]="'Drop-off Address'"
                    [floatLabel]="'always'"
                    [formCtrl]="formGroup.controls.dropoffAddress"
                    [appearance]="'outline'"
                ></mds-text-field>
            </div>
        </div>
    </div>
    <div *ngIf="mode === 'view'">
        <div class="o-grid__row" data-cy="transfer-datetime">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Pickup Date</h5>
                    <span>{{
                        formGroup.controls.pickupDate.value !== null
                            ? (formGroup.controls.pickupDate.value.toDate() | dateFormat | uppercase)
                            : 'Unspecified'
                    }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Pickup Time</h5>
                    <span>{{
                        formGroup.controls.pickupTime.value !== null
                            ? (minutesSinceMidnightToMoment(formGroup.controls.pickupTime.value).toDate()
                              | dateFormat: 'shortTime')
                            : 'Unspecified'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row" data-cy="transfer-address">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Pick-up Address</h5>
                    <span>{{ formGroup.controls.pickupAddress.value }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Dropoff address</h5>
                    <span>{{ formGroup.controls.dropoffAddress.value }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
