<footer class="site-footer">
    <mat-divider></mat-divider>
    <mat-toolbar class="footer-content" [style]="isMobile ? 'flex-direction: column' : 'flex-direction: row'">
        <div>
            <img [src]="logo" alt="Medpace Logo" class="logo" />
        </div>
        <div *ngIf="!isMobile">
            <mat-divider [vertical]="true" class="footer-divider"></mat-divider>
        </div>
        <div>
            <div class="footer-links">
                <span class="footer-link">© {{ currentYear }} Medpace, All Rights Reserved</span>
                <a
                    class="footer-link"
                    href="https://www.medpace.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                    >Privacy Policy</a
                >
                <a
                    class="footer-link"
                    href="https://www.medpace.com/accessibility-statement/"
                    target="_blank"
                    rel="noreferrer noopener"
                    >Accessibility Statement</a
                >
                <a
                    class="footer-link"
                    href="https://www.medpace.com/terms-of-use/"
                    target="_blank"
                    rel="noreferrer noopener"
                    >Terms of Use</a
                >
                <a class="footer-link" routerLink="/global-contact-numbers">Global Contact Numbers</a>
                <span class="footer-link">US Toll Free: <a href="tel:+1866-715-4403">+1 866-715-4403</a></span>
                <span class="footer-link"
                    >Email: <a href="mailto:{{ emailAddress }}">{{ emailAddress }}</a></span
                >
                <span class="footer-link" [attr.hashVersion]="this.hashVersion">PCS version {{ appVersion }}</span>
                <span class="footer-link" *ngIf="timer$ | async as timer">Session will time out in: {{ timer }}</span>
            </div>
            <div class="report-bug">
                <mds-button variant="primary" label="Report a bug" (clickEvent)="sendErrorsDetails()"></mds-button>
            </div>
        </div>
    </mat-toolbar>
</footer>
