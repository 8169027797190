import { PageEvent } from '@angular/material/paginator';

export class EmittedEvent {
    name: string = '';
    value: any = null;

    constructor(name: string, value: any) {
        this.name = name;
        this.value = value;
    }
}

export class EmittedPagingEvent {
    pageEvent: PageEvent;
    tabName: string;

    constructor(pageEvent: PageEvent, tabName: string) {
        this.pageEvent = pageEvent;
        this.tabName = tabName;
    }
}
