<div *ngIf="isEditing" class="o-grid" [formGroup]="parentFormGroup">
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="siteNumber"
                [formGrp]="parentFormGroup"
                formCtrlName="siteNumberControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Site #"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'siteNumber' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="name"
                [formGrp]="parentFormGroup"
                formCtrlName="nameControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Institution Name"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'name' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="pilastName"
                [formGrp]="parentFormGroup"
                formCtrlName="pilastNameControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="PI Last Name"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'pilastName' })"
            ></mds-text-field>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && !loaderSpinner && site$ | async as site" class="o-grid">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Site #</h5>
                <span>{{ site?.info?.siteNumber }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Institution Name</h5>
                <span>{{ site?.info?.name }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>PI Last Name</h5>
                <span>{{ site?.info?.pilastName }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="site?.status?.statusName">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Status</h5>
                <span>{{ site?.status?.statusName }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="site?.info?.clintrakStatus">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>ClinTrak Status</h5>
                <span>{{ site?.info?.clintrakStatus }}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid"><medpace-loading-card></medpace-loading-card></div>
