<div class="o-grid">
    <div class="o-grid__row">
        <medpace-flight-details
            id="patientFlightDetails"
            class="width-100"
            [mode]="mode"
            [formGroup]="formGroup.controls.patientFlightDetails"
            [historyData]="historyData"
            [noFlightOption]="false"
        ></medpace-flight-details>
    </div>
    <ng-container *ngIf="mode === 'edit' && hasAnyNonDisabledCaregiverControls()">
        <div class="o-grid__row" style="margin-top: 20px">Accompanying caregivers:</div>
        <div
            class="o-grid__row"
            *ngFor="let caregiverFormGroup of formGroup.controls.caregivers.controls"
            style="display: flex; align-items: baseline"
        >
            <ng-container *ngIf="!caregiverFormGroup.disabled">
                <mds-expansion-panel
                    id="caregiverFlightDetails_ExpansionPanel"
                    class="expansion-panel-request"
                    [panelTitle]="getCaregiverFullName(caregiverFormGroup.value.caregiver)"
                    [description]="'Book the same flight as for the patient'"
                    [disabled]="caregiverFormGroup.value.details.bookIdenticalTrip"
                    [expanded]="!caregiverFormGroup.value.details.bookIdenticalTrip"
                >
                    <div mds-header-content>
                        <mds-checkbox
                            id="bookTheSameFlight_Checkbox"
                            style="width: min-content"
                            [formCtrl]="caregiverFormGroup.controls.details.controls.bookIdenticalTrip"
                        ></mds-checkbox>
                    </div>
                    <div class="o-grid__row">
                        <medpace-flight-details
                            class="width-100"
                            [mode]="mode"
                            [formGroup]="caregiverFormGroup.controls.details.controls.flightDetails"
                            [historyData]="historyData"
                        ></medpace-flight-details>
                    </div>
                </mds-expansion-panel>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === 'view' && accompanyingCaregivers.length !== 0">
        <div class="o-grid__row" style="margin-top: 20px">Accompanying caregivers:</div>
        <div
            class="o-grid__row"
            *ngFor="let caregiverFormGroup of accompanyingCaregivers"
            style="display: flex; align-items: baseline"
        >
            <mds-expansion-panel
                class="expansion-panel-request"
                [panelTitle]="getCaregiverFullName(caregiverFormGroup.value.caregiver)"
                [description]="'Book the same flight as for the patient'"
                [disabled]="false"
                [expanded]="false"
            >
                <div mds-header-content>
                    <mds-checkbox
                        id="bookTheSameFlight_Checkbox"
                        style="width: min-content"
                        [formCtrl]="caregiverFormGroup.controls.details.controls.bookIdenticalTrip"
                    ></mds-checkbox>
                </div>
                <div class="o-grid__row">
                    <medpace-flight-details
                        class="width-100"
                        [mode]="mode"
                        [formGroup]="caregiverFormGroup.controls.details.controls.flightDetails"
                        [historyData]="historyData"
                    ></medpace-flight-details>
                </div>
            </mds-expansion-panel>
        </div>
    </ng-container>
</div>
