<medpace-editable-card
    *ngIf="{
        value: vm$ | async,
        hasSupportingCRCsChange: hasSupportingCRCsChange$ | async,
    } as vm"
    [mode]="mode"
    [title]="'Site CRC Information'"
    [showTitleAsteriskWhenEditMode]="true"
    [showSwitchModeButton]="false"
    [isLoading]="!vm.value"
    [formGroup]="formGroup"
    class="o-grid"
>
    <medpace-editable-card-view-mode-content *ngIf="vm.value">
        <ng-container
            [ngTemplateOutlet]="CRCInfo_ViewMode"
            [ngTemplateOutletContext]="{ user: vm.value.primaryCRC }"
        ></ng-container>

        <div class="o-grid__row" *ngIf="formGroup?.controls?.hasSupportingCRCs?.value">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h3>Supporting CRCs</h3>
                </div>
            </div>
        </div>

        <div class="supporting-crcs-list">
            <ng-container
                *ngFor="let user of vm.value.supportingCRCs; let i = index"
                [ngTemplateOutlet]="CRCInfo_ViewMode"
                [ngTemplateOutletContext]="{ user: user }"
            ></ng-container>
        </div>
    </medpace-editable-card-view-mode-content>
    <medpace-editable-card-edit-mode-content *ngIf="vm.value">
        <ng-container
            [ngTemplateOutlet]="CRCInfo_EditMode"
            [ngTemplateOutletContext]="{
                $implicit: {
                    formControl: formGroup.controls.primaryCRC,
                    vm: vm,
                    isRemovable: false,
                    supportingCRCIndex: null,
                },
            }"
        ></ng-container>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <label>Would you like to add supporting CRCs?</label>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-radio-group
                    [buttons]="radioButtons"
                    [formCtrl]="formGroup.controls.hasSupportingCRCs"
                    id="supportingCRC"
                >
                </mds-radio-group>
            </div>
        </div>
        <div class="mat-hint-container" *ngIf="availableOptions$ | async as options">
            <mat-hint *ngIf="options.length === 0">Assign another user to study to add more CRCs</mat-hint>
        </div>
        <div *ngIf="formGroup.controls.hasSupportingCRCs.value === true">
            <ng-container
                *ngFor="let formControl of formGroup.controls.supportingCRCs.controls; let i = index"
                [ngTemplateOutlet]="CRCInfo_EditMode"
                [ngTemplateOutletContext]="{
                    $implicit: {
                        formControl: formControl,
                        vm: vm,
                        isRemovable: true,
                        supportingCRCIndex: i,
                    },
                }"
            ></ng-container>

            <div class="o-grid__row" *ngIf="formGroup.controls.supportingCRCs.length <= vm.value.crcOptions.length - 2">
                <div class="o-grid__item">
                    <mds-button
                        [variant]="'outline'"
                        [label]="'Add CRC'"
                        [id]="'addCrcBtn'"
                        (clickEvent)="addSupportingCRC()"
                    ></mds-button>
                </div>
            </div>
        </div>
    </medpace-editable-card-edit-mode-content>
</medpace-editable-card>

<ng-template #CRCInfo_EditMode let-context>
    <div class="o-grid__row">
        <div class="o-grid__item">
            @if (availableOptions$ | async) {
                <div *ngIf="!context.isRemovable">
                    <mds-autocomplete
                        [closeOnPageScroll]="false"
                        [disableRipple]="true"
                        [formCtrl]="context.formControl"
                        [options]="primaryCRCFilteredOptions | async"
                        appearance="outline"
                        label="CRC"
                        placeholder="Select primary CRC"
                        floatLabel="always"
                        id="crcUserAutocomplete"
                        inputType="text"
                        [hint]="getWarningMessage(primaryCRCFilteredOptions | async, context)"
                        [required]="true"
                    >
                    </mds-autocomplete>
                </div>
                <div *ngIf="context.isRemovable" style="display: flex">
                    <div class="width-90">
                        <mds-autocomplete
                            [closeOnPageScroll]="false"
                            [disableRipple]="true"
                            [formCtrl]="context.formControl"
                            [options]="supportCRCFilteredOptionsArray[context.supportingCRCIndex] | async"
                            appearance="outline"
                            label="CRC"
                            placeholder="Select CRC"
                            floatLabel="always"
                            id="crcUserAutocomplete{{ context.supportingCRCIndex + 1 }}"
                            class="removable-crc"
                            inputType="text"
                            [required]="true"
                        >
                        </mds-autocomplete>
                    </div>
                    <div class="width-10">
                        <a
                            id="removeCrcUser{{ context.supportingCRCIndex + 1 }}"
                            (click)="removeSupportingCRC(context.supportingCRCIndex)"
                            ><i class="fas fa-trash icon"></i
                        ></a>
                    </div>
                </div>
            }
        </div>
    </div>
    <ng-container *ngIf="!!context.formControl.value">
        <div class="o-grid__row" *ngIf="getUserByName(context.formControl.value) as user">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Phone Number</h5>
                    <span>{{ toStringOrFallback(user?.phoneNumber, 'Unspecified') }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Email Address</h5>
                    <span>{{ toStringOrFallback(user?.emailAddress, 'Unspecified') }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #CRCInfo_ViewMode let-outerUser="user">
    <ng-container *ngIf="toUser(outerUser); let user">
        <div class="supporting-crc-info">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>First/Given Name</h5>
                        <span>{{ user.firstName }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Last/Surname</h5>
                        <span>{{ user.lastName }}</span>
                    </div>
                </div>
            </div>

            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Phone Number</h5>
                        <span>{{ toStringOrFallback(user.phoneNumber, 'Unspecified') }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Email Address</h5>
                        <span>{{ toStringOrFallback(user.emailAddress, 'Unspecified') }}</span>
                    </div>
                </div>
            </div>
            <div class="separator"></div>
        </div>
    </ng-container>
</ng-template>
