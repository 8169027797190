import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    MdsAppbarModule,
    MdsAutocompleteModule,
    MdsButtonModule,
    MdsCardModule,
    MdsCheckboxModule,
    MdsChipModule,
    MdsDatepickerModule,
    MdsDialogModule,
    MdsDividerModule,
    MdsDropdownModule,
    MdsExpansionModule,
    MdsIconModule,
    MdsListModule,
    MdsMenuModule,
    MdsPaginatorModule,
    MdsProgressBarModule,
    MdsRadioGroupModule,
    MdsSpinnerModule,
    MdsTabModule,
    MdsTableModule,
    MdsTextFieldModule,
    MdsTextareaModule,
} from '@medpacesoftwaredevelopment/designsystem';

@NgModule({
    declarations: [],
    imports: [
        MdsButtonModule,
        MdsAppbarModule,
        MdsSpinnerModule,
        MdsDialogModule,
        MdsAutocompleteModule,
        MdsDatepickerModule,
        MdsTabModule,
        MdsCheckboxModule,
        MatCheckboxModule,
        MdsTableModule,
        MdsTextFieldModule,
        MdsPaginatorModule,
        MdsChipModule,
        MdsRadioGroupModule,
        MdsIconModule,
        MdsDropdownModule,
        MdsExpansionModule,
        MdsTextareaModule,
        MdsMenuModule,
        MdsCardModule,
        MdsDividerModule,
        MdsListModule,
    ],
    exports: [
        MdsButtonModule,
        MdsAppbarModule,
        MdsSpinnerModule,
        MdsDialogModule,
        MdsAutocompleteModule,
        MdsDatepickerModule,
        MdsTabModule,
        MdsCheckboxModule,
        MatCheckboxModule,
        MdsTableModule,
        MdsTextFieldModule,
        MdsPaginatorModule,
        MdsChipModule,
        MdsRadioGroupModule,
        MdsIconModule,
        MdsDropdownModule,
        MdsExpansionModule,
        MdsTextareaModule,
        MdsMenuModule,
        MdsCardModule,
        MdsDividerModule,
        MdsProgressBarModule,
        MdsListModule,
    ],
    providers: [],
})
export class MdsModule {}
