<ng-container *ngIf="{ user: user$ | async } as vm">
    <form [formGroup]="siteFormGroup" appScrollToInvalidControlOnSubmit class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item half" *ngIf="!isModal">
                <medpace-vertical-stepper [steps]="steps"></medpace-vertical-stepper>
            </div>
            <div class="o-grid__item">
                <div class="o-grid">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <div class="flex start middle">
                                <span class="required-asterisk">*</span>
                                <span> indicates a required field </span>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="createEditButton"></ng-container>
                    </div>
                    <div class="o-grid__row" id="siteInfo">
                        <div class="o-grid__item">
                            <medpace-input-card
                                title="Site Information"
                                type="site-info"
                                [showEditSaveButton]="isUpdateAndAdmin(vm.user)"
                                [editMode]="!isUpdate"
                                [parentFormGroup]="siteFormGroup"
                                (incrementalUpdate)="submitIncrementalSave()"
                                (inputChangeEvent)="passAlongChange($event)"
                            >
                            </medpace-input-card>
                        </div>
                    </div>
                    <div class="o-grid__row">
                        <div class="o-grid__item" id="siteAddress">
                            <medpace-input-card
                                type="site-address"
                                title="Site Address"
                                [showEditSaveButton]="isUpdateAndAdmin(vm.user)"
                                [editMode]="!isUpdate"
                                [parentFormGroup]="siteFormGroup"
                                (incrementalUpdate)="submitIncrementalSave()"
                                (inputChangeEvent)="passAlongChange($event)"
                            >
                            </medpace-input-card>
                        </div>
                    </div>
                    <div class="o-grid__row">
                        <div class="o-grid__item" id="siteShippingAddress">
                            <medpace-input-card
                                type="site-shipping-address"
                                title="Site Shipping Address"
                                [showEditSaveButton]="isUpdateAndAdmin(vm.user)"
                                [editMode]="!isUpdate"
                                [parentFormGroup]="siteFormGroup"
                                (incrementalUpdate)="submitIncrementalSave()"
                                (inputChangeEvent)="passAlongChange($event)"
                            >
                            </medpace-input-card>
                        </div>
                    </div>
                    <div class="o-grid__row">
                        <div class="o-grid__item" id="siteServices">
                            <medpace-input-card
                                [isModal]="isModal"
                                [studyId]="site?.studyId"
                                type="site-services-card"
                                title="Site Settings"
                                [showEditSaveButton]="isUpdateAndAdmin(vm.user)"
                                [editMode]="!isUpdate"
                                [parentFormGroup]="siteFormGroup"
                                (incrementalUpdate)="submitIncrementalSave()"
                                (inputChangeEvent)="passAlongChange($event)"
                            >
                            </medpace-input-card>
                        </div>
                    </div>
                    <div class="o-grid__row" *ngIf="isCrcCardVisible()">
                        <div class="o-grid__item" id="siteCRCs">
                            <medpace-crc-card2
                                *ngIf="isUpdate"
                                [vm]="crcCardVM_Edit$ | async"
                                [mode]="'view'"
                                [showSwitchModeButton]="isUpdateAndAdmin(vm.user)"
                            ></medpace-crc-card2>
                            <medpace-crc-card2
                                *ngIf="!isUpdate"
                                [vm]="crcCardVM_Create$ | async"
                                [mode]="'edit'"
                                [showSwitchModeButton]="false"
                            ></medpace-crc-card2>
                        </div>
                    </div>
                    <div class="o-grid__row" *ngIf="isCrcInviteCardVisible()">
                        <div class="o-grid__item" id="primaryCrc">
                            <medpace-input-card
                                type="site-crc-invite"
                                title="Site CRC Information"
                                [editMode]="!isUpdate"
                                [parentFormGroup]="siteFormGroup"
                                [showEditSaveButton]="isUpdateAndAdmin(vm.user) && !isEditButtonVisible()"
                                (incrementalUpdate)="submitIncrementalSave()"
                                (inputChangeEvent)="passAlongChange($event)"
                            >
                            </medpace-input-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="createEditButton"></ng-container>

        <div class="o-grid__row" *ngIf="!isModal">
            <div class="o-grid__item u-ta-right">
                <span [ngClass]="isValidForm ? 'c-message' : 'c-message__show'">{{ validationMessage }}</span>
            </div>
        </div>
    </form>

    <ng-template #createEditButton>
        <ng-container *ngIf="!isModal">
            <div class="o-grid__item" *ngIf="!isUpdate">
                <div class="flex end">
                    <mds-button
                        id="createNewSiteBtn"
                        variant="primary"
                        label="Approve and Submit"
                        (clickEvent)="submit()"
                        type="submit"
                    ></mds-button>
                </div>
            </div>
            <div class="o-grid__item" *ngIf="isUpdateAndAdmin(vm.user)">
                <div class="flex end">
                    <mds-button
                        id="update-site-btn"
                        variant="primary"
                        label="Update Site"
                        (clickEvent)="submit()"
                        type="submit"
                    ></mds-button>
                    <mds-button
                        *ngIf="isChangeStatusButtonVisible()"
                        id="changeStatusbtn"
                        variant="primary"
                        label="Change Status"
                        (clickEvent)="changeStatus()"
                    ></mds-button>
                </div>
            </div>
        </ng-container>
    </ng-template>
</ng-container>
