import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { RequestTypes } from '@app/enums/RequestTypes';
import {
    BaseEditableCardComponent,
    EditableCardDataHandler,
} from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { Address } from '@models/site';
import { VisitName } from '@models/study';
import { PersistentFormControl } from '@utility/persistent-forms';
import { maxLengthValidator } from '@utility/utility.validators';
import { Moment, utc } from 'moment';
import { takeUntil } from 'rxjs';
import { dateRangeValidator } from 'src/app/custom-validators/date-range-validator';
import { timeRangeValidator } from 'src/app/custom-validators/time-range-validator';
import 'src/app/extensions/formgroup-extensions';
import * as utility from 'src/app/utility/utility';
export interface ViewModel {
    requestType: RequestTypes;
    travelRequest_ViewModel: TravelRequest_ViewModel;
    paymentRequest_ViewModel: PaymentRequest_ViewModel;
}
export interface TravelRequest_ViewModel {
    visitNameOptions: utility.MdsOptionGeneric<VisitName>[];
    siteNumber: string;
    siteAddress: Address;
    timePickerOptions: utility.MdsOptionGeneric<Moment>[];
}
export interface PaymentRequest_ViewModel {
    visitNameOptions: utility.MdsOptionGeneric<VisitName>[];
    siteNumber: string;
    siteAddress: Address;
}
export interface Data {
    requestType: RequestTypes;
    travelRequest_Data: TravelRequest_Data;
    paymentRequest_Data: PaymentRequest_Data;
}
export interface TravelRequest_Data {
    visitName: VisitName;
    specialNavigationInstructions: string;
    siteNumber: string;
    siteAddress: Address;
    visitStartDateTime: Moment;
    visitEndDateTime: Moment;
}
export interface PaymentRequest_Data {
    siteNumber: string;
    siteAddress: Address;
    visitName: VisitName;
    visitDate: Moment;
}

export class PaymentRequestHandler extends EditableCardDataHandler<PaymentRequest_ViewModel, PaymentRequest_Data> {
    public formGroup = new FormGroup({
        visitName: new FormControl<VisitName>(null, [Validators.required]),
        visitDate: new PersistentFormControl<Moment>(null, [Validators.required]),
    });
    constructor(
        protected _viewModel: PaymentRequest_ViewModel,
        protected fb: FormBuilder
    ) {
        super(_viewModel, fb);
        this._data = <PaymentRequest_Data>{
            siteNumber: _viewModel.siteNumber,
            siteAddress: _viewModel.siteAddress,
        };
    }
    public override getFormGroup(): FormGroup {
        return this.formGroup;
    }
    public onVisitDateChange(event: MatDatepickerInputEvent<Moment>) {
        // this receives a Moment and not a Date because of injected configuration properties
        const date = this.formGroup.controls.visitDate.value;
        if (date) {
            this.data.visitDate = date;
            this.dataChangeEvent.emit(this.data);
        }
    }
    public onVisitNameChange(event: MatSelectChange) {
        this.data.visitName = event.value as VisitName;
        this.dataChangeEvent.emit(this.data);
    }
}
export class TravelRequestHandler extends EditableCardDataHandler<TravelRequest_ViewModel, TravelRequest_Data> {
    public formGroup = new FormGroup({
        specialNavigationInstructions: new FormControl<string>(null, [maxLengthValidator(500)]),
        visitName: new FormControl<VisitName>(null, [Validators.required]),
        visitStartDate: new PersistentFormControl<Moment>(null, [Validators.required]),
        visitStartTime: new PersistentFormControl<Moment>(null, [Validators.required]),
        visitEndDate: new PersistentFormControl<Moment>(null, [Validators.required]),
        visitEndTime: new PersistentFormControl<Moment>(null, [Validators.required]),
    });

    constructor(
        protected _viewModel: TravelRequest_ViewModel,
        protected fb: FormBuilder
    ) {
        super(_viewModel, fb);
        this._data = <TravelRequest_Data>{
            siteNumber: this._viewModel.siteNumber,
            siteAddress: this._viewModel.siteAddress,
            visitStartDateTime: utc(),
            visitEndDateTime: utc(),
        };

        this.formGroup.controls.visitStartDate.addValidators([
            dateRangeValidator(this.formGroup.controls.visitStartDate, this.formGroup.controls.visitEndDate),
        ]);
        this.formGroup.controls.visitEndDate.addValidators([
            dateRangeValidator(this.formGroup.controls.visitStartDate, this.formGroup.controls.visitEndDate),
        ]);
        this.formGroup.controls.visitStartTime.addValidators([
            timeRangeValidator(
                this.formGroup.controls.visitStartDate,
                this.formGroup.controls.visitEndDate,
                this.formGroup.controls.visitStartTime,
                this.formGroup.controls.visitEndTime
            ),
        ]);
        this.formGroup.controls.visitStartTime.addValidators([
            timeRangeValidator(
                this.formGroup.controls.visitStartDate,
                this.formGroup.controls.visitEndDate,
                this.formGroup.controls.visitStartTime,
                this.formGroup.controls.visitEndTime
            ),
        ]);

        this.formGroup.recursiveUpdateValueAndValidity();
    }
    public override getFormGroup(): FormGroup {
        return this.formGroup;
    }
    public onVisitNameChange(event: MatSelectChange) {
        this.data.visitName = event.value as VisitName;
        this.dataChangeEvent.emit(this.data);
    }
    public onSpecialNavigationInstructionsChange(instructions: string) {
        this.data.specialNavigationInstructions = instructions;
        this.dataChangeEvent.emit(this.data);
    }
    public onVisitStartDateChange(event: MatDatepickerInputEvent<Moment>) {
        const date = this.formGroup.controls.visitStartDate.value;
        if (date) {
            this.data.visitStartDateTime = this.data.visitStartDateTime.copyDateFrom(date);
            if (!this.formGroup.controls.visitEndDate.value) {
                this.formGroup.controls.visitEndDate.setValue(date);
                this.data.visitEndDateTime = this.data.visitEndDateTime.copyDateFrom(date);
            }
            this.dataChangeEvent.emit(this.data);
            this.formGroup.recursiveUpdateValueAndValidity();
        }
    }
    public onVisitEndDateChange(event: MatDatepickerInputEvent<Moment>) {
        const date = this.formGroup.controls.visitEndDate.value;
        if (date) {
            this.data.visitEndDateTime = this.data.visitEndDateTime.copyDateFrom(date);
            this.dataChangeEvent.emit(this.data);
            this.formGroup.recursiveUpdateValueAndValidity();
        }
    }
    public onVisitStartTimeChange(event: MatSelectChange) {
        const time = this.formGroup.controls.visitStartTime.value;
        if (time) {
            this.data.visitStartDateTime = this.data.visitStartDateTime.copyTimeFrom(time);
            this.dataChangeEvent.emit(this.data);
            this.formGroup.recursiveUpdateValueAndValidity();
        }
    }
    public onVisitEndTimeChange(event: MatSelectChange) {
        const time = this.formGroup.controls.visitEndTime.value;
        if (time) {
            this.data.visitEndDateTime = this.data.visitEndDateTime.copyTimeFrom(time);
            this.dataChangeEvent.emit(this.data);
            this.formGroup.recursiveUpdateValueAndValidity();
        }
    }
}
@Component({
    selector: 'medpace-create-request-visit-details',
    templateUrl: './medpace-create-request-visit-details.component.html',
    styleUrls: ['./medpace-create-request-visit-details.component.scss'],
})
export class MedpaceCreateRequestVisitDetailsComponent extends BaseEditableCardComponent<ViewModel, Data> {
    public RequestTypes = RequestTypes;
    public paymentRequest_Handler: PaymentRequestHandler;
    public travelRequest_Handler: TravelRequestHandler;

    public formGroup: FormGroup = null;
    public readonly minDate = new Date();
    constructor(private fb: FormBuilder) {
        super();
    }
    getFormGroup() {
        return this.formGroup ?? new FormGroup({});
    }
    protected override onViewModelChange(): void {
        this.paymentRequest_Handler = new PaymentRequestHandler(this._viewModel.paymentRequest_ViewModel, this.fb);
        this.paymentRequest_Handler.dataChangeEvent
            .pipe(takeUntil(this.destroySubject))
            .subscribe((data) => this.onPaymentRequestDataChange(data));
        this.travelRequest_Handler = new TravelRequestHandler(this._viewModel.travelRequest_ViewModel, this.fb);
        this.travelRequest_Handler.dataChangeEvent
            .pipe(takeUntil(this.destroySubject))
            .subscribe((data) => this.onTravelRequestDataChange(data));

        this._data = <Data>{
            requestType: this._viewModel.requestType,
            paymentRequest_Data: this.paymentRequest_Handler.data,
            travelRequest_Data: this.travelRequest_Handler.data,
        };

        this.formGroup =
            this._viewModel.requestType === RequestTypes.PAYMENT
                ? this.paymentRequest_Handler.getFormGroup()
                : this.travelRequest_Handler.getFormGroup();

        this.viewModelChangeEvent.emit(this._viewModel);
        this.formGroupChangeEvent.emit(<FormGroup>this.formGroup);
    }
    private onPaymentRequestDataChange(data: PaymentRequest_Data) {
        this.dataChangeEvent.emit(this._data);
    }
    private onTravelRequestDataChange(data: TravelRequest_Data) {
        this.dataChangeEvent.emit(this._data);
    }
}
