import { Directive, Input, OnDestroy, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription, merge, tap } from 'rxjs';

@Directive({
    selector: 'mds-tab[formErrorHighlighter]',
})
export class FormErrorHighlighterDirective implements OnDestroy {
    private subscriptions = new Array<Subscription>();
    @Input() filterContent: string;
    private _forms: AbstractControl[];
    @Input() set forms(value: AbstractControl[]) {
        if (!value) return;
        this._forms = value.filter((form) => !!form);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [
            merge(...this._forms.map((form) => form.valueChanges))
                .pipe(
                    tap(() => {
                        Array.from(document.querySelectorAll('span.mds-tab-label'))
                            .filter(
                                (el) =>
                                    !this.filterContent ||
                                    el.textContent.toLowerCase() === this.filterContent.toLowerCase()
                            )
                            .forEach((el) => {
                                if (this._forms.some((f) => f.status === 'INVALID'))
                                    this.renderer.addClass(el, 'error-text');
                                else this.renderer.removeClass(el, 'error-text');
                            });
                    })
                )
                .subscribe(),
        ];
    }
    constructor(private renderer: Renderer2) {}
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
