import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { StudyPatientViewModel } from 'src/app/models/patient';

import { SiteDataModel } from 'src/app/models/site';
import { User } from 'src/app/models/user';
import {
    ActiveStudySummaryViewModel,
    CreateUpdateStudyDataModel,
    ExpenseType,
    Study,
    StudyDataModel,
    StudyDataModel_DTO,
    StudyRegionDataModel,
    Study_DTO,
    TravelOptionViewModel,
    VisitName,
    mapStudyDataModel_DTO_To_StudyDataModel,
    mapStudy_DTO_To_Study,
} from '../../models/study';

import { CountryRegionCtsmViewModel } from '@models/clintrak';
import { PatientVisitDto } from '@models/patient-visit-name-history-element/patient-visit-name-history-element';
import { SiteService } from 'src/app/services/site/site.service';
import { convertToFormData } from 'src/app/utility/formData-util';

@Injectable()
export class AdminStudyRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    createStudy: (newStudy: CreateUpdateStudyDataModel) => Observable<HttpResponse<number>> = (
        newStudy: CreateUpdateStudyDataModel
    ) => {
        // TODO: Temporary solution, form should be used directly
        const formData = convertToFormData(newStudy);
        return this.client.post<number>(`${this.siteService.getSiteBaseUrl()}/api/studies`, formData, {
            observe: 'response',
        });
    };

    updateStudy: (studyId: number, study: CreateUpdateStudyDataModel) => Observable<number> = (
        studyId: number,
        study: CreateUpdateStudyDataModel
    ) => {
        // TODO: Temporary solution, form should be used directly
        const formData = convertToFormData(study);
        return this.client.put<number>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}`, formData);
    };

    getStudies: () => Observable<StudyDataModel[]> = () => {
        return this.client
            .get<StudyDataModel_DTO[]>(`${this.siteService.getSiteBaseUrl()}/api/studies`)
            .pipe(map((dtos) => dtos.map((dto) => mapStudyDataModel_DTO_To_StudyDataModel(dto))));
    };

    getAllStudySummaries: () => Observable<ActiveStudySummaryViewModel[]> = () => {
        return this.client.get<ActiveStudySummaryViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/allsummaries`
        );
    };

    getAllPendingApprovalStudySummaries: () => Observable<Study[]> = () => {
        return this.client
            .get<Study_DTO[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/pendingapproval`)
            .pipe(map((dtos) => dtos.map((dto) => mapStudy_DTO_To_Study(dto))));
    };

    getStudySummaries: () => Observable<Study[]> = () => {
        return this.client
            .get<Study_DTO[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/studysummaries`)
            .pipe(map((dtos) => dtos.map((dto) => mapStudy_DTO_To_Study(dto))));
    };

    getSiteTravelSupportCount(studyId: number): Observable<number> {
        return this.client.get<number>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/site/travelsupport/count`
        );
    }

    getStudy: (studyId: number) => Observable<StudyDataModel> = (studyId: number) => {
        return this.client
            .get<StudyDataModel_DTO>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}`)
            .pipe(map((dto) => mapStudyDataModel_DTO_To_StudyDataModel(dto)));
    };

    getStudySites: (studyId: number) => Observable<SiteDataModel[]> = (studyId: number) => {
        return this.client.get<SiteDataModel[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/sites`);
    };

    getStudyVisits: (studyId: number) => Observable<VisitName[]> = (studyId: number) => {
        return this.client.get<VisitName[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/visitNames`);
    };

    getExpenseTypes: (studyId: number) => Observable<ExpenseType[]> = (studyId: number) => {
        return this.client.get<ExpenseType[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/expenseTypes`
        );
    };

    getStudyRegions: (studyId: number) => Observable<StudyRegionDataModel[]> = (studyId: number) => {
        return this.client.get<StudyRegionDataModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/regions`
        );
    };

    getStudyCountries(studyId: number): Observable<CountryRegionCtsmViewModel[]> {
        return this.client.get<CountryRegionCtsmViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/countries`
        );
    }

    getStudyTravelOptions: (studyId: number) => Observable<TravelOptionViewModel[]> = (studyId: number) => {
        return this.client.get<TravelOptionViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/traveloptions`
        );
    };

    getStudyPatients: (studyId: number) => Observable<StudyPatientViewModel[]> = (studyId: number) => {
        return this.client.get<StudyPatientViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/patientdata`
        );
    };

    getStudyUsers: (studyId: number) => Observable<User[]> = (studyId: number) => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/users`);
    };

    getStudyVisitNamesWithPatientRequests: (studyId: number, patientId: number) => Observable<PatientVisitDto[]> = (
        studyId: number,
        patientId: number
    ) => {
        return this.client.get<PatientVisitDto[]>(
            `${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/patients/${patientId}/visitnames`
        );
    };
}
