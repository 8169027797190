import { DatePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DateFormat } from '@models/date-format';
import { CountryStateService } from '@services/state-management/country-state.service';
import { isMoment, Moment } from 'moment';
import { EditFieldFormGroup } from '../edit-field.formgroup';

@Component({
    selector: 'medpace-edit-request-table',
    templateUrl: './edit-request-table.component.html',
})
export class EditRequestTableComponent {
    countryStateService = inject(CountryStateService);
    datePipe = inject(DatePipe);
    countries$ = this.countryStateService.getCountriesStringArray();
    data = input.required<EditFieldFormGroup<any>[]>();
    dataSource = computed(() => {
        const data = this.data();
        if (!data) return;
        return new MatTableDataSource(data);
    });
    displayCols = ['name', 'currentValue', 'requestedValue', 'accepted'];

    asEditFieldFormGroup(formGroup: any) {
        return formGroup as EditFieldFormGroup<any>;
    }
    getCurrentValueText(formGroup: EditFieldFormGroup<any>) {
        if (typeof formGroup.currentValue === 'boolean') return formGroup.currentValue ? 'Yes' : 'No';
        else if (isMoment(formGroup.currentValue))
            return this.datePipe.transform((formGroup.currentValue as Moment).toDate(), DateFormat.dateOnly);
        return formGroup.currentValue || 'Undefined';
    }
}
