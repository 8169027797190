import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorObject } from '@models/error-details';
import { SiteService } from '@services/site/site.service';
import { convertToFormData } from '@utility/formData-util';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorReportRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    sendError: (errorReport: ErrorObject) => Observable<HttpResponse<number>> = (errorReport: ErrorObject) => {
        // TODO: Temporary solution, form should be used directly
        const formData = convertToFormData(errorReport);
        return this.client.post<number>(`${this.siteService.getSiteBaseUrl()}/api/error`, formData, {
            observe: 'response',
        });
    };
}
