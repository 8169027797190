import { AccessLevelTypes } from '../enums/AccessLevelTypes';

export function mapAccessTypeName(accessGroup: string | number): string {
    const ag = typeof accessGroup === 'string' ? AccessLevelTypes[accessGroup] : accessGroup;
    switch (ag) {
        case AccessLevelTypes.CRC:
            return 'CRC User';
        case AccessLevelTypes.SuperAdmin:
            return 'Super Admin';
        case AccessLevelTypes.NoRole:
            return 'No Role';
        case AccessLevelTypes.Admin:
            return 'Admin';
        default:
            return '';
    }
}
