<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__col">
            <div class="o-grid">
                <div class="o-grid__row"></div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <h5 class="uppercase">{{ getTitleLabel() }}</h5>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-patient
                            [patient]="patient"
                            [study]="study"
                            [site]="site"
                            [isAdmin]="isAdmin"
                            [isSuperAdmin]="isSuperAdmin"
                            [isUpdate]="isUpdate"
                            (consentChangeEvent)="registerConsentChange($event)"
                            (travelChangeEvent)="registerTravelChangeEvent($event)"
                            (accessibilityChangeEvent)="registerAccessibilityChangeEvent($event)"
                            (identificationChangeEvent)="registerIdentificationChangeEvent($event)"
                            (submitClickEvent)="sendData($event)"
                        ></medpace-patient>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
