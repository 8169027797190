<ng-container *ngIf="setupComponent$ | async">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <h5>Accessibility Requirements</h5>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-checkbox
                label="Walker"
                id="walker"
                (changeEvent)="emitChange($event)"
                [formGrp]="accessibilityReqFormGroup"
                formCtrlName="walkerControl"
            >
            </mds-checkbox>
            <mds-checkbox
                label="Wheelchair"
                id="wheelchair"
                (changeEvent)="emitChange($event)"
                [formGrp]="accessibilityReqFormGroup"
                formCtrlName="wheelchairControl"
            >
            </mds-checkbox>
            <mds-checkbox
                label="Ramp"
                id="ramp"
                (changeEvent)="emitChange($event)"
                [formGrp]="accessibilityReqFormGroup"
                formCtrlName="rampControl"
            >
            </mds-checkbox>
            <mds-checkbox
                label="Other"
                id="other"
                (changeEvent)="emitChange($event)"
                [formGrp]="accessibilityReqFormGroup"
                formCtrlName="otherControl"
            >
            </mds-checkbox>
        </div>
    </div>
    <div *ngIf="accessibilityRequirements?.other && !isEditing" class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Additional Requirements</h5>
                <span>{{ accessibilityRequirements?.additionalRequirements }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="accessibilityReqFormGroup.controls.otherControl.value && isEditing">
        <div class="o-grid__item">
            <mds-text-field
                id="additionalRequirements"
                [formGrp]="accessibilityReqFormGroup"
                formCtrlName="additionalRequirementsControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Additional Requirements"
                width="100%"
                (input)="emitAdditionalRequirements({ id: 'additionalRequirements' }, $event.target.value)"
                additionalRequirements
            ></mds-text-field>
        </div>
    </div>
</ng-container>
