import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FileType } from '@app/enums/FileTypes';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { CurrencyViewModel } from '@models/currency';
import { Patient } from '@models/patient';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, MdsOptionGeneric } from '@utility/utility';
import { maxValidator } from '@utility/utility.validators';
import { Moment } from 'moment';
import { Observable, filter, tap } from 'rxjs';
export class TransactionDetailsFormGroup extends PersistentFormGroup<{
    receiptDate: PersistentFormControl<Moment>;
    receiptAmount: FormControl<string>; // mds input field will return the number as a string
    currency: FormControl<CurrencyViewModel>;
    uploadedAttachment: FormControl<File>;
    downloadAttachmentId: FormControl<number>;
    downloadAttachmentName: FormControl<string>;
}> {
    public static create(value: FormGroupValueOf<TransactionDetailsFormGroup>): TransactionDetailsFormGroup {
        return new TransactionDetailsFormGroup({
            receiptDate: new PersistentFormControl(value.receiptDate),
            receiptAmount: new FormControl(!!+value.receiptAmount ? (+value.receiptAmount).toFixed(2) : '', [
                Validators.min(0),
                maxValidator(2e6),
            ]),
            currency: new FormControl(value.currency),
            uploadedAttachment: new FormControl(value.uploadedAttachment),
            downloadAttachmentId: new FormControl(value.downloadAttachmentId),
            downloadAttachmentName: new FormControl(value.downloadAttachmentName),
        });
    }
}
@Component({
    selector: 'medpace-transaction-details',
    templateUrl: './transaction-details.component.html',
    styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit {
    private patientStateService = inject(PatientStateService);
    @Input() mode: EditableCardMode;
    @Input() formGroup: TransactionDetailsFormGroup;
    @Input() currencyOptions: MdsOptionGeneric<CurrencyViewModel>[];
    @Input() acceptableFileTypes: string[] = [FileType.PDF, FileType.JPG, FileType.JPEG, FileType.PNG];
    @Output() downloadAttachmentEvent = new EventEmitter<{ attachmentId: number; attachmentName: string }>();
    setupPrefferedCurrency$: Observable<Patient | null>;

    ngOnInit(): void {
        //definition of observable in ngOnInit to make sure every input field is initialized
        //sets up Currency field
        //1. Add required validator to Currency field
        //2. Prepopulate the currency field with patient preferred currency if the field is empty
        this.setupPrefferedCurrency$ = this.patientStateService.getSummaryPatientData().pipe(
            filter(Boolean),
            tap((patient: Patient) => {
                //asign preffered currency only when field is empty
                if (!this.formGroup.controls.currency?.value) {
                    const patientPreferredCurrency = patient?.preferredCurrency;
                    let option: CurrencyViewModel;
                    if (!!patientPreferredCurrency) {
                        option = this.currencyOptions.find((val) => val.viewValue === patientPreferredCurrency)?.value;
                    } else {
                        //fallback to USD
                        option = this.currencyOptions.find((val) => val.viewValue === 'USD')?.value;
                    }
                    this.formGroup.controls.currency.setValue(option);
                }
                //make field required
                this.formGroup.controls.currency.setValidators(Validators.required);
            })
        );
    }
    protected downloadAttachment() {
        const value = this.formGroup.getRawValue();
        this.downloadAttachmentEvent.emit({
            attachmentId: value.downloadAttachmentId,
            attachmentName: value.downloadAttachmentName,
        });
    }
    protected onFileSelected(file: File) {
        this.formGroup.controls.uploadedAttachment.setValue(file);
    }
}
