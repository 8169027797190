import { DatePipe } from '@angular/common';
import { CaregiverEditRequestDto } from './caregiverEditRequestDto';
import { DateFormat } from './date-format';

export interface PatientEditRequestDto {
    id?: number;
    patientId: number;
    preferredLang?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    profileChangeDetails?: string;
    isSubmitted?: boolean;
    lastUpdateTimestamp: string;
    //travel Preferences
    //airline
    airlinePrimary?: string;
    airlineSecondary?: string;
    airlineRewardsPrimary?: string;
    airlineRewardsSecondary?: string;
    seatPreference?: string;
    knownTravelerNum?: string;
    airlineSpecialNeeds?: string;
    //train
    trainSpecialNeeds?: string;
    //international - passport info
    passportNum?: string;
    passportCountry?: string;
    passportExpiration?: string;
    passportIssue?: string;
    //lodging
    hotelChainPrimary?: string;
    lodgingRoomPreference?: string;
    allergies?: string;
    lodgingSpecialNeeds?: string;
    rentalCarFrequentTravelerNum?: string;
    //car service
    groundSpecialNeeds?: string;

    //caregiver edit requests
    caregiverEditRequests: CaregiverEditRequestDto[];

    patientInfo: PatientEditRequestTableInfoDto;
}

export interface PatientEditRequestTableInfoDto {
    patientId: number;
    pseudoId: string;
    patientNum: string;
    firstName: string;
    lastName: string;
}

export class PatientEditRequestTableViewModel {
    patientId: number;
    pseudoId: string;
    patientNum: string;
    firstName: string;
    lastName: string;
    submitTimestamp: string;
}

export function transformPatientEditRequestDtoToPatientEditRequestTableViewModel(
    per: PatientEditRequestDto,
    datePipe: DatePipe
) {
    const transform: PatientEditRequestTableViewModel = {
        patientId: per.patientId,
        pseudoId: per.patientInfo.pseudoId,
        patientNum: per.patientInfo.patientNum,
        firstName: per.patientInfo.firstName,
        lastName: per.patientInfo.lastName,
        submitTimestamp: datePipe
            .transform(new Date(per.lastUpdateTimestamp), DateFormat.dateTime12, null, 'en-US')
            .toUpperCase(),
    };

    return transform;
}
