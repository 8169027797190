/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';
export class TransferPreferencesFormGroup extends PersistentFormGroup<{
    specialNeeds: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<TransferPreferencesFormGroup>): TransferPreferencesFormGroup {
        return new TransferPreferencesFormGroup({
            specialNeeds: new PersistentFormControl(value.specialNeeds, maxLengthValidator(500)),
        });
    }
}
@Component({
    selector: 'medpace-transfer-preferences',
    templateUrl: './transfer-preferences.component.html',
    styleUrls: ['./transfer-preferences.component.scss'],
})
export class TransferPreferencesComponent {
    @Input() formGroup: TransferPreferencesFormGroup;
    @Input() mode: EditableCardMode;
}
