import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'medpace-request-error-modal',
    templateUrl: './request-error-modal.component.html',
    styleUrls: ['./request-error-modal.component.scss'],
})
export class MedpaceRequestErrorModalComponent {
    constructor(public dialogRef: MatDialogRef<MedpaceRequestErrorModalComponent>) {}

    closeDialog(): void {
        this.dialogRef.close();
    }
}
