import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { ApplicationInsightsFactory } from './application-insights-factory';
import { EnvConfigurationService } from '@services/env/env-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights | null = null;

    constructor(router: Router, appInsightsFactory: ApplicationInsightsFactory, envService: EnvConfigurationService) {
        this.appInsights = appInsightsFactory.createApplicationInsightsInstance(
            envService.configuration.appInsightsConnectionString,
            router
        );

        if (this.appInsights) {
            this.appInsights.loadAppInsights();
        } else {
            console.warn(
                'Application Insights was not enabled, because the connection string was not found in the configuration.'
            );
        }
    }

    // The above setup enables basic integration (logging unhandled exceptions, requests, performance traces etc.)
    // The following methods allow us to log custom messages to App Insights:

    logDebug(message: string): void {
        if (this.appInsights) {
            this.appInsights.trackTrace({ message: message, severityLevel: 0 });
        }
    }

    logInfo(message: string): void {
        if (this.appInsights) {
            this.appInsights.trackTrace({ message: message, severityLevel: 1 });
        }
    }

    logWarning(message: string): void {
        if (this.appInsights) {
            this.appInsights.trackTrace({ message: message, severityLevel: 2 });
        }
    }

    logError(message: string): void {
        if (this.appInsights) {
            this.appInsights.trackTrace({ message: message, severityLevel: 3 });
        }
    }

    logException(error: Error): void {
        if (this.appInsights) {
            this.appInsights.trackException({ exception: error });
        }
    }
}
