<ng-container *ngIf="mode === 'view'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special needs</h5>
                    <span>{{ formGroup.controls.specialNeeds.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="mode === 'edit'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="specialNeeds"
                    [formCtrl]="formGroup.controls.specialNeeds"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Special needs"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
    </div>
</ng-container>
