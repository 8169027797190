export interface KycAuditLog {
    id: number;
    responsibleUserId: number;
    responsibleUserEmail: string;
    loggedAction: string;
    logTimeStamp: Date;
    fileName: string;
    studyCode: string;
    siteNumber: string;
    patientId: number;
    patientNum: string;
}

export class KycAuditLog implements KycAuditLog {}
