import { Injectable } from '@angular/core';
import { TravelType } from '@app/enums/travel-type.enum';
import { CarRentalRequestDetailsFormGroup } from '@components/molecules/forms/travel-card/car-rental-request-details/car-rental-request-details.component';
import { SingleFlightFormGroup } from '@components/molecules/forms/travel-card/flight-request-details/flight-details/flight-details.component';
import { LodgingRequestDetailsFormGroup } from '@components/molecules/forms/travel-card/lodging-request-details/lodging-request-details.component';
import { MomentFormat } from '@models/date-format';
import { CreateGetThereUrlViewModel } from '@models/getthere/createGetThereUrlViewModel';
import { VisitDetails } from '@models/request';
import { GetThereRepository } from '@repositories/getthere/getthere.repository';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TimeOptionsStateService } from '@services/state-management/time-options-state.service';
import { mapToGetThereCountryCode, mapToGetThereCountryName } from '@services/transforms/getThere-transform';
import { FormGroupValueOf } from '@utility/utility';
import { Moment, utc } from 'moment';
import { Observable } from 'rxjs';

export interface GetThereInputParametersDTO {
    // userInfo: {
    /**
     * Identifier of a user in GetThere. Required field. Case sensitive.
     * GetThere doesn't integrate with our B2C or other user catalogue of that kind. Instead, it has their own catalogue of users.
     * Users are a sort of required metadata to be passed, and not a mean of identification of a real user. Users don't have any passwords assigned, and authentication between systems is based on a single symmetric key.
     * GetThere's instance can be set up to accept unknown user names (it will just create user when it encounters new user identifier).
     *
     * Documentation is inconsistent on how long this field can be:
     *    - one document says '3-15' characters,
     *    - the other - '1-20',
     *    - I experimentally tested that 64 ASCII characters are accepted, but 65 and more result in "Bad UID" error
     *
     * Example often used in documentation: 'vbear'
     * In Medpace's `Facilities-GetThere` integration's, they pass a lowercase username of an user from the Active Directory's.
     * For example, for a domain user "Medpace\J.Bond" they'd pass "j.bond" here.
     *
     * See `2023-09-21-authentication-with-getthere.md` to understand what we decided to send in this field.
     * TL/DR: we send a unique id for each traveler. When user gets redirected to GetThere Portal, we don't want them to be able to manage travel plans
     * of all patients and caregivers,but just one specific traveler.
     *
     * The login flow is documented in the last slide of `2023-06-22-SSO_Implementation.ppt`
     */
    user: string;

    /**
     * Required field. C for current, N for new.
     *
     * "Creating a new user is just like logging in an existing user, except the user_flag is set to "n" for new instead of "c" for current user."
     * Alternatively: "Using the “Assume Unknown Users are New Users” feature in site admin. You can have the SSO request user_flag=c."
     *
     * "When creating users, you must specify the subsite name and subsite key in the SSO request."
     */
    user_flag: 'c' | 'n';

    /**
     * Required field. "" for domestic travel, "i" for International.
     */
    msg_e: '' | 'i';

    /** Sub-site is an instance of GetThere front-end.
     * Each sub-site is configured independently, for example - it might have custom fields added to a checkout page, or some features hidden.
     * Users are always assigned to a single, specific sub-site. It happens when they are created for the first time and cannot be easily changed later.
     *
     * In Medpace, we have a single root site 'medpace'. We could pass `sub_site`=<empty string> to be redirected there, but we were asked not to use it.
     * We also have a few sub-sites in `cert` and `production` environments, for example:
     * - `medpace_guest` dedicated to the Patient Concierge Service integration only
     * - `medpace_latam` to use in the Latin America area (I don't know specifically how we use it)
     * - `medpaceus` to use in USA & Canada area
     * - and a few obsolete environments, and environments used for testing.
     */
    sub_site: 'medpace_guest';

    /** A key that must match the `sub_site`. For `medpace_guest` it's `newuser`.
     * I'm not sure what's the purpose of this value. It's not documented. I was told by Sabre consultant
     * it is the same in `dev` and `production` so it's not a secret. We are supposed to just pass the value `newuser` here.
     */
    ss_key: 'newuser';

    // personalInfo: {
    // Docs: "The Profile Info field contains user profile information. The Profile Info field is optional and may not be present."

    /**
     * Traveler's First name.
     * This field is not documented, but exists in examples with a values like 'Fred' and 'Vernon'
     * GetThere's behavior is thet this name will appear in two places:
     * 1) As traveler's name in the checkout page
     * 2) It will also replace a name of a GetThere's user (that we pass via `user_id`)
     */
    first_name: string;

    /** Middle initial of Traveler's name */
    middle_i: string;

    /** Last Name of the Traveler*/
    last_name: string;

    /** Traveler's email */
    email: string;

    /** Traveler's birth year. A number represented as a string in the `yyyy` format, for example `1999`.
     *
     *  Compliance with Payment Card Industry standards required this field to be occluded in the GetThere Portal,
     *  so it might appear as if the fields are not initialized with a value, but it is effective and users don't have to
     *  fill in those fields manually. It's weird from the UX perspective, though, so it might be a source of confusion.
     */
    sfp_birth_year: string;

    /** Traveler's birth month. A number represented as a string in range of `1`-`12` */
    sfp_birth_month: string;

    /** Traveler's birth day. A number represented as a string in range of `1`-`31` */
    sfp_birth_day: string;

    /** Traveler's gender. */
    gender: 'm' | 'f' | '';
    //  };

    // companyInfo: {
    company: string;
    corp_ID: string;
    emp_no: string;
    cost_ctr: string;
    division: string;
    department: string;

    // addressInfo: {
    address: string;
    mail_stop: string;
    city: string;
    state: string;
    zip: string;
    country: string;

    /** Indicates the country code of the country to which the user is traveling.
     * Must use the two-character code from https://wtools.getthere.net/code/CountryCodes.jsp.
     * Optional for air only, but required for car or hotel bookings to avoid the user having to fill in the drop-down list for country. */
    countryCode: string; // two-character code

    day_phone: string;
    home_phone: string;

    //    customFieldsInfo: {

    /** The label in GetThere UI is "Mobile/Cellular Phone". Business owners decision (#10403): we should always send a constant string `866-715-4403` which is main PCS phone line */
    mobile_phone: string;

    /** Displayed as "Job title" in the GetThere UI. We agreed with business that we want a constant string, `Patient Recruitment Coordinator` here. Business owner's decision (#10403): we should send a constant string `Patient Recruitment Coordinator` here */
    position: string;

    /** Business owners decision (#10403): we should always send a constant string `N/A` */
    sfp_known_traveler_number: string;

    /** We should get this value from a "Study code" field in PCS */
    project_code: string;

    /** We should get this value from a "Site #" field in PCS */
    site_num: string;

    /** We should get this value from a "Patient Id" field in PCS */
    patient_id: string;

    /** We should get this value from a "Visit name" field in PCS */
    visit_num: string;

    /** The format of the string should be: "DD-MMM-YYYY, Example: 20-Feb-2021" */
    date_visit: string;

    //  tripInfo: {
    need_air: 'yes' | 'no';
    // airSearchCriteria: {

    /** Specifies the type of trip, and which page shows when the user accesses the system.  Values include “roundTrip” , “oneWay”, and “multipledestination”. Only needed when air [travel] is specified. */
    tripType: 'roundTrip' | 'oneWay' | 'multipledestination' | '';

    /** This indicated the class of service.  Valid values include “1” = coach, “2” = business. “3” = first */
    cabinClass: CabinClass;

    /** This indicates the pricing requested/  The valid values are “1” = Lowest Available, “2” = No Advance Purchase, “3” = No Penalty, “4” = Unrestricted */
    pricingType: PricingType;

    /** Specifies whether a hotel is needed.  Valid values are “yes” or “no” */
    need_hotel: 'yes' | 'no';

    /** Specifies whether a car rental is needed.  Valid values are “yes” or “no” */
    need_car: 'yes' | 'no';

    /** Specifies whether rail [train travel booking] is needed.  Valid values are “yes” or “no”. Only valid if Amtrak or SNCF is configured on the site.
     * Warning: Train travel booking is scheduled to be decommissioned by Sabre in October 2023.*/
    need_rail: 'yes' | 'no';

    /** Info from GetThere consultant: booking more than 1 travel (e.g. travel for passenger + caregiver) in a single
     * transaction is not reliable. This is against how GetThere backend was designed and requires a lot of effort on
     * their site to configure. We should avoid sending anything other than '1' here. */
    numPassengers: number;
    //   };

    //  departureInfo: {

    /** This indicates whether the traveler wants the search to be based on when the transportation departs from
     * the origin or when it arrives at the destination. The valid values are “departs” and “arrives” */
    'travelMethodList[0]': 'departs' | 'arrives' | '';

    /** Indicates the departure airport. Can be the airport code or the city name, but the city name will result in an
     * additional lookup page in GetThere to select airport. */
    'departList[0]': string;

    /** Indicates the number of the month of departure, with “1” being January and “12“ being December.
     *
     * This single value is used to initialize the day part of *all* of the following date fields:
     * - Flight date (if need_air is 'yes')
     * - Hotel check-in date (if need_hotel is 'yes')
     * - Car pick-up date (if need_car is 'yes')
     * */
    'monAbbrList[0]': string;

    /** Indicates the day of departure. Valid values include “1” through “31”.
     *
     * This single value is used to initialize the day part of *all* of the following date fields:
     * - Flight date (if need_air is 'yes')
     * - Hotel check-in date (if need_hotel is 'yes')
     * - Car pick-up date (if need_car is 'yes')
     * */
    'dateList[0]': string;

    /** Indicates the time preference of the flight.
     *  Valid values are 0, 1, to 23 for 12am, 1am, to 11pm, respectively.
     *  There is no way to specify Morning, Afternoon, or Evening, but they evaluate to 5am, 12pm, and 6pm in the drop-down list.
     *
     * This single value is used to initialize the day part of *all* of the following fields:
     * - Flight preferred time (if need_air is 'yes')
     * - Car pick-up time (if need_car is 'yes')
     * */
    'timeList[0]': string;
    //   };
    //  destinationInfo: {

    /** This indicates whether the traveler wants the search [of the return flight] to be based on when the transportation departs from
     * the origin or when it arrives at the destination. The valid values are “departs” and “arrives” */
    'travelMethodList[1]': string;

    /** Indicates the destination airport. Can be the airport code or the city name, but city name will result in
     * an additional airport lookup page in GetThere.
     *
     * This field is also used by GetThere for a secondary purpose: Car Rental pick-up location is set to this value if need_car=`yes`.
     * */
    'destinationList[0]': string;

    /** Indicates the number of the month of return, with “1” being January and “12“ being December.
     *
     * This single value is used to initialize the day part of *all* of the following date fields:
     * - Flight return date (if need_air is 'yes')
     * - Hotel check-out date (if need_hotel is 'yes')
     * - Car drop-off date (if need_car is 'yes')
     * */
    'monAbbrList[1]': string;

    /** Indicates the day of return. Valid values include “1” through “31”.
     *
     * This single value is used to initialize the day part of *all* of the following date fields:
     * - Flight return date (if need_air is 'yes')
     * - Hotel check-out date (if need_hotel is 'yes')
     * - Car drop-off date (if need_car is 'yes')
     * */
    'dateList[1]': string;

    /** Indicates the time preference of the return flight.
     *  Valid values are 0, 1, to 23 for 12am, 1am, to 11pm, respectively.
     *
     * This single value is used to initialize the day part of *all* of the following date fields:
     * - Return flight preferred time (if need_air is 'yes')
     * - Car drop-off time (if need_car is 'yes')
     * */
    'timeList[1]': string;
    //  };
    //  errorHandling: {
    err_url: string;
    //  };
}

/** An object representation of search pre-population parameters accepted by GetThere. The exact names of parameter in this class might differ from what we pass to GetThere, as there is a mapping of types before we send them. */
export interface GetThereInputParameters {
    // userInfo: {
    /**
     * Required field. This will need to be the final version. Case sensitive.
     *
     * See `2023-09-21-authentication-with-getthere.md` to understand what we decided to send in this field.
     * TL/DR: we send a unique id for each traveler. When user gets redirected to GetThere Portal, we don't want them to be able to manage travel plans
     * of all patients and caregivers,but just one specific traveler.
     */
    user: string;
    /**
     * Required field. C for current, N for new.
     */
    user_flag: 'c' | 'n';
    /**
     * Required field. "" for domestic travel, "i" for International.
     */
    msg_e: '' | 'i';
    sub_site: 'medpace_guest';
    ss_key: 'newuser';

    // personalInfo: {
    first_name: string;
    middle_i: string;
    last_name: string;
    email: string;
    sfp_birth_year: string;
    sfp_birth_month: string;
    sfp_birth_day: string;
    gender: 'm' | 'f' | '';
    //  };

    // companyInfo: {
    company: string;
    corp_ID: string;
    emp_no: string;
    cost_ctr: string;
    division: string;
    department: string;

    // addressInfo: {
    address: string;
    mail_stop: string;
    city: string;
    state: string;
    zip: string;
    country: string;

    /** Indicates the country code of the country to which the user is traveling.
     * Must use the standard two-character code.
     * Optional for air, required for car or hotel bookings to avoid the user having to fill in the drop-down list for country. */
    countryCode: string;

    day_phone: string;
    home_phone: string;

    //    customFieldsInfo: {

    /** The label in GetThere UI is "Mobile/Cellular Phone" */
    mobile_phone: string;

    /** Displayed as "Job title" in the GetThere UI. */
    position: string;

    /** Business owners decision (#10403): we should always send a constant string `N/A` */
    sfp_known_traveler_number: string;

    /** We should get this value from a "Study code" field in PCS */
    project_code: string;

    /** We should get this value from a "Site #" field in PCS */
    site_num: string;

    /** We should get this value from a "Patient Id" field in PCS */
    patient_id: string;

    /** We should get this value from a "Visit name" field in PCS */
    visit_num: string;

    /** The format of the string should be: "DD-MMM-YYYY, Example: 20-Feb-2021" */
    date_visit: string;

    //  tripInfo: {

    /* Specifies whether air [travel] is needed.  Valid values are “yes” or “no” */
    need_air: 'yes' | 'no';

    // airSearchCriteria: {
    tripType: 'roundTrip' | 'oneWay' | 'multipledestination' | '';
    cabinClass: CabinClass;
    pricingType: PricingType;

    need_hotel: 'yes' | 'no';
    need_car: 'yes' | 'no';
    need_rail: 'yes' | 'no';
    numPassengers: number;

    origin: string;
    destination: string;
    departureDate: Moment;
    returnDate: Moment;
    departureTravelMethod: 'departs' | 'arrives' | '';
    returnTravelMethod: 'departs' | 'arrives' | '';
    //  errorHandling: {
    err_url: string;
}

export enum PricingType {
    LowestAvailable = 1,
    NoAdvancePurchase = 2,
    NoPenalty = 3,
    Unrestricted = 4,
}
export enum CabinClass {
    Coach = 1,
    Business = 2,
    First = 3,
}
export function mapToDTO(value: GetThereInputParameters): GetThereInputParametersDTO {
    let dto: GetThereInputParametersDTO = {
        ...value,
        'departList[0]': value.origin,
        'destinationList[0]': value.destination,
        'monAbbrList[0]': value.departureDate ? (value.departureDate.month() + 1).toString() : '',
        'monAbbrList[1]': value.returnDate ? (value.returnDate.month() + 1).toString() : '',
        'dateList[0]': value.departureDate ? value.departureDate.date().toString() : '',
        'dateList[1]': value.returnDate ? value.returnDate.date().toString() : '',
        'timeList[0]': value.departureDate ? value.departureDate.hour().toString() : '',
        'timeList[1]': value.returnDate ? value.returnDate.hour().toString() : '',
        'travelMethodList[0]': value.departureTravelMethod,
        'travelMethodList[1]': value.returnTravelMethod,
    };
    for (const key in dto) {
        if (dto[key] === undefined || dto[key] === null || dto[key] === '') dto[key] = '';
    }
    return dto;
}
export interface GetThereTravelerId {
    travelerId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    emailAddress: string;
    country: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    phoneNumber: string;
    gender: string;
    birthDate: string;
    studyCode: string;
    siteNumber: string;
    patientId: string;
}
@Injectable({
    providedIn: 'root',
})
export class GetThereService {
    constructor(
        private getThereRepository: GetThereRepository,
        private timeOptionsService: TimeOptionsStateService,
        private snackbarService: SnackbarService
    ) {}

    getUrl(inputParameters: GetThereInputParametersDTO): Observable<CreateGetThereUrlViewModel> {
        return this.getThereRepository.getUrl(inputParameters);
    }

    getInputStringForNewBooking(): GetThereInputParameters {
        return {
            user_flag: 'c',
            msg_e: '',
            user: '',

            sub_site: 'medpace_guest',
            ss_key: 'newuser',

            first_name: '',
            middle_i: '',
            last_name: '',
            email: '',
            sfp_birth_day: '',
            sfp_birth_month: '',
            sfp_birth_year: '',
            gender: '',

            origin: '',
            destination: '',
            tripType: 'roundTrip',
            cabinClass: CabinClass.Coach,
            pricingType: PricingType.Unrestricted,
            need_air: 'no',
            need_hotel: 'no',
            need_car: 'no',
            need_rail: 'no',
            numPassengers: 1,

            address: '',
            city: '',
            company: '',
            corp_ID: '',
            cost_ctr: '',
            country: '',
            countryCode: '',
            date_visit: '',
            day_phone: '',
            department: '',
            departureDate: null,
            departureTravelMethod: '',
            division: '',
            emp_no: '',
            err_url: '',
            home_phone: '',
            mail_stop: '',

            /** Business owners decision (#10403): we should always send a constant string `866-715-4403` which is main PCS phone line */
            mobile_phone: '866-715-4403',

            patient_id: '',

            /** Business owner's decision (#10403): we should send a constant string `Patient Recruitment Coordinator` here */
            position: 'Patient Recruitment Coordinator',

            project_code: '',
            returnDate: null,
            returnTravelMethod: '',

            /** Business owners decision (#10403): we should always send a constant string `N/A` */
            sfp_known_traveler_number: 'N/A',

            site_num: '',
            state: '',
            visit_num: '',
            zip: '',
        };
    }

    createGetThereURLParams(
        getThereTravelerId: GetThereTravelerId,
        needsAirTravel: boolean,
        flightDetails: FormGroupValueOf<SingleFlightFormGroup>,
        needsLodging: boolean,
        lodgingDetails: FormGroupValueOf<LodgingRequestDetailsFormGroup>,
        needsCarRental: boolean,
        carRentalDetails: FormGroupValueOf<CarRentalRequestDetailsFormGroup>,
        visitDetails: VisitDetails
    ): GetThereInputParameters {
        if (!getThereTravelerId) {
            throw Error(`Patient information is required to redirect to GetThere, but it was not found`);
        }

        let params = this.getInputStringForNewBooking();
        params.user = getThereTravelerId.travelerId;

        params.need_air = needsAirTravel ? 'yes' : 'no';
        params.need_rail = 'no';
        params.need_hotel = needsLodging ? 'yes' : 'no';
        params.need_car = needsCarRental ? 'yes' : 'no';
        if (needsAirTravel) {
            const travelType = flightDetails.travelType;
            params.tripType =
                travelType === TravelType.ONE_WAY ? 'oneWay' : travelType === TravelType.ROUND_TRIP ? 'roundTrip' : '';
            params.origin = flightDetails.airportOrigin;
            params.destination = flightDetails.airportDestination;
            params.departureDate = flightDetails.departureDate?.set('hour', 12);
            if (travelType === TravelType.ROUND_TRIP) {
                params.returnDate = flightDetails.returnDate?.set('hour', 12);
            }
        } else if (needsLodging) {
            params.departureDate = lodgingDetails.checkInDate?.set('hour', 12);
            params.returnDate = lodgingDetails.checkOutDate?.set('hour', 12);

            // In PCS we don't have a travel location defined in "Lodging" tab (separately from flight destination).
            // So, if we were requested to only book hotel (but not flight), we don't know where that hotel is.
            // For now, we'll just leave field empty and allow users fill the hotel location field manually.
            params.origin = '';
            params.destination = '';
        } else if (needsCarRental) {
            params.departureDate = carRentalDetails.pickupDate
                ?.startOf('day')
                .add(carRentalDetails.dropOffTime || 0, 'minutes');
            params.returnDate = carRentalDetails.dropOffDate
                ?.startOf('day')
                .add(carRentalDetails.dropOffTime || 0, 'minutes');
            params.origin = '';
            params.destination = carRentalDetails.pickupAddress;
            params.countryCode = mapToGetThereCountryCode(carRentalDetails.pickupCountry, this.snackbarService);
        }

        params.visit_num = visitDetails.visitName.name;
        params.date_visit = visitDetails.visitStartDate.format(MomentFormat.dateOnly);
        params.departureTravelMethod = 'departs';
        params.returnTravelMethod = 'departs';
        params.numPassengers = 1; // more than one is disabled

        params.first_name = getThereTravelerId.firstName;
        params.middle_i = getThereTravelerId.middleName;
        params.last_name = getThereTravelerId.lastName;
        params.email = getThereTravelerId.emailAddress;
        params.country = this.mapCountry(getThereTravelerId.country);
        params.address = getThereTravelerId.address1;
        params.mail_stop = getThereTravelerId.address2;
        params.city = getThereTravelerId.city;
        params.state = getThereTravelerId.state;
        params.zip = getThereTravelerId.zipcode;
        params.day_phone = getThereTravelerId.phoneNumber;
        params.home_phone = getThereTravelerId.phoneNumber;
        params.gender = this.mapToGetThereGender(getThereTravelerId.gender);
        params.sfp_birth_day = utc(getThereTravelerId.birthDate).format('D');
        params.sfp_birth_month = utc(getThereTravelerId.birthDate).format('M');
        params.sfp_birth_year = utc(getThereTravelerId.birthDate).format('Y');
        params.project_code = getThereTravelerId.studyCode;
        params.site_num = getThereTravelerId.siteNumber;
        params.patient_id = getThereTravelerId.patientId;
        return params;
    }

    /* Maps country name,
     *  - from: a string representation used by PCS
     *  - to: a full country name string representation used by GetThere [https://wtools.getthere.net/code/CountryCodes.jsp]
     */
    mapCountry(country: string): string {
        return mapToGetThereCountryName(country, this.snackbarService);
    }

    /* Maps country name,
     *  - from: a string representation used by PCS
     *  - to: a 2-letter code string representation used by GetThere [https://wtools.getthere.net/code/CountryCodes.jsp]
     */
    mapCountryToGetThereCountryCode(country: string): string {
        return mapToGetThereCountryCode(country, this.snackbarService);
    }

    mapToGetThereGender(gender: string): '' | 'm' | 'f' {
        if (gender === 'female') return 'f';
        if (gender === 'male') return 'm';
        return '';
    }
}
