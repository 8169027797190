<div [ngClass]="isCard ? 'c-card' : ''">
    <div [ngClass]="isCard ? 'c-card__header' : ''">
        <div [ngClass]="isCard ? 'c-card__ribbon' : ''">
            <div [ngClass]="isCard ? 'c-card__item c-card__item--left mb-2' : ''">
                <ng-container *ngIf="!isRequired; else required">
                    <h3>{{ title }}</h3>
                </ng-container>
                <ng-template #required>
                    <h3>
                        {{ title }}
                        <span *ngIf="editMode" class="required-asterisk">*</span>
                    </h3>
                </ng-template>
            </div>
            <div [ngClass]="isCard ? 'header-buttons' : 'noCard-header-buttons'">
                <div *ngIf="showEditSaveButton">
                    <div *ngIf="editMode" [ngClass]="isCard ? 'c-card__item c-card__item--right' : ''">
                        <mds-button
                            [id]="'saveBtn'"
                            (clickEvent)="updateEditStatus()"
                            label="Save"
                            [variant]="'outline'"
                        ></mds-button>
                    </div>
                    <div
                        *ngIf="!editMode && patient?.statusId !== 2"
                        [ngClass]="isCard ? 'c-card__item c-card__item--right' : ''"
                    >
                        <mds-button
                            [id]="'editBtn'"
                            (clickEvent)="updateEditStatus()"
                            label="Edit"
                            [variant]="'outline'"
                        ></mds-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="c-card__content">
        <form #ngForm="ngForm" class="c-form" [formGroup]="cardFormGroup" appScrollToInvalidControlOnSubmit>
            <div class="o-grid">
                <div *ngIf="type === 'radio'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-study-tier
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-study-tier>
                    </div>
                </div>

                <div class="o-grid__row">
                    <div *ngIf="type === 'site-info'" class="o-grid__item">
                        <medpace-site-info
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-site-info>
                    </div>
                </div>
                <div *ngIf="type === 'study-info'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-study-info-card
                            (inputChangeEvent)="passContentAlong($event)"
                            [content]="inputObj"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                            [isClinTrakComparison]="isClinTrakComparison"
                        ></medpace-study-info-card>
                    </div>
                </div>
                <div *ngIf="type === 'study-detail'" class="o-grid__row">
                    <div class="o-grid__item">
                        <div>
                            <medpace-study-details-card
                                (inputChangeEvent)="passContentAlong($event)"
                                [isEditing]="editMode"
                                [parentFormGroup]="cardFormGroup"
                                [isClinTrakComparison]="isClinTrakComparison"
                            ></medpace-study-details-card>
                        </div>
                    </div>
                </div>
                <div *ngIf="type === 'site-address'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-site-address
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-site-address>
                    </div>
                </div>
                <div *ngIf="type === 'site-shipping-address'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-site-shipping-address
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-site-shipping-address>
                    </div>
                </div>
                <div *ngIf="type === 'site-crc-invite'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-crc-invite-card
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                            (inputChangeEvent)="passContentAlong($event)"
                        ></medpace-crc-invite-card>
                    </div>
                </div>
                <div *ngIf="type === 'personal-info'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-personal-info-card
                            (inputChangeEvent)="passContentAlong($event)"
                            (patientActivityLinkClickEvent)="emitAction($event)"
                            (profileLinkClickEvent)="emitAction($event)"
                            [isEditing]="editMode"
                            [isPatientProfile]="isPatientProfile"
                            [isUserAdmin]="isUserAdmin"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-personal-info-card>
                    </div>
                </div>
                <div *ngIf="type === 'caregiver-info'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-caregiver-info-card
                            (caregiverUpdate)="updatePatient($event)"
                        ></medpace-caregiver-info-card>
                    </div>
                </div>
                <div *ngIf="type === 'consent-card'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-consent
                            (consentChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-consent>
                    </div>
                </div>
                <div *ngIf="type === 'site-services-card'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-site-services-card
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [parentFormGroup]="cardFormGroup"
                            [isModal]="isModal"
                            [studyId]="studyId"
                        ></medpace-site-services-card>
                    </div>
                </div>
                <div *ngIf="type === 'payment-card'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-payment-card
                            (inputChangeEvent)="passContentAlong($event)"
                            [isAdmin]="isUserAdmin"
                            [isEditing]="editMode"
                            [isPatientProfile]="isPatientProfile"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-payment-card>
                    </div>
                </div>
                <div *ngIf="type === 'travel-card'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-travel-card
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [isUpdate]="isUpdate"
                            [parentFormGroup]="cardFormGroup"
                        ></medpace-travel-card>
                    </div>
                </div>
                <div *ngIf="type === 'accessiblity-card'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-accessibility-card
                            [parentFormGroup]="cardFormGroup"
                            (inputChangeEvent)="passContentAlong($event)"
                            [isEditing]="editMode"
                        ></medpace-accessibility-card>
                    </div>
                </div>
                <div *ngIf="type === 'request-type'" class="o-grid__row">
                    <div class="o-grid__item">
                        <div>
                            <medpace-request-type-select-card
                                (requestTypeChangeEvent)="passContentAlong($event)"
                                [isAdmin]="isUserAdmin"
                                [isEditing]="editMode"
                                [isUpdate]="isUpdate"
                                [parentFormGroup]="cardFormGroup"
                            ></medpace-request-type-select-card>
                        </div>
                    </div>
                </div>
                <div *ngIf="type === 'additional-info'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-additional-travel-info-card
                            (additionalInfoChange)="passContentAlong($event)"
                            [isEditing]="editMode"
                            [request]="inputObj"
                        ></medpace-additional-travel-info-card>
                    </div>
                </div>
                <div *ngIf="type === 'reimbursement-rates'" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-reimbursement-rates-upload> </medpace-reimbursement-rates-upload>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ng-template #spinner>
        <medpace-loading-card></medpace-loading-card>
    </ng-template>
</div>
