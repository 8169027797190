import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { read, utils } from 'xlsx';

export interface ParsingResult<T> {
    isSuccess: boolean;
    parsedValues: T[];
}
@Injectable({
    providedIn: 'root',
})
export class XLSXService {
    constructor() {}
    parseSheet<T>(
        file: File,
        createModelFunction: () => T,
        parsingFunction: (target: T, colData: { colId: string; colName: string }, parsedValue: Object) => boolean,
        sheetIndex: number
    ): Observable<ParsingResult<T>> {
        const reader = new FileReader();
        const fileReadSubject = new Subject<ParsingResult<T>>();
        reader.onload = (e: any) => {
            const book = read(e.target.result, { type: 'array' });
            const sheetName = book.SheetNames[sheetIndex];
            const sheet = book.Sheets[sheetName];
            const xlsxDataAsJSON: Object[] = utils.sheet_to_json(sheet, { header: 'A', defval: '' });
            let result: ParsingResult<T> = {
                isSuccess: null,
                parsedValues: new Array<T>(),
            };
            const namesRow = xlsxDataAsJSON[0];
            for (let rowIndex = 1; rowIndex < xlsxDataAsJSON.length; rowIndex++) {
                const row = xlsxDataAsJSON[rowIndex];
                let parseToObject: T = createModelFunction();
                for (const key in row) {
                    if (!parsingFunction(parseToObject, { colId: key, colName: namesRow[key] }, row[key])) {
                        result.isSuccess = false;
                        fileReadSubject.next(result);
                        fileReadSubject.complete();
                    }
                }
                result.parsedValues.push(parseToObject);
            }
            result.isSuccess = true;
            fileReadSubject.next(result);
            fileReadSubject.complete();
        };
        reader.readAsArrayBuffer(file);
        return fileReadSubject.asObservable();
    }
}
