/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';

export class CarRentalPreferencesFormGroup extends PersistentFormGroup<{
    frequentTravelerNumber: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<CarRentalPreferencesFormGroup>): CarRentalPreferencesFormGroup {
        return new CarRentalPreferencesFormGroup({
            frequentTravelerNumber: new PersistentFormControl(value.frequentTravelerNumber, maxLengthValidator(100)),
        });
    }
}
@Component({
    selector: 'medpace-car-rental-preferences',
    templateUrl: './car-rental-preferences.component.html',
    styleUrls: ['./car-rental-preferences.component.scss'],
})
export class CarRentalPreferencesComponent {
    @Input() formGroup: CarRentalPreferencesFormGroup;
    @Input() mode: EditableCardMode;
}
