import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { AccessGroupStateService } from '@services/state-management/access-group-state.service';
import { UserStateService } from '@services/state-management/user-state.service';
import { alreadyInArrayValidator } from '@utility/utility.validators';
import { map, takeUntil, tap } from 'rxjs';
import { MedpaceUserManagementModalComponent } from '../user-management-modal.component';

@Component({
    templateUrl: './create-user-management-modal.component.html',
    styleUrls: ['./create-user-management-modal.component.scss'],
})
export class CreateUserManagementModalComponent extends MedpaceUserManagementModalComponent {
    authenticationMethodRadioButtons: MdsRadioButton[] = [
        <MdsRadioButton>{
            label: this.loginOnlyWithMedpaceAccount,
            value: false,
            name: this.loginOnlyWithMedpaceAccount,
            id: this.loginOnlyWithMedpaceAccount,
        },
        <MdsRadioButton>{
            label: this.loginWithLocalAccount,
            value: true,
            name: this.loginWithLocalAccount,
            id: this.loginWithLocalAccount,
        },
    ];

    authenticationMethodFormGroup = this.formBuilder.group({
        [this.authenticationMethodFieldName]: new FormControl<boolean>(false),
    });

    updateSso$ = this.personalDataFormGroup.get(this.emailAddressFieldName).valueChanges.pipe(
        tap((emailValue) => {
            let emailRegex = /^[a-zA-Z0-9._%+-]+@medpace.com/;
            const formValue = this.authenticationMethodFormGroup.get(this.authenticationMethodFieldName);
            if (emailRegex.test(emailValue)) {
                formValue.setValue(true);
                formValue.setEnabled(true);
            } else {
                formValue.setValue(false);
                formValue.setEnabled(false);
            }
        })
    );

    constructor(
        public dialogRef: MatDialogRef<MedpaceUserManagementModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
        protected adminStudies: AdminStudyServices,
        protected accessGroupStateService: AccessGroupStateService,
        protected formBuilder: FormBuilder,
        protected userStateService: UserStateService
    ) {
        super(dialogRef, adminStudies, accessGroupStateService, formBuilder, userStateService);
        this.dialogTitle = 'Create User Profile';
        this.userStateService.setUserToCreate();
        this.userStateService
            .getUser()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((newUser) => {
                    this.resultUser = newUser;
                    super._modalDataSetup();
                })
            )
            .subscribe();

        this.userStateService
            .getUserAdminViewModelArray()
            .pipe(
                takeUntil(this.componentDestroyed$),
                map((userArray) => {
                    return userArray.map((user) => user.emailAddress.toLowerCase());
                })
            )
            .pipe(
                tap((usersEmailAddresses) => {
                    this.personalDataFormGroup.controls[this.emailAddressFieldName].addValidators(
                        alreadyInArrayValidator(usersEmailAddresses, 'User with this email address already exist!')
                    );
                    this.personalDataFormGroup.controls[this.emailAddressFieldName].updateValueAndValidity();
                })
            )
            .subscribe();
        this.userStateService.setUserAdminViewModelArray();
    }

    submitClick(): void {
        super.submitClick(() => {
            this.resultUser.hasLocalAuthentication =
                !this.authenticationMethodFormGroup.controls[this.authenticationMethodFieldName].getRawValue();
            this.resultUser.justification = this.data;
            this.userStateService.createUser();
        });
    }
}
