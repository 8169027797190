@if (!dataSource()) {
    LOADING
} @else {
    <mds-table [dataSource]="dataSource()" [hasCustomRowDefs]="true">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
                @if (asEditFieldFormGroup(element); as formGroup) {
                    {{ formGroup.name }}
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="currentValue">
            <mat-header-cell *matHeaderCellDef>Current Value</mat-header-cell>
            <mat-cell *matCellDef="let element">
                @if (asEditFieldFormGroup(element); as formGroup) {
                    {{ getCurrentValueText(formGroup) }}
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="requestedValue">
            <mat-header-cell *matHeaderCellDef>Requested Value</mat-header-cell>
            <mat-cell *matCellDef="let element" style="padding-top: 1rem">
                @if (asEditFieldFormGroup(element); as formGroup) {
                    @if (formGroup.displayMode === 'string') {
                        <mds-text-field
                            style="width: 100%"
                            [label]="formGroup.name"
                            [formCtrl]="formGroup.controls.requestedValue"
                            placeholder="Type here..."
                            appearance="outline"
                            floatLabel="always"
                        />
                    } @else if (formGroup.displayMode === 'date') {
                        <medpace-datepicker
                            style="width: 100%"
                            [label]="formGroup.name"
                            [formCtrl]="formGroup.controls.requestedValue"
                            appearance="outline"
                            floatLabel="always"
                        />
                    } @else if (formGroup.displayMode === 'country') {
                        <mds-autocomplete
                            style="width: 100%"
                            [closeOnPageScroll]="false"
                            [disableRipple]="false"
                            [formCtrl]="formGroup.controls.requestedValue"
                            [options]="countries$ | async"
                            appearance="outline"
                            floatLabel="always"
                            placeholder="Enter country"
                            label="Country"
                            id="passportCountry"
                            [validateInput]="true"
                        >
                        </mds-autocomplete>
                    } @else if (formGroup.displayMode === 'boolean') {
                        <mds-checkbox style="width: 100%" [formCtrl]="formGroup.controls.requestedValue" />
                    }
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accepted">
            <mat-header-cell *matHeaderCellDef>Accept Change</mat-header-cell>
            <mat-cell *matCellDef="let element">
                @if (asEditFieldFormGroup(element); as formGroup) {
                    <mds-checkbox [formCtrl]="formGroup.controls.isAccepted" />
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayCols"> </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayCols"></mat-row>
    </mds-table>
}
