import { CountryDto, CountryViewModel } from '../../models/country';

export function transformCountryViewModelToCountryDto(country: CountryViewModel): CountryDto {
    if (!!country.id && !!country.viewValue) {
        return <CountryDto>{
            id: country.id,
            name: country.viewValue,
        };
    } else return <CountryDto>{};
}

export function transformCountryDtoToCountryViewModel(country: CountryDto): CountryViewModel {
    if (!!country.id && !!country.name) {
        return <CountryViewModel>{
            id: country.id,
            value: country.name,
            viewValue: country.name,
        };
    } else return <CountryViewModel>{};
}
