import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MdsDropdownComponent } from '@medpacesoftwaredevelopment/designsystem';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'selected-custom-dropdown',
    templateUrl: 'selected-custom-dropdown.component.html',
    styleUrls: ['./selected-custom-dropdown.component.scss'],
})
export class SelectedCustomDropDownComponent implements OnInit, OnChanges {
    dropdownFormGroup: FormGroup;
    ddd: FormControl;
    searchTextboxControl = new UntypedFormControl();
    filteredOptions: Observable<any[]>;
    @Input() selectedValues = [];

    @Input() labelName: string;
    @Input() dataName: string;
    @Input() selectedValue: string;
    @Input() data: any[];
    @Input() setToDefaults: boolean;
    @Input() isPatientUrl: boolean = false;
    @Output() inputValueChange = new EventEmitter<{}>();
    @Output() applyFilterEvent = new EventEmitter();
    @ViewChild('search') searchTextBox: ElementRef<HTMLInputElement>;
    @ViewChild('allSelected') private allSelected: MatOption;
    @ViewChild('dropdown') private _dropdown: MdsDropdownComponent;

    public constructor(private formBuilder: FormBuilder) {
        this.dropdownFormGroup = this.formBuilder.group({
            dropdown: [''],
        });
    }
    ngOnInit(): void {
        this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
            startWith<string>(''),
            map((name) => this._filter(name))
        );
        if (this.selectedValues?.length > 0) {
            this.dropdownFormGroup.controls.dropdown.setValue(this.selectedValues);
            let type = this.getTypeName();
            this.inputValueChange.emit({ type, event: { value: this.selectedValues } });
            this.applyFilterEvent.emit();
        }
    }

    /**
     * Used to filter data based on search input
     */
    private _filter(name: string): String[] {
        const filterValue = name.toLowerCase();

        let filteredList = this.data
            ?.filter((option) => option.toLowerCase().indexOf(filterValue) === 0)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        return filteredList;
    }

    /**
     * Remove from selected values based on uncheck
     */
    selectionChange(event) {
        this.selectedValues = [];
        this.selectedValues.push(event.value);
        let type = this.getTypeName();

        this.inputValueChange.emit({ type, event });
    }

    private getTypeName(): string {
        if (this.dataName == undefined) {
            return this.labelName.split(' ').join('').toLowerCase();
        } else {
            return this.dataName.toLowerCase();
        }
    }

    /**
     * Emit data to the parent to store latest data
     * @param label
     */
    openedChange(e) {
        // Set search textbox value as empty while opening selectbox
        this.searchTextboxControl.patchValue('', { emitEvent: false });
        // Focus to search textbox while clicking on selectbox
        if (e == true) {
            this.searchTextBox.nativeElement.focus();
        }
    }

    /**
     * Reset all control values to default state
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes['setToDefaults']?.previousValue != undefined) {
            this._dropdown.formGrp.controls.dropdown.patchValue([], { emitEvent: false });
            this.selectedValues = [];
        }
    }
}
