<ng-template #patientFormButton>
    <div class="flex end">
        <ng-container *ngIf="isUpdate && (isSuperAdmin || isAdmin)">
            <mds-button
                (clickEvent)="changePatientStatus()"
                [id]="'ChangeStatusRequestBtn'"
                label="Change Status"
                variant="outline"
            >
            </mds-button>
        </ng-container>
        <mds-button
            (clickEvent)="buttonClick(true)"
            variant="primary"
            [label]="label"
            [id]="'createPatientBtn'"
            type="submit"
        ></mds-button>
    </div>
</ng-template>
<form
    [formGroup]="patientFormGroup"
    appScrollToInvalidControlOnSubmit
    class="o-grid"
    *ngIf="{ user: user$ | async } as vm"
>
    <div class="o-grid__row">
        <div class="o-grid__item half">
            <medpace-vertical-stepper [steps]="currentSteps"></medpace-vertical-stepper>
        </div>
        <div class="o-grid__item">
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="flex start middle">
                            <span class="required-asterisk">*</span>
                            <span> indicates a required field </span>
                        </div>
                    </div>
                    <div class="o-grid__item">
                        <ng-container *ngTemplateOutlet="patientFormButton"></ng-container>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item" id="studyInformation">
                        <medpace-input-card
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            (submitClickEvent)="buttonClick($event)"
                            [additionalContent]="site"
                            [inputObj]="study"
                            [showEditSaveButton]="false"
                            [editMode]="false"
                            [patient]="patient"
                            [parentFormGroup]="patientFormGroup"
                            title="Study Details"
                            type="study-detail"
                        >
                        </medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div *ngIf="patient" class="o-grid__item" id="patientConsent">
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [showEditSaveButton]="isUpdate"
                            [editMode]="!isUpdate"
                            [isRequired]="true"
                            [parentFormGroup]="patientFormGroup"
                            title="Consent Form"
                            type="consent-card"
                        >
                        </medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div *ngIf="patient" class="o-grid__item" id="patientIdentification">
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            (submitClickEvent)="buttonClick($event)"
                            [showEditSaveButton]="isUpdate"
                            [editMode]="!isUpdate"
                            [isPatientProfile]="true"
                            [parentFormGroup]="patientFormGroup"
                            title="Personal Information"
                            type="personal-info"
                        ></medpace-input-card>
                    </div>
                </div>

                <div class="o-grid__row">
                    <div *ngIf="patient" class="o-grid__item" id="caregiverInformation">
                        <medpace-input-card
                            [parentFormGroup]="patientFormGroup"
                            (incrementalUpdate)="submitIncrementalSave()"
                            title="Caregiver Information"
                            type="caregiver-info"
                            [showEditSaveButton]="false"
                            [editMode]="false"
                        >
                        </medpace-input-card>
                    </div>
                </div>

                <div class="o-grid__row">
                    <div *ngIf="patient && siteServices?.travel" class="o-grid__item" id="travelPreferences">
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [showEditSaveButton]="isUpdate"
                            [editMode]="!isUpdate"
                            [isUpdate]="isUpdate"
                            [isPatientProfile]="true"
                            [parentFormGroup]="patientFormGroup"
                            title="Travel Information"
                            type="travel-card"
                        ></medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div
                        *ngIf="site && paymentRequired && (siteServices?.reimbursement || siteServices?.stipend)"
                        class="o-grid__item"
                        id="paymentInformation"
                    >
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [showEditSaveButton]="isUpdate"
                            [editMode]="!isUpdate"
                            [isPatientProfile]="true"
                            [isUserAdmin]="isAdmin"
                            [siteSettings]="siteServices"
                            [parentFormGroup]="patientFormGroup"
                            title="Payment Information"
                            type="payment-card"
                        ></medpace-input-card>
                    </div>
                </div>
                <div *ngIf="patient" class="o-grid__row" id="additionalInformation">
                    <div class="o-grid__item">
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [parentFormGroup]="patientFormGroup"
                            title="Additional Information"
                            type="accessiblity-card"
                            [showEditSaveButton]="isUpdate"
                            [editMode]="!isUpdate"
                        ></medpace-input-card>
                    </div>
                </div>

                <div class="o-grid__row text-end">
                    <!-- There seems to be no final conclusion on how "Delete patient" should work yet. The current preference is to have it hidden
                        but not removed from the code base yet. More on this topic was discussed in: 
                         - https://dev.azure.com/Medpace/PCS/_workitems/edit/14077
                         - https://dev.azure.com/Medpace/PCS/_workitems/edit/15587
                    -->
                    <div *ngIf="vm.user.isSuperAdmin && isUpdate && false" class="o-grid__item u-ta-left">
                        <mds-button
                            [id]="'deleteBtn'"
                            (clickEvent)="deletePatient()"
                            variant="outline"
                            color="warn"
                            label="Delete Patient"
                            width="auto"
                        ></mds-button>
                    </div>
                    <ng-container *ngTemplateOutlet="patientFormButton"></ng-container>
                </div>
            </div>
        </div>
    </div>
</form>
