<form [formGroup]="dropdownFormGroup" class="matformfield-fullwidth" appearance="outline">
    <mds-dropdown
        [closeOnPageScroll]="false"
        appearance="outline"
        [multiple]="true"
        [label]="labelName"
        floatLabel="always"
        placeholder="Filter by {{ labelName }}"
        [options]="data"
        id="filterDropdown"
        (selectionChange)="selectionChange($event)"
        [formGrp]="dropdownFormGroup"
        formCtrlName="dropdown"
        #dropdown
    ></mds-dropdown>
</form>
