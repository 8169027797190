export interface IAccessGroupDTO {
    id: number;
    displayName?: string | null;
}

export class AccessGroupDTO implements IAccessGroupDTO {
    id!: number;
    displayName?: string | null;

    constructor(data?: IAccessGroupDTO) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
            this.displayName = _data['displayName'] !== undefined ? _data['displayName'] : <any>null;
        }
    }

    static fromJS(data: any): AccessGroupDTO {
        data = typeof data === 'object' ? data : {};
        let result = new AccessGroupDTO();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        data['displayName'] = this.displayName !== undefined ? this.displayName : <any>null;
        return data;
    }
}
