import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DownloadAttachmentModalComponent } from '@app/admin/manage/modals/download-attachment-modal/download-attachment-modal.component';
import { FileType } from '@app/enums/FileTypes';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { maxLengthValidator } from '@utility/utility.validators';
import { utc } from 'moment';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Site, SiteServices } from 'src/app/models/site';
import { AuthService } from 'src/app/services/auth/auth.service';
import { KycAuditLog } from '../../../../models/kycAuditLog';
import { Patient, PatientIdentification } from '../../../../models/patient';
import { AdminPatientRepository } from '../../../../repositories/admin/admin-patient.repository';
import { MedpaceKYCDeleteModalComponent } from './kyc-delete-modal.component';
@Component({
    selector: 'medpace-kyc-card',
    templateUrl: './kyc-card.component.html',
    styleUrls: ['./kyc-card.component.scss'],
})
export class MedpaceKycCardComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    isAdmin: boolean;

    @Input()
    patient: Patient = null;

    @Input()
    patientIdent: PatientIdentification;
    @Input() parentFormGroup: FormGroup;

    siteSettings: SiteServices;

    @Input()
    isEditing: boolean;

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();

    readyForPaymentChecked: boolean = false;
    hasFile: boolean = false;
    currentUserName: string;
    acceptableFileTypes: string[] = [FileType.PDF, FileType.JPG, FileType.JPEG, FileType.PNG];
    readyForPaymentControl: FormControl = new FormControl();
    kycVendorIdControl: FormControl<string> = new FormControl('');
    patient$: Observable<Patient>;
    site$: Observable<Site>;
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private authService: AuthService,
        private patientRepo: AdminPatientRepository,
        public dialog: MatDialog,
        private siteStateService: SiteStateService,
        private patientStateService: PatientStateService,
        private patientService: AdminPatientServices
    ) {}

    kycCardFormGroup = new FormGroup({
        readyForPaymentControl: this.readyForPaymentControl,
        kycVendorIdControl: this.kycVendorIdControl,
    });

    ngOnChanges(changes: SimpleChanges): void {
        if (this.patientIdent.KYCDocumentInfoId) {
            this.hasFile = true;
        } else {
            this.hasFile = false;
        }
        if (this.patient?.readyForPayment == false) {
            this.patientIdent.KYCVendorId = null;
        }
    }

    ngOnInit(): void {
        //Add formGroup to ParentFormGroup
        this.parentFormGroup.addControl('kycCardFormGroup', this.kycCardFormGroup);
        this.currentUserName = this.authService.claims['name'];
        this.kycCardFormGroup.controls.readyForPaymentControl.setValue(this.patient?.readyForPayment);
        this.kycCardFormGroup.controls.kycVendorIdControl.setValue(this.patientIdent?.KYCVendorId);
        this.site$ = this.siteStateService.getSite();
        this.site$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((site) => {
                    this.siteSettings = site?.services;
                })
            )
            .subscribe();

        this.patient$ = this.patientStateService.getSummaryPatientData();
        this.patient$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((patient) => {
                    if (patient) this.patient = patient;
                })
            )
            .subscribe();

        this.kycCardFormGroup.controls.readyForPaymentControl.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((val: boolean) => {
                if (val) {
                    this.kycCardFormGroup.controls.kycVendorIdControl.setValidators([
                        Validators.required,
                        maxLengthValidator(50),
                    ]);
                } else {
                    this.kycCardFormGroup.controls.kycVendorIdControl.clearValidators();
                }
                this.kycCardFormGroup.controls.kycVendorIdControl.updateValueAndValidity();
            });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    updateDocumentation(event: any, target: any) {
        this.hasFile = true;
        this.inputChangeEvent.emit({ target: target.id, value: event });
    }

    deleteFile(): void {
        const dialogRef = this.dialog.open(MedpaceKYCDeleteModalComponent, {
            data: { patientId: this.patient.id, kycDocumentId: this.patientIdent.KYCDocumentInfoId },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.hasFile = false;
                this.patientIdent.KYCDocumentInfoId = null;
                this.patientIdent.KYCDocumentName = null;
                this.inputChangeEvent.emit({ target: 'KYCDocument', value: null });
                this.inputChangeEvent.emit({ target: 'KYCDocumentName', value: null });
            }
        });
    }

    emitTextChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };

    emitChange: (event: any) => void = (event: any) => {
        if (event.source.id == 'readyForPayment') {
            if (event.checked) {
                this.patient.paymentApprover = this.currentUserName;
                this.patient.paymentApprovalDate = utc();
            } else {
                this.patient.paymentApprover = null;
                this.patient.paymentApprovalDate = null;
            }
        }
        this.inputChangeEvent.emit({
            target: event.source.id,
            value: event.checked,
        });
    };

    onDocumentDownloadClick() {
        this.showAttachmentDownloadModal(this.patient.id, this.patientIdent.KYCDocumentInfoId);
    }

    private showAttachmentDownloadModal(patientId: number, kycDocumentId: number) {
        this.patientService
            .getPatientKycDocumentUri(patientId, kycDocumentId)
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((fileUrl) => {
                    this.openDownloadModal(fileUrl.toString());

                    let log: KycAuditLog = new KycAuditLog();
                    log.patientId = this.patient.id;
                    log.fileName = this.patientIdent.KYCDocumentName;

                    // TODO: Why is kyc download log creation triggered from client-side? This needs to be changed
                    this.patientRepo.createKYCDownloadLog(log).subscribe((result) => {});
                })
            )
            .subscribe();
    }

    private openDownloadModal(dataSourceUrl: string): void {
        const fileName = `${this.patientIdent.firstName}-${this.patientIdent.lastName}-kyc`;
        this.dialog.open(DownloadAttachmentModalComponent, {
            data: { dataSourceUrl, attachmentName: fileName },
            autoFocus: false,
        });
    }
}
