import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-info-caregiver-modal',
    templateUrl: './delete-info-caregiver-modal.component.html',
    styleUrls: ['./delete-info-caregiver-modal.component.scss'],
})
export class MedpaceDeleteInfoCaregiverModalComponent {
    constructor(public dialogRef: MatDialogRef<MedpaceDeleteInfoCaregiverModalComponent>) {}

    closeDialog(): void {
        this.dialogRef.close();
    }
}
