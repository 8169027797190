import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { ClinTrakStudySiteViewModel, CountryRegionCtsmViewModel } from '../../models/clintrak';
import { ClinTrakDataService } from '../../services/clintrak/clintrak-data.service';

@Injectable({
    providedIn: 'root',
})
export class ClinTrakDataStateService {
    constructor(private clinTrakDataService: ClinTrakDataService) {}
    private readonly countries = new BehaviorSubject<CountryRegionCtsmViewModel[]>([]);
    private readonly siteData = new BehaviorSubject<ClinTrakStudySiteViewModel>(null);
    public setCountries(): void {
        this.clinTrakDataService
            .getAllCountries()
            .pipe(take(1))
            .subscribe((res) => {
                return this.countries.next(res);
            });
    }

    public getCountries(): Observable<CountryRegionCtsmViewModel[]> {
        let currentValue = this.countries.getValue();
        if (currentValue.length == 0) this.setCountries();
        return this.countries.asObservable();
    }

    public setStudySiteData(clinTrakSite: ClinTrakStudySiteViewModel): void {
        return this.siteData.next(clinTrakSite);
    }

    public getStudySiteData(): Observable<ClinTrakStudySiteViewModel> {
        return this.siteData.asObservable();
    }
}
