<mds-dialog [dialogTitle]="'Upload Reimbursement Rates from Spreadsheet'" *ngIf="{ value: vm$ | async } as vm">
    <mds-dialog-content>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div *cdkDragePlaceholder class="example-custom-placeholder"></div>
                    <medpace-logo-upload
                        (emitStuff)="getSpreadsheet($event)"
                        [forParsing]="true"
                        [isSpreadsheetUpload]="true"
                        [acceptableTypes]="acceptableFileTypes"
                        uploadButtonTitle="Upload Spreadsheet"
                        data-cy="fileDropzone"
                    >
                    </medpace-logo-upload>
                </div>
            </div>
            <div *ngIf="vm.value !== null">
                <div class="o-grid__row" *ngIf="vm.value.parsingResult !== null">
                    <div class="o-grid__item">
                        <div *ngIf="vm.value.parsingResult.isSuccess === true" style="color: green">
                            Correct file schema
                        </div>
                        <div *ngIf="vm.value.parsingResult.isSuccess === false" style="color: red">
                            Incorrect file schema
                        </div>
                    </div>
                </div>

                <div *ngIf="vm.value.dataErrors.length !== 0">
                    <mds-expansion-panel
                        class="expansion-panel-error"
                        [panelTitle]="'Errors (' + vm.value.dataErrors.length + ')'"
                        [expanded]="vm.value.dataErrors.length <= 3"
                        ><div class="o-grid__row" *ngFor="let error of vm.value.dataErrors">
                            <div class="o-grid__item">
                                <p>{{ error }}</p>
                            </div>
                        </div>
                    </mds-expansion-panel>
                </div>

                <div *ngIf="vm.value.canBeUploaded === true">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <p>
                                You are about to add {{ vm.value.rates.length }} record(s) to your reimbursement rates
                                table
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button
            [id]="'submit-btn'"
            [disabled]="!(vm.value !== null && vm.value.canBeUploaded === true)"
            mat-button
            variant="primary"
            label="Submit"
            (clickEvent)="onSubmitClicked()"
            data-cy="submitButton"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            mat-button
            mat-dialog-close
            variant="outline"
            label="Cancel"
            (clickEvent)="onCancelClicked()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
