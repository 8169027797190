import { Injectable } from '@angular/core';
import { utc } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ErrorDetails, ErrorObject } from '../../models/error-details';
import { ErrorReportRepository } from '../../repositories/error/error-report.repository';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    private readonly errorDetails: BehaviorSubject<ErrorDetails[]> = new BehaviorSubject<ErrorDetails[]>([]);

    constructor(private errorRepository: ErrorReportRepository) {}

    getErrors(): ErrorDetails[] {
        return this.errorDetails.getValue();
    }

    addError(errorMessage: string, callStack: string) {
        let error: ErrorDetails = {
            createDatetime: utc(Date.now()),
            errorTitle: errorMessage,
            errorCallStack: callStack,
            url: window.location.href,
        };

        this.errorDetails.next([...this.errorDetails.getValue(), error]);
    }

    clearErrorStateService() {
        this.errorDetails.next([]);
    }

    reportBug(error: ErrorObject) {
        return this.errorRepository.sendError(error);
    }
}
