import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessGroup } from '../../models/accessGroup';
import { SiteService } from 'src/app/services/site/site.service';

@Injectable()
export class GroupRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    // Requires user to be authenticated
    getGroupsForCurrentUser: () => Observable<AccessGroup[]> = () => {
        return this.client.get<AccessGroup[]>(`${this.siteService.getSiteBaseUrl()}/api/users/me/groups`);
    };
}
