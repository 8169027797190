import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { Announcement, AnnouncementStatus } from '@models/announcement';
import { Observable, map, of } from 'rxjs';
export interface AnnouncementTableModel {
    title: string;
    description: string;
    expireTimestamp: string;
    status: string;
    author: string;
    announcement: Announcement;
}
@Component({
    selector: 'medpace-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsComponent {
    private datePipe = inject(dateFormatPipe);
    protected announcementTableModels$: Observable<AnnouncementTableModel[]> = of([]);
    @Input() public set announcements(value: Observable<Announcement[]>) {
        this.announcementTableModels$ = value?.pipe(
            map((annoucementArray) => {
                if (annoucementArray)
                    return annoucementArray.map(
                        (a) =>
                            <AnnouncementTableModel>{
                                ...a,
                                expireTimestamp: this.datePipe.transform(a.expireTimestamp.toDate())?.toUpperCase(),
                                status: AnnouncementStatus[a.status],
                                title: a.title,
                                description: a.description,
                                author: a.author ? a.author : 'Unknown',
                                announcement: a,
                            }
                    );
                return [];
            })
        );
    }

    protected columnMap: { name: keyof Announcement; header: string }[] = [
        { name: 'title', header: 'Title' },
        { name: 'description', header: 'Description' },
        { name: 'expireTimestamp', header: 'Expires' },
        { name: 'status', header: 'Status' },
        { name: 'author', header: 'Author' },
    ];
    protected columns = this.columnMap.map((col) => col.name);
    @Output() public editAnnouncementEvent = new EventEmitter<Announcement>();
    @Output() public createAnnouncementEvent = new EventEmitter<void>();
    rowClick(announcementTableModel: AnnouncementTableModel) {
        this.editAnnouncementEvent.emit(announcementTableModel.announcement);
    }
    createAnnouncement() {
        this.createAnnouncementEvent.emit();
    }
}
