import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeENUS from '@angular/common/locales/en';
import localeDE from '@angular/common/locales/de';
import localeDECH from '@angular/common/locales/de-CH';
import localeENAU from '@angular/common/locales/en-AU';
import localeENCA from '@angular/common/locales/en-CA';
import localeENGB from '@angular/common/locales/en-GB';
import localeENIE from '@angular/common/locales/en-IE';
import localeENIN from '@angular/common/locales/en-IN';
import localeENSG from '@angular/common/locales/en-SG';
import localeENZA from '@angular/common/locales/en-ZA';

registerLocaleData(localePl);
registerLocaleData(localeENGB);
registerLocaleData(localeENUS);
registerLocaleData(localeDE);
registerLocaleData(localeDECH);
registerLocaleData(localeENAU);
registerLocaleData(localeENCA);
registerLocaleData(localeENIE);
registerLocaleData(localeENIN);
registerLocaleData(localeENSG);
registerLocaleData(localeENZA);

@NgModule({})
export class LocalizationRegisterModule {}
