import { Injectable } from '@angular/core';
import { Study } from '@models/study';
import { User } from '@models/user';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { Observable, ReplaySubject, forkJoin, sample, take } from 'rxjs';

@Injectable()
export class StudyStateService {
    constructor(private studyServices: AdminStudyServices) {}

    private static _staticStudy: Study;
    public static get staticStudy() {
        return StudyStateService._staticStudy;
    }

    private readonly study = new ReplaySubject<Study>();
    public readonly study$ = this.study.asObservable();

    private readonly users = new ReplaySubject<User[]>();
    public readonly users$ = this.users.asObservable();

    public setStudy(id: number): void {
        let visits = this.studyServices.getStudyVisitTypes(id);
        let expenseTypes = this.studyServices.getStudyExpenseTypes(id);
        let regions = this.studyServices.getStudyRegions(id);
        let countries = this.studyServices.getStudyCountries(id);
        let travelOptions = this.studyServices.getStudyTravelOptions(id);
        let sites = this.studyServices.getStudySites(id);
        let study = this.studyServices.getStudy(id);

        forkJoin([study, visits, expenseTypes, regions, sites, travelOptions, countries])
            .pipe(take(1))
            .subscribe(([study, visitTypes, expenseTypes, studyRegions, sites, studyTravelOptions, countries]) => {
                let studyResult = study;
                studyResult.studyInfo.visitTypes = visitTypes;
                studyResult.studyInfo.studyRegions = studyRegions;
                studyResult.studyInfo.studyCountries = countries;
                studyResult.studyInfo.expenseTypes = expenseTypes;
                studyResult.sites = sites;
                studyResult.studyInfo.studyTravelOptions = studyTravelOptions;

                StudyStateService._staticStudy = studyResult;
                return this.study.next(studyResult);
            });
    }

    public getStudy(): Observable<Study> {
        return this.study$.pipe(sample(this.study));
    }

    public setNewStudy(newStudy: Study): void {
        StudyStateService._staticStudy = newStudy;
        this.study.next(newStudy);
    }

    public clearStore() {
        StudyStateService._staticStudy = null;
        this.study.next(null);
    }

    public setUsers(id: number): void {
        this.studyServices
            .getStudyUsers(id)
            .pipe(take(1))
            .subscribe((res) => {
                res.forEach((u) => {
                    u['displayName'] = `${u.firstName} ${u.lastName}`;
                });
                return this.users.next(res);
            });
    }

    public getUsers(): Observable<User[]> {
        return this.users$;
    }

    public updateStudy(newStudyData: Study) {
        let mergedStudy = { ...StudyStateService._staticStudy, ...newStudyData };
        this.study.next(mergedStudy);
    }
}
