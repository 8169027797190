/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';
export class LodgingPreferencesFormGroup extends PersistentFormGroup<{
    hotelBrandPreference: FormControl<string>;
    roomPreference: FormControl<string>;
    specialNeeds: FormControl<string>;
    allergies: FormControl<string>;
}> {
    public static create(value: FormGroupValueOf<LodgingPreferencesFormGroup>): LodgingPreferencesFormGroup {
        return new LodgingPreferencesFormGroup({
            hotelBrandPreference: new FormControl(value.hotelBrandPreference, maxLengthValidator(50)),
            roomPreference: new FormControl(value.roomPreference, maxLengthValidator(500)),
            specialNeeds: new FormControl(value.specialNeeds, maxLengthValidator(500)),
            allergies: new FormControl(value.allergies, maxLengthValidator(1000)),
        });
    }
}
@Component({
    selector: 'medpace-lodging-preferences',
    templateUrl: './lodging-preferences.component.html',
    styleUrls: ['./lodging-preferences.component.scss'],
})
export class LodgingPreferencesComponent {
    @Input() formGroup: LodgingPreferencesFormGroup;
    @Input() mode: EditableCardMode;
}
