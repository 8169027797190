<div>
    <div class="o-grid content">
        <div class="o-grid__row">
            <h5 class="header">Are you sure you want to delete this request?</h5>
        </div>
        <div class="o-grid__row buttons">
            <mds-button [id]="'confirm-btn'" label="Yes" variant="primary" (clickEvent)="deleteConfirm()"></mds-button>
            <mds-button [id]="'cancel-btn'" label="Cancel" variant="outline" (clickEvent)="closeDialog()"></mds-button>
        </div>
    </div>
</div>
