import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'selected-custom-datepicker',
    templateUrl: 'selected-custom-datepicker.component.html',
})
export class SelectedCustomDatePickerComponent implements OnInit, OnChanges {
    @Input() labelName: string;
    @Input() setToDefaults: boolean;
    @Input() selectedValues = [];
    @Output() inputValueChange = new EventEmitter<{}>();
    @Output() applyFilterEvent = new EventEmitter();

    datePickerFormGroup: FormGroup;

    public constructor(private formBuilder: FormBuilder) {
        this.datePickerFormGroup = this.formBuilder.group({
            start: [''],
            end: [''],
        });
    }

    ngOnInit() {
        const type = this.labelName.split(' ').join('').toLowerCase();

        this.datePickerFormGroup.valueChanges.subscribe((res) => {
            if (res.start && res.end) {
                let range = [moment(res.start).utc(true).toISOString(), moment(res.end).utc(true).toISOString()];
                this.inputValueChange.emit({ type, range });
            } else if (!res.start && !res.end) {
                this.inputValueChange.emit({ type });
            }
        });

        this.setValues(type);
    }

    setValues(type: string) {
        if (this.selectedValues?.length > 0) {
            this.datePickerFormGroup.controls.start.patchValue(new Date(this.selectedValues[0]), {
                emitEvent: false,
            });
            this.datePickerFormGroup.controls.end.patchValue(new Date(this.selectedValues[1]), {
                emitEvent: false,
            });

            let range = [
                moment(this.selectedValues[0]).utc(true).toISOString(),
                moment(this.selectedValues[1]).utc(true).toISOString(),
            ];

            this.inputValueChange.emit({ type, range });
            this.applyFilterEvent.emit();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['setToDefaults']?.previousValue != undefined) {
            this.datePickerFormGroup.controls.start.patchValue('', { emitEvent: false });
            this.datePickerFormGroup.controls.end.patchValue('', { emitEvent: false });
        }
    }
}
