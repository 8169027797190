import { Injectable } from '@angular/core';
import { TravelOption } from '@models/travelOption';
import { FormDataService } from '@services/form-data/form-data.service';
import { Observable, ReplaySubject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TravelOptionStateService {
    constructor(private formService: FormDataService) {}
    private readonly travelOptions = new ReplaySubject<TravelOption[]>();
    public init(): void {
        this.formService
            .getTravelOptions()
            .pipe(take(1))
            .subscribe((res) => {
                return this.travelOptions.next(res);
            });
    }

    public getTravelOptions(): Observable<TravelOption[]> {
        return this.travelOptions.asObservable();
    }
}
