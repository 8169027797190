import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { EnvConfigurationService } from '@services/env/env-configuration.service';
import { ErrorService } from '@services/error-handling/error-service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UserService } from '@services/user/user.service';
import { Subject, takeUntil, tap } from 'rxjs';

interface HttpErrorResponse {
    // `ExceptionMessage` is part of our custom contract for 500 Errors
    ExceptionMessage: string;

    // `ExceptionStackTrace` is part of our custom contract for 500 Errors
    ExceptionStackTrace: string;

    // `title` comes from ASP.NET Core ControllerBase.Problem, and is one of the fields auto-filled by ASP.NET Core.
    // Example: for `return BadRequest()` the value is `Bad Request`
    title: string;

    // `status` comes from ASP.NET Core ControllerBase.Problem
    // Example: for `return BadRequest()` the value is 400
    status: number;
}

export interface HttpError {
    message: string;
    status: number;
    url: string;
    error?: HttpErrorResponse;
    response: string;
}

@Injectable({ providedIn: 'root' })
export class ErrorDisplayService extends ErrorHandler implements OnDestroy {
    protected componentDestroyed$: Subject<boolean> = new Subject();
    private hideErrorSnackbarForCurrentUser: boolean = true;

    constructor(
        private snackbarService: SnackbarService,
        private envConfigurationService: EnvConfigurationService,
        private userService: UserService,
        private errorStateService: ErrorService
    ) {
        super();
    }

    handleError(error: Error | HttpError): void {
        // we decided to ignore that error because it doesn't seem actionable, and is confusing when it's displayed in Cypress logs or console
        const isNG0100Error = (error as any)?.code === -100;
        if (!isNG0100Error) {
            // Call the default ErrorHandler which writes to console
            super.handleError(error);
        }

        //add error to state service
        this.errorStateService.addError(this.prepareMessage(error), this.prepareCallStack(error));

        // Additionally, display error in a snackbar to get some attention from a developer
        if (!this.envConfigurationService.configuration.production) {
            this.userService
                .getUser()
                .pipe(
                    takeUntil(this.componentDestroyed$),
                    tap((newUser) => {
                        // Don't display in the context of cypress tests, because the snack bar sometimes overlays over other controls and breaks the otherwise valid test.
                        // Heuristic approach, but hopefully good enough for a feature that just helps debug in dev/test environments
                        this.hideErrorSnackbarForCurrentUser =
                            newUser?.name?.toLowerCase().includes('cypress') ?? false;
                    })
                )
                .subscribe();

            if (this.hideErrorSnackbarForCurrentUser) return;

            if (typeof error === 'object' && 'code' in error && error.code == -100) {
                // Ignore NG0100: ExpressionChangedAfterItHasBeenCheckedError, because it brings no value - it's hard to diagnose, non critical, and we just learned to ignore it,
                // so the error message is just an annoyance
                return;
            }

            this.snackbarService.openDevelopmentErrorSnackbar(`${this.prepareMessage(error)}`);
        }
    }

    // Extracts error messages from the ASP.NET Core validation response model, and transforms to a developer-readable format
    private TryExtractValidationMessages(error: HttpError): string | null {
        const extractedMessages: string[] = [];

        // I'm not sure why, but sometimes Errors for failed 400 request have response as object in `error` property, but sometimes only in `response` as JSON-string
        const objectWithValidationErrorsInAspNetFormat =
            error.error ?? (error.response !== undefined ? JSON.parse(error.response) : {});

        for (let key in objectWithValidationErrorsInAspNetFormat) {
            if (!Array.isArray(objectWithValidationErrorsInAspNetFormat[key])) return null;
            extractedMessages.push(...objectWithValidationErrorsInAspNetFormat[key]);
        }

        const joinedMessages = extractedMessages.join(', ');
        return joinedMessages;
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    private prepareMessage(error: Error | HttpError): string {
        let errorMessage = '';

        if (typeof error === 'string') {
            errorMessage = `[DEBUG] Unhandled error: ${error}`;
        } else if (typeof error === 'object' && 'status' in error) {
            const httpError = error as HttpError;

            let message =
                // best message in case of unhandled exceptions in backend:
                httpError.error?.ExceptionMessage ??
                // best in case of `return BadRequest("message as string") in backend`:
                (typeof httpError.error === 'string' ? httpError.error : null) ??
                // best in case of ASP.NET Core built-in validation with DataAnnotations like [Required]`:
                this.TryExtractValidationMessages(httpError) ??
                // best in case of empty `return BadRequest() in backend`:
                httpError.error?.title ??
                // in other cases, `message` is usually not empty, although this would be typically be quite generic message
                httpError.message;

            errorMessage = `[DEBUG] Unexpected ${httpError.status}: ${message}. Url: ${httpError.url}`;
        } else {
            errorMessage = `[DEBUG] Unhandled error: ${error.message || 'undefined error'}`;
        }

        return errorMessage;
    }

    private prepareCallStack(error: Error | HttpError) {
        let callStack = '';
        if (typeof error === 'object' && !('status' in error)) {
            callStack = error.stack;
        }

        return callStack;
    }
}
