import { Injectable } from '@angular/core';
import { TravelRequest } from '@models/request';
import { AdminRequestRepository } from '@repositories/admin/admin-request.repository';
import { Observable } from 'rxjs';
import { StateService } from './state.service';

@Injectable({
    providedIn: 'root',
})
export class TravelRequestStateService extends StateService<TravelRequest> {
    constructor(private requestRepository: AdminRequestRepository) {
        super();
    }
    public override get(id: number): Observable<TravelRequest> {
        return this.requestRepository.getTravelRequest(id);
    }
}
