import { Injectable } from '@angular/core';
import { PatientStatus } from '../../models/patientStatus';

@Injectable({
    providedIn: 'root',
})
export class PatientStatusService {
    private AllPatientStatuses: PatientStatus[] = [
        { value: 1, viewValue: 'Enrolled' },
        { value: 2, viewValue: 'Removed' },
        { value: 3, viewValue: 'In Screening' },
        { value: 4, viewValue: 'Screen Failed' },
        { value: 5, viewValue: 'Dropped' },
        { value: 6, viewValue: 'Completed' },
        { value: 7, viewValue: 'Follow-Up' },
    ];

    constructor() {}

    public getStatuses(): PatientStatus[] {
        return this.AllPatientStatuses;
    }

    public getStatusesAvailabletoChange(): PatientStatus[] {
        return this.AllPatientStatuses.filter((x) => x.value !== 2);
    }

    public getStatusById(id: number): string {
        return this.AllPatientStatuses.find((x) => x.value === id)?.viewValue;
    }
}

/** Also, an enum representation to avoid using magic numbers in code */
export enum AllPatientStatusesEnum {
    Enrolled = 1,
    Removed = 2,
    InScreening = 3,
    ScreenFailed = 4,
    Dropped = 5,
    Completed = 6,
    FollowUp = 7,
}
