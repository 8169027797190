/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL } from '@app/API_BASE_URL';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { AccessGroupDTO } from '../../models/accessGroupDTO';
import { blobToText, throwException } from '../../utility/generated-nswag';

export interface IAccessGroupClient {
    get(): Observable<AccessGroupDTO[]>;
}

@Injectable()
export class AccessGroupClient implements IAccessGroupClient {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        if (!baseUrl)
            throw new Error(
                `Could not retrieve the URL of a backend API from the configuration. Check the value used to initialize API_BASE_URL. The retrieved value was: ${baseUrl}.`
            );
        this.baseUrl = baseUrl;
    }

    get(): Observable<AccessGroupDTO[]> {
        let url_ = this.baseUrl + '/api/accessgroups';
        url_ = url_.replace(/[?&]$/, '');

        let options_: any = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('get', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGet(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGet(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<AccessGroupDTO[]>;
                        }
                    } else return _observableThrow(response_) as any as Observable<AccessGroupDTO[]>;
                })
            );
    }

    protected processGet(response: HttpResponseBase): Observable<AccessGroupDTO[]> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    if (Array.isArray(resultData200)) {
                        result200 = [] as any;
                        for (let item of resultData200) result200!.push(AccessGroupDTO.fromJS(item));
                    } else {
                        result200 = <any>null;
                    }
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }
}
