import { PersistentFormGroup } from '@utility/persistent-forms';
import {
    lessThanTodayDateValidator,
    manualValidator,
    maxLengthValidator,
    moreThanTodayDateValidator,
    ValidatorHandle,
} from '@utility/utility.validators';
import { Moment } from 'moment';
import { EditFieldFormGroup } from './edit-field.formgroup';

export class PassportEditFormGroup extends PersistentFormGroup<{
    passportCountry: EditFieldFormGroup<string | null>;
    passportExpiration: EditFieldFormGroup<Moment | null>;
    passportIssue: EditFieldFormGroup<Moment | null>;
    passportNum: EditFieldFormGroup<string | null>;
}> {
    constructor() {
        const passportNumberValidatorHandle = new ValidatorHandle();
        const passportCountryValidatorHandle = new ValidatorHandle();
        const passportIssueValidatorHandle = new ValidatorHandle();
        const passportExpirationValidatorHandle = new ValidatorHandle();
        super({
            passportNum: new EditFieldFormGroup<string | null>('Passport Number', null, 'string', [
                maxLengthValidator(20),
                manualValidator(passportNumberValidatorHandle, { requiredAllValues: 'All fields are required!' }),
            ]),
            passportCountry: new EditFieldFormGroup<string | null>('Passport Country', null, 'country', [
                maxLengthValidator(65),
                manualValidator(passportCountryValidatorHandle, { requiredAllValues: 'All fields are required!' }),
            ]),
            passportIssue: new EditFieldFormGroup<Moment | null>('Passport Issue Date', null, 'date', [
                lessThanTodayDateValidator,
                manualValidator(passportIssueValidatorHandle, { requiredAllValues: 'All fields are required!' }),
            ]),
            passportExpiration: new EditFieldFormGroup<Moment | null>('Passport Expiration Date', null, 'date', [
                moreThanTodayDateValidator,
                manualValidator(passportExpirationValidatorHandle, { requiredAllValues: 'All fields are required!' }),
            ]),
        });

        passportNumberValidatorHandle.formControl = this.controls.passportNum.controls.requestedValue;
        passportCountryValidatorHandle.formControl = this.controls.passportCountry.controls.requestedValue;
        passportIssueValidatorHandle.formControl = this.controls.passportIssue.controls.requestedValue;
        passportExpirationValidatorHandle.formControl = this.controls.passportExpiration.controls.requestedValue;

        this.addValidators((control) => {
            const typedControl = control as PassportEditFormGroup;
            const value = typedControl.getRawValue();

            if (!typedControl.value) return null;

            const passportNumber = !value.passportNum.isAccepted
                ? typedControl.controls.passportNum.currentValue
                : value.passportNum.requestedValue; // || typedControl.controls.passportNum.currentValue;

            const passportCountry = !value.passportCountry.isAccepted
                ? typedControl.controls.passportCountry.currentValue
                : value.passportCountry.requestedValue; // || typedControl.controls.passportCountry.currentValue;

            const passportIssue = !value.passportIssue.isAccepted
                ? typedControl.controls.passportIssue.currentValue
                : value.passportIssue.requestedValue; // || typedControl.controls.passportIssue.currentValue;

            const passportExpiration = !value.passportExpiration.isAccepted
                ? typedControl.controls.passportExpiration.currentValue
                : value.passportExpiration.requestedValue; // || typedControl.controls.passportExpiration.currentValue;

            const allEmpty = !passportNumber && !passportCountry && !passportIssue && !passportExpiration;
            const atLeastOneEmpty = !passportNumber || !passportCountry || !passportIssue || !passportExpiration;

            passportNumberValidatorHandle.setValid(!!passportNumber || allEmpty);
            passportCountryValidatorHandle.setValid(!!passportCountry || allEmpty);
            passportIssueValidatorHandle.setValid(!!passportIssue || allEmpty);
            passportExpirationValidatorHandle.setValid(!!passportExpiration || allEmpty);
            if (!allEmpty && atLeastOneEmpty) {
                return {
                    requiredAllValues: true,
                };
            }

            return null;
        });
    }

    disableIfUnchanged() {
        const controls = Object.values(this.controls);
        controls.forEach((control) => {
            control.disableIfUnchanged();
        });

        if (!controls.some((control) => control.enabled)) this.disable({ persistent: true });
        else this.enable({ breakPersistence: true });
    }
}
