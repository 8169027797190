import { Component } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';

@Component({
    selector: 'medpace-logout',
    templateUrl: './logout.component.html',
    styleUrls: [],
})
export class MedpaceLogoutComponent {
    constructor(private auth: AuthService) {
        this.logout();
    }

    logout() {
        this.auth.logout();
    }
}
