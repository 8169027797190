import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordModalComponent } from '@app/admin/manage/modals/change-password-modal/change-password-modal.component';

@Component({
    selector: 'user-personal-info-card',
    templateUrl: './user-personal-info-card.component.html',
    styleUrls: ['./user-personal-info-card.component.scss'],
})
export class UserPersonalInfoCardComponent {
    @Input()
    firstName: string;
    @Input()
    middleName: string;
    @Input()
    lastName: string;
    @Input()
    phoneNumber: string;
    @Input()
    emailAddress: string;

    constructor(private dialog: MatDialog) {}

    changePassword(): void {
        this.dialog.open(ChangePasswordModalComponent, {
            autoFocus: false,
            width: '500px',
            disableClose: true,
        });
    }
}
