import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Site } from '@models/site';
import { CRCSiteRepository } from '@repositories/crc/crc-sites.repository';
import { transformToSite, transformToSiteArray } from '../transforms/site-transform';

@Injectable()
export class CRCSiteServices {
    constructor(private repo: CRCSiteRepository) {}

    getUserSite: (email: string) => Observable<Site> = (email: string) => {
        return this.repo.getUserSite(email).pipe(
            map((value) => {
                return value ? transformToSite(value[0]) : <Site>{};
            })
        );
    };

    getUserSiteByStudy: (studyId: number) => Observable<Site[]> = (studyId: number) => {
        return this.repo.getUserSiteByStudy(studyId).pipe(
            map((value) => {
                return value ? transformToSiteArray(value) : <Site[]>{};
            })
        );
    };
}
