import { Injectable } from '@angular/core';
import { Region } from '@models/region';
import { FormDataService } from '@services/form-data/form-data.service';
import { Observable, ReplaySubject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RegionStateService {
    constructor(private formService: FormDataService) {}
    private readonly regions = new ReplaySubject<Region[]>();
    public init(): void {
        this.formService
            .getRegions()
            .pipe(take(1))
            .subscribe((res) => {
                return this.regions.next(res);
            });
    }

    public getRegions(): Observable<Region[]> {
        return this.regions.asObservable();
    }
}
