<div class="o-grid">
    <ng-container *ngIf="mode === 'edit'">
        <medpace-alert
            *ngFor="let error of formGroup.errors | keyvalue"
            [additionalClass]="'danger'"
            [content]="error.value"
        ></medpace-alert>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-autocomplete
                    [formCtrl]="formGroup.controls.pickupAddress"
                    appearance="outline"
                    inputType="text"
                    label="Pick-up airport or city"
                    [placeholder]="'Type here...'"
                    [floatLabel]="'always'"
                    [options]="autocompleteOptions.pickupAddress"
                    id="pickupAddress"
                ></mds-autocomplete>
            </div>
            <div class="o-grid__item">
                <mds-autocomplete
                    [formCtrl]="formGroup.controls.pickupCountry"
                    [options]="filteredCountryOptions$ | async"
                    appearance="outline"
                    floatLabel="always"
                    placeholder="Enter country"
                    label="Country"
                    id="pickupCountry"
                    [validateInput]="true"
                    [closeOnPageScroll]="false"
                ></mds-autocomplete>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-datepicker
                    [formCtrl]="formGroup.controls.pickupDate"
                    [appearance]="'outline'"
                    [label]="'Pick-up date'"
                    [required]="true"
                    id="pickupDate"
                ></medpace-datepicker>
            </div>
            <div class="o-grid__item">
                <mds-dropdown
                    floatLabel="always"
                    [formCtrl]="formGroup.controls.pickupTime"
                    appearance="outline"
                    label="Pick-up time"
                    placeholder="Select time"
                    [options]="timeOptions"
                    id="pickupTime"
                    [closeOnPageScroll]="false"
                ></mds-dropdown>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-datepicker
                    [formCtrl]="formGroup.controls.dropOffDate"
                    appearance="outline"
                    label="Drop-off date"
                    [required]="true"
                    id="dropOffDate"
                ></medpace-datepicker>
            </div>
            <div class="o-grid__item">
                <mds-dropdown
                    floatLabel="always"
                    [formCtrl]="formGroup.controls.dropOffTime"
                    appearance="outline"
                    label="Drop-off time"
                    placeholder="Select time"
                    [options]="timeOptions"
                    id="dropOffTime"
                    [closeOnPageScroll]="false"
                ></mds-dropdown>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-textarea-autocomplete
                    [formCtrl]="formGroup.controls.specialRequests"
                    [options]="autocompleteOptions.specialRequests"
                    [cdkAutosizeMaxRows]="20"
                    [cdkAutosizeMinRows]="5"
                    label="Special Requests"
                    placeholder="Special requests - e.g. ADA accessible"
                    appearance="outline"
                    floatLabel="always"
                    [autoSize]="true"
                    id="specialRequests"
                ></medpace-textarea-autocomplete>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === 'view'">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Pickup address</h5>
                    <span id="pickupAddress">{{ formGroup.controls.pickupAddress.value }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Country</h5>
                    <span id="pickupCountry">{{ formGroup.controls.pickupCountry.value }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Pick-up date</h5>
                    <span id="pickupDate">{{
                        formGroup.controls.pickupDate.value?.toDate() | dateFormat | uppercase
                    }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Pick-up time</h5>
                    <span id="pickupTime">{{
                        getDateFromMinutesSinceMidnight(formGroup.controls.pickupTime.value)?.toDate()
                            | dateFormat: 'shortTime'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Dropoff date</h5>
                    <span id="dropOffDate">{{
                        formGroup.controls.dropOffDate.value?.toDate() | dateFormat | uppercase
                    }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Dropoff time</h5>
                    <span id="dropOffTime">{{
                        getDateFromMinutesSinceMidnight(formGroup.controls.dropOffTime.value)?.toDate()
                            | dateFormat: 'shortTime'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special requests</h5>
                    <span id="specialRequests">{{ formGroup.controls.specialRequests.value }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
