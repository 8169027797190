<div>
    <div class="o-grid content">
        <div class="o-grid__row row-p">
            <div class="o-grid__item">
                <h4 class="header">Are you sure you want to remove this caregiver?</h4>
            </div>
        </div>
        <div class="o-grid__row buttons">
            <mds-button [id]="'confirm-btn'" label="Yes" variant="primary" (clickEvent)="deleteConfirm()"></mds-button>
            <mds-button [id]="'cancel-btn'" label="Cancel" variant="outline" (clickEvent)="closeDialog()"></mds-button>
        </div>
    </div>
</div>
