<mds-dialog dialogTitle="Add New Site">
    <mds-dialog-content>
        <medpace-site
            [site]="data.site"
            [isModal]="true"
            [modalFormGroup]="siteFormGroup"
            [isUpdate]="isModalUpdate"
            (infoChangeEvent)="detectInfoChange($event)"
            (addressChangeEvent)="detectAddressChange($event)"
            (paymentChangeEvent)="detectPaymentChange($event)"
            (servicesChangeEvent)="detectServicesChange($event)"
            (crcChangeEvent)="detectCRCChange($event)"
        >
        </medpace-site>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'end'">
        <mds-button
            [id]="'cancel-btn'"
            mat-button
            mat-dialog-close
            label="Cancel"
            variant="outline"
            (clickEvent)="onNoClick()"
        ></mds-button>
        <mds-button
            [id]="'site-submit-btn'"
            mat-button
            (clickEvent)="onSubmit()"
            label="Submit"
            variant="primary"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
