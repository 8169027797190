import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatChipEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { Chip } from '@medpacesoftwaredevelopment/designsystem';

@Component({
    selector: 'medpace-chip-list',
    templateUrl: './medpace-chip-list.component.html',
    styleUrls: ['./medpace-chip-list.component.scss'],
})
export class MedpaceChipListComponent {
    @Input()
    autocompleteName: string = '';
    @Input()
    filteredOptions: string[] = [];
    @Input()
    placeholder: string = '';
    @Input()
    label: string = '';
    @Input()
    formCtrlName: string = '';
    @Input()
    formGrp: FormGroup = null;
    @Input()
    chiplist: Chip[] = [];
    @Input()
    floatLabel: 'always' | 'auto' = 'auto';

    @Output()
    public optionSelected: EventEmitter<MatOption> = new EventEmitter<MatOption>();
    @Output()
    public optionRemoved = new EventEmitter();

    select(option: MatOption): void {
        this.optionSelected.emit(option);
    }

    remove(option: MatChipEvent): void {
        this.optionRemoved.emit(option);
    }
}
