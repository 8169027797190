import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RequestTypes } from '@app/enums/RequestTypes';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { Patient } from '@models/patient';
import { GenericRequest } from '@models/request';
import { SiteServices } from '@models/site';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { RequestStateService } from '@services/state-management/request-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { maxLengthValidator } from '@utility/utility.validators';
import { Observable, Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';

@Component({
    selector: 'medpace-request-type-select-card',
    templateUrl: './request-type-card.component.html',
    styleUrl: './request-type-card.component.scss',
})
export class MedpaceRequestTypeSelectCardComponent implements OnChanges, OnInit, OnDestroy {
    @Input()
    isAdmin: boolean;

    @Input()
    parentFormGroup: FormGroup;

    @Input() isEditing: boolean;

    @Input() isUpdate: boolean; // is editing an existing request (true) or creating a new one (false)

    @Output()
    requestTypeChangeEvent = new EventEmitter<InputChange>();

    patient: Patient;
    travelEnabled: boolean;
    paymentEnabled: boolean;
    request$: Observable<any>;
    patient$: Observable<Patient>;
    request: GenericRequest;
    siteServices: SiteServices;
    requestStatus: number;
    isTravel: boolean = false;
    isPayment: boolean = false;
    travelDisabled: boolean = false;
    paymentDisabled: boolean = false;
    isNewRequest: boolean = true;
    private componentDestroyed$: Subject<boolean> = new Subject();
    requestTypeFormGroup = this.formBuilder.group({
        requestTypeGroupControl: new FormControl<RequestTypes>(null, [Validators.required]),
    });
    loaderSpinner: boolean = true;
    emailAddress: string = 'PCS@medpace.com';

    constructor(
        private formBuilder: FormBuilder,
        private requestStateService: RequestStateService,
        private siteStateService: SiteStateService,
        private patientStateService: PatientStateService,
        private activeRoute: ActivatedRoute
    ) {
        if (this.activeRoute.snapshot.params?.requestId) this.isNewRequest = false;
    }

    requestTypeOptions: MdsRadioButton[] = [
        {
            id: 'travelRadio',
            value: RequestTypes.TRAVEL,
            label: 'Travel',
            name: 'travelTypeRadio',
        },
        {
            id: 'paymentRadio',
            value: RequestTypes.PAYMENT,
            label: 'Payment',
            name: 'paymentTypeRadio',
        },
    ];

    ngOnInit(): void {
        this.parentFormGroup.addControl('requestTypeCheckbox', new FormControl(false, Validators.requiredTrue));

        this.request$ = this.requestStateService.getRequest().pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((request) => {
                if (request) {
                    this.request = request;
                    this.requestStatus = request.status;

                    this.setValues();
                }
                return this.siteStateService.getSite();
            }),
            tap((res) => {
                if (res) {
                    this.siteServices = res.services;
                    this.checkSiteServices();
                    this.setButtonDisableStatus();
                }
            })
        );

        this.patient$ = this.patientStateService.getSummaryPatientData().pipe(
            takeUntil(this.componentDestroyed$),
            tap((patient) => {
                if (patient) this.patient = patient;
            })
        );

        combineLatest([this.request$, this.patient$])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
                this.loaderSpinner = false;
            });
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    setValues(): void {
        this.requestTypeFormGroup.controls.requestTypeGroupControl.setValue(this.request.type as RequestTypes);
        if (this.request?.type) this.parentFormGroup.controls.requestTypeCheckbox.setValue(true);
        this.isEditing
            ? this.requestTypeFormGroup.controls.requestTypeGroupControl.enable()
            : this.requestTypeFormGroup.controls.requestTypeGroupControl.disable();
    }

    checkSiteServices(): void {
        this.travelEnabled = this.siteServices.travel;
        this.paymentEnabled = this.siteServices.stipend || this.siteServices.reimbursement;

        if (!this.travelEnabled) {
            this.travelDisabled = true;
            this.isTravel = false;
        }

        if (!this.paymentEnabled) {
            this.paymentDisabled = true;
            this.isPayment = false;
        }
    }

    setButtonDisableStatus(): void {
        this.requestTypeOptions.forEach((option) => {
            switch (option.value) {
                case RequestTypes.TRAVEL:
                    option.disabled = this.isTravelRadioDisabled();
                    break;
                case RequestTypes.PAYMENT:
                    option.disabled = this.isPaymentRadioDisabled();
                    break;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isEditing) {
            changes.isEditing?.currentValue
                ? this.requestTypeFormGroup.controls.requestTypeGroupControl.enable()
                : this.requestTypeFormGroup.controls.requestTypeGroupControl.disable();
        }
    }

    selectRequestType: (event: string, target: any) => void = (event: string, target: any) => {
        this.parentFormGroup.controls.requestTypeCheckbox.setValue(true);
        this.requestTypeChangeEvent.emit({ target: target.id, value: event });
    };
    isTravelRadioDisabled(): boolean {
        return this.isUpdate || this.travelDisabled;
    }
    isPaymentRadioDisabled(): boolean {
        return this.isUpdate || this.paymentDisabled;
    }
    isCompleted(): boolean {
        return this.isAdmin && this.requestStatus === 3;
    }
    isSubmittedOrHigherStatus(): boolean {
        return this.isAdmin && (this.requestStatus === 2 || this.requestStatus > 3);
    }
    isNewOrDraft(): boolean {
        return this.isAdmin && this.requestStatus < 2;
    }
    getRequestTypeValue(requestType: string): string {
        return this.requestTypeOptions.find((o) => o.value === requestType)?.label || 'Undefined';
    }

    isNew(): boolean {
        return this.requestStatus === 0;
    }
}
