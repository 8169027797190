<div>
    <div class="o-grid content">
        <div class="o-grid__row row-p">
            <h4 class="header">The caregiver cannot be removed as they are associated with travel requests.</h4>
        </div>
        <div class="o-grid__row buttons"></div>
        <div class="o-grid__row buttons">
            <mds-button [id]="'close-btn'" label="CLOSE" variant="primary" (clickEvent)="closeDialog()"></mds-button>
        </div>
    </div>
</div>
