export interface AccessGroup {
    id: number;
    displayName: string;
    appName: string;
}
export class AccessGroup {}
export enum AccessGroupEnum {
    SuperAdmin = 1,
    Admin = 2,
    CRC = 3,
}

export interface IAccessGroupViewModel {
    id: number;
    displayName?: string | null;
    appName?: string | null;
}

export class AccessGroupViewModel implements IAccessGroupViewModel {
    id!: number;
    displayName?: string | null;
    appName?: string | null;

    constructor(data?: IAccessGroupViewModel) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
            this.displayName = _data['displayName'] !== undefined ? _data['displayName'] : <any>null;
            this.appName = _data['appName'] !== undefined ? _data['appName'] : <any>null;
        }
    }

    static fromJS(data: any): AccessGroupViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new AccessGroupViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        data['displayName'] = this.displayName !== undefined ? this.displayName : <any>null;
        data['appName'] = this.appName !== undefined ? this.appName : <any>null;
        return data;
    }
}
