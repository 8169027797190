import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyViewModel } from '@models/currency';
import { PaymentOptions } from '@models/site-option-models';
import { EventService } from '@services/event/event.service';
import { SiteOptionService } from '@services/site-option/site-option.service';
import { CurrencyStateService } from '@services/state-management/currency-state.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { Observable, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Caregiver, Patient } from 'src/app/models/patient';
import { Site, SitePayment, SiteServices } from 'src/app/models/site';

interface NameValuePair {
    name: string;
    value: string;
}
@Component({
    selector: 'medpace-payment-card',
    templateUrl: './payment-card.component.html',
    styleUrls: ['./payment-card.component.scss'],
})
export class MedpacePatientPaymentCardComponent implements OnInit, OnDestroy {
    @Input()
    isAdmin: boolean;

    paymentOptions: SitePayment;

    patient: Patient = null;

    @Input()
    isEditing: boolean;

    @Input()
    isPatientProfile: boolean;

    @Input()
    parentFormGroup: FormGroup;

    siteSettings: SiteServices;

    currencies: CurrencyViewModel[] = [];

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();

    expanded: boolean;

    validPreferredMethod: boolean = true;
    validPreferredCurrency: boolean = true;

    selectOptions: any[] = [];
    optionsText: PaymentOptions = this.siteOptionService.getPaymentOptions();
    paymentRecipientSelectOptions: any[] = [];
    selectedPaymentRecipient: NameValuePair;
    currencies$: Observable<CurrencyViewModel[]>;
    paymentCardFormGroup: FormGroup;
    site$: Observable<Site> = this.siteStateService.getSite();
    patient$: Observable<Patient> = this.patientStateService.getSummaryPatientData();
    private componentDestroyed$: Subject<boolean> = new Subject();
    setupPaymentCard$: Observable<[Site, Patient]> = combineLatest([this.site$, this.patient$]).pipe(
        takeUntil(this.componentDestroyed$),
        tap(([site, patient]) => {
            if (patient) {
                this.patient = patient;
                this.paymentOptions = site?.payment;
                this.paymentOptionsAdjust();
                this.setRecipientSelectOptions();
                this.listenToPatientUpdate();
                this.setSelectedPaymentRecipient();
                this.setValuesFormGroup();
            }
        })
    );

    constructor(
        private eventService: EventService,
        private formBuilder: FormBuilder,
        private currencyStateService: CurrencyStateService,
        private siteStateService: SiteStateService,
        private patientStateService: PatientStateService,
        private siteOptionService: SiteOptionService
    ) {}

    ngOnInit() {
        this.currencies$ = this.currencyStateService.getCurrencies();
        this.initializeFormGroup();
        this.currencies$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((x) => (this.currencies = x))
            )
            .subscribe();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    showBankDetails(paymentCardFormGroup: FormGroup, sitePayment: SitePayment) {
        return (
            paymentCardFormGroup?.controls?.preferredMethodDopdownControl?.value === this.optionsText.wireTransfer &&
            sitePayment.collectBankDetails
        );
    }

    initializeFormGroup() {
        this.paymentCardFormGroup = this.formBuilder.group({
            paymentRecipientDropdownControl: '',
            preferredMethodDopdownControl: '',
            preferredCurrencyDopdownControl: '',
        });
        this.parentFormGroup.addControl('paymentCardFormGroup', this.paymentCardFormGroup);
    }

    setValuesFormGroup() {
        if (Object.keys(this.patient).length != 0 && Object.keys(this.selectedPaymentRecipient).length != 0) {
            this.paymentCardFormGroup.controls.paymentRecipientDropdownControl.setValue(
                this.selectedPaymentRecipient?.name
            );
            this.paymentCardFormGroup.controls.preferredMethodDopdownControl.setValue(this.patient?.preferredPayment);
            this.paymentCardFormGroup.controls.preferredCurrencyDopdownControl.setValue(
                this.patient?.preferredCurrency
            );
        }
    }

    listenToPatientUpdate() {
        this.eventService.on('updatedPatient', () => {
            this.patient$.pipe(takeUntil(this.componentDestroyed$)).subscribe((result: Patient) => {
                this.setActiveCaregivers(result);
            });
            this.setSelectedPaymentRecipient();
            this.setRecipientSelectOptions();
        });

        this.eventService.on('newPatientCreated', () => {
            this.setSelectedPaymentRecipient();
            this.setRecipientSelectOptions();
        });

        this.eventService.on('caregiverChanged', (result: Patient) => {
            this.setActiveCaregivers(result);
            this.setSelectedPaymentRecipient();
            this.setRecipientSelectOptions();
        });
    }

    setActiveCaregivers(patient: Patient) {
        let activeCaregivers: Caregiver[] = [];
        if (!!patient) {
            patient.caregivers?.forEach((c: Caregiver) => {
                if (!c.isDeleted && c.isPrimary) {
                    activeCaregivers.splice(0, 0, c);
                } else if (!c.isDeleted && !c.isPrimary) {
                    activeCaregivers.splice(1, 0, c);
                }
            });
            this.patient.caregivers = activeCaregivers;
        }
    }

    setSelectedPaymentRecipient() {
        let paymentRecipientName;
        if (!!this.patient && !!this.patient.paymentRecipientRole) {
            this.patient.caregivers?.forEach((c: Caregiver, index) => {
                if (this.patient.paymentRecipientRole === `Caregiver ${index}`) {
                    if (c.isPrimary) {
                        paymentRecipientName = `Primary Caregiver: ${c.firstName} ${c.lastName}`;
                    } else {
                        paymentRecipientName = `Caregiver: ${c.firstName} ${c.lastName}`;
                    }
                }
            });

            if (this.patient.paymentRecipientRole === 'Patient') {
                paymentRecipientName = `Patient: ${this.patient.patientIdentification.firstName} ${this.patient.patientIdentification.lastName}`;
            } else if (this.patient.paymentRecipientRole === '') {
                paymentRecipientName = 'N/A';
            }

            this.selectedPaymentRecipient = {
                name: this.patient.paymentRecipientRole,
                value: paymentRecipientName,
            };
        } else {
            this.selectedPaymentRecipient = {
                name: 'N/A',
                value: undefined,
            };
        }
        this.paymentOptionsAdjust();
    }

    setRecipientSelectOptions() {
        this.paymentRecipientSelectOptions = [];
        this.paymentRecipientSelectOptions.push({ value: '', viewValue: 'N/A' });

        if (!!this.patient?.patientIdentification?.firstName && !!this.patient?.patientIdentification?.lastName) {
            this.paymentRecipientSelectOptions.push({
                value: 'Patient',
                viewValue: ` Patient: ${this.patient.patientIdentification.firstName} ${this.patient.patientIdentification.lastName}`,
            });
        }

        this.patient?.caregivers?.forEach((c: Caregiver, index) => {
            if (c.isPrimary) {
                const primaryCaregiver: Caregiver = c;
                if (!!primaryCaregiver) {
                    this.paymentRecipientSelectOptions.push({
                        value: `Caregiver ${index}`,
                        viewValue: `Primary Caregiver: ${primaryCaregiver?.firstName} ${primaryCaregiver?.lastName}`,
                    });
                }
            } else {
                const caregiver: Caregiver = c;
                if (!!caregiver) {
                    this.paymentRecipientSelectOptions.push({
                        value: `Caregiver ${index}`,
                        viewValue: `Caregiver: ${caregiver?.firstName} ${caregiver?.lastName}`,
                    });
                }
            }
        });
    }

    paymentOptionsAdjust(): void {
        const propNames = Object.getOwnPropertyNames(this.paymentOptions);
        this.selectOptions = [];

        for (let x in propNames) {
            if (this.paymentOptions[propNames[x]] && this.optionsText[propNames[x]]) {
                this.selectOptions.push({
                    value: this.optionsText[propNames[x]],
                    viewValue: this.optionsText[propNames[x]],
                });
            }
        }
    }

    emitSelectChange: (value: string, target: any) => void = (value: string, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };

    emitPaymentRecipientChange: (recipient: any, target: any) => void = (recipient: any, target: any) => {
        recipient ? this.enableFieldValidation() : this.disableFieldValidation();
        this.inputChangeEvent.emit({ target: target.id, value: recipient });
        this.selectedPaymentRecipient.value = recipient;
    };

    emitChange: (event: any) => void = (event: any) => {
        this.inputChangeEvent.emit({ target: event.source.id, value: event.checked });
    };

    passContentAlong: (event: any) => void = (event: any) => {
        this.inputChangeEvent.emit({ target: event.target, value: event.value });
    };

    private enableFieldValidation(): void {
        Object.values(this.paymentCardFormGroup.controls).forEach((element) => {
            element.addValidators(Validators.required);
            element.updateValueAndValidity();
        });
        this.paymentCardFormGroup.updateValueAndValidity();
    }
    private disableFieldValidation(): void {
        this.paymentCardFormGroup.clearValidators();
        Object.values(this.paymentCardFormGroup.controls).forEach((element) => {
            element.clearValidators();
            element.updateValueAndValidity();
        });
        this.paymentCardFormGroup.updateValueAndValidity();
    }
}
