import { Moment } from 'moment';
import { AnnouncementFilterOption, AnnouncementFilterOptionDTO } from './announcementFilterOption';
export enum AnnouncementStatus {
    Active = 1,
    Archived = 2,
    Deleted = 3,
}

export interface Announcement {
    id: number;
    title: string;
    description: string;
    status: AnnouncementStatus;
    expireTimestamp: Moment;
    lastModifiedUserId: number;
    author: string; // utility field, populated using lastEditUserId, used in announcements.component.ts in table display
    filterOptions: AnnouncementFilterOption[];
}
export interface AnnouncementDTO {
    id: number;
    title: string;
    description: string;
    statusId: number;
    expireTimestamp: string;
    lastModifiedUserId: number;
    lastModifiedUserName: string;
    filterOptions: AnnouncementFilterOptionDTO[];
}

export interface CreateAnnouncementDTO {
    title: string;
    description: string;
    statusId: number;
    expireTimestamp: string;
    lastModifiedUserId: number;
    filterOptions: AnnouncementFilterOptionDTO[];
}

export interface UpdateAnnouncementDTO extends CreateAnnouncementDTO {
    id: number;
}
