<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h1>Studies</h1>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-table
                    [data]="studies"
                    [columns]="columns"
                    [fieldMap]="columnMap"
                    searchPlaceholder="Region"
                    [filterOptions]="regions"
                    [ifFiltering]="false"
                    [isSearching]="true"
                    title="All Studies"
                    [defaultSorting]="{ columnName: 'sponsor', direction: 'asc' }"
                    (rowClickEvent)="doClickRow($event)"
                ></medpace-table>
            </div>
        </div>
    </div>
</div>
