<div class="o-grid" *ngIf="isEditing">
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="sponsor"
                [formGrp]="parentFormGroup"
                formCtrlName="sponsorControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Sponsor"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'sponsor' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="protocol"
                [formGrp]="parentFormGroup"
                formCtrlName="protocolControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Protocol"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'protocol' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="studyCode"
                [formGrp]="parentFormGroup"
                formCtrlName="studyCodeControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Project ID"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'studyCode' })"
            ></mds-text-field>
        </div>
    </div>

    <ng-container *ngIf="!isClinTrakComparison">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-logo-upload
                    [isImage]="true"
                    [acceptableTypes]="acceptableFileTypes"
                    uploadButtonTitle="Upload Logo"
                    (emitStuff)="passFileAlong($event)"
                ></medpace-logo-upload>
            </div>
        </div>
    </ng-container>
</div>

<div *ngIf="!isEditing && !loaderSpinner" class="o-grid">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Sponsor</h5>
                <span>{{ study?.sponsor }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Protocol</h5>
                <span>{{ study?.protocol }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block" *ngIf="site === null">
                <h5>Project ID</h5>
                <span>{{ study?.studyCode }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item" *ngIf="site !== null">
            <div class="c-info__block">
                <h5>Site #</h5>
                <span>{{ site?.info?.siteNumber }}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
