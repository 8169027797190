import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MedpaceSiteChangeStatusModalComponent } from '@app/admin/manage/modals/site-change-status-modal/site-change-status-modal.component';
import { SiteStatusEnum } from '@app/enums/site-status.enum';
import { CRCCard2Component } from '@components/molecules/forms/crc-card2/crc-card2.component';
import { ActionModel } from '@models/actionModel';
import { InputChange } from '@models/event-objects/input-change';
import { VMCRCCard2 } from '@models/general-models';
import { User } from '@models/user';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { Step } from '@services/scroll/steps-interface';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { UserService } from '@services/user/user.service';
import { disableControls, enableControls, getDisabledControls } from '@utility/form-utils';
import { MdsOptionGeneric, buildSnackBar, collectErrors, mapCRCData_To_SiteCRCs } from '@utility/utility';
import {
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { Address, Site, SiteCRC, SiteInfo, SitePayment, SiteServices } from 'src/app/models/site';

@Component({
    selector: 'medpace-site',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss'],
})
export class MedpaceCreateSiteComponent implements OnInit, OnDestroy {
    private siteSubject = new ReplaySubject<Site>();
    @Input() public set site(value: Site) {
        if (!!value) this.siteSubject.next(value);
        this._site = value;
    }
    public get site() {
        return this._site;
    }
    private _site: Site;
    @Input()
    isModal: boolean = true;

    @Input()
    isUpdate: boolean = false;

    @Input()
    modalFormGroup: FormGroup;

    @Output()
    clickSubmit = new EventEmitter();

    @Output()
    infoChangeEvent = new EventEmitter<SiteInfo>();

    @Output()
    addressChangeEvent = new EventEmitter<Address>();

    @Output()
    shippingAddressChangeEvent = new EventEmitter<Address>();

    @Output()
    isShippingAddressChangeEvent = new EventEmitter<boolean>();

    @Output()
    servicesChangeEvent = new EventEmitter<SiteServices>();

    @Output()
    paymentChangeEvent = new EventEmitter<SitePayment>();

    @Output()
    crcChangeEvent = new EventEmitter();

    @Output()
    crcToInviteChangeEvent = new EventEmitter();

    @Output()
    statusChangeEvent = new EventEmitter();

    validationMessage: string = 'Please address changes before continuing';
    isValidForm: boolean = true;
    siteFormGroup: FormGroup;
    private componentDestroyed$: Subject<boolean> = new Subject();
    public readonly user$ = this.userService.getUser();

    steps: Step[] = [
        {
            name: 'Site Information',
            isActive: true,
            identifier: 'siteInfo',
        },
        {
            name: 'Site Address',
            isActive: false,
            identifier: 'siteAddress',
        },
        {
            name: 'Site Shipping Address',
            isActive: false,
            identifier: 'siteShippingAddress',
        },
        {
            name: 'Site Settings',
            isActive: false,
            identifier: 'siteServices',
        },
        {
            name: 'Site CRC Information',
            isActive: false,
            identifier: 'siteCRCs',
        },
    ];
    private readonly crcOptions$ = this.userService.getUser().pipe(
        switchMap((user) =>
            !user.isAdmin && !user.isSuperAdmin
                ? of(<User[]>[])
                : this.siteStateService
                      .getUsers()
                      .pipe(
                          distinctUntilChanged(
                              (previous, current) => JSON.stringify(previous) === JSON.stringify(current)
                          )
                      )
        ),
        map((users) => {
            return users.map((user) => {
                const displayName = user.firstName + ' ' + user.lastName;
                return <MdsOptionGeneric<User>>{ value: user, viewValue: displayName };
            });
        })
    );
    public readonly crcCardVM_Create$: Observable<VMCRCCard2> = this.crcOptions$.pipe(
        map((options) => {
            return <VMCRCCard2>{
                crcOptions: options,
                hasSupportingCRCs: null,
                primaryCRC: null,
                supportingCRCs: [],
            };
        })
    );
    public readonly crcCardVM_Edit$: Observable<VMCRCCard2> = combineLatest({
        crcOptions: this.crcOptions$,
        site: this.siteSubject,
    }).pipe(
        map((result) => {
            const primaryCRC = result.site.siteCRCs.find((crc) => crc.isPrimary);
            const supportingCRCs = result.site.siteCRCs.filter((crc) => !crc.isPrimary);
            return <VMCRCCard2>{
                crcOptions: result.crcOptions,
                hasSupportingCRCs: result.site.siteCRCs.some((crc) => !crc.isPrimary),
                primaryCRC: primaryCRC ? primaryCRC.user : null,
                supportingCRCs:
                    supportingCRCs && supportingCRCs.length !== 0 ? supportingCRCs.map((crc) => crc.user) : [],
            };
        })
    );

    public actions$: Observable<ActionModel[]> = this.siteSubject.pipe(
        take(1),
        switchMap((result) => (!result ? of(<ActionModel[]>[]) : this.siteService.getAvailableActions(result.id)))
    );

    private _crcCard: CRCCard2Component;
    private crcCardSubject = new ReplaySubject<CRCCard2Component>();
    public get crcCard() {
        return this._crcCard;
    }
    @ViewChild(CRCCard2Component) public set crcCard(value: CRCCard2Component) {
        this._crcCard = value;
        if (this._crcCard) this.siteFormGroup.addControl('CRC Card', this._crcCard.formGroup);
        this.crcCardSubject.next(value);
    }
    constructor(
        private snackbarService: SnackbarService,
        private userService: UserService,
        private siteStateService: SiteStateService,
        private siteService: AdminSiteServices,
        public dialog: MatDialog
    ) {
        this.siteFormGroup = new FormGroup({});
    }

    ngOnInit(): void {
        if (this.isModal) {
            this.siteFormGroup.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
                this.modalFormGroup.controls = this.siteFormGroup.controls;
                this.modalFormGroup.updateValueAndValidity();
            });
        }
        this.crcCardSave$.pipe(takeUntil(this.componentDestroyed$)).subscribe();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    changeStatus(): void {
        const dialogRef = this.dialog.open(MedpaceSiteChangeStatusModalComponent, {
            data: {
                actionsObservable: this.actions$,
                siteSubject: this.siteSubject,
            },
            width: '500px',
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result: string) => {
            if (result) {
                this.statusChangeEvent.emit(result);
            }
        });
    }

    submit(): void {
        const disabledControls = getDisabledControls(this.siteFormGroup);
        enableControls(this.siteFormGroup, disabledControls);

        if (this.siteFormGroup.valid) {
            // && this._crcCard.formGroup.valid)
            // this.crcChangeEvent.emit(mapCRCData_To_SiteCRCs(this._crcCard.getOutputData(), this.site.id));
            this.clickSubmit.emit();
        } else {
            this.siteFormGroup.markAllAsTouched();

            // if (this.isCrcCardVisible()) {
            //     this._crcCard.formGroup.markAllAsTouched();
            //     this._crcCard.formGroup.recursiveUpdateValueAndValidity();
            // }
        }

        //should display snackbar only when form invalid
        if (this.isUpdate && this.siteFormGroup.invalid) {
            buildSnackBar(collectErrors(this.siteFormGroup), this.snackbarService);
        }
        disableControls(this.siteFormGroup, disabledControls);
    }

    submitIncrementalSave(): void {
        this.clickSubmit.emit({ type: 'incrementalSave', doRoute: false });
    }

    passAlongChange(event: any) {
        let splittedTarget = event.target.split('_');
        let crcs = this.registerCrcChange(event);
        let crcToInvite = this.registerCrcToInviteChange(event);
        let address = this.mapToAddress(event);
        let services = this.mapToServices(event);
        let info = this.mapToSiteInfo(event);
        let payment = this.mapToPayment(event);

        if (address && splittedTarget[0] === 'shipping') {
            this.shippingAddressChangeEvent.emit(address);
        }
        if (address && splittedTarget[0] !== 'shipping') {
            this.addressChangeEvent.emit(address);
        }
        if (info) {
            this.infoChangeEvent.emit(info);
        }
        if (services) {
            this.servicesChangeEvent.emit(services);
        }
        if (payment) {
            this.paymentChangeEvent.emit(payment);
        }
        if (crcs) {
            this.crcChangeEvent.emit(crcs);
        }

        if (crcToInvite) {
            this.crcToInviteChangeEvent.emit(crcToInvite);
        }

        if (event.target === 'isShippingAddressSameAsPrimary') {
            this.isShippingAddressChangeEvent.emit(event.value);
        }
    }

    public readonly crcCardSave$ = combineLatest({ crcCard: this.crcCardSubject, site: this.siteSubject }).pipe(
        filter((x) => this.isCrcCardVisible()),
        switchMap((result) =>
            result.crcCard.saveEvent.pipe(
                map((data) => {
                    return { site: result.site, data: data };
                })
            )
        ),
        map((result) => mapCRCData_To_SiteCRCs(result.data, result.site.id)),
        tap((crcs) => {
            this.crcChangeEvent.emit(crcs);
            this.submitIncrementalSave();
        })
    );

    private registerCrcChange(event): SiteCRC[] {
        if (event.target === 'siteCRCs') {
            return event.value;
        }
    }

    private registerCrcToInviteChange(event): string {
        if (event.target === 'primaryCrcEmailToInvite') {
            return event.value;
        }
    }

    private mapToAddress(event: InputChange): Address {
        let splittedTarget = event.target.split('_');
        let address = <Address>{};

        splittedTarget[0] === 'shipping'
            ? (address[splittedTarget[1]] = event.value)
            : (address[event.target] = event.value);

        return address;
    }

    private mapToServices(event: InputChange): SiteServices {
        let services = <SiteServices>{};
        services[event.target] = event.value;
        return services;
    }

    private mapToSiteInfo(event: InputChange): SiteInfo {
        let info = <SiteInfo>{};
        info[event.target] = event.value;
        return info;
    }

    private mapToPayment(event: InputChange): SitePayment {
        let payment = <SitePayment>{};
        payment[event.target] = event.value;
        return payment;
    }
    public isUpdateAndAdmin(user: User): boolean {
        return this.isUpdate && (user.isAdmin || user.isSuperAdmin);
    }

    isEditButtonVisible(): boolean {
        return (
            this.site?.status?.statusId === SiteStatusEnum.PreRegistered ||
            this.site?.status?.statusId === SiteStatusEnum.Registered
        );
    }

    isCrcCardVisible(): boolean {
        return (
            this.site &&
            this.site?.status &&
            this.site?.status.statusId !== SiteStatusEnum.Created &&
            this.site?.status.statusId !== SiteStatusEnum.PreRegistered &&
            this.site?.siteCRCs?.some((x) => x.isPrimary)
        );
    }

    isCrcInviteCardVisible(): boolean {
        return (
            (this.site &&
                !!this.site.status &&
                (this.site?.status?.statusId === SiteStatusEnum.Created ||
                    this.site?.status?.statusId === SiteStatusEnum.PreRegistered)) ||
            !this.site?.siteCRCs?.some((x) => x.isPrimary)
        );
    }

    isChangeStatusButtonVisible(): boolean {
        return this.site && !!this.site.status && this.site.status.statusId !== SiteStatusEnum.Inactive;
    }
}
