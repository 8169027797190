<mds-dialog *ngIf="{ sso: updateSso$ | async } as vm" [dialogTitle]="dialogTitle" style="max-width: 800px">
    <mds-dialog-content>
        <div class="o-grid" *ngIf="studies[0]; else spinner">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="flex start middle">
                        <span class="required-asterisk">*</span>
                        <span> indicates a required field </span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="personalDataFormGroup"
                        [formCtrlName]="emailAddressFieldName"
                        [id]="emailAddressFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="email"
                        floatLabel="always"
                        [label]="emailAddressFieldName"
                        width="100%"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="personalDataFormGroup"
                        [formCtrlName]="firstNameFieldName"
                        [id]="firstNameFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        [label]="firstNameFieldName"
                        width="100%"
                        matTooltipPosition="below"
                        aria-label="None"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="personalDataFormGroup"
                        [formCtrlName]="lastNameFieldName"
                        [id]="lastNameFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        [label]="lastNameFieldName"
                        width="100%"
                        matTooltipPosition="below"
                        aria-label="None"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="personalDataFormGroup"
                        [formCtrlName]="phoneNumberFieldName"
                        [id]="phoneNumberFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="tel"
                        floatLabel="always"
                        [label]="phoneNumberFieldName"
                        width="100%"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <mds-checkbox
                        [formGrp]="authenticationMethodFormGroup"
                        [formCtrlName]="authenticationMethodFieldName"
                        label="SSO"
                        mat-raised-button
                        [matTooltip]="matToolTipTextCheckBox"
                        matTooltipPosition="below"
                        aria-label="None"
                        id="authenticationMethodButton"
                    >
                    </mds-checkbox>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        [formGrp]="assignedAccessGroupFormGroup"
                        [formCtrlName]="userAccessGroupFieldName"
                        [id]="userAccessGroupFieldName"
                        appearance="outline"
                        [label]="userAccessGroupFieldLabel"
                        [placeholder]="userAccessPlaceholder"
                        [options]="accessGroupOptions$ | async"
                    ></mds-dropdown>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isCRC || isAdmin">
                <div class="o-grid__field" [formGroup]="assignedStudyFormGroup">
                    <medpace-chip-list
                        id="assignedStudy"
                        autocompleteName="assignedStudy"
                        [filteredOptions]="filteredStudies$ | async"
                        placeholder="Please select study"
                        label="Assigned Study"
                        formCtrlName="studyControl"
                        [formGrp]="assignedStudyFormGroup"
                        [chiplist]="studyListChips$ | async"
                        (optionSelected)="selectedStudy($event)"
                        (optionRemoved)="removeStudy($event)"
                    ></medpace-chip-list>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isCRC">
                <div class="o-grid__field" [formGroup]="assignedSiteFormGroup">
                    <medpace-chip-list
                        id="assignedSite"
                        [autocompleteName]="siteAutocompleteName"
                        [filteredOptions]="filteredSites$ | async"
                        placeholder="Please select sites"
                        label="Assigned Site"
                        [formCtrlName]="siteAutocompleteName"
                        [formGrp]="assignedSiteFormGroup"
                        [chiplist]="siteListChips"
                        (optionSelected)="selectedSite($event)"
                        (optionRemoved)="removeSite($event)"
                    ></medpace-chip-list>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button
            variant="primary"
            [id]="'create-user-btn'"
            mat-button
            (clickEvent)="submitClick()"
            label="Create Profile"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            variant="outline"
            mat-button
            mat-dialog-close
            label="Cancel"
            (clickEvent)="cancelClick()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
