import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
    inject,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MdsDatepickerComponent } from '@medpacesoftwaredevelopment/designsystem';
import { PersistentFormControl } from '@utility/persistent-forms';
import { invalidValue } from '@utility/utility.validators';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, Subject, filter, merge, startWith, takeUntil, tap } from 'rxjs';

/**
 * Wrapper component for MdsDatepicker that handles dates using moment
 */
@Component({
    selector: 'medpace-datepicker',
    templateUrl: './medpace-datepicker.component.html',
    styleUrls: ['./medpace-datepicker.component.scss'],
})
export class MedpaceDatepickerComponent implements OnDestroy, AfterViewInit {
    @ViewChild('mds_datepicker') datePicker: MdsDatepickerComponent;
    private renderer = inject(Renderer2);

    @Input() label: string = '';
    @Input() hint: string;
    @Input() appearance: 'fill' | 'outline' = 'fill';
    @Input() color: ThemePalette = 'primary';
    @Input() datePickerHeight: 'standard' | 'small' = 'standard';
    @Input() defaultErrorSpacing: boolean = false;
    @Input() placeholder: string = 'Select date';
    @Input() set formCtrl(value: PersistentFormControl<Moment>) {
        this._formCtrl = value;
        this.init();
    }
    get formCtrl() {
        return this._formCtrl;
    }
    private _formCtrl: PersistentFormControl<Moment>;
    @Input() set formCtrlName(value: string) {
        this._formCtrlName = value;

        if (!!this.formCtrlName && !!this.formGrp) this.init();
    }
    get formCtrlName() {
        return this._formCtrlName;
    }
    private _formCtrlName: string;
    @Input() set formGrp(value: FormGroup) {
        this._formGrp = value;
        if (!!this.formCtrlName && !!this.formGrp) this.init();
    }
    get formGrp() {
        return this._formGrp;
    }
    private _formGrp: FormGroup;
    @Input() id: string;
    @Input() required: boolean = false;
    /**
     * FormControl used locally
     */
    localFormControl: PersistentFormControl<Date> = new PersistentFormControl(null);

    fallbackInputValue: BehaviorSubject<string> = new BehaviorSubject('');

    /**
     * Checks if component passed FormControl or FormGroup with FormControl name
     */
    isFormCtrl: boolean = false;
    private componentDestroyed$ = new Subject();
    private init$ = new Subject<void>();
    private componentDestroyedOrInit$ = merge(this.componentDestroyed$, this.init$);
    /**
     * Emits an event when date is changed
     *
     * WARNING: target does not have a complete implementation of MatDatepickerInputBase, only property 'value' exists
     */
    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Moment>> = new EventEmitter();

    init(): void {
        //handle form control
        if (!this.formCtrl) {
            // no formCtrl, get value from formGroup
            if (!!this.formGrp && !!this.formCtrlName) {
                const formCtrl = <PersistentFormControl<Moment>>this.formGrp.get(this.formCtrlName);
                if (!!formCtrl) {
                    this.localFormControl.setValidators(formCtrl.validator);
                    if (formCtrl.hasValidator(Validators.required))
                        this.localFormControl.addValidators(Validators.required);

                    this.localFormControl.setValue(this.getDateFromFormValue(formCtrl.value));
                    //Pass errors from formControl to localFormControl
                    formCtrl.valueChanges
                        .pipe(
                            takeUntil(this.componentDestroyedOrInit$),
                            startWith(formCtrl.value),
                            tap((value) => {
                                this.localFormControl.setErrors(formCtrl.errors);
                            })
                        )
                        .subscribe();
                    if (formCtrl.touchedChanges)
                        formCtrl.touchedChanges
                            .pipe(
                                takeUntil(this.componentDestroyedOrInit$),
                                startWith(this.formCtrl?.touched),
                                tap((touched) =>
                                    touched
                                        ? this.localFormControl.markAsTouched()
                                        : this.localFormControl.markAsUntouched()
                                )
                            )
                            .subscribe();
                    this.isFormCtrl = false;
                } else {
                    throw new Error('No valid formControl');
                }
            }
        } else {
            this.localFormControl.setValidators(this.formCtrl.validator);
            if (this.formCtrl.hasValidator(Validators.required))
                this.localFormControl.addValidators(Validators.required);

            this.localFormControl.setValue(this.getDateFromFormValue(this.formCtrl.value));
            this.formCtrl.valueChanges
                .pipe(
                    takeUntil(this.componentDestroyedOrInit$),
                    startWith(this.formCtrl.value),
                    tap((value) => {
                        this.localFormControl.setErrors(this.formCtrl.errors);
                    })
                )
                .subscribe();
            if (this.formCtrl.touchedChanges)
                // copy the touched state, without it the control won't be correctly highlighted in red when there's an error
                this.formCtrl.touchedChanges
                    .pipe(
                        takeUntil(this.componentDestroyedOrInit$),
                        startWith(this.formCtrl?.touched),
                        tap((touched) =>
                            touched ? this.localFormControl.markAsTouched() : this.localFormControl.markAsUntouched()
                        )
                    )
                    .subscribe();
            this.isFormCtrl = true;
        }

        this.localFormControlValueChanges();

        this.setUpFallbackInputValue();
    }

    ngAfterViewInit() {
        const nativeElement = this.datePicker['_matDatePicker']?.datepickerInput['_elementRef']?.nativeElement;
        if (nativeElement) this.renderer.setAttribute(nativeElement, 'placeholder', this.placeholder);
    }

    localFormControlValueChanges(): void {
        //return Date converted to Moment
        if (this.isFormCtrl) {
            //listen to localFormControl and return to formCtrl
            this.localFormControl.valueChanges
                .pipe(
                    takeUntil(this.componentDestroyedOrInit$),
                    tap((value: Date) => {
                        this.formCtrl?.setValue(this.transformDateToMoment(value));
                    })
                )
                .subscribe();
        } else {
            //listem to localFormControl and return to formGrp
            this.localFormControl.valueChanges
                .pipe(
                    takeUntil(this.componentDestroyedOrInit$),
                    tap((value: Date) => {
                        this.formGrp?.get(this.formCtrlName)?.setValue(this.transformDateToMoment(value));
                    })
                )
                .subscribe();
        }
    }

    /**
     * Return date from FormValue
     * @param formValue value of datepicker, can be a native date string or Moment object
     * @returns date or null of formValue is falsely
     */
    getDateFromFormValue(formValue: Moment | Date): Date | null {
        if (!!formValue) {
            if (typeof formValue === 'string') {
                return formValue;
            } else if (typeof formValue === 'object' && 'toDate' in formValue) {
                let date = moment(formValue);
                if (formValue.utcOffset() === 0) {
                    //add timezone offset
                    date.subtract(moment(new Date(formValue.toDate())).utcOffset(), 'm');
                }
                return date.toDate();
            }
        }
        return null;
    }

    transformDateToMoment(date: Date): Moment | null {
        if (!!date) {
            //TODO: change when mds-datepicker supports utc
            return moment(date).add(moment(date).utcOffset(), 'm').utc();
        }
        return null;
    }

    emitDateChange(event: MatDatepickerInputEvent<Date, unknown>): void {
        const newValue = this.transformDateToMoment(event.value);
        const newEvent: MatDatepickerInputEvent<Moment> = {
            ...event,
            target: {
                value: newValue,
            } as any,
            value: newValue,
        };

        this.dateChange.emit(newEvent);
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    writeText(event: KeyboardEvent) {
        //current value from native HTML input element
        this.fallbackInputValue.next(
            this.datePicker['_matDatePicker']?.datepickerInput['_elementRef']?.nativeElement?.value
        );
    }
    setUpFallbackInputValue() {
        //on fallback value change validate form control
        this.fallbackInputValue
            .asObservable()
            .pipe(
                takeUntil(this.componentDestroyedOrInit$),
                tap((value) => {
                    // Validated manually on fallbackInputValue change, when non-date value is inserted into form field
                    // asyncValidator would not update validation due to control value not changing (still being null)
                    const invalidInputError = invalidValue(value)(this.localFormControl);

                    if (!!invalidInputError) {
                        this.localFormControl.setErrors(invalidInputError);
                    }
                })
            )
            .subscribe();
        //if form has valid value (is not null) clear fallbackInputValue
        this.localFormControl.valueChanges
            .pipe(
                takeUntil(this.componentDestroyedOrInit$),
                filter(Boolean),
                tap((_) => {
                    this.fallbackInputValue.next('');
                })
            )
            .subscribe();
    }
}
