import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { safeUTC } from '@app/extensions/moment-extensions';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { Caregiver, Patient } from '@models/patient';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { PersistentFormControl } from '@utility/persistent-forms';
import { buildSnackBar, collectErrors, tryFormatPhoneNumberToE164 } from '@utility/utility';
import {
    emailValidator,
    lessThanTodayDateValidator,
    maxLengthValidator,
    phoneNumberFormatValidatorWithMaxLength,
    requiredValidatorCustomMessage,
    zipCodeFormatValidator,
} from '@utility/utility.validators';
import { Moment } from 'moment';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { globals } from '../../../../../../globals';
@Component({
    selector: 'medpace-caregiver-info-dialog',
    templateUrl: './caregiver-info-dialog.component.html',
    styleUrls: ['./caregiver-info-dialog.component.scss'],
})
export class MedpaceCaregiverInfoDialogComponent implements OnInit, OnDestroy {
    caregiverInfoFormGroup: FormGroup;
    asListedOnGovID: string = '(' + globals.constants.asListedOnGovernmentID + ')';
    editMode: boolean = false;
    patient: Patient = null;
    isPrimary: boolean = false;
    isDisabled: boolean = false;
    selectedCaregiver: Caregiver = null;
    isNewCaregiver: boolean = false;
    showAddressFields: boolean = false;
    validationMessage: string = '*Required fields are missing';
    showValidationMessage: boolean = false;
    countries$: Observable<string[]> = this.countryStateService.getCountriesStringArray();
    private componentDestroyed$: Subject<boolean> = new Subject();
    componentIdName: string = 'caregiver-info';
    genderRadioButtons: MdsRadioButton[] = [
        {
            label: 'Male',
            value: 'male',
            name: `${this.componentIdName}male`,
            id: `${this.componentIdName}male`,
        },
        {
            label: 'Female',
            value: 'female',
            name: `${this.componentIdName}female`,
            id: `${this.componentIdName}female`,
        },
    ];
    adressRadioButtons: MdsRadioButton[] = [
        {
            label: 'Yes',
            value: 'true',
            name: `${this.componentIdName}yes`,
            id: `${this.componentIdName}yes`,
        },
        {
            label: 'No',
            value: 'false',
            name: `${this.componentIdName}no`,
            id: `${this.componentIdName}no`,
        },
    ];
    regionStateType$ = of('Province / Region / State');

    constructor(
        private fb: FormBuilder,
        private countryStateService: CountryStateService,
        public dialogRef: MatDialogRef<MedpaceCaregiverInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackbarService: SnackbarService,
        private googleMapsService: GoogleMapsService,
        private ngZone: NgZone
    ) {}
    birthDate: Moment;
    ngOnInit(): void {
        this.editMode = this.data?.editMode;
        this.isNewCaregiver = this.data?.newCaregiver;
        this.patient = this.data?.patient;
        this.selectedCaregiver = !!this.data?.selectedCaregiver ? this.data.selectedCaregiver : new Caregiver();
        if (this.selectedCaregiver && this.selectedCaregiver.birthDate)
            this.birthDate = safeUTC(this.selectedCaregiver.birthDate);
        this.initializeFormGroup();
        this.setAddressValidators();
        this.shouldBePrimary();
        this.showAddressFields = this.caregiverInfoFormGroup.get('addressSameAsPatient').value === 'false';
        this.caregiverInfoFormGroup
            .get('country')
            ?.valueChanges.pipe(
                takeUntil(this.componentDestroyed$),
                tap((country) => {
                    if (typeof country === 'object' && country.value !== undefined) {
                        this.regionStateType$ = this.countryStateService.getRegionStateType(country.value);
                    }
                })
            )
            .subscribe();

        this.googleMapsService
            .observePlaceListener()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((place) => {
                    this.googleMapsService
                        .getFieldName()
                        .pipe(
                            tap((fieldName) => {
                                if (place && fieldName === 'caregiverAddress1') this.setAddressControls(place);
                            }),
                            take(1)
                        )
                        .subscribe();
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    private setAddressControls(placeResult: google.maps.places.PlaceResult) {
        const addressComponents = placeResult.address_components;
        const line1 = `${this.googleMapsService.getAddressComponent(
            'street_number',
            addressComponents
        )} ${this.googleMapsService.getAddressComponent('route', addressComponents)}`;
        const city = this.googleMapsService.getCityComponent(placeResult);
        const state = this.googleMapsService.getAddressComponent('administrative_area_level_1', addressComponents);
        const postalCode = this.googleMapsService.getAddressComponent('postal_code', addressComponents);
        const country = this.googleMapsService.getAddressComponent('country', addressComponents);

        this.caregiverInfoFormGroup.patchValue({
            address1: line1,
            city: city,
            state: state,
            zipCode: postalCode,
            country: country,
        });
    }

    setEventListenerForAddressControl() {
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            const mdsComp = document.querySelector('mds-text-field > #caregiverAddress1');
            const inputField = mdsComp?.querySelector('input') as HTMLInputElement | null;

            if (inputField) this.googleMapsService.setPlaceListener(inputField);
        });
    }

    initializeFormGroup(): void {
        this.caregiverInfoFormGroup = this.fb.group({
            isPrimary: false,
            firstName: ['', [Validators.required, maxLengthValidator(150)]],
            middleName: ['', maxLengthValidator(150)],
            lastName: ['', [Validators.required, maxLengthValidator(150)]],
            phoneNumber: ['', [Validators.required, phoneNumberFormatValidatorWithMaxLength(16)]],
            email: ['', [Validators.required, emailValidator, maxLengthValidator(56)]],
            relationshipToPatient: ['', [Validators.required, maxLengthValidator(15)]],
            language: ['', [Validators.required, maxLengthValidator(50)]],
            gender: ['', requiredValidatorCustomMessage('Please select gender')],
            birthDate: new PersistentFormControl<Moment>(null, [Validators.required, lessThanTodayDateValidator]),
            addressSameAsPatient: this.isNewCaregiver
                ? ['true']
                : [this.selectedCaregiver.addressSameAsPatient === true ? 'true' : 'false'],
            address1: [''],
            address2: ['', maxLengthValidator(100)],
            city: [''],
            state: ['', maxLengthValidator(100)],
            zipCode: [''],
            country: [null],
            additionalDetails: ['', maxLengthValidator(100)],
        });
        this.caregiverInfoFormGroup.controls.birthDate.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((value) => (this.birthDate = value))
            )
            .subscribe();
        this.caregiverInfoFormGroup.controls.addressSameAsPatient.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((value) => {
                    this.setAddressValidators();
                    value === 'true' ? (this.showAddressFields = true) : (this.showAddressFields = false);
                })
            )
            .subscribe();
    }

    setValues() {
        this.caregiverInfoFormGroup.patchValue({
            isPrimary: this.selectedCaregiver.isPrimary,
            firstName: this.selectedCaregiver.firstName,
            middleName: this.selectedCaregiver.middleName,
            lastName: this.selectedCaregiver.lastName,
            phoneNumber: this.selectedCaregiver.phone,
            email: this.selectedCaregiver.email,
            relationshipToPatient: this.selectedCaregiver.relationship,
            language: this.selectedCaregiver.preferredLang,
            gender: this.selectedCaregiver.gender,
            birthDate: this.selectedCaregiver.birthDate,
            addressSameAsPatient: this.selectedCaregiver.addressSameAsPatient ? 'true' : 'false',
            additionalDetails: this.selectedCaregiver.details,
        });
        if (!this.selectedCaregiver.addressSameAsPatient) {
            this.caregiverInfoFormGroup.patchValue({
                address1: this.selectedCaregiver.address1,
                address2: this.selectedCaregiver.address2,
                city: this.selectedCaregiver.city,
                state: this.selectedCaregiver.state,
                zipCode: this.selectedCaregiver.zipcode,
                country: this.selectedCaregiver.country,
            });
        }
    }

    setAddressValidators() {
        this.caregiverInfoFormGroup.get('addressSameAsPatient').valueChanges.subscribe((value) => {
            if (!!value) {
                if (value === 'false' || value === false) {
                    this.showAddressFields = true;
                    this.caregiverInfoFormGroup
                        .get('address1')
                        .setValidators([Validators.required, maxLengthValidator(100)]);
                    this.caregiverInfoFormGroup
                        .get('city')
                        .setValidators([Validators.required, maxLengthValidator(100)]);
                    this.caregiverInfoFormGroup.get('zipCode').setValidators([zipCodeFormatValidator]);
                    this.caregiverInfoFormGroup.get('country').setValidators([Validators.required]);
                } else if (value === 'true' || value === true) {
                    this.showAddressFields = false;

                    this.caregiverInfoFormGroup.get('address1').clearValidators();
                    this.caregiverInfoFormGroup.get('city').clearValidators();
                    this.caregiverInfoFormGroup.get('zipCode').clearValidators();
                    this.caregiverInfoFormGroup.get('country').clearValidators();

                    this.caregiverInfoFormGroup.get('address1').setValue('');
                    this.caregiverInfoFormGroup.get('state').setValue('');
                    this.caregiverInfoFormGroup.get('city').setValue('');
                    this.caregiverInfoFormGroup.get('zipCode').setValue('');
                    this.caregiverInfoFormGroup.get('country').setValue('');
                }
            }
        });
    }

    closeDialog(addCaregiver: boolean): void {
        if (addCaregiver) {
            if (this.caregiverInfoFormGroup.invalid) {
                this.caregiverInfoFormGroup.markAllAsTouched();
                if (this.editMode && !this.isNewCaregiver) {
                    buildSnackBar(collectErrors(this.caregiverInfoFormGroup), this.snackbarService);
                }
                this.showValidationMessage = true;
            } else {
                this.dialogRef.close(this.transformToCaregiverObj());
            }
        } else {
            this.dialogRef.close();
        }
    }

    transformToCaregiverObj(): Caregiver {
        let caregiver;

        if (this.isNewCaregiver) {
            caregiver = new Caregiver();
            caregiver.addressSameAsPatient = this.caregiverInfoFormGroup.get('addressSameAsPatient').value === 'true';
        } else {
            caregiver = this.selectedCaregiver;
            caregiver.addressSameAsPatient = this.caregiverInfoFormGroup.get('addressSameAsPatient').value === 'true';
        }
        caregiver.isPrimary = this.caregiverInfoFormGroup.get('isPrimary').value;
        caregiver.firstName = this.caregiverInfoFormGroup.get('firstName').value;
        caregiver.middleName = this.caregiverInfoFormGroup.get('middleName').value;
        caregiver.lastName = this.caregiverInfoFormGroup.get('lastName').value;
        caregiver.phone = this.parsePhoneNumber(this.caregiverInfoFormGroup.get('phoneNumber').value);
        caregiver.email = this.caregiverInfoFormGroup.get('email').value;
        caregiver.relationship = this.caregiverInfoFormGroup.get('relationshipToPatient').value;
        caregiver.preferredLang = this.caregiverInfoFormGroup.get('language').value;
        caregiver.gender = this.caregiverInfoFormGroup.get('gender').value;
        caregiver.birthDate = this.caregiverInfoFormGroup.get('birthDate').value;
        caregiver.details = this.caregiverInfoFormGroup.get('additionalDetails').value;
        caregiver.address1 = this.caregiverInfoFormGroup.get('address1').value;
        caregiver.address2 = this.caregiverInfoFormGroup.get('address2').value;
        caregiver.zipcode = this.caregiverInfoFormGroup.get('zipCode').value;
        caregiver.city = this.caregiverInfoFormGroup.get('city').value;
        caregiver.state = this.caregiverInfoFormGroup.get('state').value;
        caregiver.country = this.caregiverInfoFormGroup.get('country').value ?? '';
        return caregiver;
    }

    selectValue(event: any, target: string): void {
        if (target === 'isPrimary') {
            event.checked
                ? this.caregiverInfoFormGroup.get(target).setValue(true)
                : this.caregiverInfoFormGroup.get(target).setValue(false);
        } else {
            this.caregiverInfoFormGroup.get(target).setValue(event.value);
        }

        if (target === 'addressSameAsPatient' && event.value === 'false') {
            this.setEventListenerForAddressControl();
        }
    }

    typeAddress(id: string) {
        this.googleMapsService.setStyleForModal();
        this.googleMapsService.setActiveField(id);
    }

    shouldBePrimary() {
        //Strategy for selecting primary cargiver
        //1. First caregiver will always be primary by default, checkbox disabled
        //2. If checkbox is checked for any other caregiver, that caregiver becomes primary
        //3. If primary caregiver is removed, first caregiver on the list becomes primary

        if (this.isNewCaregiver && (!this.patient.caregivers || this.patient.caregivers.length === 0)) {
            //1. First Caregiver
            this.isPrimary = true;
            this.isDisabled = true;
        } else if (this.isNewCaregiver && !!this.patient.caregivers && this.patient.caregivers.length >= 1) {
            //2. Other Caregiver
            this.isPrimary = !this.patient.caregivers[0].isPrimary;
        } else if (!this.isNewCaregiver) {
            //If primary - cannot uncheck; If other - can become primary
            this.isPrimary = this.selectedCaregiver.isPrimary;
            this.isDisabled = this.isPrimary;
        }

        //set 'isPrimary' checkbox
        this.caregiverInfoFormGroup.controls.isPrimary.setValue(this.isPrimary);
        if (this.isDisabled) {
            this.caregiverInfoFormGroup.controls.isPrimary.disable();
        } else {
            this.caregiverInfoFormGroup.controls.isPrimary.enable();
        }
    }

    editCaregiver() {
        this.setValues();
        this.editMode = true;
        this.setEventListenerForAddressControl();
    }

    parsePhoneNumber(phoneNumber: string): string {
        return tryFormatPhoneNumberToE164(phoneNumber);
    }
}
