import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Moment } from 'moment';

export function timeRangeValidator(
    startDate: FormControl<Moment>,
    endDate: FormControl<Moment>,
    startTime: FormControl<Moment>,
    endTime: FormControl<Moment>
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!startDate.value || !startTime.value || !endDate.value || !endTime.value) return null;
        else if (
            startDate.value.isSame(endDate.value, 'day') &&
            (startTime.value.hour() > endTime.value.hour() ||
                (startTime.value.hour() === endTime.value.hour() && startTime.value.minute() > endTime.value.minute()))
        )
            return { invalidTime: 'Start time must be less than end time!' };
        else return null;
    };
}
