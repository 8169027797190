import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Moment } from 'moment';

export function dateRangeValidator(startDate: FormControl<Moment>, endDate: FormControl<Moment>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!startDate.value || !endDate.value) return null;
        else if (startDate.value.isSameOrBefore(endDate.value, 'day')) return null;
        else {
            return { invalidDate: 'Start date must be less than end date!' };
        }
    };
}

export function conditionalDateRangeValidator(
    startDate: FormControl<Moment>,
    endDate: FormControl<Moment>,
    condition: () => boolean
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!condition()) return null;
        if (!startDate.value || !endDate.value) return null;
        else if (startDate.value.isSameOrBefore(endDate.value, 'day')) return null;
        else {
            return { invalidDate: 'Start date must be less than end date!' };
        }
    };
}
