import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { EmittedEvent } from '@models/event';
import { InputChange } from '@models/event-objects/input-change';
import { Target } from '@models/event-objects/target';
import { Study, SupportTier } from '@models/study';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { EventService } from '@services/event/event.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { deepClone } from 'fast-json-patch';
import { Observable, Subject, combineLatest, map, of, takeUntil, tap } from 'rxjs';
import { StudyRadioButtons } from './../../../../models/study';

@Component({
    selector: 'medpace-study-tier',
    templateUrl: './study-tier.component.html',
    styleUrls: ['./study-tier.component.scss'],
})
export class StudyTierComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    parentFormGroup: FormGroup;
    @Input()
    isEditing: boolean;

    @Output() inputChangeEvent = new EventEmitter<InputChange>();

    radioButtonStudyTierFormGroup: FormGroup;
    private componentDestroyed$: Subject<boolean> = new Subject();
    study: Study;
    studyRadioButtons: MdsRadioButton[] = deepClone(StudyRadioButtons);
    loaderSpinner: boolean = true;

    canChangeToPaymentOnly$: Observable<boolean>;
    canChangeToPaymentOnlyMessage: string = '';
    isCanChangeToPaymentLoaded: boolean = true;
    canChangeToLimitedService$: Observable<boolean>;
    canChangeToLimitedServiceMessage =
        'Cannot choose Limited Service - Study has active flight or train travel requests';
    isCanChangeToLimitedLoaded: boolean = true;

    constructor(
        private studyStateService: StudyStateService,
        private formBuilder: FormBuilder,
        private eventService: EventService,
        private requestService: AdminRequestServices,
        private adminStudyService: AdminStudyServices
    ) {}

    ngOnInit(): void {
        this.studyStateService
            .getStudy()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((study) => {
                    if (study) {
                        this.initializeFormGroup();
                        this.study = study;
                        this.loaderSpinner = false;
                        this.setValuesRequestFormGroup();
                    }
                }),
                tap((study: Study) => {
                    if (!!study?.id) {
                        this.isCanChangeToPaymentLoaded = false;
                        this.isCanChangeToLimitedLoaded = false;
                        //setup canChangeToPaymentOnly$
                        this.canChangeToPaymentOnly$ = this.setupCanChangeToPaymentOnly(study.id);
                        //setup canChangeToLimitedService$
                        this.canChangeToLimitedService$ = this.setupCanChangeToLimitedService(study.id);
                    } else {
                        //new study or fallback
                        this.canChangeToPaymentOnly$ = of(true);
                        this.canChangeToLimitedService$ = of(true);
                    }
                })
            )
            .subscribe();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.radioButtonStudyTierFormGroup) {
            changes.isEditing?.currentValue
                ? this.radioButtonStudyTierFormGroup.enable()
                : this.radioButtonStudyTierFormGroup.disable();
        }
    }

    setupCanChangeToLimitedService(studyId: number): Observable<boolean> {
        return this.requestService.canFullServiceBeChangedToLimitedService(studyId).pipe(
            tap((canChange: boolean) => {
                if (!canChange) {
                    //disable Limited Service radio button
                    let limitedServiceButton = this.studyRadioButtons.find(
                        (button) => button.value === SupportTier.limitedService
                    );
                    limitedServiceButton.disabled = true;
                }
            }),
            tap(() => {
                this.isCanChangeToLimitedLoaded = true;
            })
        );
    }

    setupCanChangeToPaymentOnly(studyId: number): Observable<boolean> {
        return combineLatest([
            this.requestService.getTravelRequestCountFullService(studyId),
            this.adminStudyService.doesStudyHaveSiteWithTravelSupport(studyId),
        ]).pipe(
            //map travelRequestCount to boolean and pass hasSiteWithTravelSupport along
            map(([travelRequestCount, hasSiteWithTravelSupport]: [number, boolean]) => [
                travelRequestCount > 0,
                hasSiteWithTravelSupport,
            ]),
            tap(([hasTravelRequests, hasSiteWithTravelSupport]: boolean[]) => {
                const canChange = !hasTravelRequests && !hasSiteWithTravelSupport;
                if (!canChange) {
                    //disable Payment Only radio button
                    let paymentOnlyButton = this.studyRadioButtons.find(
                        (button) => button.value === SupportTier.payment
                    );
                    paymentOnlyButton.disabled = true;
                }
                //setup message
                if (hasTravelRequests && !hasSiteWithTravelSupport) {
                    this.canChangeToPaymentOnlyMessage =
                        'Cannot choose Payment Program - Study has active travel requests';
                } else if (!hasTravelRequests && hasSiteWithTravelSupport) {
                    this.canChangeToPaymentOnlyMessage =
                        'Cannot choose Payment Program - Study has sites with travel support';
                } else {
                    this.canChangeToPaymentOnlyMessage =
                        'Cannot choose Payment Program - Study has sites with travel support and active travel requests';
                }
            }),
            map(([hasTravelRequests, hasSiteWithTravelSupport]) => {
                //can change to payment if there are no travel requests and study has no site with Travel Support service
                return !hasTravelRequests && !hasSiteWithTravelSupport;
            }),
            tap(() => {
                this.isCanChangeToPaymentLoaded = true;
            })
        );
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    initializeFormGroup() {
        this.radioButtonStudyTierFormGroup = this.formBuilder.group({
            radioGroupControl: [SupportTier.fullService, Validators.required],
        });
        this.parentFormGroup.addControl('radioButtonStudyTierFormGroup', this.radioButtonStudyTierFormGroup);
        this.isEditing ? this.radioButtonStudyTierFormGroup.enable() : this.radioButtonStudyTierFormGroup.disable();
    }
    setValuesRequestFormGroup() {
        this.radioButtonStudyTierFormGroup.controls.radioGroupControl.patchValue(this.study.supportTier);
        this.eventService.emit(new EmittedEvent('tierChanged', this.study.supportTier));
    }

    emitChange: (value: SupportTier, target: Target) => void = (value: SupportTier, target: Target) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
        this.eventService.emit(new EmittedEvent('tierChanged', value));
    };

    objectKeys: (obj) => string[] = (obj) => {
        return Object.keys(obj);
    };
}
