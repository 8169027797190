import { AfterViewInit, Component, ElementRef, HostListener, inject, input, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { DateFormat } from '@models/date-format';
import {
    PatientVisitNameHistoryElement,
    VisitNameType,
} from '@models/patient-visit-name-history-element/patient-visit-name-history-element';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { utc } from 'moment';
import { BehaviorSubject, combineLatest, distinct, EMPTY, filter, map, of, switchMap, tap } from 'rxjs';

@Component({
    selector: 'medpace-history-timeline',
    templateUrl: './history-timeline.component.html',
    styleUrl: './history-timeline.component.scss',
})
export class HistoryTimelineComponent implements AfterViewInit {
    studyService: AdminStudyServices = inject(AdminStudyServices);

    patientId = input.required<number>();
    studyId = input.required<number>();

    patientId$ = toObservable(this.patientId).pipe(distinct());
    studyId$ = toObservable(this.studyId).pipe(distinct());

    private timelineSubject = new BehaviorSubject<ElementRef<HTMLDivElement>>(null);
    private timelineLineSubject = new BehaviorSubject<ElementRef<HTMLDivElement>>(null);

    @ViewChild('timeline') set timeline(value: ElementRef<HTMLDivElement>) {
        if (value) this.timelineSubject.next(value);
    }

    @ViewChild('timelineLine') set timelineLine(value: ElementRef<HTMLDivElement>) {
        if (value) this.timelineLineSubject.next(value);
    }

    studyVisitNamesWithPatientRequests$ = combineLatest([this.patientId$, this.studyId$]).pipe(
        switchMap(([patientId, studyId]) => {
            if (patientId && studyId)
                return this.studyService.getStudyVisitNamesWithPatientRequests(studyId, patientId);

            return EMPTY;
        }),
        map((visitNames) => {
            if (visitNames && visitNames?.length > 0)
                return visitNames.slice().sort((a, b) => b.sortOrder - a.sortOrder);

            return visitNames;
        })
    );

    patientVisitNameTimelineElements$ = this.studyVisitNamesWithPatientRequests$.pipe(
        switchMap((visitNamesWithRequests) => {
            let timelineElements: PatientVisitNameHistoryElement[] = [];
            let setToPrevious = false;

            visitNamesWithRequests?.map((visitName) => {
                let timelineElement: PatientVisitNameHistoryElement = {
                    sortOrder: visitName.sortOrder,
                    sortOrderDate: visitName.sortOrderDate ? utc(visitName.sortOrderDate).toDate() : null,
                    requestCount: visitName.requestCount,
                    visitName: visitName.visitName,
                    visitActualDate: visitName.actualDate ? utc(visitName.actualDate).toDate() : null,
                    visitScheduledDate: visitName.scheduledDate ? utc(visitName.scheduledDate).toDate() : null,
                    visitNameType: VisitNameType.IN_FEATURE,
                };
                if (visitName.scheduled) {
                    if (
                        !setToPrevious &&
                        visitName.scheduled === true &&
                        (!!timelineElement.visitActualDate === false ||
                            (!!timelineElement.visitActualDate && timelineElement.visitActualDate > new Date()))
                    ) {
                        timelineElements.push(timelineElement);
                    } else if (
                        !!timelineElement.visitActualDate === true ||
                        (!!timelineElement.visitActualDate === false && visitName.scheduled)
                    ) {
                        setToPrevious = true;
                        timelineElement.visitNameType = VisitNameType.COMPLETED;
                        timelineElements.push(timelineElement);
                    }
                }
            });

            timelineElements = timelineElements.sort((a, b) => {
                if (a.sortOrderDate && b.sortOrderDate) {
                    if (b.sortOrderDate < a.sortOrderDate) return 0;
                    return -1;
                } else {
                    if (b.sortOrder < a.sortOrder) {
                        return 0;
                    }
                    return -1;
                }
            });

            for (let i = 0; i < timelineElements?.length; i++) {
                if (timelineElements[i].visitNameType === VisitNameType.IN_FEATURE) {
                    timelineElements[i].visitNameType = VisitNameType.NEXT;
                    this.nextVisitIndex = i;
                    break;
                }
            }

            if (!this.nextVisitIndex) this.nextVisitIndex = 0;

            return of(timelineElements);
        })
    );

    numberOfElements$ = combineLatest({
        timeline: this.timelineSubject.asObservable().pipe(filter(Boolean)),
        timelineLine: this.timelineLineSubject.asObservable().pipe(filter(Boolean)),
        numberOfElements: this.patientVisitNameTimelineElements$.pipe(map((visitNames) => visitNames.length)),
    }).pipe(
        tap(({ numberOfElements, timeline, timelineLine }) => {
            this.numberOfElements = numberOfElements;
            this.updateDimensions();

            if (this.isTimelineReady()) {
                this.currentTranslate -=
                    (this.timelineSubject.getValue().nativeElement.parentElement.offsetWidth / 5) *
                    (this.nextVisitIndex - 2);
                this.prevTranslate = this.currentTranslate;
                this.setTimelinePosition();
            }
        })
    );

    ngAfterViewInit(): void {
        this.numberOfElements$.subscribe();
    }

    numberOfElements: number = 0;
    isDragging = false;
    startX = 0;
    currentTranslate = 0;
    prevTranslate = 0;
    animationId: number;
    timelineWidth: number;
    containerWidth: number;
    nextVisitIndex: number;
    nextArrowDisabled: boolean = false;
    prevArrowDisabled: boolean = false;

    onMouseUp(event: MouseEvent) {
        if (this.isTimelineReady()) {
            this.isDragging = false;
            cancelAnimationFrame(this.animationId);
            this.prevTranslate = this.currentTranslate;
            this.timelineSubject.getValue().nativeElement.classList.remove('dragging');
        }
    }
    onMouseMove(event: MouseEvent) {
        if (this.isTimelineReady() && this.isDragging) {
            const currentPosition = event.pageX - this.startX;
            this.currentTranslate = currentPosition;
            this.setTimelinePosition();
        }
    }
    onMouseDown(event: MouseEvent) {
        if (this.isTimelineReady()) {
            this.isDragging = true;
            this.startX = event.pageX - this.prevTranslate;
            this.timelineSubject.getValue().nativeElement.classList.add('dragging');
            this.animationId = requestAnimationFrame(this.animate.bind(this));
        }
    }

    animate() {
        if (this.isDragging) {
            this.timelineSubject.getValue().nativeElement.style.transform = `translateX(${this.currentTranslate}px)`;
            requestAnimationFrame(this.animate.bind(this));
        }
    }

    canEnableNavigation() {
        if (this.numberOfElements <= 5) {
            this.nextArrowDisabled = true;
            this.prevArrowDisabled = true;
            return false;
        }
        return true;
    }

    setTimelinePosition() {
        this.nextArrowDisabled = false;
        this.prevArrowDisabled = false;

        const maxTranslate = 0;
        const minTranslate = this.containerWidth - this.timelineWidth;

        this.nextArrowDisabled = false;
        this.prevArrowDisabled = false;
        if (this.currentTranslate >= maxTranslate) {
            this.currentTranslate = maxTranslate;
            this.prevArrowDisabled = true;
        } else if (this.currentTranslate <= minTranslate + 1) {
            this.currentTranslate = minTranslate;
            this.nextArrowDisabled = true;
        }

        this.timelineSubject.getValue().nativeElement.style.transform = `translateX(${this.currentTranslate}px)`;
    }

    isTimelineReady(): boolean {
        let ready = false;

        let timeline = this.timelineSubject.getValue();
        let timelineLine = this.timelineLineSubject.getValue();

        if (timeline && timeline.nativeElement && timelineLine && timelineLine.nativeElement) ready = true;

        return ready && this.canEnableNavigation();
    }

    @HostListener('window:resize')
    onResize() {
        this.updateDimensions();
        this.setTimelinePosition();
    }

    updateDimensions() {
        if (this.isTimelineReady()) {
            this.containerWidth = this.timelineSubject.getValue().nativeElement.parentElement.offsetWidth;

            this.timelineWidth = this.timelineLineSubject.getValue().nativeElement.offsetWidth;
        }
    }

    scrollNext() {
        if (this.isTimelineReady()) {
            this.currentTranslate -= this.timelineSubject.getValue().nativeElement.parentElement.offsetWidth / 5;
            this.prevTranslate = this.currentTranslate;
            this.setTimelinePosition();
        }
    }

    scrollPrev() {
        if (this.isTimelineReady()) {
            this.currentTranslate += this.timelineSubject.getValue().nativeElement.parentElement.offsetWidth / 5;
            this.prevTranslate = this.currentTranslate;
            this.setTimelinePosition();
        }
    }

    getBackgroundColorClass(visitName: PatientVisitNameHistoryElement): string {
        return Object.keys(VisitNameType)
            .find((key) => VisitNameType[key] === visitName.visitNameType)
            ?.toLowerCase();
    }

    setHeight(visitName: PatientVisitNameHistoryElement) {
        let height = 16;
        if (!visitName.visitScheduledDate) {
            height += 16;
        }
        if (!visitName.visitActualDate) {
            height += 16;
        }
        return height + 'px';
    }

    dateOnly = DateFormat.dateOnly;
}
