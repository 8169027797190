import { AddressDataModel } from '../../models/address';

export function transformToAddressString(address: AddressDataModel): string {
    return (
        address.address1 +
        (address.address2 ? ' ' + address.address2 : '') +
        (address.city ? ' ' + address.city : '') +
        (address.state ? ' ' + address.state : '') +
        (address.zipcode ? ' ' + address.zipcode : '')
    );
}
