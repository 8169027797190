import { RequestSummary } from '@models/request';

export function sortRequestByStatus(data: RequestSummary[]) {
    data?.sort((x, y) => {
        const xDateTime: number = new Date(x?.requestDate).getTime();
        const yDateTime: number = new Date(y?.requestDate).getTime();
        const xStatus: string = statusDefaultSortOrder[x?.status];
        const yStatus: string = statusDefaultSortOrder[y?.status];

        if (xStatus > yStatus) return 1;
        if (xStatus < yStatus) return -1;
        if (xStatus === yStatus) {
            return xDateTime < yDateTime ? 1 : -1;
        }

        return 1;
    });
}

export const statusDefaultSortOrder = {
    Draft: 1,
    Submitted: 2,
    'In Process': 3,
    'Pending Set-Up': 4,
    'Pending Approval': 5,
    Completed: 6,
    Denied: 7,
    Cancelled: 8,
};
