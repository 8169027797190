/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { LodgingHistoryData } from '@models/history-data/lodgingHistoryData';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, getUniqueObjects } from '@utility/utility';
import { equalOrMoreThanTodayOnlyIfCRCDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment } from 'moment';
export class LodgingRequestDetailsFormGroup extends PersistentFormGroup<{
    checkInDate: PersistentFormControl<Moment>;
    checkOutDate: PersistentFormControl<Moment>;
    specialRequests: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<LodgingRequestDetailsFormGroup>): LodgingRequestDetailsFormGroup {
        return new LodgingRequestDetailsFormGroup(
            {
                checkInDate: new PersistentFormControl(value.checkInDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                checkOutDate: new PersistentFormControl(value.checkOutDate, [
                    Validators.required,
                    equalOrMoreThanTodayOnlyIfCRCDateValidator,
                ]),
                specialRequests: new PersistentFormControl(value.specialRequests, [maxLengthValidator(1000)]),
            },
            [
                (control: AbstractControl<LodgingRequestDetailsFormGroup['value']>) => {
                    const value = control.value;
                    if (!value.checkInDate || !value.checkOutDate) return null;
                    return value.checkInDate <= value.checkOutDate
                        ? null
                        : {
                              invalidDates: 'Check-out date cannot be before check-in',
                          };
                },
            ]
        );
    }
}
@Component({
    selector: 'medpace-lodging-request-details',
    templateUrl: './lodging-request-details.component.html',
    styleUrls: ['./lodging-request-details.component.scss'],
})
export class LodgingRequestDetailsComponent {
    @Input() mode: EditableCardMode;
    @Input() formGroup: LodgingRequestDetailsFormGroup;
    @Input() set historyData(value: LodgingHistoryData[]) {
        // every component should create its own MdsOptions so they dont share values
        this.autocompleteOptions = {
            specialRequests: getUniqueObjects(
                value
                    .filter((data) => !!data.specialRequest)
                    .map(
                        (data) =>
                            <MedpaceAutocompleteObject>{ requestDate: data.requestDate, value: data.specialRequest }
                    )
            ),
        };
    }
    protected autocompleteOptions: {
        specialRequests: MedpaceAutocompleteObject[];
    };
}
