import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DownloadAttachmentModalComponent } from '@app/admin/manage/modals/download-attachment-modal/download-attachment-modal.component';
import { TravelType } from '@app/enums/travel-type.enum';
import { AlertData } from '@components/atoms/alert/alert.component';
import {
    EditableCardComponent,
    EditableCardMode,
} from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { CountryViewModel } from '@models/country';
import { CurrencyViewModel } from '@models/currency';
import { CarServiceHistoryData } from '@models/history-data/carServiceHistoryData';
import { FlightHistoryData } from '@models/history-data/flightHistoryData';
import { LodgingHistoryData } from '@models/history-data/lodgingHistoryData';
import { OtherHistoryData } from '@models/history-data/otherHistoryData';
import { RentalCarHistoryData } from '@models/history-data/rentalCarHistoryData';
import { TrainHistoryData } from '@models/history-data/trainHistoryData';
import { Caregiver } from '@models/patient';
import { GenericRequest, GenericRequestStatus, TrainTravelType } from '@models/request';
import { Study, StudyTravelNames, StudyTravelOption, SupportTier } from '@models/study';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, MdsOptionGeneric, buildSnackBar, collectErrors } from '@utility/utility';
import { Moment } from 'moment';
import { Observable, combineLatest, defer, map, startWith, switchMap } from 'rxjs';
import { CarRentalPreferencesFormGroup } from './car-rental-request-details/car-rental-preferences/car-rental-preferences.component';
import { CarRentalRequestDetailsFormGroup } from './car-rental-request-details/car-rental-request-details.component';
import { FlightPreferencesFormGroup } from './flight-request-details/flight-preferences/flight-preferences.component';
import { FlightRequestDetailsFormGroup } from './flight-request-details/flight-request-details.component';
import { LodgingPreferencesFormGroup } from './lodging-request-details/lodging-preferences/lodging-preferences.component';
import { LodgingRequestDetailsFormGroup } from './lodging-request-details/lodging-request-details.component';
import { OtherRequestDetailsFormGroup } from './other-request-details/other-request-details.component';
import { TrainPreferencesFormGroup } from './train-request-details/train-preferences/train-preferences.component';
import { TrainRequestDetailsFormGroup } from './train-request-details/train-request-details.component';
import { TransactionDetailsFormGroup } from './transaction-details/transaction-details.component';
import { TransferPreferencesFormGroup } from './transfer-request-details/transfer-preferences/transfer-preferences/transfer-preferences.component';
import { TransferRequestDetailsFormGroup } from './transfer-request-details/transfer-request-details.component';
export interface BookableCaregiver {
    caregiver: Caregiver;
    bookable: boolean;
}
export class CheckboxFormGroup<T> extends PersistentFormGroup<{
    checked: FormControl<boolean>;
    value: FormControl<T>;
}> {}

export class TravelCardFormGroup extends FormGroup<{
    accompanyingCaregivers: PersistentFormGroup<{
        primaryCaregiver: CheckboxFormGroup<BookableCaregiver>;
        otherCaregivers: FormArray<CheckboxFormGroup<BookableCaregiver>>;
        none: CheckboxFormGroup<null>;
    }>;
    travelDetails: FormGroup<{
        needsAirTravel: FormControl<boolean>;
        flightPreferences: FlightPreferencesFormGroup;
        flightDetails: FlightRequestDetailsFormGroup;
        flightTransactionDetails: TransactionDetailsFormGroup;

        needsTrainTravel: FormControl<boolean>;
        trainPreferences: TrainPreferencesFormGroup;
        trainDetails: TrainRequestDetailsFormGroup;
        trainTransactionDetails: TransactionDetailsFormGroup;

        needsLodging: FormControl<boolean>;
        lodgingPreferences: LodgingPreferencesFormGroup;
        lodgingDetails: LodgingRequestDetailsFormGroup;
        lodgingTransactionDetails: TransactionDetailsFormGroup;

        needsCarRental: FormControl<boolean>;
        carRentalPreferences: CarRentalPreferencesFormGroup;
        carRentalDetails: CarRentalRequestDetailsFormGroup;
        carRentalTransactionDetails: TransactionDetailsFormGroup;

        needsTransfer: FormControl<boolean>;
        transferPreferences: TransferPreferencesFormGroup;
        transferDetails: TransferRequestDetailsFormGroup;
        transferTransactionDetails: TransactionDetailsFormGroup;

        needsOther: FormControl<boolean>;
        otherDetails: OtherRequestDetailsFormGroup;
        otherTransactionDetails: TransactionDetailsFormGroup;
    }>;
}> {
    public static create(value: FormGroupValueOf<TravelCardFormGroup>): TravelCardFormGroup {
        return new TravelCardFormGroup({
            accompanyingCaregivers: new PersistentFormGroup(
                {
                    primaryCaregiver: new CheckboxFormGroup({
                        checked: new FormControl(value.accompanyingCaregivers.primaryCaregiver.checked),
                        value: new FormControl(value.accompanyingCaregivers.primaryCaregiver.value),
                    }),
                    otherCaregivers: new FormArray(
                        value.accompanyingCaregivers.otherCaregivers.map(
                            (caregiver) =>
                                new CheckboxFormGroup({
                                    checked: new FormControl(caregiver.checked),
                                    value: new FormControl(caregiver.value),
                                })
                        )
                    ),
                    none: new CheckboxFormGroup({
                        checked: new FormControl(value.accompanyingCaregivers.none.checked),
                        value: new FormControl(value.accompanyingCaregivers.none.value),
                    }),
                },
                (control: AbstractControl): ValidationErrors | null => {
                    const typedControl = control as TravelCardFormGroup['controls']['accompanyingCaregivers'];
                    const value = typedControl.getRawValue();
                    if ([value.primaryCaregiver, ...value.otherCaregivers, value.none].some((v) => v.checked))
                        return null;
                    else return { requiredAtLeastOne: 'At least one option is required' };
                }
            ),
            travelDetails: new FormGroup(
                {
                    // Flight tab
                    needsAirTravel: new FormControl(value.travelDetails.needsAirTravel, [Validators.required]),
                    flightDetails: FlightRequestDetailsFormGroup.create(value.travelDetails.flightDetails),
                    flightTransactionDetails: TransactionDetailsFormGroup.create(
                        value.travelDetails.flightTransactionDetails
                    ),
                    flightPreferences: FlightPreferencesFormGroup.create(value.travelDetails.flightPreferences),

                    // Train tab
                    needsTrainTravel: new FormControl(value.travelDetails.needsTrainTravel, [Validators.required]),
                    trainDetails: TrainRequestDetailsFormGroup.create(value.travelDetails.trainDetails),
                    trainTransactionDetails: TransactionDetailsFormGroup.create(
                        value.travelDetails.trainTransactionDetails
                    ),
                    trainPreferences: TrainPreferencesFormGroup.create(value.travelDetails.trainPreferences),

                    // Hotel tab
                    needsLodging: new FormControl(value.travelDetails.needsLodging, [Validators.required]),
                    lodgingDetails: LodgingRequestDetailsFormGroup.create(value.travelDetails.lodgingDetails),
                    lodgingTransactionDetails: TransactionDetailsFormGroup.create(
                        value.travelDetails.lodgingTransactionDetails
                    ),
                    lodgingPreferences: LodgingPreferencesFormGroup.create(value.travelDetails.lodgingPreferences),

                    // Car Rental tab
                    needsCarRental: new FormControl(value.travelDetails.needsCarRental, [Validators.required]),
                    carRentalDetails: CarRentalRequestDetailsFormGroup.create(value.travelDetails.carRentalDetails),
                    carRentalTransactionDetails: TransactionDetailsFormGroup.create(
                        value.travelDetails.carRentalTransactionDetails
                    ),
                    carRentalPreferences: CarRentalPreferencesFormGroup.create(
                        value.travelDetails.carRentalPreferences
                    ),

                    // Transfer / Car Service tab
                    needsTransfer: new FormControl(value.travelDetails.needsTransfer, [Validators.required]),
                    transferPreferences: TransferPreferencesFormGroup.create(value.travelDetails.transferPreferences),
                    transferDetails: TransferRequestDetailsFormGroup.create(value.travelDetails.transferDetails),
                    transferTransactionDetails: TransactionDetailsFormGroup.create(
                        value.travelDetails.transferTransactionDetails
                    ),

                    // Other tab
                    needsOther: new FormControl(value.travelDetails.needsOther, [Validators.required]),
                    otherDetails: OtherRequestDetailsFormGroup.create(value.travelDetails.otherDetails),
                    otherTransactionDetails: TransactionDetailsFormGroup.create(
                        value.travelDetails.otherTransactionDetails
                    ),
                },
                [
                    (control: AbstractControl): ValidationErrors | null => {
                        const typedControl = control as TravelCardFormGroup['controls']['travelDetails'];
                        const value = typedControl.getRawValue();
                        if (
                            value.needsAirTravel ||
                            value.needsTrainTravel ||
                            value.needsLodging ||
                            value.needsCarRental ||
                            value.needsTransfer ||
                            value.needsOther
                        )
                            return null;
                        else {
                            return { requiredAtLeastOneTravelOption: 'At least one travel option is required.' };
                        }
                    },
                    (control: AbstractControl): ValidationErrors | null => {
                        const typedControl = control as TravelCardFormGroup['controls']['travelDetails'];
                        const value = typedControl.getRawValue();

                        // needs air is checked, but other fields are empty/invalid
                        const flightTabInvalid =
                            value.needsAirTravel &&
                            (!typedControl.controls.flightDetails.valid ||
                                !typedControl.controls.flightPreferences.valid ||
                                !typedControl.controls.flightTransactionDetails.valid);

                        // needs train is checked, but other fields are empty/invalid
                        const trainTabInvalid =
                            value.needsTrainTravel &&
                            (!typedControl.controls.trainDetails.valid ||
                                !typedControl.controls.trainPreferences.valid ||
                                !typedControl.controls.trainTransactionDetails.valid);

                        const lodgingTabInvalid =
                            value.needsLodging &&
                            (!typedControl.controls.lodgingDetails.valid ||
                                !typedControl.controls.lodgingPreferences.valid ||
                                !typedControl.controls.lodgingTransactionDetails.valid);

                        const carRentalTabInvalid =
                            value.needsCarRental &&
                            (!typedControl.controls.carRentalDetails.valid ||
                                !typedControl.controls.carRentalPreferences.valid ||
                                !typedControl.controls.carRentalTransactionDetails.valid);

                        const transferTabInvalid =
                            value.needsTransfer &&
                            (!typedControl.controls.transferDetails.valid ||
                                !typedControl.controls.transferPreferences.valid ||
                                !typedControl.controls.transferTransactionDetails.valid);

                        const otherTabInvalid =
                            value.needsOther &&
                            (!typedControl.controls.otherDetails.valid ||
                                !typedControl.controls.otherTransactionDetails.valid);

                        if (
                            flightTabInvalid ||
                            trainTabInvalid ||
                            lodgingTabInvalid ||
                            carRentalTabInvalid ||
                            transferTabInvalid ||
                            otherTabInvalid
                        ) {
                            const invalidTabsNames = [];
                            if (flightTabInvalid) invalidTabsNames.push('Flight');
                            if (trainTabInvalid) invalidTabsNames.push('Train');
                            if (lodgingTabInvalid) invalidTabsNames.push('Lodging');
                            if (carRentalTabInvalid) invalidTabsNames.push('Rental Car');
                            if (transferTabInvalid) invalidTabsNames.push('Car Service');
                            if (otherTabInvalid) invalidTabsNames.push('Other');

                            return {
                                travelInfoMissing: `Travel information missing in tabs: ${invalidTabsNames.join(', ')}. Please make sure that all tabs are filled out correctly `,
                            };
                        } else {
                            return null;
                        }
                    },
                ]
            ),
        });
    }
}
export type Dates = {
    startDate: Moment;
    endDate: Moment;
    tabName: string;
    isOneWay: boolean;
};
@Component({
    selector: 'travel-card',
    templateUrl: './travel-card.component.html',
    styleUrls: ['./travel-card.component.scss'],
})
export class TravelCardComponent extends EditableCardComponent<FormGroupValueOf<TravelCardFormGroup>> {
    public GenericRequestStatus = GenericRequestStatus;
    @Input() mode: EditableCardMode;
    @Input() showSwitchModeButton: boolean;
    @Input() showBookButton: boolean;
    @Input() formGroup: TravelCardFormGroup;
    @Input() timeOptions: MdsOptionGeneric<number>[];
    @Input() countryOptions: MdsOptionGeneric<CountryViewModel>[];
    get countryOptionsStringArray() {
        return this.countryOptions?.map((country) => country.viewValue);
    }
    @Input() currencyOptions: MdsOptionGeneric<CurrencyViewModel>[];
    @Input() isLoading: boolean;
    @Input() historyData: {
        car: CarServiceHistoryData[];
        flight: FlightHistoryData[];
        lodging: LodgingHistoryData[];
        other: OtherHistoryData[];
        carRental: RentalCarHistoryData[];
        train: TrainHistoryData[];
    };
    @Input() isUpdate: boolean;
    @Input() request: GenericRequest;
    @Output() bookViaGetThereForPatient = new EventEmitter<void>();
    @Output() bookViaGetThereForCaregiver = new EventEmitter<Caregiver>();
    private adminRequestServices = inject(AdminRequestServices);
    private dialog = inject(MatDialog);
    private studyStateService = inject(StudyStateService);

    study$ = this.studyStateService.getStudy();
    // observable returning departure and return dates from FlightTab whenever something changes in it
    private flightDates$: Observable<Dates> = defer(() => {
        const value = this.formGroup.controls.travelDetails.value;
        const needsAirTravel = value.needsAirTravel;
        const travelType = value.flightDetails?.patientFlightDetails.travelType;
        const departureDate = value.flightDetails?.patientFlightDetails.departureDate;
        const returnDate = value.flightDetails?.patientFlightDetails.returnDate;
        return combineLatest({
            needsAirTravel: this.formGroup.controls.travelDetails.controls.needsAirTravel.valueChanges.pipe(
                startWith(needsAirTravel)
            ),
            travelType:
                this.formGroup.controls.travelDetails.controls.flightDetails.controls.patientFlightDetails.controls.travelType.valueChanges.pipe(
                    startWith(travelType)
                ),
            departureDate:
                this.formGroup.controls.travelDetails.controls.flightDetails.controls.patientFlightDetails.controls.departureDate.valueChanges.pipe(
                    startWith(departureDate)
                ),
            returnDate:
                this.formGroup.controls.travelDetails.controls.flightDetails.controls.patientFlightDetails.controls.returnDate.valueChanges.pipe(
                    startWith(returnDate)
                ),
        }).pipe(
            map((value) => {
                const dates: Dates = { startDate: null, endDate: null, tabName: 'Flight', isOneWay: null };
                if (!value.needsAirTravel) return null;
                else if (value.travelType === TravelType.ONE_WAY) {
                    dates.startDate = value.departureDate;
                    dates.endDate = null;
                    dates.isOneWay = true;
                } else if (value.travelType === TravelType.ROUND_TRIP) {
                    dates.startDate = value.departureDate;
                    dates.endDate = value.returnDate;
                    dates.isOneWay = false;
                } else return null;

                return dates;
            })
        );
    });
    // observable returning departure and return dates from TrainTab whenever something changes in it
    private trainDates$: Observable<Dates> = defer(() => {
        const value = this.formGroup.controls.travelDetails.value;
        const needsTrainTravel = value.needsTrainTravel;
        const travelType = value.trainDetails?.patientTrainTripDetails.travelType;
        const departureDate = value.trainDetails?.patientTrainTripDetails.departureDate;
        const returnDate = value.trainDetails?.patientTrainTripDetails.returnDate;
        return combineLatest({
            needsTrainTravel: this.formGroup.controls.travelDetails.controls.needsTrainTravel.valueChanges.pipe(
                startWith(needsTrainTravel)
            ),
            travelType:
                this.formGroup.controls.travelDetails.controls.trainDetails.controls.patientTrainTripDetails.controls.travelType.valueChanges.pipe(
                    startWith(travelType)
                ),
            departureDate:
                this.formGroup.controls.travelDetails.controls.trainDetails.controls.patientTrainTripDetails.controls.departureDate.valueChanges.pipe(
                    startWith(departureDate)
                ),
            returnDate:
                this.formGroup.controls.travelDetails.controls.trainDetails.controls.patientTrainTripDetails.controls.returnDate.valueChanges.pipe(
                    startWith(returnDate)
                ),
        }).pipe(
            map((value) => {
                const dates: Dates = { startDate: null, endDate: null, tabName: 'Train', isOneWay: null };
                if (!value.needsTrainTravel) return null;
                else if (value.travelType === TrainTravelType.OneWay) {
                    dates.startDate = value.departureDate;
                    dates.endDate = null;
                    dates.isOneWay = true;
                } else if (value.travelType === TrainTravelType.RoundTrip) {
                    dates.startDate = value.departureDate;
                    dates.endDate = value.returnDate;
                    dates.isOneWay = false;
                } else return null;

                return dates;
            })
        );
    });
    // observable returning checkIn and checkOut dates from LodgingTab whenever something changes in it
    private lodgingDates$: Observable<Dates> = defer(() => {
        const value = this.formGroup.controls.travelDetails.value;
        const needsLodging = value.needsLodging;
        const checkInDate = value.lodgingDetails?.checkInDate;
        const checkOutDate = value.lodgingDetails?.checkOutDate;
        return combineLatest({
            needsLodging: this.formGroup.controls.travelDetails.controls.needsLodging.valueChanges.pipe(
                startWith(needsLodging)
            ),
            checkInDate:
                this.formGroup.controls.travelDetails.controls.lodgingDetails.controls.checkInDate.valueChanges.pipe(
                    startWith(checkInDate)
                ),
            checkOutDate:
                this.formGroup.controls.travelDetails.controls.lodgingDetails.controls.checkOutDate.valueChanges.pipe(
                    startWith(checkOutDate)
                ),
        }).pipe(
            map((value) => {
                if (!value.needsLodging) return null;
                else
                    return <Dates>{
                        tabName: 'Lodging',
                        startDate: value.checkInDate,
                        endDate: value.checkOutDate,
                        isOneWay: false,
                    };
            })
        );
    });
    // observable returning pickup and dropOff dates from TrainTab whenever something changes in it
    private carRentalDates$: Observable<Dates> = defer(() => {
        const value = this.formGroup.controls.travelDetails.value;
        const needsTravel = value.needsCarRental;
        const startDate = value.carRentalDetails?.pickupDate;
        const endDate = value.carRentalDetails?.dropOffDate;
        return combineLatest({
            needsTravel: this.formGroup.controls.travelDetails.controls.needsCarRental.valueChanges.pipe(
                startWith(needsTravel)
            ),
            startDate:
                this.formGroup.controls.travelDetails.controls.carRentalDetails.controls.pickupDate.valueChanges.pipe(
                    startWith(startDate)
                ),
            endDate:
                this.formGroup.controls.travelDetails.controls.carRentalDetails.controls.dropOffDate.valueChanges.pipe(
                    startWith(endDate)
                ),
        }).pipe(
            map((value) => {
                if (!value.needsTravel) return null;
                else
                    return <Dates>{
                        tabName: 'Rental Car',
                        startDate: value.startDate,
                        endDate: value.endDate,
                        isOneWay: false,
                    };
            })
        );
    });
    // observable returning either flight or train dates, combined as 'travelDates'
    private travelDates$ = combineLatest({
        flight: this.flightDates$,
        train: this.trainDates$,
    }).pipe(
        map((result) => {
            // in cases when only Flight or both Flight and Train are needed return Flight as the more important one, else return Train
            return !!result.flight ? result.flight : result.train;
        })
    );
    // will accept an array of Dates objects and filter those that have their startDate and endDate duplicated in the array. Will skip empty dates
    private filterDates(dates: Dates[]): Dates[] {
        const result: Dates[] = [];
        for (let i = 0; i < dates.length - 1; i++) {
            for (let j = i + 1; j < dates.length; j++) {
                // compare one Date against all other Dates in the array
                const dateA = dates[i];
                const dateB = dates[j];
                const skipStartDate = !dateA.startDate || !dateB.startDate; // skip if empty values, they do not trigger the warning
                const skipEndDate = !dateA.endDate || !dateB.endDate;
                const identicalStartDate =
                    !!dateA.startDate && !!dateB.startDate && dateA.startDate.isSame(dateB.startDate, 'day');
                const identicalEndDate =
                    !!dateA.endDate && !!dateB.endDate && dateA.endDate.isSame(dateB.endDate, 'day');
                const identicalDatesOrSkip = (skipStartDate || identicalStartDate) && (skipEndDate || identicalEndDate);
                if (!identicalDatesOrSkip) {
                    // those Dates are not empty and do not have duplicates in the array - they will trigger the warning
                    if (!result.includes(dateA)) result.push(dateA);
                    if (!result.includes(dateB)) result.push(dateB);
                }
            }
        }
        return result;
    }
    private differentTravelDates$: Observable<AlertData> = defer(() =>
        combineLatest({
            travelDates: this.travelDates$,
            lodgingDates: this.lodgingDates$,
            carRentalDates: this.carRentalDates$,
        }).pipe(
            map((dates) => {
                let differentDates = Object.values(dates) // grab the dates as an array
                    .filter((date) => !!date) // it's null if it's set to 'No' - travel option not needed
                    .filter((date) => !!date.startDate || !!date.endDate); // at least one date is not empty

                differentDates = this.filterDates(differentDates); // remove duplicates and empty values, leaving only those tabs that trigger a warning
                let content: string = '';
                if (differentDates.length > 0) {
                    const differentDatesTabNames = differentDates.map((date) => date.tabName);
                    // this will turn tabNames into a readable using commas and 'and' word, to a string like 'Flight, Lodging and Rental Car' or 'Flight and Rental Car'
                    const differentDatesAsString =
                        differentDatesTabNames.length > 2
                            ? `${differentDatesTabNames.slice(0, -2).join(', ')}, ${differentDatesTabNames
                                  .slice(-2)
                                  .join(' and ')}`
                            : differentDatesTabNames.length === 2
                              ? `${differentDatesTabNames.slice(-2).join(' and ')}`
                              : differentDatesTabNames.join('');

                    content =
                        !this.isUpdate || this.request.status === GenericRequestStatus.Draft
                            ? `The selected dates are different for ${differentDatesAsString}. Please confirm that the dates are correct.`
                            : `The selected dates are different for ${differentDatesAsString}.`;
                }
                return <AlertData>{ content: content, type: 'warning' };
            })
        )
    );

    isLimitedService(study: Study): boolean {
        return study.supportTier === SupportTier.limitedService;
    }

    protected alerts$ = combineLatest({ differentTravelDates: this.differentTravelDates$ }).pipe(
        map((alerts) => Object.values(alerts).filter((alert) => !!alert.content))
    );
    getAccompanyingFlightCaregivers() {
        const value = this.formGroup.getRawValue();
        const accompanyingCaregivers = [
            value.accompanyingCaregivers.primaryCaregiver,
            ...value.accompanyingCaregivers.otherCaregivers,
        ]
            .filter((c) => c.checked)
            .map((c) => c.value.caregiver);
        return this.formGroup.controls.travelDetails.controls.flightDetails.controls.caregivers.controls.filter(
            (control) => {
                const controlValue = control.getRawValue();
                return (
                    (controlValue.details.bookIdenticalTrip ||
                        controlValue.details.flightDetails.travelType !== TravelType.NO_FLIGHT) &&
                    accompanyingCaregivers.some((caregiver) => caregiver.id === controlValue.caregiver.id)
                );
            }
        );
    }
    getAccompanyingTrainCaregivers() {
        const value = this.formGroup.getRawValue();
        const accompanyingCaregivers = [
            value.accompanyingCaregivers.primaryCaregiver,
            ...value.accompanyingCaregivers.otherCaregivers,
        ]
            .filter((c) => c.checked)
            .map((c) => c.value.caregiver);
        return this.formGroup.controls.travelDetails.controls.trainDetails.controls.caregivers.controls.filter(
            (control) => {
                const controlValue = control.getRawValue();
                return (
                    (controlValue.details.bookIdenticalTrip ||
                        controlValue.details.trainDetails.travelType !== TrainTravelType.NoTravel) &&
                    accompanyingCaregivers.some((caregiver) => caregiver.id === controlValue.caregiver.id)
                );
            }
        );
    }
    getOutputData() {
        return this.formGroup.getRawValue();
    }
    protected override canChangeMode(nextMode: EditableCardMode) {
        return nextMode === 'edit' || (nextMode === 'view' && this.formGroup.valid);
    }
    protected onModeChangeSuccess(mode: EditableCardMode) {
        mode === 'edit' ? this.formGroup.enable({ emitEvent: false }) : this.formGroup.disable({ emitEvent: false });
    }
    protected onModeChangeFail(mode: EditableCardMode) {
        this.formGroup.markAllAsTouched();
        buildSnackBar(collectErrors(this.formGroup), this.snackbarService);
    }
    bookViaGetthereClickPatient() {
        this.bookViaGetThereForPatient.emit();
    }
    bookViaGetthereClickCaregiver(caregiver: Caregiver) {
        this.bookViaGetThereForCaregiver.emit(caregiver);
    }
    getCaregiverFullName(caregiver: Caregiver) {
        return !caregiver
            ? ''
            : [caregiver.firstName, caregiver.middleName, caregiver.lastName].filter((name) => !!name).join(' ');
    }
    protected downloadAttachment(attachmentInfo: { attachmentId: number; attachmentName: string }) {
        this.adminRequestServices
            .getTravelRequestAttachmentUri(this.request.id, attachmentInfo.attachmentId)
            .pipe(
                switchMap((url) =>
                    this.dialog
                        .open<DownloadAttachmentModalComponent, DownloadAttachmentModalComponent['data'], void>(
                            DownloadAttachmentModalComponent,
                            {
                                data: { dataSourceUrl: url.toString(), attachmentName: attachmentInfo.attachmentName },
                            }
                        )
                        .afterClosed()
                )
            )
            .subscribe();
    }
    protected isGetThereButtonEnabled() {
        return (
            !!this.request &&
            (this.request.status === GenericRequestStatus.InProcess ||
                this.request.status === GenericRequestStatus.Submitted)
        );
    }

    isTravelOptionAvailable(studyOption: StudyTravelOption[], option: StudyTravelNames): boolean {
        return studyOption.filter((val) => val.name === option).length > 0;
    }

    getAlertContent(typeOfTravel: string): string {
        return `The administrator has disallowed ${typeOfTravel} bookings for this particular study. For further assistance, please contact your system administrator`;
    }
}
