<mds-dialog dialogTitle="Delete Form">
    <mds-dialog-content>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <span class="header">Are you sure you want to delete this file?</span>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button [id]="'confirm-btn'" label="Confirm" (clickEvent)="submitDelete()" variant="primary"></mds-button>
        <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline"></mds-button>
    </mds-dialog-actions>
</mds-dialog>
