<mds-dialog [dialogTitle]="'Information Accuracy'" data-cy="information-accuracy-modal">
    <mds-dialog-content>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <span class="bold">
                        Due to European regulations, the identity of each account holder must be verified before their
                        card can be activated. In order to ensure successful verification, it is essential that the
                        below information entered into the Bank Details section matches their government-issued ID.
                    </span>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <span>
                        To avoid payment delays, please ensure that all information entered into the
                        <span class="bold">Bank Details</span> section is accurate and complete, including:
                    </span>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <ul class="list">
                        <li>Full name</li>
                        <li>Bank code</li>
                        <li>IBAN</li>
                        <li>Recipient address, city, postal code and country</li>
                    </ul>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <span class="bold"> Inaccurate information will cause unnecessary delays in payment. </span>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-alert [content]="alertMessage" [additionalClass]="'warning'"></medpace-alert>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button variant="primary" label="Understood" (clickEvent)="closeDialog()"></mds-button>
    </mds-dialog-actions>
</mds-dialog>
