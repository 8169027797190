import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileDownloadService } from '@services/file-download/file-download.service';
import { saveAs } from 'file-saver';
import { Subject, takeUntil } from 'rxjs';
import { MedpaceDeletePatientModalComponent } from '../delete-patient-modal.component';

@Component({
    selector: 'app-download-attachment-modal',
    templateUrl: './download-attachment-modal.component.html',
    styleUrls: ['./download-attachment-modal.component.scss'],
})
export class DownloadAttachmentModalComponent implements AfterViewInit, OnDestroy {
    private file: Blob;
    private defaultFileName = 'download';
    private componentDestroyed$: Subject<boolean> = new Subject();
    fileError = false;
    fileReady = false;

    constructor(
        private dialogRef: MatDialogRef<MedpaceDeletePatientModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { dataSourceUrl: string; attachmentName?: string },
        private downloadService: FileDownloadService
    ) {}

    /* istanbul ignore next */
    ngAfterViewInit(): void {
        this.downloadService
            .downloadFile<Blob>(this.data.dataSourceUrl, 'blob')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: ({ response }) => {
                    if (response instanceof HttpResponse) {
                        this.data.attachmentName ??= this.getFileNameFromContentDispositionHeader(response.headers);
                        this.data.attachmentName = this.replaceIllegalFileNameCharacters(this.data.attachmentName);
                        this.file = response.body;
                        this.fileReady = true;
                    }
                },
                error: () => {
                    this.fileReady = false;
                    this.fileError = true;
                },
            });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    onDownloadButtonClick(): void {
        if (this.file && this.fileReady) {
            saveAs(this.file, this.data.attachmentName);
        }
        this.dialogRef.close();
    }

    private getFileNameFromContentDispositionHeader(headers: HttpHeaders): string {
        if (headers) {
            const contentDisposition = headers.get('content-disposition') || '';
            const matches = /filename=([^;]+)/gi.exec(contentDisposition);
            return (matches[1] || this.defaultFileName).trim();
        }
        return undefined;
    }

    private replaceIllegalFileNameCharacters(filename: string): string {
        if (filename) {
            return filename.replace(/[/\\?%*:|"<>]/g, '-');
        }
        return this.data.attachmentName;
    }
}
