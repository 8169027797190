import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MdsTableDataColumn } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-table-data-column';
import { DateFormat } from '@models/date-format';
import { utc } from 'moment';
@Component({
    selector: 'medpace-view-history-modal',
    templateUrl: './view-history-modal.component.html',
    styleUrls: ['./view-history-modal.component.scss'],
})
export class MedpaceViewHistoryModalComponent implements OnInit {
    requestStatusLog: MatTableDataSource<any>;
    DateFormat = DateFormat;
    requestColumns: string[] = ['requestApprovalStatusId', 'timestamp', 'responsibleUserName', 'details'];
    requestColumnsToDisplay: MdsTableDataColumn[] = [];

    columnMap: any[] = [
        { header: 'status', name: 'requestApprovalStatusId' },
        { header: 'date', name: 'timestamp' },
        { header: 'name', name: 'responsibleUserName' },
        { header: 'details', name: 'details' },
    ];

    constructor(
        public dialogRef: MatDialogRef<MedpaceViewHistoryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.data.forEach((r) => {
            r.isExpanded = false;
        });

        this.requestStatusLog = new MatTableDataSource(this.data);
    }

    doClickRow(event: any): void {
        event.isExpanded = !event.isExpanded;
    }
    getLocalDate(timestamp: string) {
        return utc(timestamp).toDate();
    }
}
