<div class="o-grid">
    <div *ngIf="mode === 'view'">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special Requests</h5>
                    <span>{{ formGroup.controls.specialRequests.value }}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode === 'edit'">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-textarea-autocomplete
                    id="specialRequests"
                    [formCtrl]="formGroup.controls.specialRequests"
                    [cdkAutosizeMaxRows]="20"
                    [cdkAutosizeMinRows]="5"
                    [label]="'Special Requests'"
                    placeholder="Special requests"
                    [appearance]="'outline'"
                    floatLabel="always"
                    [autoSize]="true"
                    [options]="autocompleteOptions.specialRequests"
                ></medpace-textarea-autocomplete>
            </div>
        </div>
    </div>
</div>
