<div class="medpace-table">
    <div [ngClass]="isCard ? 'c-card' : ''">
        <div [ngClass]="isCard ? 'c-card__header' : ''">
            <div [ngClass]="isCard ? 'c-card__ribbon' : ''">
                <div [ngClass]="isCard ? 'c-card__item c-card__item--left' : ''">
                    <h5 [ngClass]="showFilters ? 'filter-header' : ''">{{ title }}</h5>
                </div>
                <div class="c-card__item c-card__item--right">
                    <div *ngIf="ifFiltering">
                        <mds-dropdown
                            floatLabel="always"
                            [closeOnPageScroll]="false"
                            appearance="outline"
                            [multiple]="true"
                            [label]="searchPlaceholder"
                            placeholder="Select filter..."
                            [options]="filterOptions"
                            id="filterDropdown"
                            (selectionChange)="emitChange($event)"
                        ></mds-dropdown>
                    </div>
                    <div *ngIf="isSearching" class="searchBar">
                        <mds-text-field
                            id="tableSearch"
                            data-cy="table-search"
                            appearance="outline"
                            label="Search"
                            placeholder="Type here..."
                            floatLabel="always"
                            startIcon="search"
                            (keyup)="applyFilter($event)"
                        ></mds-text-field>
                    </div>
                </div>
            </div>
        </div>
        <hr *ngIf="showFilters" class="table-hr" />

        <div class="table-content">
            <selected-custom
                *ngIf="showFilters"
                [data]="_data"
                (filterClickEvent)="filterClickEvent($event)"
                (resetDataClickEvent)="resetDataClickEvent()"
            ></selected-custom>
            <div class="c-table">
                <mds-table
                    [dataSource]="filterableData"
                    [hasCustomRowDefs]="true"
                    columnCase="uppercase"
                    dataWhiteSpace="nowrap"
                    matSort
                    [matSortActive]="defaultSorting?.columnName"
                    [matSortDirection]="defaultSorting?.direction"
                    (matSortChange)="OnSortChange()"
                >
                    <ng-container *ngFor="let column of fieldMap" [matColumnDef]="column.name">
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            sortActionDescription="Sort by {{ column.name }}"
                            >{{ column.header.toUpperCase() }}</mat-header-cell
                        >
                        <mat-cell
                            *matCellDef="let element"
                            mat-cell
                            class="medpace-cell"
                            [ngClass]="setMatCellClass(element, column.name)"
                        >
                            <mat-icon
                                *ngIf="column.name === 'status' && element.flaggedDuplicate"
                                class="dupeIcon"
                                title="Possible Duplicate"
                                ><img
                                    alt="Potential Duplicate"
                                    class="duplicate-red-icon"
                                    src="/../../assets/img/duplicate-icn.png"
                                />
                            </mat-icon>
                            {{ element[column.name] }}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row
                        (click)="OnRowClick(row)"
                        *matRowDef="let row; columns: columns"
                        [class.mdc-data-table__row-removed]="isDeletedPropertyName && row[isDeletedPropertyName]"
                        [attr.title]="GetTitle(row)"
                    ></mat-row>
                </mds-table>
            </div>
        </div>
        <ng-container *ngIf="loadSpinner">
            <div class="spinnerOverlay">
                <medpace-loading-card></medpace-loading-card>
            </div>
        </ng-container>
        <mds-paginator #tablePaginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mds-paginator>
    </div>
</div>
