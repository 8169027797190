import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AdminRequestServices } from '../admin/admin-request.service';

@Injectable()
export class RequestMessagingService {
    private adminRequestCountSubject = new BehaviorSubject<number>(0);
    public adminRequestCount$ = this.adminRequestCountSubject.asObservable();

    private crcRequestCountSubject = new BehaviorSubject<number>(0);
    public crcRequestCount$ = this.crcRequestCountSubject.asObservable();

    constructor(private requestServices: AdminRequestServices) {}

    public init() {
        this.submittedRequests();
    }

    getAdminRequestCount(): Observable<number> {
        return this.adminRequestCount$;
    }

    getCRCRequestCount(): Observable<number> {
        return this.crcRequestCount$;
    }

    submittedRequests(): void {
        this.requestServices
            .getAllAccessibleRequestsCount()
            .pipe(take(1))
            .subscribe((r) => this.adminRequestCountSubject.next(r));
    }
}
