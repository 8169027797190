<mds-dialog [attr.data-cy]="'announcementReadModal'">
    <mds-dialog-content style="max-width: 60ch">
        <div class="c-form">
            <div class="o-grid">
                <ng-container *ngIf="vm.announcements?.length > 0">
                    <div
                        class="o-grid__row"
                        [attr.data-cy]="'announcements'"
                        *ngFor="let announcement of vm.announcements"
                    >
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5 [attr.data-cy]="'announcementTitle'" style="line-height: normal">
                                    {{ announcement.title }}
                                </h5>
                                <p
                                    [attr.data-cy]="'announcementDescription'"
                                    style="word-wrap: break-word; line-height: normal"
                                >
                                    {{ announcement.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="vm.announcements?.length > 0 && vm.showRedirectToRequests">
                    <mds-divider style="height: 20px; display: block"></mds-divider>
                </ng-container>
                <div class="o-grid__row" [attr.data-cy]="'redirectToRequests'" *ngIf="vm.showRedirectToRequests">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5 [attr.data-cy]="'redirectToRequestsTitle'" style="line-height: normal">
                                New Requests Pending Site Review
                            </h5>
                            <p
                                [attr.data-cy]="'redirectToRequestsDescription'"
                                style="word-wrap: break-word; line-height: normal"
                            >
                                Site Coordinator,
                            </p>
                            <p
                                [attr.data-cy]="'redirectToRequestsDescription2'"
                                style="word-wrap: break-word; line-height: normal"
                            >
                                You have new patient requests pending your review. Please review these in order to
                                submit to PCS for processing.
                            </p>
                            <p style="text-align: center">
                                <a (click)="redirectToRequestPage()" mat-dialog-close>View Pending Requests</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            [id]="'close-btn'"
            mat-button
            mat-dialog-close
            [attr.data-cy]="'closeButton'"
            label="Close"
            (clickEvent)="close()"
            variant="primary"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
