<div *ngIf="{ isAdmin: isUserAdmin$ | async } as role" class="o-grid component-container">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <h5 class="header">Request History</h5>
        </div>
    </div>
    <div class="post-container" *ngIf="requestHistoryPosts$ | async as posts">
        <ng-container *ngFor="let post of posts">
            <medpace-request-history-post [postInfo]="post" [isAdmin]="role.isAdmin" />
        </ng-container>
    </div>
</div>
