import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RequestStatusEnum, RequestStatusService } from '@services/request-status/request-status.service';
import { Announcement } from 'src/app/models/announcement';

export interface AnnouncementModalVm {
    announcements: Announcement[];
    showRedirectToRequests: boolean;
}
@Component({
    selector: 'app-announcement-modal',
    templateUrl: './announcement-modal.component.html',
    styleUrls: ['./announcement-modal.component.scss'],
})
export class AnnouncementModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public vm: AnnouncementModalVm,
        private requestStatusService: RequestStatusService,
        private router: Router
    ) {}

    redirectToRequestPage() {
        this.close();
        let requestStatus = this.requestStatusService.getStatusById(
            RequestStatusEnum.PendingSiteVerification
        )?.viewValue;

        this.router.navigate(['crc/requests'], {
            queryParams: { params: `${JSON.stringify({ status: [requestStatus] })}` },
        });
    }

    /* istanbul ignore next */
    protected close() {
        if (this.vm?.announcements?.length > 0) {
            this.setAnnouncementsToSessionStorage(this.vm.announcements);
        }
        sessionStorage.setItem('showRedirectToRequests', 'false');
    }

    setAnnouncementsToSessionStorage(announcements: Announcement[]) {
        //list passed unique announcement ids
        let announcementIds: number[] = announcements
            .map((x) => x.id)
            .filter((id, i, arr) => arr.findIndex((t) => t == id) === i);

        //list existing ids in the session storage
        let existingIds = this.getAnnouncementsFromSessionStorage();

        if (existingIds != undefined && existingIds.length > 0) {
            //exclude ids that are allready in the array
            existingIds = existingIds.filter((x) => !announcementIds.includes(x));
            if (existingIds.length > 0) {
                //create single list to pass it to the sessionStorage
                announcementIds = announcementIds.concat(existingIds);
            }
        }
        //update session storage with new list of ids
        sessionStorage.setItem('announcements', announcementIds.toString());
    }

    getAnnouncementsFromSessionStorage(): number[] {
        const announcementIds = sessionStorage
            .getItem('announcements')
            ?.split(',')
            .map((x) => +x);
        return announcementIds;
    }
}
