/* istanbul ignore file */

import { Component, Input } from '@angular/core';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';
export class TrainPreferencesFormGroup extends PersistentFormGroup<{
    specialNeeds: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<TrainPreferencesFormGroup>): TrainPreferencesFormGroup {
        return new TrainPreferencesFormGroup({
            specialNeeds: new PersistentFormControl(value.specialNeeds, maxLengthValidator(500)),
        });
    }
}
@Component({
    selector: 'medpace-train-preferences',
    templateUrl: './train-preferences.component.html',
    styleUrls: ['./train-preferences.component.scss'],
})
export class TrainPreferencesComponent {
    @Input() formGroup: TrainPreferencesFormGroup;
    @Input() mode: EditableCardMode;
}
