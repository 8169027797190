<form [formGroup]="formGrp">
    <div class="c-chip-list">
        <mds-chip [chips]="chiplist" [chipListType]="'basic'" (removed)="remove($event)" [multiple]="true"></mds-chip>
    </div>
    <mds-autocomplete
        [closeOnPageScroll]="false"
        [disableRipple]="false"
        [options]="filteredOptions"
        appearance="outline"
        [placeholder]="placeholder"
        [label]="label"
        id="{{ autocompleteName }}"
        inputType="text"
        [formCtrlName]="formCtrlName"
        [formGrp]="formGrp"
        (optionSelected)="select($event.option)"
        [floatLabel]="floatLabel"
    >
    </mds-autocomplete>
</form>
