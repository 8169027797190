<mds-dialog dialogTitle="">
    <mds-dialog-content>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-alert
                    content="This function is prohibited and should be used only in exceptional situations."
                    additionalClass="danger"
                ></medpace-alert>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <div class="c-info__block">
                    <mds-textarea
                        [formGrp]="alertFormGroup"
                        formCtrlName="justificationControl"
                        id="justificationField"
                        [cdkAutosizeMinRows]="8"
                        [cdkAutosizeMaxRows]="8"
                        label="Justification"
                        appearance="outline"
                        placeholder="Type here..."
                        floatLabel="always"
                        [autoSize]="true"
                    >
                    </mds-textarea>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button
            variant="primary"
            [id]="'agree-btn'"
            mat-button
            (clickEvent)="submitClick()"
            label="Agree"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            variant="outline"
            mat-button
            mat-dialog-close
            label="Cancel"
            (clickEvent)="cancelClick()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
