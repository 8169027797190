import { CountryViewModel } from '@models/country';
import { Patient, PatientDataModel } from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { PersistentFormGroup } from '@utility/persistent-forms';
import { getCountryByCodeOrName } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';
import { utc } from 'moment';
import { EditFieldFormGroup } from '../edit-field.formgroup';
import { PassportEditFormGroup } from '../passport.formgroup';
export class PatientTravelPrefsEditRequestFormGroup extends PersistentFormGroup<{
    flight: PersistentFormGroup<{
        airlinePreference1: EditFieldFormGroup<string | null>;
        frequentFlyerNumber1: EditFieldFormGroup<string | null>;
        airlinePreference2: EditFieldFormGroup<string | null>;
        frequentFlyerNumber2: EditFieldFormGroup<string | null>;
        seatPreference: EditFieldFormGroup<string | null>;
        knownTravelerNumber: EditFieldFormGroup<string | null>;
        specialNeeds: EditFieldFormGroup<string | null>;
    }>;
    train: PersistentFormGroup<{
        specialNeeds: EditFieldFormGroup<string | null>;
    }>;
    international: PassportEditFormGroup;
    lodging: PersistentFormGroup<{
        hotelBrandPreference: EditFieldFormGroup<string | null>;
        hotelRoomPreference: EditFieldFormGroup<string | null>;
        allergies: EditFieldFormGroup<string | null>;
        specialNeeds: EditFieldFormGroup<string | null>;
    }>;
    carRental: PersistentFormGroup<{
        rentalCompanyFrequentTravelerNumber: EditFieldFormGroup<string | null>;
    }>;
    carService: PersistentFormGroup<{
        specialNeeds: EditFieldFormGroup<string | null>;
    }>;
}> {
    constructor(
        public readonly patient: Patient,
        public readonly editRequest: PatientEditRequestDto
    ) {
        super({
            flight: new PersistentFormGroup({
                airlinePreference1: new EditFieldFormGroup<string | null>('Airline Preference #1', null, 'string', [
                    maxLengthValidator(50),
                ]),
                frequentFlyerNumber1: new EditFieldFormGroup<string | null>('Frequent Flyer No. #1', null, 'string', [
                    maxLengthValidator(50),
                ]),
                airlinePreference2: new EditFieldFormGroup<string | null>('Airline Preference #2', null, 'string', [
                    maxLengthValidator(50),
                ]),
                frequentFlyerNumber2: new EditFieldFormGroup<string | null>('Frequent Flyer No. #2', null, 'string', [
                    maxLengthValidator(50),
                ]),
                knownTravelerNumber: new EditFieldFormGroup<string | null>('Known Traveler Number', null, 'string', [
                    maxLengthValidator(20),
                ]),
                seatPreference: new EditFieldFormGroup<string | null>('Seat Preference', null, 'string', [
                    maxLengthValidator(75),
                ]),
                specialNeeds: new EditFieldFormGroup<string | null>('Special Needs', null, 'string', [
                    maxLengthValidator(500),
                ]),
            }),
            train: new PersistentFormGroup({
                specialNeeds: new EditFieldFormGroup<string | null>('Special Needs', null, 'string', [
                    maxLengthValidator(500),
                ]),
            }),
            international: new PassportEditFormGroup(),
            lodging: new PersistentFormGroup({
                hotelBrandPreference: new EditFieldFormGroup<string | null>('Hotel Brand Preference', null, 'string', [
                    maxLengthValidator(50),
                ]),
                hotelRoomPreference: new EditFieldFormGroup<string | null>('Hotel Room Preference', null, 'string', [
                    maxLengthValidator(500),
                ]),
                allergies: new EditFieldFormGroup<string | null>('Allergies', null, 'string', [
                    maxLengthValidator(1000),
                ]),
                specialNeeds: new EditFieldFormGroup<string | null>('Special Needs', null, 'string', [
                    maxLengthValidator(500),
                ]),
            }),
            carRental: new PersistentFormGroup({
                rentalCompanyFrequentTravelerNumber: new EditFieldFormGroup<string | null>(
                    'Rental Company Frequent Traveler Number',
                    null,
                    'string',
                    [maxLengthValidator(100)]
                ),
            }),
            carService: new PersistentFormGroup({
                specialNeeds: new EditFieldFormGroup<string | null>('Special Needs', null, 'string', [
                    maxLengthValidator(500),
                ]),
            }),
        });
    }
    getPatientDataModel(model: PatientDataModel, countries: CountryViewModel[]) {
        const value = this.getRawValue();
        const flight = value.flight;
        if (flight.airlinePreference1.isAccepted) model.airlinePrimary = flight.airlinePreference1.requestedValue;

        // check if frequentflyer1 and 2 work on backend
        if (flight.frequentFlyerNumber1.isAccepted)
            model.patientDatum.airlineRewardsPrimary = flight.frequentFlyerNumber1.requestedValue;

        if (flight.airlinePreference2.isAccepted) model.airlineSecondary = flight.airlinePreference2.requestedValue;

        if (flight.frequentFlyerNumber2.isAccepted)
            model.patientDatum.airlineRewardsSecondary = flight.frequentFlyerNumber2.requestedValue;

        if (flight.knownTravelerNumber.isAccepted)
            model.patientDatum.knownTravelerNum = flight.knownTravelerNumber.requestedValue;

        if (flight.seatPreference.isAccepted) model.seatPreference = flight.seatPreference.requestedValue;

        // check
        if (flight.specialNeeds.isAccepted) model.patientDatum.airlineSpecialNeeds = flight.specialNeeds.requestedValue;

        const train = value.train;
        if (train.specialNeeds.isAccepted) model.patientDatum.trainSpecialNeeds = train.specialNeeds.requestedValue;

        const lodging = value.lodging;
        if (lodging.hotelBrandPreference.isAccepted)
            model.hotelChainPrimary = lodging.hotelBrandPreference.requestedValue;
        if (lodging.hotelRoomPreference.isAccepted)
            model.patientDatum.lodgingRoomPreference = lodging.hotelRoomPreference.requestedValue;
        if (lodging.allergies.isAccepted) model.allergies = lodging.allergies.requestedValue;
        if (lodging.specialNeeds.isAccepted)
            model.patientDatum.lodgingSpecialNeeds = lodging.specialNeeds.requestedValue;

        const international = value.international;
        if (international.passportNum.isAccepted)
            model.patientDatum.passportNum = international.passportNum.requestedValue;

        if (international.passportCountry.isAccepted) {
            const passportCountryFullName = international.passportCountry.requestedValue;
            if (passportCountryFullName) {
                const passportCountry = getCountryByCodeOrName(passportCountryFullName, countries);
                if (!passportCountry) throw new Error('Missing country!');
                model.patientDatum.passportCountry = passportCountry.countryCode; // country code, not full name
            } else model.patientDatum.passportCountry = '';
        }

        if (international.passportIssue.isAccepted)
            model.patientDatum.passportIssue =
                international.passportIssue.requestedValue?.toISOString().split('T')[0] ?? null;

        if (international.passportExpiration.isAccepted)
            model.patientDatum.passportExpiration =
                international.passportExpiration.requestedValue?.toISOString().split('T')[0] ?? null;

        const carService = value.carService;
        if (carService.specialNeeds.isAccepted)
            model.patientDatum.groundSpecialNeeds = carService.specialNeeds.requestedValue;

        const carRental = value.carRental;
        if (carRental.rentalCompanyFrequentTravelerNumber.isAccepted)
            model.patientDatum.rentalCarFrequentTravelerNum =
                carRental.rentalCompanyFrequentTravelerNumber.requestedValue;

        return model;
    }
    disableIfUnchanged() {
        const controls = [
            ...Object.values(this.controls.flight.controls),
            ...Object.values(this.controls.train.controls),
            ...Object.values(this.controls.lodging.controls),
            ...Object.values(this.controls.international.controls),
            ...Object.values(this.controls.carRental.controls),
            ...Object.values(this.controls.carService.controls),
        ];

        controls.forEach((control) => {
            control.disableIfUnchanged();
        });

        if (!controls.some((control) => control.enabled)) this.disable({ persistent: true });
        else this.enable({ breakPersistence: true });
    }
    setRequestedValue(editRequest: PatientEditRequestDto, countries: CountryViewModel[]) {
        const flight = this.controls.flight.controls;
        flight.airlinePreference1.setValue({
            requestedValue: editRequest.airlinePrimary ?? null,
            isAccepted: false,
        });
        flight.frequentFlyerNumber1.setValue({
            requestedValue: editRequest.airlineRewardsPrimary ?? null,
            isAccepted: false,
        });
        flight.airlinePreference2.setValue({
            requestedValue: editRequest.airlineSecondary ?? null,
            isAccepted: false,
        });
        flight.frequentFlyerNumber2.setValue({
            requestedValue: editRequest.airlineRewardsSecondary ?? null,
            isAccepted: false,
        });
        flight.knownTravelerNumber.setValue({
            requestedValue: editRequest.knownTravelerNum ?? null,
            isAccepted: false,
        });
        flight.seatPreference.setValue({
            requestedValue: editRequest.seatPreference ?? null,
            isAccepted: false,
        });
        flight.specialNeeds.setValue({
            requestedValue: editRequest.airlineSpecialNeeds ?? null,
            isAccepted: false,
        });

        const train = this.controls.train.controls;
        train.specialNeeds.setValue({
            requestedValue: editRequest.trainSpecialNeeds ?? null,
            isAccepted: false,
        });

        const lodging = this.controls.lodging.controls;
        lodging.hotelBrandPreference.setValue({
            requestedValue: editRequest.hotelChainPrimary ?? null,
            isAccepted: false,
        });
        lodging.hotelRoomPreference.setValue({
            requestedValue: editRequest.lodgingRoomPreference ?? null,
            isAccepted: false,
        });
        lodging.allergies.setValue({
            requestedValue: editRequest.allergies ?? null,
            isAccepted: false,
        });
        lodging.specialNeeds.setValue({
            requestedValue: editRequest.lodgingSpecialNeeds ?? null,
            isAccepted: false,
        });

        const international = this.controls.international.controls;
        international.passportNum.setValue({
            requestedValue: editRequest.passportNum ?? null,
            isAccepted: false,
        });

        if (editRequest.passportCountry === '') {
            international.passportCountry.setValue({
                requestedValue: null,
                isAccepted: false,
            });
            international.passportCountry.hasBeenCleared = true;
        } else {
            international.passportCountry.setValue({
                requestedValue: editRequest.passportCountry
                    ? (getCountryByCodeOrName(editRequest.passportCountry, countries)?.viewValue ?? null)
                    : null,
                isAccepted: false,
            });
        }
        if (editRequest.passportIssue === '0001-01-01') {
            international.passportIssue.setValue({
                requestedValue: null,
                isAccepted: false,
            });
            international.passportIssue.hasBeenCleared = true;
        } else {
            international.passportIssue.setValue({
                requestedValue: editRequest.passportIssue ? utc(editRequest.passportIssue) : null,
                isAccepted: false,
            });
        }

        if (editRequest.passportExpiration === '0001-01-01') {
            international.passportExpiration.setValue({
                requestedValue: null,
                isAccepted: false,
            });
            international.passportExpiration.hasBeenCleared = true;
        } else {
            international.passportExpiration.setValue({
                requestedValue: editRequest.passportExpiration ? utc(editRequest.passportExpiration) : null,
                isAccepted: false,
            });
        }

        const carService = this.controls.carService.controls;
        carService.specialNeeds.setValue({
            requestedValue: editRequest.groundSpecialNeeds ?? null,
            isAccepted: false,
        });

        const carRental = this.controls.carRental.controls;
        carRental.rentalCompanyFrequentTravelerNumber.setValue({
            requestedValue: editRequest.rentalCarFrequentTravelerNum ?? null,
            isAccepted: false,
        });
    }
    setCurrentValue(patient: Patient) {
        const flight = this.controls.flight.controls;
        flight.airlinePreference1.currentValue = patient.travelPreferences.airtravel.airlinePreference1;
        flight.frequentFlyerNumber1.currentValue = patient.travelPreferences.airtravel.frequentFlyer1;
        flight.airlinePreference2.currentValue = patient.travelPreferences.airtravel.airlinePreference2;
        flight.frequentFlyerNumber2.currentValue = patient.travelPreferences.airtravel.frequentFlyer2;
        flight.knownTravelerNumber.currentValue = patient.travelPreferences.airtravel.knownTravelerNumber;
        flight.seatPreference.currentValue = patient.travelPreferences.airtravel.seatPreference;
        flight.specialNeeds.currentValue = patient.travelPreferences.airtravel.airlineSpecialNeeds;

        const train = this.controls.train.controls;
        train.specialNeeds.currentValue = patient.travelPreferences.traintravel.specialNeeds;

        const lodging = this.controls.lodging.controls;
        lodging.hotelBrandPreference.currentValue = patient.travelPreferences.lodging.hotelBrandPreference;
        lodging.hotelRoomPreference.currentValue = patient.travelPreferences.lodging.lodgingRoomPreference;
        lodging.allergies.currentValue = patient.travelPreferences.lodging.allergies;
        lodging.specialNeeds.currentValue = patient.travelPreferences.lodging.specialRequirements;

        const international = this.controls.international.controls;
        international.passportNum.currentValue = patient.travelPreferences.internationaltravel.passportNum;
        international.passportCountry.currentValue = patient.travelPreferences.internationaltravel.passportCountry;
        international.passportIssue.currentValue = patient.travelPreferences.internationaltravel.passportIssue;
        international.passportExpiration.currentValue =
            patient.travelPreferences.internationaltravel.passportExpiration;

        const carService = this.controls.carService.controls;
        carService.specialNeeds.currentValue = patient.travelPreferences.groundTransportation.groundSpecialNeeds;

        const carRental = this.controls.carRental.controls;
        carRental.rentalCompanyFrequentTravelerNumber.currentValue =
            patient.travelPreferences.rentalCar.rentalCarFrequentTravelerNumber;
    }
}
