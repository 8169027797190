import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { safeUTC } from '@app/extensions/moment-extensions';
import { MileageReimbursementRateData } from '@models/request';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { ParsingResult, XLSXService } from '@services/xlsx/xlsx.service';
import { BehaviorSubject, combineLatest, map, of, switchMap } from 'rxjs';
import { FileType } from './../../../../enums/FileTypes';

@Component({
    selector: 'app-reimbursement-rates-upload-dialog',
    templateUrl: './reimbursement-rates-upload-dialog.component.html',
    styleUrls: ['./reimbursement-rates-upload-dialog.component.scss'],
})
export class ReimbursementRatesUploadDialogComponent {
    acceptableFileTypes: string[] = [FileType.XLSX];
    private fileParsedSubject = new BehaviorSubject<ParsingResult<MileageReimbursementRateData>>(<
        ParsingResult<MileageReimbursementRateData>
    >{
        isSuccess: null,
        parsedValues: [],
    });
    private rates: MileageReimbursementRateData[];
    private dataErrorsSubject = new BehaviorSubject<string[]>(new Array<string>());

    public vm$ = combineLatest({
        parsingResult: this.fileParsedSubject,
        dataErrors: this.dataErrorsSubject,
    }).pipe(
        map((result) => {
            const canBeUploaded =
                !!result.parsingResult && result.parsingResult.isSuccess === true && result.dataErrors.length === 0;
            this.rates = canBeUploaded ? result.parsingResult.parsedValues : null;
            return {
                canBeUploaded,
                rates: this.rates,
                ...result,
            };
        })
    );
    constructor(
        private dialogRef: MatDialogRef<ReimbursementRatesUploadDialogComponent>,
        private xlsxService: XLSXService,
        private adminRequestService: AdminRequestServices
    ) {}
    getSpreadsheet(file: File) {
        this.xlsxService
            .parseSheet<MileageReimbursementRateData>(
                file,
                () => new MileageReimbursementRateData(),
                (target: MileageReimbursementRateData, colData, parsedValue: Object): boolean => {
                    try {
                        const colName_LowerCase_NoWhitespace = colData.colName.toLowerCase().replace(/\s+/g, '');
                        switch (colName_LowerCase_NoWhitespace) {
                            case 'Id'.toLowerCase():
                                // do nothing with Id
                                break;
                            case 'CountryCode'.toLowerCase():
                                target.countryCode = parsedValue as string;
                                break;
                            case 'CurrencyCode'.toLowerCase():
                                target.currencyCode = parsedValue as string;
                                break;
                            case 'EffectiveDate'.toLowerCase():
                                const daysSince1900 = parsedValue as number;
                                target.effectiveDate = safeUTC(this.excelDateToJSDate(daysSince1900));
                                break;
                            case 'MileageDistanceUnits'.toLowerCase():
                                target.mileageDistanceUnits = parsedValue as string;
                                break;
                            case 'MileageReimbursementRatePerUnit'.toLowerCase():
                                target.mileageReimbursementRatePerUnit = parsedValue as number;
                                break;
                            case '':
                                break;
                            default:
                                return false;
                        }
                    } catch (error) {
                        return false;
                    }
                    return true;
                },
                0
            )
            .pipe(
                switchMap((result) => {
                    if (result.isSuccess) {
                        return combineLatest([
                            this.adminRequestService.validateMileageReimbursementRates(result.parsedValues),
                            of(result),
                        ]);
                    } else {
                        return combineLatest([of(null), of(result)]);
                    }
                })
            )
            .subscribe(([validationErrors, result]) => {
                let dataErrors = new Array<string>();
                if (!result.isSuccess) {
                    dataErrors.push('Error while parsing');
                } else if (!result.parsedValues || result.parsedValues.length === 0) {
                    dataErrors.push('Could not find any reimbursement rates in the supplied spreadsheet');
                } else {
                    dataErrors = [];
                }
                if (validationErrors?.length > 0) {
                    validationErrors.forEach((err) => dataErrors.push(err));
                }

                this.fileParsedSubject.next(dataErrors.length === 0 ? result : null);
                this.dataErrorsSubject.next(dataErrors);
            });
    }

    excelDateToJSDate(daysSince1900: number): Date {
        let offset = new Date(0).getTimezoneOffset();
        let date = new Date(0, 0, daysSince1900 - 1, 0, -offset, 0);
        return date;
    }

    onCancelClicked(): void {
        this.dialogRef.close();
    }

    onSubmitClicked(): void {
        this.dialogRef.close(this.rates);
    }
}
