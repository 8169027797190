import { cloneDeep } from 'lodash-es';
import { Moment, isMoment } from 'moment';
import { serialize } from 'object-to-formdata';

export function convertToFormData(object: any): FormData {
    // Needed because of issues between FormData and MomentJS
    if (object) {
        const fixedObj = fixupObjectDates(object);

        const formDataObj = serialize(fixedObj, {
            nullsAsUndefineds: true,
            indices: true,
            dotsForObjectNotation: true,
        });

        return formDataObj;
    }

    throw Error('Cannot convert `null` to a FormData.');
}

function fixupObjectDates(incomingObj) {
    let obj = cloneDeep(incomingObj);
    const stack = [obj];
    while (stack?.length > 0) {
        const currentObj = stack.pop();
        Object.keys(currentObj).forEach((key) => {
            if (currentObj[key] && isMoment(currentObj[key])) {
                const oldVal: Moment = currentObj[key];
                currentObj[key] = oldVal.toISOString();
            } else if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
                stack.push(currentObj[key]);
            }
        });
    }
    return obj;
}
