<div *ngIf="isEditing" class="o-grid">
    <div *ngIf="patient?.statusName" class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Patient Status</h5>
                <span>{{ patient?.statusName }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="patientId"
                [formGrp]="parentFormGroup"
                formCtrlName="patientIDControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Patient ID"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'patientId' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-dropdown
                floatLabel="always"
                [closeOnPageScroll]="false"
                [formCtrl]="visitScheduleDropdownControl"
                appearance="outline"
                label="Select Part/Cohort"
                placeholder="Patient Participating in..."
                [options]="studyVisitGroups"
                id="visitScheduleDropdown"
                [required]="true"
                (selectionChange)="emitChange($event.value, { id: 'visitGroupId' })"
            ></mds-dropdown>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="firstName"
                [formGrp]="parentFormGroup"
                formCtrlName="firstNameControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="First/Given Name"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'firstName' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="middleName"
                placeholder="Type here..."
                [formGrp]="parentFormGroup"
                formCtrlName="middleNameControl"
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Middle Name"
                width="100%"
                (input)="emitChange($event.target.value, { id: 'middleName' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="lastName"
                [formGrp]="parentFormGroup"
                formCtrlName="lastNameControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Last/Surname"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'lastName' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="phoneNumber"
                [formGrp]="parentFormGroup"
                formCtrlName="phoneNumberControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Phone Number"
                width="100%"
                [required]="true"
                (input)="emitChange(parsePhoneNumber($event.target.value), { id: 'phoneNumber' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="emailAddress"
                [formGrp]="parentFormGroup"
                formCtrlName="emailAddressControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Email Address"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'emailAddress' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="preferredLanguage"
                [formGrp]="parentFormGroup"
                formCtrlName="preferredLanguageControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Preferred Language"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'preferredLanguage' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <label
                >Gender
                <span class="required-asterisk">*</span>
            </label>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-radio-group
                [buttons]="genderRadioButtons"
                [formGrp]="genderFormGroup"
                formCtrlName="genderControl"
                id="gender"
                (changeEvent)="selectGender($event.value, { id: 'gender' })"
            >
            </mds-radio-group>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field half">
            <medpace-datepicker
                id="birthDate"
                appearance="outline"
                [required]="true"
                label="Birthdate"
                formCtrlName="birthDateControl"
                [formGrp]="parentFormGroup"
                (dateChange)="emitChange($event.target.value, { id: 'birthDate' })"
            ></medpace-datepicker>
        </div>
        <div class="o-grid__item half"></div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="label-with-description">
                <label
                    >Is Patient a Minor?
                    <span class="required-asterisk">*</span>
                </label>
                <span class="caregiver-info">Caregiver Information required if patient is a minor</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-radio-group
                [buttons]="minorRadioButtons"
                [formGrp]="minorFormGroup"
                formCtrlName="minorControl"
                id="under18"
                (changeEvent)="selectUnder18($event.value, { id: 'under18' })"
            >
            </mds-radio-group>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="address1"
                [formGrp]="parentFormGroup"
                formCtrlName="address1Control"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Address Line 1"
                width="100%"
                [required]="true"
                (input)="typeAddress($event.target.value, { id: 'address1' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field">
            <mds-text-field
                id="address2"
                [formGrp]="parentFormGroup"
                formCtrlName="address2Control"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Address Line 2"
                width="100%"
                (input)="emitChange($event.target.value, { id: 'address2' })"
            ></mds-text-field>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__field half">
            <mds-text-field
                id="city"
                [formGrp]="parentFormGroup"
                formCtrlName="cityControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="City"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'city' })"
            ></mds-text-field>
        </div>
        <div class="o-grid__field half">
            <mds-text-field
                id="state"
                [formGrp]="parentFormGroup"
                formCtrlName="stateControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                [label]="regionStateType$ | async"
                width="100%"
                (input)="emitChange($event.target.value, { id: 'state' })"
            ></mds-text-field>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__field half">
            <mds-text-field
                id="zipcode"
                [formGrp]="parentFormGroup"
                formCtrlName="zipcodeControl"
                placeholder="Type here..."
                appearance="outline"
                inputType="text"
                floatLabel="always"
                label="Zipcode/Postal Code"
                width="100%"
                [required]="true"
                (input)="emitChange($event.target.value, { id: 'zipcode' })"
            ></mds-text-field>
        </div>
        <div class="o-grid__field half" *ngIf="countries$ | async as countries">
            <mds-autocomplete
                [closeOnPageScroll]="false"
                [options]="countries"
                appearance="outline"
                floatLabel="always"
                placeholder="Enter country"
                label="Country"
                id="countryAutocomplete"
                (optionSelected)="emitChange($event.option.value, { id: 'country' })"
                [formGrp]="parentFormGroup"
                formCtrlName="countryControl"
                [validateInput]="true"
            >
            </mds-autocomplete>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && !loaderSpinner" class="o-grid">
    <div *ngIf="isPatientProfile">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Patient Status</h5>
                    <span>{{ patient?.statusName }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Patient ID</h5>
                    <span>{{ patient?.patientIdentification?.patientId }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row" *ngIf="patient?.patientIdentification?.visitGroup?.name">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <mds-expansion-panel
                        [panelTitle]="'Patient Participating in ' + patient?.patientIdentification?.visitGroup?.name"
                        [description]="''"
                        [disabled]="false"
                        [expanded]="false"
                        [hideToggle]="false"
                        [enableCheckbox]="false"
                        [enablePanelRibbon]="false"
                    >
                        <div *ngFor="let item of patientVisits">
                            <span>{{ item }}</span>
                        </div>
                    </mds-expansion-panel>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>PCS ID</h5>
                    <span>{{ patient?.studyCode }}-{{ patient?.id }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>
                        First/Given Name <i class="asListedOnGovIDLabel">{{ asListedOnGovID }}</i>
                    </h5>
                    <span>{{ patient?.patientIdentification?.firstName }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="patientIdent.middleName" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>
                        Middle Name <i class="asListedOnGovIDLabel">{{ asListedOnGovID }}</i>
                    </h5>
                    <span>{{ patient?.patientIdentification?.middleName }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>
                        Last/Surname <i class="asListedOnGovIDLabel">{{ asListedOnGovID }}</i>
                    </h5>
                    <span>{{ patient?.patientIdentification?.lastName }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Phone Number</h5>
                    <span>{{ patient?.patientIdentification?.phoneNumber }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Email Address</h5>
                    <span>{{ patient?.patientIdentification?.emailAddress }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Preferred Language</h5>
                    <span>{{ patient?.patientIdentification?.preferredLanguage }}</span>
                </div>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Birthdate</h5>
                    <span *ngIf="birthDate">{{ birthDate?.toDate() | dateFormat | uppercase }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Gender</h5>
                    <span>{{ patient?.patientIdentification?.gender }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Is Minor</h5>
                    <span *ngIf="patient?.statusId === 1"
                        >{{
                            patient?.patientIdentification?.under18 !== null
                                ? patient?.patientIdentification?.under18
                                    ? 'YES'
                                    : 'NO'
                                : ''
                        }}
                    </span>
                    <span *ngIf="patient?.statusId === 2">{{ '' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Address 1</h5>
                    <span>{{ patient?.patientIdentification?.address1 }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="patientIdent.address2" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Address 2</h5>
                    <span>{{ patient?.patientIdentification?.address2 }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>City</h5>
                    <span>{{ patient?.patientIdentification?.city }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>{{ regionStateType$ | async }}</h5>
                    <span>{{ patient?.patientIdentification?.state }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Zipcode/Postal Code</h5>
                    <span>{{
                        !!patient?.patientIdentification?.zipcode
                            ? patient?.patientIdentification?.zipcode
                            : 'Unspecified'
                    }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Country</h5>
                    <span>{{ patient?.patientIdentification?.country }}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isPatientProfile">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Patient Name</h5>
                    <span *ngIf="patient?.statusId !== 2"
                        >{{ patient?.patientIdentification?.firstName }}&nbsp;{{
                            patient?.patientIdentification?.lastName
                        }}</span
                    >
                    <span *ngIf="patient?.statusId === 2">Removed</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Patient ID</h5>
                    <span>{{ patient?.patientIdentification?.patientId }}</span>
                </div>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>PCS ID</h5>
                    <span>{{ patient?.studyCode }}-{{ patient?.id }}</span>
                </div>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-caregiver-info-card *ngIf="requestStatus <= 2"></medpace-caregiver-info-card>
            </div>
        </div>

        <div *ngIf="!isUserAdmin" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Address 1</h5>
                    <span>{{ patient?.patientIdentification?.address1 }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!isUserAdmin" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Address 2</h5>
                    <span>{{
                        patient?.patientIdentification?.address2 ? patient?.patientIdentification?.address2 : '--'
                    }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!isUserAdmin" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>City</h5>
                    <span>{{ patient?.patientIdentification?.city }}</span>
                </div>
            </div>
            <div *ngIf="!isUserAdmin" class="o-grid__item">
                <div class="c-info__block">
                    <h5>State</h5>
                    <span>{{ patient?.patientIdentification?.state }}</span>
                </div>
            </div>
            <div *ngIf="!isUserAdmin" class="o-grid__item">
                <div class="c-info__block">
                    <h5>Zipcode/Postal Code</h5>
                    <span>{{ patient?.patientIdentification?.zipcode }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="isUserAdmin" class="o-grid__row">
            <div class="o-grid__item">
                <mds-button
                    [id]="'patient-profile-btn'"
                    variant="outline"
                    label="PATIENT PROFILE"
                    icon="recent_actors"
                    (clickEvent)="profileLinkClick()"
                ></mds-button>
            </div>

            <div class="o-grid__item">
                <mds-button
                    [id]="'patient-activity-btn'"
                    [disabled]="patient?.statusId === 2"
                    variant="outline"
                    label="PATIENT ACTIVITY"
                    icon="poll"
                    (clickEvent)="activityLinkClick()"
                ></mds-button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
