import { Injectable } from '@angular/core';
import { HistoryDataRepository } from '@app/repositories/historyData/history-data.repository';
import { CarServiceHistoryData } from '@models/history-data/carServiceHistoryData';
import { FlightHistoryData } from '@models/history-data/flightHistoryData';
import { LodgingHistoryData } from '@models/history-data/lodgingHistoryData';
import { OtherHistoryData } from '@models/history-data/otherHistoryData';
import { RentalCarHistoryData } from '@models/history-data/rentalCarHistoryData';
import { TrainHistoryData } from '@models/history-data/trainHistoryData';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HistoryDataService {
    constructor(private historyDataRepository: HistoryDataRepository) {}
    getPatientTravelRequestHistoryForCarService: (patientId: number) => Observable<CarServiceHistoryData[]> = (
        patientId: number
    ) => {
        return this.historyDataRepository.getPatientTravelRequestHistoryForCarService(patientId);
    };

    getPatientTravelRequestHistoryForLodging: (patientId: number) => Observable<LodgingHistoryData[]> = (
        patientId: number
    ) => {
        return this.historyDataRepository.getPatientTravelRequestHistoryForLodging(patientId);
    };

    getPatientTravelRequestHistoryForRentalCar: (patientId: number) => Observable<RentalCarHistoryData[]> = (
        patientId: number
    ) => {
        return this.historyDataRepository.getPatientTravelRequestHistoryForRentalCar(patientId);
    };

    getPatientTravelRequestHistoryForFlight: (patientId: number) => Observable<FlightHistoryData[]> = (
        patientId: number
    ) => {
        return this.historyDataRepository.getPatientTravelRequestHistoryForFlight(patientId);
    };

    getPatientTravelRequestHistoryForTrain: (patientId: number) => Observable<TrainHistoryData[]> = (
        patientId: number
    ) => {
        return this.historyDataRepository.getPatientTravelRequestHistoryForTrain(patientId);
    };

    getPatientTravelRequestHistoryForOther: (patientId: number) => Observable<OtherHistoryData[]> = (
        patientId: number
    ) => {
        return this.historyDataRepository.getPatientTravelRequestHistoryForOther(patientId);
    };

    clearRepositoryCache(): void {
        this.historyDataRepository.clearCache();
    }
}
