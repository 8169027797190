<ng-container>
    <mds-textarea
        [formCtrl]="formControl"
        [autoSize]="autoSize"
        [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
        [cdkAutosizeMinRows]="cdkAutosizeMinRows"
        [label]="label"
        [placeholder]="placeholder"
        [appearance]="appearance"
        [floatLabel]="floatLabel"
        [required]="required"
        [color]="color"
        [style]="style"
        matAutocompleteOrigin
        #origin="matAutocompleteOrigin"
        (input)="update($event)"
        (focusin)="update($event)"
    ></mds-textarea>
    <input
        matInput
        [formControl]="formControl"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin"
        #autoTrigger="matAutocompleteTrigger"
        style="display: none"
    />
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
        class="textarea-autocomplete"
        [hideSingleSelectionIndicator]="true"
    >
        <mat-option class="mat-option" *ngFor="let option of options$ | async" [value]="option">
            <div class="container">
                <span class="overflow">{{ option.value }}</span>
                <span class="date-text"> ({{ option.requestDate }})</span>
            </div>
        </mat-option>
    </mat-autocomplete>
</ng-container>
