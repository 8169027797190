import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ClinTrakStudyDataViewModel,
    ClinTrakStudySiteAddressViewModel,
    ClinTrakStudySiteViewModel,
    ClinTrakStudyViewModel,
    ClinTrakStudyVisitViewModel,
    CountryRegionCtsmViewModel,
} from '../../models/clintrak';
import { ClinTrakDataRepository } from '../../repositories/clintrak/clintrak-data.repository';

@Injectable({
    providedIn: 'root',
})
export class ClinTrakDataService {
    constructor(private clintrakDataRepository: ClinTrakDataRepository) {}
    getAllActiveStudies(): Observable<ClinTrakStudyViewModel[]> {
        return this.clintrakDataRepository.getAllActiveStudies();
    }

    getAllStudies(): Observable<ClinTrakStudyViewModel[]> {
        return this.clintrakDataRepository.getAllStudies();
    }

    getStudyData(studyId: string): Observable<ClinTrakStudyDataViewModel> {
        return this.clintrakDataRepository.getStudyData(studyId);
    }

    getStudyDataByPcsStudyId(studyId: number): Observable<ClinTrakStudyDataViewModel> {
        return this.clintrakDataRepository.getStudyDataByPcsStudyId(studyId);
    }

    getStudySiteDataByPcsSiteId(siteId: number): Observable<ClinTrakStudySiteViewModel> {
        return this.clintrakDataRepository.getStudySiteDataByPcsSiteId(siteId);
    }

    getAllCountries(): Observable<CountryRegionCtsmViewModel[]> {
        return this.clintrakDataRepository.getAllCountries();
    }

    getPendingSitesByStudyId(studyId: number): Observable<ClinTrakStudySiteViewModel[]> {
        return this.clintrakDataRepository.getPendingSitesByStudyId(studyId);
    }

    rejectStudy(studyId: string): Observable<number> {
        return this.clintrakDataRepository.rejectStudy(studyId);
    }

    getStudySiteAddress(studySiteId: string): Observable<ClinTrakStudySiteAddressViewModel> {
        return this.clintrakDataRepository.getStudySiteAddress(studySiteId);
    }

    getStudyVisits(studyCode: string): Observable<ClinTrakStudyVisitViewModel[]> {
        return this.clintrakDataRepository.getStudyVisits(studyCode);
    }

    existsInClinTrak(patientId: number): Observable<boolean> {
        return this.clintrakDataRepository.existsInClinTrak(patientId);
    }
}
