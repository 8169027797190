import { AccessGroup } from './accessGroup';
import { AccessGroupDTO } from './accessGroupDTO';
import {
    ICreateUserDTO,
    IGetUserDto,
    IPasswordUpdateDto,
    IUpdateUserDTO,
    IUser,
    IUserAdminViewModel,
    IUserStatusDTO,
    IUserTableModel,
} from './interfaces/iUser';
import { ISite, SiteSummaryViewModel } from './site';
import { IStudy, StudySummaryViewModel } from './study';

export class User implements IUser {
    id: number;
    name: string;
    objectId: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    firstName: string;
    lastName: string;
    displayName: string;
    phoneNumber: string;
    // Some accounts in B2C (e.g. "admin accounts" with logins like `firstname.s`) don't have this email claim configured.
    // So `undefined` can happen in data. We handle it as an error because we rely on user's email in many places.
    emailAddress: string | undefined;
    accessType: string;
    assignedStudy: string;
    assignedSite: string;
    userStudies: IStudy[];
    userSites?: ISite[];
    userAccessGroups: AccessGroup[];
    hasLocalAuthentication: boolean;
}

export class CreateUserDTO implements ICreateUserDTO {
    firstName!: string;
    lastName!: string;
    middleName?: string | null;
    password?: string | null;
    emailAddress!: string;
    phoneNumber?: string | null;
    isActive!: boolean;
    userAccessGroupId!: number;
    hasLocalAuthentication!: boolean;
    siteIds?: number[] | null;
    studyIds?: number[] | null;
    justification: string | null;

    constructor(data?: ICreateUserDTO) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data['firstName'] !== undefined ? _data['firstName'] : <any>null;
            this.lastName = _data['lastName'] !== undefined ? _data['lastName'] : <any>null;
            this.middleName = _data['middleName'] !== undefined ? _data['middleName'] : <any>null;
            this.password = _data['password'] !== undefined ? _data['password'] : <any>null;
            this.emailAddress = _data['emailAddress'] !== undefined ? _data['emailAddress'] : <any>null;
            this.phoneNumber = _data['phoneNumber'] !== undefined ? _data['phoneNumber'] : <any>null;
            this.justification = _data['justification'] !== undefined ? _data['justification'] : <any>null;
            this.isActive = _data['isActive'] !== undefined ? _data['isActive'] : <any>null;
            this.userAccessGroupId = _data['userAccessGroupId'] !== undefined ? _data['userAccessGroupId'] : <any>null;
            this.hasLocalAuthentication =
                _data['hasLocalAuthentication'] !== undefined ? _data['hasLocalAuthentication'] : <any>null;
            if (Array.isArray(_data['siteIds'])) {
                this.siteIds = [] as any;
                for (let item of _data['siteIds']) this.siteIds!.push(item);
            } else {
                this.siteIds = <any>null;
            }
            if (Array.isArray(_data['studyIds'])) {
                this.studyIds = [] as any;
                for (let item of _data['studyIds']) this.studyIds!.push(item);
            } else {
                this.studyIds = <any>null;
            }
        }
    }

    static fromJS(data: any): CreateUserDTO {
        data = typeof data === 'object' ? data : {};
        let result = new CreateUserDTO();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['firstName'] = this.firstName !== undefined ? this.firstName : <any>null;
        data['lastName'] = this.lastName !== undefined ? this.lastName : <any>null;
        data['middleName'] = this.middleName !== undefined ? this.middleName : <any>null;
        data['password'] = this.password !== undefined ? this.password : <any>null;
        data['emailAddress'] = this.emailAddress !== undefined ? this.emailAddress : <any>null;
        data['phoneNumber'] = this.phoneNumber !== undefined ? this.phoneNumber : <any>null;
        data['justification'] = this.justification !== undefined ? this.justification : <any>null;
        data['isActive'] = this.isActive !== undefined ? this.isActive : <any>null;
        data['userAccessGroupId'] = this.userAccessGroupId !== undefined ? this.userAccessGroupId : <any>null;
        data['hasLocalAuthentication'] =
            this.hasLocalAuthentication !== undefined ? this.hasLocalAuthentication : <any>null;
        if (Array.isArray(this.siteIds)) {
            data['siteIds'] = [];
            for (let item of this.siteIds) data['siteIds'].push(item);
        }
        if (Array.isArray(this.studyIds)) {
            data['studyIds'] = [];
            for (let item of this.studyIds) data['studyIds'].push(item);
        }
        return data;
    }
}

export class UpdateUserDTO extends CreateUserDTO implements IUpdateUserDTO {
    id!: number;

    constructor(data?: IUpdateUserDTO) {
        super(data);
    }

    override init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
        }
    }

    static override fromJS(data: any): UpdateUserDTO {
        data = typeof data === 'object' ? data : {};
        let result = new UpdateUserDTO();
        result.init(data);
        return result;
    }

    override toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        super.toJSON(data);
        return data;
    }
}

export class UserAdminViewModel implements IUserAdminViewModel {
    id!: number;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    isActive!: boolean;
    hasLocalAuthentication!: boolean;
    accessGroups?: AccessGroupDTO[] | null;
    studies?: StudySummaryViewModel[] | null;
    sites?: SiteSummaryViewModel[] | null;

    constructor(data?: IUserAdminViewModel) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
            this.firstName = _data['firstName'] !== undefined ? _data['firstName'] : <any>null;
            this.lastName = _data['lastName'] !== undefined ? _data['lastName'] : <any>null;
            this.middleName = _data['middleName'] !== undefined ? _data['middleName'] : <any>null;
            this.emailAddress = _data['emailAddress'] !== undefined ? _data['emailAddress'] : <any>null;
            this.phoneNumber = _data['phoneNumber'] !== undefined ? _data['phoneNumber'] : <any>null;
            this.isActive = _data['isActive'] !== undefined ? _data['isActive'] : <any>null;
            this.hasLocalAuthentication =
                _data['hasLocalAuthentication'] !== undefined ? _data['hasLocalAuthentication'] : <any>null;
            if (Array.isArray(_data['accessGroups'])) {
                this.accessGroups = [] as any;
                for (let item of _data['accessGroups']) this.accessGroups!.push(AccessGroupDTO.fromJS(item));
            } else {
                this.accessGroups = <any>null;
            }
            if (Array.isArray(_data['studies'])) {
                this.studies = [] as any;
                for (let item of _data['studies']) this.studies!.push(StudySummaryViewModel.fromJS(item));
            } else {
                this.studies = <any>null;
            }
            if (Array.isArray(_data['sites'])) {
                this.sites = [] as any;
                for (let item of _data['sites']) this.sites!.push(SiteSummaryViewModel.fromJS(item));
            } else {
                this.sites = <any>null;
            }
        }
    }

    static fromJS(data: any): UserAdminViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new UserAdminViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        data['firstName'] = this.firstName !== undefined ? this.firstName : <any>null;
        data['lastName'] = this.lastName !== undefined ? this.lastName : <any>null;
        data['middleName'] = this.middleName !== undefined ? this.middleName : <any>null;
        data['emailAddress'] = this.emailAddress !== undefined ? this.emailAddress : <any>null;
        data['phoneNumber'] = this.phoneNumber !== undefined ? this.phoneNumber : <any>null;
        data['isActive'] = this.isActive !== undefined ? this.isActive : <any>null;
        data['hasLocalAuthentication'] =
            this.hasLocalAuthentication !== undefined ? this.hasLocalAuthentication : <any>null;
        if (Array.isArray(this.accessGroups)) {
            data['accessGroups'] = [];
            for (let item of this.accessGroups) data['accessGroups'].push(item.toJSON());
        }
        if (Array.isArray(this.studies)) {
            data['studies'] = [];
            for (let item of this.studies) data['studies'].push(item.toJSON());
        }
        if (Array.isArray(this.sites)) {
            data['sites'] = [];
            for (let item of this.sites) data['sites'].push(item.toJSON());
        }
        return data;
    }
}

export class UserTableModel implements IUserTableModel {
    displayName?: string | null;
    emailAddress: string | null;
    isActive?: string | null;
    accessType?: string | null;
    assignedStudy?: string | null;
    assignedSite?: string | null;
    loginTypes?: string | null;

    constructor(data?: IUserTableModel) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export class UserStatusDTO implements IUserStatusDTO {
    userId!: number;
    isActive!: boolean;

    constructor(data?: IUserStatusDTO) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data['userId'] !== undefined ? _data['userId'] : <any>null;
            this.isActive = _data['isActive'] !== undefined ? _data['isActive'] : <any>null;
        }
    }

    static fromJS(data: any): UserStatusDTO {
        data = typeof data === 'object' ? data : {};
        let result = new UserStatusDTO();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['userId'] = this.userId !== undefined ? this.userId : <any>null;
        data['isActive'] = this.isActive !== undefined ? this.isActive : <any>null;
        return data;
    }
}

export class GetUserDto implements IGetUserDto {
    id!: number;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    emailAddress!: string;
    phoneNumber?: string | null;
    isActive!: boolean;
    userAccessGroupId?: number | null;
    hasLocalAuthentication!: boolean;
    siteIds?: number[] | null;
    siteIdsPrimaryCRC?: number[] | null;
    studyIds?: number[] | null;
    studyIdsPrimaryCRC?: number[] | null;

    constructor(data?: IGetUserDto) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
            this.firstName = _data['firstName'] !== undefined ? _data['firstName'] : <any>null;
            this.lastName = _data['lastName'] !== undefined ? _data['lastName'] : <any>null;
            this.middleName = _data['middleName'] !== undefined ? _data['middleName'] : <any>null;
            this.emailAddress = _data['emailAddress'] !== undefined ? _data['emailAddress'] : <any>null;
            this.phoneNumber = _data['phoneNumber'] !== undefined ? _data['phoneNumber'] : <any>null;
            this.isActive = _data['isActive'] !== undefined ? _data['isActive'] : <any>null;
            this.userAccessGroupId = _data['userAccessGroupId'] !== undefined ? _data['userAccessGroupId'] : <any>null;
            this.hasLocalAuthentication =
                _data['hasLocalAuthentication'] !== undefined ? _data['hasLocalAuthentication'] : <any>null;
            if (Array.isArray(_data['siteIds'])) {
                this.siteIds = [] as any;
                for (let item of _data['siteIds']) this.siteIds!.push(item);
            } else {
                this.siteIds = <any>null;
            }
            if (Array.isArray(_data['siteIdsPrimaryCRC'])) {
                this.siteIdsPrimaryCRC = [] as any;
                for (let item of _data['siteIdsPrimaryCRC']) this.siteIdsPrimaryCRC!.push(item);
            } else {
                this.siteIdsPrimaryCRC = <any>null;
            }
            if (Array.isArray(_data['studyIds'])) {
                this.studyIds = [] as any;
                for (let item of _data['studyIds']) this.studyIds!.push(item);
            } else {
                this.studyIds = <any>null;
            }
            if (Array.isArray(_data['studyIdsPrimaryCRC'])) {
                this.studyIdsPrimaryCRC = [] as any;
                for (let item of _data['studyIdsPrimaryCRC']) this.studyIdsPrimaryCRC!.push(item);
            } else {
                this.studyIdsPrimaryCRC = <any>null;
            }
        }
    }

    static fromJS(data: any): GetUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new GetUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        data['firstName'] = this.firstName !== undefined ? this.firstName : <any>null;
        data['lastName'] = this.lastName !== undefined ? this.lastName : <any>null;
        data['middleName'] = this.middleName !== undefined ? this.middleName : <any>null;
        data['emailAddress'] = this.emailAddress !== undefined ? this.emailAddress : <any>null;
        data['phoneNumber'] = this.phoneNumber !== undefined ? this.phoneNumber : <any>null;
        data['isActive'] = this.isActive !== undefined ? this.isActive : <any>null;
        data['userAccessGroupId'] = this.userAccessGroupId !== undefined ? this.userAccessGroupId : <any>null;
        data['hasLocalAuthentication'] =
            this.hasLocalAuthentication !== undefined ? this.hasLocalAuthentication : <any>null;
        if (Array.isArray(this.siteIds)) {
            data['siteIds'] = [];
            for (let item of this.siteIds) data['siteIds'].push(item);
        }
        if (Array.isArray(this.siteIdsPrimaryCRC)) {
            data['siteIdsPrimaryCRC'] = [];
            for (let item of this.siteIdsPrimaryCRC) data['siteIdsPrimaryCRC'].push(item);
        }
        if (Array.isArray(this.studyIds)) {
            data['studyIds'] = [];
            for (let item of this.studyIds) data['studyIds'].push(item);
        }
        if (Array.isArray(this.studyIdsPrimaryCRC)) {
            data['studyIdsPrimaryCRC'] = [];
            for (let item of this.studyIdsPrimaryCRC) data['studyIdsPrimaryCRC'].push(item);
        }
        return data;
    }
}

export class PasswordUpdateDto implements IPasswordUpdateDto {
    updatedPassword!: string;

    constructor(data?: IPasswordUpdateDto) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.updatedPassword = _data['updatedPassword'] !== undefined ? _data['updatedPassword'] : <any>null;
        }
    }

    static fromJS(data: any): PasswordUpdateDto {
        data = typeof data === 'object' ? data : {};
        let result = new PasswordUpdateDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['updatedPassword'] = this.updatedPassword !== undefined ? this.updatedPassword : <any>null;
        return data;
    }
}
