import { Injectable } from '@angular/core';
import { EmittedEvent } from '@models/event';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    subject$ = new Subject<EmittedEvent>();

    emit(event: EmittedEvent) {
        this.subject$.next(event);
    }

    on(event: string, action: any): Subscription {
        return this.subject$
            .pipe(
                filter((e: EmittedEvent) => e.name === event),
                map((e: EmittedEvent) => e.value)
            )
            .subscribe(action);
    }
}
