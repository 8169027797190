<ng-container *ngIf="mode === 'edit'">
    <div class="o-grid__row">
        <div class="o-grid__field third">
            <div class="c-datepicker">
                <medpace-datepicker
                    [formCtrl]="formGroup.controls.receiptDate"
                    [appearance]="'outline'"
                    [label]="'Receipt Date'"
                ></medpace-datepicker>
            </div>
        </div>
        <div class="o-grid__field third">
            <mds-text-field
                appDisableMouseScroll
                [formCtrl]="formGroup.controls.receiptAmount"
                [placeholder]="'Type here...'"
                [appearance]="'outline'"
                [inputType]="'decimal'"
                [floatLabel]="'always'"
                [label]="'Receipt Amount'"
            ></mds-text-field>
        </div>
        <div class="o-grid__field third" *ngIf="setupPrefferedCurrency$ | async">
            <mds-dropdown
                floatLabel="always"
                [closeOnPageScroll]="false"
                [formCtrl]="formGroup.controls.currency"
                [appearance]="'outline'"
                [label]="'Currency'"
                [placeholder]="'Select currency'"
                [options]="currencyOptions"
            ></mds-dropdown>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-logo-upload
                [acceptableTypes]="acceptableFileTypes"
                [uploadButtonTitle]="'Upload Document'"
                [inputObj]="
                    formGroup.controls.uploadedAttachment.value || formGroup.controls.downloadAttachmentId.value
                "
                (emitStuff)="onFileSelected($event)"
            ></medpace-logo-upload>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="mode === 'view'">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Receipt Date</h5>
                <span>{{ formGroup.controls.receiptDate.value?.toDate() | dateFormat | uppercase }}</span>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Receipt Amount</h5>
                <span>{{ formGroup.controls.receiptAmount.value | number: '1.2' : 'en-US' }}</span>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Currency</h5>
                <span>{{ formGroup.controls.currency.value?.viewValue }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="formGroup.controls.downloadAttachmentId.value">
        <div class="c-info__block">
            <h5>Attachment</h5>
            <a (click)="downloadAttachment()">Download</a>
        </div>
    </div>
</ng-container>
