import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserAdminViewModel } from '@models/user';
import { UserStateService } from '@services/state-management/user-state.service';
import { UserService } from '@services/user/user.service';
import { matToolTipTextPassword, passwordValidatorPattern, sameStringValidator } from '@utility/utility.validators';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnDestroy {
    protected componentDestroyed$: Subject<boolean> = new Subject();
    currentUser$: Observable<UserAdminViewModel>;
    usersWithoutLocalAuthenticationMessage: string =
        "User cannot update password, because he doesn't have Local Authentication Account configured!";
    newPasswordFieldName: string = 'New Password';
    reenterPasswordFieldName: string = 'Confirm New Password';

    passwordChangeFormGroup = this.formBuilder.group({
        [this.newPasswordFieldName]: new FormControl<string>('', [
            Validators.required,
            Validators.pattern(passwordValidatorPattern),
        ]),
        [this.reenterPasswordFieldName]: new FormControl<string>('', [Validators.required]),
    });
    matToolTipTextPassword: string = matToolTipTextPassword;

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
        private formBuilder: FormBuilder,
        private userStateService: UserStateService,
        private userService: UserService
    ) {
        this.currentUser$ = this.userService.getCurrentUserAccountData().pipe(
            takeUntil(this.componentDestroyed$),
            tap((user) => {
                if (user.hasLocalAuthentication === false) {
                    this.passwordChangeFormGroup.disable();
                }
            })
        );
        this.passwordChangeFormGroup.controls[this.newPasswordFieldName].valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((inputValue) => {
                this.passwordChangeFormGroup.controls[this.reenterPasswordFieldName].setValidators([
                    sameStringValidator(inputValue, 'Values are not the same'),
                    Validators.required,
                ]);
            });
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    changePasswordClick(): void {
        if (this.passwordChangeFormGroup.valid) {
            this.userStateService.updateCurrentUserPassword(
                this.passwordChangeFormGroup.controls[this.newPasswordFieldName].value
            );
            this.dialogRef.close();
        } else {
            this.passwordChangeFormGroup.markAllAsTouched();
        }
    }

    cancelClick(): void {
        this.dialogRef.close();
    }
}
