<mds-dialog dialogTitle="Change Patient Site">
    <mds-dialog-content>
        <div class="o-grid">
            <div class="o-grid__row" *ngIf="this.currentSiteName$ | async as currentSiteName">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Current site</h5>
                        <span>{{ currentSiteName }}</span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="sitesAvailableToSelect$ | async as sitesToSelect; else spinner">
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-dropdown
                            [closeOnPageScroll]="false"
                            appearance="outline"
                            label="Change patient site to"
                            placeholder="Please Select site"
                            [options]="sitesToSelect"
                            id="availableSitesToChange"
                            [formGrp]="patientSiteTransferFormGroup"
                            formCtrlName="availableSitesToChange"
                            floatLabel="always"
                            [required]="true"
                        ></mds-dropdown>
                    </div>
                </div>
            </ng-container>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button [id]="'confirm-btn'" label="Confirm" (clickEvent)="saveDialog()" variant="primary"> </mds-button>
        <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline"> </mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
