<mds-dialog dialogTitle="Patient Status">
    <mds-dialog-content [formGroup]="patientStatusChangeFormGroup">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Current Status</h5>
                        <span>{{ this?.textStatus }}</span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="displayStatus?.length > 0; else spinner">
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-dropdown
                            floatLabel="always"
                            [closeOnPageScroll]="false"
                            formCtrlName="statusDropdownControl"
                            appearance="outline"
                            label="Change Status to"
                            placeholder="Please Select Status"
                            [options]="displayStatus"
                            id="patientStatusDropdown"
                            [formGrp]="patientStatusChangeFormGroup"
                            [required]="true"
                        ></mds-dropdown>
                    </div>
                </div>
            </ng-container>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button
            [id]="'confirm-btn'"
            id="changePatientStatusConfirmBtn"
            label="Confirm"
            (clickEvent)="saveDialog()"
            variant="primary"
        >
        </mds-button>
        <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline"> </mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
