<mds-dialog dialogTitle="Site Status">
    <mds-dialog-content [formGroup]="siteStatusChangeFormGroup">
        <div class="o-grid">
            <div class="o-grid__row" *ngIf="statusText$ | async as statusName">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Current Status</h5>
                        <span>{{ statusName }}</span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="availableActions$ | async as options; else spinner">
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-dropdown
                            [closeOnPageScroll]="false"
                            [formCtrl]="siteStatusChangeFormGroup.controls.statusDropdownControl"
                            appearance="outline"
                            label="Change Status to"
                            placeholder="Please Select Status"
                            [options]="options"
                            id="siteStatusDropdown"
                            [required]="true"
                        ></mds-dropdown>
                    </div>
                </div>
            </ng-container>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button
            [id]="'confirm-btn'"
            id="changeSiteStatusConfirmBtn"
            label="Confirm"
            (clickEvent)="saveDialog()"
            variant="primary"
        >
        </mds-button>
        <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline"> </mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
