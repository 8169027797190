import { ErrorHandler, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { EnvConfigurationService } from '@services/env/env-configuration.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UserService } from '@services/user/user.service';
import { ApplicationInsightsService } from './application-insights.service';
import { ErrorDisplayService } from './error-display-service';
import { ErrorService } from './error-service';

function errorHandlerFactory(
    snackbarService: SnackbarService,
    envConfigurationService: EnvConfigurationService,
    userService: UserService,
    errorStateService: ErrorService
) {
    if (
        envConfigurationService.configuration.appInsightsConnectionString !== null &&
        envConfigurationService.configuration.appInsightsConnectionString !== undefined &&
        envConfigurationService.configuration.appInsightsConnectionString !== ''
    ) {
        return new ApplicationinsightsAngularpluginErrorService();
    } else {
        return new ErrorDisplayService(snackbarService, envConfigurationService, userService, errorStateService);
    }
}

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        ApplicationInsightsService,
        {
            provide: ErrorHandler,
            useFactory: errorHandlerFactory,
            deps: [SnackbarService, EnvConfigurationService, UserService, ErrorService],
        },
    ],
})
export class ErrorHandlingModule {
    constructor(
        private appInsightsService: ApplicationInsightsService,
        private envService: EnvConfigurationService
    ) {
        // `appInsightsService` might appear unused, but it's injected intentionally to initialize the service when the app starts
    }
}
