<!-- CREATE & EDIT -->
<mds-dialog
    id="editCaregiverData"
    *ngIf="editMode"
    dialogTitle="{{ isNewCaregiver ? 'Add New Caregiver' : 'Caregiver Profile' }}"
>
    <mds-dialog-content *ngIf="editMode">
        <div class="caregiver-info">
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="flex start middle">
                            <span class="required-asterisk">*</span>
                            <span> indicates a required field </span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <mds-checkbox
                            (changeEvent)="selectValue($event, 'isPrimary')"
                            [disabled]="isDisabled"
                            class="make-primary-field"
                            formCtrlName="isPrimary"
                            [formGrp]="caregiverInfoFormGroup"
                            label="Make Primary?"
                            id="isPrimaryCheckbox"
                        >
                        </mds-checkbox>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="firstName"
                            id="firstName"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="First/Given Name"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="middleName"
                            id="middleName"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Middle Name"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="lastName"
                            id="lastName"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Last/Surname"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="phoneNumber"
                            id="phoneNumber"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Phone Number"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="email"
                            id="email"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Email Address"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="relationshipToPatient"
                            id="relationshipToPatient"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Relationship to patient"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="language"
                            id="language"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Preferred Language"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <label
                            >Gender
                            <span class="required-asterisk">*</span>
                        </label>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-radio-group
                            [buttons]="genderRadioButtons"
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="gender"
                            id="gender"
                            (changeEvent)="selectValue($event, 'gender')"
                        >
                        </mds-radio-group>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <medpace-datepicker
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="birthDate"
                            id="birthDate"
                            appearance="outline"
                            [required]="true"
                            label="Birthdate"
                        ></medpace-datepicker>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <label
                            >Same address as patient?
                            <span class="required-asterisk">*</span>
                        </label>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-radio-group
                            [buttons]="adressRadioButtons"
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="addressSameAsPatient"
                            id="sameAdress"
                            (changeEvent)="selectValue($event, 'addressSameAsPatient')"
                        >
                        </mds-radio-group>
                        <mat-error
                            *ngIf="
                                caregiverInfoFormGroup.get('addressSameAsPatient').hasError('required') &&
                                caregiverInfoFormGroup.get('addressSameAsPatient').touched
                            "
                        >
                            This field is required
                        </mat-error>
                    </div>
                </div>

                <div *ngIf="showAddressFields">
                    <div class="o-grid__row">
                        <div class="o-grid__field">
                            <mds-text-field
                                [formGrp]="caregiverInfoFormGroup"
                                formCtrlName="address1"
                                id="caregiverAddress1"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Address Line 1"
                                width="100%"
                                (input)="typeAddress('caregiverAddress1')"
                            ></mds-text-field>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__field">
                            <mds-text-field
                                [formGrp]="caregiverInfoFormGroup"
                                formCtrlName="address2"
                                id="address2"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Address Line 2"
                                width="100%"
                            ></mds-text-field>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__field">
                            <mds-text-field
                                [formGrp]="caregiverInfoFormGroup"
                                formCtrlName="city"
                                id="city"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="City"
                                width="100%"
                            ></mds-text-field>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__field">
                            <mds-text-field
                                [formGrp]="caregiverInfoFormGroup"
                                formCtrlName="state"
                                id="state"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                [label]="regionStateType$ | async"
                                width="100%"
                            ></mds-text-field>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__field">
                            <mds-text-field
                                [formGrp]="caregiverInfoFormGroup"
                                formCtrlName="zipCode"
                                id="zipCode"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Zipcode/Postal Code"
                                width="100%"
                            ></mds-text-field>
                        </div>
                    </div>
                    <div class="o-grid__field" *ngIf="countries$ | async as countries">
                        <mds-autocomplete
                            [closeOnPageScroll]="false"
                            [formGrp]="caregiverInfoFormGroup"
                            [disableRipple]="false"
                            [options]="countries"
                            appearance="outline"
                            floatLabel="always"
                            placeholder="Enter country"
                            label="Country"
                            id="country"
                            inputType="text"
                            formCtrlName="country"
                            [validateInput]="true"
                        >
                        </mds-autocomplete>
                    </div>
                </div>

                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            [formGrp]="caregiverInfoFormGroup"
                            formCtrlName="additionalDetails"
                            id="additionalDetails"
                            placeholder="Type here..."
                            appearance="outline"
                            inputType="text"
                            floatLabel="always"
                            label="Additional Details"
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
                <div *ngIf="isNewCaregiver" class="o-grid__row">
                    <div class="o-grid__item u-ta-left">
                        <span *ngIf="showValidationMessage" class="c-message__show">{{ validationMessage }}</span>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="flex start">
                            <ng-container *ngIf="isNewCaregiver; then addButton; else editButton"></ng-container>
                            <ng-container *ngTemplateOutlet="closeButton"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
</mds-dialog>
<!-- VIEW -->
<mds-dialog id="editCaregiverData" *ngIf="!editMode" dialogTitle="Caregiver Profile">
    <mds-dialog-content *ngIf="!editMode">
        <div class="caregiver-info">
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Primary Caregiver?</h5>
                            <span>{{ selectedCaregiver.isPrimary ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>
                                First/Given name<i class="asListedOnGovIDLabel">{{ asListedOnGovID }}</i>
                            </h5>
                            <span>{{ selectedCaregiver.firstName }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedCaregiver.middleName" class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>
                                Middle Name<i class="asListedOnGovIDLabel">{{ asListedOnGovID }}</i>
                            </h5>
                            <span>{{ selectedCaregiver.middleName }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>
                                Last/Surname<i class="asListedOnGovIDLabel">{{ asListedOnGovID }}</i>
                            </h5>
                            <span>{{ selectedCaregiver.lastName }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Phone Number</h5>
                            <span>{{ selectedCaregiver.phone }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Email Address</h5>
                            <span>{{ selectedCaregiver.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Relationship to Patient</h5>
                            <span>{{ selectedCaregiver.relationship }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Preferred Language</h5>
                            <span>{{ selectedCaregiver.preferredLang }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Gender</h5>
                            <span>{{ selectedCaregiver.gender | titlecase }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Birthdate</h5>
                            <span>{{ birthDate?.toDate() | dateFormat | uppercase }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Same address as patient?</h5>
                            <span>{{ selectedCaregiver.addressSameAsPatient ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedCaregiver.addressSameAsPatient === false">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5>Address Line 1</h5>
                                <span>{{ selectedCaregiver.address1 }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="o-grid__row" *ngIf="selectedCaregiver.address2">
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5>Address Line 2</h5>
                                <span>{{ selectedCaregiver.address2 }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5>City</h5>
                                <span>{{ selectedCaregiver.city }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5>{{ regionStateType$ | async }}</h5>
                                <span>{{ selectedCaregiver.state }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5>Zipcode/Postal Code</h5>
                                <span>{{
                                    !!selectedCaregiver.zipcode ? selectedCaregiver.zipcode : 'Unspecified'
                                }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <div class="c-info__block">
                                <h5>Country</h5>
                                <span>{{ selectedCaregiver.country }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedCaregiver.details" class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Additional Details</h5>
                            <span>{{ selectedCaregiver.details }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button [id]="'editBtn'" variant="primary" label="Edit" (clickEvent)="editCaregiver()"></mds-button>
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
    </mds-dialog-actions>
</mds-dialog>
<!-- BUTTON TEMPLATES -->
<ng-template #closeButton>
    <mds-button [id]="'cancel-btn'" (clickEvent)="closeDialog(false)" variant="outline" label="Cancel"></mds-button>
</ng-template>
<ng-template #addButton>
    <mds-button
        (clickEvent)="closeDialog(true)"
        variant="primary"
        label="Add caregiver"
        [id]="'AddNewCaregiverBtn'"
    ></mds-button>
</ng-template>
<ng-template #editButton>
    <mds-button [id]="'save-btn'" variant="primary" label="Save" (clickEvent)="closeDialog(true)"></mds-button>
</ng-template>
