<mds-dialog dialogTitle="Data comparison">
    <mds-dialog-content>
        <div class="spinnerOverlay" *ngIf="isLoading">
            <medpace-loading-card />
        </div>
        <ng-container *ngIf="clinTrakData$ | async as clinTrakData">
            @if (clinTrakData) {
                <ng-container
                    *ngIf="clinTrakData"
                    [ngTemplateOutlet]="dialog_body"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                />
            }
        </ng-container>
        <ng-container *ngIf="!existingClinTrakData && !isLoading">
            <ng-container [ngTemplateOutlet]="noDataAlert" />
        </ng-container>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'end'">
        <mds-button variant="outline" label="Close" data-cy="close-modal" (clickEvent)="closeModal()" />
        <mds-button
            *ngIf="existingClinTrakData"
            data-cy="delete-request"
            variant="primary"
            label="Set ClinTrak data"
            (clickEvent)="setData()"
        />
        <mds-button
            *ngIf="existingClinTrakData"
            data-cy="submit-request"
            variant="primary"
            label="Submit"
            type="submit"
            (clickEvent)="submit()"
        />
    </mds-dialog-actions>
</mds-dialog>

<ng-template #dialog_body let-data>
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item"><h2>ClinTrak</h2></div>
            <div class="o-grid__item"><h2>Current</h2></div>
        </div>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item"></div>
                <div class="o-grid__item"></div>
            </div>
        </div>
        <div class="o-grid__row" name="" id="studyDetails">
            <div class="o-grid__item">
                <div class="o-grid">
                    <div class="c-card">
                        <div class="c-card__header">
                            <div class="c-card__ribbon">
                                <div class="c-card__item c-card__item--left mb-2">
                                    <h3>Site Information</h3>
                                </div>
                                <div class="header-buttons"></div>
                            </div>
                        </div>
                        <div class="c-card__content">
                            <div class="o-grid">
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="o-grid">
                                            <div class="o-grid__row">
                                                <div class="o-grid__field">
                                                    <mds-text-field
                                                        id="siteNumber"
                                                        [formCtrl]="parentFormGroup.controls.siteNumberControl"
                                                        placeholder="Type here..."
                                                        appearance="outline"
                                                        inputType="text"
                                                        floatLabel="always"
                                                        label="Site #"
                                                        width="100%"
                                                        [required]="true"
                                                    ></mds-text-field>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__field">
                                                    <mds-text-field
                                                        id="name"
                                                        [formCtrl]="parentFormGroup.controls.nameControl"
                                                        placeholder="Type here..."
                                                        appearance="outline"
                                                        inputType="text"
                                                        floatLabel="always"
                                                        label="Institution Name"
                                                        width="100%"
                                                        [required]="true"
                                                    ></mds-text-field>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__field">
                                                    <mds-text-field
                                                        id="pilastName"
                                                        [formCtrl]="parentFormGroup.controls.pilastNameControl"
                                                        placeholder="Type here..."
                                                        appearance="outline"
                                                        inputType="text"
                                                        floatLabel="always"
                                                        label="PI Last Name"
                                                        width="100%"
                                                        [required]="true"
                                                    ></mds-text-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-grid__item">
                <medpace-input-card
                    [showEditSaveButton]="false"
                    [editMode]="true"
                    [isClinTrakComparison]="true"
                    type="site-info"
                    title="Site Information"
                    [parentFormGroup]="parentFormGroup"
                >
                </medpace-input-card>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noDataAlert>
    <medpace-alert [content]="'Does not exist ClinTrak data for the Site'" additionalClass="warning"></medpace-alert
></ng-template>
