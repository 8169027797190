import { Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { safeUTC } from '@app/extensions/moment-extensions';
import { MedpacePatientComponent } from '@components/organisms/create-patient/patient.component';
import { MedpaceInputCardComponent } from '@components/organisms/input-card/input-card.component';
import { CountryStateService } from '@services/state-management/country-state.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { combineLatest, filter, take, tap } from 'rxjs';
import { Caregiver, PatientTravelPreferences, getCaregiverFullName, getFullName } from 'src/app/models/patient';
import { CarRentalPreferencesFormGroup } from '../travel-card/car-rental-request-details/car-rental-preferences/car-rental-preferences.component';
import { FlightPreferencesFormGroup } from '../travel-card/flight-request-details/flight-preferences/flight-preferences.component';
import { InternationalPreferencesFormGroup } from '../travel-card/international-preferences/international-preferences.component';
import { PassportInfoFormGroup } from '../travel-card/international-preferences/passport-info/passport-info.component';
import { LodgingPreferencesFormGroup } from '../travel-card/lodging-request-details/lodging-preferences/lodging-preferences.component';
import { TrainPreferencesFormGroup } from '../travel-card/train-request-details/train-preferences/train-preferences.component';
import { TransferPreferencesFormGroup } from '../travel-card/transfer-request-details/transfer-preferences/transfer-preferences/transfer-preferences.component';

@Component({
    selector: 'medpace-travel-card',
    templateUrl: './travel-card.component.html',
    styleUrls: ['./travel-card.component.scss'],
})
export class MedpacePatientTravelPreferencesCardComponent {
    private patientTravelPreferences: PatientTravelPreferences;
    @Input()
    isEditing: boolean;

    @Input() isUpdate: boolean;
    @Input()
    parentFormGroup: FormGroup;
    private countryStateService = inject(CountryStateService);
    private patientStateService = inject(PatientStateService);

    protected formGroup = new FormGroup({
        airTravelPreferences: FlightPreferencesFormGroup.create({
            airlinePreference1: '',
            airlinePreference2: '',
            frequentFlyerNumber1: '',
            frequentFlyerNumber2: '',
            knownTravelerNumber: '',
            seatPreference: '',
            specialNeeds: '',
        }),
        trainTravelPreferences: TrainPreferencesFormGroup.create({
            specialNeeds: '',
        }),
        internationalPreferences: InternationalPreferencesFormGroup.create(PassportInfoFormGroup.create('', null)),
        lodgingPreferences: LodgingPreferencesFormGroup.create({
            hotelBrandPreference: '',
            roomPreference: '',
            allergies: '',
            specialNeeds: '',
        }),
        carRentalPreferences: CarRentalPreferencesFormGroup.create({
            frequentTravelerNumber: '',
        }),
        transferPreferences: TransferPreferencesFormGroup.create({
            specialNeeds: '',
        }),
    });

    form$ = combineLatest({
        patient: this.patientStateService.getPatient(),
        countries: this.countryStateService.getCountries(),
    }).pipe(
        filter((result) => !!result.patient),
        tap((result) => {
            const travelPreferences = result.patient.travelPreferences;
            this.patientTravelPreferences = travelPreferences;

            const caregivers = result.patient.caregivers;
            const caregiverPassportInfos =
                this.formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.controls;

            //new caregivers for whom a PassportInfoFormGroup shall be added to the formgroup
            const caregiversToAdd = caregivers.filter((caregiver) => {
                const caregiverPassportInfo = caregiverPassportInfos.find((passportInfo) => {
                    const holderCaregiver = passportInfo.holder as Caregiver;
                    // if ids exists and different than 0
                    if (!!holderCaregiver.id && !!caregiver.id) return holderCaregiver.id === caregiver.id;
                    else return holderCaregiver === caregiver;
                });
                return caregiverPassportInfo === undefined;
            });

            // existing formgroups for caregivers that have just been removed - they need to be removed too
            const caregiverPassportInfosToRemove = caregiverPassportInfos.filter((passportInfo) => {
                const holder = caregivers.find((caregiver) => {
                    const holderCaregiver = passportInfo.holder as Caregiver;
                    if (!!holderCaregiver.id && !!caregiver.id) return holderCaregiver.id === caregiver.id;
                    else return holderCaregiver === caregiver;
                });
                return holder === undefined;
            });
            caregiversToAdd.forEach((caregiverToAdd) => {
                const title = caregiverToAdd.isPrimary
                    ? `Primary Caregiver ${getCaregiverFullName(caregiverToAdd)}`
                    : `Caregiver ${getCaregiverFullName(caregiverToAdd)}`;
                this.formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.push(
                    PassportInfoFormGroup.create(title, caregiverToAdd)
                );
            });
            caregiverPassportInfosToRemove.forEach((passportInfoToRemove) => {
                this.formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.controls =
                    this.formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.controls.filter(
                        (passportInfo) => passportInfo !== passportInfoToRemove
                    );
            });
            this.formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.updateValueAndValidity();
            // populate formgroup with patient data, only if editing existing patient, else leave default form values
            if (this.isUpdate) {
                this.formGroup.setValue({
                    airTravelPreferences: {
                        airlinePreference1: travelPreferences.airtravel.airlinePreference1 || '',
                        airlinePreference2: travelPreferences.airtravel.airlinePreference2 || '',
                        frequentFlyerNumber1: travelPreferences.airtravel.frequentFlyer1 || '',
                        frequentFlyerNumber2: travelPreferences.airtravel.frequentFlyer2 || '',
                        knownTravelerNumber: travelPreferences.airtravel.knownTravelerNumber || '',
                        seatPreference: travelPreferences.airtravel.seatPreference || '',
                        specialNeeds: travelPreferences.airtravel.airlineSpecialNeeds || '',
                    },
                    trainTravelPreferences: {
                        specialNeeds: travelPreferences.traintravel.specialNeeds || '',
                    },
                    internationalPreferences: {
                        patientPassportInfo: {
                            number: travelPreferences.internationaltravel.passportNum || '',
                            country:
                                result.countries.find(
                                    (country) =>
                                        country.countryCode === travelPreferences?.internationaltravel?.passportCountry
                                )?.viewValue || '',
                            expirationDate: travelPreferences.internationaltravel.passportExpiration || null,
                            issueDate: travelPreferences.internationaltravel.passportIssue || null,
                        },
                        caregiverPassportInfos:
                            this.formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.controls.map(
                                (passportInfo) => {
                                    const caregiver = passportInfo.holder as Caregiver;
                                    const res = {
                                        number: caregiver.passportNum || '',
                                        country:
                                            result.countries.find(
                                                (country) => country.countryCode === caregiver?.passportCountry
                                            )?.viewValue || '',
                                        expirationDate: safeUTC(caregiver.passportExpiration) || null,
                                        issueDate: safeUTC(caregiver.passportIssue) || null,
                                    };
                                    return res;
                                }
                            ),
                    },
                    lodgingPreferences: {
                        hotelBrandPreference: travelPreferences.lodging.hotelBrandPreference || '',
                        roomPreference: travelPreferences.lodging.lodgingRoomPreference || '',
                        allergies: travelPreferences.lodging.allergies || '',
                        specialNeeds: travelPreferences.lodging.specialRequirements || '',
                    },
                    carRentalPreferences: {
                        frequentTravelerNumber: travelPreferences.rentalCar.rentalCarFrequentTravelerNumber || '',
                    },
                    transferPreferences: {
                        specialNeeds: travelPreferences.groundTransportation.groundSpecialNeeds || '',
                    },
                });
                const patientFullName = getFullName(
                    result.patient.patientIdentification.firstName,
                    result.patient.patientIdentification.middleName,
                    result.patient.patientIdentification.lastName
                );
                this.formGroup.controls.internationalPreferences.controls.patientPassportInfo.title = `Patient ${patientFullName}`;
                this.formGroup.controls.internationalPreferences.controls.patientPassportInfo.holder = result.patient;
            }
        }),
        tap(() => this.parentFormGroup.setControl('travelInformation', this.formGroup))
    );
    createUpdatePatientClick$ = MedpacePatientComponent.createUpdatePatientClick$.pipe(
        tap((type) => {
            this.applyFormData(this.patientTravelPreferences, this.formGroup);
        })
    );
    save$ = MedpaceInputCardComponent.save$.pipe(
        filter((type) => type === 'travel-card'),
        tap(() => {
            this.applyFormData(this.patientTravelPreferences, this.formGroup);
        })
    );
    applyFormData(patientTravelPreferences: PatientTravelPreferences, formGroup: typeof this.formGroup) {
        this.countryStateService
            .getCountries()
            .pipe(take(1))
            .subscribe((countries) => {
                const value = formGroup.getRawValue();
                patientTravelPreferences.airtravel = {
                    airlinePreference1: value.airTravelPreferences.airlinePreference1,
                    airlinePreference2: value.airTravelPreferences.airlinePreference2,
                    knownTravelerNumber: value.airTravelPreferences.knownTravelerNumber,
                    seatPreference: value.airTravelPreferences.seatPreference,
                    airlineSpecialNeeds: value.airTravelPreferences.specialNeeds,
                    frequentFlyer1: value.airTravelPreferences.frequentFlyerNumber1,
                    frequentFlyer2: value.airTravelPreferences.frequentFlyerNumber2,
                    needsAirTravel: true, // not used anymore
                };
                patientTravelPreferences.traintravel = {
                    specialNeeds: value.trainTravelPreferences.specialNeeds,
                    needsTrainTravel: true, // not used anymore
                };
                patientTravelPreferences.lodging = {
                    hotelBrandPreference: value.lodgingPreferences.hotelBrandPreference,
                    lodgingRoomPreference: value.lodgingPreferences.roomPreference,
                    allergies: value.lodgingPreferences.allergies,
                    specialRequirements: value.lodgingPreferences.specialNeeds,
                    needsLodging: true, // not used anymore
                };
                patientTravelPreferences.rentalCar = {
                    rentalCarFrequentTravelerNumber: value.carRentalPreferences.frequentTravelerNumber,
                    needsRentalCar: true, // not used anymore
                };
                patientTravelPreferences.groundTransportation = {
                    groundSpecialNeeds: value.transferPreferences.specialNeeds,
                    needsGroundTransportation: true, // not used anymore
                };
                patientTravelPreferences.internationaltravel.passportCountry = countries.find(
                    (country) => country.viewValue === value.internationalPreferences.patientPassportInfo.country
                )?.countryCode;
                patientTravelPreferences.internationaltravel.passportNum =
                    value.internationalPreferences.patientPassportInfo.number;
                patientTravelPreferences.internationaltravel.passportExpiration =
                    value.internationalPreferences.patientPassportInfo.expirationDate;
                patientTravelPreferences.internationaltravel.passportIssue =
                    value.internationalPreferences.patientPassportInfo.issueDate;

                formGroup.controls.internationalPreferences.controls.caregiverPassportInfos.controls.forEach(
                    (caregiverPassportInfo) => {
                        const caregiver = caregiverPassportInfo.holder as Caregiver;
                        const value = caregiverPassportInfo.getRawValue();
                        caregiver.passportCountry = countries.find(
                            (country) => country.viewValue === value.country
                        )?.countryCode;
                        caregiver.passportNum = value.number;
                        caregiver.passportIssue = value.issueDate?.toISOString();
                        caregiver.passportExpiration = value.expirationDate?.toISOString();
                    }
                );
            });
    }
}
