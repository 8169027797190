<div>
    <div class="o-grid content">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h4 class="header">Report a bug</h4>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                Please describe below the situation in which the error occurred. Provide as many details as possible.
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                Along with the submitted form, the information recorded during the error occurrence will be sent.
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-textarea
                    label="Bug description"
                    floatLabel="always"
                    placeholder="Type Here..."
                    appearance="outline"
                    name="bugDetails"
                    [autoSize]="true"
                    [cdkAutosizeMinRows]="8"
                    [cdkAutosizeMaxRows]="8"
                    [formCtrl]="reportBugFromGroup.controls.bugDetails"
                >
                </mds-textarea>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="flex start">
                    <mds-button [id]="'confirm-btn'" label="Send" (clickEvent)="saveDialog()" variant="primary">
                    </mds-button>
                    <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline">
                    </mds-button>
                </div>
            </div>
        </div>
    </div>
</div>
