import { Component, Input, inject } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { Caregiver, Patient } from '@models/patient';
import { CountryStateService } from '@services/state-management/country-state.service';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import {
    lessThanTodayDateValidator,
    maxLengthValidator,
    moreThanTodayDateValidator,
} from '@utility/utility.validators';
import { Moment } from 'moment';
import { defer } from 'rxjs';
export class PassportInfoFormGroup extends PersistentFormGroup<{
    number: PersistentFormControl<string>;
    country: PersistentFormControl<string>;
    issueDate: PersistentFormControl<Moment>;
    expirationDate: PersistentFormControl<Moment>;
}> {
    title: string;
    holder: Patient | Caregiver;

    public static create(title: string, holder: Patient | Caregiver) {
        const formGroup = new PassportInfoFormGroup(
            {
                number: new PersistentFormControl('', [maxLengthValidator(20)]),
                country: new PersistentFormControl(''),
                issueDate: new PersistentFormControl<Moment>(null, [lessThanTodayDateValidator]),
                expirationDate: new PersistentFormControl<Moment>(null, [moreThanTodayDateValidator]),
            },
            [
                (control: AbstractControl): ValidationErrors | null => {
                    const typedControl = control as PassportInfoFormGroup;
                    const values = Object.values(typedControl.value);
                    const someFieldHasValue = values.find((value) => value !== '' && value !== null) || false;
                    const allFieldsHaveValues = values.every((value) => value !== '' && value !== null);
                    if (someFieldHasValue && !allFieldsHaveValues)
                        return { requiredAllValues: 'Some fields have not been populated!' };
                    else return null;
                },
            ]
        );
        formGroup.title = title;
        formGroup.holder = holder;
        return formGroup;
    }
}
@Component({
    selector: 'medpace-passport-info',
    templateUrl: './passport-info.component.html',
    styleUrls: ['./passport-info.component.scss'],
})
export class PassportInfoComponent {
    @Input() set formGroup(value: PassportInfoFormGroup) {
        this._formGroup = value;
    }
    get formGroup() {
        return this._formGroup;
    }
    private _formGroup: PassportInfoFormGroup;
    @Input() mode: EditableCardMode;
    private countryStateService = inject(CountryStateService);

    countries$ = defer(() => this.countryStateService.getCountriesStringArray());
}
