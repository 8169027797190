import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private snackbarService: SnackbarService,
        private snackBar: MatSnackBar
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error?.error?.errorReasonCode?.includes('Duplicate')) return throwError(() => error);
                if (error?.error?.errorMessage) this.snackbarService.openErrorSnackbar(error?.error?.errorMessage);

                return throwError(() => error);
            })
        );
    }
}
