import { AccessGroupDTO } from './accessGroupDTO';
import {
    IAccessActionDto,
    IAccessRequestDto,
    IAccessRequestTableModel,
    ICreateAccessRequestDto,
    ICreateInvitationMessageDto,
    IInvitationDto,
    IUserAccessRequestLogDto,
    IUserAccessRequestLogTableModel,
} from './interfaces/iAccessRequest';

export class AccessRequestDto implements IAccessRequestDto {
    id: number;
    userEmail!: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    phoneNumber?: string | null;
    accessGroup: AccessGroupDTO;
    requestTimestamp: Date;
    expiredBy: Date;
    affectedSitesInstitutionName: string[];
    affectedStudiesProtocol: string[];
    justification?: string | null;
    isPrimary?: boolean | null;

    constructor(data?: IAccessRequestDto) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userEmail = _data['userEmail'] !== undefined ? _data['userEmail'] : <any>null;
            this.firstName = _data['firstName'] !== undefined ? _data['firstName'] : <any>null;
            this.lastName = _data['lastName'] !== undefined ? _data['lastName'] : <any>null;
            this.accessGroup = _data['accessGroup'] !== undefined ? _data['accessGroup'] : <any>null;
        }
    }

    static fromJS(data: any): AccessRequestDto {
        data = typeof data === 'object' ? data : {};
        let result = new AccessRequestDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['userEmail'] = this.userEmail !== undefined ? this.userEmail : <any>null;
        data['firstName'] = this.firstName !== undefined ? this.firstName : <any>null;
        data['lastName'] = this.lastName !== undefined ? this.lastName : <any>null;
        data['middleName'] = this.middleName !== undefined ? this.middleName : <any>null;
        data['accessGroup'] = this.accessGroup !== undefined ? this.accessGroup : <any>null;
        return data;
    }
}

export class UserAccessRequestLogDto implements IUserAccessRequestLogDto {
    id: number;
    userEmail: string;
    accessLevel: AccessGroupDTO;
    requestTimestamp: string;
    closeTimestamp: string;
    affectedSitesNumber: string[];
    affectedStudiesProtocol: string[];
    sourceIPV4Address: string;
    processedByIPV4Address: string;
    justification: string;
    reason: string;
    processedBy: string;
    status: string;
}

export class CreateAccessRequestDto implements ICreateAccessRequestDto {
    userEmail!: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    userAccessGroupId?: number | null;
    justification?: string | null;
    phoneNumber?: string | null;

    constructor(data?: ICreateAccessRequestDto) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userEmail = _data['userEmail'] !== undefined ? _data['userEmail'] : <any>null;
            this.firstName = _data['firstName'] !== undefined ? _data['firstName'] : <any>null;
            this.lastName = _data['lastName'] !== undefined ? _data['lastName'] : <any>null;
            this.middleName = _data['middleName'] !== undefined ? _data['middleName'] : <any>null;
            this.justification = _data['justification'] !== undefined ? _data['justification'] : <any>null;
            this.userAccessGroupId = _data['userAccessGroupId'] !== undefined ? _data['userAccessGroupId'] : <any>null;
            this.justification = _data['justification'] !== undefined ? _data['justification'] : <any>null;
            this.phoneNumber = _data['phoneNumber'] !== undefined ? _data['phoneNumber'] : <any>null;
        }
    }

    static fromJS(data: any): CreateAccessRequestDto {
        data = typeof data === 'object' ? data : {};
        let result = new CreateAccessRequestDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['userEmail'] = this.userEmail !== undefined ? this.userEmail : <any>null;
        data['firstName'] = this.firstName !== undefined ? this.firstName : <any>null;
        data['lastName'] = this.lastName !== undefined ? this.lastName : <any>null;
        data['middleName'] = this.middleName !== undefined ? this.middleName : <any>null;
        data['justification'] = this.justification !== undefined ? this.justification : <any>null;
        data['userAccessGroupId'] = this.userAccessGroupId !== undefined ? this.userAccessGroupId : <any>null;
        data['justification'] = this.justification !== undefined ? this.justification : <any>null;
        data['phoneNumber'] = this.phoneNumber !== undefined ? this.phoneNumber : <any>null;
        return data;
    }
}

export class AccessRequestTableModel implements IAccessRequestTableModel {
    id: number;
    displayName?: string;
    userEmail: string;
    phoneNumber?: string | null;
    accessType?: string;
    assignedStudy?: string[];
    assignedSite?: string[];
    createDate: string;
    expireDate: string;
    justification?: string | null;

    constructor(data?: IAccessRequestTableModel) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export class UserAccessRequestLogTableModel implements IUserAccessRequestLogTableModel {
    id: number;
    userEmail: string;
    accessType?: string;
    assignedStudy?: string;
    assignedSite?: string;
    requestTimestamp: string;
    closeTimestamp: string;
    sourceIPV4Address: string;
    processedByIPV4Address: string;
    justification: string;
    reason: string;
    processedBy: string;
    status: string;

    constructor(data?: IUserAccessRequestLogTableModel) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export class CreateInvitationMessageDto implements ICreateInvitationMessageDto {
    accessGroupId: number;
    userEmail: string;
    studyIds: number[];
    siteIds: number[];
    isPrimary: boolean;
}

export class InvitationDto implements IInvitationDto {
    accessGroupName: string;
    userEmail: string;
    studiesProtocol: string[];
    sitesName: string[];
    isPrimary: boolean;
}

export class InvitationReportDto implements InvitationDto {
    accessGroupName: string;
    userEmail: string;
    studiesProtocol: string[];
    sitesName: string[];
    isPrimary: boolean;
    invitationTimestamp: string;
    expiredBy: string;
}

export class InvitationReport {
    accessGroupName: string;
    userEmail: string;
    studiesProtocol: string;
    sitesName: string;
    isPrimary: boolean;
    invitationTimestamp: string;
    expiredBy: string;
}

export class AccessActionDto implements IAccessActionDto {
    reason?: string | null;
    studyIds?: number[] | null;
}
