import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestCount } from '@models/requestCount';
import {
    mapReimbursementRequest_DTO_To_ReimbursementRequest,
    mapStipendRequest_DTO_To_StipendRequest,
    mapTravelRequest_DTO_To_TravelRequest,
} from '@services/transforms/request-transform';
import { SaveStatusResponse } from '@utility/utility';
import { Observable, map, of } from 'rxjs';
import { SiteService } from 'src/app/services/site/site.service';
import { convertToFormData } from 'src/app/utility/formData-util';
import {
    MileageReimbursementRate,
    MileageReimbursementRateData,
    ReimbursementRequest,
    ReimbursementRequest_DTO,
    RequestLog,
    RequestSummary,
    StipendRequest,
    StipendRequest_DTO,
    TravelRequest,
    TravelRequestTypeCount,
    TravelRequest_DTO,
} from '../../models/request';

@Injectable()
export class AdminRequestRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getAllRequestsCount = (filters?: { studyIds?: number[]; visitNamesIds?: number[] }) => {
        let params: any = {};
        if (filters) {
            if (filters.studyIds && filters.studyIds.length !== 0) params.studyIds = filters.studyIds.join(',');
            if (filters.visitNamesIds && filters.visitNamesIds.length !== 0)
                params.visitNamesIds = filters.visitNamesIds.join(',');
        }
        return this.client.get<number>(`${this.siteService.getSiteBaseUrl()}/api/requests/all/count`, {
            params,
        });
    };
    getAllReimbursementRequestsCount = (filters?: {
        studyIds?: number[];
        visitNamesIds?: number[];
        expenseTypesNames?: string[];
    }) => {
        let params: any = {};
        if (filters) {
            if (filters.studyIds && filters.studyIds.length !== 0) params.studyIds = filters.studyIds.join(',');
            if (filters.visitNamesIds && filters.visitNamesIds.length !== 0)
                params.visitNamesIds = filters.visitNamesIds.join(',');
            if (filters.expenseTypesNames && filters.expenseTypesNames.length !== 0)
                params.expenseTypesNames = filters.expenseTypesNames.join(',');
        }
        return this.client.get<number>(`${this.siteService.getSiteBaseUrl()}/api/requests/all/reimbursement/count`, {
            params,
        });
    };
    getAllAccessibleRequestsCount: () => Observable<number> = () => {
        return this.client.get<number>(`${this.siteService.getSiteBaseUrl()}/api/requests/all/details/count`);
    };

    getCrcUserReimbursementRequestCount = (statusesIds?: number[]) => {
        let params: any = {};

        if (statusesIds?.length > 0) {
            params.statusesIds = statusesIds.join(',');
        }

        return this.client.get<number>(`${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/count`, {
            params,
        });
    };

    getTotalAllRequestsCount: () => Observable<RequestCount[]> = () => {
        return this.client.get<RequestCount[]>(`${this.siteService.getSiteBaseUrl()}/api/requests/all/details/total`);
    };

    getAllRequestSummaries: () => Observable<RequestSummary[]> = () => {
        return this.client.get<RequestSummary[]>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/all/requestSummaries`
        );
    };

    createStipendRequest: (stipendRequest) => Observable<SaveStatusResponse> = (stipendRequest) => {
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/stipend`,
            stipendRequest
        );
    };

    updateStipendRequest: (stipendRequest) => Observable<SaveStatusResponse> = (stipendRequest) => {
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/stipend`,
            stipendRequest
        );
    };

    submitStipendRequest: (stipendRequest) => Observable<HttpResponse<SaveStatusResponse>> = (stipendRequest) => {
        if (stipendRequest.id === undefined || stipendRequest.id === 0) {
            return this.client.post<SaveStatusResponse>(
                `${this.siteService.getSiteBaseUrl()}/api/requests/stipend/submit`,
                stipendRequest,
                {
                    observe: 'response',
                }
            );
        } else {
            return this.client.put<SaveStatusResponse>(
                `${this.siteService.getSiteBaseUrl()}/api/requests/stipend/submit`,
                stipendRequest,
                {
                    observe: 'response',
                }
            );
        }
    };

    completeStipendRequest: (stipendRequest) => Observable<SaveStatusResponse> = (stipendRequest) => {
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/stipend/complete`,
            stipendRequest
        );
    };

    getStipendRequest: (requestId: number) => Observable<StipendRequest> = (requestId: number) => {
        return this.client
            .get<StipendRequest_DTO>(`${this.siteService.getSiteBaseUrl()}/api/requests/stipend/${requestId}`)
            .pipe(map((dto) => mapStipendRequest_DTO_To_StipendRequest(dto)));
    };

    getStipendRequests: (patientId: number) => Observable<StipendRequest[]> = (patientId: number) => {
        return this.client
            .get<
                StipendRequest_DTO[]
            >(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/requests/stipend`)
            .pipe(map((dtos) => dtos.map((dto) => mapStipendRequest_DTO_To_StipendRequest(dto))));
    };

    createReimbursementRequest: (
        reimbursementRequest: ReimbursementRequest
    ) => Observable<HttpResponse<SaveStatusResponse>> = (reimbursementRequest: ReimbursementRequest) => {
        const formData = convertToFormData(reimbursementRequest);
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement`,
            formData,
            {
                observe: 'response',
            }
        );
    };

    updateReimbursementRequest: (
        requestId: number,
        reimbursementRequest: ReimbursementRequest
    ) => Observable<SaveStatusResponse> = (requestId: number, reimbursementRequest) => {
        const formData = convertToFormData(reimbursementRequest);
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/${requestId}`,
            formData
        );
    };

    submitReimbursementRequest: (
        reimbursementRequest: ReimbursementRequest
    ) => Observable<HttpResponse<SaveStatusResponse>> = (reimbursementRequest: ReimbursementRequest) => {
        const formData = convertToFormData(reimbursementRequest);
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/submit`,
            formData,
            {
                observe: 'response',
            }
        );
    };

    completeReimbursementRequest: (
        requestId: number,
        reimbursementRequest: ReimbursementRequest
    ) => Observable<SaveStatusResponse> = (requestId: number, reimbursementRequest: ReimbursementRequest) => {
        const formData = convertToFormData(reimbursementRequest);
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/${requestId}/complete`,
            formData
        );
    };

    getReimbursementRequest: (requestId: number) => Observable<ReimbursementRequest> = (requestId: number) => {
        return this.client
            .get<ReimbursementRequest_DTO>(
                `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/${requestId}`
            )
            .pipe(map((dto) => mapReimbursementRequest_DTO_To_ReimbursementRequest(dto)));
    };

    getReimbursementRequestAttachmentUri(requestId: number, attachmentId: number): Observable<URL> {
        return of(
            new URL(
                `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/${requestId}/attachment/${attachmentId}`
            )
        );
    }

    getReimbursementRequests: (patientId: number) => Observable<ReimbursementRequest[]> = (patientId: number) => {
        return this.client
            .get<
                ReimbursementRequest_DTO[]
            >(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/requests/reimbursement`)
            .pipe(map((dtos) => dtos.map((dto) => mapReimbursementRequest_DTO_To_ReimbursementRequest(dto))));
    };

    createTravelRequest: (travelRequest: TravelRequest) => Observable<HttpResponse<SaveStatusResponse>> = (
        travelRequest
    ) => {
        const formData = convertToFormData(travelRequest);
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/travel`,
            formData,
            {
                observe: 'response',
            }
        );
    };

    updateTravelRequest: (requestId: number, travelRequest: TravelRequest) => Observable<SaveStatusResponse> = (
        requestId: number,
        travelRequest: TravelRequest
    ) => {
        const formData = convertToFormData(travelRequest);
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/travel/${requestId}`,
            formData
        );
    };

    submitTravelRequest: (travelRequest: TravelRequest) => Observable<HttpResponse<SaveStatusResponse>> = (
        travelRequest: TravelRequest
    ) => {
        const formData = convertToFormData(travelRequest);
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/travel/submit`,
            formData,
            {
                observe: 'response',
            }
        );
    };

    completeTravelRequest: (requestId: number, travelRequest: TravelRequest) => Observable<SaveStatusResponse> = (
        requestId: number,
        travelRequest: TravelRequest
    ) => {
        const formData = convertToFormData(travelRequest);
        return this.client.put<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/travel/${requestId}/complete`,
            formData
        );
    };

    getTravelRequest: (requestId: number) => Observable<TravelRequest> = (requestId: number) => {
        return this.client
            .get<TravelRequest_DTO>(`${this.siteService.getSiteBaseUrl()}/api/requests/travel/${requestId}`)
            .pipe(map((dto) => mapTravelRequest_DTO_To_TravelRequest(dto)));
    };

    getTravelRequestAttachmentUri(requestId: number, attachmentId: number): Observable<URL> {
        return of(
            new URL(`${this.siteService.getSiteBaseUrl()}/api/requests/travel/${requestId}/attachment/${attachmentId}`)
        );
    }

    getTravelRequests: (patientId: number) => Observable<TravelRequest[]> = (patientId: number) => {
        return this.client
            .get<TravelRequest_DTO[]>(`${this.siteService.getSiteBaseUrl()}/api/patients/${patientId}/requests/travel`)
            .pipe(map((dtos) => dtos.map((dto) => mapTravelRequest_DTO_To_TravelRequest(dto))));
    };

    getTravelRequestLog: (requestId: number) => Observable<RequestLog[]> = (requestId: number) => {
        return this.client.get<RequestLog[]>(
            `${this.siteService.getSiteBaseUrl()}/api/requeststatuslogs/travel/${requestId}`
        );
    };

    getStipendRequestLog: (requestId: number) => Observable<RequestLog[]> = (requestId: number) => {
        return this.client.get<RequestLog[]>(
            `${this.siteService.getSiteBaseUrl()}/api/requeststatuslogs/stipend/${requestId}`
        );
    };

    getReimbursementRequestLog: (requestId: number) => Observable<RequestLog[]> = (requestId: number) => {
        return this.client.get<RequestLog[]>(
            `${this.siteService.getSiteBaseUrl()}/api/requeststatuslogs/reimbursement/${requestId}`
        );
    };

    getMileageReimbursementRate: (countryCode: string, date: string) => Observable<MileageReimbursementRate> = (
        countryCode: string,
        date: string
    ) => {
        return this.client.get<MileageReimbursementRate>(
            `${this.siteService.getSiteBaseUrl()}/api/mileagereimbursementrates/currentRate?countryCode=${countryCode}&date=${date}`
        );
    };

    getActiveTravelRequestTypeCountByStudy(studyId: number): Observable<TravelRequestTypeCount> {
        return this.client.get<TravelRequestTypeCount>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/study/${studyId}/travel/active/count`
        );
    }

    getActiveTravelRequestTypeCountBySite(studyId: number): Observable<TravelRequestTypeCount> {
        return this.client.get<TravelRequestTypeCount>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/site/${studyId}/travel/active/count`
        );
    }

    uploadMileageReimbursementRates: (
        rates: MileageReimbursementRateData[]
    ) => Observable<MileageReimbursementRateData[]> = (rates) => {
        return this.client.post<MileageReimbursementRateData[]>(
            `${this.siteService.getSiteBaseUrl()}/api/mileagereimbursementrates/upload`,
            rates
        );
    };

    validateMileageReimbursementRates(rates: MileageReimbursementRateData[]): Observable<string[] | null> {
        return this.client.post<string[] | null>(
            `${this.siteService.getSiteBaseUrl()}/api/mileagereimbursementrates/validate`,
            rates
        );
    }

    deleteTravelRequest: (requestId: number) => Observable<SaveStatusResponse> = (requestId: number) => {
        return this.client.delete<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/travel/${requestId}`
        );
    };

    deleteReimbursementRequest: (requestId: number) => Observable<SaveStatusResponse> = (requestId: number) => {
        return this.client.delete<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/reimbursement/${requestId}`
        );
    };

    deleteStipendRequest: (requestId: number) => Observable<SaveStatusResponse> = (requestId: number) => {
        return this.client.delete<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/stipend/${requestId}`
        );
    };

    deleteTravelRequestCaregiver: (travelRequestId: number, caregiverId: number) => Observable<void> = (
        travelRequestId: number,
        caregiverId: number
    ) => {
        return this.client.delete<void>(
            `${this.siteService.getSiteBaseUrl()}/api/requests/travel/${travelRequestId}/caregiver/${caregiverId}`
        );
    };
}
