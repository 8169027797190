<div class="o-grid">
    <ng-container *ngIf="mode === 'edit'">
        <medpace-alert
            *ngFor="let error of formGroup.errors | keyvalue"
            [additionalClass]="'danger'"
            [content]="error.value"
        ></medpace-alert>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-datepicker
                    appearance="outline"
                    label="Check-in date"
                    id="lodgingCheckInDate"
                    [formCtrl]="formGroup.controls.checkInDate"
                ></medpace-datepicker>
            </div>

            <div class="o-grid__item">
                <medpace-datepicker
                    appearance="outline"
                    label="Check-out date"
                    id="lodgingCheckOutDate"
                    [formCtrl]="formGroup.controls.checkOutDate"
                ></medpace-datepicker>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-textarea-autocomplete
                    id="specialRequests"
                    [autoSize]="true"
                    [cdkAutosizeMaxRows]="20"
                    [cdkAutosizeMinRows]="5"
                    label="Special Requests"
                    placeholder="Special requests - e.g. hotel preference, room size, ADA accessible"
                    appearance="outline"
                    floatLabel="always"
                    [formCtrl]="formGroup.controls.specialRequests"
                    [options]="autocompleteOptions.specialRequests"
                ></medpace-textarea-autocomplete>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === 'view'">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Check-in date</h5>
                    <span>{{ formGroup.controls.checkInDate.value?.toDate() | dateFormat | uppercase }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Check-out date</h5>
                    <span>{{ formGroup.controls.checkOutDate.value?.toDate() | dateFormat | uppercase }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special requests</h5>
                    <span>{{ formGroup.controls.specialRequests.value }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
