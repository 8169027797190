<div class="o-grid {{ optionName }}-draggable-list-component">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <h5 class="draggable-list-header">{{ title }}</h5>
        </div>
    </div>
    <div cdkDropList id="{{ optionName }}List" class="c-bar-list" (cdkDropListDropped)="drop($event)">
        <!-- for each -->
        <div *ngFor="let option of options" cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="o-grid__row">
                <div class="o-grid__item option-row">
                    <div class="option width-90">
                        <mds-text-field
                            id="input-{{ option.sortOrder }}"
                            [formCtrl]="getFormControl(optionsFormGroup, option)"
                            floatLabel="always"
                            appearance="outline"
                            label="{{ capitalizeText(labelText) }}"
                            placeholder="Type here..."
                            (input)="optionChanged($event.target.value, option)"
                        ></mds-text-field>
                    </div>
                    <div class="delete-icon width-10">
                        <a (click)="onOptionRemoveClick(option)"><i class="fas fa-trash icon"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- for each end  -->
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-button
                [id]="getButtonID(optionName)"
                variant="outline"
                label="Add Option"
                (clickEvent)="addOption()"
            ></mds-button>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-alert
                *ngIf="!optionsFormGroup?.valid && optionsFormGroup?.touched"
                [content]="getValidationMessage(optionsFormGroup)"
                [additionalClass]="'danger'"
            />
        </div>
    </div>
</div>
