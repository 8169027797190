<div class="c-header">
    <mds-appbar
        id="navbar"
        color="primary"
        title=""
        [logo]="'pcs-white'"
        [actionItems]="actionItems"
        [logoutDialogTitle]="'Patient Concierge Services (PCS)'"
        [profile]="profile"
        [showNotifications]="loggedIn"
        [notificationItems]="notificationItems"
        [hideCollapsibleMenu]="true"
        [homeLink]="homeLink"
        [showStudySearch]="false"
        (logoutEventEmitter)="logout()"
    ></mds-appbar>
</div>
