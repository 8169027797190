<div class="o-grid {{ optionName }}-draggable-list-component">
    <div class="o-grid__row">
        <div class="o-grid__item cohort-header" *ngIf="hasMultipleVisitSchedules">
            <h5 class="draggable-list-header" *ngIf="!isEditGroupName">{{ title }}</h5>
            <ng-container *ngIf="isEditGroupName" [ngTemplateOutlet]="header_editView"></ng-container>
            <mds-button
                *ngIf="!isEditGroupName"
                [icon]="'edit'"
                [variant]="'icon'"
                class="edit-button-width"
                (clickEvent)="editGroupNameClickEvent()"
            ></mds-button>
            <mds-checkbox
                [required]="false"
                [disabled]="false"
                [indeterminate]="false"
                [label]="'Primary'"
                [checked]="false"
                (changeEvent)="isPrimaryOptionChange($event.checked)"
                id="isPrimarycheckbox-{{ title }}"
                [formCtrl]="isPrimaryControl"
            ></mds-checkbox>
        </div>
    </div>

    <div cdkDropList id="{{ optionName }}List" class="c-bar-list" (cdkDropListDropped)="drop($event)">
        <!-- for each -->
        <div *ngFor="let option of options" cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="o-grid__row">
                <div class="o-grid__item option-row">
                    <div class="option width-90">
                        <div class="display-flex">
                            <div class="width-75">
                                <mds-text-field
                                    id="input-{{ option.sortOrder }}"
                                    [formCtrl]="getFormControlText(optionsFormGroup, option)"
                                    floatLabel="always"
                                    appearance="outline"
                                    label="{{ capitalizeText(labelText) }}"
                                    placeholder="Type here..."
                                    (input)="optionChanged($event.target.value, option)"
                                ></mds-text-field>
                            </div>
                            <div class="display-flex">
                                <mds-checkbox
                                    [label]="'Scheduled'"
                                    [formCtrl]="getFormControlCheckbox(optionsFormGroup, option)"
                                    (changeEvent)="checkboxChanged($event.checked, option)"
                                    class="checkbox"
                                    id="checkbox-{{ option.sortOrder }}"
                                ></mds-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="delete-icon width-10">
                        <a (click)="onOptionRemoveClick(option)"><i class="fas fa-trash icon"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- for each end  -->
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-button
                [id]="getButtonID(optionName)"
                variant="outline"
                label="Add Option"
                (clickEvent)="addOption()"
            ></mds-button>
            <mds-button
                *ngIf="hasMultipleVisitSchedules"
                [id]="getRemoveButtonID(optionName)"
                variant="outline"
                label="Remove Cohort/Part"
                color="warn"
                (clickEvent)="tryToRemoveGroup()"
            ></mds-button>
        </div>
    </div>

    <div class="o-grid__row" *ngIf="!optionsFormGroup?.valid && optionsFormGroup?.touched">
        <div class="o-grid__item">
            <medpace-alert [content]="getValidationMessage(optionsFormGroup)" [additionalClass]="'danger'" />
        </div>
    </div>
</div>

<ng-template #header_editView>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-text-field
                [formCtrlName]="'groupNameEditInput'"
                [formGrp]="parentFormGroup"
                id="goupNameEditInput"
                floatLabel="always"
                appearance="outline"
                label="{{ 'Cohort/Group name' }}"
                placeholder="Type here..."
            ></mds-text-field>
        </div>
        <div class="o-grid__item center-buttons">
            <mds-button
                id="SubmitEditVisitGroupNameBtn"
                label="Submit"
                [icon]="'done'"
                [variant]="'icon'"
                (clickEvent)="doneChangeHeaderNameClickEvent()"
            ></mds-button>
            <mds-button
                id="CancelVisitGroupNameBtn"
                label="Cancel"
                [icon]="'cancel'"
                [variant]="'icon'"
                (clickEvent)="isEditGroupName = false"
            ></mds-button>
        </div>
    </div>
</ng-template>
