<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="flex space-between middle">
                    <h1>Patients</h1>
                </div>
            </div>
        </div>
        <div
            class="o-grid__row"
            *ngIf="{
                patientEditRequest: patientEditRequests$ | async,
                expandProfileEditRequests: expandProfileEditRequests$ | async,
            } as vm"
        >
            <div class="o-grid__item" *ngIf="vm.patientEditRequest?.length > 0 && isUserAdmin() | async">
                <mds-expansion-panel
                    [panelTitle]="'Patient Portal - Profile Edit Requests'"
                    [attr.data-cy]="'profile-edit-request'"
                    [expanded]="vm.expandProfileEditRequests"
                    icon="account_circle"
                >
                    <medpace-patient-edit-request-table [patientEditRequests]="vm.patientEditRequest" />
                </mds-expansion-panel>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-table
                    data-cy="patient-table"
                    [data]="patients"
                    title="All Patients"
                    [columns]="columns"
                    isDeletedPropertyName="isPatientDeleted"
                    [isCard]="true"
                    [fieldMap]="columnMapping"
                    searchPlaceholder="Country"
                    [ifFiltering]="false"
                    [isSearching]="true"
                    [searchFn]="searchFn"
                    [filterOptions]="countries"
                    [includePaginator]="true"
                    [showFilters]="true"
                    (rowClickEvent)="doClickRow($event)"
                >
                </medpace-table>
            </div>
        </div>
    </div>
</div>
