<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__col">
            <div class="o-grid">
                <div class="o-grid__row"></div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <h5 class="uppercase">{{ getTitleLabel() }}</h5>
                    </div>
                </div>
                <div class="o-grid">
                    <div class="o-grid__item" *ngIf="!isUpdate">
                        <medpace-site
                            [site]="site"
                            [isModal]="false"
                            [isUpdate]="isUpdate"
                            (infoChangeEvent)="detectInfoChange($event)"
                            (addressChangeEvent)="detectAddressChange($event)"
                            (servicesChangeEvent)="detectServicesChange($event)"
                            (paymentChangeEvent)="detectPaymentChange($event)"
                            (crcChangeEvent)="detectCRCChange($event)"
                            (clickSubmit)="validateAndPost()"
                            (crcToInviteChangeEvent)="detectCrcToInviteChange($event)"
                        >
                        </medpace-site>
                    </div>
                    <div class="o-grid__item" *ngIf="isUpdate">
                        <medpace-site
                            [site]="site"
                            [isModal]="false"
                            [isUpdate]="isUpdate"
                            (infoChangeEvent)="detectInfoChange($event)"
                            (addressChangeEvent)="detectAddressChange($event)"
                            (shippingAddressChangeEvent)="detectShippingAddressChange($event)"
                            (isShippingAddressChangeEvent)="detectIsShippingRadioChange($event)"
                            (servicesChangeEvent)="detectServicesChange($event)"
                            (paymentChangeEvent)="detectPaymentChange($event)"
                            (crcChangeEvent)="detectCRCChange($event)"
                            (clickSubmit)="validateAndUpdate($event)"
                            (statusChangeEvent)="changeStatus($event)"
                            (crcToInviteChangeEvent)="detectCrcToInviteChange($event)"
                        >
                        </medpace-site>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
