import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [],
    imports: [
        MatDatepickerModule,
        MatMomentDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatTableModule,
        MatTabsModule,
        DragDropModule,
        MatSnackBarModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSortModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
    ],
    exports: [
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatTableModule,
        MatTable,
        MatDialogModule,
        MatTabsModule,
        DragDropModule,
        MatSnackBarModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSortModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTooltipModule,
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
            },
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true,
            },
        },
    ],
})
export class MaterialModule {}
