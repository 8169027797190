// This file is used for any global constants
export const maxFileSizeInMB: number = 5;
export const maxFileSize: number = maxFileSizeInMB * Math.pow(2, 20); //5MB in bytes
export const maxCountOfFiles: number = 6;
export const maxSizeOfFilesInMB: number = 30;
export const maxSizeOfFiles: number = maxSizeOfFilesInMB * Math.pow(2, 20);

export const globals = {
    constants: {
        asListedOnGovernmentID: 'as listed on Government ID',
        mileageExpenseType: 'Mileage (mi/km)',
    },
};
