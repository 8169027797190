<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h3>Patient Overview</h3>
            </div>
            <div class="o-grid__item">
                <div class="flex end">
                    <mds-button
                        id="addNewRequest"
                        (clickEvent)="addNewRequest()"
                        variant="primary"
                        label="Add New Request"
                    ></mds-button>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div *ngIf="!!patient; else spinner">
                    <medpace-info-card
                        [isAdmin]="isAdmin"
                        (navButtonClick)="goToDetails()"
                        [data]="patient"
                        inputCTA="PATIENT PROFILE"
                        type="patient"
                    ></medpace-info-card>
                </div>
            </div>
        </div>
        <ng-template #spinner>
            <div class="spinnerOverlay">
                <medpace-loading-card></medpace-loading-card>
            </div>
        </ng-template>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h3>Visit Schedule</h3>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div *ngIf="!!patient && !!visitTypes; else spinner">
                    <medpace-history-timeline
                        [patientId]="patient.id"
                        [studyId]="patient.studyId"
                    ></medpace-history-timeline>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item"></div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h3>Patient's Requests</h3>
            </div>
            <div class="o-grid__item">
                <div class="export-btn flex end">
                    <mds-button
                        id="export-as-csv-btn"
                        (clickEvent)="saveAsCsv()"
                        variant="outline"
                        label="Export as .csv"
                    ></mds-button>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-table
                    title="Filter Patient Requests"
                    (rowClickEvent)="doClickRow($event)"
                    (doFilterData)="filterData($event)"
                    [columns]="tableColumnHeaders"
                    [data]="travelRequestData"
                    [fieldMap]="tableColumnMap"
                    [ifFiltering]="false"
                    [isSearching]="true"
                    [showFilters]="true"
                    [includePaginator]="true"
                >
                </medpace-table>
            </div>
        </div>
    </div>
</div>
