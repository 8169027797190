import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccessGroup } from '../../models/accessGroup';

import { HttpErrorResponse } from '@angular/common/http';
import { GroupRepository } from '../../repositories/group/group.repository';

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    constructor(private repo: GroupRepository) {}

    getUserGroups(): Observable<AccessGroup[]> {
        return this.repo.getGroupsForCurrentUser();
    }

    isUserAdmin(groups: string[]): boolean {
        if (
            groups &&
            groups.length > 0 &&
            (groups.indexOf('Medpace_TravelPortal_Admins') > -1 ||
                groups.indexOf('Medpace_TravelPortal_SuperAdmins') > -1)
        ) {
            return true;
        }
        return false;
    }

    isUserSuperAdmin(groups: string[]): boolean {
        if (groups && groups.length > 0 && groups.indexOf('Medpace_TravelPortal_SuperAdmins') > -1) {
            return true;
        }
        return false;
    }

    getGroupsFromStorage(): Observable<string[]> {
        let subject = new BehaviorSubject<string[]>([]);
        const groups = sessionStorage.getItem('grp')?.split(',');

        if (!groups || groups.length === 0) {
            return this.repo.getGroupsForCurrentUser().pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        // We can ignore such response, because we use this paticular API call to check if front-end has a valid token in trial-and-error manner
                        console.info(
                            'User is not authorized to get groups. Most likely, the user has simply not logged in yet.'
                        );
                        return of([]);
                    } else if (error.status === 404) {
                        // Backend responds with 404 if the token has proper signature, but doesn't carry enough information to allow us match the request with a specific user.
                        // This happens e.g. when signing in with Medpace admin account (logins like `firstname.s@medpace.medpace.com`) which generate tokens without `email` claim.
                        console.info(
                            `Backend responded with 404 when asked for user's roles. Assuming no role is assigned.`
                        );
                        return of([]);
                    }

                    return of([]);
                }),
                map((groups) => groups.map((g) => g.appName))
            );
        } else {
            subject.next(groups);
            return subject.asObservable();
        }
    }

    setGroupsToSessionStorage(groups: string[]): void {
        sessionStorage.setItem('grp', groups.toString());
    }
}
