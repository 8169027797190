import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap, timer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IdleTimeService {
    private timeoutId: any;
    private durationInSeconds: number;

    private countdownSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private countdownTimer$: Observable<number>;
    public timer$: Observable<number> = this.countdownSubject.asObservable();

    startTimeoutAsync(durationInSeconds: number): Promise<boolean> {
        this.durationInSeconds = durationInSeconds;
        return new Promise<boolean>((resolve) => {
            this.timeoutId = setTimeout(() => {
                resolve(true);
            }, this.durationInSeconds * 1000);
        });
    }

    resetTimeoutAsync(): Promise<boolean> {
        clearTimeout(this.timeoutId);
        return this.startTimeoutAsync(this.durationInSeconds);
    }

    startTimer(): Observable<number> {
        this.countdownTimer$ = timer(0, 1000);

        return this.countdownTimer$.pipe(
            tap((elapsedSeconds) => {
                this.countdownSubject.next(this.durationInSeconds - elapsedSeconds);
            })
        );
    }
}
