import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ClinTrakStudyDataViewModel,
    ClinTrakStudySiteAddressViewModel,
    ClinTrakStudySiteViewModel,
    ClinTrakStudyViewModel,
    ClinTrakStudyVisitViewModel,
    CountryRegionCtsmViewModel,
} from '../../models/clintrak';
import { SiteService } from '../../services/site/site.service';

@Injectable({
    providedIn: 'root',
})
export class ClinTrakDataRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getAllActiveStudies(): Observable<ClinTrakStudyViewModel[]> {
        return this.client.get<ClinTrakStudyViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/studies/activestudies`
        );
    }

    getAllStudies(): Observable<ClinTrakStudyViewModel[]> {
        return this.client.get<ClinTrakStudyViewModel[]>(`${this.siteService.getSiteBaseUrl()}/api/clintrak/studies`);
    }

    getStudyData(studyId: string): Observable<ClinTrakStudyDataViewModel> {
        return this.client.get<ClinTrakStudyDataViewModel>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/studies/studydata/${studyId}`
        );
    }

    getStudyDataByPcsStudyId(studyId: number): Observable<ClinTrakStudyDataViewModel> {
        return this.client.get<ClinTrakStudyDataViewModel>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/studies/studydata/pcs/${studyId}`
        );
    }

    getStudySiteDataByPcsSiteId(siteId: number): Observable<ClinTrakStudySiteViewModel> {
        return this.client.get<ClinTrakStudySiteViewModel>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/sites/pcs/${siteId}`
        );
    }

    getAllCountries(): Observable<CountryRegionCtsmViewModel[]> {
        return this.client.get<CountryRegionCtsmViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/other/countries`
        );
    }

    rejectStudy(studyId: string): Observable<number> {
        return this.client.put<number>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/studies/rejectstudy/${studyId}`,
            {}
        );
    }

    getPendingSitesByStudyId(studyId: number): Observable<ClinTrakStudySiteViewModel[]> {
        return this.client.get<ClinTrakStudySiteViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/sites/${studyId}`
        );
    }

    getStudySiteAddress(studySiteId: string): Observable<ClinTrakStudySiteAddressViewModel> {
        return this.client.get<ClinTrakStudySiteAddressViewModel>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/sites/address/${studySiteId}`
        );
    }

    getStudyVisits(studyCode: string): Observable<ClinTrakStudyVisitViewModel[]> {
        return this.client.get<ClinTrakStudyVisitViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/studies/study/visits/${studyCode}`
        );
    }

    existsInClinTrak(patientId: number): Observable<boolean> {
        return this.client.get<boolean>(
            `${this.siteService.getSiteBaseUrl()}/api/clintrak/patients/exists/${patientId}`
        );
    }
}
