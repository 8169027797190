<div class="o-small-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-card *ngIf="!isLoading && errorMessage">
                    <medpace-card-header>
                        <div class="o-grid__row">
                            <div class="o-grid__item">
                                <medpace-alert [content]="errorMessage" [additionalClass]="'danger'"></medpace-alert>
                            </div>
                        </div>
                    </medpace-card-header>
                </medpace-card>
                <medpace-card *ngIf="!isLoading && !errorMessage">
                    <medpace-card-header>
                        <div class="o-grid__row" *ngIf="!isExistingUser">
                            <div class="o-grid__item">
                                <medpace-alert
                                    content="If you have a Medpace account sign in please."
                                    [additionalClass]="'primary'"
                                ></medpace-alert>
                            </div>
                        </div>
                    </medpace-card-header>

                    <medpace-card-content>
                        <div class="o-grid">
                            <div class="o-grid__row">
                                <div [className]="isExistingUser ? 'o-grid__item margin-bottom-0' : 'o-grid__item'">
                                    <div class="c-info__block">
                                        <mds-button
                                            width="100%"
                                            label="Sign In"
                                            [variant]="'primary'"
                                            [id]="'signInBtn'"
                                            (clickEvent)="signIn()"
                                        ></mds-button>
                                    </div>
                                </div>
                            </div>
                            <div class="o-grid__row" *ngIf="!isExistingUser">
                                <div class="o-grid__item margin-bottom-0">
                                    <div class="c-info__block">
                                        <mds-button
                                            width="100%"
                                            label="Register"
                                            [variant]="'primary'"
                                            [id]="'registerBtn'"
                                            (clickEvent)="register()"
                                        ></mds-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </medpace-card-content>
                </medpace-card>
            </div>
        </div>
    </div>
</div>

<div class="o-grid__row" *ngIf="isLoading">
    <div class="o-grid__item">
        <medpace-loading-card></medpace-loading-card>
    </div>
</div>
