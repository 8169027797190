<ng-template #loading>
    <medpace-loading-card></medpace-loading-card>
</ng-template>

<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h1>User Account</h1>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <user-personal-info-card
                    [firstName]="vm?.accountData.firstName"
                    [middleName]="vm?.accountData.middleName"
                    [lastName]="vm?.accountData.lastName"
                    [phoneNumber]="vm?.accountData.phoneNumber"
                    [emailAddress]="vm?.accountData.emailAddress"
                ></user-personal-info-card>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-table
                    (rowClickEvent)="onStudyRowClicked($event)"
                    [columns]="columns"
                    [data]="studies"
                    [fieldMap]="columnMap"
                    [ifFiltering]="false"
                    [isSearching]="true"
                    title="Active Studies"
                    [defaultSorting]="{ columnName: 'sponsor', direction: 'asc' }"
                ></medpace-table>
            </div>
        </div>
    </div>
</div>
