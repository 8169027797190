import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnvConfigurationService } from '@services/env/env-configuration.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UserService } from '@services/user/user.service';
import { ErrorDisplayService } from './error-display-service';
import { ErrorService } from './error-service';

export interface IApplicationInsightsFactory {
    createApplicationInsightsInstance(connectionString: string, router: Router): ApplicationInsights | null;
}

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsFactory implements IApplicationInsightsFactory {
    private angularPlugin = new AngularPlugin();

    constructor(
        private snackbarService: SnackbarService,
        private envService: EnvConfigurationService,
        private userService: UserService,
        private errorStateService: ErrorService
    ) {}

    createApplicationInsightsInstance(connectionString: string, router: Router): ApplicationInsights | null {
        if (connectionString !== null && connectionString !== undefined && connectionString !== '') {
            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: connectionString,
                    enableAutoRouteTracking: true,
                    extensions: [this.angularPlugin],
                    extensionConfig: {
                        [this.angularPlugin.identifier]: {
                            router: router,

                            // Application insights is registered as a main handler for errors, but it allows other error handlers to run. They are specified here.
                            errorServices: [
                                new ErrorDisplayService(
                                    this.snackbarService,
                                    this.envService,
                                    this.userService,
                                    this.errorStateService
                                ),
                            ],
                        },
                    },
                },
            });
            return appInsights;
        } else {
            // Allow AppInsights integration to be optional. We might want to not enable logging to Insights on local environments or during automated tests.
            return null;
        }
    }
}
