<mds-dialog dialogTitle="Upload Sites from Spreadsheet" *ngIf="{ value: vm$ | async } as vm">
    <mds-dialog-content>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="example-custom-placeholder" *cdkDragePlaceholder></div>
                    <medpace-logo-upload
                        [acceptableTypes]="acceptableFileTypes"
                        [isSpreadsheetUpload]="true"
                        (emitStuff)="getSpreadsheet($event)"
                        [forParsing]="true"
                        uploadButtonTitle="Upload Spreadsheet"
                    >
                    </medpace-logo-upload>
                </div>
            </div>
            <div *ngIf="vm.value !== null">
                <div class="o-grid__row" *ngIf="vm.value.parsingResult !== null">
                    <div class="o-grid__item">
                        <div *ngIf="vm.value.parsingResult.isSuccess === true" style="color: green">
                            Correct file schema
                        </div>
                        <div *ngIf="vm.value.parsingResult.isSuccess === false" style="color: red">
                            Incorrect file schema
                        </div>
                    </div>
                </div>

                <div *ngIf="vm.value.dataErrors.length !== 0">
                    <div class="o-grid__row" *ngFor="let error of vm.value.dataErrors">
                        <div class="o-grid__item">
                            <div style="color: red">{{ error }}</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="vm.value.canBeUploaded === true">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <p>You are about to add {{ vm.value.sites.length }} sites to your study</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'end'">
        <mds-button
            [id]="'cancel-btn'"
            mat-button
            mat-dialog-close
            variant="outline"
            label="Cancel"
            width="auto"
            (clickEvent)="onCancelClicked()"
        ></mds-button>
        <mds-button
            [id]="'site-submit-btn'"
            [disabled]="!(vm.value !== null && vm.value.canBeUploaded === true)"
            mat-button
            variant="primary"
            label="Submit"
            width="auto"
            (clickEvent)="onSubmitClicked()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
