import { Injectable } from '@angular/core';
import { CurrencyViewModel } from '@models/currency';
import { FormDataService } from '@services/form-data/form-data.service';
import { Observable, ReplaySubject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CurrencyStateService {
    constructor(private formService: FormDataService) {}
    private readonly currencies = new ReplaySubject<CurrencyViewModel[]>();
    public init(): void {
        this.formService
            .getCurrencies()
            .pipe(take(1))
            .subscribe((res) => {
                let currencyViewModel = res.map((currency) => {
                    return <CurrencyViewModel>{ ...currency, value: currency.isocode, viewValue: currency.isocode };
                });

                return this.currencies.next(currencyViewModel);
            });
    }

    public getCurrencies(): Observable<CurrencyViewModel[]> {
        return this.currencies.asObservable();
    }
}
