<medpace-card>
    <medpace-card-header>
        <div class="c-card__ribbon">
            <div class="c-card__item c-card__item--left mb-2">
                <h3>Personal Information</h3>
            </div>
            <mds-button
                (clickEvent)="changePassword()"
                label="Change Password"
                [variant]="'outline'"
                [id]="'changePasswordBtn'"
            ></mds-button>
        </div>
    </medpace-card-header>
    <medpace-card-content>
        <div class="c-form">
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>
                                First/Given Name
                                <i class="asListedOnGovIDLabel">(as listed on Government ID)</i>
                            </h5>
                            <span>{{ firstName }}</span>
                        </div>
                    </div>
                </div>

                <div class="o-grid__row" *ngIf="middleName">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>
                                Middle Name
                                <i class="asListedOnGovIDLabel">(as listed on Government ID)</i>
                            </h5>
                            <span>{{ middleName }}</span>
                        </div>
                    </div>
                </div>

                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>
                                Last/Surname
                                <i class="asListedOnGovIDLabel">(as listed on Government ID)</i>
                            </h5>
                            <span>{{ lastName }}</span>
                        </div>
                    </div>
                </div>

                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Email Address</h5>
                            <span>{{ emailAddress }}</span>
                        </div>
                    </div>
                </div>

                <div class="o-grid__row" *ngIf="phoneNumber">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Phone Number</h5>
                            <span>{{ phoneNumber }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </medpace-card-content>
</medpace-card>
