<div class="o-grid__row">
    <div class="o-grid__item">
        <div *ngIf="!loaderSpinner; else spinner">
            <div>
                <ng-container
                    *ngIf="{
                        canChangeToPaymentOnly: canChangeToPaymentOnly$ | async,
                        canChangeToLimitedService: canChangeToLimitedService$ | async,
                    } as vm"
                >
                    <mds-radio-group
                        [buttons]="studyRadioButtons"
                        [formGrp]="radioButtonStudyTierFormGroup"
                        formCtrlName="radioGroupControl"
                        id="studyTierSelect"
                        (changeEvent)="emitChange($event.value, { id: $event.source.id })"
                    />
                    <p
                        class="limited-service-warning"
                        *ngIf="isCanChangeToLimitedLoaded && !vm.canChangeToLimitedService"
                    >
                        {{ canChangeToLimitedServiceMessage }}
                    </p>
                    <p class="payment-only-warning" *ngIf="isCanChangeToPaymentLoaded && !vm.canChangeToPaymentOnly">
                        {{ canChangeToPaymentOnlyMessage }}
                    </p>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #spinner>
    <medpace-loading-card></medpace-loading-card>
</ng-template>
