import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {
    EditableCardComponent,
    EditableCardMode,
} from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { VMCRCCard2 } from '@models/general-models';
import { IUser } from '@models/interfaces/iUser';
import { CRCData } from '@models/site';
import { User } from '@models/user';
import { MdsOptionGeneric, buildSnackBar, collectErrors, filterAsync, toStringOrFallback } from '@utility/utility';
import { inArrayAsyncValidator } from '@utility/utility.validators';
import {
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    filter,
    map,
    skipWhile,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
@Component({
    selector: 'medpace-crc-card2',
    templateUrl: './crc-card2.component.html',
    styleUrls: ['./crc-card2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CRCCard2Component extends EditableCardComponent<CRCData> {
    @Input() set vm(value: VMCRCCard2) {
        this.vmSubject.next(value);
    }
    @Input() mode: EditableCardMode = 'view';
    @Input() showSwitchModeButton: boolean = true;

    protected readonly radioButtons: MdsRadioButton[] = [
        {
            label: 'Yes',
            value: true,
            name: 'hasSupportingCRCs_RadioButton_True',
            id: 'crc-cardYes',
        },
        {
            label: 'No',
            value: false,
            name: 'hasSupportingCRCs_RadioButton_False',
            id: 'crc-cardNo',
        },
    ];
    private readonly vmSubject = new ReplaySubject<VMCRCCard2>();
    private readonly vmObservable$ = this.vmSubject.asObservable();
    private readonly formGroupInit = new Subject<VMCRCCard2>();

    availableOptionsSubject = new ReplaySubject<MdsOptionGeneric<User>[]>();
    availableOptions$ = this.availableOptionsSubject.asObservable();
    supportCRCFilteredOptionsArray: Observable<string[]>[] = [];

    allCrcs: MdsOptionGeneric<User>[] = [];
    allCrcs$ = this.vmObservable$.pipe(
        filter((vm) => !!vm?.crcOptions),
        takeUntil(this.destroySubject),
        map((vm: VMCRCCard2) => vm.crcOptions),
        tap((val) => (this.allCrcs = val))
    );

    private uniqueCRCValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (!!control.value) {
            const controls = this.formGroup.controls.supportingCRCs.controls
                .concat(this.formGroup.controls.primaryCRC)
                .filter((c) => c !== control)
                .filter((c) => !!c.value && c.value === control.value);
            if (controls.length !== 0) {
                controls.forEach((c) => c.markAsTouched());
                return { nonUniqueCRCs: 'All CRCs must be different' };
            } else return null;
        } else {
            return null;
        }
    };

    constructor(private fb: FormBuilder) {
        super();
        this.allCrcs$.subscribe();
    }
    private createUserFormControl(userOption: MdsOptionGeneric<User>): FormControl<string> {
        let formControl: FormControl<string> = new FormControl<string>(
            userOption?.viewValue || '',
            [Validators.required, this.uniqueCRCValidator],
            [
                inArrayAsyncValidator<User>(
                    this.vmObservable$.pipe(
                        filter((vm) => !!vm),
                        map((vm) => vm.crcOptions)
                    ),
                    'Invalid CRC'
                ),
            ]
        );
        formControl.patchValue(userOption?.viewValue || '');
        return formControl;
    }

    formGroup = this.fb.group({
        primaryCRC: this.createUserFormControl(null),
        hasSupportingCRCs: new FormControl<boolean>(null, [Validators.required]),
        supportingCRCs: new FormArray<FormControl<string>>([]),
    });

    primaryCRCFilteredOptions = filterAsync<User>(this.availableOptionsSubject, this.formGroup.controls.primaryCRC);

    public readonly vm$ = this.vmObservable$.pipe(
        skipWhile((vm) => vm === null),
        switchMap((vm: VMCRCCard2) => {
            this.setupFormGroupAndComponentAfterDataInitialization(vm);
            this.setupMutualValidatorsObservationToAdjustOptions(vm);
            return this.vmObservable$;
        })
    );

    getUserByName(name: string): User {
        return this.allCrcs?.find((crc) => crc.viewValue === name)?.value;
    }

    public readonly hasSupportingCRCsChange$ = this.formGroupInit.pipe(
        switchMap((vm) => this.formGroup.controls.hasSupportingCRCs.valueChanges),
        tap((value) => {
            if (value === true && this.formGroup.controls.supportingCRCs.length === 0) this.addSupportingCRC();
            else if (value === false) this.formGroup.controls.supportingCRCs.clear();
        })
    );
    protected override canChangeMode(mode: EditableCardMode): boolean {
        return mode === 'edit' || this.formGroup.valid;
    }
    protected override onModeChangeFail(mode: EditableCardMode) {
        this.formGroup.markAllAsTouched();
        buildSnackBar(collectErrors(this.formGroup), this.snackbarService);
    }
    public getOutputData(): CRCData {
        let outputData: CRCData;

        let supportingCRCsArray: IUser[] = [];
        this.formGroup.controls.supportingCRCs.controls
            .map((control) => control.value)
            .forEach((supportingCRC) => {
                supportingCRCsArray.push(this.allCrcs.find((crc) => crc.viewValue === supportingCRC).value);
            });
        outputData = <CRCData>{
            primaryCRC: this.allCrcs.find((crc) => crc.viewValue === this.formGroup.controls.primaryCRC.value).value,
            supportingCRCs: supportingCRCsArray,
        };
        return outputData;
    }
    public toCRCInfoContext(context: {
        user: User;
        crcOptions: MdsOptionGeneric<User>[];
        isRemovable: boolean;
        supportingCRCIndex: number;
    }) {
        return context;
    }
    public toUser(user: User) {
        return user;
    }
    private removeSupportingCRCs() {
        this.supportCRCFilteredOptionsArray = [];
        this.formGroup.controls.supportingCRCs.clear();
    }

    public removeSupportingCRC(index: number) {
        this.formGroup.controls.supportingCRCs.removeAt(index);
        this.supportCRCFilteredOptionsArray.splice(index, 1);

        if (this.formGroup.controls.supportingCRCs.length === 0)
            this.formGroup.controls.hasSupportingCRCs.setValue(false);
    }
    public addSupportingCRC(value?: MdsOptionGeneric<User>) {
        const formControl: FormControl<string> = this.createUserFormControl(value ?? null);
        this.supportCRCFilteredOptionsArray.push(filterAsync<User>(this.availableOptionsSubject, formControl));
        this.formGroup.controls.supportingCRCs.push(formControl);
    }
    public toStringOrFallback(object: Object, fallback: string) {
        return toStringOrFallback(object, fallback);
    }

    public getWarningMessage(options: string[], context: any) {
        return options.length === 0 && context.formControl.untouched && context.vm.value.primaryCRC === null
            ? 'To add a CRC, you must first assign him to study'
            : '';
    }

    private setupFormGroupAndComponentAfterDataInitialization(vm: VMCRCCard2): void {
        this.formGroup.patchValue(
            {
                primaryCRC: !!vm.primaryCRC ? vm.primaryCRC.firstName + ' ' + vm.primaryCRC.lastName : '',
                hasSupportingCRCs: vm.hasSupportingCRCs,
            },
            { emitEvent: false }
        );
        this.removeSupportingCRCs();
        vm.supportingCRCs.forEach((crc) => {
            this.addSupportingCRC(<MdsOptionGeneric<User>>{
                value: crc,
                viewValue: crc.firstName + ' ' + crc.lastName,
            });
        });
        this.formGroupInit.next(vm);
    }

    private setupMutualValidatorsObservationToAdjustOptions(vm: VMCRCCard2): void {
        combineLatest([
            this.formGroup.controls.supportingCRCs.valueChanges.pipe(startWith([])),
            this.formGroup.controls.primaryCRC.valueChanges.pipe(
                startWith(!!vm.primaryCRC ? vm.primaryCRC.firstName + ' ' + vm.primaryCRC.lastName : '')
            ),
        ])
            .pipe(
                takeUntil(this.destroySubject),
                map(([secondaries, primary]) => {
                    return (
                        vm.crcOptions.filter(
                            (crcOption) =>
                                !secondaries.includes(crcOption?.viewValue) && primary !== crcOption?.viewValue
                        ) ?? []
                    );
                }),
                tap((result) => {
                    this.availableOptionsSubject.next(result);
                })
            )
            .subscribe();
    }
}
