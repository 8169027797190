import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { maxLengthValidator } from '@utility/utility.validators';
@Component({
    selector: 'medpace-delete-request-modal',
    templateUrl: './additional-details-modal.component.html',
    styleUrls: ['./delete-patient-modal.component.scss'],
})
export class MedpaceAdditionalDetailsModalComponent {
    additionalDetailsFormGroup = this.formBuilder.group({
        additionalInfoControl: new FormControl<string>('', [maxLengthValidator(500)]),
    });

    constructor(
        public dialogRef: MatDialogRef<MedpaceAdditionalDetailsModalComponent>,
        private formBuilder: FormBuilder
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    saveDialog(): void {
        if (this.additionalDetailsFormGroup.valid) {
            this.dialogRef.close({
                save: true,
                info: this.additionalDetailsFormGroup.controls.additionalInfoControl.value,
            });
        } else this.additionalDetailsFormGroup.markAllAsTouched();
    }
}
