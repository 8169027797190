<ng-container *ngIf="postInfo">
    <div class="o-grid__row">
        <div class="post">
            <time class="post-date" [dateTime]="postTime.toISOString()">
                {{ getLocalDate(postInfo.timestamp) | dateFormat: DateFormat.dateTime12 : null : 'en-US' | uppercase }}
            </time>
            <p>{{ postMessage }}</p>
        </div>
    </div>
</ng-container>
