import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { CreateInvitationMessageDto } from '@models/accessRequest';
import { Site } from '@models/site';
import { Study } from '@models/study';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { AccessGroupStateService } from '@services/state-management/access-group-state.service';
import { UserStateService } from '@services/state-management/user-state.service';
import { finalize, take } from 'rxjs';
import { MedpaceUserManagementModalComponent } from '../user-management-modal.component';

@Component({
    templateUrl: './send-invitation-modal.component.html',
    styleUrls: ['./send-invitation-modal.component.scss'],
})
export class SendInvitationModalComponent extends MedpaceUserManagementModalComponent {
    isLoading: boolean = false;
    authenticationMethodFieldName: string = 'isLocalAuthCtrl';
    matToolTipTextCheckBox: string = 'Activate Login with SSO. Users with Medpace Accounts Only';
    authenticationMethodRadioButtons: MdsRadioButton[] = [
        <MdsRadioButton>{
            label: 'No',
            value: false,
            name: 'no',
            id: 'no',
        },
        <MdsRadioButton>{
            label: 'Yes',
            value: true,
            name: 'yes',
            id: 'yes',
        },
    ];

    isPrimaryCheckboxGroup = this.formBuilder.group({
        isPrimaryCheckboxControl: new FormControl<boolean>(false),
    });

    constructor(
        public dialogRef: MatDialogRef<MedpaceUserManagementModalComponent>,
        protected adminStudies: AdminStudyServices,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            canInvitePrimaryCRC: boolean;
            siteId: number;
            studyId: number;
        },
        protected accessGroupStateService: AccessGroupStateService,
        protected formBuilder: FormBuilder,
        protected userStateService: UserStateService,
        private accessRequestService: AccessRequestService,
        private snackbarService: SnackbarService,
        public dialog: MatDialog
    ) {
        super(dialogRef, adminStudies, accessGroupStateService, formBuilder, userStateService);
        this.dialogTitle = 'Invite CRC User';
        super._modalDataSetup();
        this.personalDataFormGroup.controls[this.firstNameFieldName].disable();
        this.personalDataFormGroup.controls[this.lastNameFieldName].disable();
        this.personalDataFormGroup.controls[this.phoneNumberFieldName].disable();
        this.assignedAccessGroupFormGroup.controls[this.userAccessGroupFieldName].patchValue(3);
        this.assignedAccessGroupFormGroup.controls[this.userAccessGroupFieldName].disable();

        if (this.data) {
            if (!this.data?.canInvitePrimaryCRC)
                this.isPrimaryCheckboxGroup.controls.isPrimaryCheckboxControl.disable();

            this.selectedStudies.push(<Study>{ id: this.data.studyId });
            this.selectedSites.push(<Site>{ id: this.data.siteId });
        }
    }

    submitClick(): void {
        this.isLoading = true;

        if (this.parentFormGroup.valid) {
            let invitationMessage = <CreateInvitationMessageDto>{
                accessGroupId: this.resultUser.userAccessGroupId,
                userEmail: this.personalDataFormGroup.controls[this.emailAddressFieldName].value,
                studyIds: this.selectedStudies?.map((study) => study.id),
                siteIds: this.selectedSites?.map((site) => site.id),
                isPrimary: this.isPrimaryCheckboxGroup.controls['isPrimaryCheckboxControl'].value,
            };

            this.accessRequestService
                .sendInvitation(invitationMessage)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.dialogRef.close();
                    })
                )
                .subscribe({
                    error: (e) => {
                        console.error('error', e);
                        this.dialog.open(DisplayErrorModalComponent, {
                            autoFocus: false,
                            width: '500px',
                            disableClose: false,
                            data: e.error.ExceptionMessage,
                        });
                    },
                    complete: () =>
                        this.snackbarService.openInfoSnackbar('Invitation message has been sent successfully.'),
                });
        } else {
            this.parentFormGroup.markAllAsTouched();
        }
    }
}
