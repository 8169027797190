<div class="o-page" *ngIf="user$ | async as user">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h1>Dashboard</h1>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-request-dashboard-card [requestTable]="requestTable"></medpace-request-dashboard-card>
            </div>
        </div>
        <div class="o-grid__row" *ngIf="{ vm: activeStudies$ | async } as activeStudies">
            <div class="o-grid__item">
                <medpace-table
                    (rowClickEvent)="doClickRow($event)"
                    [columns]="activeStudiesColumns"
                    [data]="activeStudies.vm || undefined"
                    [fieldMap]="activeStudiesColumnMap"
                    [isSearching]="true"
                    title="Active Studies"
                    [defaultSorting]="{ columnName: 'sponsor', direction: 'asc' }"
                ></medpace-table>
            </div>
        </div>
        <div class="o-grid__row" *ngIf="user.isSuperAdmin">
            <div class="o-grid__item">
                <mds-expansion-panel
                    *ngIf="{ vm: activeClinTrakStudies$ | async } as activeClinTrakStudies; else spinner"
                    [panelTitle]="'Pending Approval Studies'"
                    [description]="''"
                    icon="access_time"
                    [attr.data-cy]="'pendingApprovalStudies'"
                >
                    <medpace-table
                        (rowClickEvent)="goToEditStudy($event)"
                        [columns]="pendingStudiesColumns"
                        [data]="activeClinTrakStudies.vm || undefined"
                        [fieldMap]="pendingStudiesColumnMap"
                        [ifFiltering]="false"
                        [isSearching]="true"
                        title=""
                        [isCard]="false"
                    ></medpace-table>
                </mds-expansion-panel>
            </div>
        </div>
    </div>
</div>
<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
