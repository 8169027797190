import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, map, take } from 'rxjs';

export function getDisabledControls(formGroup: FormGroup): string[] {
    const disabledControls = Object.keys(formGroup.controls).filter((control) => formGroup.get(control).disabled);
    return disabledControls;
}

export function getEnabledControls(formGroup: FormGroup): string[] {
    const enabledControls = Object.keys(formGroup.controls).filter((control) => formGroup.get(control).enabled);
    return enabledControls;
}

export function disableControls(formGroup: FormGroup, controlNames: string[]): void {
    controlNames.forEach((control) => {
        formGroup.get(control).disable();
    });
}

export function enableControls(formGroup: FormGroup, controlNames: string[]): void {
    controlNames.forEach((control) => {
        formGroup.get(control).enable();
    });
}

/**
 * Validator that checks if all values in given array are not the same as a given value
 * @param array Array of values
 * @param value checked value
 * @param message error message
 * @returns null if valid, message if invalid
 */
export function arrayItemsCheckValidator(
    value: any,
    message: string
): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
        const array = [
            control.value?.fieldFormArrayAirlinePrefsRequest?.find((x) => x !== undefined)
                ?.radioGroupControlAirlinePrefs,
            control.value?.fieldFormArrayTrainPrefsRequest?.find((x) => x !== undefined)
                ?.radioGroupControlTrainTravelReq,
            control.value?.fieldFormArrayLodgingPrefsRequest?.find((x) => x !== undefined)
                ?.radioGroupControlLodgingForRequest,
            control.value?.fieldFormArrayRentalCarPrefsRequest?.find((x) => x !== undefined)
                ?.radioGroupControlRentalCarForRequest,
            control.value?.fieldFormArrayOtherPrefsRequest?.find((x) => x !== undefined)?.radioGroupControlOther,
        ];
        const needsTransfer = control.value?.fieldFormArrayGroundPrefsRequest?.['needsTransfer'] as boolean;
        array.push(needsTransfer === true ? 'yes' : 'no');

        if (!Array.isArray(array)) {
            return { invalidArray: true };
        }

        if (array.length === 0) {
            return null;
        }
        for (let i = 0; i < array.length; i++) {
            if (array[i] !== value) {
                return null;
            }
        }
        return { invalidArray: message };
    };
}
export function requiredIfAsync(predicate: Observable<boolean>): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        return predicate.pipe(
            map((value) => {
                if (value && (control.value === null || control.value === '')) return { required: true };
                else return null;
            }),
            take(1)
        );
    };
}
