/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { OtherHistoryData } from '@models/history-data/otherHistoryData';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf, getUniqueObjects } from '@utility/utility';
import { maxLengthValidator } from '@utility/utility.validators';

export class OtherRequestDetailsFormGroup extends PersistentFormGroup<{
    specialRequests: PersistentFormControl<string>;
}> {
    public static create(value: FormGroupValueOf<OtherRequestDetailsFormGroup>): OtherRequestDetailsFormGroup {
        return new OtherRequestDetailsFormGroup({
            specialRequests: new PersistentFormControl(value.specialRequests, [
                Validators.required,
                maxLengthValidator(1000),
            ]),
        });
    }
}
@Component({
    selector: 'medpace-other-request-details',
    templateUrl: './other-request-details.component.html',
    styleUrls: ['./other-request-details.component.scss'],
})
export class OtherRequestDetailsComponent {
    @Input() mode: EditableCardMode;
    @Input() formGroup: OtherRequestDetailsFormGroup;
    @Input() set historyData(value: OtherHistoryData[]) {
        // every component should create its own MdsOptions so they dont share values
        this.autocompleteOptions = {
            specialRequests: getUniqueObjects(
                value
                    .filter((data) => !!data.specialRequest)
                    .map(
                        (data) =>
                            <MedpaceAutocompleteObject>{ requestDate: data.requestDate, value: data.specialRequest }
                    )
            ),
        };
    }
    protected autocompleteOptions: {
        specialRequests: MedpaceAutocompleteObject[];
    };
}
