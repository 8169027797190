import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnDestroy, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { ClinTrakStudySiteViewModel } from '@models/clintrak';
import { Site } from '@models/site';
import { StudyInformation } from '@models/study';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { transformToSiteDataModel } from '@services/transforms/site-transform';
import { filter, finalize, map, of, switchMap, take, tap } from 'rxjs';
import { DisplayErrorModalComponent } from '../display-error-modal/display-error-modal.component';

@Component({
    selector: 'site-clintrak-changes-modal',
    templateUrl: './site-clintrak-changes-modal.component.html',
    styleUrls: ['./site-clintrak-changes-modal.component.scss'],
})
export class SiteClintrakChangesModalComponent implements OnDestroy {
    data = inject<{ id: number }>(MAT_DIALOG_DATA);
    data$ = of(this.data);
    public dialog: MatDialog = inject(MatDialog);
    private dialogRef = inject(DialogRef);
    private clintrakDataService = inject(ClinTrakDataService);
    private snackBarService = inject(SnackbarService);
    private siteStateService = inject(SiteStateService);
    private siteService = inject(AdminSiteServices);
    formBuilder = inject(FormBuilder);
    datePipe = inject(dateFormatPipe);
    parentFormGroup = new FormGroup({
        siteNumberControl: new FormControl<string>(''),
        nameControl: new FormControl<string>(''),
        pilastNameControl: new FormControl<string>(''),
    });
    siteInfoFormGroupName: string = 'Site Information CardFormGroup';
    content: StudyInformation = <StudyInformation>{};
    existingClinTrakData: ClinTrakStudySiteViewModel;
    isLoading: boolean = true;

    clinTrakData$ = this.data$.pipe(
        take(1),
        filter(Boolean),
        tap((data) => this.siteStateService.setSite(data.id)),
        switchMap((data) => this.clintrakDataService.getStudySiteDataByPcsSiteId(data.id)),
        tap((clinTrakData) => {
            if (clinTrakData) {
                this.existingClinTrakData = clinTrakData;
                this.setClinTrakData();
            }
        }),
        finalize(() => (this.isLoading = false))
    );

    ngOnDestroy(): void {
        this.siteStateService.clearStore();
    }

    setClinTrakData() {
        this.parentFormGroup.controls.siteNumberControl.setValue(this.existingClinTrakData.studySiteCenterId);
        this.parentFormGroup.controls.nameControl.setValue(this.existingClinTrakData.siteName);
        this.parentFormGroup.controls.pilastNameControl.setValue(this.existingClinTrakData.primaryInvestigator);

        this.parentFormGroup.controls.siteNumberControl.disable();
        this.parentFormGroup.controls.nameControl.disable();
        this.parentFormGroup.controls.pilastNameControl.disable();
    }

    submit() {
        this.updateExistingSite();
    }

    setData() {
        let siteInfoFormGroup = this.parentFormGroup.controls[this.siteInfoFormGroupName] as FormGroup;
        siteInfoFormGroup.controls.nameControl.setValue(this.parentFormGroup.controls.nameControl.value);
        siteInfoFormGroup.controls.pilastNameControl.setValue(this.parentFormGroup.controls.pilastNameControl.value);
    }

    closeModal() {
        this.dialogRef.close(null);
    }

    updateExistingSite() {
        this.siteStateService
            .getSite()
            .pipe(
                take(1),
                map((site) => this.mapClinTrakData(site)),
                switchMap((site: Site) => {
                    let model = transformToSiteDataModel(site);
                    return this.siteService.updateSite(model);
                })
            )
            .subscribe({
                error: (e) => {
                    console.error('error', e);
                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: e.error.ExceptionMessage,
                    });
                },
                complete: () => {
                    this.siteStateService.setSite(this.data?.id);
                    this.snackBarService.openInfoSnackbar(
                        `Updated site ${this.existingClinTrakData.studySiteCenterId}`
                    );
                    this.closeModal();
                },
            });
    }

    mapClinTrakData(site: Site) {
        let siteDetailsFormGroup = this.parentFormGroup.controls[this.siteInfoFormGroupName] as FormGroup;
        site.info.name = siteDetailsFormGroup.controls.nameControl.value;
        site.info.pilastName = siteDetailsFormGroup.controls.pilastNameControl.value;
        return site;
    }
}
