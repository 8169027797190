import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnnouncementModalComponent } from '@components/atoms/announcement-modal/announcement-modal/announcement-modal.component';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { AnnouncementService } from '@services/announcement/announcement.service';
import { RequestStatusEnum } from '@services/request-status/request-status.service';
import { MdsOptionGeneric } from '@utility/utility';
import { Subject, combineLatest, map, takeUntil } from 'rxjs';
import { Study } from '../../models/study';
import { CRCStudyServices } from '../../services/crc/crc-study.service';

@Component({
    selector: '',
    templateUrl: './crc-dashboard.component.html',
})
export class CRCDashboardComponent implements OnInit, OnDestroy {
    studies: Study[];
    regions: MdsOptionGeneric<string>[];

    columns: string[] = ['sponsor', 'protocol', 'indication', 'status', 'requests'];
    private componentDestroyed$: Subject<boolean> = new Subject();

    columnMap: any[] = [
        { header: 'sponsor name', name: 'sponsor' },
        { header: 'protocol', name: 'protocol' },
        { header: 'Indication', name: 'indication' },
        { header: 'clinTrak Status', name: 'status' },
        { header: 'requests', name: 'requests' },
    ];

    constructor(
        private router: Router,
        private crcStudies: CRCStudyServices,
        private announcementService: AnnouncementService,
        private requestsService: AdminRequestServices,
        private matDialog: MatDialog
    ) {}

    private announcementDialogRef: MatDialogRef<AnnouncementModalComponent>;

    ngOnInit(): void {
        this.regions = [];
        this.crcStudies
            .getAllStudySummaries()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.studies = result;
                // result.forEach((study) => {
                //     this.populateRegionFilter(study.region);
                // });
            });
        this.showAnnouncementsModal();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
    doClickRow(event: any): void {
        this.router.navigate([`studies/${event.id}`], { state: event });
    }

    crcShowRedirectToReimbursementRequests$ = this.requestsService
        .getCrcUserReimbursementRequestCount([RequestStatusEnum.PendingSiteVerification])
        .pipe(
            map((numberOfPendingRequests) => {
                let showRedirectToRequest = sessionStorage.getItem('showRedirectToRequests');
                if (showRedirectToRequest === 'false') return false;

                return !!numberOfPendingRequests && numberOfPendingRequests > 0;
            })
        );

    showAnnouncementsModal() {
        combineLatest([
            this.crcShowRedirectToReimbursementRequests$,
            this.announcementService.getAnnouncementsToDisplay(),
        ])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(([crcShowRedirectToReimbursementRequests, announcements]) => {
                if (announcements.length != 0 || crcShowRedirectToReimbursementRequests) {
                    this.matDialog.open(AnnouncementModalComponent, {
                        data: {
                            announcements: announcements,
                            showRedirectToRequests: crcShowRedirectToReimbursementRequests,
                        },
                    });
                }
            });
    }

    populateRegionFilter(value: string) {
        let values = value.split(', ');
        values.forEach((region) => {
            if (this.regions.filter((x) => x.value === region)?.length === 0) {
                this.regions.push(<MdsOptionGeneric<string>>{ value: region, viewValue: region });
            }
        });
    }
}
