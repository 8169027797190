<mds-dialog [dialogTitle]="'The operation has not been completed'">
    <mds-dialog-content style="display: flex; flex-direction: column; justify-items: center; align-items: center">
        <div class="inner-html" [innerHTML]="bodyText"></div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            [id]="'cancel-btn'"
            mat-button
            mat-dialog-close
            label="Close"
            variant="outline"
            (clickEvent)="close()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
