import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from '@models/country';
import { Observable } from 'rxjs';
import { SiteService } from 'src/app/services/site/site.service';

@Injectable({
    providedIn: 'root',
})
export class FormDataRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getRegions: () => Observable<any> = () => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/regions`);
    };

    getTravelOptions: () => Observable<any> = () => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/traveloptions`);
    };

    getCountries: () => Observable<Country[]> = () => {
        return this.client.get<Country[]>(`${this.siteService.getSiteBaseUrl()}/api/countries`);
    };
    getActiveCountries: () => Observable<any> = () => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/countries/active`);
    };

    getCurrencies: () => Observable<any> = () => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/currencies`);
    };

    getAccessGroups: () => Observable<any[]> = () => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/accessgroups`);
    };
}
