import { AnnouncementFilterOption, AnnouncementFilterOptionDTO } from '../../models/announcementFilterOption';

export function mapAnnouncementFilterOptionToAnnouncementFilterOptionDTO(
    model: AnnouncementFilterOption
): AnnouncementFilterOptionDTO {
    return <AnnouncementFilterOptionDTO>{
        ...model,
    };
}
export function mapAnnouncementFilterOptionDTOToAnnouncementFilterOption(
    dto: AnnouncementFilterOptionDTO
): AnnouncementFilterOption {
    return <AnnouncementFilterOption>{
        ...dto,
    };
}
