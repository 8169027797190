<mds-dialog dialogTitle="Data comparison">
    <mds-dialog-content>
        <div class="spinnerOverlay" *ngIf="isLoading">
            <medpace-loading-card />
        </div>
        <ng-container *ngIf="clinTrakData$ | async as clinTrakData">
            @if (clinTrakData) {
                <ng-container
                    *ngIf="existingClinTrakData"
                    [ngTemplateOutlet]="dialog_body"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                />
            }
        </ng-container>
        <ng-container *ngIf="!existingClinTrakData && !isLoading">
            <ng-container [ngTemplateOutlet]="noDataAlert" />
        </ng-container>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'end'">
        <mds-button variant="outline" label="Close" data-cy="close-modal" (clickEvent)="closeModal()" />
        <mds-button
            *ngIf="existingClinTrakData"
            data-cy="delete-request"
            variant="primary"
            label="Set ClinTrak data"
            (clickEvent)="setData()"
        />
        <mds-button
            *ngIf="existingClinTrakData"
            data-cy="submit-request"
            variant="primary"
            label="Submit"
            type="submit"
            (clickEvent)="submit()"
        />
    </mds-dialog-actions>
</mds-dialog>

<ng-template #dialog_body let-data>
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item"><h2>ClinTrak</h2></div>
            <div class="o-grid__item"><h2>Current</h2></div>
        </div>
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item"></div>
                <div class="o-grid__item"></div>
            </div>
        </div>
        <div class="o-grid__row" name="" id="studyDetails">
            <div class="o-grid__item">
                <div class="o-grid">
                    <div class="c-card">
                        <div class="c-card__header">
                            <div class="c-card__ribbon">
                                <div class="c-card__item c-card__item--left mb-2">
                                    <h3>Study Details</h3>
                                </div>
                                <div class="header-buttons"></div>
                            </div>
                        </div>
                        <div class="c-card__content">
                            <div class="o-grid">
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="o-grid">
                                            <div class="o-grid__row">
                                                <div class="o-grid__field">
                                                    <mds-text-field
                                                        id="sponsor"
                                                        [formCtrl]="parentFormGroup.controls.sponsorControl"
                                                        placeholder="Type here..."
                                                        appearance="outline"
                                                        inputType="text"
                                                        floatLabel="always"
                                                        label="Sponsor"
                                                        width="100%"
                                                        [required]="true"
                                                        [disabled]="true"
                                                    ></mds-text-field>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__field">
                                                    <mds-text-field
                                                        id="protocol"
                                                        [formCtrl]="parentFormGroup.controls.protocolControl"
                                                        placeholder="Type here..."
                                                        appearance="outline"
                                                        inputType="text"
                                                        floatLabel="always"
                                                        label="Protocol"
                                                        width="100%"
                                                        [disabled]="true"
                                                        [required]="true"
                                                    ></mds-text-field>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__field">
                                                    <mds-text-field
                                                        id="studyCode"
                                                        [formCtrl]="parentFormGroup.controls.projectIdControl"
                                                        placeholder="Type here..."
                                                        appearance="outline"
                                                        inputType="text"
                                                        floatLabel="always"
                                                        label="Project ID"
                                                        width="100%"
                                                        [disabled]="true"
                                                        [required]="true"
                                                    ></mds-text-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-grid__item">
                <medpace-input-card
                    [showEditSaveButton]="false"
                    [editMode]="true"
                    [isClinTrakComparison]="true"
                    type="study-detail"
                    title="Study Details"
                    [parentFormGroup]="parentFormGroup"
                >
                </medpace-input-card>
            </div>
        </div>
        <div class="o-grid__row" name="" id="studyInfo">
            <div class="o-grid__item">
                <div class="o-grid">
                    <div class="c-card">
                        <div class="c-card__header">
                            <div class="c-card__ribbon">
                                <div class="c-card__item c-card__item--left mb-2">
                                    <h3>Study Information</h3>
                                </div>
                                <div class="header-buttons"></div>
                            </div>
                        </div>
                        <div class="c-card__content">
                            <div class="o-grid">
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="o-grid">
                                            <div class="o-grid__row">
                                                <div class="o-grid__item">
                                                    <mds-expansion-panel
                                                        [panelTitle]="'Global CTM'"
                                                        [description]="''"
                                                        icon="people"
                                                        [attr.data-cy]="'accessRequestLogExpansion'"
                                                    >
                                                        <div
                                                            class="c-info__block"
                                                            *ngFor="let globalCtm of content?.globalCtm"
                                                        >
                                                            <span>{{ globalCtm.fullName }}</span>
                                                        </div>
                                                    </mds-expansion-panel>
                                                </div>
                                            </div>

                                            <div class="o-grid__row">
                                                <div class="o-grid__item">
                                                    <mds-expansion-panel
                                                        [panelTitle]="'Regional CTMs'"
                                                        [description]="''"
                                                        icon="people"
                                                        [attr.data-cy]="'accessRequestLogExpansion'"
                                                    >
                                                        <div
                                                            class="c-info__block"
                                                            *ngFor="let regionalCtm of content?.regionalCtms"
                                                        >
                                                            <div class="people-view">
                                                                <span style="width: 40%">{{
                                                                    regionalCtm.fullName
                                                                }}</span>
                                                                <span>{{
                                                                    getRegionNamesByGuid(regionalCtm.regionsId)
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                    </mds-expansion-panel>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__item">
                                                    <mds-expansion-panel
                                                        [panelTitle]="'PC'"
                                                        [description]="''"
                                                        icon="people"
                                                        [attr.data-cy]="'accessRequestLogExpansion'"
                                                    >
                                                        <div class="c-info__block" *ngFor="let pc of content?.pc">
                                                            <div class="people-view">
                                                                <span style="width: 40%">{{ pc.fullName }}</span>
                                                                <span>{{ getRegionNamesByGuid(pc.regionsId) }}</span>
                                                            </div>
                                                        </div>
                                                    </mds-expansion-panel>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__item">
                                                    <mds-expansion-panel
                                                        [panelTitle]="'Global RSM'"
                                                        [description]="''"
                                                        icon="people"
                                                        [attr.data-cy]="'accessRequestLogExpansion'"
                                                    >
                                                        <div
                                                            class="c-info__block"
                                                            *ngFor="let globalRsm of content?.globalRsm"
                                                        >
                                                            <span>{{ globalRsm.fullName }}</span>
                                                        </div>
                                                    </mds-expansion-panel>
                                                </div>
                                            </div>
                                            <div class="o-grid__row">
                                                <div class="o-grid__item">
                                                    <mds-expansion-panel
                                                        [panelTitle]="'PRM'"
                                                        [description]="''"
                                                        icon="people"
                                                        [attr.data-cy]="'accessRequestLogExpansion'"
                                                    >
                                                        <div class="c-info__block" *ngFor="let prm of content?.prm">
                                                            <span>{{ prm.fullName }}</span>
                                                        </div>
                                                    </mds-expansion-panel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="o-grid">
                    <div class="o-grid__row" id="studyInfo">
                        <div class="o-grid__item">
                            <medpace-input-card
                                [showEditSaveButton]="false"
                                [editMode]="true"
                                [parentFormGroup]="parentFormGroup"
                                [isClinTrakComparison]="true"
                                title="Study Information"
                                type="study-info"
                            ></medpace-input-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noDataAlert>
    <medpace-alert [content]="'Does not exist ClinTrak data for the Study'" additionalClass="warning"></medpace-alert
></ng-template>
