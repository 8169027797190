import { Directive, Input, OnDestroy, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription, merge, tap } from 'rxjs';

@Directive({
    selector: 'mds-expansion-panel[expansionFormErrorHighlighter]',
})
export class ExpansionFormErrorHighlighterDirective implements OnDestroy {
    private subscriptions = new Array<Subscription>();
    @Input() panelTitle: string;
    private _forms: AbstractControl[];
    @Input() set forms(value: AbstractControl[]) {
        if (!value) return;
        this._forms = value.filter((form) => !!form);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [
            merge(...this._forms.map((form) => form.valueChanges))
                .pipe(
                    tap(() => {
                        let epansionPanelTitleHtmlElement = Array.from(
                            document.querySelectorAll('mat-panel-title')
                        )?.find((el) => el.textContent?.toLowerCase().includes(this.panelTitle.toLowerCase()));

                        if (this._forms.some((f) => f.status === 'INVALID')) {
                            this.renderer.addClass(epansionPanelTitleHtmlElement, 'error-text');
                            this.renderer.removeClass(
                                epansionPanelTitleHtmlElement,
                                'mat-expansion-panel-header-title'
                            );
                        } else {
                            this.renderer.addClass(epansionPanelTitleHtmlElement, 'mat-expansion-panel-header-title');
                            this.renderer.removeClass(epansionPanelTitleHtmlElement, 'error-text');
                        }
                    })
                )
                .subscribe(),
        ];
    }
    constructor(private renderer: Renderer2) {}
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
