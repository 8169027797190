import { SnackbarService } from '@services/snackbar/snackbar.service';
import { getThereCountryNameDictionary } from '@services/transforms/getThereCountryNameDictionary';

// GetThere accepts country names listed in the following table: https://wtools.getthere.net/code/CountryCodes.jsp
export function mapToGetThereCountryName(country: string, snackbar: SnackbarService): string {
    let getThereCountry = '';

    switch (country) {
        case `Bahamas (the)`:
            getThereCountry = `Bahamas`;
            break;
        case `Bolivia (Plurinational State of)`:
            getThereCountry = `Bolivia`;
            break;
        case `Bonaire, Sint Eustatius and Saba`:
            getThereCountry = `Bonaire / St. Eustatius / Saba`;
            break;
        case `Bosnia`:
            getThereCountry = `Bosnia and Herzegovina`;
            break;
        case `British Indian Ocean Territory (the)`:
            getThereCountry = `British Indian Ocean Territory`;
            break;
        case `Cabo Verde`:
            getThereCountry = `Cape Verde`;
            break;
        case `Cayman Islands (the)`:
            getThereCountry = `Cayman Islands`;
            break;
        case `Central African Republic (the)`:
            getThereCountry = `Central African Republic`;
            break;
        case `Cocos (Keeling) Islands (the)`:
            getThereCountry = `Cocos Islands`;
            break;
        case `Comoros (the)`:
            getThereCountry = `Comoros`;
            break;
        case `Congo (the Democratic Republic of the)`:
            getThereCountry = `Congo Dem Rep`;
            break;
        case `Congo (the)`:
            getThereCountry = `Congo`;
            break;
        case `Cook Islands (the)`:
            getThereCountry = `Cook Islands`;
            break;
        case `Curaçao`:
            getThereCountry = `Curacao`;
            break;
        case `Côte d'Ivoire`:
            getThereCountry = `Cote D'Ivoire`;
            break;
        case `Eswatini`:
            getThereCountry = `Swaziland`;
            break;
        case `Falkland Islands (the) [Malvinas]`:
            getThereCountry = `Falkland Islands`;
            break;
        case `Faroe Islands (the)`:
            getThereCountry = `Faroe Islands`;
            break;
        case `French Guiana`:
            getThereCountry = `French Guyana`;
            break;
        case `French Southern Territories (the)`:
            getThereCountry = `French Southern Territories`;
            break;
        case `Gambia (the)`:
            getThereCountry = `Gambia`;
            break;
        case `Heard Island and McDonald Islands`:
            getThereCountry = `Heard and Mc Donald Islands`;
            break;
        case `Holy See (the)`:
            getThereCountry = `Vatican City State`;
            break;
        case `Lao People's Democratic Republic (the)`:
            getThereCountry = `Laos`;
            break;
        case `Macao`:
            getThereCountry = `Macau`;
            break;
        case `Marshall Islands (the)`:
            getThereCountry = `Marshall Islands`;
            break;
        case `Micronesia (Federated States of)`:
            getThereCountry = `Micronesia`;
            break;
        case `Niger (the)`:
            getThereCountry = `Niger`;
            break;
        case `Northern Mariana Islands (the)`:
            getThereCountry = `Northern Mariana Islands`;
            break;
        case `Palestine, State of`:
            getThereCountry = `Palestinian Territory, Occupied`;
            break;
        case `Pitcairn`:
            getThereCountry = `Pitcairn Island`;
            break;
        case `Republic of North Macedonia`:
            getThereCountry = `Macedonia`;
            break;
        case `Réunion`:
            getThereCountry = `Reunion`;
            break;
        case `Saint Barthélemy`:
            getThereCountry = `Saint Barthelemy`;
            break;
        case `Saint Helena, Ascension and Tristan da Cunha`:
            getThereCountry = `St. Helena`;
            break;
        case `Saint Martin (French part)`:
            getThereCountry = `Saint Martin`;
            break;
        case `Saint Pierre and Miquelon`:
            getThereCountry = `St. Pierre and Miquelon`;
            break;
        case `Saint Vincent and the Grenadines`:
            getThereCountry = `St. Vincent and the Grenadines`;
            break;
        case `Sint Maarten (Dutch part)`:
            getThereCountry = `St. Maarten`;
            break;
        case `Sudan (the)`:
            getThereCountry = `Sudan`;
            break;
        case `Svalbard and Jan Mayen`:
            getThereCountry = `Svalbard and Jan Mayen Islands`;
            break;
        case `Syrian Arab Republic`:
            getThereCountry = `Syria`;
            break;
        case `Tajikistan`:
            getThereCountry = `Tadjikistan`;
            break;
        case `Tanzania, United Republic of`:
            getThereCountry = `Tanzania`;
            break;
        case `Turks and Caicos Islands (the)`:
            getThereCountry = `Turks and Caicos Islands`;
            break;
        case `United States Minor Outlying Islands (the)`:
            getThereCountry = `USA, Outlying Islands`;
            break;
        case `United States`:
            getThereCountry = `USA`;
            break;
        case `Virgin Islands (U.S.)`:
            getThereCountry = `Virgin Islands (US)`;
            break;
        case `Wallis and Futuna`:
            getThereCountry = `Wallis and Futuna Islands`;
            break;
        case `Isle of Man`:
        case `South Georgia and the South Sandwich Islands`:
        case `Åland Islands`:
            snackbar.openErrorSnackbar('Country is not supported in GetThere system');
            break;
        default:
            getThereCountry = country;
            break;
    }
    return getThereCountry;
}

// GetThere accepts strings listed in: https://wtools.getthere.net/code/CountryCodes.jsp
export function mapToGetThereCountryCode(country: string, snackbar: SnackbarService): string {
    const getThereCountryName = mapToGetThereCountryName(country, snackbar);
    const getThereCountryCode = getThereCountryNameDictionary[getThereCountryName];

    if (!getThereCountryCode) {
        const errorMessage = `Could not map country name \`${country}\` to a country code - country missing in a dictionary.`;
        snackbar.openErrorSnackbar(errorMessage);
        throw new Error(errorMessage);
    }
    return getThereCountryCode;
}
