export enum VisitNameType {
    COMPLETED = 1,
    NEXT = 2,
    IN_FEATURE = 3,
}

export interface PatientVisitNameHistoryElement {
    sortOrder: number;
    visitName: string;
    visitActualDate: Date | null;
    visitScheduledDate: Date | null;
    sortOrderDate: Date | null;
    visitNameType: VisitNameType;
    requestCount: number;
}

export interface PatientVisitDto {
    id: number;
    visitName: string;
    groupName: string;
    sortOrder: number;
    visitNameType: number;
    actualDate: string | null;
    scheduledDate: string | null;
    sortOrderDate: string | null;
    scheduled: boolean;
    requestCount: number;
    clinTrakPatientVisits: ClinTrakPatientVisit[];
}

export interface ClinTrakPatientVisit {
    actualDate?: string | null;
    scheduledDate?: string | null;
    statusAbbreviation: string;
    statusDescription: string;
    visitNameAdhoc: string;
}
