import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export interface Configuration {
    baseUrl: string;
    production: boolean;
    discoveryDoc: string;
    issuer: string;
    tokenEndpoint: string;
    b2cAppId: string;
    b2cScope: string;
    logoutUrl: string;
    appInsightsConnectionString: string;
    version: string;
    hashVersion: string;
    nonce: string;
}

@Injectable({
    providedIn: 'root',
})
export class EnvConfigurationService {
    // making this static is a hack to allow configuration to be fetched and saved as the application launches, before Angular even starts
    private static _configuration: Configuration;
    public static setConfiguration(configuration: Configuration): void {
        this._configuration = configuration;
        this._configuration.baseUrl = environment.baseUrl;
    }

    public configuration: Configuration = EnvConfigurationService._configuration;

    constructor() {}

    public mapConfigurationToAuthConfig(configuration: Configuration): AuthConfig {
        return new AuthConfig({
            redirectUri: configuration.baseUrl,
            responseType: 'token id_token',
            issuer: configuration.issuer,
            strictDiscoveryDocumentValidation: false,
            clientId: configuration.b2cAppId,
            tokenEndpoint: configuration.tokenEndpoint,
            loginUrl: configuration.tokenEndpoint,
            logoutUrl: configuration.logoutUrl,
            postLogoutRedirectUri: configuration.baseUrl,
            scope: configuration.b2cScope,
            skipIssuerCheck: true,
            clearHashAfterLogin: true,
            oidc: true,
            silentRefreshRedirectUri: configuration.baseUrl + '/silent-refresh.html',
            silentRefreshIFrameName: 'pcs-silent-refresh-iframe',
            // silentRefreshShowIFrame: true, // Uncomment for debugging purposes
            // showDebugInformation: true, // Uncomment for debugging purposes
        });
    }
}
