<ng-container *ngIf="site$ | async as site">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <label>Shipping Address is the same as Primary Site Address? </label>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-radio-group
                    [buttons]="adressRadioButtons"
                    [formCtrl]="addressSameAsPrimaryControl"
                    id="sameAdress"
                    (changeEvent)="selectValue($event)"
                >
                </mds-radio-group>
            </div>
        </div>
    </div>
    <div *ngIf="isEditing && !this.sameAsPrimaryAddress" class="o-grid" [formGroup]="parentFormGroup">
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-text-field
                    id="shipping_line1"
                    [formCtrl]="addressFormGroup.controls.sline1Control"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Address Line 1"
                    width="100%"
                    [required]="true"
                    (input)="emitChange($event.target.value, { id: 'shipping_line1' })"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-text-field
                    id="shipping_line2"
                    [formCtrl]="addressFormGroup.controls.sline2Control"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Address Line 2"
                    width="100%"
                    (input)="emitChange($event.target.value, { id: 'shipping_line2' })"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-text-field
                    id="shipping_city"
                    [formCtrl]="addressFormGroup.controls.scityControl"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="City"
                    width="100%"
                    [required]="true"
                    (input)="emitChange($event.target.value, { id: 'shipping_city' })"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field half">
                <mds-text-field
                    id="shipping_stateOrProvince"
                    [formCtrl]="addressFormGroup.controls.sstateOrProvinceControl"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    [label]="regionStateType$ | async"
                    width="100%"
                    (input)="emitChange($event.target.value, { id: 'shipping_stateOrProvince' })"
                ></mds-text-field>
            </div>
            <div class="o-grid__field half">
                <mds-text-field
                    id="shipping_postalCode"
                    [formCtrl]="addressFormGroup.controls.spostalCodeControl"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Zipcode/Postal Code"
                    width="100%"
                    [required]="true"
                    (input)="emitChange($event.target.value, { id: 'shipping_postalCode' })"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-dropdown
                    [closeOnPageScroll]="false"
                    [formCtrl]="addressFormGroup.controls.sregionControl"
                    appearance="outline"
                    label="Region"
                    placeholder="Please Select Region"
                    [options]="regions$ | async"
                    id="shipping_regionDropdown"
                    [required]="true"
                    (selectionChange)="emitChange($event.value, { id: 'shipping_region' })"
                ></mds-dropdown>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-autocomplete
                    [closeOnPageScroll]="false"
                    [options]="countries$ | async"
                    appearance="outline"
                    placeholder="Please enter country"
                    label="Country"
                    id="shipping_countryAutocomplete"
                    inputType="text"
                    [formCtrl]="addressFormGroup.controls.scountryControl"
                    [required]="true"
                >
                </mds-autocomplete>
            </div>
        </div>
    </div>
    <div *ngIf="!this.sameAsPrimaryAddress && !isEditing && !loaderSpinner && site$ | async as site" class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Address Line 1</h5>
                    <span>{{ site?.shippingAddress?.line1 }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="site?.address?.line2" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Address Line 2</h5>
                    <span>{{ site?.shippingAddress?.line2 }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>City</h5>
                    <span>{{ site?.shippingAddress?.city }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>{{ regionStateType$ | async }}</h5>
                    <span>{{ site?.shippingAddress?.stateOrProvince }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Zipcode/Postal Code</h5>
                    <span>{{
                        site?.shippingAddress?.postalCode ? site?.shippingAddress?.postalCode : 'Unspecified'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Region</h5>
                    <span>{{ site?.shippingAddress?.region }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Country</h5>
                    <span>{{ site?.shippingAddress?.country }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
