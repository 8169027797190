import { Injectable } from '@angular/core';
import { Country } from '@models/country';
import { Observable } from 'rxjs';
import { FormDataRepository } from '../../repositories/form-data.repository';

@Injectable({
    providedIn: 'root',
})
export class FormDataService {
    constructor(private repo: FormDataRepository) {}

    getRegions(): Observable<any[]> {
        return this.repo.getRegions();
    }

    getTravelOptions(): Observable<any[]> {
        return this.repo.getTravelOptions();
    }

    getActiveCountries(): Observable<any[]> {
        return this.repo.getActiveCountries();
    }

    getCountries(): Observable<Country[]> {
        return this.repo.getCountries();
    }

    getCurrencies(): Observable<any[]> {
        return this.repo.getCurrencies();
    }

    getAccessGroups(): Observable<any[]> {
        return this.repo.getAccessGroups();
    }
}
