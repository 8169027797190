import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MedpaceDeletePatientModalComponent } from '@app/admin/manage/modals/delete-patient-modal.component';
import { MedpacePatientChangeStatusModalComponent } from '@app/admin/manage/modals/patient-change-status-modal.component';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { CurrencyViewModel } from '@models/currency';
import { User } from '@models/user';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { Step } from '@services/scroll/steps-interface';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { UserService } from '@services/user/user.service';
import { disableControls, enableControls, getDisabledControls } from '@utility/form-utils';
import { buildSnackBar, collectErrors, markFormGroupTouched } from '@utility/utility';
import { Subject, Subscription } from 'rxjs';
import { InputChange } from 'src/app/models/event-objects/input-change';
import {
    Patient,
    PatientAccessibilityRequirements,
    PatientAirlinePreferences,
    PatientIdentification,
    PatientInternationalPreferences,
    PatientLodgingPreferences,
    PatientTrainPreferences,
    PatientTravelPreferences,
    createNewPatient,
} from 'src/app/models/patient';
import { Site, SiteServices } from 'src/app/models/site';
import { Study } from 'src/app/models/study';
import { PatientStatusService } from '../../../services/patient-status/patient-status.service';

@Component({
    selector: 'medpace-patient',
    templateUrl: './patient.component.html',
    styleUrls: ['./patient.component.scss'],
})
export class MedpacePatientComponent implements OnChanges, OnInit, OnDestroy {
    @Input()
    study: Study;

    @Input()
    site: Site;

    @Input()
    patient: Patient;

    @Input()
    isUpdate: boolean;

    @Input()
    isAdmin: boolean;

    @Input()
    isSuperAdmin: boolean;

    @Output()
    submitClickEvent = new EventEmitter();

    @Output()
    identificationChangeEvent = new EventEmitter<PatientIdentification>();

    @Output()
    consentChangeEvent = new EventEmitter<Patient>();

    @Output()
    accessibilityChangeEvent = new EventEmitter<PatientAccessibilityRequirements>();

    @Output()
    travelChangeEvent = new EventEmitter<PatientTravelPreferences>();

    patientFormGroup: FormGroup;

    validationMessage: string = `Please complete the following:`;
    siteServices: SiteServices;
    paymentRequired: boolean = false;
    countries: string[] = [];
    currencies: CurrencyViewModel[] = [];
    label: string = 'Not defined';
    subscriptions: Subscription[] = [];
    bannerExpanded: boolean = false;

    private studyInformationStep: Step = {
        name: 'Study Details',
        isActive: true,
        identifier: 'studyInformation',
    };
    private consentFormStep: Step = {
        name: 'Consent Form',
        isActive: false,
        identifier: 'patientConsent',
    };
    private caregiverInformationStep: Step = {
        name: 'Caregiver Information',
        isActive: false,
        identifier: 'caregiverInformation',
    };
    private personalInformationStep: Step = {
        name: 'Personal Information',
        isActive: false,
        identifier: 'patientIdentification',
    };
    private additionalInformationStep: Step = {
        name: 'Additional Information',
        isActive: false,
        identifier: 'additionalInformation',
    };
    private paymentStep: Step = {
        name: 'Payment Information',
        isActive: false,
        identifier: 'paymentInformation',
    };
    private travelStep: Step = {
        name: 'Travel Information',
        isActive: false,
        identifier: 'travelPreferences',
    };
    private defaultSteps: Step[] = [
        this.studyInformationStep,
        this.consentFormStep,
        this.personalInformationStep,
        this.caregiverInformationStep,
        this.additionalInformationStep,
    ];
    currentSteps: Step[] = this.defaultSteps.slice();
    public readonly user$ = this.userService.getUser();

    private static createUpdatePatientClickSubject = new Subject<'create' | 'update'>();
    public static createUpdatePatientClick$ = MedpacePatientComponent.createUpdatePatientClickSubject.asObservable();
    constructor(
        private adminPatientServices: AdminPatientServices,
        public dialog: MatDialog,
        private router: Router,
        private snackbarService: SnackbarService,
        private renderer: Renderer2,
        private patientStatusService: PatientStatusService,
        private patientStateService: PatientStateService,
        private userService: UserService,
        private googleMapsService: GoogleMapsService
    ) {
        this.patientFormGroup = new FormGroup({});
    }
    ngOnDestroy(): void {
        this.googleMapsService.clearPlaceObservable();
        this.subscriptions.forEach((x) => x.unsubscribe());
    }
    ngOnInit(): void {
        this.isUpdate ? (this.label = 'Update Patient') : (this.label = 'Create Patient');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.site && changes.site.currentValue) {
            this.siteServices = changes.site.currentValue.services;

            let travel = this.siteServices.travel;
            let reimbursementOrStipend = this.siteServices.reimbursement || this.siteServices.stipend;

            if (travel || reimbursementOrStipend) {
                this.currentSteps = this.defaultSteps.slice(); // prepare a copy of default steps, after which payment or travel steps can be added
            }

            if (reimbursementOrStipend) {
                this.currentSteps.splice(4, 0, this.paymentStep);
            }
            if (travel) {
                this.currentSteps.splice(4, 0, this.travelStep);
            }

            this.paymentRequired = reimbursementOrStipend;
        }
    }

    buttonClick(event: any): void {
        if (this.patientFormGroup.valid) {
            MedpacePatientComponent.createUpdatePatientClickSubject.next(this.isUpdate ? 'update' : 'create');
            this.submitClickEvent.emit(event);
        } else markFormGroupTouched(this.patientFormGroup);

        const disabledControls = getDisabledControls(this.patientFormGroup);
        enableControls(this.patientFormGroup, disabledControls);
        //should display snackbar only when form invalid
        if (this.isUpdate && this.patientFormGroup.invalid) {
            buildSnackBar(collectErrors(this.patientFormGroup), this.snackbarService);
        }
        disableControls(this.patientFormGroup, disabledControls);
    }

    submitIncrementalSave(): void {
        this.patientFormGroup.valid
            ? this.submitClickEvent.emit({ type: 'incrementalSave', doRoute: false })
            : markFormGroupTouched(this.patientFormGroup);
    }

    fieldChangeEvent(event: InputChange): void {
        const ident = this.mapToPatientIdentification(event);
        const hasConsent = this.mapPatientConsent(event);
        const accessibility = this.mapAccessibilityRequirements(event);
        const travel = this.mapTravelPreferences(event);

        if (ident) {
            this.identificationChangeEvent.emit(ident);
        }

        if (hasConsent) {
            this.consentChangeEvent.emit(hasConsent);
        }

        if (accessibility) {
            this.accessibilityChangeEvent.emit(accessibility);
        }
        if (travel) {
            this.travelChangeEvent.emit(travel);
        }
    }

    deletePatient() {
        const dialogRef = this.dialog.open(MedpaceDeletePatientModalComponent, {
            data: this.patient,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.adminPatientServices.deletePatient(this.patient.id).subscribe(
                    (response) => {
                        if (!response.saveSuccessful) {
                            const dialogConfig = new MatDialogConfig();
                            dialogConfig.disableClose = false;
                            dialogConfig.autoFocus = true;
                            dialogConfig.maxWidth = 650;
                            dialogConfig.minWidth = 350;
                            dialogConfig.data = {
                                title: 'Patient could not be deleted',
                                bodyText: response.errorMessage,
                                showCancelButton: false,
                            };

                            this.dialog.open(MedpaceMessageModalComponent, dialogConfig);
                        } else {
                            this.router.navigate([`admin/patients`]).then((navigated: boolean) => {
                                if (navigated) {
                                    this.snackbarService.openInfoSnackbar('Deleted Patient');
                                }
                            });
                        }
                    },
                    (error) => {
                        console.error('Failed to delete a patient');
                        console.error(error);

                        throw error;
                    }
                );
            }
        });
    }

    changePatientStatus(): void {
        const dialogRef = this.dialog.open(MedpacePatientChangeStatusModalComponent, {
            data: {
                patient: this.patient,
            },
            width: '500px',
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.patient.statusId = result.statusId;
                this.patient.statusName = this.patientStatusService.getStatusById(result.statusId);
                this.patientStateService.setNewPatient(this.patient);

                const disabledControls = getDisabledControls(this.patientFormGroup);
                enableControls(this.patientFormGroup, disabledControls);
                this.submitIncrementalSave();
                disableControls(this.patientFormGroup, disabledControls);
            }
        });
    }

    private mapTravelPreferences: (event: InputChange) => PatientTravelPreferences = (event: InputChange) => {
        let patient = createNewPatient();

        if (patient.travelPreferences.airtravel.hasOwnProperty(event.target)) {
            let air = <PatientTravelPreferences>{
                airtravel: <PatientAirlinePreferences>{},
            };
            air.airtravel[event.target] = event.value;
            return air;
        }

        if (patient.travelPreferences.internationaltravel.hasOwnProperty(event.target)) {
            let inter = <PatientTravelPreferences>{
                internationaltravel: <PatientInternationalPreferences>{},
            };
            inter.internationaltravel[event.target] = event.value;
            return inter;
        }

        if (patient.travelPreferences.lodging.hasOwnProperty(event.target)) {
            let lodging = <PatientTravelPreferences>{
                lodging: <PatientLodgingPreferences>{},
            };
            lodging.lodging[event.target] = event.value;
            return lodging;
        }
        if (patient.travelPreferences.traintravel.hasOwnProperty(event.target)) {
            let train = <PatientTravelPreferences>{
                traintravel: <PatientTrainPreferences>{},
            };
            train.traintravel[event.target] = event.value;
            return train;
        }
        if (patient.travelPreferences.groundTransportation.hasOwnProperty(event.target)) {
            let ground = <PatientTravelPreferences>{
                groundTransportation: {},
            };
            ground.groundTransportation[event.target] = event.value;
            return ground;
        }
        if (patient.travelPreferences.rentalCar.hasOwnProperty(event.target)) {
            let rental = <PatientTravelPreferences>{
                rentalCar: {},
            };
            rental.rentalCar[event.target] = event.value;
            return rental;
        }

        return null;
    };

    private mapAccessibilityRequirements: (event: InputChange) => PatientAccessibilityRequirements = (
        event: InputChange
    ) => {
        let accessibility = <PatientAccessibilityRequirements>{};
        accessibility[event.target] = event.value;
        return accessibility;
    };

    private mapPatientConsent: (event: InputChange) => Patient = (event: InputChange) => {
        let patient = createNewPatient();
        let outPatient = <Patient>{};

        if (patient.hasOwnProperty(event.target)) {
            outPatient[event.target] = event.value;
        }

        return outPatient[event.target] != null || outPatient[event.target] != undefined ? outPatient : undefined;
    };

    private mapToPatientIdentification: (event: InputChange) => PatientIdentification = (event: InputChange) => {
        let patient = createNewPatient();
        let pii = <PatientIdentification>{};
        if (patient.patientIdentification.hasOwnProperty(event.target)) {
            pii[event.target] = event.value;
            return pii;
        }
        return null;
    };
    public isUpdateAndAdmin(user: User): boolean {
        return this.isUpdate && (user.isAdmin || user.isSuperAdmin);
    }
}
