<div
    *ngIf="{
        form: form$ | async,
        save: save$ | async,
        createUpdatePatientClick: createUpdatePatientClick$ | async,
    } as vm"
>
    <div>
        <div class="o-grid__row" *ngIf="parentFormGroup.errors?.invalidArray && parentFormGroup.touched">
            <div class="o-grid__item">
                <medpace-alert
                    [attr.data-cy]="'travelCardAlert'"
                    [content]="parentFormGroup.errors?.invalidArray"
                    additionalClass="danger"
                ></medpace-alert>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div id="travel-information-tabs">
                    <mds-tab-group
                        headerPosition="above"
                        [stretchTabs]="false"
                        tabAlign="center"
                        selectedTabChange="selectedIndex"
                        animationDuration="0ms"
                        [preserveContent]="false"
                        [fitInkBarToContent]="false"
                        [dynamicHeight]="true"
                        [disablePagination]="false"
                    >
                        <mds-tab
                            [label]="'Airline'"
                            formErrorHighlighter
                            [forms]="[formGroup.controls.airTravelPreferences]"
                            [filterContent]="'Airline'"
                        >
                            <medpace-flight-preferences
                                class="tab"
                                id="flight"
                                [mode]="isEditing ? 'edit' : 'view'"
                                [formGroup]="formGroup.controls.airTravelPreferences"
                            ></medpace-flight-preferences>
                        </mds-tab>

                        <mds-tab
                            label="Train"
                            formErrorHighlighter
                            [forms]="[formGroup.controls.trainTravelPreferences]"
                            [filterContent]="'Train'"
                        >
                            <medpace-train-preferences
                                class="tab"
                                id="train"
                                [mode]="isEditing ? 'edit' : 'view'"
                                [formGroup]="formGroup.controls.trainTravelPreferences"
                            ></medpace-train-preferences>
                        </mds-tab>

                        <mds-tab
                            label="International"
                            formErrorHighlighter
                            [forms]="[
                                formGroup.controls.internationalPreferences.controls.caregiverPassportInfos,
                                formGroup.controls.internationalPreferences.controls.patientPassportInfo,
                            ]"
                            [filterContent]="'International'"
                        >
                            <medpace-international-preferences
                                class="tab"
                                id="international"
                                [mode]="isEditing ? 'edit' : 'view'"
                                [formGroup]="formGroup.controls.internationalPreferences"
                            ></medpace-international-preferences>
                        </mds-tab>

                        <mds-tab
                            label="Lodging"
                            formErrorHighlighter
                            [forms]="[formGroup.controls.lodgingPreferences]"
                            [filterContent]="'Lodging'"
                        >
                            <medpace-lodging-preferences
                                class="tab"
                                id="lodging"
                                [mode]="isEditing ? 'edit' : 'view'"
                                [formGroup]="formGroup.controls.lodgingPreferences"
                            ></medpace-lodging-preferences>
                        </mds-tab>

                        <mds-tab
                            label="Rental Car"
                            formErrorHighlighter
                            [forms]="[formGroup.controls.carRentalPreferences]"
                            [filterContent]="'Rental Car'"
                        >
                            <medpace-car-rental-preferences
                                class="tab"
                                id="rentalCar"
                                [mode]="isEditing ? 'edit' : 'view'"
                                [formGroup]="formGroup.controls.carRentalPreferences"
                            ></medpace-car-rental-preferences>
                        </mds-tab>

                        <mds-tab
                            label="Car Service"
                            formErrorHighlighter
                            [forms]="[formGroup.controls.transferPreferences]"
                            [filterContent]="'Car Service'"
                        >
                            <medpace-transfer-preferences
                                class="tab"
                                id="ground"
                                [mode]="isEditing ? 'edit' : 'view'"
                                [formGroup]="formGroup.controls.transferPreferences"
                            ></medpace-transfer-preferences>
                        </mds-tab>
                    </mds-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
