import { Injectable, inject } from '@angular/core';
import { Announcement } from '@models/announcement';
import { AnnouncementRepository } from '@repositories/announcement/announcement.repository';
import { StateService } from '@services/state-management/state.service';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementService extends StateService<Announcement> {
    private repo = inject(AnnouncementRepository);

    public override get(id: number): Observable<Announcement> {
        return this.repo.getById(id);
    }

    public create(announcement: Announcement) {
        return this.repo.create(announcement);
    }
    public update(announcementId: number, announcement: Announcement) {
        return this.repo.update(announcementId, announcement);
    }

    public getAll(): Observable<Announcement[]> {
        return this.repo.getAll();
    }

    public getRecentAnnouncementsForCRC(numberOfElements: number = 2): Observable<Announcement[]> {
        return this.repo.getAllActive(numberOfElements);
    }

    public delete(announcementId: number) {
        return this.repo.delete(announcementId);
    }

    public getAnnouncementsToDisplay(): Observable<Announcement[]> {
        const existingIds = this.getAnnouncementIdsFromSessionStorage();
        return this.getRecentAnnouncementsForCRC().pipe(
            map((announcements) =>
                existingIds ? announcements.filter((x) => !existingIds.includes(x.id)) : announcements
            )
        );
    }

    public getAnnouncementIdsFromSessionStorage() {
        const announcementIds = sessionStorage
            .getItem('announcements')
            ?.split(',')
            .map((x) => +x);
        return announcementIds;
    }
}
