import { Component } from '@angular/core';

@Component({
    selector: 'medpace-loading-card',
    templateUrl: './loading-card.component.html',
    styleUrls: ['./loading-card.component.scss'],
})
export class LoadingCardComponent {
    constructor() {}
}
