<mds-dialog [dialogTitle]="dialogTitle" style="max-width: 800px">
    <mds-dialog-content>
        <div class="o-grid" *ngIf="studies[0] && !isLoading; else spinner">
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        [formGrp]="personalDataFormGroup"
                        [formCtrlName]="emailAddressFieldName"
                        [id]="emailAddressFieldName"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="email"
                        floatLabel="always"
                        [label]="emailAddressFieldName"
                        width="100%"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-dropdown
                        [closeOnPageScroll]="false"
                        [formGrp]="assignedAccessGroupFormGroup"
                        [formCtrlName]="userAccessGroupFieldName"
                        [id]="userAccessGroupFieldName"
                        appearance="outline"
                        floatLabel="always"
                        placeholder="Select Study"
                        [label]="userAccessGroupFieldLabel"
                        [options]="accessGroupOptions$ | async"
                    ></mds-dropdown>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-checkbox
                        [formGrp]="isPrimaryCheckboxGroup"
                        formCtrlName="isPrimaryCheckboxControl"
                        label="IsPrimary"
                        mat-raised-button
                        matTooltip="Invitation for Primary CRC User"
                        matTooltipPosition="below"
                        aria-label="None"
                        id="isPrimaryCheckbox"
                    >
                    </mds-checkbox>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="(isCRC || isAdmin) && !data">
                <div class="o-grid__field" [formGroup]="assignedStudyFormGroup">
                    <medpace-chip-list
                        id="assignedStudy"
                        autocompleteName="assignedStudy"
                        [filteredOptions]="filteredStudies$ | async"
                        placeholder="Please select study"
                        formCtrlName="studyControl"
                        floatLabel="always"
                        label="Assigned Study"
                        [formGrp]="assignedStudyFormGroup"
                        [chiplist]="studyListChips$ | async"
                        (optionSelected)="selectedStudy($event)"
                        (optionRemoved)="removeStudy($event)"
                    ></medpace-chip-list>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isCRC && !data">
                <div class="o-grid__field" [formGroup]="assignedSiteFormGroup">
                    <medpace-chip-list
                        id="assignedSite"
                        [autocompleteName]="siteAutocompleteName"
                        [filteredOptions]="filteredSites$ | async"
                        placeholder="Please select sites"
                        label="Assigned Site"
                        floatLabel="always"
                        [formCtrlName]="siteAutocompleteName"
                        [formGrp]="assignedSiteFormGroup"
                        [chiplist]="siteListChips"
                        (optionSelected)="selectedSite($event)"
                        (optionRemoved)="removeSite($event)"
                    ></medpace-chip-list>
                </div>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions *ngIf="!isLoading">
        <mds-button
            variant="primary"
            [id]="'send-invitation-btn'"
            mat-button
            (clickEvent)="submitClick()"
            label="Send"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            variant="outline"
            mat-button
            mat-dialog-close
            label="Cancel"
            (clickEvent)="cancelClick()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
