<div class="c-card" *ngIf="patientVisitNameTimelineElements$ | async as patientVisitNameHistoryElements">
    <div class="c-card__content">
        <div class="arrow prev-button" (click)="scrollPrev()" [ngClass]="prevArrowDisabled ? 'disabled' : ''">
            <mds-icon fontIcon="arrow_back_ios"></mds-icon>
        </div>
        <div
            class="timeline-container"
            (mousedown)="onMouseDown($event)"
            (mousemove)="onMouseMove($event)"
            (mouseup)="onMouseUp($event)"
            (mouseleave)="onMouseUp($event)"
        >
            <div class="timeline" #timeline>
                <div
                    class="timeline-line"
                    #timelineLine
                    [ngStyle]="{ width: patientVisitNameHistoryElements.length * 20 + '%' }"
                ></div>
                <div
                    class="timeline-event"
                    *ngFor="
                        let visit of patientVisitNameTimelineElements$ | async as patientVisitNameHistoryElements;
                        let i = index
                    "
                    [ngStyle]="{ left: i * 20 + '%' }"
                >
                    <div class="timeline-dot" [ngClass]="getBackgroundColorClass(visit)"></div>
                    <div class="timeline-content">
                        <p>
                            <strong>{{ visit.visitName }}</strong>
                        </p>
                        <div style="margin-top: 10px">
                            <p *ngIf="visit.visitActualDate">
                                Actual:
                                {{ visit.visitActualDate | dateFormat: dateOnly : 'utc' }}
                            </p>
                            <p *ngIf="visit.visitScheduledDate">
                                Projected:
                                {{ visit.visitScheduledDate | dateFormat: dateOnly : 'utc' }}
                            </p>
                            <p [style]="'height:' + setHeight(visit)">Open Requests: {{ visit.requestCount }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow next-button" (click)="scrollNext()" [ngClass]="nextArrowDisabled ? 'disabled' : ''">
            <mds-icon fontIcon="arrow_forward_ios"></mds-icon>
        </div>
    </div>
</div>
