import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { Subject, take, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'medpace-kyc-delete-modal',
    templateUrl: './kyc-delete-modal.component.html',
    styleUrls: ['./kyc-delete-modal.component.scss'],
})
export class MedpaceKYCDeleteModalComponent implements OnDestroy {
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<MedpaceKYCDeleteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { patientId: number; kycDocumentId: number },
        private patientService: AdminPatientServices
    ) {}

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    submitDelete(): void {
        this.patientService
            .deletePatientKycDocument(this.data.patientId, this.data.kycDocumentId)
            .pipe(
                take(1),
                takeUntil(this.componentDestroyed$),
                tap((res) => {
                    this.dialogRef.close(res);
                })
            )
            .subscribe();
    }
}
