<mds-expansion-panel [panelTitle]="formGroup.controls.patientPassportInfo.title" [description]="'Passport Information'">
    <medpace-passport-info [mode]="mode" [formGroup]="formGroup.controls.patientPassportInfo"></medpace-passport-info>
</mds-expansion-panel>

<mds-expansion-panel
    *ngFor="let passportInfoFormGroup of formGroup.controls.caregiverPassportInfos.controls"
    [panelTitle]="passportInfoFormGroup.title"
    [description]="'Passport Information'"
>
    <medpace-passport-info [mode]="mode" [formGroup]="passportInfoFormGroup"></medpace-passport-info>
</mds-expansion-panel>
