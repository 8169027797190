import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { EditedCard } from '@models/edited-card';
import { InputChange } from '@models/event-objects/input-change';
import { Patient } from '@models/patient';
import { SiteServices } from '@models/site';
import { Study, StudyTravelOption } from '@models/study';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { EventService } from '@services/event/event.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { buildSnackBar, collectErrors, markFormGroupTouched } from '@utility/utility';
import { Subject } from 'rxjs';

@Component({
    selector: 'medpace-input-card',
    templateUrl: './input-card.component.html',
    styleUrls: ['./input-card.component.scss', '../create-site/site.component.scss'],
})
export class MedpaceInputCardComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() visitDetails: any;

    @Input() isCard: boolean = true;

    @Input() title: string;

    @Input() request: any;

    @Input() inputObj: any;

    @Input() schema: any = null;

    @Input() type: string;

    @Input() requestType: string;

    @Input() travelEnabled: boolean;

    @Input() paymentEnabled: boolean;

    @Input() stipendEnabled: boolean;

    @Input() reimbursementEnabled: boolean;

    @Input() siteSettings: SiteServices;

    @Input() additionalContent: any;

    @Input() isPatientProfile: boolean;

    @Input() isRequired: boolean;

    @Input() editMode: boolean;
    @Input() showEditSaveButton: boolean;

    @Input() isUserAdmin: boolean;

    @Input() studyUsers: any[];

    @Input() showAdditionalDetails: boolean;

    @Input() patient: Patient;

    @Input()
    parentFormGroup: FormGroup;

    @Input()
    isClinTrakComparison: boolean = false;

    @Input()
    cardFormGroup: FormGroup;

    @Input()
    isUpdate: boolean;

    @Input()
    studyTravelOptions: StudyTravelOption[];

    @Input() isModal: boolean = false;

    @Input() studyId: number;

    @Input() parentFormType: 'study' | 'site' | 'patient' | 'request' | undefined = undefined;

    @Output() inputChangeEvent = new EventEmitter<InputChange>();

    @Output() actionClickEvent = new EventEmitter();

    @Output() incrementalUpdate = new EventEmitter();

    @ViewChild('ngForm') ngForm: NgForm;
    private static saveSubject = new Subject<string>();
    public static save$ = MedpaceInputCardComponent.saveSubject.asObservable();

    study: Study;
    newVisitDate: Date = null;
    isRequestUrl: boolean = false;
    isNewRequest: boolean = false;
    currentCard: EditedCard;
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private eventService: EventService,
        private router: Router,
        private snackbarService: SnackbarService,
        private currentlyEditedCardService: CurrentlyEditedCardService
    ) {
        if (this.parentFormGroup === undefined) {
            this.parentFormGroup = new FormGroup({});
        }
    }

    ngOnInit() {
        if (this.router.url.includes('newrequest')) {
            this.isNewRequest = true;
        }
        this.eventService.on('visitStartDate', (date: Date) => {
            this.newVisitDate = date;
        });

        if (this.type === 'checkbox') {
            this.mapTextForView();
        }

        this.isRequestUrl = window.location.href.includes('request');

        this.setCaregiverProperties();
        this.cardFormGroup = new FormGroup({});

        //set EditedCard object
        this.currentCard = <EditedCard>{
            cardName: this.title,
            cardForm: this,
        };
    }
    ngAfterViewInit(): void {
        // FormGroup.disable() does not work in OnInit ;(
        this.parentFormGroup.addControl(`${this.title} CardFormGroup`, this.cardFormGroup);
        this.editMode ? this.cardFormGroup.enable() : this.cardFormGroup.disable();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
    updateEditStatus(): void {
        if (this.editMode) {
            this.ngForm.ngSubmit.emit();
            // editMode = true => Save button is displayed
            if (this.cardFormGroup.invalid) {
                markFormGroupTouched(this.cardFormGroup);
                buildSnackBar(collectErrors(this.cardFormGroup), this.snackbarService);
                return;
            } else {
                // valid data in card, remove from currentlyEditedCard
                // if CurrentlyEditedCardService has this card, remove it
                // if CurrentlyEditedCardService has different card, throw error (should not happen)
                if (!this.currentlyEditedCardService.clearCurrentState(this.currentCard)) {
                    throw Error(
                        `Incorrect currently edited card, should be '${this.currentCard.cardName}', got '${
                            this.currentlyEditedCardService.getState().cardName
                        }'`
                    );
                }
                MedpaceInputCardComponent.saveSubject.next(this.type);
                this.incrementalUpdate.emit('doSave');
            }
        } else {
            // editMode === false => Edit button is displayed
            // if CurrentlyEditedCardService has any card, prevent from editing and show a snackbar message
            // if CurrentlyEditedCardService does not have a card, set state with current card
            if (!this.currentlyEditedCardService.setState(this.currentCard)) {
                //card not set successfully, show snackbar message
                this.snackbarService.openInfoSnackbar(
                    `Card '${this.currentCard.cardName}' cannot be edited, currently editing: '${
                        this.currentlyEditedCardService.getState().cardName
                    }'`
                );
                return;
            }
        }
        this.toggleEditMode();
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
        this.editMode ? this.cardFormGroup.enable() : this.cardFormGroup.disable();
    }

    updatePatient(event: any) {
        this.incrementalUpdate.emit(event);
    }

    passContentAlong: (event: any) => void = (event: any) => {
        this.inputChangeEvent.emit({ target: event.target, value: event.value });
    };

    passFileAlong(event: any) {
        this.inputChangeEvent.emit({ target: 'logo', value: event });
    }

    passSiteServicesAlong(event: any): void {
        this.inputChangeEvent.emit({ target: event.type, value: event });
    }

    passPaymentAlong(event: any): void {
        this.inputChangeEvent.emit({ target: event.type, value: event });
    }

    emitAction(event: any): void {
        this.actionClickEvent.emit(event);
    }

    private mapTextForView(): void {
        for (let key in Object.keys(this.inputObj)) {
            for (let k in Object.keys(this.schema)) {
                if (this.inputObj && Object.keys(this.schema)[k] === Object.keys(this.inputObj)[key]) {
                    this.schema[Object.keys(this.schema)[k]]['value'] = this.inputObj[Object.keys(this.inputObj)[key]];
                    break;
                }
            }
        }
    }

    /**
     * Set the caregiver propeties for middle name & the html checkbox.
     */
    private setCaregiverProperties(): void {
        this.patient?.caregivers?.forEach((c) => {
            if (!c.middleName) {
                c.middleName = '';
            }

            const foundCaregiver = this.request?.travelRequestCaregivers?.find((b) => b.caregiverId == c.id);
            if (foundCaregiver) {
                c.isChecked = true;
            }
        });
    }
}
