import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Caregiver,
    CaregiverDTO,
    PatchPatientDataModel,
    Patient,
    PatientDataModel,
    PatientDetailsDataModel,
    PatientDetailsViewModel,
} from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { RequestSummary } from '@models/request';
import { AdminPatientRepository } from '@repositories/admin/admin-patient.repository';
import { SaveStatusResponse } from '@utility/utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    transformToPatient,
    transformToPatientDetailsViewModelArray,
} from 'src/app/services/transforms/patient-transform';

@Injectable()
export class AdminPatientServices {
    constructor(private repo: AdminPatientRepository) {}

    createPatient: (patient: PatientDataModel) => Observable<HttpResponse<number>> = (patient: PatientDataModel) => {
        return this.repo.createPatient(patient);
    };

    updatePatient: (patientId: number, patient: PatientDataModel) => Observable<SaveStatusResponse> = (
        patientId: number,
        patient: PatientDataModel
    ) => {
        return this.repo.updatePatient(patientId, patient);
    };

    patchPatient: (
        patientId: number,
        patientOld: PatchPatientDataModel,
        patientNew: PatchPatientDataModel
    ) => Observable<any> = (
        patientId: number,
        patientOld: PatchPatientDataModel,
        patientNew: PatchPatientDataModel
    ) => {
        return this.repo.patchPatient(patientId, patientOld, patientNew);
    };

    deletePatient: (patientId: number) => Observable<any> = (patientId: number) => {
        return this.repo.deletePatient(patientId);
    };

    getAllPatientSummaries: () => Observable<PatientDetailsViewModel[]> = () => {
        return this.repo.getAllPatientSummaries().pipe(
            map((value: PatientDetailsDataModel[]) => {
                return value ? transformToPatientDetailsViewModelArray(value) : [];
            })
        );
    };

    getPatientEditRequestById(patientId: number): Observable<PatientEditRequestDto> {
        return this.repo.getEditRequest(patientId);
    }

    removePatientEditRequest(patientId: number): Observable<SaveStatusResponse> {
        return this.repo.removeEditRequest(patientId);
    }

    getAllSubmittedPatientEditRequests(): Observable<PatientEditRequestDto[]> {
        return this.repo.getAllEditRequests();
    }

    getAllSubmittedPatientEditRequestCount(): Observable<number> {
        return this.repo.getSubmittedEditRequestCount();
    }

    getPatientById: (patientId: number) => Observable<any> = (patientId: number) => {
        return this.repo.getPatientById(patientId);
    };

    getPatientTrialSummary: (patientId: number) => Observable<Patient> = (patientId: number) => {
        return this.repo.getPatientTrialSummary(patientId).pipe(
            map((value: PatientDataModel) => {
                return transformToPatient(value);
            })
        );
    };

    getPatientRequestSummaries: (patientId: number) => Observable<RequestSummary[]> = (patientId) => {
        return this.repo.getPatientRequestSummaries(patientId);
    };

    deleteCaregiver: (patientId: number, caregiverId: number) => Observable<any> = (
        patientId: number,
        caregiverId: number
    ) => {
        return this.repo.deleteCaregiver(patientId, caregiverId);
    };

    getPatientCaregivers: (patientId: number) => Observable<CaregiverDTO[]> = (patientId: number) => {
        return this.repo.getPatientCaregivers(patientId);
    };

    createCaregiver: (caregiver: Caregiver) => Observable<any[]> = (caregiver: Caregiver) =>
        this.repo.createCaregiver(caregiver);

    updateCaregiver: (caregiver: Caregiver) => Observable<any[]> = (caregiver: Caregiver) =>
        this.repo.updateCaregiver(caregiver);

    getPatientKycDocumentUri(patientId: number, kycDocumentId: number): Observable<URL> {
        return this.repo.getPatientKycDocumentUri(patientId, kycDocumentId);
    }

    deletePatientKycDocument(patientId: number, kycDocumentId: number): Observable<boolean> {
        return this.repo.deletePatientKycDocument(patientId, kycDocumentId);
    }
}
