<div>
    <div class="o-grid content">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h4 class="header">Submit Request</h4>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-textarea
                    label="Additional Information"
                    floatLabel="always"
                    placeholder="Type Here..."
                    appearance="outline"
                    id="additionalInfo"
                    name="additionalInfo"
                    [autoSize]="true"
                    [cdkAutosizeMinRows]="8"
                    [cdkAutosizeMaxRows]="8"
                    [formCtrl]="additionalDetailsFormGroup.controls.additionalInfoControl"
                >
                </mds-textarea>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="flex start">
                    <mds-button [id]="'confirm-btn'" label="Confirm" (clickEvent)="saveDialog()" variant="primary">
                    </mds-button>
                    <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline">
                    </mds-button>
                </div>
            </div>
        </div>
    </div>
</div>
