<mds-dialog dialogTitle="Announcement" [attr.data-cy]="'announcementOpenModal'">
    <mds-dialog-content style="min-width: 500px">
        <div class="c-form">
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="flex start middle">
                            <span class="required-asterisk">*</span>
                            <span> indicates a required field </span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <mds-text-field
                                placeholder="Enter announcement's title"
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Title"
                                width="100%"
                                [formCtrl]="data.formGroup.controls.title"
                                [attr.data-cy]="'announcementTitle'"
                            ></mds-text-field>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <!-- had to use textarea-autocomplete instead of mds-textarea because it [cdkAutosizeMinRows] and [cdkAutosizeMinRows] did not work -->
                            <medpace-textarea-autocomplete
                                [cdkAutosizeMinRows]="8"
                                [cdkAutosizeMaxRows]="8"
                                [label]="'Description'"
                                [placeholder]="'Enter announcement\'s description'"
                                [appearance]="'outline'"
                                [autoSize]="true"
                                [floatLabel]="'always'"
                                [formCtrl]="data.formGroup.controls.description"
                                [attr.data-cy]="'announcementDescription'"
                            ></medpace-textarea-autocomplete>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <div>
                            <mds-dropdown
                                label="Status"
                                appearance="outline"
                                [floatLabel]="'always'"
                                [closeOnPageScroll]="false"
                                placeholder="Please Select Status"
                                [options]="displayStatus"
                                [formCtrl]="data.formGroup.controls.status"
                                id="announcementStatusDropdown"
                                [attr.data-cy]="'announcementStatus'"
                            ></mds-dropdown>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <medpace-datepicker
                                [label]="'Expires'"
                                [appearance]="'outline'"
                                [formCtrl]="data.formGroup.controls.expirationDate"
                                [attr.data-cy]="'announcementExpirationDate'"
                            ></medpace-datepicker>
                        </div>
                    </div>
                </div>
                <mds-expansion-panel panelTitle="Filter options">
                    <mds-dropdown
                        [appearance]="'outline'"
                        [options]="studyOptions"
                        [placeholder]="'Choose studies to filter by'"
                        [label]="'Studies'"
                        [floatLabel]="'always'"
                        [formCtrl]="data.formGroup.controls.filterOptions.controls.studies"
                        [multiple]="true"
                    ></mds-dropdown>
                    <mds-dropdown
                        [appearance]="'outline'"
                        [options]="siteOptions$ | async"
                        [placeholder]="'Choose sites to filter by'"
                        [label]="'Sites'"
                        [floatLabel]="'always'"
                        [formCtrl]="data.formGroup.controls.filterOptions.controls.sites"
                        [multiple]="true"
                    ></mds-dropdown>
                </mds-expansion-panel>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions>
        <mds-button [attr.data-cy]="'saveButton'" label="Save" variant="primary" (clickEvent)="save()"></mds-button>
        <mds-button
            [attr.data-cy]="'cancelButton'"
            label="Cancel"
            variant="outline"
            (clickEvent)="cancel()"
        ></mds-button>
        <mds-button
            *ngIf="data.isRemovable"
            [attr.data-cy]="'removeButton'"
            label="Remove"
            variant="primary"
            color="warn"
            (clickEvent)="remove()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
