import { Component, inject } from '@angular/core';
import { RequestTypes } from '@app/enums/RequestTypes';
import { GenericRequest, RequestLog } from '@models/request';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { GroupService } from '@services/group/group.service';
import { RequestStateService } from '@services/state-management/request-state.service';
import { Observable, map, of, switchMap, take } from 'rxjs';

@Component({
    selector: 'medpace-request-history',
    templateUrl: './request-history.component.html',
    styleUrls: ['./request-history.component.scss'],
})
export class RequestHistoryComponent {
    private adminRequestServices = inject(AdminRequestServices);
    private requestStateService = inject(RequestStateService);
    private groups = inject(GroupService);

    isUserAdmin$ = this.groups.getGroupsFromStorage().pipe(
        take(1),
        map((result) => {
            return this.groups.isUserAdmin(result);
        })
    );

    requestHistoryPosts$: Observable<RequestLog[]> = this.requestStateService.getRequest().pipe(
        switchMap((request) => {
            const requestType = this.getRequestExactType(request);
            switch (requestType) {
                case RequestTypes.TRAVEL:
                    return this.adminRequestServices.getTravelRequestLog(request?.id);
                case RequestTypes.REIMBURSEMENT:
                    return this.adminRequestServices.getReimbursementRequestLog(request?.id);
                case RequestTypes.STIPEND:
                    return this.adminRequestServices.getStipendRequestLog(request?.id);
                default:
                    return of([]);
            }
        })
    );

    getRequestExactType(request: GenericRequest): RequestTypes {
        let exactType: RequestTypes;
        if (request.type === 'travel') {
            exactType = RequestTypes.TRAVEL;
        } else if (request.type === 'payment' || request.type === 'stipend' || request.type === 'out-of-pocket') {
            if (request.paymentType === 'stipend') {
                exactType = RequestTypes.STIPEND;
            } else if (request.paymentType === 'out-of-pocket') {
                exactType = RequestTypes.REIMBURSEMENT;
            }
        }
        return exactType;
    }
}
