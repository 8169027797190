import { Injectable } from '@angular/core';
import { IMedpaceLogoUploadComponent } from '@models/interfaces/iMedpaceLogoUploadComponent';
import { Observable, ReplaySubject } from 'rxjs';
import { maxSizeOfFiles } from 'src/globals';

@Injectable({
    providedIn: 'root',
})
export class AttachmentSizeService {
    private readonly maxFileSizesSubject: ReplaySubject<boolean> = new ReplaySubject();
    private readonly maxFileSizesObservable: Observable<boolean> = this.maxFileSizesSubject.asObservable();
    // TODO consider changing logic to remove below dependeny
    private medpaceLogoUploadComponents: IMedpaceLogoUploadComponent[] = [];
    private summaryAttachmentSizes: number = 0;
    private isMessagePublished: boolean = false;

    constructor() {
        this.maxFileSizesSubject.next(true);
    }

    public AreFileSizesInLimit(): Observable<boolean> {
        return this.maxFileSizesObservable;
    }

    public addAttachmentSize(medpaceLogoUploadComponent: IMedpaceLogoUploadComponent): void {
        if (!this.medpaceLogoUploadComponents.includes(medpaceLogoUploadComponent)) {
            this.medpaceLogoUploadComponents.push(medpaceLogoUploadComponent);
        }
        this.calculateAttachmentSizes();
    }

    public flushAttachmentSize(): void {
        this.summaryAttachmentSizes = 0;
        this.medpaceLogoUploadComponents = [];
    }

    public removeAttachment(elementCount: number) {
        this.medpaceLogoUploadComponents.splice(elementCount, 1);
        this.calculateAttachmentSizes();
        this.checkAttachmentSize();
    }

    private checkAttachmentSize(): void {
        this.maxFileSizesSubject.next(this.summaryAttachmentSizes < maxSizeOfFiles);
        if (this.summaryAttachmentSizes < maxSizeOfFiles) {
            if (this.isMessagePublished) this.publishMessageAboutFileSizeNotTrepass();
        } else {
            if (!this.isMessagePublished) this.publishMessageAboutFileSizeTrepass();
        }
    }

    private calculateAttachmentSizes() {
        this.summaryAttachmentSizes = this.medpaceLogoUploadComponents.reduce(
            (sum, element) => sum + element.getFileSize(),
            0
        );
        this.checkAttachmentSize();
    }

    private publishMessageAboutFileSizeTrepass() {
        this.medpaceLogoUploadComponents[this.medpaceLogoUploadComponents.length - 1].setFileSizeTrepassMessage();
        this.isMessagePublished = true;
    }

    private publishMessageAboutFileSizeNotTrepass() {
        this.medpaceLogoUploadComponents.forEach((x) => x.setFileSizeNotTrepassMessage());
        this.isMessagePublished = false;
    }
}
