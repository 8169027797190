<mds-dialog dialogTitle="Access Request">
    <mds-dialog-content>
        <div class="o-grid" *ngIf="!isLoading">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Name</h5>
                        <span>{{ data?.displayName }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="data?.phoneNumber">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Phone Number</h5>
                        <span>{{ data?.phoneNumber }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Email Address</h5>
                        <span>{{ data?.userEmail }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Access Type</h5>
                        <span>{{ data?.accessType }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="data?.assignedStudy && data?.assignedStudy.length > 0">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Affected Studies</h5>
                        <div *ngFor="let studyProtocol of data?.assignedStudy">
                            <span>{{ studyProtocol }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="data?.assignedSite && data?.assignedSite.length > 0">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Affected Sites</h5>
                        <div *ngFor="let siteName of data?.assignedSite">
                            <span>{{ siteName }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="data?.justification">
                <div class="o-grid__field">
                    <div class="c-info__block">
                        <h5>Justification</h5>
                        <span>{{ data?.justification }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isStudiesEmpty">
                <div class="o-grid__field">
                    <medpace-alert
                        content="Please select Study to assign"
                        [additionalClass]="'warning'"
                    ></medpace-alert>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="isAdminAccessLevel">
                <div class="o-grid__field" [formGroup]="arActionFormGroup">
                    <medpace-chip-list
                        id="assignedStudy"
                        autocompleteName="assignedStudy"
                        [filteredOptions]="filteredStudies$ | async"
                        placeholder="Please select study"
                        label="Study to assign"
                        formCtrlName="studyControl"
                        floatLabel="always"
                        [formGrp]="arActionFormGroup"
                        [chiplist]="studyListChips$ | async"
                        (optionSelected)="selectedStudy($event)"
                        (optionRemoved)="removeStudy($event)"
                    ></medpace-chip-list>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-textarea
                        [formGrp]="arActionFormGroup"
                        formCtrlName="reasonControl"
                        id="reasonField"
                        [cdkAutosizeMinRows]="5"
                        [cdkAutosizeMaxRows]="5"
                        label="Reason"
                        placeholder="Type here..."
                        floatLabel="always"
                        appearance="outline"
                        [autoSize]="true"
                        matTooltip="Required for reject action only"
                    >
                    </mds-textarea>
                </div>
            </div>
        </div>
        <div class="o-grid" *ngIf="isLoading">
            <div class="spinnerOverlay">
                <medpace-loading-card></medpace-loading-card>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions *ngIf="!isLoading">
        <mds-button
            [id]="'grant-access-btn'"
            id="grantAccessBtn"
            label="Grant access"
            (clickEvent)="grantAccess()"
            variant="primary"
        >
        </mds-button>
        <mds-button
            [id]="'reject-access-btn'"
            id="rejectAccessBtn"
            label="Reject"
            (clickEvent)="rejectAccess()"
            variant="primary"
            color="warn"
        >
        </mds-button>
        <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline"> </mds-button>
    </mds-dialog-actions>
</mds-dialog>
