import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { Announcement, AnnouncementStatus } from '@models/announcement';
import { Site } from '@models/site';
import { Study } from '@models/study';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { PersistentFormControl } from '@utility/persistent-forms';
import { MdsOptionGeneric } from '@utility/utility';
import { Moment } from 'moment';
import { concatMap, from, map, reduce, startWith, switchMap, tap } from 'rxjs';

export interface CreateEditAnnouncementDialogData {
    announcement: Announcement;
    allStudies: Study[];
    formGroup: FormGroup<{
        title: FormControl<string>;
        description: FormControl<string>;
        status: FormControl<AnnouncementStatus>;
        expirationDate: PersistentFormControl<Moment>; // use PersistentFormControl to track touchedChanges
        filterOptions: FormGroup<{
            studies: FormControl<Study[]>;
            sites: FormControl<Site[]>;
        }>;
    }>;
    isRemovable: boolean;
}

export interface CreateEditAnnouncementDialogResult {
    announcement: Announcement;
    value: CreateEditAnnouncementDialogData['formGroup']['value'];
    remove: boolean;
    cancel: boolean;
}
@Component({
    selector: 'app-create-edit-announcement-dialog',
    templateUrl: './create-edit-announcement-dialog.component.html',
    styleUrls: ['./create-edit-announcement-dialog.component.scss'],
})
export class CreateEditAnnouncementDialogComponent {
    displayStatus: MdsOption[];

    constructor(
        private dialogRef: MatDialogRef<CreateEditAnnouncementDialogComponent, CreateEditAnnouncementDialogResult>,
        @Inject(MAT_DIALOG_DATA) protected data: CreateEditAnnouncementDialogData,
        private studyService: AdminStudyServices
    ) {
        this.displayStatus = Object.entries(AnnouncementStatus)
            .filter((entry) => typeof entry[0] === 'string' && typeof entry[1] === 'number')
            .map((entry) => ({
                value: Number(entry[1]),
                viewValue: entry[0],
            }))
            .filter((element) => element.value !== 3);
    }
    protected studyOptions = this.data.allStudies.map(
        (study) =>
            <MdsOptionGeneric<Study>>{
                value: study,
                viewValue: study.protocol,
            }
    );
    protected siteOptions$ = this.data.formGroup.controls.filterOptions.controls.studies.valueChanges.pipe(
        startWith(this.data.formGroup.controls.filterOptions.controls.studies.value),
        switchMap((studies) =>
            from(studies.map((study) => study.id)).pipe(
                concatMap((id) => this.studyService.getStudySites(id)),
                reduce((acc, sites) => acc.concat(sites), <Site[]>[])
            )
        ),
        map((sites) =>
            sites.map(
                (site) =>
                    <MdsOptionGeneric<Site>>{
                        value: site,
                        viewValue: site.info.name,
                    }
            )
        ),
        tap((siteOptions) => {
            // reselect previously selected sites
            const selectedSites = this.data.formGroup.controls.filterOptions.controls.sites.value.map(
                (site) => site.id
            );
            this.data.formGroup.controls.filterOptions.controls.sites.setValue(
                siteOptions.map((option) => option.value).filter((site) => selectedSites.includes(site.id))
            );
        })
    );

    public save() {
        if (!this.data.formGroup.valid) {
            this.data.formGroup.markAllAsTouched();
            return;
        } else {
            this.dialogRef.close(<CreateEditAnnouncementDialogResult>{
                announcement: this.data.announcement,
                value: this.data.formGroup.value,
                cancel: false,
                remove: false,
            });
        }
    }
    public cancel() {
        this.dialogRef.close(<CreateEditAnnouncementDialogResult>{
            announcement: this.data.announcement,
            value: null,
            cancel: true,
            remove: false,
        });
    }
    public remove() {
        this.dialogRef.close(<CreateEditAnnouncementDialogResult>{
            announcement: this.data.announcement,
            value: null,
            cancel: false,
            remove: true,
        });
    }
}
