export enum DateFormat {
    dateOnly = 'dd-MMM-yyyy',
    dbDateTime = 'yyyy-MM-dd hh:mm:ss:sss',
    dateTime24 = 'dd-MMM-yyyy, HH:mm',
    dateTime12 = 'dd-MMM-yyyy, hh:mm a',
    timeOnly = 'HH:mm',
}

export enum MomentFormat {
    dateOnly = 'DD-MMM-yyyy',
    dbDateTime = 'yyyy-MM-DD hh:mm:ss:sss',
    dateTime24 = 'DD-MMM-yyyy, HH:mm',
    dateTime12 = 'DD-MMM-yyyy, hh:mm a',
    timeOnly = 'HH:mm',
}
