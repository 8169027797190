import { ChangeDetectionStrategy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Study } from '@models/study';
import { UserAdminViewModel } from '@models/user';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { CRCStudyServices } from '@services/crc/crc-study.service';
import { GroupService } from '@services/group/group.service';
import { UserService } from '@services/user/user.service';
import { combineLatest, map, Observable, switchMap, tap } from 'rxjs';
@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class MedpaceUserAccountComponent implements OnInit {
    columns: string[] = ['sponsor', 'protocol', 'numSites', 'numPatients', 'requests', 'region'];
    columnMap = [
        { header: 'sponsor name', name: 'sponsor' },
        { header: 'protocol', name: 'protocol' },
        { header: '# sites', name: 'numSites' },
        { header: '# patients', name: 'numPatients' },
        { header: 'requests', name: 'requests' },
        { header: 'region', name: 'region' },
    ];
    accountData$: Observable<UserAdminViewModel> = this.userService.getCurrentUserAccountData().pipe(
        tap((data) => {
            if (!data.phoneNumber) data.phoneNumber = 'Unspecified';
        })
    );
    isAdmin$ = this.groupService.getGroupsFromStorage().pipe(map((groups) => this.groupService.isUserAdmin(groups)));
    studies$: Observable<Study[]> = combineLatest([this.accountData$, this.isAdmin$]).pipe(
        switchMap(([accountData, isAdmin]) =>
            isAdmin ? this.adminStudyService.getStudySummaries() : this.crcStudyService.getAllStudySummaries()
        )
    );
    vm$ = combineLatest([this.accountData$, this.studies$, this.isAdmin$]).pipe(
        map(([accountData, studies, isAdmin]) => ({
            accountData,
            studies,
            isAdmin,
        })),
        tap((vm) => {
            this.vm = vm;
            this.studies = vm.studies;
        })
    );
    vm: {
        accountData: UserAdminViewModel;
        studies: Study[];
        isAdmin: boolean;
    };
    studies: Study[]; // sorting doesnt work without this property passed directly to medpace-table, instead of vm.studies. Async pipe doesnt work too %$#%#@
    constructor(
        private userService: UserService,
        private crcStudyService: CRCStudyServices,
        private adminStudyService: AdminStudyServices,
        private router: Router,
        private groupService: GroupService
    ) {}

    ngOnInit(): void {
        this.vm$.subscribe();
    }
    onStudyRowClicked(event: any) {
        this.router.navigate([`studies/${event.id}`], { state: event });
    }
}
