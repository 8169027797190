import { Injectable } from '@angular/core';
import { ReimbursementTypes } from '@app/enums/ReimbursementTypes';
import { RequestTypes } from '@app/enums/RequestTypes';
import { GenericRequest, GenericRequestStatus } from '@models/request';
import { VisitName } from '@models/study';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { PageMode } from '@utility/utility';
import { BehaviorSubject, Observable, ReplaySubject, sample, take } from 'rxjs';
import { ReimbursementRequestStateService } from './reimbursement-request-state.service';
import { StateService } from './state.service';
import { StipendRequestStateService } from './stipend-request-state.service';
import { TravelRequestStateService } from './travel-request-state.service';
export interface RequestContextState {
    requestType: RequestTypes;
    requestStatus: GenericRequestStatus;
    reimbursementType: ReimbursementTypes;
    pageMode: PageMode;
    patientId: number;
}
@Injectable({ providedIn: 'root' })
export class RequestStateService extends StateService<RequestContextState> {
    constructor(
        private studyServices: AdminStudyServices,
        private requestServices: AdminRequestServices,
        public readonly travelRequestStateService: TravelRequestStateService,
        public readonly stipendRequestStateService: StipendRequestStateService,
        public readonly reimbursementRequestStateService: ReimbursementRequestStateService
    ) {
        super();
    }
    public override clearState(): void {
        super.clearState();
        this.travelRequestStateService.clearState();
        this.stipendRequestStateService.clearState();
        this.reimbursementRequestStateService.clearState();
    }

    private readonly visits = new BehaviorSubject<VisitName[]>(null);
    public readonly visits$ = this.visits.asObservable();

    private readonly request = new ReplaySubject<GenericRequest>();
    public readonly request$ = this.request.asObservable();

    public setRequest(requestType: string, stipendOrOOP: string, id: number): void {
        let request: Observable<GenericRequest>;
        if (requestType === 'travel') request = this.requestServices.getTravelRequest(id);
        else if (requestType === 'payment' && stipendOrOOP === 'stipend')
            request = this.requestServices.getStipendRequest(id);
        else if (requestType === 'payment' && stipendOrOOP === 'out-of-pocket')
            request = this.requestServices.getReimbursementRequest(id);
        request.pipe(take(1)).subscribe((res) => {
            return this.request.next(res);
        });
    }

    public setNewRequest(newRequest: GenericRequest): void {
        this.request.next(newRequest);
    }

    public updateRequestData(request: any): void {
        this.request.next(request);
    }

    public clearStore() {
        return this.request.next(null);
    }

    public getRequest(): Observable<GenericRequest> {
        return this.request$.pipe(sample(this.request));
    }

    public setVisits(id: number): void {
        this.studyServices
            .getStudyVisitTypes(id)
            .pipe(take(1))
            .subscribe((res) => {
                return this.visits.next(res);
            });
    }

    public getVisits(): Observable<VisitName[]> {
        return this.visits$;
    }

    public get(id: any): Observable<RequestContextState> {
        throw new Error('Not supported by this service'); // this service does not support http get, it only exists locallys
    }
}
