import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReportBugResultViewModel } from '@models/error-details';
import { maxLengthValidator } from '@utility/utility.validators';

@Component({
    selector: 'medpace-report-bug',
    templateUrl: './report-bug.component.html',
    styleUrls: ['../delete-patient-modal.component.scss'],
})
export class ReportBugModalComponent {
    reportBugFromGroup = new FormGroup({
        bugDetails: new FormControl<string>('', [maxLengthValidator(500)]),
    });

    constructor(public dialogRef: MatDialogRef<ReportBugModalComponent, ReportBugResultViewModel>) {}

    closeDialog(): void {
        this.dialogRef.close({ save: false, description: undefined });
    }

    saveDialog(): void {
        if (this.reportBugFromGroup.valid) {
            this.dialogRef.close({
                save: true,
                description: this.reportBugFromGroup.controls.bugDetails.value,
            });
        } else this.reportBugFromGroup.markAllAsTouched();
    }
}
