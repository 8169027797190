import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { User } from '@models/user';
import { AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/user/user.service';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'medpace-no-role-modal',
    templateUrl: './no-role-modal.component.html',
    styleUrls: ['./no-role-modal.component.scss'],
})
export class NoRoleModalComponent implements OnInit, OnDestroy {
    user$: Observable<User> = undefined;
    user: User = undefined;
    private componentDestroyed$: Subject<boolean> = new Subject();
    contactEmail: string = 'PCS@Medpace.com';

    navigationExtras: NavigationExtras = {
        state: {
            isFromNoRole: true,
        },
    };

    constructor(
        public dialogRef: MatDialogRef<NoRoleModalComponent>,
        private auth: AuthService,
        private userService: UserService,
        private route: Router
    ) {}

    ngOnInit(): void {
        this.userService
            .getUser()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((user) => (this.user = user))
            )
            .subscribe();
    }
    initLogout(): void {
        this.auth.logout();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    accessReqeustPage(): void {
        this.dialogRef.close();
        this.route.navigate(['/access-request'], this.navigationExtras);
    }
}
