@defer (when countries$|async) {
    <mds-tab-group data-cy="travel-tab-group" [tabAlign]="'center'" [dynamicHeight]="true" [animationDuration]="'0ms'">
        @if (isAnyControlEnabled(formGroup().controls.flight)) {
            <mds-tab
                #flightTab
                [label]="'Flights'"
                formErrorHighlighter
                [forms]="[formGroup().controls.flight]"
                [filterContent]="flightTab.label"
            >
                @if (flightEditFields(); as flightEditFields) {
                    <medpace-edit-request-table [id]="'flight-tab'" [data]="flightEditFields" />
                }
            </mds-tab>
        }
        @if (isAnyControlEnabled(formGroup().controls.train)) {
            <mds-tab
                #trainTab
                [label]="'Train'"
                formErrorHighlighter
                [forms]="[formGroup().controls.train]"
                [filterContent]="trainTab.label"
            >
                @if (trainEditFields(); as trainEditFields) {
                    <medpace-edit-request-table [id]="'train-tab'" [data]="trainEditFields" />
                }
            </mds-tab>
        }
        @if (isAnyControlEnabled(formGroup().controls.lodging)) {
            <mds-tab
                #lodgingTab
                [label]="'Lodging'"
                formErrorHighlighter
                [forms]="[formGroup().controls.lodging]"
                [filterContent]="lodgingTab.label"
            >
                @if (lodgingEditFields(); as lodgingEditFields) {
                    <medpace-edit-request-table [id]="'lodging-tab'" [data]="lodgingEditFields" />
                }
            </mds-tab>
        }
        @if (isAnyControlEnabled(formGroup().controls.international)) {
            <mds-tab
                #internationalTab
                [label]="'International'"
                formErrorHighlighter
                [forms]="[formGroup().controls.international]"
                [filterContent]="internationalTab.label"
            >
                @if (internationalEditFields(); as internationalEditFields) {
                    <medpace-edit-request-table [id]="'international-tab'" [data]="internationalEditFields" />
                }
            </mds-tab>
        }
        @if (isAnyControlEnabled(formGroup().controls.carService)) {
            <mds-tab
                [label]="'Car Service'"
                #transferTab
                formErrorHighlighter
                [forms]="[formGroup().controls.carService]"
                [filterContent]="transferTab.label"
            >
                @if (carServiceEditFields(); as carServiceEditFields) {
                    <medpace-edit-request-table [id]="'car-service-tab'" [data]="carServiceEditFields" />
                }
            </mds-tab>
        }

        @if (isAnyControlEnabled(formGroup().controls.carRental)) {
            <mds-tab
                [label]="'Rental Car'"
                #carRentalTab
                formErrorHighlighter
                [forms]="[formGroup().controls.carRental]"
                [filterContent]="carRentalTab.label"
            >
                @if (carRentalEditFields(); as carRentalEditFields) {
                    <medpace-edit-request-table [id]="'car-rental-tab'" [data]="carRentalEditFields" />
                }
            </mds-tab>
        }
    </mds-tab-group>
}
