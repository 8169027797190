import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { VMCRCCard2 } from '@models/general-models';
import { IUser } from '@models/interfaces/iUser';
import { Address, Site, SiteCRC, SiteDataModel } from '@models/site';
import { User } from '@models/user';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { transformToSiteDataModel } from '@services/transforms/site-transform';
import { UserService } from '@services/user/user.service';
import { MdsOptionGeneric, toStringOrFallback } from '@utility/utility';
import { emailValidator, maxLengthValidator } from '@utility/utility.validators';
import { Observable, Subject, distinctUntilChanged, filter, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
@Component({
    selector: 'site-register-modal',
    templateUrl: './site-register-modal.component.html',
})
export class SiteRegisterModalComponent implements OnInit, OnDestroy {
    addressFormGroup: FormGroup;
    baseFormGroup: FormGroup;
    supportingCrcFormArray: FormArray<FormGroup>;
    shippingAddressFormGroup: FormGroup;
    hasSupportingCrc: boolean = false;
    site$: Observable<Site> = this.stateService.getSite();
    site: Site;
    sameAsPrimaryAddress: boolean = false;
    vm: VMCRCCard2;
    primaryCRC: SiteCRC;
    supportingCRCs: SiteCRC[];
    verifyCheckboxControl: FormControl<boolean> = new FormControl<boolean>(false, [Validators.requiredTrue]);

    adressRadioButtons: MdsRadioButton[] = [
        {
            label: 'Yes',
            value: 'true',
            name: `yes`,
            id: `yes`,
        },
        {
            label: 'No',
            value: 'false',
            name: `no`,
            id: `no`,
        },
    ];

    supportingCrcRadioButtons: MdsRadioButton[] = [
        {
            label: 'Yes',
            value: 'true',
            name: `yesCrc`,
            id: `yesCrc`,
        },
        {
            label: 'No',
            value: 'false',
            name: `noCrc`,
            id: `noCrc`,
        },
    ];

    crcOptions$ = this.userService.getUser().pipe(
        switchMap((user) =>
            !user.isAdmin && !user.isSuperAdmin
                ? of(<User[]>[])
                : this.stateService
                      .getUsers()
                      .pipe(
                          distinctUntilChanged(
                              (previous, current) => JSON.stringify(previous) === JSON.stringify(current)
                          )
                      )
        ),
        map((users) => {
            return users.map((user) => {
                const displayName = user.firstName + ' ' + user.lastName;
                return <MdsOptionGeneric<User>>{ value: user, viewValue: displayName };
            });
        })
    );

    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<SiteRegisterModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: number,
        private stateService: SiteStateService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private siteService: AdminSiteServices
    ) {
        this.addressFormGroup = new FormGroup({});
        this.supportingCrcFormArray = new FormArray([]);
        this.shippingAddressFormGroup = new FormGroup({});

        this.baseFormGroup = this.formBuilder.group({
            addressFormGroup: this.addressFormGroup,
            supportingCrcFormArray: this.supportingCrcFormArray,
            shippingAddressFormGroup: this.shippingAddressFormGroup,
            verifyCheckboxControl: this.verifyCheckboxControl,
        });

        this.stateService.setSite(this.data);
    }

    protected validAndClose() {
        if (this.supportingCrcFormArray.length > 0 && !this.hasSupportingCrc) this.supportingCrcFormArray.clear();
        if (this.baseFormGroup.valid) {
            this.updateSiteAndChangeStatus();
            this.dialogRef.close();
        } else this.baseFormGroup.markAllAsTouched();

        return;
    }

    updateSiteAndChangeStatus() {
        this.updateSiteModel();
        let model = transformToSiteDataModel(this.site);
        this.addCrcToSiteModel(model);

        this.siteService
            .getAvailableActions(this.site.id)
            .pipe(
                filter((x) => x!! && x.length > 0),
                take(1),
                switchMap((actions) => {
                    return this.siteService.changeSiteStatus(actions[0].name, this.site.id, model);
                })
            )
            .subscribe();
    }

    addCrcToSiteModel(model: SiteDataModel) {
        this.supportingCrcFormArray.controls.forEach((supportingCRC) => {
            model.siteCrcs.push(<SiteCRC>{
                user: <IUser>{
                    firstName: supportingCRC.controls.firstNameCtrl.value,
                    lastName: supportingCRC.controls.lastNameCtrl.value,
                    emailAddress: supportingCRC.controls.emailCtrl.value,
                },
                isPrimary: false,
                userId: 0,
                siteId: this.site.id,
            });
        });
    }

    updateSiteModel() {
        let address: Address = {
            line1: this.addressFormGroup.controls.line1Control.value,
            line2: this.addressFormGroup.controls.line2Control.value,
            city: this.addressFormGroup.controls.cityControl.value,
            stateOrProvince: this.addressFormGroup.controls.stateOrProvinceControl.value,
            postalCode: this.addressFormGroup.controls.postalCodeControl.value,
            country: this.addressFormGroup.controls.countryControl.value,
            region: this.addressFormGroup.controls.regionControl.value,
        };

        let shippingAddFormGroup = this.shippingAddressFormGroup.controls.addressFormGroup as FormGroup;
        let shippingAddressSameAsPrimary = this.shippingAddressFormGroup.controls.addressSameAsPrimary.value as boolean;
        let shippingAddress: Address = {
            line1: shippingAddFormGroup.controls.sline1Control.value,
            line2: shippingAddFormGroup.controls.sline2Control.value,
            city: shippingAddFormGroup.controls.scityControl.value,
            stateOrProvince: shippingAddFormGroup.controls.sstateOrProvinceControl.value,
            postalCode: shippingAddFormGroup.controls.spostalCodeControl.value,
            country: shippingAddFormGroup.controls.scountryControl.value,
            region: shippingAddFormGroup.controls.sregionControl.value,
        };

        this.site.isShippingAddressSameAsPrimary = shippingAddressSameAsPrimary;
        this.site.shippingAddress = shippingAddress;
        this.site.address = address;
        this.primaryCRC.user.phoneNumber = this.baseFormGroup.controls.phoneCtrl.value;
        this.site.isReviewedByCRC = true;
    }

    ngOnInit(): void {
        this.baseFormGroup.addControl('phoneCtrl', new FormControl('', [Validators.required, maxLengthValidator(20)]));
        this.baseFormGroup.addControl(
            'supportingRadioButtons',
            new FormControl(this.hasSupportingCrc ? 'true' : 'false')
        );

        this.addressFormGroup.addControl(
            'addressSameAsPrimary',
            new FormControl(this.sameAsPrimaryAddress ? 'true' : 'false')
        );

        this.site$
            .pipe(takeUntil(this.componentDestroyed$))
            .pipe(
                tap((siteResult) => {
                    if (siteResult) {
                        this.site = siteResult;
                        this.primaryCRC = this.site.siteCRCs.find((crc) => crc.isPrimary);
                        this.baseFormGroup.controls.phoneCtrl.setValue(this.primaryCRC.user.phoneNumber);
                    }
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    selectSupportingCrcValue(event: MatRadioChange) {
        this.hasSupportingCrc = event.value === 'true' ? true : false;

        if (this.hasSupportingCrc && this.supportingCrcFormArray.length == 0) this.addSupportingCRC();
    }

    public toStringOrFallback(object: Object, fallback: string) {
        return toStringOrFallback(object, fallback);
    }

    public removeSupportingCRC(index: number) {
        this.supportingCrcFormArray.removeAt(index);
    }
    public addSupportingCRC() {
        this.supportingCrcFormArray.push(this.createSupportingCrcFormGroup());
    }

    private createSupportingCrcFormGroup(): FormGroup {
        let newFormGroup = this.formBuilder.group({
            firstNameCtrl: new FormControl('', [Validators.required, maxLengthValidator(50)]),
            lastNameCtrl: new FormControl('', [Validators.required, maxLengthValidator(50)]),
            emailCtrl: new FormControl('', [Validators.required, emailValidator, maxLengthValidator(60)]),
        });
        return newFormGroup;
    }
}
