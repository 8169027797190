<form
    [formGroup]="studyFormGroup"
    appScrollToInvalidControlOnSubmit
    class="o-grid"
    *ngIf="{ user: user$ | async } as vm"
>
    <div class="o-grid__row">
        <div class="o-grid__item half">
            <medpace-vertical-stepper [steps]="steps"></medpace-vertical-stepper>
        </div>
        <div class="o-grid__item">
            <div class="o-grid">
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="flex start middle">
                            <span class="required-asterisk">*</span>
                            <span> indicates a required field </span>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="submitButton"></ng-container>
                </div>
                <div class="o-grid__row" id="tierSupport">
                    <div class="o-grid__item">
                        <medpace-input-card
                            [inputObj]="study?.supportTier"
                            type="radio"
                            [showEditSaveButton]="isUpdateAndAdmin(vm.user)"
                            [editMode]="!isUpdate"
                            title="Tier Support"
                            [parentFormGroup]="studyFormGroup"
                            [schema]="tierFields"
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="processStudyChange($event)"
                        >
                        </medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row" name="" id="studyDetails">
                    <div class="o-grid__item">
                        <medpace-input-card
                            [inputObj]="study"
                            [showEditSaveButton]="isUpdateAndAdmin(vm.user)"
                            [editMode]="!isUpdate"
                            type="study-detail"
                            title="Study Details"
                            [parentFormGroup]="studyFormGroup"
                            (incrementalUpdate)="submitIncrementalSave()"
                            (inputChangeEvent)="processStudyChange($event)"
                        >
                        </medpace-input-card>
                    </div>
                </div>

                <div class="o-grid__row" id="studyInfo">
                    <div class="o-grid__item" *ngIf="vm.user.isAdmin || vm.user.isSuperAdmin" name="study-info">
                        <mds-expansion-panel
                            expansionFormErrorHighlighter
                            id="studyInfoExpansionPanel"
                            [panelTitle]="'Study Information'"
                            [description]="''"
                            icon="info"
                            [forms]="[getStudyInfoFormGroup()]"
                            [attr.data-cy]="'studyInfoExpansionPanel'"
                        >
                            <medpace-input-card
                                [isCard]="false"
                                [inputObj]="study?.studyInfo"
                                [showEditSaveButton]="isUpdate"
                                [editMode]="!isUpdate"
                                type="study-info"
                                [title]="studyInfoTitle"
                                [parentFormGroup]="studyFormGroup"
                                (incrementalUpdate)="submitIncrementalSave()"
                                (inputChangeEvent)="processStudyChange($event)"
                                [isModal]="true"
                            >
                            </medpace-input-card>
                        </mds-expansion-panel>
                    </div>
                    <div class="o-grid__item" *ngIf="!(vm.user.isAdmin || vm.user.isSuperAdmin)">
                        <medpace-input-card
                            [inputObj]="study?.studyInfo"
                            [showEditSaveButton]="false"
                            [editMode]="!isUpdate"
                            [parentFormGroup]="studyFormGroup"
                            [title]="studyInfoTitle"
                            type="study-info"
                        ></medpace-input-card>
                    </div>
                </div>

                <!-- <div class="o-grid__row" id="sites" *ngIf="isUpdateAndAdmin(vm.user)">
                    <div class="o-grid__item">
                        <study-sites-card
                            (uploadSpreadsheetClickedEvent)="uploadSpreadsheetClicked()"
                            (addNewSiteClickedEvent)="addNewSiteClicked()"
                            (siteRowClickedEvent)="siteRowClicked($event)"
                        >
                        </study-sites-card>
                    </div>
                </div> -->

                <ng-container *ngTemplateOutlet="submitButton"></ng-container>
            </div>
        </div>
    </div>
</form>

<ng-template #submitButton>
    <div class="o-grid__item" *ngIf="study">
        <div class="flex end">
            <mds-button
                *ngIf="this.isUpdate"
                [id]="'submitStudy'"
                variant="primary"
                (clickEvent)="submit({ type: 'save', doRoute: true })"
                [label]="label"
                type="submit"
            ></mds-button>
            <mds-button
                *ngIf="!this.isUpdate"
                [id]="'submitStudy'"
                variant="primary"
                (clickEvent)="approve({ type: 'save', doRoute: true })"
                [label]="'Approve and Submit'"
                type="submit"
            ></mds-button>
            <mds-button
                *ngIf="!this.isUpdate"
                [id]="'submitStudy'"
                variant="primary"
                (clickEvent)="reject()"
                [label]="'Reject'"
                type="submit"
            ></mds-button>
        </div>
    </div>
</ng-template>
