import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'medpace-textarea-autocomplete',
    templateUrl: './medpace-textarea-autocomplete.component.html',
    styleUrls: ['./medpace-textarea-autocomplete.component.scss'],
})
export class MedpaceTextareaAutocompleteComponent implements OnInit, OnDestroy {
    @Input() formCtrl: FormControl<string>;
    @Input() formCtrlName: string;
    @Input() formGrp: FormGroup;
    @Input() autoSize: boolean = false;
    @Input() cdkAutosizeMaxRows: number = NaN;
    @Input() cdkAutosizeMinRows: number = NaN;
    @Input() label: string = '';
    @Input() placeholder: string;
    @Input() hint: string;
    @Input() appearance: 'fill' | 'outline';
    @Input() floatLabel: 'always' | 'auto';
    @Input() color: ThemePalette;
    @Input() required: boolean = false;
    @Input() options: MedpaceAutocompleteObject[] = [];
    @Input() style: string;
    @Input() id: string;

    private optionsSubject = new BehaviorSubject<MedpaceAutocompleteObject[]>([]);
    options$ = this.optionsSubject.asObservable();

    @Output() optionSelected = new EventEmitter<string>();

    @ViewChild('autoTrigger') autoTrigger: MatAutocompleteTrigger;

    private componentDestroyed$: Subject<boolean> = new Subject();
    formControl: FormControl<string>;

    ngOnInit() {
        //assing form control
        if (!!this.formCtrl) {
            this.formControl = this.formCtrl;
        } else if (!!this.formGrp && !!this.formCtrlName) {
            this.formControl = <FormControl<string>>this.formGrp?.get(this.formCtrlName);
        } else {
            this.formControl = new FormControl('');
        }

        this.optionsSubject.next(this.options);
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
    private getMatchingOptions(value: string) {
        if (value === '') return this.options;
        return this.options.filter((option) => option.value.toLowerCase().includes(value.toLowerCase()));
    }
    update(event: Event): void {
        const value = (event.target as any).value as string;
        const matchingOptions = this.getMatchingOptions(value);
        if (matchingOptions.length > 0 && !this.autoTrigger.panelOpen) {
            this.autoTrigger.openPanel();
        } else if (matchingOptions.length === 0 && this.autoTrigger.panelOpen) {
            this.autoTrigger.closePanel();
        }
        this.optionsSubject.next(matchingOptions);

        this.formControl.setValue(value, { emitEvent: false }); // Do not emit event to prevent endless loop
    }
    onOptionSelected(event: MatAutocompleteSelectedEvent) {
        this.formControl.setValue(event.option.value.value, { emitEvent: false });
        this.optionSelected.emit(this.formControl.value);
    }
}
