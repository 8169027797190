import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GenericRequestStatus } from '@models/request';
import { RequestApprovalStatus } from '@models/requestApprovalStatus';
import { RequestStatusService } from './../../../services/request-status/request-status.service';

@Component({
    selector: 'medpace-request-change-modal',
    templateUrl: './request-change-modal.component.html',
})
export class MedpaceRequestChangeModalComponent implements OnInit {
    textStatus: string;
    statusConstants: RequestApprovalStatus[];
    displayStatus: RequestApprovalStatus[];
    updatedStatus: number;
    isCompletable: boolean;
    requestStatusChangeFormGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<MedpaceRequestChangeModalComponent>,
        public requestStatusService: RequestStatusService,
        @Inject(MAT_DIALOG_DATA) public data,
        private formBuilder: FormBuilder
    ) {
        this.requestStatusChangeFormGroup = this.formBuilder.group({
            additionalInfoControl: [''],
            statusDropdownControl: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.statusConstants = this.requestStatusService.getStatuses();
        this.displayStatus = this.arrangeDisplayStatuses(this.requestStatusService.getStatusesAvailabletoChange());

        this.isCompletable = true;
        this.textStatus = this.statusConstants.find((x) => x.value === this.data.request.status).viewValue;
    }

    arrangeDisplayStatuses(statuses: RequestApprovalStatus[]): RequestApprovalStatus[] {
        const resultStatuses = [...statuses];
        if (this.data.request.type === 'payment' && this.data.request.paymentType === 'out-of-pocket') {
            //move Pending Set-up before Pending Approval - only Reimbursement
            moveItemInArray(
                resultStatuses,
                resultStatuses.findIndex((val) => val.value === GenericRequestStatus.PendingSetUp),
                resultStatuses.findIndex((val) => val.value === GenericRequestStatus.PendingApprovel)
            );
        } else {
            //Don't show Pending Set-Up
            resultStatuses.splice(
                resultStatuses.findIndex((val) => val.value === GenericRequestStatus.PendingSetUp),
                1
            );
        }
        return resultStatuses;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    saveDialog(): void {
        if (this.requestStatusChangeFormGroup.valid && this.isCompletable) {
            this.dialogRef.close({
                status: this.updatedStatus,
                info: this.requestStatusChangeFormGroup.controls.additionalInfoControl.value,
            });
        }
    }

    updateStatus(event: any): void {
        let attemptedStatus = this.statusConstants.find((x) => x.value === event.value).value;
        if (attemptedStatus === 3) {
            if (this.data.completable === true) {
                this.updatedStatus = attemptedStatus;
                this.isCompletable = true;
            } else {
                this.isCompletable = false;
            }
        } else {
            this.isCompletable = true;
            this.updatedStatus = attemptedStatus;
        }
    }
}
