<ng-container *ngIf="mode === 'view'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Hotel brand preference</h5>
                    <span id="hotelBrandPreference">{{
                        formGroup.controls.hotelBrandPreference.value || 'Unspecified'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Hotel room preference</h5>
                    <span id="roomPreference">{{ formGroup.controls.roomPreference.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special needs</h5>
                    <span id="specialNeeds">{{ formGroup.controls.specialNeeds.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Allergies</h5>
                    <span id="allergies">{{ formGroup.controls.allergies.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="mode === 'edit'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="hotelBrandPreference"
                    [formCtrl]="formGroup.controls.hotelBrandPreference"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Hotel brand preference"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="roomPreference"
                    [formCtrl]="formGroup.controls.roomPreference"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Hotel room preference"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="specialNeeds"
                    [formCtrl]="formGroup.controls.specialNeeds"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Special needs"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="allergies"
                    [formCtrl]="formGroup.controls.allergies"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Allergies"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
    </div>
</ng-container>
