<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h1>Manage</h1>
            </div>
        </div>
    </div>
    <!-- Access Requests -->

    <ng-container *ngIf="{ vm: accessRequests$ | async } as accessRequests">
        <div class="o-grid__row" [ngStyle]="accessRequests.vm ? { display: 'block' } : { display: 'none' }">
            <div class="o-grid__item">
                <mds-expansion-panel
                    [panelTitle]="'Pending Access Requests'"
                    [description]="''"
                    icon="pending_actions"
                    [attr.data-cy]="'accessRequestExpansion'"
                    [expanded]="isPendingARExpanded"
                >
                    <medpace-table
                        [isCard]="false"
                        [attr.data-cy]="'accessRequestTable'"
                        [data]="accessRequests.vm || undefined"
                        title="Pending Access Requests"
                        [columns]="accessRequestcolumns"
                        [fieldMap]="accessRequestcolumnMap"
                        [isSearching]="true"
                        (rowClickEvent)="doClickAccessRequestRow($event)"
                    ></medpace-table>
                </mds-expansion-panel>
            </div>
        </div>
    </ng-container>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Access Requests Log'"
                [description]="''"
                icon="analytics"
                [attr.data-cy]="'accessRequestLogExpansion'"
                (openedEvent)="{ sub: loadAccessRequestLogSubject.next(true), data: setAccessRequestLogsData() }"
            >
                @defer (when loadAccessRequestLogSubject |async) {
                    <medpace-table
                        *ngIf="{
                            vm: accessRequestLogs$ | async,
                        } as accessRequestLogs"
                        [isCard]="false"
                        [attr.data-cy]="'accessRequestLogTable'"
                        [data]="accessRequestLogs.vm || undefined"
                        title="Access Requests Log"
                        [columns]="accessRequestLogcolumns"
                        [fieldMap]="accessRequestLogcolumnMap"
                        [isSearching]="true"
                        [includePaginator]="true"
                        [ifFiltering]="false"
                        [showFilters]="true"
                        (doFilterData)="filterData($event)"
                    ></medpace-table>
                    <div class="o-grid__item">
                        <div class="flex">
                            <mds-button
                                [id]="'user-create-btn'"
                                variant="primary"
                                label="Export data"
                                (clickEvent)="downloadAccessRequestLogSpreadsheet()"
                            ></mds-button>
                        </div>
                    </div>
                }
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row" *ngIf="{ vm: tableUsers$ | async } as users">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Users'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'usersExpansion'"
            >
                <medpace-table
                    [isCard]="false"
                    [attr.data-cy]="'usersTable'"
                    [data]="users.vm ?? undefined"
                    title="Users"
                    [columns]="columns"
                    [fieldMap]="columnMap"
                    [isSearching]="true"
                    (rowClickEvent)="doClickRow($event)"
                    (doFilterData)="getFilteredData($event)"
                ></medpace-table>
                <div class="o-grid__item">
                    <div class="flex">
                        <mds-button
                            [id]="'user-sendInvitation-btn'"
                            variant="primary"
                            label="Invite CRC User"
                            (clickEvent)="openInvitationDialog()"
                        ></mds-button>
                        <mds-button
                            style="display: inline-block"
                            [id]="'user-create-btn'"
                            variant="outline"
                            label="Create User"
                            (clickEvent)="openUserCreationDialog()"
                        ></mds-button>
                    </div>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row" id="user-invitation-sheet">
        <div class="o-grid__item">
            <mds-expansion-panel [panelTitle]="'Download User Spreadsheet'" [description]="''" icon="file_download">
                <div class="flex">
                    <mds-button
                        [id]="'user-spreadsheet-download-btn'"
                        variant="primary"
                        label="Download User Spreadsheet"
                        (clickEvent)="downloadUserSpreadsheet()"
                        data-cy="download-user-spreadsheet"
                    ></mds-button>
                </div>
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row" id="rates">
        <div class="o-grid__item">
            <mds-expansion-panel [panelTitle]="'Reimbursement Rate Upload'" [description]="''" icon="upload_file">
                <medpace-reimbursement-rates-upload> </medpace-reimbursement-rates-upload>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row" id="announcements">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Announcements'"
                [description]="''"
                icon="announcement"
                [attr.data-cy]="'announcementExpansion'"
            >
                <medpace-announcements
                    [announcements]="announcements$"
                    (createAnnouncementEvent)="createAnnouncement()"
                    (editAnnouncementEvent)="openAnnouncement($event)"
                ></medpace-announcements>
            </mds-expansion-panel>
        </div>
    </div>
</div>
