import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileType } from '@app/enums/FileTypes';
import { transformToSite } from '@services/transforms/site-transform';
import { ParsingResult, XLSXService } from '@services/xlsx/xlsx.service';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { User } from 'src/app/models/user';
import { Site, SiteCRC, SiteRawDataModel } from '../../../models/site';

@Component({
    selector: 'medpace-bulk-site-modal',
    templateUrl: './site-upload-modal.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedpaceBulkSiteModalComponent {
    acceptableFileTypes: string[] = [FileType.XLSX];

    private fileParsedSubject = new BehaviorSubject<ParsingResult<SiteRawDataModel>>(<ParsingResult<SiteRawDataModel>>{
        isSuccess: null,
        parsedValues: [],
    });
    private dataErrorsSubject = new BehaviorSubject<string[]>(new Array<string>());
    private sites: Site[];

    public vm$ = combineLatest({
        parsingResult: this.fileParsedSubject,
        dataErrors: this.dataErrorsSubject,
    }).pipe(
        map((result) => {
            const canBeUploaded =
                !!result.parsingResult && result.parsingResult.isSuccess === true && result.dataErrors.length === 0;
            this.sites = canBeUploaded
                ? result.parsingResult.parsedValues.map((model) => transformToSite(model))
                : null;
            return { canBeUploaded, sites: this.sites, ...result };
        })
    );

    constructor(
        public dialogRef: MatDialogRef<MedpaceBulkSiteModalComponent>,
        private xlsxService: XLSXService,
        @Inject(MAT_DIALOG_DATA) public data: User[]
    ) {}

    getSpreadsheet(file: File) {
        this.xlsxService
            .parseSheet<SiteRawDataModel>(
                file,
                () => new SiteRawDataModel(),
                (target: SiteRawDataModel, colData, parsedValue: Object): boolean => {
                    try {
                        const colName_LowerCase_NoWhitespace = colData.colName.toLowerCase().replace(/\s+/g, '');
                        switch (colName_LowerCase_NoWhitespace) {
                            case 'Id'.toLowerCase():
                                target.id = 0;
                                break;
                            case 'StudyId'.toLowerCase():
                                target.studyId = parsedValue as number;
                                break;
                            case 'SiteNumber'.toLowerCase():
                                target.siteNumber = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'InstitutionName'.toLowerCase():
                                target.institutionName = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'PILastName'.toLowerCase():
                                target.pilastName = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'Address1'.toLowerCase():
                                target.address1 = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'Address2'.toLowerCase():
                                target.address2 = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'City'.toLowerCase():
                                target.city = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'State'.toLowerCase():
                                target.state = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'Zipcode'.toLowerCase():
                                target.zipcode = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'Region'.toLowerCase():
                                target.region = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'Country'.toLowerCase():
                                target.country = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'PaymentCC'.toLowerCase():
                                target.paymentCc = parsedValue as boolean;
                                break;
                            case 'PaymentACH'.toLowerCase():
                                target.paymentCheck = parsedValue as boolean;
                                break;
                            case 'PaymentWireTransfer'.toLowerCase():
                                target.paymentWireTransfer = parsedValue as boolean;
                                break;
                            case 'PaymentDirect'.toLowerCase():
                                target.paymentDirect = parsedValue as boolean;
                                break;
                            case 'PrimaryCRCEmail'.toLowerCase():
                                target.primaryCRCEmail = parsedValue ? (parsedValue as string) : '';
                                break;
                            case 'SecondaryCRCEmail'.toLowerCase():
                                target.supportingCRCEmail = parsedValue ? (parsedValue as string) : '';
                                break;
                            case '':
                                break;
                            default:
                                return false;
                        }
                    } catch (error) {
                        return false;
                    }
                    return true;
                },
                0
            )
            .subscribe((result) => {
                let dataErrors = new Array<string>();
                if (!result.isSuccess) {
                    dataErrors.push('Error while parsing');
                } else if (!result.parsedValues || result.parsedValues.length === 0) {
                    dataErrors.push('Could not find any sites in the supplied spreadsheet');
                } else {
                    result.parsedValues.forEach((siteRawDataModel) => {
                        siteRawDataModel.siteCrcs = new Array<SiteCRC>();
                        if (siteRawDataModel.primaryCRCEmail) {
                            const user = this.data.find(
                                (user) =>
                                    user.emailAddress.toLowerCase() === siteRawDataModel.primaryCRCEmail.toLowerCase()
                            );
                            if (!!user) siteRawDataModel.siteCrcs.push(<SiteCRC>{ isPrimary: true, user: user });
                            else
                                dataErrors.push(
                                    `Primary user with email: ${siteRawDataModel.primaryCRCEmail} does not exist!`
                                );
                        }
                        if (siteRawDataModel.supportingCRCEmail) {
                            const user = this.data.find(
                                (user) =>
                                    user.emailAddress.toLowerCase() ===
                                    siteRawDataModel.supportingCRCEmail.toLowerCase()
                            );
                            if (!!user) siteRawDataModel.siteCrcs.push(<SiteCRC>{ isPrimary: false, user: user });
                            else
                                dataErrors.push(
                                    `Supporting user with email: ${siteRawDataModel.supportingCRCEmail} does not exist!`
                                );
                        }
                        if (isNaN(+siteRawDataModel.studyId)) {
                            dataErrors.push(
                                `Study ID has incorrect format: expected number, got ${siteRawDataModel.studyId}`
                            );
                        }
                    });
                }
                this.fileParsedSubject.next(dataErrors.length === 0 ? result : null);
                this.dataErrorsSubject.next(dataErrors);
            });
    }

    onCancelClicked(): void {
        this.dialogRef.close();
    }
    onSubmitClicked(): void {
        this.dialogRef.close(this.sites);
    }
}
