import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Chip } from '@medpacesoftwaredevelopment/designsystem';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { ICreateUserDTO, IUpdateUserDTO } from '@models/interfaces/iUser';
import { Site } from '@models/site';
import { AccessGroupStateService } from '@services/state-management/access-group-state.service';
import { UserStateService } from '@services/state-management/user-state.service';
import {
    emailValidator,
    maxLengthValidator,
    phoneNumberFormatValidatorWithMaxLength,
} from '@utility/utility.validators';
import { Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Study } from 'src/app/models/study';
import { AdminStudyServices } from 'src/app/services/admin/admin-study.sevice';
import { CreateUserDTO, UpdateUserDTO } from '../../../../models/user';

@Component({
    template: '',
    styleUrls: ['./user-management-modal.component.scss'],
})
export abstract class MedpaceUserManagementModalComponent implements OnDestroy {
    protected componentDestroyed$: Subject<boolean> = new Subject();
    @Output() inputChangeEvent = new EventEmitter<InputChange>();
    componentIdName: string = 'user-management-modal';
    siteAutocompleteName: string = 'assignedSite';
    studyListChipsSubject: ReplaySubject<Chip[]> = new ReplaySubject<Chip[]>(1);
    studyListChips$: Observable<Chip[]> = this.studyListChipsSubject.asObservable();
    siteListChips: Chip[] = [];
    studies: Study[] = [];
    sites: Site[] = [];
    studyIdsForWhoseSitesUserIsPrimaryCRC: number[] = [];
    siteIdsForWhoseSitesUserIsPrimaryCRC: number[] = [];

    userAccessGroupFieldName: string = 'accessGroup';
    userAccessGroupFieldLabel: string = 'Access Level';
    userAccessPlaceholder: string = 'Select access level';

    firstNameFieldName: string = 'First Name';
    lastNameFieldName: string = 'Last Name';
    emailAddressFieldName: string = 'Email Address';
    phoneNumberFieldName: string = 'Phone Number';
    authenticationMethodFieldName: string = 'Authentication Method';
    loginOnlyWithMedpaceAccount: string = 'Login Only With Medpace Account';
    loginWithLocalAccount: string = 'Login With Local Account';
    matToolTipTextCheckBox: string = 'Activate Login with SSO. Users with Medpace Accounts Only';
    matToolTipResetPasswordTextCheckBox: string =
        'If checked, the user will receive a newly-generated password and must change it upon the first login.';
    showPrimaryCRCStudyWarning: boolean = false;
    primaryCRCStudyWarning: string =
        'Some studies cannot be deleted because user is assigned as PrimaryCRC to one of Sites associated with this Study. Please change Site Primary CRC.';
    showPrimaryCRCSiteWarning: boolean = false;
    primaryCRCSiteWarning: string =
        'Some sites cannot be deleted because user is assigned as PrimaryCRC to one of Sites. Please change Site Primary CRC.';

    personalDataFormGroup = this.formBuilder.group({
        [this.firstNameFieldName]: new FormControl<string>(null, [Validators.required, maxLengthValidator(50)]),
        [this.lastNameFieldName]: new FormControl<string>(null, [Validators.required, maxLengthValidator(50)]),
        [this.emailAddressFieldName]: new FormControl<string>('', [
            Validators.required,
            emailValidator,
            maxLengthValidator(60),
        ]),
        [this.phoneNumberFieldName]: new FormControl<string>('', [phoneNumberFormatValidatorWithMaxLength(20)]),
    });

    accessGroupOptions$: Observable<MdsOption[]> = this.accessGroupStateService.getAccessGroups().pipe(
        takeUntil(this.componentDestroyed$),
        map((accessGroups) => {
            return accessGroups.map((accessGroup) => {
                return <MdsOption>{ value: accessGroup.id, viewValue: accessGroup.displayName };
            });
        })
    );

    assignedAccessGroupFormGroup = this.formBuilder.group({
        [this.userAccessGroupFieldName]: new FormControl<number>(null, [
            Validators.required,
            (control: AbstractControl): null | { isPrimaryCRC: string } => {
                if (
                    control.value !== 3 &&
                    control.value !== null &&
                    (this.studyIdsForWhoseSitesUserIsPrimaryCRC.length > 0 ||
                        this.siteIdsForWhoseSitesUserIsPrimaryCRC.length > 0)
                ) {
                    return {
                        isPrimaryCRC:
                            'Unable to change role because the user is still the Primary CRC in one of the assigned sites.',
                    };
                }
                return null;
            },
        ]),
    });

    assignedStudyFormGroup = this.formBuilder.group({
        studyControl: [''],
    });

    assignedSiteFormGroup = this.formBuilder.group({
        [this.siteAutocompleteName]: [''],
    });

    parentFormGroup: FormGroup;
    filteredStudies$: Observable<string[]>;
    filteredSites$: Observable<string[]>;
    selectedStudies: Study[] = [];
    selectedSites: Site[] = [];
    isCRC: boolean = false;
    isAdmin: boolean = false;
    isEditing: boolean = true;

    radioButtons: MdsRadioButton[] = [
        {
            label: 'All',
            value: 'all',
            name: `${this.componentIdName}All`,
            id: `${this.componentIdName}All`,
        },
        {
            label: 'Only Assigned',
            value: 'assigned',
            name: `${this.componentIdName}assigned`,
            id: `${this.componentIdName}assigned`,
        },
    ];
    dialogTitle: string = 'User Profile';
    resultUser: CreateUserDTO = new CreateUserDTO();
    dbUser: CreateUserDTO;

    constructor(
        public dialogRef: MatDialogRef<MedpaceUserManagementModalComponent>,
        protected adminStudies: AdminStudyServices,
        protected accessGroupStateService: AccessGroupStateService,
        protected formBuilder: FormBuilder,
        protected userStateService: UserStateService
    ) {
        this._initFormControl();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.userStateService.resetUser();
    }

    submitClick(submitfunction: () => void): void {
        switch (this.resultUser.userAccessGroupId) {
            case 1:
                this.resultUser.studyIds = [];
                this.resultUser.siteIds = [];
                break;
            case 2:
                this.resultUser.studyIds = this.selectedStudies.map((study) => study.id);
                this.resultUser.siteIds = [];
                break;
            case 3:
                this.resultUser.studyIds = this.selectedStudies.map((study) => study.id);
                this.resultUser.siteIds = this.selectedSites.map((site) => site.id);
                break;
        }
        if (this.parentFormGroup.valid) {
            this._readUserFormValues();
            submitfunction();
            this.dialogRef.close();
        } else {
            this.parentFormGroup.markAllAsTouched();
        }
    }

    cancelClick(): void {
        this.dialogRef.close();
    }

    selectedStudy(option: MatOption): void {
        if (!this.selectedStudies) {
            this.selectedStudies = [];
            this.studyListChipsSubject.next([]);
        }
        const selectedStudy = this.studies.find((x) => x.protocol === option?.value);
        this.selectedStudies.push(selectedStudy);
        let studyChipList: Chip[] = this._getStudyListChips();
        studyChipList.push({ color: 'primary', value: selectedStudy.protocol, removable: true });
        this.studyListChipsSubject.next(studyChipList);
        this.assignedSiteFormGroup?.controls[`${this.siteAutocompleteName}`].patchValue('');

        this.assignedStudyFormGroup.controls.studyControl.setValue(null);
    }

    removeStudy(chipEvent: MatChipEvent): void {
        const studyRemoved = this.selectedStudies.find((x) => x.protocol == chipEvent.chip.value);
        const index = this.selectedStudies.indexOf(studyRemoved);

        if (index >= 0 && this.studyIdsForWhoseSitesUserIsPrimaryCRC.indexOf(studyRemoved.id) === -1) {
            this.selectedStudies.splice(index, 1);
            let studyChipList: Chip[] = this._getStudyListChips();
            studyChipList.splice(index, 1);
            this.studyListChipsSubject.next(studyChipList);
            this.removeSitesFromStudy(studyRemoved);
            this.assignedSiteFormGroup?.controls[`${this.siteAutocompleteName}`].patchValue('');
        } else if (this.studyIdsForWhoseSitesUserIsPrimaryCRC.indexOf(studyRemoved.id) !== -1) {
            this.showPrimaryCRCStudyWarning = true;
        }
        this.assignedStudyFormGroup.controls.studyControl.setValue(null);
    }

    selectedSite(option: MatOption): void {
        if (!this.selectedSites) {
            this.selectedSites = [];
        }
        let siteNameWithSiteNumber: string = option?.value;
        let siteName = siteNameWithSiteNumber.split('/ ')[1];
        const selectedSite = this.sites.find((x) => x.info?.name === siteName);
        if (selectedSite) this.selectedSites.push(selectedSite);
        this.siteListChips.push({ color: 'primary', value: selectedSite?.info?.name, removable: true });
        this.assignedSiteFormGroup?.controls[`${this.siteAutocompleteName}`].setValue(null);
    }

    removeSite(chipEvent: MatChipEvent): void {
        const siteRemoved = this.selectedSites.find((x) => x.info.name === chipEvent.chip.value);
        const index = this.selectedSites.indexOf(siteRemoved);

        if (index >= 0 && this.siteIdsForWhoseSitesUserIsPrimaryCRC.indexOf(siteRemoved.id) === -1) {
            this.selectedSites.splice(index, 1);
            this.siteListChips.splice(index, 1);
            this.assignedSiteFormGroup?.controls[`${this.siteAutocompleteName}`].patchValue('');
        } else if (this.siteIdsForWhoseSitesUserIsPrimaryCRC.indexOf(siteRemoved.id) !== -1) {
            this.showPrimaryCRCSiteWarning = true;
        }
        this.assignedStudyFormGroup.controls.studyControl.setValue(null);
    }

    removeSitesFromStudy(removedStudy: Study) {
        for (let i = this.selectedSites.length - 1; i >= 0; i--) {
            if (this.selectedSites[i].studyId === removedStudy.id) {
                this.selectedSites.splice(i, 1);
            }
        }
        this._refreshSiteChips();
    }

    protected _modalDataSetup(): void {
        this.adminStudies.getActiveStudies().subscribe((result) => {
            this.studies = result;
            this._setUserStudies();
            this._setUserSites();
            this._setStudiesFiltration();
            this._setSitesFiltration();
            this._initializeChips();
        });
    }

    protected _initializeChips() {
        this.studyListChipsSubject.next(
            this.selectedStudies.map((study) => {
                return <Chip>{
                    value: study.protocol,
                    removable: true,
                };
            })
        );

        this.siteListChips = this.selectedSites.map((site) => {
            return <Chip>{ color: 'primary', value: site?.info?.name, removable: true };
        });
    }

    protected _setUserStudies(): void {
        this.resultUser?.studyIds?.forEach((userStudyId) => {
            const current = this.selectedStudies.filter((selectedStudy) => selectedStudy.id === userStudyId)[0];
            if (!current) {
                let studyToAdd = this.studies.filter((studyToAdd) => {
                    return studyToAdd.id === userStudyId;
                })[0];
                this.selectedStudies.push(studyToAdd);
                studyToAdd?.sites.forEach((site) => {
                    if (!this.sites.map((existingSite) => existingSite.id).includes(site.id)) {
                        this.sites.push(site);
                    }
                });
            }
        });
        this.studyListChips$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap(() => {
                    this.sites = [];
                    this.selectedStudies?.forEach((study) => {
                        this.sites = this.sites.concat(study?.sites);
                    });
                })
            )
            .subscribe();
    }

    protected _setUserSites(): void {
        this.resultUser?.siteIds?.forEach((userSiteId) => {
            if (!this.selectedSites?.filter((selectedSite) => selectedSite?.id === userSiteId)[0]) {
                let siteToAdd = this.sites?.filter((siteToAdd) => {
                    return siteToAdd.id === userSiteId;
                })[0];
                if (siteToAdd) this.selectedSites.push(siteToAdd);
            }
        });
    }

    protected _setStudiesFiltration(): void {
        this.filteredStudies$ = this.assignedStudyFormGroup.controls.studyControl.valueChanges.pipe(
            startWith(null),
            map((study: any | null) => {
                if (typeof study === 'string') {
                    return this._filterStudies(study).map((x) => x.protocol);
                } else {
                    return this.studies
                        .slice()
                        .filter((x) => !this.selectedStudies.map((z) => z.protocol).includes(x.protocol))
                        .map((x) => x.protocol);
                }
            })
        );
    }

    protected _setSitesFiltration(): void {
        this.filteredSites$ = this.assignedSiteFormGroup?.controls[`${this.siteAutocompleteName}`].valueChanges.pipe(
            startWith(null),
            map((site: any | null) => {
                if (typeof site === 'string') {
                    return this._filterSites(site).map((x) => `${x?.info?.siteNumber}/ ${x?.info?.name}`);
                } else {
                    return this.sites
                        .slice()
                        .filter((x) => !this.selectedSites.map((z) => z?.info?.name).includes(x?.info?.name))
                        .map((x) => `${x?.info?.siteNumber}/ ${x?.info?.name}`);
                }
            })
        );
    }

    protected _filterStudies(value: string): Study[] {
        let studyProtocol = '';
        studyProtocol = value.toLowerCase();
        let filterStudies = this.studies.filter(
            (study) => !this.selectedStudies.map((selectedStudy) => selectedStudy.id).includes(study.id)
        );
        return filterStudies.filter((study) => study.protocol.toLowerCase().indexOf(studyProtocol) === 0);
    }
    protected _filterSites(value: string): Site[] {
        let siteName = '';
        siteName = value.toLowerCase();
        let sitesToFilter = this.sites.filter(
            (site) => !this.selectedSites.map((selectedSite) => selectedSite.id).includes(site.id)
        );

        return sitesToFilter.filter((site) =>
            `${site?.info?.siteNumber}/ ${site?.info?.name}`.toLowerCase().includes(siteName)
        );
    }

    protected _getStudyListChips(): Chip[] {
        let studyChipList1: Chip[];
        this.studyListChips$
            .pipe(
                take(1),
                tap((studyChipList) => {
                    studyChipList1 = studyChipList;
                })
            )
            .subscribe();
        return studyChipList1;
    }

    protected _refreshSiteChips(): void {
        this.siteListChips = [];
        this.selectedSites.forEach((site) => {
            this.siteListChips.push({ color: 'primary', value: site?.info?.name, removable: true });
        });
    }

    protected _checkUserGroup(value): void {
        this.resultUser.userAccessGroupId = value;
        switch (value) {
            case 1:
                this.selectedStudies.filter(
                    (study) => this.studyIdsForWhoseSitesUserIsPrimaryCRC.indexOf(study.id) > -1
                );
                this.selectedSites.filter((site) => this.siteIdsForWhoseSitesUserIsPrimaryCRC.indexOf(site.id) > -1);
                this.isCRC = false;
                this.isAdmin = false;
                this._initializeChips();
                break;
            case 2:
                this.selectedSites.filter((site) => this.siteIdsForWhoseSitesUserIsPrimaryCRC.indexOf(site.id) > -1);
                this.isCRC = false;
                this.isAdmin = true;
                this._initializeChips();
                break;
            case 3:
                this.isCRC = true;
                this.isAdmin = false;
                this._initializeChips();
                break;
            default:
                this.selectedStudies = [];
                this.isCRC = false;
                this.isAdmin = false;
                this._initializeChips();
        }
    }

    protected _initFormControl(): void {
        this.parentFormGroup = this.formBuilder.group({
            ['assignedAccessGroupFormGroup']: this.assignedAccessGroupFormGroup,
            ['assignedStudyFormGroup']: this.assignedStudyFormGroup,
            ['assignedSiteFormGroup']: this.assignedSiteFormGroup,
        });
        this.assignedAccessGroupFormGroup.controls[this.userAccessGroupFieldName].valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((assignedGroupId) => {
                    this._checkUserGroup(assignedGroupId);
                })
            )
            .subscribe();
        this.assignedAccessGroupFormGroup.updateValueAndValidity();
        this.parentFormGroup.addControl('personalDataFormGroup', this.personalDataFormGroup);
    }

    protected _userTransformCreate(inputUserData): CreateUserDTO {
        return new CreateUserDTO(<ICreateUserDTO>{
            firstName: inputUserData?.firstName,
            lastName: inputUserData?.lastName,
            emailAddress: inputUserData?.emailAddress,
            hasLocalAuthentication: inputUserData?.hasLocalAuthentication,
            userAccessGroupId: inputUserData?.accessGroups[0]?.id,
            siteIds: inputUserData?.sites?.map((site) => site?.id),
            studyIds: inputUserData?.studies?.map((study) => study?.id),
        });
    }
    protected _userTransformUpdate(inputUserData): UpdateUserDTO {
        return new UpdateUserDTO(<IUpdateUserDTO>{
            ...this._userTransformCreate(inputUserData),
            id: inputUserData?.id,
        });
    }

    protected _readUserFormValues(): void {
        this.resultUser.emailAddress = this.personalDataFormGroup.controls[this.emailAddressFieldName].value;
        this.resultUser.firstName = this.personalDataFormGroup.controls[this.firstNameFieldName].value;
        this.resultUser.lastName = this.personalDataFormGroup.controls[this.lastNameFieldName].value;
        this.resultUser.phoneNumber = this.personalDataFormGroup.controls[this.phoneNumberFieldName].value;
    }
}
