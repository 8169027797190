import { Injectable } from '@angular/core';
import { AccessGroupDTO } from '@models/accessGroupDTO';
import { AccessGroupClient } from '@repositories/group/access-group-client.repository';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccessGroupStateService {
    private readonly accessGroups: ReplaySubject<AccessGroupDTO[]> = new ReplaySubject<AccessGroupDTO[]>();
    private readonly accessGroups$: Observable<AccessGroupDTO[]> = this.accessGroups.asObservable();

    constructor(private accessGroupClient: AccessGroupClient) {}

    init(): void {
        this.accessGroupClient
            .get()
            .pipe(
                tap((result) => {
                    this.accessGroups.next(result);
                })
            )
            .subscribe();
    }

    getAccessGroups(): Observable<AccessGroupDTO[]> {
        return this.accessGroups$;
    }
}
