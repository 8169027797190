import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteService } from '@services/site/site.service';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FileDownloadService {
    constructor(
        private httpClient: HttpClient,
        private siteService: SiteService
    ) {}

    downloadFile<Type>(uri: string, responseType: 'blob' | 'json'): Observable<{ response: HttpEvent<Type> }> {
        if (this.isRelativeUrl(uri)) {
            uri = new URL(uri, this.siteService.getSiteBaseUrl()).toString();
        }
        const request = new HttpRequest('GET', uri, {
            responseType: responseType,
        });

        return this.httpClient.request<Type>(request).pipe(
            map((event) => ({
                response: event,
            }))
        );
    }

    private isRelativeUrl(url: string): boolean {
        // Regular expression pattern to match relative URLs
        const relativeUrlPattern = /^(?!www\.|http[s]?(:\/\/)).*$/;

        return relativeUrlPattern.test(url);
    }
}
