import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateGetThereUrlViewModel } from '@models/getthere/createGetThereUrlViewModel';
import { GetThereInputParametersDTO } from '@services/getthere/getthere.service';
import { SiteService } from '@services/site/site.service';
import { Observable } from 'rxjs';

@Injectable()
export class GetThereRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getUrl(inputParameters: GetThereInputParametersDTO): Observable<CreateGetThereUrlViewModel> {
        return this.client.post<CreateGetThereUrlViewModel>(
            `${this.siteService.getSiteBaseUrl()}/api/getthere/createUrl`,
            inputParameters
        );
    }
}
