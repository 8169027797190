<div *ngIf="isEditing">
    <div class="o-grid__row">
        <span>In order to process payment, patient documentation should be uploaded here if requested by PCS.</span>
    </div>
    <div class="o-grid__row"></div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-logo-upload
                id="kyc-upload-document"
                [acceptableTypes]="acceptableFileTypes"
                [inputObj]="patientIdent.KYCDocument"
                (emitStuff)="updateDocumentation($event, { id: 'KYCDocument' })"
                (fileNameEmitter)="updateDocumentation($event, { id: 'KYCDocumentName' })"
                uploadButtonTitle="Upload Document"
            ></medpace-logo-upload>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="isAdmin">
        <div class="o-grid__item">
            <mds-checkbox
                label="Ready for Payment"
                [formCtrl]="kycCardFormGroup.controls.readyForPaymentControl"
                [disabled]="siteSettings?.kycDocumentation && !hasFile"
                id="readyForPayment"
                (changeEvent)="emitChange($event)"
            >
            </mds-checkbox>
            <p class="p-mb" *ngIf="patient?.readyForPayment">
                Approved by {{ patient.paymentApprover }} on
                {{ patient.paymentApprovalDate?.toDate() | dateFormat | uppercase }}
            </p>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="isAdmin && patient?.readyForPayment">
        <div class="o-grid__item">
            <mds-text-field
                [appearance]="'outline'"
                id="KYCVendorId"
                label="Vendor ID"
                [formCtrl]="kycCardFormGroup.controls.kycVendorIdControl"
                placeholder="Enter ID"
                (input)="emitTextChange($event.target.value, { id: 'KYCVendorId' })"
            ></mds-text-field>
        </div>
    </div>
</div>

<div *ngIf="!isEditing">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <span>In order to process payment, patient documentation should be uploaded here if requested by PCS.</span>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item" *ngIf="hasFile">
            <div class="flex start">
                <mds-button
                    [id]="'kyc-document-download-btn'"
                    variant="outline"
                    label="KYC Document download"
                    (clickEvent)="onDocumentDownloadClick()"
                >
                </mds-button>
                <mds-button
                    [id]="'delete-btn'"
                    *ngIf="isAdmin"
                    variant="outline"
                    color="warn"
                    label="X"
                    width="50px"
                    (clickEvent)="deleteFile()"
                >
                </mds-button>
            </div>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="isAdmin">
        <div class="o-grid__item">
            <mat-checkbox
                disabled
                [(ngModel)]="patient.readyForPayment"
                [checked]="patient.readyForPayment"
                (change)="emitChange($event)"
            >
                Ready for Payment
            </mat-checkbox>
            <p class="p-mb" *ngIf="patient?.readyForPayment">
                Approved by {{ patient.paymentApprover }} on
                {{ patient.paymentApprovalDate?.toDate() | dateFormat | uppercase }}
            </p>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="!isAdmin">
        <div class="o-grid__item">
            <mat-checkbox
                disabled
                id="readyForPayment"
                [(ngModel)]="patient.readyForPayment"
                [checked]="patient.readyForPayment"
                (change)="emitChange($event)"
            >
                Ready for Payment
            </mat-checkbox>
            <p class="p-mb" *ngIf="patient?.readyForPayment">
                Approved by {{ patient.paymentApprover }} on
                {{ patient.paymentApprovalDate?.toDate() | dateFormat | uppercase }}
            </p>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="isAdmin && patient?.readyForPayment">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h4>Vendor ID</h4>
                <span>{{ patientIdent.KYCVendorId }}</span>
            </div>
        </div>
    </div>
</div>
