import { Directive, ElementRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs';
import { GroupService } from '../../services/group/group.service';

@Directive({
    selector: '[superAdmin]',
})
export class SuperAdminDirective implements OnInit {
    constructor(
        private readonly groupServices: GroupService,
        private viewContainer: ViewContainerRef,
        private el: ElementRef,
        private templateRef: TemplateRef<any>
    ) {}

    ngOnInit(): void {
        this.groupServices
            .getGroupsFromStorage()
            .pipe(take(1))
            .subscribe((result) => {
                if (this.groupServices.isUserSuperAdmin(result)) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
