<form
    [formGroup]="requestFormGroup"
    appScrollToInvalidControlOnSubmit
    class="o-grid"
    *ngIf="{
        user: user$ | async,
        expenseDetails: expenseDetails$ | async,
        requestType: requestType$ | async,
        travelCardVM: travelCardVM$ | async,
        isUpdate: isUpdate$ | async,
    } as vm"
>
    <div class="o-grid__row">
        <div class="o-grid__item half">
            <div class="sidebar">
                <medpace-vertical-stepper [steps]="steps"></medpace-vertical-stepper>
                <div class="request-history-container" *ngIf="request?.id && request?.type && request?.paymentType">
                    <medpace-request-history />
                </div>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="o-grid">
                <ng-container *ngTemplateOutlet="requestbuttons"></ng-container>

                <div class="requiredText">
                    <span class="required-asterisk">*</span>
                    indicates a required field
                </div>
                <div *ngIf="request" class="o-grid__row" id="requestType" #requestType>
                    <div
                        *ngIf="
                            isAdmin ||
                            request.status === GenericRequestStatus.Draft ||
                            request.status === GenericRequestStatus.Completed
                        "
                        class="o-grid__item"
                    >
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave(true)"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [editMode]="!isUpdate"
                            [showEditSaveButton]="false"
                            [isUserAdmin]="isAdmin"
                            [parentFormGroup]="requestFormGroup"
                            [isUpdate]="isUpdate"
                            title="Request Information"
                            type="request-type"
                        ></medpace-input-card>
                    </div>
                    <div
                        *ngIf="
                            !isAdmin &&
                            request.status !== GenericRequestStatus.Draft &&
                            request.status !== GenericRequestStatus.Completed
                        "
                        class="o-grid__item"
                    >
                        <medpace-input-card
                            (incrementalUpdate)="submitIncrementalSave(true)"
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [editMode]="!isUpdate"
                            [showEditSaveButton]="!isRequestStatus(3) && isUpdateAndAdmin(vm.user)"
                            [additionalContent]="request.status"
                            [inputObj]="request.type"
                            [isUserAdmin]="isAdmin"
                            [paymentEnabled]="paymentAvailable"
                            [travelEnabled]="travelAvailable"
                            [patient]="patient"
                            [visitDetails]="request.visitDetails"
                            [request]="request"
                            [parentFormGroup]="requestFormGroup"
                            [isUpdate]="isUpdate"
                            title="Request Information"
                            type="request-type"
                        ></medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row" id="studyInformation" #studyInformation>
                    <div class="o-grid__item">
                        <medpace-input-card
                            (inputChangeEvent)="fieldChangeEvent($event)"
                            [editMode]="false"
                            [showEditSaveButton]="false"
                            title="Study Details"
                            type="study-detail"
                        >
                        </medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row" id="patientInformation" #patientInformation>
                    <div class="o-grid__item">
                        <medpace-input-card
                            (actionClickEvent)="patientNavigation($event)"
                            [editMode]="false"
                            [showEditSaveButton]="false"
                            [isPatientProfile]="false"
                            [isUserAdmin]="isAdmin"
                            title="Patient Information"
                            type="personal-info"
                        ></medpace-input-card>
                    </div>
                </div>
                <div class="o-grid__row" id="visitDetails" *ngIf="vm.requestType">
                    <div class="o-grid__item">
                        <medpace-create-request-visit-details
                            *ngIf="!isUpdate"
                            [viewModel]="createRequestVisitDetails_ViewModel$ | async"
                        ></medpace-create-request-visit-details>
                        <medpace-edit-request-visit-details
                            *ngIf="isUpdate"
                            [viewModel]="editRequestVisitDetails_ViewModel$ | async"
                            (saveEvent)="onEditVisitDetailsSave($event)"
                            [showSwitchModeButton]="
                                showSwitchModeButtonInVisitDetailsCard(vm.user.isAdmin, vm.user.isSuperAdmin)
                            "
                        ></medpace-edit-request-visit-details>
                    </div>
                </div>
                <div>
                    <div *ngIf="showTravelCards" class="o-grid__row" id="travel-information-step">
                        <div class="o-grid__item">
                            <travel-card
                                *ngIf="!isUpdate"
                                [isLoading]="!vm.travelCardVM"
                                [formGroup]="requestFormGroup.controls.travelCard"
                                [countryOptions]="vm.travelCardVM?.countryOptions"
                                [currencyOptions]="vm.travelCardVM?.currencyOptions"
                                [timeOptions]="vm.travelCardVM?.timeOptions"
                                [historyData]="vm.travelCardVM?.historyData"
                                [isUpdate]="vm.travelCardVM?.isUpdate"
                                [mode]="'edit'"
                                [showBookButton]="false"
                                [showSwitchModeButton]="false"
                                [request]="request"
                            ></travel-card>
                            <travel-card
                                *ngIf="isUpdate"
                                [isLoading]="!vm.travelCardVM"
                                [formGroup]="requestFormGroup.controls.travelCard"
                                [countryOptions]="vm.travelCardVM?.countryOptions"
                                [currencyOptions]="vm.travelCardVM?.currencyOptions"
                                [timeOptions]="vm.travelCardVM?.timeOptions"
                                [historyData]="vm.travelCardVM?.historyData"
                                [isUpdate]="vm.travelCardVM?.isUpdate"
                                [mode]="'view'"
                                [showBookButton]="
                                    vm.travelCardVM?.patientBookable &&
                                    request?.status !== GenericRequestStatus.Completed &&
                                    (vm.user.isAdmin || vm.user.isSuperAdmin)
                                "
                                [showSwitchModeButton]="
                                    !isRequestStatus(3) && (isUpdateAndAdmin(vm.user) || isRequestStatus(1, 2))
                                "
                                [request]="request"
                                (saveEvent)="travelCardSave($event)"
                                (bookViaGetThereForCaregiver)="bookViaGetThereForCaregiver($event)"
                                (bookViaGetThereForPatient)="bookViaGetThereForPatient()"
                            ></travel-card>
                            <ng-content select="travel-card"></ng-content>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row" *ngIf="vm.requestType === 'payment'" id="paymentInformation">
                    <div class="o-grid__item" *ngIf="!!expenseTypes">
                        <medpace-create-edit-request-expense-details
                            [expenseOptions]="expenseTypes"
                            (saveEvent)="onPaymentRequestExpenseDetailsSave($event)"
                            *ngIf="!isUpdate"
                            [showSwitchModeButton]="false"
                        ></medpace-create-edit-request-expense-details>
                        <medpace-create-edit-request-expense-details
                            [expenseOptions]="expenseTypes"
                            (saveEvent)="onPaymentRequestExpenseDetailsSave($event)"
                            *ngIf="isUpdate"
                            [showSwitchModeButton]="true"
                        ></medpace-create-edit-request-expense-details>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="requestbuttons"></ng-container>

                <div class="o-grid__row">
                    <div class="o-grid__item u-ta-right">
                        <span
                            *ngIf="isButtonSubmitActive"
                            [ngClass]="validForm && isButtonSubmitActive ? 'c-message' : 'c-message__show'"
                            >{{ validationMessage }}
                        </span>
                        <span *ngIf="!isButtonSubmitActive" class="error-text">
                            Submission has exceeded entry limit ({{ maxSummaryFileSizesInMB }}MB). Please condense
                            receipt files OR submit current request and upload remaining files in a new request.</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<ng-template #requestbuttons>
    <div class="o-grid__row">
        <ng-container *ngIf="request && request.status === GenericRequestStatus.PendingSiteVerification">
            <ng-container *ngTemplateOutlet="potentialDuplicate"></ng-container>
            <div class="o-grid__item">
                <div class="flex end">
                    <mds-button
                        *ngIf="isAdmin"
                        (click)="deleteRequest()"
                        [id]="'delete-request-btn'"
                        label="Delete Request"
                        variant="outline"
                        color="warn"
                    >
                    </mds-button>
                    <mds-button
                        (click)="viewHistory()"
                        [id]="'view-history-btn'"
                        label="View History"
                        variant="outline"
                    >
                    </mds-button>
                    <mds-button
                        (click)="submitRequest()"
                        variant="primary"
                        label="Submit"
                        [id]="'submitRequest'"
                        [disabled]="!isButtonSubmitActive"
                    ></mds-button>
                </div>
            </div>
        </ng-container>

        <div *ngIf="request && request.status < GenericRequestStatus.Submitted" class="o-grid__item">
            <div class="flex end">
                <mds-button
                    type="submit"
                    (click)="saveDraftClick()"
                    variant="outline"
                    [id]="'saveRequestDraft'"
                    label="Save Draft"
                ></mds-button>
                <mds-button
                    type="submit"
                    (click)="submitRequest()"
                    variant="primary"
                    label="Submit"
                    [id]="'submitRequest'"
                    [disabled]="!isButtonSubmitActive"
                ></mds-button>
            </div>
        </div>
        <ng-container
            *ngIf="
                request &&
                (request.status === GenericRequestStatus.Submitted ||
                    (request.status > GenericRequestStatus.Completed &&
                        request.status !== GenericRequestStatus.PendingSiteVerification))
            "
        >
            <ng-container *ngTemplateOutlet="potentialDuplicate"></ng-container>
            <div class="o-grid__item">
                <div class="flex end">
                    <mds-button
                        *ngIf="isAdmin"
                        (click)="deleteRequest()"
                        [id]="'delete-request-btn'"
                        label="Delete Request"
                        variant="outline"
                        color="warn"
                    >
                    </mds-button>
                    <mds-button
                        (click)="viewHistory()"
                        [id]="'view-history-btn'"
                        label="View History"
                        variant="outline"
                    >
                    </mds-button>
                    <mds-button
                        *ngIf="isAdmin && patient?.statusId !== 2"
                        (click)="changeStatus()"
                        [id]="'ChangeStatusRequestBtn'"
                        label="Change Status"
                        [variant]="isAdmin ? 'primary' : 'outline'"
                    >
                    </mds-button>
                    <mds-button
                        *ngIf="patient?.statusId !== 2 && !isAdmin && request.status === GenericRequestStatus.Submitted"
                        (click)="saveDraftClick()"
                        variant="primary"
                        [id]="'updateBtn'"
                        label="Update"
                    >
                    </mds-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="request && request.status === GenericRequestStatus.Completed">
            <ng-container *ngTemplateOutlet="potentialDuplicate"></ng-container>
            <div class="o-grid__item">
                <div class="flex end">
                    <mds-button
                        (click)="viewHistory()"
                        variant="primary"
                        [id]="'view-history-btn'"
                        label="View History"
                    >
                    </mds-button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #potentialDuplicate>
    <div class="o-grid__item.half u-ta-left">
        <span class="processingText">{{ actualStatus | uppercase }}</span>
        <mat-icon *ngIf="request && request.flaggedDuplicate" class="duplicateIcon" title="Potential Duplicate"
            ><img alt="Potential Duplicate" class="duplicate-red-icon" src="../../../assets/img/duplicate-icn.png" />
        </mat-icon>
    </div>
</ng-template>
