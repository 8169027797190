<ng-container *ngIf="{ countries: countries$ | async } as vm">
    <ng-container *ngIf="mode === 'view'">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Passport number</h5>
                        <span id="passportNum">{{ formGroup.value.number || 'Unspecified' }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Country</h5>
                        <span id="passportCountry">{{
                            formGroup.value.country ? formGroup.value.country : 'Unspecified'
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Passport issue date</h5>
                        <span id="passportIssue">{{
                            formGroup.value.issueDate
                                ? (formGroup.value.issueDate.toDate() | dateFormat | uppercase)
                                : 'Unspecified'
                        }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Passport expiration date</h5>
                        <span id="passportExpiration">{{
                            formGroup.value.expirationDate
                                ? (formGroup.value.expirationDate.toDate() | dateFormat | uppercase)
                                : 'Unspecified'
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === 'edit'">
        <div class="o-grid">
            <div class="o-grid__row" *ngFor="let error of formGroup.errors | keyvalue">
                <div class="o-grid__item">
                    <medpace-alert [additionalClass]="'danger'" [content]="error.value"></medpace-alert>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <mds-text-field
                        id="passportNum"
                        [formCtrl]="formGroup.controls.number"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        label="Passport Number"
                        width="100%"
                    ></mds-text-field>
                </div>

                <div class="o-grid__item">
                    <mds-autocomplete
                        [closeOnPageScroll]="false"
                        [disableRipple]="false"
                        [formCtrl]="formGroup.controls.country"
                        [options]="vm.countries"
                        appearance="outline"
                        floatLabel="always"
                        placeholder="Enter country"
                        label="Country"
                        id="passportCountry"
                        [validateInput]="true"
                    >
                    </mds-autocomplete>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-datepicker
                        [formCtrl]="formGroup.controls.issueDate"
                        id="passportIssue"
                        appearance="outline"
                        label="Issue Date"
                        [required]="true"
                    ></medpace-datepicker>
                </div>

                <div class="o-grid__item">
                    <medpace-datepicker
                        [formCtrl]="formGroup.controls.expirationDate"
                        id="passportExpiration"
                        appearance="outline"
                        label="Expiration Date"
                        [required]="true"
                    ></medpace-datepicker>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
