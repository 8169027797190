import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { CountryViewModel } from '@models/country';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { RegionStateService } from '@services/state-management/region-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { maxLengthValidator, zipCodeFormatValidator } from '@utility/utility.validators';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Address, Site } from 'src/app/models/site';

@Component({
    selector: 'medpace-site-address',
    templateUrl: './site-address.component.html',
    styleUrls: ['../../../organisms/input-card/input-card.component.scss'],
})
export class MedpaceSiteAddressComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    content: Address;
    site$: Observable<Site>;
    private componentDestroyed$: Subject<boolean> = new Subject();

    @Input() isEditing: boolean;

    @Input() parentFormGroup: FormGroup;

    countries$: Observable<string[]> = this.countryStateService.getCountriesStringArray();

    emitCountryValue$: Observable<string>;

    @Output() inputChangeEvent = new EventEmitter<InputChange>();

    regions: any[] = [];
    filteredCountries: Observable<MdsOption[]>;
    loaderSpinner: boolean = true;
    regionStateType$ = of('Province / Region / State');

    constructor(
        private stateService: SiteStateService,
        private countryStateService: CountryStateService,
        private regionStateService: RegionStateService,
        private googleMapsService: GoogleMapsService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.initializeFormGroup();
        this.getRegions();

        this.site$ = this.stateService.getSite();
        this.site$.pipe(takeUntil(this.componentDestroyed$)).subscribe((result) => {
            if (result?.address) {
                this.content = result.address;
                this.setValues();
                this.loaderSpinner = false;
            }
        });

        this.parentFormGroup
            .get('countryControl')
            ?.valueChanges.pipe(
                filter(Boolean),
                takeUntil(this.componentDestroyed$),
                tap((country) => {
                    this.regionStateType$ = this.countryStateService.getRegionStateType(country);
                }),
                switchMap((countryString) => this.countryStateService.getCountryByName(countryString)),
                filter(Boolean),
                tap((countryModel: CountryViewModel) => {
                    //country is valid, emit value
                    this.emitChange(countryModel.viewValue, { id: 'country' });
                })
            )
            .subscribe();

        this.googleMapsService
            .observePlaceListener()
            .pipe(
                takeUntil(this.componentDestroyed$),
                switchMap((place) => combineLatest([of(place), this.googleMapsService.getFieldName()])),
                tap(([place, fieldName]) => {
                    if (place && fieldName === 'line1') this.setAddressControls(place);
                })
            )
            .subscribe();
    }

    setEventListenerForAddressControl() {
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            const mdsComp = document.querySelector('#line1');
            const inputField = mdsComp?.querySelector('input') as HTMLInputElement | null;

            if (inputField) this.googleMapsService.setPlaceListener(inputField);
        });
    }

    ngAfterViewInit(): void {
        this.setEventListenerForAddressControl();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isEditing.currentValue && !changes.isEditing.firstChange) {
            this.setEventListenerForAddressControl();
        }
    }

    private setAddressControls(placeResult: google.maps.places.PlaceResult) {
        const addressComponents = placeResult.address_components;
        const line1 = `${this.googleMapsService.getAddressComponent(
            'street_number',
            addressComponents
        )} ${this.googleMapsService.getAddressComponent('route', addressComponents)}`;
        const city = this.googleMapsService.getCityComponent(placeResult);
        const state = this.googleMapsService.getAddressComponent('administrative_area_level_1', addressComponents);
        const postalCode = this.googleMapsService.getAddressComponent('postal_code', addressComponents);
        const country = this.googleMapsService.getAddressComponent('country', addressComponents);

        this.parentFormGroup.controls.line1Control.setValue(line1);
        this.parentFormGroup.controls.cityControl.setValue(city);
        this.parentFormGroup.controls.stateOrProvinceControl.setValue(state);
        this.parentFormGroup.controls.postalCodeControl.setValue(postalCode);
        this.parentFormGroup.controls.countryControl.setValue(country);

        this.inputChangeEvent.emit({ target: 'line1', value: line1 });
        this.inputChangeEvent.emit({ target: 'city', value: city });
        this.inputChangeEvent.emit({ target: 'stateOrProvince', value: state });
        this.inputChangeEvent.emit({ target: 'postalCode', value: postalCode });
        this.inputChangeEvent.emit({ target: 'country', value: country });
    }

    ngOnDestroy() {
        this.googleMapsService.clearPlaceObservable();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    getRegions() {
        this.regionStateService
            .getRegions()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.regions = result.map((x) => ({ value: x.name, viewValue: x.name }));
            });
    }

    initializeFormGroup() {
        this.parentFormGroup.addControl(
            'line1Control',
            new FormControl('', [Validators.required, maxLengthValidator(150)])
        );
        this.parentFormGroup.addControl('line2Control', new FormControl('', [maxLengthValidator(100)]));
        this.parentFormGroup.addControl(
            'cityControl',
            new FormControl('', [Validators.required, maxLengthValidator(100)])
        );
        this.parentFormGroup.addControl('stateOrProvinceControl', new FormControl('', [maxLengthValidator(25)]));
        this.parentFormGroup.addControl('postalCodeControl', new FormControl('', [zipCodeFormatValidator]));
        this.parentFormGroup.addControl(
            'regionControl',
            new FormControl('', [Validators.required, maxLengthValidator(75)])
        );
        this.parentFormGroup.addControl(
            'countryControl',
            new FormControl('', [Validators.required, maxLengthValidator(65)])
        );
    }

    setValues() {
        if (this.content) {
            this.parentFormGroup.controls.line1Control.setValue(this.content.line1);
            this.parentFormGroup.controls.line2Control.setValue(this.content.line2);
            this.parentFormGroup.controls.cityControl.setValue(this.content.city);
            this.parentFormGroup.controls.stateOrProvinceControl.setValue(this.content.stateOrProvince);
            this.parentFormGroup.controls.postalCodeControl.setValue(this.content.postalCode);
            this.parentFormGroup.controls.regionControl.setValue(this.content.region);
            this.parentFormGroup.controls.countryControl.setValue(this.content.country);
        }
    }

    emitChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
        this.googleMapsService.setActiveField(target.id);
    };

    selected(event: any): void {
        this.inputChangeEvent.emit({ target: 'country', value: event.option.value });
    }
}
