<mds-dialog dialogTitle="File download" id="fileDownloadDialog">
    <mds-dialog-content>
        <div *ngIf="!fileReady && !fileError">Preparing the file to download...</div>
        <div *ngIf="fileReady && !fileError">File is ready to download.</div>
        <div *ngIf="fileError">
            Sorry, we were unable to prepare your file for download at this time.<br />Please try again later.
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            [id]="'download-btn'"
            data-cy="download-btn"
            label="Download"
            (clickEvent)="onDownloadButtonClick()"
            variant="primary"
            [disabled]="!fileReady || fileError"
        >
        </mds-button>
    </mds-dialog-actions>
</mds-dialog>
