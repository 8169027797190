import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { CountryViewModel } from '@models/country';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { RegionStateService } from '@services/state-management/region-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { maxLengthValidator, zipCodeFormatValidator } from '@utility/utility.validators';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Address } from 'src/app/models/site';

@Component({
    selector: 'medpace-site-shipping-address',
    templateUrl: './site-shipping-address.component.html',
    styleUrls: ['../../../organisms/input-card/input-card.component.scss'],
})
export class MedpaceSiteShippingAddressComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    content: Address;
    addressFormGroup = new FormGroup({
        sline1Control: new FormControl<string>('', [Validators.required, maxLengthValidator(150)]),
        sline2Control: new FormControl<string>('', [maxLengthValidator(100)]),
        scityControl: new FormControl<string>('', [Validators.required, maxLengthValidator(100)]),
        sstateOrProvinceControl: new FormControl<string>('', [maxLengthValidator(25)]),
        spostalCodeControl: new FormControl<string>('', [zipCodeFormatValidator]),
        sregionControl: new FormControl<string>('', [Validators.required, maxLengthValidator(75)]),
        scountryControl: new FormControl<string>('', [Validators.required, maxLengthValidator(65)]),
    });
    sameAsPrimaryAddress: boolean = false;
    private componentDestroyed$: Subject<boolean> = new Subject();
    addressSameAsPrimaryControl = new FormControl<boolean>(this.sameAsPrimaryAddress);

    adressRadioButtons: MdsRadioButton[] = [
        {
            label: 'Yes',
            value: 'true',
            name: `yes`,
            id: `yes`,
        },
        {
            label: 'No',
            value: 'false',
            name: `no`,
            id: `no`,
        },
    ];

    @Input() isEditing: boolean;
    @Input() parentFormGroup: FormGroup;

    countries$: Observable<string[]> = this.countryStateService.getCountriesStringArray();
    emitCountryValue$: Observable<string>;

    @Output() inputChangeEvent = new EventEmitter<InputChange>();

    regions: any[] = [];
    filteredCountries: Observable<MdsOption[]>;
    loaderSpinner: boolean = true;
    regionStateType$ = of('Province / Region / State');

    regions$ = this.regionStateService.getRegions().pipe(
        takeUntil(this.componentDestroyed$),
        map((regions) => {
            return regions.map((x) => ({ value: x.name, viewValue: x.name }));
        })
    );

    site$ = this.stateService.getSite().pipe(
        takeUntil(this.componentDestroyed$),
        skipWhile((site) => site === null),
        tap((result) => {
            this.sameAsPrimaryAddress = result.isShippingAddressSameAsPrimary;
            this.parentFormGroup.controls.addressSameAsPrimary.setValue(this.sameAsPrimaryAddress ? 'true' : 'false');

            this.sameAsPrimaryAddress ? this.addressFormGroup.disable() : this.addressFormGroup.enable();
            if (!result.isShippingAddressSameAsPrimary && result?.shippingAddress) {
                this.content = result.shippingAddress;
                this.setValues();
            }

            this.loaderSpinner = false;
        })
    );

    constructor(
        private stateService: SiteStateService,
        private countryStateService: CountryStateService,
        private regionStateService: RegionStateService,
        private googleMapsService: GoogleMapsService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.initializeFormGroup();

        this.parentFormGroup
            .get('scountryControl')
            ?.valueChanges.pipe(
                filter(Boolean),
                takeUntil(this.componentDestroyed$),
                tap((country) => {
                    this.regionStateType$ = this.countryStateService.getRegionStateType(country);
                }),
                switchMap((countryString) => this.countryStateService.getCountryByName(countryString)),
                filter(Boolean),
                tap((countryModel: CountryViewModel) => {
                    //country is valid, emit value
                    this.emitChange(countryModel.viewValue, { id: 'shipping_country' });
                })
            )
            .subscribe();

        this.googleMapsService
            .observePlaceListener()
            .pipe(
                takeUntil(this.componentDestroyed$),
                switchMap((place) => combineLatest([of(place), this.googleMapsService.getFieldName()])),
                tap(([place, fieldName]) => {
                    if (place && fieldName === 'shipping_line1') this.setAddressControls(place);
                })
            )
            .subscribe();
    }

    setEventListenerForAddressControl() {
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            const mdsComp = document.querySelector('#shipping_line1');
            const inputField = mdsComp?.querySelector('input') as HTMLInputElement | null;

            if (inputField) this.googleMapsService.setPlaceListener(inputField);
        });
    }

    ngAfterViewInit(): void {
        this.setEventListenerForAddressControl();
    }

    selectValue(event: MatRadioChange) {
        this.sameAsPrimaryAddress = event.value === 'true' ? true : false;
        !this.sameAsPrimaryAddress ? this.addressFormGroup.enable() : this.addressFormGroup.disable();
        this.inputChangeEvent.emit({ target: 'isShippingAddressSameAsPrimary', value: event.value });
        this.setEventListenerForAddressControl();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isEditing.currentValue && !changes.isEditing.firstChange) {
            this.setEventListenerForAddressControl();
        }
    }

    private setAddressControls(placeResult: google.maps.places.PlaceResult) {
        const addressComponents = placeResult.address_components;
        const line1 = `${this.googleMapsService.getAddressComponent(
            'street_number',
            addressComponents
        )} ${this.googleMapsService.getAddressComponent('route', addressComponents)}`;
        const city = this.googleMapsService.getCityComponent(placeResult);
        const state = this.googleMapsService.getAddressComponent('administrative_area_level_1', addressComponents);
        const postalCode = this.googleMapsService.getAddressComponent('postal_code', addressComponents);
        const country = this.googleMapsService.getAddressComponent('country', addressComponents);

        this.addressFormGroup.controls.sline1Control.setValue(line1);
        this.addressFormGroup.controls.scityControl.setValue(city);
        this.addressFormGroup.controls.sstateOrProvinceControl.setValue(state);
        this.addressFormGroup.controls.spostalCodeControl.setValue(postalCode);
        this.addressFormGroup.controls.scountryControl.setValue(country);

        this.inputChangeEvent.emit({ target: 'shipping_line1', value: line1 });
        this.inputChangeEvent.emit({ target: 'shipping_city', value: city });
        this.inputChangeEvent.emit({ target: 'shipping_stateOrProvince', value: state });
        this.inputChangeEvent.emit({ target: 'shipping_postalCode', value: postalCode });
        this.inputChangeEvent.emit({ target: 'shipping_country', value: country });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    initializeFormGroup() {
        this.parentFormGroup.addControl('addressFormGroup', this.addressFormGroup);
        this.parentFormGroup.addControl('addressSameAsPrimary', this.addressSameAsPrimaryControl);
    }

    setValues() {
        if (this.content) {
            this.addressFormGroup.controls.sline1Control.setValue(this.content.line1);
            this.addressFormGroup.controls.sline2Control.setValue(this.content.line2);
            this.addressFormGroup.controls.scityControl.setValue(this.content.city);
            this.addressFormGroup.controls.sstateOrProvinceControl.setValue(this.content.stateOrProvince);
            this.addressFormGroup.controls.spostalCodeControl.setValue(this.content.postalCode);
            this.addressFormGroup.controls.sregionControl.setValue(this.content.region);
            this.addressFormGroup.controls.scountryControl.setValue(this.content.country);
        }
    }

    emitChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
        this.googleMapsService.setActiveField(target.id);
    };

    selected(event: any): void {
        this.inputChangeEvent.emit({ target: 'country', value: event.option.value });
    }
}
