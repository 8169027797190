import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { debounceTime, fromEvent, take } from 'rxjs';

@Directive({
    selector: '[appScrollToInvalidControlOnSubmit]',
})
export class ScrollToInvalidControlOnSubmitDirective {
    constructor(
        private el: ElementRef,
        private formGroupDir: FormGroupDirective
    ) {}

    @HostListener('ngSubmit') onSubmit() {
        if (this.formGroupDir.control.invalid) {
            this.scrollToFirstInvalidControl();
        }
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('body :not(form).ng-invalid');
        const element = firstInvalidControl ?? this.el.nativeElement;
        window.scroll({
            top: this.getTopOffset(element),
            left: 0,
            behavior: 'smooth',
        });

        fromEvent(window, 'scroll')
            .pipe(debounceTime(100), take(1))
            .subscribe(() => element.focus());
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 100;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
}
