<ng-container *ngIf="mode === 'view'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Airline Preference #1</h5>
                    <span id="airlinePreference1">{{
                        formGroup.controls.airlinePreference1.value || 'Unspecified'
                    }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Frequent Flyer No. #1</h5>
                    <span>{{ formGroup.controls.frequentFlyerNumber1.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Airline Preference #2</h5>
                    <span>{{ formGroup.controls.airlinePreference2.value || 'Unspecified' }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Frequent Flyer No. #2</h5>
                    <span>{{ formGroup.controls.frequentFlyerNumber2.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Seat preference</h5>
                    <span>{{ formGroup.controls.seatPreference.value || 'Unspecified' }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Known traveler number</h5>
                    <span>{{ formGroup.controls.knownTravelerNumber.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Special needs</h5>
                    <span>{{ formGroup.controls.specialNeeds.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="mode === 'edit'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="airlinePreference1"
                    [formCtrl]="formGroup.controls.airlinePreference1"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Airline Preference #1"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="frequentFlyer1"
                    [formCtrl]="formGroup.controls.frequentFlyerNumber1"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Frequent Flyer #1"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="airlinePreference2"
                    [formCtrl]="formGroup.controls.airlinePreference2"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Airline Preference #2"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="frequentFlyer2"
                    [formCtrl]="formGroup.controls.frequentFlyerNumber2"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Frequent Flyer #2"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="seatPreference"
                    [formCtrl]="formGroup.controls.seatPreference"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Seat Preference"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="airlineSpecialNeeds"
                    [formCtrl]="formGroup.controls.specialNeeds"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Special Needs"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="knownTravelerNumber"
                    [formCtrl]="formGroup.controls.knownTravelerNumber"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Known Traveler Number"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
    </div>
</ng-container>
