import { EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

export enum UploadStatus {
    Empty,
    Available,
    AvailableWithUrl,
    InvalidAttachmentSize,
    UnacceptableTypesInput,
}

export interface IMedpaceLogoUploadComponent {
    files: any[];
    fileName: string;
    maxSizeOfFileInMB: number;
    isFilesSizeTrepasses: boolean;
    maxSummaryFileSizesInMB: number;
    url: SafeUrl;
    file: File;
    currentUploadStatus: UploadStatus;
    acceptableTypesInput: string;
    acceptableTypesDisplay: string;

    //Outputs
    emitStuff: EventEmitter<File>;
    fileNameEmitter: EventEmitter<string>;

    //Inputs
    inputObj: any;
    idVal: string;
    acceptableTypes: string[];
    uploadButtonTitle: string;
    isSpreadsheetUpload: boolean;
    isImage: boolean;
    forParsing: boolean;

    //Functions
    setFileSizeTrepassMessage();

    setFileSizeNotTrepassMessage();

    getFileSize(): number;

    processFiles(event: FileList);

    onFileSelected(event: FileList);

    uploadButtonClicked();
}
