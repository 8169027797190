import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Study } from '../../models/study';
import { CRCStudyRepository } from '../../repositories/crc/crc-studies.repository';

@Injectable()
export class CRCStudyServices {
    constructor(private crcStudies: CRCStudyRepository) {}
    getAllStudySummaries: () => Observable<Study[]> = () => this.crcStudies.getAllStudySummaries();
}
