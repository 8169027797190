import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CaregiverTravelRequest } from '../../models/caregiverTravelRequest';
import { CaregiverRepository } from '../../repositories/caregiver/caregiver.repository';

@Injectable({
    providedIn: 'root',
})
export class CaregiverService {
    constructor(private repo: CaregiverRepository) {}

    public getTravelRequests(id: number): Observable<CaregiverTravelRequest[]> {
        return this.repo.getTravelRequests(id);
    }
}
