import { Component, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { catchError, finalize, take, tap } from 'rxjs';

@Component({
    selector: 'access-request-actions-card',
    templateUrl: './access-request-actions-card.component.html',
    styleUrls: ['./access-request-actions-card.component.scss'],
})
export class AccessRequestActionsCardComponent implements OnInit {
    accessFormGroup: FormGroup;
    key: string;
    isLoading: boolean = true;
    isExistingUser: boolean;
    errorMessage: string = null;

    private accessRequestService = inject(AccessRequestService);
    private activeRoute = inject(ActivatedRoute);
    private router = inject(Router);

    ngOnInit(): void {
        this.activeRoute.queryParams.subscribe((params) => {
            this.key = params['guid'];
        });

        this.isUserExist();
    }

    signIn() {
        this.isLoading = true;
        this.router.navigate(['/access-request/logged', { guid: this.key ?? '' }]).then((navigated: boolean) => {
            if (navigated) {
                this.isLoading = false;
            }
        });
    }

    register() {
        this.router.navigate(['/access-request', { guid: this.key ?? '' }]);
    }

    isUserExist() {
        this.accessRequestService
            .ifUserExistByGuid(this.key ?? '')
            .pipe(
                take(1),
                tap((exist) => {
                    this.isExistingUser = exist;
                }),
                catchError((error) => {
                    this.errorMessage = error?.error?.ExceptionMessage;
                    throw error;
                }),
                finalize(() => (this.isLoading = false))
            )
            .subscribe();
    }
}
