import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
export interface Country extends CountryDto {
    id: number;
    isActive: boolean;
    name: string;
    numericCode: number;
    threeCharCode: string;
    twoCharCode: string;
    regionType: string;
}

export interface CountryDto {
    id: number;
    name: string;
}

export class CountryViewModel implements MdsOption {
    id: number;
    value: unknown;
    viewValue: string;
    countryCode: string;
    constructor(init?: Partial<CountryViewModel>) {
        if (init) Object.assign(this, init);
    }
}
