<!-- PAYMENT CARD EDIT -->
<ng-container *ngIf="setupPaymentCard$ | async">
    <ng-container *ngIf="isEditing && isPatientProfile">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-dropdown
                    floatLabel="always"
                    [closeOnPageScroll]="false"
                    [formGrp]="paymentCardFormGroup"
                    formCtrlName="paymentRecipientDropdownControl"
                    appearance="outline"
                    label="Payment Recipient (Stipend or Reimbursement)"
                    placeholder="Select Recipient"
                    [options]="paymentRecipientSelectOptions"
                    id="paymentRecipient"
                    (selectionChange)="emitPaymentRecipientChange($event.value, { id: 'paymentRecipientRole' })"
                ></mds-dropdown>
            </div>
        </div>
        <ng-container *ngIf="patient?.paymentRecipientRole">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        [formGrp]="paymentCardFormGroup"
                        formCtrlName="preferredMethodDopdownControl"
                        appearance="outline"
                        label="Preferred Method"
                        placeholder="Select method"
                        [options]="selectOptions"
                        id="preferredMethod"
                        (selectionChange)="emitSelectChange($event.value, { id: 'preferredPayment' })"
                    ></mds-dropdown>
                </div>
                <div class="o-grid__item">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        [formGrp]="paymentCardFormGroup"
                        formCtrlName="preferredCurrencyDopdownControl"
                        appearance="outline"
                        label="Preferred Currency"
                        placeholder="Select currency"
                        [options]="currencies"
                        id="currencies"
                        (selectionChange)="emitSelectChange($event.value, { id: 'preferredCurrency' })"
                    ></mds-dropdown>
                </div>
            </div>
            <!-- BANK DETAILS EDIT MODE -->
            <medpace-bank-details
                *ngIf="showBankDetails(paymentCardFormGroup, paymentOptions)"
                [isEditing]="isEditing"
                [patientInfo]="patient"
                [parentFormGroup]="paymentCardFormGroup"
            ></medpace-bank-details>
        </ng-container>
    </ng-container>

    <!-- PAYMENT CARD VIEW -->
    <ng-container *ngIf="!isEditing && isPatientProfile">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Payment Recipient (Stipend or Reimbursement)</h5>
                    <span>{{ selectedPaymentRecipient?.value }}</span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="patient?.paymentRecipientRole">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Preferred Method</h5>
                        <span>{{ patient?.preferredPayment }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Preferred Currency</h5>
                        <span>{{ patient?.preferredCurrency }}</span>
                    </div>
                </div>
            </div>
            <!-- BANK DETAILS COMPONENT VIEW MODE-->
            <medpace-bank-details
                *ngIf="showBankDetails(paymentCardFormGroup, paymentOptions)"
                [isEditing]="isEditing"
                [patientInfo]="patient"
                [parentFormGroup]="paymentCardFormGroup"
            ></medpace-bank-details>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="patient?.paymentRecipientRole">
        <h5 class="title">KYC Details (Know Your Customer)</h5>
        <medpace-kyc-card
            (inputChangeEvent)="passContentAlong($event)"
            [isAdmin]="isAdmin"
            [isEditing]="isEditing"
            [patientIdent]="patient?.patientIdentification"
            [patient]="patient"
            [parentFormGroup]="parentFormGroup"
        ></medpace-kyc-card>
    </ng-container>
</ng-container>
