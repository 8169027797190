<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h1>Requests</h1>
            </div>
            <div class="o-grid__item">
                <div class="export-btn flex end">
                    <mds-button
                        id="export-as-csv-btn"
                        (clickEvent)="saveAsCsv()"
                        variant="outline"
                        label="Export as .csv"
                    ></mds-button>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-table
                    title="All Requests"
                    (rowClickEvent)="doClickRow($event)"
                    (doFilterData)="filterData($event)"
                    [columns]="requestColumns"
                    [data]="requests"
                    isDeletedPropertyName="isPatientDeleted"
                    [fieldMap]="tableColumnMap"
                    [ifFiltering]="false"
                    [isSearching]="true"
                    [showFilters]="true"
                    [includePaginator]="true"
                ></medpace-table>
            </div>
        </div>
    </div>
</div>
