import {
    AfterViewInit,
    Component,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { EditedCard } from '@models/edited-card';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ReplaySubject, Subject, map, switchMap, takeUntil, tap } from 'rxjs';

@Directive({
    selector: 'medpace-editable-card-header',
    standalone: false,
})
export class MedpaceEditableCardHeaderDirective {}
@Directive({
    selector: 'medpace-editable-card-edit-mode-content',
    standalone: false,
})
export class MedpaceEditableCardEditModeContentDirective {}
@Directive({
    selector: 'medpace-editable-card-view-mode-content',
    standalone: false,
})
export class MedpaceEditableCardViewModeContentDirective {}
export type EditableCardMode = 'edit' | 'view';
@Component({
    selector: 'medpace-editable-card',
    templateUrl: './medpace-editable-card.component.html',
    styleUrls: ['./medpace-editable-card.component.scss'],
})
export class MedpaceEditableCardComponent implements OnInit {
    private currentlyEditedCardService = inject(CurrentlyEditedCardService);
    private snackbarService = inject(SnackbarService);

    @Input() public mode: EditableCardMode;
    @Input() public title: string;
    @Input() public showTitleAsteriskWhenEditMode: boolean;
    @Input() public showSwitchModeButton: boolean;
    @Input() public isLoading: boolean;
    @Input() public formGroup: FormGroup = new FormGroup({});
    @Output() public changeModeButtonClickEvent = new EventEmitter<EditableCardMode>();
    @ViewChild('ngForm') ngForm: NgForm;

    private modeSubject = new ReplaySubject<EditableCardMode>();
    public mode$ = this.modeSubject.asObservable().pipe();
    currentCard: EditedCard;

    ngOnInit(): void {
        this.currentCard = <EditedCard>{ cardName: this.title, cardForm: this };
    }

    public getMode() {
        return this.modeSubject.asObservable();
    }
    public setMode(mode: EditableCardMode) {
        this.mode = mode;
        this.modeSubject.next(this.mode);
    }

    public toggleEditMode() {
        this.mode === 'edit' ? this.setMode('view') : this.setMode('edit');
    }
    handleSaveClick() {
        this.ngForm.ngSubmit.emit();
        this.changeModeButtonClickEvent.emit('view');
    }
    handleEditClick() {
        this.changeModeButtonClickEvent.emit('edit');
    }

    /**
     * Sets or clears state of CurrentlyEditedCardService depending on mode
     * @param mode mode to which the card will be changed
     * @returns true if card can be edited, false otherwise
     */
    setupCurrentCard(mode: EditableCardMode): boolean {
        let result: boolean = true;
        if (mode === 'view') {
            //Current mode 'edit', throw an error if other card is currently edited
            if (!this.currentlyEditedCardService.clearCurrentState(this.currentCard)) {
                throw Error(
                    `Incorrect currently edited card, should be '${this.currentCard.cardName}', got '${
                        this.currentlyEditedCardService.getState().cardName
                    }'`
                );
            }
        } else if (mode === 'edit') {
            //current mode 'view'
            //set this card as current state, if failed show a snackbar message
            if (!this.currentlyEditedCardService.setState(this.currentCard)) {
                result = false;
                this.snackbarService.openInfoSnackbar(
                    `Card '${this.currentCard.cardName}' cannot be edited, currently editing: '${
                        this.currentlyEditedCardService.getState().cardName
                    }'`
                );
            }
        }
        return result;
    }
}

@Component({
    template: '',
})
export class EditableCardComponent<T> implements OnInit, OnDestroy {
    @Output() public saveEvent = new EventEmitter<T>();
    protected destroySubject = new Subject<boolean>();
    protected editableCardSubject = new ReplaySubject<MedpaceEditableCardComponent>();
    @ViewChild(MedpaceEditableCardComponent) public set editableCard(value: MedpaceEditableCardComponent) {
        this.editableCardSubject.next(value);
    }
    protected snackbarService = inject(SnackbarService);
    public readonly changeMode$ = this.editableCardSubject.pipe(
        takeUntil(this.destroySubject),
        switchMap((editableCard) =>
            editableCard.changeModeButtonClickEvent.pipe(
                map((mode) => {
                    return { editableCard, mode };
                })
            )
        ),
        tap((result) => {
            if (this.canChangeMode(result.mode) && result.editableCard.setupCurrentCard(result.mode)) {
                result.editableCard.setMode(result.mode);
                this.onModeChangeSuccess(result.mode);
                if (result.mode === 'view') this.saveEvent.emit(this.getOutputData());
            } else {
                this.onModeChangeFail(result.mode);
            }
        })
    );
    public getOutputData(): T {
        return null;
    }
    public ngOnInit(): void {
        this.changeMode$.subscribe();
    }
    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }
    protected canChangeMode(mode: EditableCardMode): boolean {
        return true;
    }
    protected onModeChangeSuccess(mode: EditableCardMode) {}
    protected onModeChangeFail(mode: EditableCardMode) {}
}
export abstract class EditableCardDataHandler<V, D> {
    constructor(
        protected _viewModel: V,
        protected fb: FormBuilder
    ) {}
    public get viewModel(): V {
        return this._viewModel;
    }

    public get data(): D {
        return this._data;
    }
    protected _data: D;
    public abstract getFormGroup(): FormGroup;
    public dataChangeEvent = new EventEmitter<D>();
    public onSave(): void {}
}
/**
 * @deprecated Use MedpaceEditableCardComponent instead.
 */
@Component({
    template: '',
})
export abstract class BaseEditableCardComponent<V, D> implements AfterViewInit, OnDestroy {
    @Input() public set viewModel(value: V) {
        this._viewModel = value;
        if (this._viewModel) this.onViewModelChange();
    }
    public get viewModel() {
        return this._viewModel;
    }
    protected _viewModel: V;
    public get data() {
        return this._data;
    }
    protected _data: D;
    @Input() public mode: EditableCardMode = 'view';
    @Input() public showTitleAsteriskWhenEditMode: boolean = false;
    @Input() public showSwitchModeButton: boolean = true;
    @Input() public isLoading: boolean = false;
    @Output() public saveEvent = new EventEmitter<D>();
    @Output() public dataChangeEvent = new EventEmitter<D>();
    @Output() public viewModelChangeEvent = new EventEmitter<V>();
    @Output() public formGroupChangeEvent = new EventEmitter<FormGroup>();

    @ViewChild(MedpaceEditableCardComponent) protected editableCard: MedpaceEditableCardComponent = null;
    protected editableCardSubject = new Subject<MedpaceEditableCardComponent>();
    protected destroySubject = new Subject<boolean>();
    public ngAfterViewInit(): void {
        this.editableCard.changeModeButtonClickEvent
            .pipe(takeUntil(this.destroySubject))
            .subscribe((mode: EditableCardMode) => this.onChangeModeButtonClick(mode));

        this.editableCardSubject.next(this.editableCard);
    }
    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }
    private onChangeModeButtonClick(mode: EditableCardMode) {
        if (this.canChangeMode(mode) && this.editableCard.setupCurrentCard(mode)) {
            this.editableCard.mode = mode;
            if (mode === 'view') this.onSave();
            else if (mode === 'edit') this.onEdit();
        }
    }
    protected canChangeMode(targetMode: EditableCardMode): boolean {
        return true;
    }
    protected onSave(): void {
        this.saveEvent.emit(this._data);
    }
    protected onEdit(): void {}
    protected onViewModelChange(): void {}
}
