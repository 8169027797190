import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionModel } from '@models/actionModel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientRequestSummary, SitePatientsViewModel } from 'src/app/models/patient';
import {
    transformToSite,
    transformToSiteArray,
    transformToSiteCRCUserArray,
} from 'src/app/services/transforms/site-transform';
import { Site, SiteDataModel, SiteSummaryViewModel } from '../../models/site';
import { AdminSiteRepository } from '../../repositories/admin/admin-site.repository';

@Injectable()
export class AdminSiteServices {
    constructor(private adminSiteRepo: AdminSiteRepository) {}

    createSite: (newSite: SiteDataModel) => Observable<HttpResponse<number>> = (newSite: SiteDataModel) => {
        return this.adminSiteRepo.createSite(newSite);
    };

    getSitesVM: () => Observable<SiteSummaryViewModel[]> = () => {
        return this.adminSiteRepo.getSitesVM();
    };

    getStudySites: (studyId: number) => Observable<SiteSummaryViewModel[]> = (studyId: number) => {
        return this.adminSiteRepo.getStudySites(studyId);
    };

    getSite: (siteId: number) => Observable<Site> = (siteId: number) => {
        return this.adminSiteRepo.getSite(siteId).pipe(
            map((value) => {
                return value ? transformToSite(value) : <Site>{};
            })
        );
    };
    getSites: (siteIds: number[]) => Observable<Site[]> = (siteIds: number[]) => {
        return this.adminSiteRepo.getSites(siteIds).pipe(map((dtos) => dtos.map((dto) => transformToSite(dto))));
    };

    getSiteCRCs: (siteId: number) => Observable<any[]> = (siteId: number) => {
        return this.adminSiteRepo.getSiteCRCs(siteId).pipe(
            map((value) => {
                return value ? transformToSiteCRCUserArray(value) : [];
            })
        );
    };

    updateSite: (site: SiteDataModel) => Observable<number> = (site: SiteDataModel) => {
        return this.adminSiteRepo.updateSite(site);
    };

    getSitePatients: (siteId: number) => Observable<SitePatientsViewModel[]> = (siteId: number) => {
        return this.adminSiteRepo.getSitePatients(siteId);
    };

    getSitePatientRequestSummaries: (siteId: number) => Observable<PatientRequestSummary[]> = (siteId: number) => {
        return this.adminSiteRepo.getSitePatientRequestSummaries(siteId);
    };

    getSitePatientDataWithVisitsId: (siteId: number) => Observable<SitePatientsViewModel[]> = (siteId: number) => {
        return this.adminSiteRepo.getSitePatientDataWithVisitsId(siteId);
    };

    getUserSite: (email: string) => Observable<Site> = (email: string) => {
        return this.adminSiteRepo.getUserSite(email).pipe(
            map((value) => {
                return value ? transformToSite(value[0]) : <Site>{};
            })
        );
    };

    getUserSiteByStudy: (studyId: number) => Observable<Site[]> = (studyId: number) => {
        return this.adminSiteRepo.getUserSiteByStudy(studyId).pipe(
            map((value) => {
                return value ? transformToSiteArray(value) : <Site[]>{};
            })
        );
    };

    isTravelSupportedInSite(siteId: number): Observable<boolean> {
        return this.getSite(siteId).pipe(map((site: Site) => site.services.travel));
    }

    changeSiteStatus: (newStatusName: string, siteId: number, site?: SiteDataModel) => Observable<number> = (
        newStatusName: string,
        siteId: number,
        site?: SiteDataModel
    ) => {
        return this.adminSiteRepo.changeSiteStatus(newStatusName, siteId, site);
    };

    getAvailableActions: (siteId: number) => Observable<ActionModel[]> = (siteId: number) => {
        return this.adminSiteRepo.getAvailableActions(siteId);
    };
}
