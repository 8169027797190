<div class="c-vert-stepper">
    <ul class="c-vert-stepper__list">
        <li class="c-vert-stepper__item is-active" *ngFor="let step of steps">
            <medpace-vertical-stepper-link
                (click)="stuff(step)"
                [stepName]="step.name"
                [stepIdentifier]="step.identifier"
                [isActive]="step.isActive"
                [offsetElement]="navbar"
            ></medpace-vertical-stepper-link>
        </li>
    </ul>
    <div class="c-vert-stepper__line"></div>
</div>
