// shared functionality between the components
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

/**
 * The purpose of Persistent form controls, that is PersistentFormControl, PersistentFormGroup and PersistentFormArray
 * is to allow them to persist in their enabled/disabled state when the parent control changes its state,
 * which Angular by default doesn't provide.
 * Example: given a FormGroup with nested FormControls, if you disable one nested FormControl, and then disable the whole FormGroup
 * and reenable it after a while, all nested FormControls will also get enabled, which may not be the desired behavior
 *
 * Persistent controls override their inherited enable() and disable() methods which get called from parent controls.
 * Then they can decide whether they want to ignore the command if they're told to be persistent
 *
 * By calling disable() with persistent=true, the control will remain disabled even when its parent formgroup get enabled, unless
 * called with force=true or breakPersistence=true
 */

export class PersistentFormGroup<
    TControl extends {
        [K in keyof TControl]: AbstractControl<any>;
    } = any,
> extends FormGroup<TControl> {
    private persistentDisable = false;
    private persistentEnable = false;
    setPersistence(opts: { disable?: boolean; enable?: boolean }) {
        if (opts.disable !== undefined) this.persistentDisable = opts.disable;
        if (opts.enable !== undefined) this.persistentEnable = opts.enable;
    }
    enable(opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        persistent?: boolean;
        force?: boolean;
        breakPersistence?: boolean;
    }): void {
        if (opts?.persistent !== undefined) this.persistentEnable = opts.persistent;
        if (opts?.breakPersistence) this.persistentDisable = false;
        if (!this.persistentDisable || opts?.force)
            super.enable({ emitEvent: opts?.emitEvent, onlySelf: opts?.onlySelf });
    }
    disable(opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        persistent?: boolean;
        force?: boolean;
        breakPersistence?: boolean;
    }): void {
        if (opts?.persistent !== undefined) this.persistentDisable = opts.persistent;
        if (opts?.breakPersistence) this.persistentEnable = false;
        if (!this.persistentEnable || opts?.force)
            super.disable({ emitEvent: opts?.emitEvent, onlySelf: opts?.onlySelf });
    }
}
export class PersistentFormArray<TControl extends AbstractControl<any> = any> extends FormArray<TControl> {
    private persistentDisable = false;
    private persistentEnable = false;
    setPersistence(opts: { disable?: boolean; enable?: boolean }) {
        if (opts.disable !== undefined) this.persistentDisable = opts.disable;
        if (opts.enable !== undefined) this.persistentEnable = opts.enable;
    }
    enable(opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        persistent?: boolean;
        force?: boolean;
        breakPersistence?: boolean;
    }): void {
        if (opts?.persistent !== undefined) this.persistentEnable = opts.persistent;
        if (opts?.breakPersistence) this.persistentDisable = false;
        if (!this.persistentDisable || opts?.force)
            super.enable({ emitEvent: opts?.emitEvent, onlySelf: opts?.onlySelf });
    }
    disable(opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        persistent?: boolean;
        force?: boolean;
        breakPersistence?: boolean;
    }): void {
        if (opts?.persistent !== undefined) this.persistentDisable = opts.persistent;
        if (opts?.breakPersistence) this.persistentEnable = false;
        if (!this.persistentEnable || opts?.force)
            super.disable({ emitEvent: opts?.emitEvent, onlySelf: opts?.onlySelf });
    }
}
export class PersistentFormControl<TControl = any> extends FormControl<TControl> {
    private persistentDisable = false;
    private persistentEnable = false;
    setPersistence(opts: { disable?: boolean; enable?: boolean }) {
        if (opts.disable !== undefined) this.persistentDisable = opts.disable;
        if (opts.enable !== undefined) this.persistentEnable = opts.enable;
    }
    enable(opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        persistent?: boolean;
        force?: boolean;
        breakPersistence?: boolean;
    }): void {
        if (opts?.persistent !== undefined) this.persistentEnable = opts.persistent;
        if (opts?.breakPersistence) this.persistentDisable = false;
        if (!this.persistentDisable || opts?.force)
            super.enable({ emitEvent: opts?.emitEvent, onlySelf: opts?.onlySelf });
    }
    disable(opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
        persistent?: boolean;
        force?: boolean;
        breakPersistence?: boolean;
    }): void {
        if (opts?.persistent !== undefined) this.persistentDisable = opts.persistent;
        if (opts?.breakPersistence) this.persistentEnable = false;
        if (!this.persistentEnable || opts?.force)
            super.disable({ emitEvent: opts?.emitEvent, onlySelf: opts?.onlySelf });
    }

    private touchedChangesSubject = new Subject<boolean>();
    readonly touchedChanges: Observable<boolean> = this.touchedChangesSubject.asObservable();
    override markAsTouched(opts?: { onlySelf?: boolean }): void {
        super.markAsTouched(opts);
        this.touchedChangesSubject.next(true);
    }
    override markAsUntouched(opts?: { onlySelf?: boolean }): void {
        super.markAsUntouched(opts);
        this.touchedChangesSubject.next(false);
    }
}
