import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedpaceDeleteCaregiverModalComponent } from '@app/admin/manage/modals/delete-caregiver-modal/delete-caregiver-modal.component';
import { MedpaceDeleteInfoCaregiverModalComponent } from '@app/admin/manage/modals/delete-info-caregiver-modal/delete-info-caregiver-modal.component';
import { MedpaceCaregiverInfoDialogComponent } from '@components/molecules/forms/caregiver-info-card/caregiver-info-dialog/caregiver-info-dialog.component';
import { CaregiverTravelRequest } from '@models/caregiverTravelRequest';
import { EmittedEvent } from '@models/event';
import { Caregiver, Patient } from '@models/patient';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { CaregiverService } from '@services/caregiver/caregiver.service';
import { EventService } from '@services/event/event.service';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { transformToPatientDataModel } from '@services/transforms/patient-transform';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'medpace-caregiver-info-card',
    templateUrl: './caregiver-info-card.component.html',
    styleUrls: ['./caregiver-info-card.component.scss'],
})
export class MedpaceCaregiverInfoCardComponent implements OnInit, OnDestroy {
    patient: Patient = null;

    isFromPatientInfo: boolean = false;

    @Output()
    caregiverUpdate = new EventEmitter();

    destroy$ = new Subject();

    hasPrimaryCaregiver: boolean = false;

    patient$: Observable<Patient>;

    private removeCaregiverSubject = new Subject<Caregiver>();

    protected removeCaregiver(caregiver: Caregiver) {
        this.removeCaregiverSubject.next(caregiver);
    }

    constructor(
        public dialog: MatDialog,
        private adminPatientServices: AdminPatientServices,
        private snackbarService: SnackbarService,
        private eventService: EventService,
        private patientStateService: PatientStateService,
        private googleMapsService: GoogleMapsService,
        private caregiverService: CaregiverService
    ) {}

    ngOnInit(): void {
        this.patient$ = this.patientStateService.getSummaryPatientData();
        this.patient$
            .pipe(
                takeUntil(this.destroy$),
                filter((patient) => !!patient),
                tap((patient) => {
                    if (patient) {
                        this.patient = patient;
                        if (!this.patient?.caregivers) {
                            this.patient.caregivers = new Array<Caregiver>();
                        } else {
                            this.patient?.caregivers?.forEach((caregiver: Caregiver) => {
                                if (caregiver.isPrimary) {
                                    this.hasPrimaryCaregiver = true;
                                }
                            });
                        }
                    }
                })
            )
            .subscribe();

        //remove caregiver when it is possible
        this.openCaregiverRemoveDialog$.subscribe({
            next: ([caregiver, _, deletedFromDB]) => {
                //handle deletion from DB
                if (deletedFromDB) {
                    this.patient.caregivers.splice(this.patient.caregivers.indexOf(caregiver), 1);
                    if (
                        (caregiver.isPrimary && this.patient.paymentRecipientRole === 'Primary Caregiver') ||
                        (!caregiver.isPrimary &&
                            this.patient.paymentRecipientRole ===
                                `Caregiver ${this.patient.caregivers.indexOf(caregiver)}`)
                    ) {
                        this.patient.paymentRecipientRole = '';
                    }
                    if (caregiver.isPrimary && this.patient.caregivers.length > 0) {
                        // set first remaining caregiver to primary
                        this.setCaregiverPrimary(this.patient.caregivers[0]);
                    }
                    this.patientStateService.setNewPatient(this.patient);
                    this.snackbarService.openInfoSnackbar('Caregiver Removed Successfully');
                }
            },
            error: (error) => console.error(`failed to delete the caregiver, error ${error}`),
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    setIdOnCaregiver(caregiver: Caregiver) {
        if (this.patient.caregivers.length > 0) {
            this.patient.caregivers.forEach((caregiver: Caregiver) => {
                if (!caregiver.id) {
                    this.adminPatientServices.getPatientCaregivers(this.patient.id).subscribe((result: any[]) => {
                        result?.forEach((caregiverInfo: any) => {
                            if (
                                caregiver.firstName === caregiverInfo.firstName &&
                                caregiver.middleName === caregiverInfo.middleName &&
                                caregiver.lastName === caregiverInfo.lastName &&
                                caregiver.isPrimary === caregiverInfo.isPrimary
                            ) {
                                caregiver.id = caregiverInfo.id;
                            }
                        });
                        if (caregiver.isPrimary) {
                            this.setCaregiverPrimary(caregiver);
                        }
                    });
                }
            });
        }
    }

    openCaregiverProfile(caregiver: Caregiver) {
        const dialogRef = this.dialog.open(MedpaceCaregiverInfoDialogComponent, {
            width: '80%',
            maxWidth: 600,
            minWidth: 360,
            disableClose: true,
            data: {
                editMode: false,
                patient: this.patient,
                selectedCaregiver: caregiver,
                newCaregiver: false,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((caregiverInfo: Caregiver) => {
                if (!!caregiverInfo) {
                    if (caregiverInfo.isPrimary) {
                        this.hasPrimaryCaregiver = true;
                        this.setCaregiverPrimary(caregiverInfo);
                    }
                    if (!!this.patient.id) {
                        this.updateCaregiver(caregiverInfo);
                    }
                }
                this.googleMapsService.setStyleForComponent();
            });
    }

    //return caregiver and result of window
    checkIfRemoveCaregiverIsPossible$ = this.removeCaregiverSubject.pipe(
        switchMap((caregiver) =>
            this.caregiverService.getTravelRequests(caregiver.id).pipe(
                take(1),
                switchMap((travelRequests: CaregiverTravelRequest[]) => {
                    if (travelRequests.length === 0) {
                        return combineLatest([
                            of(caregiver),
                            this.dialog
                                .open(MedpaceDeleteCaregiverModalComponent, { disableClose: true })
                                .afterClosed(),
                        ]);
                    } else {
                        this.dialog.open(MedpaceDeleteInfoCaregiverModalComponent);
                        return combineLatest([of(caregiver), of(undefined)]);
                    }
                })
            )
        )
    );

    //caregiver,closeresult,removed
    openCaregiverRemoveDialog$ = this.checkIfRemoveCaregiverIsPossible$.pipe(
        switchMap(([caregiver, dataResult]) => {
            if (dataResult) {
                if (!!this.patient.id) {
                    return combineLatest([
                        of(caregiver), //caregiver
                        this.adminPatientServices.deleteCaregiver(caregiver.patientId, caregiver.id),
                        of(true),
                    ]);
                } else {
                    //handle deletion for new patient
                    this.patient.caregivers.splice(this.patient.caregivers.indexOf(caregiver), 1);
                    if (caregiver.isPrimary && this.patient.caregivers.length > 0) {
                        // set first remaining caregiver to primary
                        this.setCaregiverPrimary(this.patient.caregivers[0]);
                    }
                    this.eventService.emit(new EmittedEvent('caregiverChanged', this.patient));
                    return combineLatest([of(caregiver), of(undefined), of(false)]);
                }
            }
            return combineLatest([of(caregiver), of(undefined), of(false)]);
        }),
        // notify observers of patient that with its new caregivers array
        tap((_) => this.patientStateService.setNewPatient(this.patient))
    );

    openCaregiverInfoDialog() {
        const dialogRef = this.dialog.open(MedpaceCaregiverInfoDialogComponent, {
            width: '80%',
            maxWidth: 600,
            minWidth: 360,
            disableClose: true,
            data: {
                editMode: true,
                patient: this.patient,
                newCaregiver: true,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((caregiverInfo: Caregiver) => {
                if (!!caregiverInfo) {
                    if (caregiverInfo.isPrimary) {
                        this.patient.caregivers.splice(0, 0, caregiverInfo);
                        this.hasPrimaryCaregiver = true;
                    } else {
                        this.patient.caregivers.splice(1, 0, caregiverInfo);
                    }

                    if (!!this.patient.id) {
                        caregiverInfo.patientId = this.patient.id;
                        this.createCaregiver(caregiverInfo);
                        this.snackbarService.openInfoSnackbar('Caregiver Created Successfully');
                    } else {
                        this.eventService.emit(new EmittedEvent('caregiverChanged', this.patient));
                        if (caregiverInfo?.isPrimary) {
                            this.setCaregiverPrimary(caregiverInfo);
                        }
                    }

                    this.patientStateService.setNewPatient(this.patient);
                }

                this.googleMapsService.setStyleForComponent();
            });
    }

    /**
     * Change a primary caregiver
     * @param caregiver Caregiver that will be made primary
     */
    setCaregiverPrimary(caregiver: Caregiver) {
        if (!!this.patient.id) {
            //set other caregivers to not be primary
            this.patient.caregivers.forEach((c: Caregiver) => {
                if (caregiver.id === c.id) {
                    c.isPrimary = true;
                } else {
                    c.isPrimary = false;
                }
            });

            //If not new patient (has ID), make changes through adminPatientServices
            //update patient with updated caregivers

            this.adminPatientServices
                .updatePatient(this.patient.id, transformToPatientDataModel(this.patient))
                .subscribe(() => {
                    this.patientStateService.setSummaryPatientData(this.patient.id);
                });
        } else {
            //set other caregivers to not be primary
            this.patient.caregivers.forEach((c: Caregiver) => {
                if (caregiver === c) {
                    c.isPrimary = true;
                } else {
                    c.isPrimary = false;
                }
            });
            //If new patient (does not have ID), all changes can be done locally
            this.eventService.emit(new EmittedEvent('caregiverChanged', this.patient));
        }
    }

    /**
     * Immediately add a Caregiver to DB.
     */
    createCaregiver(caregiver: Caregiver): void {
        this.adminPatientServices
            .createCaregiver(caregiver)
            .pipe(
                takeUntil(this.destroy$),
                tap(() => this.setIdOnCaregiver(caregiver)),
                finalize(() => this.patientStateService.setSummaryPatientData(this.patient.id))
            )
            .subscribe();
    }

    /**
     * Immediately update the Caregiver in DB.
     */
    updateCaregiver(caregiver: Caregiver): void {
        this.adminPatientServices
            .updateCaregiver(caregiver)
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    this.patientStateService.setSummaryPatientData(this.patient.id);
                })
            )
            .subscribe();
    }
}
