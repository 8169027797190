import { Injectable } from '@angular/core';
import { MdsOptionGeneric } from '@utility/utility';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TimeOptionsStateService {
    private timeOptionsSubject = new ReplaySubject<MdsOptionGeneric<number>[]>();
    public is24HourFormat: boolean;
    public init(is24HourFormat: boolean) {
        this.is24HourFormat = is24HourFormat;
        const timeOptions = Array.from({ length: (24 * 60) / 15 }, (_, index) => index * 15).map(
            (minutesSinceMidnight) =>
                <MdsOptionGeneric<number>>{
                    value: minutesSinceMidnight,
                    viewValue: this.convertMinutesSinceMidnightToTimeString(minutesSinceMidnight, is24HourFormat),
                }
        );
        this.timeOptionsSubject.next(timeOptions);
    }
    public getTimeOptions() {
        return this.timeOptionsSubject.asObservable();
    }

    public convertMinutesSinceMidnightToTimeString(
        minutesSinceMidnight: number,
        is24HourFormat: boolean = false
    ): string {
        if (minutesSinceMidnight < 0 || minutesSinceMidnight >= 1440) {
            throw new Error('Invalid input. MinutesSinceMidnight should be between 0 and 1439.');
        }

        const hours = Math.floor(minutesSinceMidnight / 60);
        const minutes = minutesSinceMidnight % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        if (!is24HourFormat) {
            const ampm = hours < 12 ? 'am' : 'pm';

            // Convert hours to 12-hour format
            const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
            return `${formattedHours}:${formattedMinutes} ${ampm}`;
        } else {
            return `${hours}:${formattedMinutes}`;
        }
    }

    public convertTimeStringToMinutesSinceMidnight(timeStr: string): number | null {
        const match = timeStr.match(/^(\d+):(\d+)\s+(am|pm)$/i);

        if (!match) {
            return null; // Invalid input format
        }

        let [_, hoursStr, minutesStr, period] = match;
        let hours = parseInt(hoursStr, 10);
        let minutes = parseInt(minutesStr, 10);

        if (isNaN(hours) || isNaN(minutes) || hours < 1 || hours > 12 || minutes < 0 || minutes > 59) {
            return null; // Invalid time
        }

        if (period.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
        } else if (period.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        }

        const totalMinutes = hours * 60 + minutes;
        return totalMinutes;
    }
}
