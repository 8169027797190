import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BankDetails } from '@models/bankDetails';
import { CountryViewModel } from '@models/country';
import { BankDetailsForm } from '@models/form-models/bankDetailsForm';
import { Patient } from '@models/patient';
import { CountryStateService } from '@services/state-management/country-state.service';
import { transformCountryViewModelToCountryDto } from '@services/transforms/country-transform';
import { maxLengthValidator, minLengthValidator } from '@utility/utility.validators';
import { Subject, filter, switchMap, takeUntil, tap } from 'rxjs';
import { InformationAccuracyModalComponent } from './information-accuracy-modal/information-accuracy-modal.component';

@Component({
    selector: 'medpace-bank-details',
    templateUrl: './bank-details.component.html',
    styleUrls: ['./bank-details.component.scss'],
})
export class MedpaceBankDetailsComponent implements OnInit, OnDestroy {
    private fb = inject(FormBuilder);
    private countryStateService = inject(CountryStateService);
    private dialog = inject(MatDialog);

    @Input({ required: true }) patientInfo: Patient;
    @Input() isEditing: boolean = false;
    @Input({ required: true }) parentFormGroup: FormGroup;

    bankDetails: BankDetails;
    newBankDetails: boolean = false;
    isLoading: boolean = true;
    isUnderstood: boolean = false;
    bankDetailsFormGroup = this.fb.group<BankDetailsForm>({
        fullNameControl: this.fb.control('', [maxLengthValidator(452)]),
        bankCodeControl: this.fb.control('', [minLengthValidator(8), maxLengthValidator(11)]),
        ibanControl: this.fb.control(''),
        countryControl: this.fb.control(''),
        cityControl: this.fb.control('', [maxLengthValidator(100)]),
        addressControl: this.fb.control('', [maxLengthValidator(201)]),
        postCodeControl: this.fb.control('', [maxLengthValidator(10)]),
    });

    private componentDestroyed$ = new Subject<boolean>();
    countries$ = this.countryStateService.getCountriesStringArray().pipe(
        tap((_) => {
            this.isLoading = false;
        })
    );
    handleValueChange$ = this.bankDetailsFormGroup.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        filter(Boolean),
        tap((value) => {
            this.bankDetails.fullName = value?.fullNameControl;
            this.bankDetails.bankCode = value?.bankCodeControl;
            this.bankDetails.iban = value?.ibanControl;
            this.bankDetails.city = value?.cityControl;
            this.bankDetails.address = value?.addressControl;
            this.bankDetails.postCode = value.postCodeControl;
        }),
        //Update country value
        filter((value) => !!value.countryControl),
        switchMap((value) => this.countryStateService.getCountryByName(value.countryControl)),
        //Check if valid country
        filter(Boolean),
        tap((country: CountryViewModel) => {
            this.bankDetails.country = transformCountryViewModelToCountryDto(country);
        })
    );

    ngOnInit(): void {
        if (!this.patientInfo?.bankDetails) {
            this.patientInfo.bankDetails = <BankDetails>{};
            this.newBankDetails = true;
        }
        this.bankDetails = this.patientInfo?.bankDetails;

        if (this.isEditing) {
            //Not adding formGroup if component in view mode
            //Add FormGroup to ParentFormGroup
            this.parentFormGroup.addControl('bankDetailsFormGroup', this.bankDetailsFormGroup);
            this.handleValueChange$.subscribe();

            this.initializeFormGroup(this.bankDetailsFormGroup, this.newBankDetails);
        }
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    initializeFormGroup(bankDetailsFormGroup: FormGroup<BankDetailsForm>, newBankDetails: boolean) {
        if (newBankDetails) {
            if (this.patientInfo?.patientIdentification?.country) {
                bankDetailsFormGroup.patchValue({
                    countryControl: this.patientInfo?.patientIdentification?.country,
                });
            } else {
                //patch empty value
                bankDetailsFormGroup.patchValue({
                    countryControl: '',
                });
            }
            //No bank details
            bankDetailsFormGroup.patchValue({
                cityControl: this.patientInfo?.patientIdentification?.city,
                addressControl:
                    this.patientInfo?.patientIdentification?.address1 +
                    (this.patientInfo?.patientIdentification?.address2
                        ? ' ' + this.patientInfo?.patientIdentification?.address2
                        : ''),
                postCodeControl: this.patientInfo?.patientIdentification?.zipcode,
            });
        } else {
            bankDetailsFormGroup.patchValue({
                fullNameControl: this.bankDetails?.fullName,
                bankCodeControl: this.bankDetails?.bankCode,
                ibanControl: this.bankDetails?.iban,
                cityControl: this.bankDetails?.city,
                countryControl: this.bankDetails.country.name,
                addressControl: this.bankDetails?.address,
                postCodeControl: this.bankDetails?.postCode,
            });
        }
    }

    handleInformationAccuracyUnderstanding(): void {
        //open dialog only one time when editing bank details
        if (!this.isUnderstood && this.isEditing) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.maxWidth = '750px';

            //afterClosed completes after the value is observed, no need for takeUntil
            this.dialog
                .open<InformationAccuracyModalComponent>(InformationAccuracyModalComponent, dialogConfig)
                .afterClosed()
                .subscribe((value: boolean) => {
                    this.isUnderstood = value;
                });
        }
    }
}
