import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarServiceHistoryData } from '@models/history-data/carServiceHistoryData';
import { FlightHistoryData } from '@models/history-data/flightHistoryData';
import { LodgingHistoryData } from '@models/history-data/lodgingHistoryData';
import { OtherHistoryData } from '@models/history-data/otherHistoryData';
import { RentalCarHistoryData } from '@models/history-data/rentalCarHistoryData';
import { TrainHistoryData } from '@models/history-data/trainHistoryData';
import { SiteService } from '@services/site/site.service';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HistoryDataRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    private cacheCarServiceHistoryData$: Observable<CarServiceHistoryData[]>;
    private cacheLodgingHistoryData$: Observable<LodgingHistoryData[]>;
    private cacheRentalCarHistoryData$: Observable<RentalCarHistoryData[]>;
    private cacheFlightHistoryData$: Observable<FlightHistoryData[]>;
    private cacheTrainHistoryData$: Observable<TrainHistoryData[]>;
    private cacheOtherHistoryData$: Observable<OtherHistoryData[]>;

    clearCache(): void {
        this.cacheCarServiceHistoryData$ = null;
        this.cacheLodgingHistoryData$ = null;
        this.cacheRentalCarHistoryData$ = null;
        this.cacheFlightHistoryData$ = null;
        this.cacheTrainHistoryData$ = null;
        this.cacheOtherHistoryData$ = null;
    }

    getPatientTravelRequestHistoryForCarService: (patientId: number) => Observable<CarServiceHistoryData[]> = (
        patientId: number
    ) => {
        if (!this.cacheCarServiceHistoryData$) {
            this.cacheCarServiceHistoryData$ = this.client
                .get<
                    CarServiceHistoryData[]
                >(`${this.siteService.getSiteBaseUrl()}/api/history/carService/${patientId}`)
                .pipe(shareReplay(1));
        }

        return this.cacheCarServiceHistoryData$;
    };

    getPatientTravelRequestHistoryForLodging: (patientId: number) => Observable<LodgingHistoryData[]> = (
        patientId: number
    ) => {
        if (!this.cacheLodgingHistoryData$) {
            this.cacheLodgingHistoryData$ = this.client
                .get<LodgingHistoryData[]>(`${this.siteService.getSiteBaseUrl()}/api/history/lodging/${patientId}`)
                .pipe(shareReplay(1));
        }

        return this.cacheLodgingHistoryData$;
    };

    getPatientTravelRequestHistoryForRentalCar: (patientId: number) => Observable<RentalCarHistoryData[]> = (
        patientId: number
    ) => {
        if (!this.cacheRentalCarHistoryData$) {
            this.cacheRentalCarHistoryData$ = this.client
                .get<RentalCarHistoryData[]>(`${this.siteService.getSiteBaseUrl()}/api/history/rentalCar/${patientId}`)
                .pipe(shareReplay(1));
        }

        return this.cacheRentalCarHistoryData$;
    };

    getPatientTravelRequestHistoryForFlight: (patientId: number) => Observable<FlightHistoryData[]> = (
        patientId: number
    ) => {
        if (!this.cacheFlightHistoryData$) {
            this.cacheFlightHistoryData$ = this.client
                .get<FlightHistoryData[]>(`${this.siteService.getSiteBaseUrl()}/api/history/flight/${patientId}`)
                .pipe(shareReplay(1));
        }
        return this.cacheFlightHistoryData$;
    };

    getPatientTravelRequestHistoryForTrain: (patientId: number) => Observable<TrainHistoryData[]> = (
        patientId: number
    ) => {
        if (!this.cacheTrainHistoryData$) {
            this.cacheTrainHistoryData$ = this.client
                .get<TrainHistoryData[]>(`${this.siteService.getSiteBaseUrl()}/api/history/train/${patientId}`)
                .pipe(shareReplay(1));
        }
        return this.cacheTrainHistoryData$;
    };

    getPatientTravelRequestHistoryForOther: (patientId: number) => Observable<OtherHistoryData[]> = (
        patientId: number
    ) => {
        if (!this.cacheOtherHistoryData$) {
            this.cacheOtherHistoryData$ = this.client
                .get<OtherHistoryData[]>(`${this.siteService.getSiteBaseUrl()}/api/history/other/${patientId}`)
                .pipe(shareReplay(1));
        }
        return this.cacheOtherHistoryData$;
    };
}
