import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EmittedEvent } from '@models/event';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { EventService } from '@services/event/event.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { Subject, takeUntil } from 'rxjs';
import { MedpaceMessageModalComponent } from 'src/app/components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import {
    Patient,
    PatientAccessibilityRequirements,
    PatientIdentification,
    PatientTravelPreferences,
    createNewPatient,
} from 'src/app/models/patient';
import { Site } from 'src/app/models/site';
import { Study } from 'src/app/models/study';
import { FieldValidator } from 'src/app/models/validators/field-validator';
import { GroupService } from 'src/app/services/group/group.service';
import { transformToPatientDataModel } from 'src/app/services/transforms/patient-transform';

@Component({
    selector: '',
    templateUrl: './patient-add-edit.component.html',
})
export class PatientCreateEditComponent implements OnInit, OnDestroy {
    study: Study;
    site: Site;
    patient: Patient;
    validationArray: FieldValidator<any>[];
    isUpdate: boolean = false;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private groupServices: GroupService,
        private adminPatientServices: AdminPatientServices,
        public dialog: MatDialog,
        private eventService: EventService,
        private studyStateService: StudyStateService,
        private siteStateService: SiteStateService,
        private patientStateService: PatientStateService,
        private snackbarService: SnackbarService,
        private cardService: CurrentlyEditedCardService,
        private location: Location
    ) {}

    ngOnInit(): void {
        //yeah... we really don't need a userid to get the groups from storage.  TODO guard this?
        this.groupServices.getGroupsFromStorage().subscribe((result) => {
            this.isAdmin = this.groupServices.isUserAdmin(result);
            this.isSuperAdmin = this.groupServices.isUserSuperAdmin(result);
        });

        this.setStudyStates(this.activeRoute.snapshot.params.id);
        this.setSiteStates(this.activeRoute.snapshot.params.siteId);
        this.getStudyStates();
        this.getSiteStates();

        if (this.activeRoute.snapshot.routeConfig.path.indexOf('/new') != -1) {
            this.patient = createNewPatient();
            this.setNewPatient(this.patient);
        }

        if (this.activeRoute.snapshot.routeConfig.path.indexOf('/edit') != -1) {
            this.isUpdate = true;
            this.setPatientSiteStates(this.activeRoute.snapshot.params.patientId);
            this.getPatientSiteStates();
        }
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.siteStateService.clearStore();
        this.patientStateService.clearStore();
        this.studyStateService.clearStore();
    }
    registerTravelChangeEvent(event: PatientTravelPreferences): void {
        if (event.airtravel) {
            this.patient.travelPreferences.airtravel = {
                ...this.patient.travelPreferences.airtravel,
                ...event.airtravel,
            };
        }

        if (event.internationaltravel) {
            this.patient.travelPreferences.internationaltravel = {
                ...this.patient.travelPreferences.internationaltravel,
                ...event.internationaltravel,
            };
        }

        if (event.lodging) {
            this.patient.travelPreferences.lodging = { ...this.patient.travelPreferences.lodging, ...event.lodging };
        }

        if (event.traintravel) {
            this.patient.travelPreferences.traintravel = {
                ...this.patient.travelPreferences.traintravel,
                ...event.traintravel,
            };
        }
        if (event.groundTransportation) {
            this.patient.travelPreferences.groundTransportation = {
                ...this.patient.travelPreferences.groundTransportation,
                ...event.groundTransportation,
            };
        }

        if (event.rentalCar) {
            this.patient.travelPreferences.rentalCar = {
                ...this.patient.travelPreferences.rentalCar,
                ...event.rentalCar,
            };
        }
    }

    setStudyStates(id: number) {
        this.studyStateService.setStudy(id);
    }
    setSiteStates(id: number) {
        this.siteStateService.setSite(id);
    }

    setPatientSiteStates(patientId: number) {
        this.patientStateService.setSummaryPatientData(patientId);
    }
    setNewPatient(newPatient: Patient) {
        this.patientStateService.setNewPatient(newPatient);
    }

    getStudyStates() {
        this.studyStateService
            .getStudy()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((x) => {
                this.study = x;
            });
    }
    getSiteStates() {
        this.siteStateService
            .getSite()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((x) => {
                this.site = x;
            });
    }

    getPatientSiteStates() {
        this.patientStateService
            .getSummaryPatientData()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((x) => {
                this.patient = x;
            });
    }

    registerAccessibilityChangeEvent(event: PatientAccessibilityRequirements): void {
        Object.assign(this.patient.accessibilityRequirements, { ...this.patient.accessibilityRequirements, ...event });
    }

    registerIdentificationChangeEvent(event: PatientIdentification) {
        Object.assign(this.patient.patientIdentification, { ...this.patient.patientIdentification, ...event });
    }

    registerConsentChange(event: Patient): void {
        let check = { ...this.patient, ...event };
        Object.assign(this.patient, check);
    }

    sendData(event: any): void {
        //send it
        if (this.patient.id) {
            //PUT
            this.patient.siteId = this.site.id;
            const model = transformToPatientDataModel(this.patient);

            this.adminPatientServices.updatePatient(model.id, model).subscribe(
                (result) => {
                    let card = this.cardService.getState();
                    if (card?.cardForm) {
                        card.cardForm.toggleEditMode();
                        this.cardService.clearCurrentState(card);
                    }

                    this.setPatientSiteStates(this.activeRoute.snapshot.params.patientId);
                    this.eventService.emit(new EmittedEvent('updatedPatient', null));

                    if (event.type !== 'incrementalSave') {
                        this.location.back();
                    }
                    this.snackbarService.openInfoSnackbar(
                        `Updated patient ${this.patient.patientIdentification.firstName} ${this.patient.patientIdentification.lastName}`
                    );
                },
                (error) => {
                    this.handlePatientSaveError(error);
                }
            );
        } else {
            //POST
            this.patient.siteId = this.site.id;
            const model = transformToPatientDataModel(this.patient);

            this.adminPatientServices.createPatient(model).subscribe(
                (response) => {
                    this.location.back();
                    this.snackbarService.openInfoSnackbar('Created new patient');
                },
                (error) => {
                    this.handlePatientSaveError(error);
                }
            );
        }
    }

    private handlePatientSaveError(error) {
        switch (error.status) {
            case 400:
                switch (error.error.errorReasonCode) {
                    case 'DuplicatePatient':
                        const dialogConfig = new MatDialogConfig();

                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 350;
                        dialogConfig.minWidth = 350;

                        dialogConfig.data = {
                            title: 'Duplicate Entry',
                            bodyText:
                                'This patient cannot be created because a patient with the same ID already exists',
                        };

                        this.dialog.open(MedpaceMessageModalComponent, dialogConfig);
                        break;
                }
                break;
        }
    }

    getTitleLabel(): string {
        return this.isUpdate ? 'Edit Patient' : 'Create New Patient';
    }
}
