import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestCount } from '@models/requestCount';
import { AdminRequestRepository } from '@repositories/admin/admin-request.repository';
import {
    transformReimbursementToGenericRequest,
    transformStipendArrayToGenericRequestArray,
    transformStipendToGenericRequest,
    transformToReimbursementArrayToGenericRequestArray,
    transformTravelRequesToGenericRequest,
    transformTravelRequestArrayToGenericRequestArray,
} from '@services/transforms/request-transform';
import { SaveStatusResponse } from '@utility/utility';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
    GenericRequest,
    MileageReimbursementRate,
    MileageReimbursementRateData,
    ReimbursementRequest,
    RequestLog,
    RequestSummary,
    TravelRequest,
    TravelRequestTypeCount,
} from '../../models/request';

@Injectable()
export class AdminRequestServices {
    constructor(private repo: AdminRequestRepository) {}

    private cacheActiveTravelRequestTypeCountByStudy$: Observable<TravelRequestTypeCount>;

    getAllAccessibleRequestsCount: () => Observable<number> = () => {
        return this.repo.getAllAccessibleRequestsCount();
    };
    getAllRequestsCount = (filters?: { studyIds?: number[]; visitNamesIds?: number[] }) => {
        return this.repo.getAllRequestsCount(filters);
    };
    getAllReimbursementRequestsCount = (filters?: {
        studyIds?: number[];
        visitNamesIds?: number[];
        expenseTypesNames?: string[];
    }) => {
        return this.repo.getAllReimbursementRequestsCount(filters);
    };

    getTotalAllRequestsCount: () => Observable<RequestCount[]> = () => {
        return this.repo.getTotalAllRequestsCount();
    };

    getAllRequestSummaries: () => Observable<RequestSummary[]> = () => {
        return this.repo.getAllRequestSummaries().pipe(
            map((summaries: RequestSummary[]) =>
                summaries.map((summary) => ({
                    ...summary,
                    visitName: summary.visitName ?? 'Unspecified',
                }))
            )
        );
    };

    createStipendRequest: (stipendRequest) => Observable<SaveStatusResponse> = (stipendRequest) => {
        return this.repo.createStipendRequest(stipendRequest);
    };

    updateStipendRequest: (stipendRequest) => Observable<SaveStatusResponse> = (stipendRequest) => {
        return this.repo.updateStipendRequest(stipendRequest);
    };

    submitStipendRequest: (stipendRequest) => Observable<HttpResponse<SaveStatusResponse>> = (stipendRequest) => {
        return this.repo.submitStipendRequest(stipendRequest);
    };

    completeStipendRequest: (stipendRequest) => Observable<SaveStatusResponse> = (stipendRequest) => {
        return this.repo.completeStipendRequest(stipendRequest);
    };

    getStipendRequest: (requestId: number) => Observable<GenericRequest> = (requestId: number) => {
        return this.repo.getStipendRequest(requestId).pipe(
            map((value) => {
                return value ? transformStipendToGenericRequest(value) : null;
            })
        );
    };

    getStipendRequests: (patientId: number) => Observable<GenericRequest[]> = (patientId: number) => {
        return this.repo.getStipendRequests(patientId).pipe(
            map((value) => {
                return value ? transformStipendArrayToGenericRequestArray(value) : [];
            })
        );
    };

    createReimbursementRequest: (reimbursement: ReimbursementRequest) => Observable<HttpResponse<SaveStatusResponse>> =
        (reimbursement: ReimbursementRequest) => {
            return this.repo.createReimbursementRequest(reimbursement);
        };

    updateReimbursementRequest: (
        requestId: number,
        reimbursement: ReimbursementRequest
    ) => Observable<SaveStatusResponse> = (requestId: number, reimbursement: ReimbursementRequest) => {
        return this.repo.updateReimbursementRequest(requestId, reimbursement);
    };

    submitReimbursementRequest: (reimbursement: ReimbursementRequest) => Observable<HttpResponse<SaveStatusResponse>> =
        (reimbursement: ReimbursementRequest) => {
            return this.repo.submitReimbursementRequest(reimbursement);
        };

    completeReimbursementRequest: (
        requestId: number,
        reimbursement: ReimbursementRequest
    ) => Observable<SaveStatusResponse> = (requestId: number, reimbursement: ReimbursementRequest) => {
        return this.repo.completeReimbursementRequest(requestId, reimbursement);
    };

    getReimbursementRequest: (requestId: number) => Observable<GenericRequest> = (requestId: number) => {
        return this.repo.getReimbursementRequest(requestId).pipe(
            map((value) => {
                return value ? transformReimbursementToGenericRequest(value) : null;
            })
        );
    };

    getReimbursementRequestAttachmentUri(requestId: number, attachmentId: number): Observable<URL> {
        return this.repo.getReimbursementRequestAttachmentUri(requestId, attachmentId);
    }

    getReimbursementRequests: (patientId: number) => Observable<GenericRequest[]> = (patientId: number) => {
        return this.repo.getReimbursementRequests(patientId).pipe(
            map((value) => {
                return value ? transformToReimbursementArrayToGenericRequestArray(value) : [];
            })
        );
    };

    createTravelRequest: (travelRequest: TravelRequest) => Observable<HttpResponse<SaveStatusResponse>> = (
        travelRequest: TravelRequest
    ) => {
        return this.repo.createTravelRequest(travelRequest);
    };

    updateTravelRequest: (requestId: number, travelRequest: TravelRequest) => Observable<SaveStatusResponse> = (
        requestId: number,
        travelRequest: TravelRequest
    ) => {
        return this.repo.updateTravelRequest(requestId, travelRequest);
    };

    submitTravelRequest: (travelRequest: TravelRequest) => Observable<HttpResponse<SaveStatusResponse>> = (
        travelRequest: TravelRequest
    ) => {
        return this.repo.submitTravelRequest(travelRequest);
    };

    completeTravelRequest: (requestId: number, travelRequest: TravelRequest) => Observable<SaveStatusResponse> = (
        requestId: number,
        travelRequest: TravelRequest
    ) => {
        return this.repo.completeTravelRequest(requestId, travelRequest);
    };

    getTravelRequest: (requestId: number) => Observable<GenericRequest> = (requestId: number) => {
        return this.repo.getTravelRequest(requestId).pipe(
            map((value) => {
                return value ? transformTravelRequesToGenericRequest(value) : null;
            })
        );
    };

    getTravelRequests: (patientId: number) => Observable<GenericRequest[]> = (patientId: number) => {
        return this.repo.getTravelRequests(patientId).pipe(
            map((value) => {
                return value ? transformTravelRequestArrayToGenericRequestArray(value) : [];
            })
        );
    };

    getCrcUserReimbursementRequestCount: (statusesIds: number[]) => Observable<number | null> = (
        statusesIds: number[]
    ) => {
        return this.repo.getCrcUserReimbursementRequestCount(statusesIds);
    };

    getTravelRequestAttachmentUri(requestId: number, attachmentId: number): Observable<URL> {
        return this.repo.getTravelRequestAttachmentUri(requestId, attachmentId);
    }

    getTravelRequestLog: (requestId: number) => Observable<RequestLog[]> = (requestId: number) => {
        return this.repo.getTravelRequestLog(requestId);
    };

    getStipendRequestLog: (requestId: number) => Observable<RequestLog[]> = (requestId: number) => {
        return this.repo.getStipendRequestLog(requestId);
    };

    getReimbursementRequestLog: (requestId: number) => Observable<RequestLog[]> = (requestId: number) => {
        return this.repo.getReimbursementRequestLog(requestId);
    };

    deleteTravelRequest: (requestId: number) => Observable<SaveStatusResponse> = (requestId: number) => {
        return this.repo.deleteTravelRequest(requestId);
    };

    deleteStipendRequest: (requestId: number) => Observable<SaveStatusResponse> = (requestId: number) => {
        return this.repo.deleteStipendRequest(requestId);
    };

    deleteReimbursementRequest: (requestId: number) => Observable<SaveStatusResponse> = (requestId: number) => {
        return this.repo.deleteReimbursementRequest(requestId);
    };

    getMileageReimbursementRate: (countryCode: string, date: Date) => Observable<MileageReimbursementRate> = (
        countryCode: string,
        date: Date
    ) => {
        const isoDate = date.toISOString().split('T')[0]; // get only date from ISO format, without time
        return this.repo.getMileageReimbursementRate(countryCode, isoDate);
    };

    /**
     * Get a number of travel requests for study with Full Service
     * @param studyId Id of a study
     * @returns Count of all travel requests for given studyId
     */
    getTravelRequestCountFullService(studyId: number): Observable<number> {
        return this.getCacheActiveTravelRequestTypeCountByStudy(studyId).pipe(
            map((value: TravelRequestTypeCount) => {
                const countArray = Object.values(value);
                return countArray.reduce((acc: number, current: number) => acc + current);
            })
        );
    }

    /**
     * Check if study with Full Service tier can be changed to a study with Limited Service tier
     * @param studyId Id of a study
     * @returns true if tier can be changed to Limited Service, else false
     */
    canFullServiceBeChangedToLimitedService(studyId: number): Observable<boolean> {
        return this.getCacheActiveTravelRequestTypeCountByStudy(studyId).pipe(
            map((value: TravelRequestTypeCount) => {
                return value.flightCount + value.trainCount === 0;
            })
        );
    }

    private getCacheActiveTravelRequestTypeCountByStudy(studyId: number): Observable<TravelRequestTypeCount> {
        if (!this.cacheActiveTravelRequestTypeCountByStudy$) {
            this.cacheActiveTravelRequestTypeCountByStudy$ = this.repo
                .getActiveTravelRequestTypeCountByStudy(studyId)
                .pipe(shareReplay(1));
        }

        return this.cacheActiveTravelRequestTypeCountByStudy$;
    }

    clearCacheActiveTravelRequestTypeCountByStudy(): void {
        this.cacheActiveTravelRequestTypeCountByStudy$ = null;
    }

    /**
     * Get a number of travel requests for site
     * @param siteId Id of a site
     * @returns Count of all travel requests for given siteId
     */
    getTravelRequestCountSite(siteId: number): Observable<number> {
        return this.repo.getActiveTravelRequestTypeCountBySite(siteId).pipe(
            map((value: TravelRequestTypeCount) => {
                const countArray = Object.values(value);
                return countArray.reduce((acc: number, current: number) => acc + current);
            })
        );
    }

    uploadMileageReimbursementRates: (
        rates: MileageReimbursementRateData[]
    ) => Observable<MileageReimbursementRateData[]> = (rates: MileageReimbursementRateData[]) => {
        return this.repo.uploadMileageReimbursementRates(rates);
    };

    validateMileageReimbursementRates(rates: MileageReimbursementRateData[]): Observable<string[] | null> {
        return this.repo.validateMileageReimbursementRates(rates);
    }

    deleteTravelRequestCaregiver: (travelRequestId: number, caregiverId: number) => Observable<void> = (
        travelRequestId: number,
        caregiverId: number
    ) => {
        return this.repo.deleteTravelRequestCaregiver(travelRequestId, caregiverId);
    };
}
