<div class="caregiver-info-card">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-button
                    (clickEvent)="openCaregiverInfoDialog()"
                    variant="outline"
                    label="Add Caregiver"
                    [id]="'addCaregiverBtn'"
                    [width]="'100%'"
                ></mds-button>
            </div>
        </div>

        <div *ngIf="patient?.caregivers?.length > 0; else noCaregivers">
            <div *ngFor="let caregiver of patient?.caregivers; index as i">
                <div *ngIf="caregiver?.isPrimary" class="o-grid__item">
                    <div class="o-grid__row secondary-caregiver">
                        <div class="c-info__block">
                            <h5>Primary Caregiver</h5>

                            <div *ngIf="caregiver?.middleName" class="caregiver-info">
                                {{ caregiver?.firstName + ' ' + caregiver?.middleName + ' ' + caregiver?.lastName }}
                            </div>
                            <div *ngIf="!caregiver?.middleName" class="caregiver-info">
                                {{ caregiver?.firstName + ' ' + caregiver?.lastName }}
                            </div>
                            <div class="caregiver-info">{{ caregiver?.relationship }}</div>
                            <div class="caregiver-buttons">
                                <mds-button
                                    variant="outline"
                                    label="VIEW PROFILE"
                                    (clickEvent)="openCaregiverProfile(caregiver)"
                                    [id]="'viewProfileButton'"
                                ></mds-button>
                                <mds-button
                                    variant="outline"
                                    color="warn"
                                    label="REMOVE"
                                    (clickEvent)="removeCaregiver(caregiver)"
                                    [id]="'removeCaregiverButton'"
                                ></mds-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="expansion-panel" *ngIf="patient?.caregivers?.length > 1 || !patient?.caregivers[0]?.isPrimary">
                <mat-accordion>
                    <mds-expansion-panel
                        panelTitle="Other caregivers"
                        [expanded]="patient?.caregivers?.length === 2 || !patient?.caregivers[0].isPrimary"
                    >
                        <div *ngFor="let caregiver of patient?.caregivers; index as i">
                            <div *ngIf="!caregiver?.isPrimary" class="o-grid__item">
                                <div class="o-grid__row secondary-caregiver">
                                    <div class="c-info__block">
                                        <h5>Caregiver</h5>

                                        <div *ngIf="caregiver?.middleName" class="caregiver-info">
                                            {{
                                                caregiver?.firstName +
                                                    ' ' +
                                                    caregiver?.middleName +
                                                    ' ' +
                                                    caregiver?.lastName
                                            }}
                                        </div>
                                        <div *ngIf="!caregiver?.middleName" class="caregiver-info">
                                            {{ caregiver?.firstName + ' ' + caregiver?.lastName }}
                                        </div>
                                        <div class="caregiver-info">{{ caregiver?.relationship }}</div>
                                        <div class="caregiver-buttons">
                                            <mds-button
                                                variant="outline"
                                                label="VIEW PROFILE"
                                                (clickEvent)="openCaregiverProfile(caregiver)"
                                                [id]="'viewProfileButton'"
                                            ></mds-button>
                                            <mds-button
                                                variant="outline"
                                                label="MAKE PRIMARY"
                                                (clickEvent)="setCaregiverPrimary(caregiver)"
                                                [id]="'makePrimaryButton'"
                                            ></mds-button>
                                            <mds-button
                                                variant="outline"
                                                label="REMOVE"
                                                color="warn"
                                                (clickEvent)="removeCaregiver(caregiver)"
                                                [id]="'removeCaregiverButton'"
                                            ></mds-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mds-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <ng-template #noCaregivers>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <span class="no-caregivers-added"> No caregivers have been added for this patient</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>
