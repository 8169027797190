import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { MileageReimbursementRateData } from '@models/request';
import { ReimbursementRatesUploadDialogComponent } from '@request/modals/reimbursement-rates-upload/reimbursement-rates-upload-dialog/reimbursement-rates-upload-dialog.component';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';

@Component({
    selector: 'medpace-reimbursement-rates-upload',
    templateUrl: './reimbursement-rates-upload.component.html',
    styleUrls: ['./reimbursement-rates-upload.component.scss'],
})
export class ReimbursementRatesUploadComponent {
    constructor(
        public dialog: MatDialog,
        private adminRequestServices: AdminRequestServices,
        private snackbarService: SnackbarService
    ) {}

    openRatesUploadDialog(): void {
        const dialogRef = this.dialog.open<
            ReimbursementRatesUploadDialogComponent,
            null,
            MileageReimbursementRateData[]
        >(ReimbursementRatesUploadDialogComponent, {
            maxWidth: 550,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((rates) => {
            if (!!rates) {
                this.adminRequestServices.uploadMileageReimbursementRates(rates).subscribe(
                    (result) => {
                        this.snackbarService.openInfoSnackbar('Spreadsheet Uploaded!');
                    },
                    (error) => {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 350;
                        dialogConfig.minWidth = 350;

                        if (error.error === 'An Upload Mileage error has occured.') {
                            dialogConfig.data = {
                                title: 'Error',
                                bodyText: 'Spreadsheet failed to upload.',
                                showCancelButton: false,
                            };
                        } else {
                            dialogConfig.data = {
                                title: 'Error',
                                bodyText: 'Spreadsheet was not formatted correctly.',
                                showCancelButton: false,
                            };
                        }

                        const dialogRef = this.dialog.open(MedpaceMessageModalComponent, dialogConfig);

                        return dialogRef.afterClosed();
                    }
                );
            }
        });
    }
}
