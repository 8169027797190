import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, NgZone } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DownloadAttachmentModalComponent } from '@app/admin/manage/modals/download-attachment-modal/download-attachment-modal.component';
import { ExpenseTypes } from '@app/enums/ExpenseTypes';
import { FileType } from '@app/enums/FileTypes';
import { ReimbursementTypes } from '@app/enums/ReimbursementTypes';
import { RequestTypes } from '@app/enums/RequestTypes';
import {
    EditableCardComponent,
    EditableCardMode,
} from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { CountryViewModel } from '@models/country';
import { CurrencyViewModel } from '@models/currency';
import { Patient } from '@models/patient';
import {
    Expense,
    GenericRequestStatus,
    MileageReimbursementRate,
    Reimbursement,
    ReimbursementRequest,
    StipendRequest,
} from '@models/request';
import { User } from '@models/user';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { CurrencyStateService } from '@services/state-management/currency-state.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { RequestContextState, RequestStateService } from '@services/state-management/request-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { UserService } from '@services/user/user.service';
import { PersistentFormControl } from '@utility/persistent-forms';
import {
    PageMode,
    RequestPageMode,
    buildSnackBar,
    collectErrors,
    isTypeMileageExpense,
    parseDistanceUnit,
    serializeDistanceUnit,
} from '@utility/utility';
import { maxValidator, requiredAtLeastOneOptionArray } from '@utility/utility.validators';
import { Moment, utc } from 'moment';
import {
    BehaviorSubject,
    EMPTY,
    ReplaySubject,
    Subject,
    catchError,
    combineLatest,
    defer,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    ignoreElements,
    map,
    merge,
    mergeMap,
    of,
    shareReplay,
    skip,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom,
    zip,
} from 'rxjs';

interface State {
    reimbursementType: ReimbursementTypes;
    request: {
        requestContextState: RequestContextState;
        stipendRequest: StipendRequest;
        reimbursementRequest: ReimbursementRequest;
    };
    countries: CountryViewModel[];
    currencies: CurrencyViewModel[];
    patient: Patient;
    user: User;
}
function isInProcessOrHigher(requestStatus: GenericRequestStatus) {
    return [
        GenericRequestStatus.Completed,
        GenericRequestStatus.InProcess,
        GenericRequestStatus.PendingApprovel,
        GenericRequestStatus.Denied,
        GenericRequestStatus.Cancelled,
        GenericRequestStatus.PendingSetUp,
    ].includes(requestStatus);
}
@Component({
    selector: 'medpace-create-edit-request-expense-details',
    templateUrl: './medpace-create-edit-request-expense-details.component.html',
    styleUrls: ['./medpace-create-edit-request-expense-details.component.scss'],
})
export class MedpaceCreateEditRequestExpenseDetailsComponent extends EditableCardComponent<Reimbursement> {
    constructor(
        private fb: FormBuilder,
        private requestStateService: RequestStateService,
        private countryStateService: CountryStateService,
        private currencyStateService: CurrencyStateService,
        private sanitizer: DomSanitizer,
        private patientStateService: PatientStateService,
        private adminRequestService: AdminRequestServices,
        private userService: UserService,
        private dialog: MatDialog,
        private googleMapsService: GoogleMapsService,
        private siteStateService: SiteStateService,
        private ngZone: NgZone,
        private activeRoute: ActivatedRoute
    ) {
        super();
    }
    @Input() showSwitchModeButton: boolean = true;
    @Input() expenseOptions: MdsOption[] = [];
    public GenericRequestStatus = GenericRequestStatus;
    getCountry(countryCode: string) {
        return this.countryStateService
            .getCountryByCodeOrName(countryCode)
            .pipe(map((country) => country.value as string));
    }
    isInProcessOrHigher = isInProcessOrHigher;
    public readonly RequestTypes = RequestTypes;
    public readonly ReimbursementTypes = ReimbursementTypes;
    public readonly PageMode = PageMode;
    public readonly reimbursementType_RadioButtons: MdsRadioButton[] = [
        {
            label: 'Stipend',
            value: ReimbursementTypes.Stipend,
            name: 'request-expense-card-stipend',
            id: 'request-expense-card-stipend',
        },
        {
            label: 'Out of Pocket Reimbursement',
            value: ReimbursementTypes.OutOfPocket,
            name: 'request-expense-card-reimburse',
            id: 'request-expense-card-reimburse',
        },
    ];
    public readonly distanceUnit_RadioButtons: MdsRadioButton[] = [
        {
            label: 'Miles',
            value: 'Miles',
            name: 'distanceUnit_Miles',
            id: 'distanceUnit_Miles',
        },
        {
            label: 'Kilometers',
            value: 'Kilometers',
            name: 'distanceUnit_Kilometers',
            id: 'distanceUnit_Kilometers',
        },
    ];

    public readonly acceptableFileTypes: string[] = [FileType.PDF, FileType.JPG, FileType.JPEG, FileType.PNG];
    public readonly formGroup = new FormGroup({
        reimbursementType: new FormControl<ReimbursementTypes>(null, [Validators.required]),
        totalAmount: new FormControl<string>('', [Validators.required, Validators.min(0), maxValidator(2e6)]),
        currency: new FormControl<string>('', [Validators.required]),
        expenseControls: new FormArray<AbstractControl>([]),
        changedPaymentType: new FormControl<boolean>(false),
    });
    public addExpenseSubject = new Subject<Expense>();
    public mileageExpenseHandlersSubject = new Subject<MileageExpenseHandler>();
    public otherExpenseHandlersSubject = new Subject<OtherExpenseHandler>();
    private expenseHandlersSubject = new BehaviorSubject<ExpenseHandler[]>(null);
    private expenseHandlersState$ = this.expenseHandlersSubject.pipe(filter((handlers) => !!handlers));
    public mode$ = merge(
        this.editableCardSubject.pipe(switchMap((editableCard) => editableCard.getMode())),
        this.requestStateService.getState().pipe(
            map((state) => {
                if (RequestPageMode(this.activeRoute) === PageMode.CREATE) return 'edit';
                else return 'view';
            })
        )
    ).pipe(distinctUntilChanged());

    public radioButtonState$ = combineLatest([this.mode$, this.siteStateService.getSite()])
        .pipe(
            map(([cardMode, site]) => {
                return {
                    mode: cardMode,
                    isStipendAllowed: site?.services?.stipend,
                    isReimbursementAllowed: site?.services?.reimbursement,
                };
            })
        )
        .pipe(
            distinctUntilChanged(),
            tap((radioState) => {
                this.reimbursementType_RadioButtons.forEach((button) => {
                    button.disabled = radioState.mode === 'view';

                    if (!radioState.isStipendAllowed && button.name.includes('stipend')) {
                        button.disabled = true;
                    }
                    if (!radioState.isReimbursementAllowed && button.name.includes('reimburse')) {
                        button.disabled = true;
                    }
                });
            })
        );
    public reimbursementType$ = merge(
        this.requestStateService.getState().pipe(map((state) => state.reimbursementType)),
        this.formGroup.controls.reimbursementType.valueChanges.pipe(filter((value) => !!value))
    ).pipe(
        distinctUntilChanged(),
        tap((reimbursmentType: ReimbursementTypes) => {
            //All controls except expenseControls are the same for Stipend and Out-of-pocket requests
            if (reimbursmentType === ReimbursementTypes.OutOfPocket) {
                //enable expenseControls
                this.formGroup.controls.expenseControls.enable();
            } else if (reimbursmentType === ReimbursementTypes.Stipend) {
                //disable expenseControls
                this.formGroup.controls.expenseControls.disable();
            }
        })
    );

    public paymentRequestType$ = combineLatest([
        this.formGroup.controls.reimbursementType.valueChanges,
        this.requestStateService.getRequest().pipe(map((state) => state?.paymentType)),
    ]).pipe(
        tap(([radioButton, requestState]) => {
            if (!!radioButton && !!requestState) {
                this.formGroup.controls.changedPaymentType.setValue(radioButton !== requestState);
            }
        })
    );

    public state$ = combineLatest({
        reimbursementType: this.reimbursementType$,
        request: zip(
            this.requestStateService.getState(),
            this.requestStateService.stipendRequestStateService.getState(),
            this.requestStateService.reimbursementRequestStateService.getState()
        ).pipe(
            map(([requestContextState, stipendRequest, reimbursementRequest]) => {
                return {
                    requestContextState,
                    stipendRequest,
                    reimbursementRequest,
                };
            })
        ),
        countries: this.countryStateService.getCountries().pipe(take(1)),
        currencies: this.currencyStateService.getCurrencies().pipe(take(1)),
        patient: this.patientStateService.getSummaryPatientData().pipe(
            filter((patient) => !!patient),
            distinctUntilKeyChanged('id')
        ),
        user: this.userService.getUser(),
    }).pipe(distinctUntilChanged(), shareReplay(1));
    public vm$ = this.state$.pipe(
        tap((state) => {
            let patientPreferredCurrency = state.patient.preferredCurrency;
            let currency: string;
            let totalAmount = 0;
            if (
                RequestPageMode(this.activeRoute) === PageMode.CREATE ||
                (RequestPageMode(this.activeRoute) === PageMode.EDIT &&
                    (!state.reimbursementType ||
                        (state.reimbursementType === ReimbursementTypes.OutOfPocket &&
                            !state.request.reimbursementRequest) ||
                        (state.reimbursementType === ReimbursementTypes.Stipend && !state.request.stipendRequest)))
            ) {
                currency = patientPreferredCurrency;
                totalAmount = 0;
            } else if (state.reimbursementType === ReimbursementTypes.OutOfPocket) {
                const c = state.currencies.find(
                    (currency) => currency.isocode === state.request.reimbursementRequest.totalCurrency
                );
                currency = c ? c.value : null;
                totalAmount = state.request.reimbursementRequest.reimbursementTransactions.reduce(
                    (acc: number, curr: Expense) => acc + curr.authorizedAmount,
                    0
                );
            } else if (state.reimbursementType === ReimbursementTypes.Stipend) {
                const c = state.currencies.find(
                    (currency) => currency.isocode === state.request.stipendRequest.currency
                );
                currency = c ? c.value : null;
                totalAmount = state.request.stipendRequest.amount ?? 0;
            }
            this.formGroup.patchValue(
                {
                    currency: currency,
                    reimbursementType: state.reimbursementType,
                    totalAmount: totalAmount.toFixed(2),
                    expenseControls: [],
                },
                { emitEvent: false }
            );
        }),
        tap((state) => {
            if (state.reimbursementType === ReimbursementTypes.OutOfPocket) {
                this.formGroup.controls.totalAmount.disable({ emitEvent: false });
            } else if (state.reimbursementType === ReimbursementTypes.Stipend) {
                this.formGroup.controls.totalAmount.enable({ emitEvent: false });
            }
        }),
        switchMap((state) => {
            return combineLatest({
                state: of(state).pipe(
                    map((state) => {
                        const transactionDate = !!state.request.reimbursementRequest
                            ? state.request.reimbursementRequest.transactionDate
                            : !!state.request.stipendRequest
                              ? state.request.stipendRequest.transactionDate
                              : utc();

                        return { ...state, transactionDate };
                    })
                ),
                reimbursementVM: combineLatest({
                    actions: merge(this.totalAmountChange$.pipe(ignoreElements(), startWith(null))),
                    expenseHandlers: this.expenseHandlers$,
                }).pipe(startWith(null)),
                stipendVM: combineLatest({}).pipe(startWith(null)),
            });
        }),
        shareReplay(1)
    );

    parseDistanceUnit = parseDistanceUnit;

    private createExpenseHandler(expense: Expense, state: State): ExpenseHandler {
        if (isTypeMileageExpense(expense.category)) {
            const mileageExpenseHandler = new MileageExpenseHandler(
                this.fb,
                expense,
                this.expenseOptions,
                state,
                this.sanitizer,
                this.adminRequestService,
                this.formGroup.controls.currency,
                this.userService,
                this.googleMapsService,
                this.ngZone,
                this.formGroup.controls.expenseControls,
                this.requestStateService
            );
            this.mileageExpenseHandlersSubject.next(mileageExpenseHandler);
            return mileageExpenseHandler;
        } else {
            const otherExpenseHandler = new OtherExpenseHandler(
                this.fb,
                expense,
                this.expenseOptions,
                this.sanitizer,
                this.userService,
                this.requestStateService,
                this.formGroup.controls.currency
            );
            this.otherExpenseHandlersSubject.next(otherExpenseHandler);
            return otherExpenseHandler;
        }
    }
    public createExpenseHandlers$ = this.state$.pipe(
        filter((state) => state.reimbursementType === ReimbursementTypes.OutOfPocket),
        map((state) => {
            const expenses = state.request.reimbursementRequest
                ? state.request.reimbursementRequest.reimbursementTransactions
                : [
                      new Expense({
                          amount: 0,
                      }),
                  ];
            return {
                expenses,
                state,
            };
        }),
        map((result) => {
            return result.expenses.map(
                (expense) => this.createExpenseHandler(new Expense(expense), result.state) // create a copy of an Expense so as not to modify the state
            );
        })
    );

    public addExpenseHandler$ = this.addExpenseSubject.pipe(
        withLatestFrom(this.state$),
        map((result) => this.createExpenseHandler(result[0], result[1])),
        withLatestFrom(this.expenseHandlersState$),
        map((result) => result[1].concat(result[0]))
    );
    public removeExpenseHandler$ = merge(
        this.mileageExpenseHandlersSubject.pipe(
            mergeMap((handler) => handler.remove$.pipe(map((_) => handler as ExpenseHandler)))
        ),
        this.otherExpenseHandlersSubject.pipe(
            mergeMap((handler) => handler.remove$.pipe(map((_) => handler as ExpenseHandler)))
        )
    ).pipe(
        withLatestFrom(this.expenseHandlersState$),
        map((result) => result[1].filter((handler) => handler !== result[0]))
    );
    public changeExpenseHandlerCategory$ = merge(
        this.mileageExpenseHandlersSubject.pipe(
            mergeMap((handler) =>
                handler.formGroup.controls.category.valueChanges.pipe(
                    map((value) => {
                        return { value, handler: handler as ExpenseHandler };
                    })
                )
            )
        ),
        this.otherExpenseHandlersSubject.pipe(
            mergeMap((handler) =>
                handler.formGroup.controls.category.valueChanges.pipe(
                    map((value) => {
                        return { value, handler: handler as ExpenseHandler };
                    })
                )
            )
        )
    ).pipe(
        withLatestFrom(this.expenseHandlersState$, this.state$),
        map((result) => {
            const index = result[1].indexOf(result[0].handler);
            const expense = result[1][index].expense;
            const prevCategory = expense.category;
            const newCategory = result[0].value;
            if (
                (isTypeMileageExpense(prevCategory) && !isTypeMileageExpense(newCategory)) ||
                (!isTypeMileageExpense(prevCategory) && isTypeMileageExpense(newCategory))
            ) {
                expense.category = newCategory;
                result[1][index] = this.createExpenseHandler(expense, result[2]);
            }
            return result[1];
        })
    );
    public expenseHandlers$ = merge(
        this.addExpenseHandler$,
        this.removeExpenseHandler$,
        this.changeExpenseHandlerCategory$,
        this.createExpenseHandlers$ // must be the last one to subscribe to
    ).pipe(
        tap((handlers) => {
            this.formGroup.controls.expenseControls.clear();
            this.formGroup.controls.expenseControls.setValidators(requiredAtLeastOneOptionArray());
            handlers.forEach((handler) => this.formGroup.controls.expenseControls.push(handler.getFormGroup()));
        }),
        tap((handlers) => this.expenseHandlersSubject.next(handlers))
    );
    public totalAmountChange$ = merge(
        this.mileageExpenseHandlersSubject.pipe(
            // sum authorizedAmount fields
            mergeMap((handler) =>
                handler.formGroup.controls.authorizedAmount.valueChanges.pipe(map((value) => handler as ExpenseHandler))
            )
        ),
        this.otherExpenseHandlersSubject.pipe(
            mergeMap((handler) =>
                handler.formGroup.controls.authorizedAmount.valueChanges.pipe(map((value) => handler as ExpenseHandler))
            )
        ),
        this.expenseHandlersState$
    ).pipe(
        withLatestFrom(this.expenseHandlersState$),
        tap((result) => {
            this.formGroup.controls.totalAmount.setValue(
                result[1]
                    .reduce((acc: number, curr: ExpenseHandler) => {
                        // sum authorized amount
                        return acc + curr.getAuthorizedAmount();
                    }, 0)
                    .toFixed(2),
                { emitEvent: false }
            );
        })
    );
    private downloadAttachmentSubject = new Subject<number>();
    public downloadAttachment$ = this.downloadAttachmentSubject.pipe(
        withLatestFrom(this.state$),
        filter((result) => result[1].reimbursementType === ReimbursementTypes.OutOfPocket), // attachments are only available for reimbursementrequests
        switchMap((result) =>
            this.adminRequestService
                .getReimbursementRequestAttachmentUri(result[1].request.reimbursementRequest.id, result[0])
                .pipe(
                    map((url) => {
                        return { url, state: result[1], attachmentId: result[0] };
                    })
                )
        ),
        tap((result) =>
            this.openAttachmentDownloadModal(
                result.url.toString(),
                `${result.state.patient.patientIdentification.firstName}-${result.state.patient.patientIdentification.lastName}-expense-attachment`
            )
        )
    );
    protected onAddExpenseClick() {
        this.addExpenseSubject.next(
            new Expense({
                amount: 0,
            })
        );
    }
    public toMileageExpenseVM(item: ExpenseVM<MileageExpenseHandler>): ExpenseVM<MileageExpenseHandler> {
        return item;
    }
    public toOtherExpenseVM(item: ExpenseVM<OtherExpenseHandler>): ExpenseVM<OtherExpenseHandler> {
        return item;
    }
    protected override canChangeMode(mode: EditableCardMode): boolean {
        return mode === 'edit' || (mode === 'view' && this.formGroup.valid);
    }

    protected override onModeChangeFail(mode: EditableCardMode): void {
        if (mode === 'view') {
            this.formGroup.markAllAsTouched();
            buildSnackBar(collectErrors(this.formGroup), this.snackbarService);
        }
    }
    public getOutputData(): Reimbursement {
        const value = this.formGroup.getRawValue();
        return new Reimbursement({
            type: value.reimbursementType,
            currency: value.currency,
            totalAmount: +value.totalAmount,
            expenses:
                value.reimbursementType === ReimbursementTypes.OutOfPocket
                    ? this.expenseHandlersSubject.getValue()?.map((handler) => handler.expense)
                    : null,
            transactionDate: utc(),
            changedRequestType: value.changedPaymentType,
        });
    }

    public onAttachmentDownloadClick(attachmentId: number) {
        this.downloadAttachmentSubject.next(attachmentId);
    }

    private openAttachmentDownloadModal(dataSourceUrl: string, fileName: string): void {
        this.dialog.open(DownloadAttachmentModalComponent, {
            data: { dataSourceUrl, attachmentName: fileName },
            autoFocus: false,
        });
    }
    isExpenseMileage(value: string): boolean {
        return isTypeMileageExpense(value);
    }

    switchModeButtonAvailable(vm: any): boolean {
        return (
            !!vm.value &&
            this.showSwitchModeButton &&
            vm.value.state.request.requestContextState.requestStatus !== GenericRequestStatus.Completed &&
            (vm.value.state.user.isAdmin ||
                vm.value.state.user.isSuperAdmin ||
                vm.value.state.request.requestContextState.requestStatus === GenericRequestStatus.Draft ||
                vm.value.state.request.requestContextState.requestStatus === GenericRequestStatus.Submitted ||
                vm.value.state.request.requestContextState.requestStatus ===
                    GenericRequestStatus.PendingSiteVerification)
        );
    }
}
export interface ExpenseVM<T extends ExpenseHandler> {
    expenseHandler: T;
    index: number;
    state: State;
}
export abstract class ExpenseHandler {
    constructor(
        public readonly expense: Expense,
        public readonly expenseTypes: MdsOption[],
        protected sanitizer: DomSanitizer
    ) {}
    protected removeSubject = new Subject<boolean>();
    public readonly remove$ = this.removeSubject.asObservable();
    protected fileSubject = new ReplaySubject<File>();
    public readonly fileUpload$ = this.fileSubject.pipe(
        filter((file) => !!file),
        tap((file) => {
            this.expense.attachment = file;
        })
    );
    public onRemoveExpenseClick() {
        this.removeSubject.next(true);
    }
    public onFileUploaded(file: File) {
        this.fileSubject.next(file);
    }
    public abstract getRequestedAmount(): number;
    public abstract getAuthorizedAmount(): number;
    public abstract getFormGroup(): AbstractControl;
    setCategory(category: string): string {
        if (!!category) {
            const expenses = this.expenseTypes?.map((val: MdsOption): string => val.viewValue);
            if (expenses.includes(category)) {
                //if category in defined expenses, return that category
                return category;
            } else if (isTypeMileageExpense(category)) {
                //if mileage in expenses, but writted differently
                // (i.e. category: 'Mileage (mi/km)', in expenses: 'Mileage'), return mileage in expenses
                const expense = expenses.find((x) => isTypeMileageExpense(x));
                if (!!expense) {
                    return expense;
                }
            }
            return ExpenseTypes.Other;
        } else {
            //if no category, return ''
            return ExpenseTypes.None;
        }
    }
}
export class MileageExpenseHandler extends ExpenseHandler {
    constructor(
        protected fb: FormBuilder,
        public readonly expense: Expense,
        public readonly expenseTypes: MdsOption[],
        protected readonly state: State,
        protected sanitizer: DomSanitizer,
        private adminRequestService: AdminRequestServices,
        private currencyControl: FormControl<string>,
        private userService: UserService,
        private googleMapsService: GoogleMapsService,
        private ngZone: NgZone,
        private mainFormArray: FormArray,
        private requestStateService: RequestStateService
    ) {
        super(expense, expenseTypes, sanitizer);
        const country = this.state.countries.find((c) => c.countryCode === this.expense.mileageReimbursementCountry);
        this.formGroup.setValue(
            {
                category: this.setCategory(this.expense.category),
                fromLocation: this.expense.mileageStartLocation || '',
                toLocation: this.expense.mileageEndLocation || '',
                country: country?.viewValue || this.expense.mileageReimbursementCountry || '',
                reimbursementRate: this.expense.mileageReimbursementRatePerUnit || null,
                currency: this.expense.currency || null,
                totalDistance: (this.expense.mileageTotalDistance || 0).toString(),
                distanceUnit: parseDistanceUnit(this.expense.mileageDistanceUnits),
                timestamp: this.expense.timestamp || null,
                comment: this.expense.mileageComments || '',
                requestedAmount: (this.expense.amount ?? 0).toFixed(2),
                authorizedAmount: (this.expense.authorizedAmount ?? 0).toFixed(2),
                notes: this.expense.notes ?? '',
                manualTotalCalculation: false,
            },
            { emitEvent: false }
        );
    }

    requestStatus$ = this.requestStateService.getState().pipe(map((state) => state.requestStatus));
    public readonly initGoogleMapsService$ = this.googleMapsService.observePlaceListener().pipe(
        tap((place) => {
            if (place) this.setAddressControls(place);
        })
    );

    public readonly initFormGroup$ = combineLatest({ user: this.userService.getUser() }).pipe(
        take(1),
        tap((result) => {
            const country = this.state.countries.find(
                (c) => c.countryCode === this.expense.mileageReimbursementCountry
            );
            this.formGroup.setValue({
                category: this.setCategory(this.expense.category),
                fromLocation: this.expense.mileageStartLocation || '',
                toLocation: this.expense.mileageEndLocation || '',
                country: country?.viewValue || this.expense.mileageReimbursementCountry || '',
                reimbursementRate: this.expense.mileageReimbursementRatePerUnit || null,
                currency: this.expense.currency || null,
                totalDistance: (this.expense.mileageTotalDistance || 0).toString(),
                distanceUnit: parseDistanceUnit(this.expense.mileageDistanceUnits),
                timestamp: this.expense.timestamp || null,
                comment: this.expense.mileageComments || '',
                requestedAmount: (this.expense.amount ?? 0).toFixed(2),
                authorizedAmount: (this.expense.authorizedAmount ?? 0).toFixed(2),
                notes: this.expense.notes ?? '',
                manualTotalCalculation:
                    (result.user.isAdmin || result.user.isSuperAdmin) &&
                    this.expense.manualTotalCalculation !== undefined
                        ? this.expense.manualTotalCalculation
                        : false,
            });

            this.setEventListenerForAddressControl();
        })
    );

    locationInput(id: string, count: number) {
        this.googleMapsService.setActiveField(id);
        this.googleMapsService.setActiveCount(count);
    }
    public readonly formGroup = new FormGroup({
        category: new FormControl<string>(ExpenseTypes.None, [Validators.required]),
        fromLocation: new FormControl<string>('', [Validators.required, Validators.maxLength(500)]),
        toLocation: new FormControl<string>('', [Validators.required, Validators.maxLength(500)]),
        country: new FormControl<string>('', [Validators.required]),
        reimbursementRate: new FormControl<number>(null, [Validators.required, Validators.min(0), maxValidator(1e9)]),
        currency: new FormControl<string>({ value: null, disabled: true }, [Validators.required]),
        totalDistance: new FormControl<string>(null, [Validators.required, Validators.min(1), maxValidator(1e6)]),
        distanceUnit: new FormControl<DistanceUnit>(null, [Validators.required]),
        timestamp: new PersistentFormControl<Moment>(null, [Validators.required]),
        comment: new FormControl<string>('', [Validators.maxLength(500)]),
        requestedAmount: new FormControl<string>(null, [Validators.required, Validators.min(0.01), maxValidator(2e6)]),
        authorizedAmount: new PersistentFormControl<string>(null, [
            Validators.required,
            Validators.min(0),
            maxValidator(2e6),
        ]),
        notes: new PersistentFormControl<string>('', [Validators.maxLength(500)]),
        manualTotalCalculation: new FormControl<boolean>(null),
    });
    public readonly formChange$ = this.formGroup.valueChanges.pipe(
        tap((_) => {
            const value = this.formGroup.getRawValue(); // include disabled values
            if (value.requestedAmount !== undefined) this.expense.amount = +value.requestedAmount;
            if (value.authorizedAmount !== undefined) this.expense.authorizedAmount = +value.authorizedAmount;
            if (value.notes !== undefined) this.expense.notes = value.notes;
            if (value.category !== undefined) this.expense.category = value.category;
            if (value.fromLocation !== undefined) this.expense.mileageStartLocation = value.fromLocation;
            if (value.toLocation !== undefined) this.expense.mileageEndLocation = value.toLocation;
            if (value.totalDistance !== undefined) this.expense.mileageTotalDistance = +value.totalDistance;
            if (value.distanceUnit !== undefined)
                this.expense.mileageDistanceUnits = serializeDistanceUnit(value.distanceUnit);
            if (value.country !== undefined) {
                const country = this.state.countries.find((c) => c.value === value.country);
                this.expense.mileageReimbursementCountry = country?.countryCode || value.country;
            }
            if (value.timestamp !== undefined) {
                this.expense.timestamp = value.timestamp;
            }
            if (value.comment !== undefined) this.expense.mileageComments = value.comment;
            if (value.currency !== undefined) this.expense.currency = value.currency;
            if (value.reimbursementRate !== undefined)
                this.expense.mileageReimbursementRatePerUnit = value.reimbursementRate;
            if (value.manualTotalCalculation !== undefined)
                this.expense.manualTotalCalculation = value.manualTotalCalculation;
        })
    );
    isInProcessOrHigher = isInProcessOrHigher;
    public readonly authorizedAmountEnabled$ = defer(() => {
        return combineLatest({
            state: this.requestStateService.getState(),
            user: this.userService.getUser(),
        }).pipe(
            tap(({ state, user }) => {
                const requestStatusInProcessOrHigher = this.isInProcessOrHigher(state.requestStatus);
                const isUserAdmin = user.isAdmin || user.isSuperAdmin;
                if (requestStatusInProcessOrHigher && isUserAdmin) {
                    this.formGroup.controls.authorizedAmount.enable({ force: true });
                    this.formGroup.controls.notes.enable({ force: true });
                } else {
                    this.formGroup.controls.authorizedAmount.disable({ persistent: true });
                    this.formGroup.controls.notes.disable({ persistent: true });
                }
            })
        );
    });
    public readonly copyRequestedAmountToAuthorizedAmount$ = defer(() => {
        return this.formGroup.controls.requestedAmount.valueChanges.pipe(
            tap((requestedAmount) => {
                this.formGroup.controls.authorizedAmount.setValue((+requestedAmount).toFixed(2));
            })
        );
    });
    private readonly countryChange$ = defer(() =>
        this.formGroup.controls.country.valueChanges.pipe(startWith(this.formGroup.controls.country.value))
    );
    private readonly currencyChange$ = defer(() =>
        this.formGroup.controls.currency.valueChanges.pipe(startWith(this.formGroup.controls.currency.value))
    );
    public readonly sharedCurrencyChange$ = defer(() =>
        this.currencyControl.valueChanges.pipe(
            startWith(this.currencyControl.value),
            tap((value) => this.formGroup.controls.currency.setValue(value))
        )
    );
    public readonly manualTotalCalculationChange$ = defer(() =>
        this.formGroup.controls.manualTotalCalculation.valueChanges.pipe(
            startWith(this.formGroup.controls.manualTotalCalculation.value)
        )
    );
    private readonly reimbursementRateChange$ = defer(() =>
        this.formGroup.controls.reimbursementRate.valueChanges.pipe(
            startWith(this.formGroup.controls.reimbursementRate.value),
            tap((rate) => {
                if (typeof rate === 'string') return; // <mds-text-field> with [inputType]="'decimal'" emits the current value as a string when losing focus,  don't know why. Ignore this case
                const currentMileageReimbursementRate = this.currentMileageReimbursementRateSubject.getValue();
                if (
                    currentMileageReimbursementRate === null ||
                    currentMileageReimbursementRate.mileageReimbursementRatePerUnit !== rate
                ) {
                    // changed by user
                    this.formGroup.controls.distanceUnit.enable();
                }
            })
        )
    );
    private readonly totalDistanceChange$ = defer(() =>
        this.formGroup.controls.totalDistance.valueChanges.pipe(
            startWith(this.formGroup.controls.totalDistance.value),
            map((distance) => +distance)
        )
    );
    private readonly isIncompleteSubject = new ReplaySubject<boolean>();
    public readonly isIncomplete$ = combineLatest({
        isIncomplete: this.isIncompleteSubject.pipe(startWith(this.expense.isIncomplete)),
        manualTotalCalculation: this.manualTotalCalculationChange$,
        rate: this.reimbursementRateChange$,
    }).pipe(
        filter((result) => result.isIncomplete !== null),
        map(
            (result) =>
                result.isIncomplete === true &&
                (result.manualTotalCalculation === false || result.manualTotalCalculation == null) &&
                !result.rate &&
                result.rate !== 0
        ),
        distinctUntilChanged(),
        tap((isIncomplete) => (this.expense.isIncomplete = isIncomplete))
    );
    public readonly reimbursementRate$ = defer(() => {
        return combineLatest({
            country: this.countryChange$,
            currency: this.currencyChange$,
            receiptDate: this.formGroup.controls.timestamp.valueChanges.pipe(
                startWith(this.formGroup.controls.timestamp.value)
            ),
        }).pipe(
            skip(1), // skip first emission that comes after formgroup init data
            filter((result) => !!result.country && !!result.currency && !!result.receiptDate),
            distinctUntilChanged(),
            switchMap((result) => {
                const countryCode: string | undefined = this.state.countries.find(
                    (country) => country.viewValue === result.country
                )?.countryCode;
                if (!countryCode) return EMPTY;
                return this.adminRequestService
                    .getMileageReimbursementRate(countryCode, result.receiptDate.toDate())
                    .pipe(
                        map((rate) => {
                            const rateUnavailable = !rate || rate.currencyCode !== result.currency;
                            this.isIncompleteSubject.next(rateUnavailable);
                            return !rateUnavailable
                                ? rate
                                : new MileageReimbursementRate({
                                      countryCode: this.state.countries.find(
                                          (country) => country.viewValue === result.country
                                      )?.countryCode,
                                      currencyCode: result.currency,
                                      effectiveDate: null,
                                      id: null,
                                      mileageDistanceUnits: null,
                                      mileageReimbursementRatePerUnit: null,
                                  });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            const rateNotFound = error.status === 404;
                            this.isIncompleteSubject.next(rateNotFound);
                            return !rateNotFound
                                ? EMPTY
                                : of(
                                      new MileageReimbursementRate({
                                          countryCode: this.state.countries.find(
                                              (country) => country.viewValue === result.country
                                          )?.countryCode,
                                          currencyCode: result.currency,
                                          effectiveDate: null,
                                          id: null,
                                          mileageDistanceUnits: null,
                                          mileageReimbursementRatePerUnit: null,
                                      })
                                  );
                        })
                    );
            }),
            tap((rate) => this.currentMileageReimbursementRateSubject.next(rate)),
            tap((rate) => {
                const distanceUnit = parseDistanceUnit(rate.mileageDistanceUnits);
                this.formGroup.patchValue({
                    reimbursementRate: rate.mileageReimbursementRatePerUnit,
                    distanceUnit: distanceUnit,
                });

                if (distanceUnit) this.formGroup.controls.distanceUnit.disable();
                else this.formGroup.controls.distanceUnit.enable();
            })
        );
    });
    private currentMileageReimbursementRateSubject = new BehaviorSubject<MileageReimbursementRate | null>(null);

    public readonly totalReimbursement$ = combineLatest({
        reimbursementRate: this.reimbursementRateChange$,
        totalDistance: this.totalDistanceChange$,
        manualTotalCalculationChange: this.manualTotalCalculationChange$,
    }).pipe(
        filter(
            (result) =>
                !result.manualTotalCalculationChange &&
                result.totalDistance !== null &&
                result.totalDistance !== undefined
        ),
        tap((result) => {
            const total =
                result.reimbursementRate !== null && result.reimbursementRate !== undefined
                    ? result.reimbursementRate * result.totalDistance
                    : 0;
            this.formGroup.controls.requestedAmount.patchValue(total.toFixed(2));
        })
    );
    private readonly manualTotalSelection_Enabled$ = combineLatest({
        country: this.countryChange$,
        currency: this.currencyChange$,
        user: this.userService.getUser(),
    }).pipe(
        map((result) => (result.user.isAdmin || result.user.isSuperAdmin) && !!result.currency && !!result.country),
        tap((enabled) =>
            enabled
                ? this.formGroup.controls.manualTotalCalculation.enable({ emitEvent: false })
                : this.formGroup.controls.manualTotalCalculation.disable({ emitEvent: false })
        )
    );
    private readonly reimbursementRate_Enabled$ = combineLatest({
        country: this.countryChange$,
        currency: this.currencyChange$,
        manualTotalCalculation: this.manualTotalCalculationChange$,
        user: this.userService.getUser(),
    }).pipe(
        map(
            (result) =>
                (result.user.isAdmin || result.user.isSuperAdmin) &&
                result.manualTotalCalculation === false &&
                !!result.currency &&
                !!result.country
        ),
        tap((enabled) =>
            enabled
                ? this.formGroup.controls.reimbursementRate.enable({ emitEvent: false })
                : this.formGroup.controls.reimbursementRate.disable({ emitEvent: false })
        )
    );
    private readonly totalDistance_Enabled$ = combineLatest({
        country: this.countryChange$,
        currency: this.currencyChange$,
    }).pipe(
        map((result) => !!result.currency && !!result.country),
        tap((enabled) =>
            enabled
                ? this.formGroup.controls.totalDistance.enable({ emitEvent: false })
                : this.formGroup.controls.totalDistance.disable({ emitEvent: false })
        )
    );
    private readonly distanceUnit_Enabled$ = combineLatest({
        country: this.countryChange$,
        currency: this.currencyChange$,
    }).pipe(
        map((result) => !!result.currency && !!result.country),
        tap((enabled) =>
            enabled
                ? this.formGroup.controls.distanceUnit.enable({ emitEvent: false })
                : this.formGroup.controls.distanceUnit.disable({ emitEvent: false })
        )
    );
    private readonly reimbursementTotal_Enabled$ = combineLatest({
        country: this.countryChange$,
        currency: this.currencyChange$,
        manualTotalCalculation: this.manualTotalCalculationChange$,
    }).pipe(
        map((result) => result.manualTotalCalculation === true && !!result.currency && !!result.country),
        tap((enabled) =>
            enabled
                ? this.formGroup.controls.requestedAmount.enable({ emitEvent: false })
                : this.formGroup.controls.requestedAmount.disable({ emitEvent: false })
        )
    );
    public readonly formEnabled$ = merge(
        this.manualTotalSelection_Enabled$,
        this.reimbursementRate_Enabled$,
        this.totalDistance_Enabled$,
        this.distanceUnit_Enabled$,
        this.reimbursementTotal_Enabled$
    );
    public readonly filteredCountries$ = this.countryChange$.pipe(
        filter(Boolean),
        map((value) =>
            this.state.countries
                .map((country) => country.viewValue)
                .filter((country) => country.toLowerCase().startsWith(value.toLowerCase()))
        ),
        startWith(this.state.countries.map((country) => country.viewValue))
    );
    public getRequestedAmount(): number {
        return +this.formGroup.controls.requestedAmount.value;
    }
    public getAuthorizedAmount(): number {
        return +this.formGroup.controls.authorizedAmount.value;
    }
    public getFormGroup(): AbstractControl {
        return this.formGroup;
    }

    private setEventListenerForAddressControl() {
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            const mdsFromLocationComponent = document.querySelectorAll('mds-text-field > #FromLocationField');
            const mdsToLocationComponent = document.querySelectorAll('mds-text-field > #ToLocationField');

            let inputField: HTMLInputElement;

            mdsFromLocationComponent.forEach((component) => {
                inputField = component?.querySelector('input') as HTMLInputElement | null;
                if (inputField) this.googleMapsService.setPlaceListener(inputField);
            });

            mdsToLocationComponent.forEach((component) => {
                inputField = component?.querySelector('input') as HTMLInputElement | null;
                if (inputField) this.googleMapsService.setPlaceListener(inputField);
            });
        });
    }

    private setAddressControls(placeResult: google.maps.places.PlaceResult) {
        const country = this.googleMapsService.getAddressComponent('country', placeResult.address_components);
        const formattedAddress = placeResult.formatted_address;

        combineLatest([this.googleMapsService.getFieldName(), this.googleMapsService.getCount()])
            .pipe(
                tap(([fieldName, count]) => {
                    let activeFormGroup = this.mainFormArray.controls[count] as FormGroup;

                    fieldName === 'fromLocationField'
                        ? activeFormGroup.controls.fromLocation.setValue(formattedAddress)
                        : activeFormGroup.controls.toLocation.setValue(formattedAddress);

                    const countryObj = country || '';
                    activeFormGroup.controls.country.setValue(countryObj);
                }),
                take(1)
            )
            .subscribe();
    }
}
export class OtherExpenseHandler extends ExpenseHandler {
    constructor(
        protected fb: FormBuilder,
        public readonly expense: Expense,
        public readonly expenseTypes: MdsOption[],
        protected sanitizer: DomSanitizer,
        private userService: UserService,
        private requestStateService: RequestStateService,
        private currencyControl: FormControl<string>
    ) {
        super(expense, expenseTypes, sanitizer);
        this.formGroup.setValue(
            {
                category: this.setCategory(expense.category),
                requestedAmount: (expense.amount ?? 0).toFixed(2),
                authorizedAmount: (expense.authorizedAmount ?? 0).toFixed(2),
                timestamp: expense.timestamp || null,
                notes: expense.notes ?? '',
            }
            //{ emitEvent: false }
        );
    }
    requestStatus$ = this.requestStateService.getState().pipe(map((state) => state.requestStatus));
    public readonly formGroup = new FormGroup({
        category: new FormControl<string>(ExpenseTypes.None, [Validators.required]),
        timestamp: new PersistentFormControl<Moment>(null, [Validators.required]),
        requestedAmount: new FormControl<string>(null, [Validators.required, Validators.min(0.01), maxValidator(2e6)]),
        authorizedAmount: new PersistentFormControl<string>(null, [
            Validators.required,
            Validators.min(0),
            maxValidator(2e6),
        ]),
        notes: new PersistentFormControl<string>('', [Validators.maxLength(500)]),
    });
    public readonly formChange$ = this.formGroup.valueChanges.pipe(
        tap(() => {
            const value = this.formGroup.getRawValue();
            if (value.requestedAmount !== undefined) this.expense.amount = +value.requestedAmount;
            if (value.authorizedAmount !== undefined) this.expense.authorizedAmount = +value.authorizedAmount;
            if (value.notes !== undefined) this.expense.notes = value.notes;
            if (value.category !== undefined) this.expense.category = value.category;
            if (value.timestamp !== undefined) this.expense.timestamp = value.timestamp;
        })
    );
    isInProcessOrHigher = isInProcessOrHigher;
    public readonly authorizedAmountEnabled$ = defer(() => {
        return combineLatest({
            state: this.requestStateService.getState(),
            user: this.userService.getUser(),
        }).pipe(
            tap(({ state, user }) => {
                const requestStatusInProcess = this.isInProcessOrHigher(state.requestStatus);
                const isUserAdmin = user.isAdmin || user.isSuperAdmin;
                if (requestStatusInProcess && isUserAdmin) {
                    this.formGroup.controls.authorizedAmount.enable({ force: true });
                    this.formGroup.controls.notes.enable({ force: true });
                } else {
                    this.formGroup.controls.authorizedAmount.disable({ persistent: true });
                    this.formGroup.controls.notes.disable({ persistent: true });
                }
            })
        );
    });
    public readonly copyRequestedAmountToAuthorizedAmount$ = defer(() => {
        return this.formGroup.controls.requestedAmount.valueChanges.pipe(
            tap((requestedAmount) => {
                this.formGroup.controls.authorizedAmount.setValue((+requestedAmount).toFixed(2));
            })
        );
    });
    public readonly sharedCurrencyChange$ = defer(() =>
        this.currencyControl.valueChanges.pipe(
            startWith(this.currencyControl.value),
            tap((value) => (this.expense.currency = value))
        )
    );
    public getRequestedAmount(): number {
        return +this.formGroup.controls.requestedAmount.value;
    }
    public getAuthorizedAmount(): number {
        return +this.formGroup.controls.authorizedAmount.getRawValue();
    }
    public getFormGroup(): AbstractControl {
        return this.formGroup;
    }
}
export type DistanceUnit = 'Miles' | 'Kilometers';
