import { Injectable } from '@angular/core';
import { ISite } from '@models/site';
import { IStudy } from '@models/study';
import { UserClient } from '@repositories/admin/user-client.repository';
import { Observable, ReplaySubject, catchError, of, take, tap } from 'rxjs';
import { User, UserAdminViewModel } from 'src/app/models/user';
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private static _staticUser: User;
    public static get staticUser() {
        return UserService._staticUser;
    }
    private readonly user = new ReplaySubject<User>();
    private readonly IsValidatedToLoadData = new ReplaySubject<boolean>();

    constructor(private userClient: UserClient) {}

    setUser(user: User) {
        let mergedUser = {
            ...UserService._staticUser,
            ...user,
        };
        this.user.next(mergedUser);
        UserService._staticUser = mergedUser;
    }

    getUser(): Observable<User> {
        return this.user.asObservable();
    }

    getCurrentUserAccountData(): Observable<UserAdminViewModel> {
        return this.userClient.getUserAccountDataByEmailClaim();
    }

    checkUserRole(): void {
        this.getCurrentUserAccountData()
            .pipe(
                take(1),
                tap((userAccountData) => {
                    this.setUser(<User>{
                        hasLocalAuthentication: userAccountData?.hasLocalAuthentication,
                    });
                    if (!userAccountData || !userAccountData.isActive || userAccountData?.accessGroups.length < 1) {
                        this.IsValidatedToLoadData.next(false);
                    } else {
                        this.IsValidatedToLoadData.next(true);
                        let mergedUser = <User>{
                            ...UserService._staticUser,
                            ...(<User>{
                                userSites: userAccountData?.sites?.map((x) => {
                                    return <ISite>{
                                        id: x.id,
                                        isReviewedByCRC: x.isReviewedByCRC,
                                        status: x.status,
                                        studyId: x.studyId,
                                    };
                                }),
                                userStudies: userAccountData?.studies?.map((x) => {
                                    return <IStudy>{ id: x.id, isActive: x.isActive, isApproved: x.isApproved };
                                }),
                            }),
                        };
                        this.setUser(mergedUser);
                    }
                }),
                catchError((error) => {
                    console.error("Failed to retrieve user's account data: ", error?.response, error?.message);
                    console.error(error);
                    this.IsValidatedToLoadData.next(false);
                    return of(null);
                })
            )
            .subscribe();
    }

    doesUserHaveRole(): Observable<boolean> {
        return this.IsValidatedToLoadData.asObservable();
    }
}
