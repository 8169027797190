<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__col">
            <div class="o-grid">
                <div class="o-grid__row"></div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <h5 class="uppercase">{{ checkEditStatus() }}</h5>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div *ngIf="isEditStudy">
                            <medpace-study
                                (detailsChangeEvent)="registerStudyDetailsChange($event)"
                                (logoChangeEvent)="registerLogoChange($event)"
                                (studyInfoChangeEvent)="registerStudyInfoChange($event)"
                                (studyRegionsChange)="registerStudyRegionChange($event)"
                                (studyCountriesChange)="registerStudyCountryChange($event)"
                                (studyTravelOptionsChange)="registerStudyTravelOptionChange($event)"
                                (submitClickEvent)="updateExistingStudy($event)"
                                (tierSupportChangeEvent)="registerTierSupportChange($event)"
                                [isAdmin]="isAdmin"
                                [isUpdate]="isEditStudy"
                                [study]="study"
                                (uploadSpreadsheetClickedEvent)="uploadSpreadsheetClicked()"
                                (addNewSiteClickedEvent)="addNewSiteClicked()"
                                (siteRowClickedEvent)="siteRowClicked($event)"
                            >
                            </medpace-study>
                        </div>
                        <div *ngIf="!isEditStudy && this.clintrakContext">
                            <medpace-study
                                (detailsChangeEvent)="registerStudyDetailsChange($event)"
                                (logoChangeEvent)="registerLogoChange($event)"
                                (studyInfoChangeEvent)="registerStudyInfoChange($event)"
                                (studyRegionsChange)="registerStudyRegionChange($event)"
                                (studyCountriesChange)="registerStudyCountryChange($event)"
                                (studyTravelOptionsChange)="registerStudyTravelOptionChange($event)"
                                (submitClickEvent)="submitNewStudy($event)"
                                (rejectStudyEvent)="rejectClinTrakStudy()"
                                (tierSupportChangeEvent)="registerTierSupportChange($event)"
                                [isAdmin]="isAdmin"
                                [isUpdate]="isEditStudy"
                                [study]="study"
                                (uploadSpreadsheetClickedEvent)="uploadSpreadsheetClicked()"
                                (addNewSiteClickedEvent)="addNewSiteClicked()"
                                (siteRowClickedEvent)="siteRowClicked($event)"
                            >
                            </medpace-study>
                        </div>
                        <div *ngIf="!isEditStudy && !this.clintrakContext">
                            <medpace-alert
                                additionalClass="danger"
                                content="You can not create Study without ClinTrak data."
                            ></medpace-alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
