<mds-datepicker
    (keyup)="writeText($event)"
    #mds_datepicker
    [label]="label"
    [hint]="hint"
    [appearance]="appearance"
    [color]="color"
    [datePickerHeight]="datePickerHeight"
    [defaultErrorSpacing]="defaultErrorSpacing"
    [floatLabel]="'always'"
    [id]="id"
    [required]="required"
    (dateInput)="emitDateChange($event)"
    [formCtrl]="localFormControl"
></mds-datepicker>
