export enum RequestTypes {
    TRAVEL = 'travel',
    PAYMENT = 'payment',
    STIPEND = 'stipend',
    REIMBURSEMENT = 'out-of-pocket',
}

export enum RequestTypesKeys {
    TRAVEL = 'Travel',
    STIPEND = 'Stipend',
    REIMBURSEMENT = 'Reimbursement',
}
