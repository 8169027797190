import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-data-redirector',
    template: '',
})
export class MedpaceDataRedirectorComponent {
    constructor(router: Router, route: ActivatedRoute) {
        const data = route.snapshot?.data;
        const extras: NavigationExtras = {
            queryParams: { params: JSON.stringify(data?.content) },
        };
        router.navigate([data?.redirectTo], extras);
    }
}
