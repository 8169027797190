import { Component, Input } from '@angular/core';

export type AlertData = {
    content: string;
    type: 'primary' | 'secondary' | 'danger' | 'warning';
};

@Component({
    selector: 'medpace-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    @Input({ required: true })
    content: string = '';

    @Input()
    additionalClass: 'primary' | 'secondary' | 'danger' | 'warning' = 'primary';
}
