import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { AnnouncementModalComponent } from '@components/atoms/announcement-modal/announcement-modal/announcement-modal.component';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { AnnouncementService } from '@services/announcement/announcement.service';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UserService } from '@services/user/user.service';
import { MdsOptionGeneric } from '@utility/utility';
import { map, Subject, takeUntil } from 'rxjs';
import { Study } from 'src/app/models/study';

@Component({
    selector: '',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
    requestTable: any[];
    activeStudiesColumns: string[] = [
        'sponsor',
        'protocol',
        'indication',
        'status',
        'numSites',
        'numPatients',
        'requests',
    ];
    pendingStudiesColumns: string[] = ['projectId', 'sponsorName', 'statusTypeName', 'studyName', 'studyNumber'];
    activeStudiesColumnMap: any[] = [
        { header: 'sponsor name', name: 'sponsor' },
        { header: 'protocol', name: 'protocol' },
        { header: 'Indication', name: 'indication' },
        { header: 'clinTrak status', name: 'status' },
        { header: '# sites', name: 'numSites' },
        { header: '# patients', name: 'numPatients' },
        { header: 'requests', name: 'requests' },
    ];
    pendingStudiesColumnMap: any[] = [
        { header: 'Project ID', name: 'projectId' },
        { header: 'Sponsor Name', name: 'sponsorName' },
        { header: 'clinTrak status', name: 'statusTypeName' },
        { header: 'Study Name', name: 'studyName' },
        { header: 'Study Number', name: 'studyNumber' },
    ];

    private componentDestroyed$: Subject<boolean> = new Subject();

    user$ = this.userService.getUser().pipe(takeUntil(this.componentDestroyed$));
    activeClinTrakStudies$ = this.clintrakDataService.getAllActiveStudies().pipe(takeUntil(this.componentDestroyed$));
    activeStudies$ = this.adminStudies.getAllStudySummaries().pipe(
        takeUntil(this.componentDestroyed$),
        map((result) => {
            result.forEach((study) => {
                study.indication = study.indication || 'N/A';
                study.status = study.status || 'N/A';
            });

            return result;
        })
    );

    regions: MdsOptionGeneric<string>[];

    constructor(
        private router: Router,
        private snackbarService: SnackbarService,
        private adminStudies: AdminStudyServices,
        private requestServices: AdminRequestServices,
        private matDialog: MatDialog,
        private announcementService: AnnouncementService,
        private clintrakDataService: ClinTrakDataService,
        private userService: UserService
    ) {}
    ngOnInit(): void {
        this.requestTable = [];
        this.regions = [];

        if ((history.state as Study)['sponsor']) {
            this.snackbarService.openInfoSnackbar(`Added or Modified Study for ${history.state.sponsor}`);
        }

        this.requestServices
            .getTotalAllRequestsCount()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => (this.requestTable = result));

        this.getAnnouncementsForCRC();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    goToEditStudy(event: any) {
        let navigationExtras = <NavigationExtras>{
            state: {
                event: event,
            },
        };
        this.router.navigate(['/admin/newstudy'], navigationExtras);
    }

    doClickRow(event: any) {
        this.router.navigate([`studies/${event.id}`], { state: event });
    }

    goToNewStudy() {
        this.router.navigate(['/admin/newstudy']);
    }

    getAnnouncementsForCRC() {
        this.announcementService
            .getAnnouncementsToDisplay()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((announcements) => {
                if (announcements.length != 0) {
                    this.matDialog.open(AnnouncementModalComponent, {
                        data: {
                            announcements: announcements,
                            showRedirectToRequests: false,
                        },
                    });
                }
            });
    }
}
