import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoadingViewTriggerService } from '../services/loading-module-trigger/loading-module-trigger.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingModalTriggerService: LoadingViewTriggerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method !== 'PUT' && request.method !== 'POST') {
            return next.handle(request);
        }

        this.loadingModalTriggerService.openLoading();

        return next.handle(request).pipe(
            finalize(() => {
                this.loadingModalTriggerService.closeLoading();
            })
        );
    }
}
