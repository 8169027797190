import { Observable, ReplaySubject, take } from 'rxjs';
export abstract class StateService<T> {
    protected subject = new ReplaySubject<T>();
    public abstract get(id: any): Observable<T>;
    public getState(): Observable<T> {
        return this.subject.asObservable();
    }
    public setState(state: T): void {
        this.subject.next(state);
    }
    public populateState(id: any): void {
        this.get(id)
            .pipe(take(1))
            .subscribe((result) => this.setState(result));
    }
    public clearState() {
        this.subject.complete();
        this.subject = new ReplaySubject<T>();
    }
}
