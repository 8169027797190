<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__col">
            <div class="o-grid">
                <div class="o-grid__row"></div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <h5 class="uppercase">{{ getTitleLabel() }}</h5>
                    </div>
                </div>
                <div *ngIf="!isUpdate" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-request
                            (saveClickEvent)="saveDraftRequest($event)"
                            (submitClickEvent)="submitRequest($event)"
                            [isAdmin]="isAdmin"
                            [isUpdate]="isUpdate"
                        ></medpace-request>
                    </div>
                </div>
                <div *ngIf="isUpdate" class="o-grid__row">
                    <div class="o-grid__item">
                        <medpace-request
                            (incrementalSave)="updateAnyRequestPart($event)"
                            (patientNavigationRequestEvent)="patientNavAction($event)"
                            (saveClickEvent)="saveDraftRequest($event)"
                            (statusChangeEvent)="updateRequestStatus($event)"
                            (submitClickEvent)="submitRequest($event)"
                            [isAdmin]="isAdmin"
                            [isUpdate]="isUpdate"
                        >
                        </medpace-request>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
