<ng-container *ngIf="mode === 'view'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Rental Company Frequent Traveler Number</h5>
                    <span>{{ formGroup.controls.frequentTravelerNumber.value || 'Unspecified' }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="mode === 'edit'">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="frequentTravelerNumber"
                    [formCtrl]="formGroup.controls.frequentTravelerNumber"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Rental Company Frequent Traveler Number"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
    </div>
</ng-container>
