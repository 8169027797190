import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const options = {
    fields: ['address_components', 'geometry', 'formatted_address'],
    types: ['address'],
};

const addressNameFormat = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
    postal_town: 'long_name',
};

const loader = new Loader({
    apiKey: 'AIzaSyC7K-K4VZtf5JIogYgbmvptct1IyI63wf0',
    version: 'weekly',
    libraries: ['places'],
    language: 'en',
});

@Injectable({
    providedIn: 'root',
})
export class GoogleMapsService {
    private readonly placeResultSubject: Subject<google.maps.places.PlaceResult> =
        new BehaviorSubject<google.maps.places.PlaceResult>(null);
    private readonly fieldNameSubject: Subject<string> = new BehaviorSubject<string>(null);
    private readonly countSubject: Subject<number> = new BehaviorSubject<number>(null);
    private autocompleteInstanceList: google.maps.places.Autocomplete[] = [];
    constructor(
        private ngZone: NgZone,
        private dialog: MatDialog
    ) {}

    setActiveCount(count: number) {
        this.countSubject.next(count);
    }

    setActiveField(fieldName: string) {
        this.fieldNameSubject.next(fieldName);
    }

    getFieldName() {
        return this.fieldNameSubject.asObservable();
    }

    getCount() {
        return this.countSubject.asObservable();
    }

    observePlaceListener(): Observable<google.maps.places.PlaceResult> {
        return this.placeResultSubject.asObservable();
    }

    clearPlaceObservable(): void {
        this.autocompleteInstanceList.forEach((autocomplete) => {
            google.maps.event.clearInstanceListeners(autocomplete);
        });

        let element = document.querySelectorAll('.pac-container');
        element.forEach((pacContainer) => {
            pacContainer.remove();
        });

        this.autocompleteInstanceList = [];
        return this.placeResultSubject.next(null);
    }

    setPlaceListener(inputField: HTMLInputElement): void {
        loader
            .importLibrary('places')
            .then((_) => {
                let autocomplete = new google.maps.places.Autocomplete(inputField, options);
                this.autocompleteInstanceList.push(autocomplete);

                autocomplete.addListener('place_changed', () => {
                    this.ngZone.run(() => {
                        //get the place result
                        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                        //verify result
                        if (place.geometry === undefined || place.geometry === null) {
                            return;
                        }

                        this.placeResultSubject.next(place);
                    });
                });
            })
            .catch((e) => {
                this.dialog.open(DisplayErrorModalComponent, {
                    autoFocus: false,
                    width: '500px',
                    disableClose: false,
                    data: 'Google Maps connection error',
                });
                throw e;
            });
    }

    public getAddressComponent(type: string, addressComponent: google.maps.GeocoderAddressComponent[]): string {
        for (const component of addressComponent) {
            if (component.types[0] === type) {
                return component[addressNameFormat[type]];
            }
        }

        return '';
    }

    public getCityComponent(placeResult: google.maps.places.PlaceResult): string {
        const baseCity = this.getAddressComponent('locality', placeResult.address_components);
        return baseCity ? baseCity : this.getAddressComponent('postal_town', placeResult.address_components);
    }

    public setStyleForModal() {
        let element = document.querySelectorAll('.pac-container');
        element.forEach((pacContainer) => {
            pacContainer.classList.add('modalPacContainer');
        });
    }

    public setStyleForComponent() {
        let element = document.querySelectorAll('.pac-container');
        element.forEach((pacContainer) => {
            pacContainer.classList.remove('modalPacContainer');
        });
    }
}
