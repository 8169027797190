import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-information-accuracy-modal',
    templateUrl: './information-accuracy-modal.component.html',
    styleUrls: ['./information-accuracy-modal.component.scss'],
})
export class InformationAccuracyModalComponent {
    constructor(public dialogRef: MatDialogRef<InformationAccuracyModalComponent>) {}
    alertMessage: string = `Please ensure the account holder's full name and address match their government-issued ID. Inaccurate information will delay the account holder's ability to access their funds.`;
    closeDialog(): void {
        this.dialogRef.close(true);
    }
}
