import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalResult } from '@app/enums/modal-result.enum';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { Patient } from '@models/patient';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { PatientStatusService } from '@services/patient-status/patient-status.service';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'medpace-patient-transfer-to-another-site',
    templateUrl: './patient-transfer-to-another-site.component.html',
    styleUrls: ['./patient-transfer-to-another-site.component.scss'],
})
export class MedpacePatientTransferToAnotherSiteComponent {
    patientSiteTransferFormGroup: FormGroup = this.formBuilder.group({
        availableSitesToChange: this.formBuilder.control<number>(null, Validators.required),
    });
    #siteService = inject(AdminSiteServices);

    #sites$ = this.#siteService.getStudySites(this.data.patient.studyId);

    currentSiteName$: Observable<string> = this.#sites$.pipe(
        map((sites) => sites.find((site) => site.id === this.data.patient.siteId)?.siteNumber)
    );

    sitesAvailableToSelect$ = this.#sites$.pipe(
        map((sites) => sites.filter((site) => site.id !== this.data.patient.siteId)),
        map((results) =>
            results.map((site): MdsOption => {
                return { value: site.id, viewValue: site.siteNumber };
            })
        )
    );

    constructor(
        public dialogRef: MatDialogRef<MedpacePatientTransferToAnotherSiteComponent>,
        public patientStatusService: PatientStatusService,
        @Inject(MAT_DIALOG_DATA) public data: { patient: Patient },
        private formBuilder: FormBuilder
    ) {}

    closeDialog(): void {
        this.dialogRef.close({ status: ModalResult.Cancel });
    }

    saveDialog(): void {
        if (this.patientSiteTransferFormGroup.valid) {
            this.dialogRef.close({
                status: ModalResult.Okay,
                newPatientSiteId: this.patientSiteTransferFormGroup.controls.availableSitesToChange.value,
            });
        }
    }
}
