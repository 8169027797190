import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'medpace-display-error-modal',
    templateUrl: './display-error-modal.component.html',
    styleUrl: './display-error-modal.component.scss',
})
export class DisplayErrorModalComponent {
    bodyText: string;

    constructor(
        private dialogRef: MatDialogRef<DisplayErrorModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {
        this.bodyText = data;
    }

    close() {
        this.dialogRef.close();
    }
}
