import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientStatusService } from '@services/patient-status/patient-status.service';
import { PatientStatus } from '../../../models/patientStatus';

@Component({
    selector: 'medpace-patient-change-status-modal',
    templateUrl: './patient-change-status-modal.component.html',
    styleUrls: ['./patient-change-status-modal.component.scss'],
})
export class MedpacePatientChangeStatusModalComponent implements OnInit {
    textStatus: string;
    statusConstants: PatientStatus[];
    displayStatus: PatientStatus[] = [];
    patientStatusChangeFormGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<MedpacePatientChangeStatusModalComponent>,
        public patientStatusService: PatientStatusService,
        @Inject(MAT_DIALOG_DATA) public data,
        private formBuilder: FormBuilder
    ) {
        this.patientStatusChangeFormGroup = this.formBuilder.group({
            statusDropdownControl: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.statusConstants = this.patientStatusService.getStatuses();
        this.displayStatus = this.patientStatusService
            .getStatusesAvailabletoChange()
            .filter((x) => x.value !== this.data?.patient?.statusId);
        this.textStatus = this.statusConstants.find((x) => x.value === this.data?.patient?.statusId)?.viewValue;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    saveDialog(): void {
        if (this.patientStatusChangeFormGroup.valid) {
            this.dialogRef.close({
                statusId: this.patientStatusChangeFormGroup?.controls.statusDropdownControl?.value,
            });
        }
    }
}
