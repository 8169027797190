<mds-dialog dialogTitle="NO ROLE ASSIGNMENT">
    <mds-dialog-content>
        <div class="no_role_assign">
            <h4 class="no_role_user_name" id="UserNoRoleName">{{ user?.name }}</h4>
        </div>

        <div class="no_role_assign" *ngIf="user.emailAddress">
            <h4 class="label-warning_no_role" id="UserNoRoleMessage">No Portal Assignment found under this account.</h4>
        </div>

        <div class="no_role_assign" *ngIf="!user.emailAddress">
            <h4 class="label-warning_no_role" id="UserNoRoleMessage">
                Account cannot be used in Portal. The `email` claim is missing in token.
            </h4>
        </div>

        <div class="no_role_assign">
            <div class="c-info__block">
                <h4 *ngIf="user?.hasLocalAuthentication || !user.emailAddress">
                    Please contact <span class="underscore">{{ contactEmail }}</span> for assistance.
                </h4>
                <h4 *ngIf="!(user?.hasLocalAuthentication || !user.emailAddress)">
                    Please contact <span class="underscore">{{ contactEmail }}</span> for assistance or create an access
                    request by the button below.
                </h4>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            *ngIf="!(user?.hasLocalAuthentication || !user.emailAddress)"
            [id]="'CreateAccessRequestBtn'"
            label="Create Access Request"
            variant="primary"
            (clickEvent)="accessReqeustPage()"
        ></mds-button>
        <mds-button
            mat-button
            mat-dialog-close
            [id]="'NoRoleLogoutButton'"
            label="Logout"
            variant="primary"
            (clickEvent)="initLogout()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
