import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReimbursementTypes } from '@app/enums/ReimbursementTypes';
import { RequestTypes } from '@app/enums/RequestTypes';
import { Patient } from '@models/patient';
import { Study, VisitName } from '@models/study';
import { FieldValidator } from '@models/validators/field-validator';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { GroupService } from '@services/group/group.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { RequestContextState, RequestStateService } from '@services/state-management/request-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import {
    transformToReimbursementRequest,
    transformToStipendRequest,
    transformToTravelRequest,
} from '@services/transforms/request-transform';
import { UserService } from '@services/user/user.service';
import { PageMode, RequestPageMode } from '@utility/utility';
import { Observable, Subject, combineLatest, filter, take, takeUntil, tap } from 'rxjs';
import { MedpaceMessageModalComponent } from 'src/app/components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { RequestMessagingService } from 'src/app/services/messaging/request-messaging.service';
import { GenericRequest, RequestStatusDTO, TravelAccommodations } from '../../models/request';
import { Site, SiteServices } from '../../models/site';

@Component({
    selector: '',
    templateUrl: './create-edit-request.component.html',
})
export class MedpaceCreateEditPaymentTravelRequestComponent implements OnInit, OnDestroy {
    request: GenericRequest;
    patient: Patient;
    study: Study;
    visitTypes: VisitName[];
    site: Site;
    siteServiceInfo: SiteServices;
    isUpdate: boolean = false;
    validationArray: FieldValidator<any>[];
    isAdmin: boolean;
    stipendOrOOP: string;
    changedPaymentType: boolean = false;
    private componentDestroyed$: Subject<boolean> = new Subject();
    destroy$ = new Subject();
    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private requestServices: AdminRequestServices,
        private requestCounter: RequestMessagingService,
        private groupServices: GroupService,
        public dialog: MatDialog,
        private snackbarService: SnackbarService,
        private requestStateService: RequestStateService,
        private studyStateService: StudyStateService,
        private siteStateService: SiteStateService,
        private patientStateService: PatientStateService,
        private userService: UserService,
        private cardService: CurrentlyEditedCardService,
        private location: Location
    ) {}

    setStates() {
        this.userService.getUser().subscribe((user) => {
            this.studyStateService.setStudy(this.activeRoute.snapshot.params.id);
            this.patientStateService.setSummaryPatientData(this.activeRoute.snapshot.params.patientId);
            this.requestStateService.setVisits(this.activeRoute.snapshot.params.id);
            user.isAdmin || user.isSuperAdmin
                ? this.siteStateService.setSiteAndStudyUsers(this.activeRoute.snapshot.params.siteId)
                : this.siteStateService.setSite(this.activeRoute.snapshot.params.siteId);
        });
    }

    getStates() {
        let study = this.studyStateService.getStudy();
        let patient = this.patientStateService.getSummaryPatientData();
        let site = this.siteStateService.getSite();
        let visits = this.requestStateService.getVisits();

        combineLatest({ study, patient, site, visits })
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((result) => {
                    this.study = result.study;
                    this.patient = result.patient;

                    this.site = result.site;
                    if (result.site) {
                        this.siteServiceInfo = result.site.services;
                        if (this.patient) this.patient.patientIdentification['siteId'] = this.site.id;
                    }

                    if (this.patient?.patientIdentification && this.study)
                        this.patient.patientIdentification['studyId'] = this.study.id;

                    this.visitTypes = result.visits;
                    if (this.study) this.study.studyInfo.visitTypes = this.visitTypes;
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        const payOrTravel = this.IsPaymentOrTravelType();
        this.stipendOrOOP = this.activeRoute.snapshot.queryParams['stipend-oop'];
        this.setStates();
        this.getStates();

        this.groupServices
            .getGroupsFromStorage()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.isAdmin = this.groupServices.isUserAdmin(result);
            });
        const patientId = this.activeRoute.snapshot.params.patientId;
        if (RequestPageMode(this.activeRoute) === PageMode.CREATE) {
            this.request = <GenericRequest>{
                patientId: patientId,
                type: payOrTravel,
                travelRequests: <TravelAccommodations>{},
                status: 0,
            };
            this.requestStateService.setNewRequest(this.request);
            // set empty state if creating a new request
            this.requestStateService.setState(<RequestContextState>{
                pageMode: PageMode.CREATE,
                requestType: null,
                reimbursementType: null,
                requestStatus: this.request.status,
                patientId: patientId,
            });
            this.requestStateService.travelRequestStateService.setState(null);
            this.requestStateService.stipendRequestStateService.setState(null);
            this.requestStateService.reimbursementRequestStateService.setState(null);
        }

        if (RequestPageMode(this.activeRoute) === PageMode.EDIT) {
            this.isUpdate = true;
            this.GetNewRequestState(payOrTravel);
        }
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.requestStateService.clearStore();
        this.requestStateService.clearState();
        this.siteStateService.clearStore();
        this.studyStateService.clearStore();
        this.patientStateService.clearStore();

        this.destroy$.next(null);
        this.destroy$.complete();
    }

    updateAnyRequestPart(event: GenericRequest) {
        if (event?.paymentType !== this.stipendOrOOP) {
            this.changedPaymentType = true;
        }
        if (event.type === 'travel') {
            const tr = transformToTravelRequest(event);
            this.requestServices.updateTravelRequest(tr.id, tr).subscribe({
                next: (result) => {
                    const payOrTravel = this.IsPaymentOrTravelType();
                    this.GetNewRequestState(payOrTravel);
                    this.requestCounter.submittedRequests();
                    this.requestStateService.setRequest(
                        RequestTypes.TRAVEL,
                        this.stipendOrOOP,
                        this.activeRoute.snapshot.params.requestId
                    );
                    this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('update', 'travel'));
                },
                error: (error) => {
                    this.handleRequestSaveError(error).subscribe((result) => {
                        switch (result) {
                            case 'Okay':
                            case 'OKAY':
                            case 'okay':
                                event.flaggedDuplicate = true;

                                this.updateAnyRequestPart(event);
                                break;
                            default:
                                break;
                        }
                    });
                },
            });
        } else if (event.type === 'payment' && event.paymentType === 'stipend') {
            const stipend = transformToStipendRequest(event);
            this.requestServices.updateStipendRequest(stipend).subscribe({
                next: (result) => {
                    this.requestCounter.submittedRequests();
                    if (!this.changedPaymentType) {
                        const payOrTravel = this.IsPaymentOrTravelType();
                        this.GetNewRequestState(payOrTravel);

                        this.requestStateService.setRequest(
                            RequestTypes.PAYMENT,
                            this.stipendOrOOP,
                            this.activeRoute.snapshot.params.requestId
                        );
                    } else {
                        this.requestStateService.setRequest(
                            RequestTypes.PAYMENT,
                            'stipend',
                            parseInt(result.infoLabel)
                        );
                        this.setNewRequestUrl(event, result);
                    }
                    this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('update', 'stipend'));
                },
                error: (error) => {
                    this.handleRequestSaveError(error).subscribe((result) => {
                        switch (result) {
                            case 'Okay':
                            case 'OKAY':
                            case 'okay':
                                event.flaggedDuplicate = true;

                                this.updateAnyRequestPart(event);
                                break;
                            default:
                                break;
                        }
                    });
                },
            });
        } else if (event.type === 'payment' && event.paymentType === 'out-of-pocket') {
            const oop = transformToReimbursementRequest(event);

            this.requestServices.updateReimbursementRequest(oop.id, oop).subscribe({
                next: (result) => {
                    this.requestCounter.submittedRequests();
                    if (!this.changedPaymentType) {
                        const payOrTravel = this.IsPaymentOrTravelType();
                        this.GetNewRequestState(payOrTravel);

                        this.requestStateService.setRequest(
                            RequestTypes.PAYMENT,
                            this.stipendOrOOP,
                            this.activeRoute.snapshot.params.requestId
                        );
                    } else {
                        this.requestStateService.setRequest(
                            RequestTypes.PAYMENT,
                            'out-of-pocket',
                            parseInt(result.infoLabel)
                        );
                        this.setNewRequestUrl(event, result);
                    }
                    this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('update', 'reimbursement'));
                },
                error: (error) => {
                    this.handleRequestSaveError(error).subscribe((result) => {
                        switch (result) {
                            case 'Okay':
                            case 'OKAY':
                            case 'okay':
                                event.flaggedDuplicate = true;

                                this.updateAnyRequestPart(event);
                                break;
                        }
                    });
                },
            });
        }
    }

    setNewRequestUrl(event: GenericRequest, result: any) {
        let route: string;
        if (!!result?.infoLabel && !isNaN(parseInt(result?.infoLabel))) {
            const newRequestNumber = parseInt(result?.infoLabel);
            const paymentType = event.paymentType === 'stipend' ? 'stipend' : 'out-of-pocket';
            route = `studies/${this.study.id}/sites/${this.site.id}/patients/${event.patientId}/requests/${newRequestNumber}/edit?payment-travel=payment&stipend-oop=${paymentType}`;
        } else {
            route = `studies/${this.study.id}/sites/${this.site.id}/patients/${event.patientId}`;
        }
        this.router.navigateByUrl(route).then((navigated: boolean) => {
            if (navigated) {
                this.snackbarService.openInfoSnackbar(
                    this.GetSnackbarMessageContent(
                        'update',
                        event.paymentType === 'stipend' ? 'stipend' : 'reimbursement'
                    )
                );
            }
        });
    }

    saveDraftRequest(event: GenericRequest) {
        if (event.type === 'travel') {
            const tr = transformToTravelRequest(event);
            if (event.id) {
                this.requestServices.updateTravelRequest(tr.id, tr).subscribe({
                    next: (result) => {
                        this.requestCounter.submittedRequests();
                        this.location.back();
                        this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('update', 'travel'));
                    },
                    error: (error) => {
                        this.handleRequestSaveError(error).subscribe((result) => {
                            switch (result) {
                                case 'Okay':
                                case 'OKAY':
                                case 'okay':
                                    event.flaggedDuplicate = true;

                                    this.saveDraftRequest(event);
                                    break;
                                default:
                                    break;
                            }
                        });
                    },
                });
            } else {
                this.requestServices.createTravelRequest(tr).subscribe({
                    next: (response) => {
                        this.requestCounter.submittedRequests();
                        this.location.back();
                        this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('create', 'travel'));
                    },
                    error: (error) => {
                        this.handleRequestSaveError(error).subscribe((result) => {
                            switch (result) {
                                case 'Okay':
                                case 'OKAY':
                                case 'okay':
                                    event.flaggedDuplicate = true;
                                    this.saveDraftRequest(event);
                                    break;
                                default:
                                    break;
                            }
                        });
                    },
                });
            }
        } else if (event.type === 'payment' && event.paymentType === 'stipend') {
            const stipend = transformToStipendRequest(event);
            if (event.id) {
                this.requestServices.updateStipendRequest(stipend).subscribe({
                    next: (result) => {
                        this.requestCounter.submittedRequests();
                        this.location.back();
                        this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('update', 'stipend'));
                    },
                    error: (error) => {
                        this.handleRequestSaveError(error).subscribe((result) => {
                            switch (result) {
                                case 'Okay':
                                case 'OKAY':
                                case 'okay':
                                    event.flaggedDuplicate = true;
                                    this.saveDraftRequest(event);
                                    break;
                                default:
                                    break;
                            }
                        });
                    },
                });
            } else {
                this.requestServices.createStipendRequest(stipend).subscribe({
                    next: (response) => {
                        this.requestCounter.submittedRequests();
                        this.location.back();
                        this.snackbarService.openInfoSnackbar(this.GetSnackbarMessageContent('create', 'stipend'));
                    },
                    error: (error) => {
                        this.handleRequestSaveError(error).subscribe((result) => {
                            switch (result) {
                                case 'Okay':
                                case 'OKAY':
                                case 'okay':
                                    event.flaggedDuplicate = true;

                                    this.saveDraftRequest(event);
                                    break;
                                default:
                                    break;
                            }
                        });
                    },
                });
            }
        } else if (event.type === 'payment' && event.paymentType === 'out-of-pocket') {
            const oop = transformToReimbursementRequest(event);

            if (event.id) {
                this.requestServices.updateReimbursementRequest(oop.id, oop).subscribe({
                    next: (result) => {
                        this.requestCounter.submittedRequests();
                        this.location.back();
                        this.snackbarService.openInfoSnackbar(
                            this.GetSnackbarMessageContent('update', 'reimbursement')
                        );
                    },
                    error: (error) => {
                        this.handleRequestSaveError(error).subscribe((result) => {
                            switch (result) {
                                case 'Okay':
                                case 'OKAY':
                                case 'okay':
                                    event.flaggedDuplicate = true;

                                    this.saveDraftRequest(event);
                                    break;
                                default:
                                    break;
                            }
                        });
                    },
                });
            } else {
                // TODO: Confirm that this is only used for Draft creation
                this.requestServices.createReimbursementRequest(oop).subscribe({
                    next: (response) => {
                        this.requestCounter.submittedRequests();
                        this.location.back();
                        this.snackbarService.openInfoSnackbar(
                            this.GetSnackbarMessageContent('create', 'reimbursement')
                        );
                    },
                    error: (error) => {
                        this.handleRequestSaveError(error).subscribe((result) => {
                            switch (result) {
                                case 'Okay':
                                case 'OKAY':
                                case 'okay':
                                    event.flaggedDuplicate = true;

                                    this.saveDraftRequest(event);
                                    break;
                                default:
                                    break;
                            }
                        });
                    },
                });
            }
        }
    }
    submitRequest(event: GenericRequest) {
        const origStatus = event.status;
        this.request = event;
        this.executeSaveRequest(this.request, origStatus, null);
    }

    updateRequestStatus(event: RequestStatusDTO) {
        let request = event.request;
        const origStatus = request.status;
        request.status = event.newStatus;
        let redirectUrl = `studies/${this.study.id}/sites/${this.site.id}/patients/${event.request.patientId}`;

        this.executeSaveRequest(request, origStatus, redirectUrl);
    }

    executeSaveRequest(request: GenericRequest, fallbackStatus: number, redirectUrl: string | null) {
        if (request.type === 'travel') {
            const tr = transformToTravelRequest(request);

            switch (tr.statusId) {
                case 1:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    this.requestServices.updateTravelRequest(tr.id, tr).subscribe({
                        next: (result) => {
                            this.requestCounter.submittedRequests();
                            this.GetNewRequestState('travel');

                            if (redirectUrl) {
                                this.router.navigate([redirectUrl]).then((navigated: boolean) => {
                                    if (navigated) {
                                        this.snackbarService.openInfoSnackbar(
                                            this.GetSnackbarMessageContent('update', 'travel')
                                        );
                                    }
                                });
                            } else {
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('update', 'travel')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
                case 2: //submit
                    this.requestServices.submitTravelRequest(tr).subscribe({
                        next: (response) => {
                            this.requestCounter.submittedRequests();

                            if (tr.id) {
                                this.location.back();
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('update', 'travel')
                                );
                            } else {
                                this.location.back();
                                this.GetSnackbarMessageContent('create', 'travel');
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
                case 3: //complete
                    this.requestServices.completeTravelRequest(tr.id, tr).subscribe({
                        next: (result) => {
                            this.requestCounter.submittedRequests();
                            this.GetNewRequestState('travel');

                            if (redirectUrl) {
                                this.router.navigate([redirectUrl]).then((navigated: boolean) => {
                                    if (navigated) {
                                        this.snackbarService.openInfoSnackbar(
                                            this.GetSnackbarMessageContent('complete', 'travel')
                                        );
                                    }
                                });
                            } else {
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('complete', 'travel')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
            }
        }

        if (request.type === 'payment' && request.paymentType === 'stipend') {
            const stipend = transformToStipendRequest(request);
            switch (stipend.statusId) {
                case 1:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    this.requestServices.updateStipendRequest(stipend).subscribe({
                        next: (result) => {
                            this.requestCounter.submittedRequests();
                            this.GetNewRequestState('payment');
                            if (redirectUrl) {
                                this.router.navigate([redirectUrl]).then((navigated: boolean) => {
                                    if (navigated) {
                                        this.snackbarService.openInfoSnackbar(
                                            this.GetSnackbarMessageContent('update', 'stipend')
                                        );
                                    }
                                });
                            } else {
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('update', 'stipend')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
                case 2: //submit
                    this.requestServices.submitStipendRequest(stipend).subscribe({
                        next: (response) => {
                            this.requestCounter.submittedRequests();
                            if (stipend.id) {
                                this.location.back();
                                this.GetSnackbarMessageContent('update', 'stipend');
                            } else {
                                this.location.back();
                                this.GetSnackbarMessageContent('create', 'stipend');
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
                case 3: //complete
                    this.requestServices.completeStipendRequest(stipend).subscribe({
                        next: (result) => {
                            this.requestCounter.submittedRequests();
                            this.GetNewRequestState('payment');

                            if (redirectUrl) {
                                this.router.navigate([redirectUrl]).then((navigated: boolean) => {
                                    if (navigated) {
                                        this.snackbarService.openInfoSnackbar(
                                            this.GetSnackbarMessageContent('complete', 'stipend')
                                        );
                                    }
                                });
                            } else {
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('complete', 'stipend')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
            }
        } else if (request.type === 'payment' && request.paymentType === 'out-of-pocket') {
            const oop = transformToReimbursementRequest(request);

            switch (oop.statusId) {
                case 1:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    this.requestServices.updateReimbursementRequest(oop.id, oop).subscribe({
                        next: (result) => {
                            this.requestCounter.submittedRequests();
                            this.GetNewRequestState('payment');
                            if (redirectUrl) {
                                this.router.navigate([redirectUrl]).then((navigated: boolean) => {
                                    if (navigated) {
                                        this.snackbarService.openInfoSnackbar(
                                            this.GetSnackbarMessageContent('update', 'reimbursement')
                                        );
                                    }
                                });
                            } else {
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('update', 'reimbursement')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
                case 2: //submit
                    this.requestServices.submitReimbursementRequest(oop).subscribe({
                        next: (response) => {
                            this.requestCounter.submittedRequests();
                            if (oop.id) {
                                this.location.back();
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('update', 'reimbursement')
                                );
                            } else {
                                this.location.back();
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('create', 'reimbursement')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
                case 3: //complete
                    this.requestServices.completeReimbursementRequest(oop.id, oop).subscribe({
                        next: (result) => {
                            this.requestCounter.submittedRequests();
                            this.GetNewRequestState('payment');
                            if (redirectUrl) {
                                this.router.navigate([redirectUrl]).then((navigated: boolean) => {
                                    if (navigated) {
                                        this.snackbarService.openInfoSnackbar(
                                            this.GetSnackbarMessageContent('complete', 'reimbursement')
                                        );
                                    }
                                });
                            } else {
                                this.snackbarService.openInfoSnackbar(
                                    this.GetSnackbarMessageContent('complete', 'reimbursement')
                                );
                            }
                        },
                        error: (error) => {
                            this.handleRequestSaveError(error).subscribe((result) => {
                                switch (result) {
                                    case 'Okay':
                                    case 'OKAY':
                                    case 'okay':
                                        request.flaggedDuplicate = true;

                                        this.submitRequest(request);
                                        break;
                                    default:
                                        request.status = fallbackStatus;
                                        break;
                                }
                            });
                        },
                    });
                    break;
            }
        }
    }
    patientNavAction(event: any) {
        if (event.target === 'profile') {
            //goto profile
            this.router.navigate(
                [`studies/${this.site.studyId}/sites/${this.site.id}/patients/${this.patient.id}/edit`],
                { state: this.patient }
            );
        }
        if (event.target === 'activity') {
            //goto activity
            this.router.navigate([`studies/${this.site.studyId}/sites/${this.site.id}/patients/${this.patient.id}`], {
                state: this.patient,
            });
        }
    }

    private handleRequestSaveError(error: HttpErrorResponse): Observable<any> {
        switch (error?.status) {
            case 400:
                switch (error.error.errorReasonCode) {
                    case 'DuplicateRequest':
                        const dialogConfig = new MatDialogConfig();

                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 350;
                        dialogConfig.minWidth = 350;

                        dialogConfig.data = {
                            title: 'Potential Duplicate',
                            bodyText:
                                'A request with the same information already exists for this patient. Do you still want to submit?',
                            showCancelButton: true,
                            okayButtonLabel: 'Continue',
                            cancelButtonLabel: 'Cancel',
                        };

                        const dialogRef = this.dialog.open(MedpaceMessageModalComponent, dialogConfig);

                        return dialogRef.afterClosed();

                    // for other kinds of 400 errors, we might still want to display some error message, at least in dev/test
                    default:
                        throw error;
                }
            default:
                throw error;
        }
    }

    private GetNewRequestState(payOrTravel: string) {
        this.requestStateService.clearState(); // clear previous request state
        const requestId = +this.activeRoute.snapshot.params.requestId;
        this.requestStateService.setRequest(payOrTravel, this.stipendOrOOP, requestId);
        this.requestStateService
            .getRequest()
            .pipe(
                filter((request) => !!request && request.id === requestId), // unknown origin error - previous requests are sometimes emitted, quickfix is to filter out other request ids
                take(1),
                takeUntil(this.destroy$) // take(1) but if it hasn't yet emitted and component got destroyed, this will remain in memory, possibly emitting
            )
            .subscribe((request) => {
                this.request = request;
                let requestType =
                    payOrTravel === 'payment'
                        ? RequestTypes.PAYMENT
                        : payOrTravel === 'travel'
                          ? RequestTypes.TRAVEL
                          : null;
                let reimbursementType =
                    this.stipendOrOOP === 'stipend'
                        ? ReimbursementTypes.Stipend
                        : this.stipendOrOOP === 'out-of-pocket'
                          ? ReimbursementTypes.OutOfPocket
                          : null;
                // populate state with the appropriate request from api when editing request
                this.requestStateService.setState(<RequestContextState>{
                    pageMode: PageMode.EDIT,
                    requestType: requestType,
                    reimbursementType: reimbursementType,
                    requestStatus: this.request.status,
                });
                if (requestType === RequestTypes.TRAVEL) {
                    this.requestStateService.travelRequestStateService.populateState(request.id);
                    this.requestStateService.stipendRequestStateService.setState(null);
                    this.requestStateService.reimbursementRequestStateService.setState(null);
                } else if (requestType === RequestTypes.PAYMENT && reimbursementType === ReimbursementTypes.Stipend) {
                    this.requestStateService.travelRequestStateService.setState(null);
                    this.requestStateService.stipendRequestStateService.populateState(request.id);
                    this.requestStateService.reimbursementRequestStateService.setState(null);
                } else if (
                    requestType === RequestTypes.PAYMENT &&
                    reimbursementType === ReimbursementTypes.OutOfPocket
                ) {
                    this.requestStateService.travelRequestStateService.setState(null);
                    this.requestStateService.stipendRequestStateService.setState(null);
                    this.requestStateService.reimbursementRequestStateService.populateState(request.id);
                }
            });
    }

    private IsPaymentOrTravelType() {
        return this.activeRoute.snapshot.queryParams['payment-travel'];
    }

    getTitleLabel(): string {
        return this.isUpdate ? 'Edit Request' : 'Create New Request';
    }

    private GetSnackbarMessageContent(
        action: 'create' | 'update' | 'complete',
        requestType: 'travel' | 'reimbursement' | 'stipend'
    ): string {
        if (action === 'create') {
            if (requestType === 'travel') {
                return 'Created new Travel Request';
            } else if (requestType === 'reimbursement') {
                return 'Created new Reimbursement Request';
            } else if (requestType === 'stipend') {
                return 'Created new Stipend Request';
            }
        } else if (action == 'update') {
            if (requestType === 'travel') {
                return 'Updated Travel Request';
            } else if (requestType === 'reimbursement') {
                return 'Updated Reimbursement Request';
            } else if (requestType === 'stipend') {
                return 'Updated Stipend Request';
            }
        } else if (action == 'complete') {
            if (requestType === 'travel') {
                return 'Completed Travel Request';
            } else if (requestType === 'reimbursement') {
                return 'Completed Reimbursement Request';
            } else if (requestType === 'stipend') {
                return 'Completed Stipend Request';
            }
        }
    }
}
